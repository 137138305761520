import { PlusOutlined } from "@ant-design/icons"
import { InputRef, Input, Tag, Tooltip } from "antd"
import { useState, useRef, useEffect } from "react"

export const TraderLabels: React.FC<{
    labels: string[]
    onChange: (value: string[]) => void
}> = (props) => {
    //const { token } = theme.useToken();
    const [labels, setLabels] = useState(props.labels)
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [editInputValue, setEditInputValue] = useState("")
    const inputRef = useRef<InputRef>(null)
    const editInputRef = useRef<InputRef>(null)

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])

    useEffect(() => {
        if (props.onChange && typeof props.onChange === "function") {
            props.onChange(labels)
        }
    }, [labels])

    useEffect(() => {
        editInputRef.current?.focus()
    }, [editInputValue])

    const handleClose = (removedTag: string) => {
        const newTags = labels.filter((tag) => tag !== removedTag)
        setLabels(newTags)
    }

    const showInput = () => {
        setInputVisible(true)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        if (inputValue && !labels.includes(inputValue)) {
            setLabels([...labels, inputValue])
        }
        setInputVisible(false)
        setInputValue("")
    }

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value)
    }

    const handleEditInputConfirm = () => {
        const newTags = [...labels]
        newTags[editInputIndex] = editInputValue
        setLabels(newTags)
        setEditInputIndex(-1)
        setEditInputValue("")
    }

    const tagInputStyle: React.CSSProperties = {
        width: 64,
        height: 22,
        marginInlineEnd: 8,
        verticalAlign: "top",
    }

    const tagPlusStyle: React.CSSProperties = {
        height: 22,
        borderStyle: "dashed",
    }

    return (
        <>
            {labels.map((tag, index) => {
                if (editInputIndex === index) {
                    return (
                        <Input
                            ref={editInputRef}
                            key={tag}
                            size="small"
                            style={tagInputStyle}
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                        />
                    )
                }
                const isLongTag = tag.length > 20
                const tagElem = (
                    <Tag key={tag} closable={true} style={{ userSelect: "none" }} onClose={() => handleClose(tag)}>
                        <span
                            onDoubleClick={(e) => {
                                if (index !== 0) {
                                    setEditInputIndex(index)
                                    setEditInputValue(tag)
                                    e.preventDefault()
                                }
                            }}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                    </Tag>
                )
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                )
            })}
            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (
                <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                    New Tag
                </Tag>
            )}
        </>
    )
}

export default TraderLabels