export interface SnippetFile {
    name: string
    code: string
}

export interface Snippet {
    name: string
    code: string
}

/**
 * Manage a list of snippets stored in local storage.
 */
export class SnippetsManager {
    snippets: SnippetFile[] = []
    constructor() {
        this.load()
    }

    load() {
        const snippets = localStorage.getItem('snippets')
        if (snippets) {
            this.snippets = JSON.parse(snippets)
        }
    }

    save() {
        localStorage.setItem('snippets', JSON.stringify(this.snippets))
    }

    add(snippet: SnippetFile) {
        this.snippets.push(snippet)
        this.save()
    }

    // Update by snippet name
    update(snippet: SnippetFile) {
        const index = this.snippets.findIndex((s) => s.name === snippet.name)
        if (index === -1) {
            throw new Error(`Snippet with name ${snippet.name} not found`)
        }
        this.snippets[index] = snippet
        this.save()
        console.log('updated snippet', snippet)
    }

    remove(index: number) {
        this.snippets.splice(index, 1)
        this.save()
    }

    get(index: number) {
        return this.snippets[index]
    }

    getAll(): SnippetFile[] {
        return this.snippets
    }
}
