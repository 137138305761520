import { useSignals } from '@preact/signals-react/runtime'
import { Descriptions, Switch, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { useCallback } from 'react'
import {
    DeskContext,
    ThemeName,
    saveUserSettingsPartial,
    settingsSignal
} from '../App'
import { Toolbar, ToolbarItem } from '../components/Toolbar'
import { NarrowPage } from '../components/pages'
import { mts } from '../desk_protos'

const ProfilePage: React.FC<{}> = (props) => {
    useSignals()
    const appCtx = React.useContext(DeskContext)

    const user = appCtx?.user as mts.desk.User
    const session = appCtx?.session as mts.desk.Session

    const handleThemeChange = useCallback(
        (useDark: boolean) => {
            appCtx?.setTheme(useDark ? 'dark' : 'light')
            const bg = useDark ? '#000' : '#fff'
            document.body.style.backgroundColor = bg
            let themeName: ThemeName = useDark ? 'dark' : 'light'
            console.log(`settingsSignal.value`, settingsSignal.value)
            saveUserSettingsPartial({ themeName })
        },
        [appCtx],
    )

    const handleCompactChange = (useCompact: boolean) => {
        saveUserSettingsPartial({ compact: useCompact })
    }

    return (
        <NarrowPage>
            <Toolbar>
                <ToolbarItem>
                    <Title>User#{user.id}</Title>
                </ToolbarItem>
                <div style={{ flex: '1' }} />
            </Toolbar>

            <Title level={2}>User</Title>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
                <Descriptions.Item label="Username">{user.username}</Descriptions.Item>
                <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                <Descriptions.Item label="Anonymous">{user.anonymous}</Descriptions.Item>
                <Descriptions.Item label="Created">{user.created}</Descriptions.Item>
            </Descriptions>

            <Title level={2}>Session</Title>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="ID">{session.id}</Descriptions.Item>
                <Descriptions.Item label="IP Address">{session.remoteIp}</Descriptions.Item>
                <Descriptions.Item label="Created">{session.created}</Descriptions.Item>
            </Descriptions>

            <Title level={2}>Settings</Title>
            <div>
                <Typography.Text>Dark: </Typography.Text>
                <Switch checked={appCtx?.settings.themeName === 'dark'} onChange={handleThemeChange} />
            </div>
            <div>
                <Typography.Text>Compact: </Typography.Text>
                <Switch checked={settingsSignal.value.compact} onChange={handleCompactChange} />
            </div>
        </NarrowPage>
    )
}

export default ProfilePage
