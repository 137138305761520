import styled from 'styled-components'

/**
 * Thought antd would have a component for this.. a top bar that can take a list of items, like the sencha Toolbar
 */
export const Toolbar: React.FC<{
    children: React.ReactNode
}> = (props) => {
    return <ToolbarWrapper className="toolbar">{props.children}</ToolbarWrapper>
}

export const ToolbarItem: React.FC<{
    children: React.ReactNode
}> = (props) => {


    return <div className={`toolbar-item`}>{props.children}</div>
}

const ToolbarWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-top: 0px;
    align-items: center; // Add this line to vertically align the items in the middle
    .toolbar-item:first-child {
        padding-left: 0px;
    }
    .toolbar-item:last-child {
        padding-right: 0px;
    }
    .toolbar-item {
        padding: ${(props) => props.theme.antd.padding / 2}px;
        
        h1 {
            margin-bottom: 0px !important;
            font-size: 14px !important;
        }
    }
`
