import { Button } from 'antd'
import React from 'react'
import { FullHeightTablePageWrapper } from '../components/pages'
import PortfoliosTable from './portfolios/PortfoliosTable'
import { Toolbar, ToolbarItem } from '../components/Toolbar'
import { ReloadOutlined } from '@ant-design/icons'

const PortfoliosPage: React.FC<{}> = (props) => {
    const [lastRefresh, setLastRefresh] = React.useState<Date>()

    //const appCtx = useContext(DeskContext)
    //const navigate = useNavigate()

    const handleReloadClicked = () => {
        setLastRefresh(new Date())
    }

    /*
    const handleNewPortfolio = async () => {
        console.log('new portfolio')
        setShowCreatingPortfolioSpinner(true)

        const request = mts.portfolio.AddPortfolioRequest.create({})

        const addPortfolioResponse = await deskService.fetchProtobufOld<mts.portfolio.AddPortfolioResponse>(
            `${appCtx?.config.portfolioAddr}/portfolios`,
            mts.portfolio.AddPortfolioResponse.decode,
            {
                method: 'POST',
                body: mts.portfolio.AddPortfolioRequest.encode(request).finish(),
            },
        )
        console.log('addPortfolioResponse', addPortfolioResponse)
        const portfolio = addPortfolioResponse.portfolio

        navigate(`/portfolios/${portfolio?.id}`)
    }
    */

    return (
        <FullHeightTablePageWrapper>
            <Toolbar>
                <ToolbarItem>
                    <Button onClick={handleReloadClicked}>
                        <ReloadOutlined />
                    </Button>
                </ToolbarItem>
                {/*
                <ToolbarItem>
                    <Button onClick={handleNewPortfolio} loading={showCreatingPortfolioSpinner}>
                        New Portfolio
                    </Button>
                </ToolbarItem>*/}
            </Toolbar>
            <PortfoliosTable lastLoaded={lastRefresh} />
        </FullHeightTablePageWrapper>
    )
}

export default PortfoliosPage
