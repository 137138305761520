import { Alert, Button, Descriptions, Popover, Tag, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import { Config, DeskContext } from '../../App'
import { Toolbar, ToolbarItem } from '../../components/Toolbar'
import { Page } from '../../components/pages'
import { mts } from '../../desk_protos'
import { CreateInstanceModal } from './CreeateInstanceModal'
import Markdown from 'react-markdown'
import { RunBacktestButton } from './RunBacktestButton'
import { QuestionCircleOutlined, QuestionOutlined } from '@ant-design/icons'

const { Item } = Descriptions
//type Status = "open" | "closed" | "connecting"

export const TraderPage: React.FunctionComponent<{}> = (props) => {
    const { traderId } = useParams()

    const [createInstanceModalOpen, setCreateInstanceModalOpen] = useState(false)
    const [error, setError] = React.useState<string | null>(null)
    const [trader, setTrader] = useState<mts.desk.Trader>()
    const [loading, setLoading] = React.useState(true)
    const [sseStatus, setSseStatus] = React.useState('Not Connected')
    const [sseEnabled, setSseEnabled] = React.useState(false)

    const navigate = useNavigate()
    const appCtx = React.useContext(DeskContext)

    //const [icon, setIcon] = React.useState(<PlayCircleFilled />)

    const { user } = appCtx

    const handleMessage = useCallback((ev: MessageEvent) => {
        console.log('Recv SSE Event: ', ev)
        const obj = JSON.parse(ev.data)
        console.log('Parsed JSON', obj)
        const msg = mts.desk.http.GetTraderUpdatesResponse.fromObject(JSON.parse(ev.data))

        console.log('handleSseMessage()', msg)
        switch (msg.updateType) {
            case 'trader':
                setTrader(msg.trader as mts.desk.Trader)
                break
        }

        console.log('<message', msg)
    }, [])

    useEffect(() => {
        if (!sseEnabled) {
            return
        }

        const handleError = (ev: Event) => {
            console.log(`Error`, ev)
            setError(`Failed to connect to SSE`)
            setSseStatus(`Error`)
        }

        const handleOpen = (ev: Event) => {
            setSseStatus('Connected')
        }

        const sseUrl = `${Config.DESK_ADDR}/trader-events/${traderId}`

        console.log(`Opening EventSource[${sseUrl}]`)
        const es = new EventSource(sseUrl)
        es.onerror = handleError
        es.onmessage = handleMessage
        es.onopen = handleOpen

        return () => {
            console.log('Closing eventSource')
            es.close()
        }
    }, [handleMessage, sseEnabled, traderId])

    const load = useCallback(async () => {
        const traderUrl = `${Config.DESK_ADDR}/traders/${traderId}`
        console.log(`Loading Trader from ${traderUrl}`)
        try {
            const resp = await fetch(traderUrl, {
                headers: {
                    Accept: 'application/protobuf',
                },
            })
            setLoading(false)
            if (resp.ok) {
                const data = await resp.arrayBuffer()
                const trader = mts.desk.Trader.decode(new Uint8Array(data))
                console.log('Got', trader)
                setTrader(trader as mts.desk.Trader)
            } else {
                setError(`Failed to fetch trader: Got ${resp.status} from ${traderUrl}`)
            }
        } catch (err) {
            setError(`Failed to fetch traders from ${traderUrl} - ${err}`)
        }
    }, [traderId])

    useEffect(() => {
        load()
    }, [load])

    if (error) {
        return <Alert message={error} type="error" />
    }
    if (!trader || loading) {
        return <></>
    }

    const handleEditClick = () => {
        navigate(`/traders-editor/${trader.id}`)
    }

    const handleCreateInstanceClick = () => {
        setCreateInstanceModalOpen(true)
    }

    const handleCreateInstanceModalClose = () => {
        console.log('close')
        setCreateInstanceModalOpen(false)
    }

    const canEdit = user.id === trader.userId

    return (
        <Page>
            <CreateInstanceModal
                open={createInstanceModalOpen}
                trader={trader}
                onClose={handleCreateInstanceModalClose}
            />

            <Toolbar>
                <ToolbarItem>
                    <h1>
                        <Link to="/traders">Traders </Link> : {trader.name}
                    </h1>
                </ToolbarItem>
                <div style={{ flex: '1' }} />

                <ToolbarItem>
                    <Button onClick={handleCreateInstanceClick}>Create Instance</Button>
                </ToolbarItem>
                <ToolbarItem>
                    <RunBacktestButton trader={trader} disabled={false} />
                </ToolbarItem>
                {canEdit && (
                    <ToolbarItem>
                        <Button onClick={handleEditClick}>Edit</Button>
                    </ToolbarItem>
                )}
            </Toolbar>

            {trader.error && <Alert message="Error" description={trader.error} type="error" showIcon />}

            <Descriptions bordered column={1} style={{ maxWidth: '1000px' }}>
                <Item label="Name">{trader.name}</Item>
                <Item label="Id">{trader.id}</Item>
                <Item label="Owner">{user.username}</Item>
                <Item label="Access">{mts.desk.Access[trader.access]}</Item>
                <Item label="Tick Interval">
                    {trader.tickInterval + ''} {mts.desk.TimeUnit[trader.tickIntervalUnit]}
                    &nbsp;
                    <QuestionPopover
                        content="Tick Interval is the frequency in which the Bot can do work. If building a 
                        Bot that trades daily a 1 day interval is appropriate. If your Bot trades using 5 
                        minute candles then use a 5 minute interval"
                    />
                </Item>
                <Item label="Labels">
                    {Object.keys(trader.labels).map((key) => (
                        <Tag key={key}>{key}</Tag>
                    ))}
                </Item>
                <Item label="Description">
                    <Markdown>{trader.description}</Markdown>
                </Item>
            </Descriptions>
        </Page>
    )
}

export default TraderPage

// A react component that displays a question mark icon that when clicked displays a popover with a title and content.
// using the QuestionCircleOutlined from antd and styled to provide some css
// childrene are passed in as props and displayed in the popover
export const QuestionPopover: React.FunctionComponent<{ content: string }> = (props) => {
    return (
        <Popover
            rootClassName="mts-question-popover"
            content={<Typography.Paragraph style={{ width: '200px' }}>{props.content}</Typography.Paragraph>}
        >
            <QuestionCircleOutlined style={{ fontSize: '14px' }} />
        </Popover>
    )
}
