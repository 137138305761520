import { useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { Alert, Button, DatePicker, Form, Input, Modal, Space, Typography, message } from 'antd'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { useForm } from 'antd/es/form/Form'
import { DateTime } from 'luxon'
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon'
import { useContext, useEffect, useState } from 'react'
import { DeskContext, deskService } from '../../App'
import { GuidePopover } from '../../components/GuidePopover'
import { OptionValueItemsForm } from '../../components/instances/OptionValueItemsForm'
import { mts } from '../../desk_protos'
import { OptionValues } from '../instances/TraderInstancePage'
import { useNavigate } from 'react-router-dom'
import { useLoggedInUser } from './InstanceSummaryMobilePage'

const LuxonDatePicker = DatePicker.generatePicker<DateTime>(luxonGenerateConfig)

/**
 * If props.instance is preset, option values will be set to the instance's option values
 */
export const InstanceNotificationsModal: React.FC<{
    open: boolean
    onClose: () => void
    instanceId: string
}> = (props) => {
    useSignals()

    const [error, setError] = useState('')
    const [instance, setInstance] = useState<mts.desk.TraderInstance>()
    const [userInstanceNotificationSettings, setUserInstanceNotificationSettings] =
        useState<mts.desk.UserInstanceNotificationSetting[]>()
    const [saving, setSaving] = useState(false)
    const [open, setOpen] = useState(props.open)
    const navigate = useNavigate()
    const loggedInUser = useLoggedInUser()

    const [form] = useForm()
    const appCtx = useContext(DeskContext)

    const redirect = useSignal(true)

    // Load instance
    useEffect(() => {
        if (props.instanceId) {
            deskService
                .getInstance(props.instanceId, false)
                .then((resp) => {
                    setInstance(resp.instance as mts.desk.TraderInstance)
                })
                .catch((err) => {
                    setError(`Failed to fetch instance ${props.instanceId}: ${err}`)
                })
        }
    }, [props.instanceId])

    // Loading notification settings
    useEffect(() => {
        if (props.instanceId) {
            deskService
                .getUserInstanceNotificationSettings(props.instanceId)
                .then((resp) => {
                    const settings = resp.notificationSettings as mts.desk.UserInstanceNotificationSetting[]
                
                    setUserInstanceNotificationSettings(settings)
                })
                .catch((err) => {
                    setError(`Failed to fetch notification settings for instance ${props.instanceId}: ${err}`)
                })
        }
    }, [props.instanceId])

    // Update open from props.open
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleOk = () => {
        const fields = form.getFieldsValue()
        console.log('form field', fields)

        const notificationSettings: mts.desk.UserInstanceNotificationSetting[] = []

        // But a list of notification settings and save
        // if watchlist,email is selected, then add
        if (fields['watchlist,email']) {
            const setting = mts.desk.NotificationSetting.create({
                notificationMethod: mts.desk.NotificationSetting.NotificationMethod.EMAIL,
            })
            const instanceSetting = mts.desk.UserInstanceNotificationSetting.create({
                userId: loggedInUser.id,
                instanceId: props.instanceId,
                notificationSetting: setting,
                notificationType: mts.desk.UserInstanceNotificationSetting.NotificationType.WATCHLIST_CREATED
            })
            notificationSettings.push(instanceSetting)
        }
        if (fields['watchlist,web']) {
            const setting = mts.desk.NotificationSetting.create({
                notificationMethod: mts.desk.NotificationSetting.NotificationMethod.BROWSER,
            })
            const instanceSetting = mts.desk.UserInstanceNotificationSetting.create({
                userId: loggedInUser.id,
                instanceId: props.instanceId,
                notificationSetting: setting,
                notificationType: mts.desk.UserInstanceNotificationSetting.NotificationType.WATCHLIST_CREATED
            })
            notificationSettings.push(instanceSetting)
        }
        /*const watchlistEmail = fields['watchlist,email']
        const notificationSettingEmail = mts.desk.NotificationSetting.create({
            notificationMethod: mts.desk.NotificationSetting.NotificationMethod.EMAIL,
        })
        */
        deskService.putUserInstanceNotificationSettings(instance?.id as string, notificationSettings)
    }

    const handleClose = () => {
        console.log('handleClose()')
        setOpen(false)
        props.onClose()
    }

    return (
        <Modal
            title={`Instance Notifications for ${props.instanceId}`}
            onOk={handleOk}
            onCancel={handleClose}
            destroyOnClose={false}
            transitionName=""
            open={open}
            footer={[
                <Button key="submit" type="primary" loading={saving} onClick={handleOk}>
                    Ok
                </Button>,
            ]}
        >
            {error && <Alert message="Error" description={error} type="error" showIcon />}
            {instance && (
                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 25 }}
                    initialValues={instance.optionValues}
                    requiredMark={true}
                    form={form}
                    style={{ maxWidth: 1000 }}
                >
                    <Form.Item label="Email" name="email">
                        <Input />
                    </Form.Item>

                    {/* A table of which notifications to recieve, watchlists, orders, etc */}
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Email</td>
                                <td>Web</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>New Watchlists</td>
                                <td>
                                    <Form.Item name="watchlist,email" valuePropName="checked">
                                        <Checkbox />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item name="watchlist,web" valuePropName="checked">
                                        <Checkbox />
                                    </Form.Item>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            )}
        </Modal>
    )
}
