/* eslint-disable react/jsx-no-comment-textnodes */
import { Button, Modal, ModalFuncProps, Typography } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/es/typography/Title'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GuidePopover } from '../components/GuidePopover'
import { NarrowPage } from '../components/pages'

const Home = () => {
    const [modal, contextHolder] = Modal.useModal()

    // 3 horizontal and nicely styled responsive cards: Builders, Traders, Investors.
    const cards = [
        {
            title: 'For Builders',
            content: (
                <>
                    Build & test your own automated trading strategies without worrying about all the
                    infrastructure normally required like servers, data, live feeds, etc.
                    <ul>
                        <li>No software to install</li>
                        <li>Write a Bot that sends customized scans at regular intervals</li>
                        <li>Analyze backtest & realtime performance with the built-in Portfolio</li>
                    </ul>
                </>
            ),
            footer: (
                <GuidePopover storageKey="guide.home.closed" content="Run your first Trader Bot now">
                    <Link className="action" to="/traders">
                        Run your first Trading Bot &#8594;
                    </Link>
                </GuidePopover>
            ),
        },
        {
            title: 'For Traders',
            content: (
                <>
                    For Traders that do not have interest in developing trading bots. You can use bots that
                    others have developed to assist in your trading. i.e.,
                    <ul>
                        <li>A bot that smartly scales into a position at levels you've defined</li>
                        <li>
                            Generate and send a daily watchlist for the past 100 trading days of stocks that
                            crossed under $5
                        </li>
                    </ul>
                </>
            ),
        },
        {
            title: 'For Investors',
            content: (
                <>
                    Run longer term backtests on a daily, or weekly timeframe to test investing strategies.
                    MTSBots allows you to run multiple backtests and then compare the results.
                    <ul>
                        <li>Run a bot to mirror in your own portfolio</li>
                        <li>Backtest the impact of re-investing dividends</li>
                        <li>Backtest investing 1k/month into the S&P500, but only on a dip of 1% or more</li>
                        <li>Compare a balanced portfolio of stocks vs a portfolio of ETFs</li>
                    </ul>
                </>
            ),
        },
    ]

    const cardsEl = cards.map((card, i) => (
        <div key={i} className="card">
            <Title level={2}>{card.title}</Title>
            <div className="body">
                <Typography.Paragraph>{card.content}</Typography.Paragraph>
            </div>
            <div className="footer">{card.footer}</div>
        </div>
    ))

    const modalConfig: ModalFuncProps = {
        title: 'Contact',
        content: (
            <Paragraph>
                <Typography.Text>email: </Typography.Text>
                <Typography.Link href="mailto:tfishwick@gmail.com">tfishwick@gmail.com</Typography.Link>
            </Paragraph>
        ),
    }

    const showContactModal = () => {
        modal.info(modalConfig)
    }

    return (
        <StyledNarrowPage>
            {contextHolder}
            <Title className="mts-title">Market Trading Systems<br/>TRADING BOTS</Title>

            <Paragraph className="description">
                Build, backtest, run and share Trading Bots.{' '}
                <br/>
                <i className="note">
                    (A Trading Bot can also generate watchlists to share, send alerts, perform scans).
                </i>
            </Paragraph>

            <StyledCards>{cardsEl}</StyledCards>

            

            <Footer>
                <Typography.Paragraph>
                    &copy; {DateTime.now().year} Market Trading Systems -{' '}
                    <Link onClick={showContactModal} to={''}>
                        Contact
                    </Link>
                </Typography.Paragraph>
            </Footer>
        </StyledNarrowPage>
    )
}

const Footer = styled.div`
    margin-top: 20px;
    text-align: right;
`

const StyledNarrowPage = styled(NarrowPage)`
    ul {
        margin-left: 20px;
    }
    h1 {
        font-size: 30px !important;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    .mts-title {
        text-align: center;
        font-size: 20px !important;
    }
    .description {
        .note {
            font-size: smaller;
        }
    }
`

// Style for cards, which are 3 horizontal and nicely styled responsive cards: Builders, Traders, Investors.
const StyledCards = styled.div`
    display: flex;
    flex-wrap: wrap; // Add this line to allow the cards to wrap to the next line
    justify-content: center;
    margin: 0 auto;

    .card:first-child {
        margin-left: 0;
    }

    .card:last-child {
        margin-right: 0;
    }

    .card {
        flex: 1;
        flex-basis: 300px; // Add this line to set the initial width for each card

        border: 1px solid #ddd;
        border-radius: 5px;
        margin: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding: ${(props) => props.theme.antd.padding * 1.5}px;

        .body {
        }

        h2 {
            margin-bottom: 10px;
            margin-top: 0px;
        }

        ul {
            margin-left: 10px; // Reduce the left margin to 10px
        }
        .action {
            padding: 10px;
            border-radius: 5px;
            text-decoration: none;
            margin-top: 10px;
        }

        .footer {
            display: flex;
            justify-content: flex-end;
        }
    }

    @media (max-width: 768px) {
        .card {
            margin: 10px 0; // Remove the horizontal margin on mobile devices
        }
    }
`

export default Home
