import { CaretRightFilled } from '@ant-design/icons'
import { Button, Select } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'
import { DeskContext, deskService, snippetsManager } from '../App'
import { Toolbar, ToolbarItem } from '../components/Toolbar'
import { FullHeightFlexPage } from '../components/pages'
import { SnippetFile } from '../data/SnippetsManager'
import { mts } from '../desk_protos'
import { JavascriptSetting, ScriptExamplesDropdown } from './traders/editor/OptionsForm'
import { visit } from 'unist-util-visit'

const Help: React.FC<{
    onRun: (code: string) => void
}> = (props) => {
    const [markdown, setMarkdown] = useState('')

    useEffect(() => {
        const fetchMarkdown = async () => {
            try {
                const response = await fetch('/docs/api.md')
                const data = await response.text()
                setMarkdown(data)
            } catch (error) {
                console.error('Error fetching markdown:', error)
            }
        }

        fetchMarkdown()
    }, [])

    return (
        <StyledMarkdown
            className="help"
            components={{
                // Map `h1` (`# heading`) to use `h2`s.
                h1: 'h1',
                // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                em(props2) {
                    const { node, ...rest } = props2
                    return <i style={{ color: 'red' }} {...rest} />
                },
                // Rewrite code blocks to include a run link
                code(props2) {
                    console.log('props', props2)
                    if (props2.className === 'language-api-snippet') {
                        const { node, ...rest } = props2
                        return (
                            <pre className="can-run">
                                {props2.children}
                                <Button
                                    className="run"
                                    type="primary"
                                    onClick={() => props.onRun(props2.children as string)}
                                >
                                    <CaretRightFilled />
                                    Run
                                </Button>
                            </pre>
                        )
                    }

                    return <code {...props2} />
                },
            }}
        >
            {markdown}
        </StyledMarkdown>
    )
}

/**
 * Style for all markdown components
 */
const StyledMarkdown = styled(Markdown)`
    pre.can-run {
        padding: ${(props) => props.theme.antd.padding}px;

        background-color: ${(props) => props.theme.antd.colorFillSecondary} !important;
        position: relative;
        button.run {
            position: absolute;
            right: ${(props) => props.theme.antd.padding}px;
            top: ${(props) => props.theme.antd.padding}px;
            background-color: green;
            color: white;
        }
        button.run:hover {
            background-color: darkgreen;
        }
    }
`

/**
 * Display results of a run. This means a compilation error, stdout, etc.
 */
const Console: React.FC<{ content?: string }> = (props) => {
    const ref = useRef<HTMLParagraphElement | null>(null)
    //const [content, setContent] = useState('')

    useEffect(() => {
        if (!ref || !ref.current) {
            return
        }
        // setContent(props.runResponse?.stdout.replaceAll("\n", "<br/>"))
        const s = props.content?.replaceAll('\n', '</div><div>')
        ref.current.innerHTML = '<div>' + s + '</div>'
    }, [props.content])

    return (
        <div className="console">
            <Paragraph ref={ref}></Paragraph>
        </div>
    )
}

/**
 * A snippets component that is a dropdown of snippets that can be inserted into the editor.
 * Ths snippets are stored in localstorage.
 */
export const MySnippetsDropdown: React.FC<{
    onSnippetFileSelect: (snippet: SnippetFile) => void
    label: string
}> = (props) => {
    const [snippetFiles] = useState<SnippetFile[]>(snippetsManager.getAll())
    const [selectedSnippetFile, setSelectedSnippetFile] = useState<SnippetFile | undefined>()

    // On mount, select the first snippet
    useEffect(() => {
        let selectedSnippet = snippetFiles[0]
        setSelectedSnippetFile(selectedSnippet)
        props.onSnippetFileSelect(selectedSnippet)
    }, [])

    const handleSelect = (name: any) => {
        const snippetFile = snippetFiles.find((s) => s.name === name)
        if (!snippetFile) {
            throw new Error(`Unknown snippet with name ${name}`)
        }
        setSelectedSnippetFile(snippetFile)
        props.onSnippetFileSelect(snippetFile)
    }

    // List of menu items for the script downdown with field name -> key, and label -> key
    const options = snippetFiles.map((snippet) => ({
        label: snippet.name,
        value: snippet.name,
    }))

    return (
        <div>
            <Select
                style={{ width: 200 }}
                placeholder={'My snippets'}
                options={options}
                value={selectedSnippetFile?.name}
                onSelect={handleSelect}
            ></Select>
        </div>
    )
}

const SandboxPage: React.FC<{}> = (props) => {
    const [code, setCode] = useState('')
    const [consoleContent, setConsoleContent] = useState('')
    const [running, setRunning] = useState(false)
    const [runResponse, setRunResponse] = useState<mts.desk.http.PostScriptResponse>()
    const [selectedSnippetFile, setSelectedSnippetFile] = useState<SnippetFile>()
    const ctx = useContext(DeskContext)

    const handleRunResponse = (response: mts.desk.http.PostScriptResponse) => {
        console.log('PostScriptResponse', response)
        setConsoleContent(response.stdout)
        setRunResponse(response)
        setRunning(false)
    }

    // Run script handler
    const handleRunClick = () => {
        setRunning(true)

        const request = mts.desk.http.PostScriptRequest.create({
            code,
        })
        console.log(`Sending run script request`, request)
        deskService
            .fetchProtobufOld<mts.desk.http.PostScriptResponse>(
                `${ctx.config.deskAddr}/scripts`,
                mts.desk.http.PostScriptResponse.decode,
                {
                    body: mts.desk.http.PostScriptRequest.encode(request).finish(),
                    method: 'POST',
                }
            )
            .then(handleRunResponse)
            .catch((err) => {
                if (err instanceof mts.common.ApiError) {
                    console.log('got ApiError', err)
                    const apiError = err as mts.common.ApiError
                    setConsoleContent(`<font color='red'>${apiError.detail}</font>`)
                } else {
                    console.log('got some other type of err', err)
                    setConsoleContent(`<font color='red'>Unknown server error</font>`)
                }
                setRunning(false)
            })
    }

    const updateSnippetFile = (snippetFile: SnippetFile) => {
        snippetsManager.update(snippetFile)
    }

    const updateSelectedSnippetFileDebounced = useCallback(
        debounce((snippetFile: SnippetFile, code: string) => {
            snippetFile.code = code
            updateSnippetFile(snippetFile)
        }, 1000),
        []
    )

    // When code is updated, save the snippet
    useEffect(() => {
        if (!selectedSnippetFile) {
            return
        }
        updateSelectedSnippetFileDebounced(selectedSnippetFile, code)
    }, [code])

    // when selected snippet is changed, load the code
    useEffect(() => {
        if (!selectedSnippetFile) {
            return
        }
        setCode(selectedSnippetFile.code)
    }, [selectedSnippetFile])

    const handleInsertSnippet = (snippet: SnippetFile) => {
        console.log('handleInsertSnippet', snippet)
        setCode(code + '\n' + snippet.code)
    }

    const handleReplaceSnippet = (snippet: SnippetFile) => {
        console.log('handleReplaceSnippet', snippet)
        setCode(snippet.code)
    }

    const handleSelectSnippetFile = useCallback((snippet: SnippetFile) => {
        console.log('handleSelectSnippetFile', snippet)
        setSelectedSnippetFile(snippet)
    }, [])

    const handleRunFromHelp = (code: string) => {
        setCode(code)
        handleRunClick()
    }

    const left = (
        <div className="left">
            <Toolbar>
                <ToolbarItem>
                    <MySnippetsDropdown onSnippetFileSelect={handleSelectSnippetFile} label="My Snippets" />
                </ToolbarItem>

                <div style={{ flex: 1 }}></div>
                <ToolbarItem>
                    <ScriptExamplesDropdown
                        label="Replace with snippet..."
                        onSnippetSelect={handleReplaceSnippet}
                    />
                </ToolbarItem>
                <ToolbarItem>
                    <ScriptExamplesDropdown label="Insert snippet..." onSnippetSelect={handleInsertSnippet} />
                </ToolbarItem>
                <ToolbarItem>
                    <Button
                        type="primary"
                        className="mts-run-btn"
                        loading={running}
                        onClick={handleRunClick}
                        disabled={running}
                    >
                        <CaretRightFilled />
                        Run
                    </Button>
                </ToolbarItem>
            </Toolbar>
            <JavascriptSetting onChange={setCode} code={code} />
        </div>
    )

    return (
        <FullHeightFlexPage>
            <Wrapper>
                {left}
                <div className="right">
                    <div className="topbar">
                        <div> in {runResponse?.elapsedTimeMillis} millis</div>
                        <div style={{ flex: 1 }}></div>
                        <Button onClick={handleRunClick} disabled={running}>
                            Clear
                        </Button>
                    </div>
                    <Console content={consoleContent} />
                    <Help onRun={handleRunFromHelp} />
                </div>
            </Wrapper>
        </FullHeightFlexPage>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: horizontal;

    .left {
        flex: 1;
        flex-direction: column;
        display: flex;
        max-width: 800px;
        .react-monaco-editor-container {
            border: 2px solid ${(props) => props.theme.antd.colorBorder};
        }
    }
    .right {
        flex: 1;
        flex-direction: column;
        display: flex;
        .console {
            padding: ${(props) => props.theme.antd.padding / 2}px;
            font-family: monospace;

            overflow: auto;
            flex-grow: 1;
            flex-basis: 0;
            border-bottom: 5px solid ${(props) => props.theme.antd.colorBorder};
        }
        .help {
            flex: 1;
            padding: ${(props) => props.theme.antd.padding / 2}px;
            overflow-y: scroll;
            flex-grow: 1;
            flex-basis: 0;
        }
    }

    .console.error {
        color: red;
    }

    .topbar {
        padding: ${(props) => props.theme.antd.padding / 2}px;
        flex: 0;
        text-align: right;
        display: flex;
    }
    .topbar > div {
        display: inline-block;
    }
`

export default SandboxPage
