import { useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { Alert, Button, DatePicker, Form, Modal, Space, Typography, message } from 'antd'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { useForm } from 'antd/es/form/Form'
import { DateTime } from 'luxon'
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon'
import { useContext, useEffect, useState } from 'react'
import { DeskContext, deskService } from '../../App'
import { GuidePopover } from '../../components/GuidePopover'
import { OptionValueItemsForm } from '../../components/instances/OptionValueItemsForm'
import { mts } from '../../desk_protos'
import { OptionValues } from '../instances/TraderInstancePage'
import { useNavigate } from 'react-router-dom'

const LuxonDatePicker = DatePicker.generatePicker<DateTime>(luxonGenerateConfig)

/**
 * If props.instance is preset, option values will be set to the instance's option values
 */
export const RunBacktestModal: React.FC<{
    trader: mts.desk.Trader
    open: boolean
    onClose: () => void
    instance?: mts.desk.TraderInstance
}> = (props) => {
    useSignals()

    const [error, setError] = useState('')
    const [trader, setTrader] = useState(props.trader)
    const [saving, setSaving] = useState(false)
    const [open, setOpen] = useState(props.open)
    const navigate = useNavigate()

    const [form] = useForm()
    const appCtx = useContext(DeskContext)

    const redirect = useSignal(true)
    const options = trader?.options
    const optionValuesSignal = useSignal({})

    // Store "redirect" value in local storage
    useEffect(() => {
        const stored = localStorage.getItem('mts-backtest-redirect')
        if (stored) {
            redirect.value = JSON.parse(stored)
        }
    }, [redirect])

    useEffect(() => {
        localStorage.setItem('mts-backtest-redirect', JSON.stringify(redirect.value))
    }, [redirect])

    // Update open from props.open
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])
    
    // Set option values from props.instance if set
    useEffect(() => {
        console.debug('RunBacktestModal useEffect', props.instance)
        if (props.instance) {
            console.log('Setting option values from instance', props.instance)
            optionValuesSignal.value = props.instance.optionValues

            const from = DateTime.fromISO(props.instance.from)
            const to = DateTime.fromISO(props.instance.to)
            form.setFieldValue('date', [from, to])
        }
    }, [form, optionValuesSignal, props.instance])

    useEffect(() => {
        //console.log("props.trader updated", props.traders)
        setTrader(props.trader)
    }, [props.trader])

    if (!trader && props.open) {
        console.error('props.trader cannot be undefined when props.open=true')
        return <></>
    }
    if (!trader) {
        return <></>
    }

    // Create a trader instance request per trader and save
    // TODO: submit in one batch to a single endpoint
    const runBacktest = async () => {
        try {
            console.log('Saving backtest', optionValuesSignal.value)

            // coerce option values to string: string
            const optionValues = Object.fromEntries(
                Object.entries(optionValuesSignal.value).map(([key, value]) => [key, String(value)])
            )

            const values = form.getFieldsValue()
            console.debug('From values', values)
            if (!appCtx) {
                throw new Error('We need the app context to be set...')
            }

            const fromDate = values.date[0] as DateTime
            // toDate is values.date[1] with time at end of day
            const toDate = (values.date[1] as DateTime).endOf('day')

            const newTraderInstance = mts.desk.TraderInstance.create({
                traderId: trader.id,
                mode: mts.desk.TraderInstance.Mode.BACKTEST,
                from: fromDate.toISO({ includeOffset: false }) + 'Z',
                to: toDate.toISO({ includeOffset: false }) + 'Z',
                userId: appCtx.session.userId,
                optionValues: optionValues,
            })

            console.log('backtest instance to be added', newTraderInstance)

            const resp = await deskService.addTraderInstance(appCtx, newTraderInstance)
            const traderInstance = resp?.traderInstance as mts.desk.ITraderInstance
            const url = `/instances/${traderInstance.id}`

            message.info(`Created TraderInstance#${traderInstance.id}`)

            // Open in a new tab if redirect is true
            if (redirect.value) {
                window.open(url, '_blank')
                
                props.onClose()
            // Otherwise, navigate to the new instance in same tab
            } else {
                //message.info((<div>Created <Link to={url}>TraderInstance#{traderInstance.id}</Link></div>))
                navigate(url)
                props.onClose()
            }
        } catch (err) {
            console.error('Got error', err)
            if (err instanceof mts.common.ApiError) {
                const { code, detail, description } = err as mts.common.ApiError
                setError(`Failed to add instance: (${code}-${description}): ${detail}`)
            } else {
                setError(`Unknown error: ${JSON.stringify(err)}`)
            }
        } finally {
            setSaving(false)
        }
    }

    const handleOk = () => {
        form.validateFields()
            .then(runBacktest)
            .catch((err) => {})
    }

    const handleClose = () => {
        console.log('handleClose()')
        setOpen(false)
        props.onClose()
    }

    const setDateRange = (from: DateTime, to: DateTime) => {
        form.setFieldValue('date', [from, to])
    }

    const now = DateTime.now()

    const setYearRange = (year: number) => {
        setDateRange(DateTime.fromISO(`${year}-01-01`), DateTime.fromISO(`${year}-12-31T23:59:59Z`))
    }

    // ytd is jan 1 until now unless the time of day is before 9pm (america/New_york) then it's the previous day
    const ytd =
        DateTime.now().setZone('America/New_York').hour < 21
            ? DateTime.now().minus({ days: 1 })
            : DateTime.now()

    const handleYTDPreset = () => {
        setDateRange(now.startOf('year'), ytd)
    }

    const presets = (
        <Space>
            <Typography.Link onClick={() => setDateRange(now.minus({ days: 1 }), now)}>Yesterday</Typography.Link>
            <Typography.Link onClick={handleYTDPreset}>YTD</Typography.Link>
            <Typography.Link onClick={() => setYearRange(now.year - 1)}>{now.year - 1}</Typography.Link>
            <Typography.Link onClick={() => setYearRange(now.year - 2)}>{now.year - 2}</Typography.Link>
            <Typography.Link onClick={() => setYearRange(now.year - 3)}>{now.year - 3}</Typography.Link>
        </Space>
    )

    const handleOptionValuesChange = (values: OptionValues) => {
        console.log('Option values changed', values)
        optionValuesSignal.value = values
    }

    return (
        <Modal
            title={`Run Backtest for "${trader.name}"`}
            onOk={handleOk}
            onCancel={handleClose}
            destroyOnClose={false}
            transitionName=""
            open={open}
            footer={[
                <Checkbox
                    key="redirect"
                    onChange={(e) => (redirect.value = e.target.checked)}
                    checked={redirect.value}
                >
                    Open in new tab
                </Checkbox>,
                <Button key="back" onClick={handleClose}>
                    Close
                </Button>,

                <GuidePopover
                    key="guide"
                    storageKey="backtestmodal"
                    content="Choose a Date or preset, then click Run"
                >
                    <Button key="submit" type="primary" loading={saving} onClick={handleOk}>
                        Run
                    </Button>
                </GuidePopover>,
            ]}
        >
            <Form
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 25 }}
                initialValues={{}}
                requiredMark={true}
                form={form}
                style={{ maxWidth: 1000 }}
            >
                <Form.Item name="date" label="Date" rules={[{ required: true }]} extra={presets}>
                    <LuxonDatePicker.RangePicker />
                </Form.Item>
            </Form>

            <OptionValueItemsForm
                options={options}
                optionValues={optionValuesSignal.value}
                onOptionValuesChange={handleOptionValuesChange}
            />

            {error && <Alert message="Error" description={error} type="error" showIcon />}
        </Modal>
    )
}
