import { CloseOutlined } from '@ant-design/icons'
import { useSignals } from '@preact/signals-react/runtime'
import { Drawer } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { userSignal } from '../App'
import ProfilePanel from '../pages/ProfilePanel'
import { useLoggedInUser } from '../pages/instances/InstanceSummaryMobilePage'

export const ProfileIcon: React.FC = () => {
    useSignals()
    const loggedInUser = useLoggedInUser()

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseClick = () => {
        setOpen(false)
    }

    return (
        <>
            <Wrapper onClick={handleClick}>{loggedInUser.username.substring(0, 2).toUpperCase()}</Wrapper>

            <Drawer
                title=<div style={{ display: 'flex' }}>
                    <div style={{ flex: 1}}>{userSignal.value?.username}</div>
                    <CloseOutlined onClick={handleCloseClick}/>
                    </div>
                placement="right"
                closable={false}
                onClose={handleClose}
                open={open}
                key="right"
            >
                <ProfilePanel />
            </Drawer>
        </>
    )
}

export default ProfileIcon

const Wrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #007bff; /* Blue color */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: auto; /* Add this line to vertically center the icon */
    margin-right: ${(props) => props.theme.antd.padding}px;
    :hover {
        background-color: #0056b3; /* Darker blue on hover */
    }
    
`
