import React from 'react'
import { mts } from '../../desk_protos'
import Table, { ColumnsType } from 'antd/es/table'
import { Formatters } from '../../components/utils/formatters'
import { DateTime } from 'luxon'
import { Config } from '../../App'

type Tx = mts.portfolio.Tx

/**
 * Transactions table. Initially load all transactions in descending order, use SSE to update.
 *
 * http://api.mtsbots.com/portfolios
 */
const TransactionsTable: React.FC<{
    portfolio: mts.portfolio.Portfolio
}> = (props) => {
    const { portfolio } = props
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<string>('')
    let [transactions, setTransactions] = React.useState<Tx[]>([])

    // Listen to updates using sse. Handles two types of messages:
    React.useEffect(() => {
        // Load all transactions via a plain http call
        const load = async () => {
            const url = `${Config.PORTFOLIO_ADDR}/portfolios/${portfolio.id}/transactions`

            try {
                const resp = await fetch(url, {
                    headers: {
                        Accept: 'application/protobuf',
                    },
                })
                const data = await resp.arrayBuffer()
                const transactionsResp = mts.portfolio.GetTransactionsResponse.decode(new Uint8Array(data))
                const t = transactionsResp.transactions as Tx[]
                console.log('setTransactions: ', t)
                setTransactions(t)
                setLoading(false)
                //sse()
            } catch (err) {
                console.log('err', err)
                const msg = `Failed to fetch traders from ${url} - ${err}`
                //message.error(`Failed to fetch traders from ${url} - ${err}`)
                setError(String(msg))
            }
        }
        load()
    }, [portfolio.id])

    const columns: ColumnsType<Tx> = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value) => mts.portfolio.TxType[value],
        },
        { title: 'Symbol', dataIndex: 'symbol', key: 'symbol' },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (value, record) => {
                if (record.type === mts.portfolio.TxType.SALE) {
                    return value
                }
                return ''
            },
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => Formatters.humanDate(DateTime.fromISO(date)),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (value) => value,
        },
        {
            title: 'Comm.',
            dataIndex: 'commission',
            key: 'commission',
            render: (value) => value,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (value) => value,
        },
        {
            title: 'Cash',
            dataIndex: 'cash',
            key: 'cash',
            render: (value) => value,
        },
        {
            title: 'Trade ID',
            dataIndex: 'tradeId',
            key: 'tradeId',
            render: (value) => value,
        },
        {
            title: 'Gain',
            dataIndex: 'gain',
            key: 'gain',
            render: (value) => value,
        },
        {
            title: 'Gain %',
            dataIndex: 'gainPercent',
            key: 'gainPercent',
            render: (value) => `${value}%`,
        },
    ]

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <>
            <Table loading={loading} rowKey="id" key="id" columns={columns} dataSource={transactions} />
        </>
    )
}

export default TransactionsTable
