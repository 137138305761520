import { useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { Alert, Button, Card, Descriptions, Dropdown, Modal, Select, Typography, message } from 'antd'
import ErrorBoundary from 'antd/es/alert/ErrorBoundary'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Config, DeskContext, deskService } from '../../App'
import { Toolbar, ToolbarItem } from '../../components/Toolbar'
import { FullHeightFlexPage } from '../../components/pages'
import { PortfolioSummaryPanel } from '../../components/portfolio/PortfolioSummaryWidget'
import { mts } from '../../desk_protos'
import { EditOptionValuesModal } from './EditOptionValuesModal'
import { RunBacktestButton } from '../traders/RunBacktestButton'
import { canExecuteInstance, isEditOptionsVisible, useLoggedInUser } from './InstanceSummaryMobilePage'

const { Item } = Descriptions

const { State, Mode } = mts.desk.TraderInstance

function formatDuration(instance?: mts.desk.TraderInstance) {
    if (!instance) {
        return '-'
    }
    const d = durationMillis(instance)
    return d ? (d / 1000).toFixed(3) + ' seconds' : '-'
}

// Given an enum index return the name
export function enumName(enumType: any, value: any) {
    return enumType[value]
}

/**
 * Format instance date range - only valid if this is a backtest, otherwise return '-'.
 * If the tick interval is daily or greater then show yyyy-mm-dd format.
 * @param instance
 */
export function formatInstanceDateRange(instance: mts.desk.TraderInstance) {
    if (instance.mode === Mode.REALTIME) {
        return '-'
    }

    const from = DateTime.fromISO(instance.from)
    const to = DateTime.fromISO(instance.to)

    if (instance.trader?.tickIntervalUnit === mts.desk.TimeUnit.DAYS) {
        return `${from.toISODate()} - ${to.toISODate()}`
    }

    return `${from.toISO()} - ${to.toISO()}`
}

function formatOptionValues(trader: mts.desk.Trader, optionValues: OptionValues) {
    if (!optionValues) {
        return <>-</>
    }

    const options = trader.options
    const optionsByName = Object.fromEntries(options?.map((o) => [o.name, o]) || [])

    const renderOption = (optionName: string) => {
        var option = optionsByName[optionName]
        return (
            <>
                {option?.label}: {JSON.stringify(optionValues[optionName])}
            </>
        )
    }

    return (
        <>
            {Object.keys(optionValues).map((optionName, i) => (
                <div key={i}>{renderOption(optionName)}</div>
            ))}
        </>
    )
}

// TODO refactor, this is unclean
export type OptionValues = { [key: string]: any }

// Build dom element for a log entry
const buildLogEntry = (entry: mts.desk.TraderInstanceOutput.LogEntry): HTMLDivElement => {
    const dateDiv = document.createElement('div')
    dateDiv.className = 'log-date'
    const messageDiv = document.createElement('div')
    messageDiv.className = 'log-message'
    const time = DateTime.fromMillis(entry.time as number)
    dateDiv.innerHTML = time.toISO() as string
    messageDiv.innerHTML = entry.data as string

    const newLogEntry = document.createElement('div')
    newLogEntry.className = 'log-entry'
    newLogEntry.appendChild(dateDiv)
    newLogEntry.appendChild(messageDiv)
    return newLogEntry
}

export const TraderInstanceSummary: React.FunctionComponent<{}> = (props) => {
    useSignals()

    const { instanceId } = useParams()
    const appCtx = React.useContext(DeskContext)
    const [watchlists, setWatchlists] = useState<mts.desk.Watchlist[]>([])
    const [error, setError] = React.useState<string | null>(null)
    const [instance, setInstance] = useState<mts.desk.TraderInstance>()
    const [loading, setLoading] = React.useState(true)
    const [messageApi, contextHolder] = message.useMessage()
    const [state, setState] = useState<mts.desk.TraderInstance.State>()
    const [deleting, setDeleting] = useState(false)
    const navigate = useNavigate()
    const [stopping, setStopping] = useState(false)
    const [outputError, setOutputError] = useState<string>()
    const [duration, setDuration] = useState<string>('-')
    const logEntriesRef = useRef<HTMLDivElement>(null)
    const [stopButtonEnabled, setStopButtonEnabled] = useState(false)
    const loggedInUser = useLoggedInUser()

    const [optionValuesModalOpen, setOptionValuesModalOpen] = useState(false)
    const optionValuesSignal = useSignal<OptionValues>({})

    const { user } = appCtx

    // Instance updated side effects
    useEffect(() => {
        if (!instance) {
            return
        }

        const stateName = mts.desk.TraderInstance.State[instance?.state as mts.desk.TraderInstance.State]
        console.log(`instance updated, state=${stateName}, started=${instance?.started}`, instance)

        // Update duration
        setDuration(formatDuration(instance))
        setStopButtonEnabled(instance.state === State.RUNNING)
    }, [instance])

    // Update trader instance with new state
    useEffect(() => {
        if (!state) {
            return
        }
        messageApi.info('Instance state changed to ' + mts.desk.TraderInstance.State[state])

        // If new state is STOPPED then set stopping to false, and disable the stop button
        if (state === mts.desk.TraderInstance.State.STOPPED) {
            setStopping(false)
        }
    }, [messageApi, state])

    // Enable SSE when the instance id changes, OR disable permanently if state enters FINISHED
    useEffect(() => {
        const handleSseMessage = (ev: MessageEvent) => {
            const msg = mts.desk.http.GetTraderInstanceUpdatesResponse.fromObject(JSON.parse(ev.data))
            console.log(`Got instance update of ${msg.updateType} sequence ${msg.sequence}`, msg)

            switch (msg.updateType) {
                case 'traderInstance':
                    setInstance(msg.traderInstance as mts.desk.TraderInstance)
                    break
                case 'stateChange':
                    //let state = msg.stateChange?.state as mts.desk.TraderInstance.State
                    setState(msg.stateChange?.state as mts.desk.TraderInstance.State)
                    break
                case 'instanceOutput':
                    let newLogEntries = msg.instanceOutput?.logs as mts.desk.TraderInstanceOutput.LogEntry[]

                    let nodes = newLogEntries.map((entry) => buildLogEntry(entry))
                    logEntriesRef.current?.prepend(...nodes)

                    setDuration(formatDuration(instance))

                    const limit = 100

                    // Remove all entries too keep the count under the limit
                    if (logEntriesRef?.current) {
                        const div = logEntriesRef.current

                        let over = div.childElementCount - limit
                        if (limit > 0) {
                            for (let i = 0; i < over; i++) {
                                div.removeChild(div.lastChild as Node)
                            }
                        }
                    }

                    break
                case 'tickEvent':
                    // ignore for now, maybe update duration?
                    break
                case 'heartbeat':
                    // ignore
                    break
                default:
                    console.log('SSE unhandled message type', msg)
            }
        }

        const url = `${Config.DESK_ADDR}/traderinstances-events/${instanceId}`
        const handleError = (ev: Event) => {
            console.log(`Error`, ev)

            messageApi.warning(`Failed to connect to SSE(${url}; retrying automatically`)
        }

        const handleOpen = (ev: Event) => {}

        setError('')

        const es = new EventSource(url)
        es.onerror = handleError
        es.onmessage = handleSseMessage
        es.onopen = handleOpen

        return () => {
            console.log('Closing eventSource')
            es.close()
        }
    }, [instanceId, messageApi])

    // Loud output when traderInstance is updated
    useEffect(() => {
        if (!instance) {
            return
        }
        deskService
            .getInstanceOutput(instance)
            .then((resp) => {
                var logs = resp.instanceOutputs
                    .map((io) => io.logs as mts.desk.TraderInstanceOutput.LogEntry[])
                    .flat()
                console.log('Got output logs', logs)
                // Clear all children from logentriesref
                if (logEntriesRef?.current) {
                    logEntriesRef.current.innerHTML = ''
                }
                logEntriesRef.current?.append(...logs.map(buildLogEntry))
            })
            .catch((err) => setOutputError(err.message))
    }, [instance])

    // Load instance
    useEffect(() => {
        const load = async () => {
            deskService
                .getInstance(instanceId as string, true)
                .then((resp) => {
                    const instance = resp.instance as mts.desk.TraderInstance
                    setInstance(instance)
                    setWatchlists(resp.watchlists as mts.desk.Watchlist[])
                    optionValuesSignal.value = instance.optionValues
                    setLoading(false)
                })
                .catch((err) => {
                    console.error('Failed to fetch trader instance', err)
                    setError(err.message)
                })
        }

        load()
    }, [])

    const handleErr = useCallback(
        () => (err: Error | mts.common.ApiError) => {
            if (err instanceof mts.common.ApiError) {
                messageApi.error(`${err.code}-${err.description}: ${err.detail}`)
            } else {
                messageApi.error(err.message)
            }
        },
        [messageApi]
    )

    const handleStopClick = useCallback(() => {
        if (!instance) {
            return
        }
        setStopping(true)
        deskService
            .updateInstanceState(instance, mts.desk.TraderInstance.State.STOPPING)
            .then(() => messageApi.info('Stopping Instance'))
            .catch(handleErr)
            .finally(() => setStopping(false))
    }, [handleErr, instance, messageApi])

    if (!instance || !instance.trader || loading) {
        console.log('traderInstance not set or loading=true, return </>')
        return <></>
    }

    const handleStartClick = () => {
        deskService
            .updateInstanceState(instance, mts.desk.TraderInstance.State.STARTING)
            .then((instance) => {
                messageApi.info('Starting Instance')
                setInstance(instance)
            })
            .catch((err) => {
                messageApi.error(err.message)
                console.error('Caught err from startTraderInstance', err)
            })
    }

    // Conditions on which the start button is enabled
    const startEnabled =
        [State.NEW, State.STOPPED, State.ERROR].includes(instance.state) ||
        (instance.state === State.FINISHED && instance.mode === Mode.REALTIME)

    // Conditions on whcih the stop button is enabled
    const trader = instance.trader

    const deleteInstance = () => {
        setDeleting(true)
        deskService
            .deleteInstance(instance)
            .then(() => console.log('deleted'))
            .catch((err) => setError(err.message))
            .finally(() => navigate('/instances'))
    }

    const handleDeleteClick = () => {
        Modal.confirm({
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete this instance?',
            onOk: () => deleteInstance(),
            onCancel: () => {
                // Do nothing
            },
        })
    }

    const handleEditOptionsClick = () => {
        setOptionValuesModalOpen(true)
    }

    const handleOptionsChangeOk = (optionValues: OptionValues) => {
        setOptionValuesModalOpen(false)

        console.log('save OptionValues', optionValues)

        // Save the trader instance with the new option values
        // PUT a new option attributes onto an instance
        deskService
            .updateInstanceOptionValues(instance, optionValues)
            .then((resp) => {
                console.log('Saved option values', optionValues)
                optionValuesSignal.value = optionValues
                let updatedInstance = mts.desk.TraderInstance.create({
                    ...instance,
                    optionValues: optionValues,
                })
                setInstance(updatedInstance)
            })
            .catch((err) => {
                console.error('Failed to update instance option values', err)
                setError(err.message)
            })
    }

    const handleOptionsChangeClose = () => {
        setOptionValuesModalOpen(false)
    }

    const canExecute = canExecuteInstance(loggedInUser, instance)

    return (
        <StyledFullHeightFlexPage>
            {contextHolder}
            {error && <Alert description={error} type="error" showIcon />}

            <EditOptionValuesModal
                open={optionValuesModalOpen}
                onClose={handleOptionsChangeClose}
                instance={instance}
                options={instance.trader?.options as mts.desk.ITraderOption[]}
                optionValues={optionValuesSignal.value}
                onSave={handleOptionsChangeOk}
            />

            <Toolbar>
                <ToolbarItem>
                    <h1>
                        <Link to="/instances">Instances</Link>: {instance.id}
                    </h1>
                </ToolbarItem>
                <div style={{ flex: '1' }} />
                {canExecute && (
                    <ToolbarItem>
                        <Button disabled={!stopButtonEnabled} loading={stopping} onClick={handleStopClick}>
                            Stop
                        </Button>
                    </ToolbarItem>
                )}
                {canExecute && (
                    <ToolbarItem>
                        <Button
                            className="start"
                            disabled={!startEnabled}
                            onClick={handleStartClick}
                            type="primary"
                        >
                            Start
                        </Button>
                    </ToolbarItem>
                )}
                <ToolbarItem>
                    <RunBacktestButton
                        instance={instance}
                        trader={instance.trader as mts.desk.Trader}
                        disabled={false}
                    />
                </ToolbarItem>
                {/* Add an elippisis that when clicked shows a menu with the options delete, clear output */}
                <ToolbarItem>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: 'delete',
                                    onClick: handleDeleteClick,
                                    label: 'Delete',
                                },
                                {
                                    key: 'clearOutput',
                                    label: 'Clear Output',
                                },
                            ],
                        }}
                        trigger={['click']}
                    >
                        <Button>More Actions...</Button>
                    </Dropdown>
                </ToolbarItem>
            </Toolbar>

            {instance.error && <Alert description={instance.error} type="error" showIcon />}

            <div className="top">
                <Descriptions bordered size="small" column={2} className="descriptions">
                    <Item label="Id">{instance.id}</Item>
                    <Item label="Trader">
                        <Link to={`/traders/${trader.id}`}>{trader.name}</Link>
                    </Item>
                    <Item label="Owner">{user.id}</Item>
                    <Item label="Portfolio">
                        <Link to={`/portfolios/${instance.portfolioId}`}>{instance.portfolioId}</Link>
                    </Item>
                    <Item label="Broker">{instance.brokerAccountId}</Item>
                    <Item label="State">{mts.desk.TraderInstance.State[instance.state]}</Item>
                    <Item label="Mode">{mts.desk.TraderInstance.Mode[instance.mode]}</Item>
                    <Item label="Tick Interval">
                        {instance.trader?.tickInterval as number}{' '}
                        {enumName(mts.desk.TimeUnit, instance.trader?.tickIntervalUnit)}
                    </Item>

                    <Item label="Duration">{duration}</Item>
                    <Item label="Date Range">
                        {instance.from} - {instance.to}
                    </Item>
                    <Item label="Started">{instance.started}</Item>
                    <Item label="Option Values">
                        {formatOptionValues(instance.trader as mts.desk.Trader, optionValuesSignal.value)}
                        {isEditOptionsVisible(loggedInUser, instance) && (
                            <div>
                                <Typography.Link onClick={handleEditOptionsClick}>
                                    Edit Options
                                </Typography.Link>
                            </div>
                        )}
                    </Item>
                    <Item label="Runner ID">{instance.runnerId}</Item>
                    <Item label="Finished">{instance.finished}</Item>
                    <Item label="Access">
                        <InstanceAccess instance={instance} />
                    </Item>
                    <Item label="">&nbsp;</Item>
                    <Item
                        label="Trader Description"
                        span={2}
                        className="mts-instance-descriptions-description"
                    >
                        <Markdown>{trader.description}</Markdown>
                    </Item>
                    <Item label="Artifacts">
                        {watchlists.map((watchlist) => WatchlistLink({ instance, watchlist }))}
                    </Item>
                </Descriptions>
                <PortfolioSummaryPanel portfolioId={instance.portfolioId}></PortfolioSummaryPanel>
            </div>

            <OutputCard title={<div>Output</div>} className="logs" size="small">
                {outputError && <Alert type="error" message={outputError} />}

                <ErrorBoundary message="Failure inside log div">
                    <div ref={logEntriesRef}></div>
                </ErrorBoundary>
            </OutputCard>
        </StyledFullHeightFlexPage>
    )
}

/**
 * Format a link to a watchlist given an instance and artifact.
 * The name is formatted as "Watchlist: {date}/{tickType}"
 */
const WatchlistLink: React.FC<{ instance: mts.desk.TraderInstance; watchlist: mts.desk.Watchlist }> = (
    props
) => {
    const { instance, watchlist } = props
    const date = DateTime.fromISO(watchlist.id).toISODate()
    //const tickType = mts.desk.Tick.Type[instance.trader?.tickType as mts.desk.TickType]

    //if (artifact.type === mts.desk.Artifact.WATCHLIST) {
    return (
        <div>
            <Link to={`/watchlists/${watchlist?.id}`}>Watchlist: {watchlist?.id}</Link>
        </div>
    )
    //}
}

/**
 * Displays the access level of the instance.
 * If the instance is owned by the loggedInUser then a dropdown is displayed.
 * If the instance is not owned by the loggedInUser then the access level is displayed.
 * The access level is saved when dropdown is changed.
 */
const InstanceAccess: React.FC<{ instance: mts.desk.TraderInstance }> = (props) => {
    const { instance } = props
    const loggedInUser = useLoggedInUser()
    const [access, setAccess] = useState<mts.desk.Access>(instance.access)

    const updateAccess = (newAccess: mts.desk.Access) => {
        deskService
            .updateInstanceAccess(instance, newAccess)
            .then(() => {
                setAccess(newAccess)
            })
            .catch((err) => {
                console.error('Failed to update instance access', err)
            })
    }

    //const accessEnum = mts.desk.Access[formValues.access as keyof typeof mts.desk.Access]
    // List of access options using enum mts.desk.Access
    const accessOptions = Object.keys(mts.desk.Access)
        .filter((k) => k !== 'UNKNOWN_ACCESS')
        .map((k) => ({
            label: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
            value: k,
        }))

    const onChange = (value: string) => {
        console.log(`onChange ${value}`)
        const access: mts.desk.Access = mts.desk.Access[value as keyof typeof mts.desk.Access]
        updateAccess(access)
        console.log(`enumKey setAccess ${access}`)
        setAccess(access)
    }

    if (loggedInUser.id === instance.userId) {
        return (
            <Select
                options={accessOptions}
                style={{ width: 100 }}
                onChange={onChange}
                value={mts.desk.Access[access]}
            />
        )
    }

    return <>{mts.desk.Access[access]}</>
}

const StyledFullHeightFlexPage = styled(FullHeightFlexPage)`
    display: flex;
    flex-direction: column;
    .descriptions {
        margin-right: ${(props) => props.theme.antd.padding}px;
        margin-left: ${(props) => props.theme.antd.padding}px;
        flex: 1;

        .ant-descriptions-item-label {
            vertical-align: top;
        }
        .ant-descriptions-item-content {
            vertical-align: top;
            padding-top: 0px;
        }

        .ant-descriptions-item-content.mts-instance-descriptions-description {
            padding-top: 0px !important;
        }

        .ant-descriptions-item-content.mts-instance-descriptions-description p {
            margin-top: 5px !important;
        }

        .ant-descriptions-item-content span {
            padding-top: 0px;
        }

        .ant-descriptions-item-content p {
            padding-top: 0px;
        }
    }
    .top {
        display: flex;
        flex: 2;
        flex-direction: row;
    }
    .logs {
        overflow-x: hidden;
        flex: 1;
    }
`

const OutputCard = styled(Card)`
    margin-top: ${(props) => props.theme.antd.padding}px;
    margin-left: ${(props) => props.theme.antd.padding}px;
    margin-right: ${(props) => props.theme.antd.padding}px;

    .log-entry {
        font-family: monospace;
        white-space: pre;
        border-bottom: 1px solid #aaa;
        display: flex;
    }
    .log-entry:last-child {
        border-bottom: none;
    }
    .log-entry {
        display: flex;
    }
    .log-date {
        color: #555;
        font-size: 1em;
        flex: 0;
    }
    .log-message {
        margin-left: 1em;
        width: 700px;
        white-space: normal;
        overflow-wrap: break-word;
        flex: 1;
    }
`

/**
 * Returns duration this trader took to run in millis.
 * Returns undefined if Trader.started or Trader.finished are undefined
 */
function durationMillis(traderInstance: mts.desk.TraderInstance): undefined | number {
    if (traderInstance.state === State.RUNNING) {
        const started = DateTime.fromISO(traderInstance.started)
        const durationMillis = DateTime.now().toMillis() - started.toMillis()
        return durationMillis
    }

    if (!traderInstance.started || !traderInstance.finished) {
        return undefined
    }
    const started = DateTime.fromISO(traderInstance.started)
    const finished = DateTime.fromISO(traderInstance.finished)
    const durationMillis = finished.toMillis() - started.toMillis()
    return durationMillis
}

export default TraderInstanceSummary
