/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const mts = $root.mts = (() => {

    /**
     * Namespace mts.
     * @exports mts
     * @namespace
     */
    const mts = {};

    mts.common = (function() {

        /**
         * Namespace common.
         * @memberof mts
         * @namespace
         */
        const common = {};

        common.ApiError = (function() {

            /**
             * Properties of an ApiError.
             * @memberof mts.common
             * @interface IApiError
             * @property {string|null} [app] ApiError app
             * @property {number|null} [code] ApiError code
             * @property {string|null} [description] ApiError description
             * @property {string|null} [detail] ApiError detail
             * @property {number|null} [httpStatus] ApiError httpStatus
             */

            /**
             * Constructs a new ApiError.
             * @memberof mts.common
             * @classdesc Represents an ApiError.
             * @implements IApiError
             * @constructor
             * @param {mts.common.IApiError=} [properties] Properties to set
             */
            function ApiError(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ApiError app.
             * @member {string} app
             * @memberof mts.common.ApiError
             * @instance
             */
            ApiError.prototype.app = "";

            /**
             * ApiError code.
             * @member {number} code
             * @memberof mts.common.ApiError
             * @instance
             */
            ApiError.prototype.code = 0;

            /**
             * ApiError description.
             * @member {string} description
             * @memberof mts.common.ApiError
             * @instance
             */
            ApiError.prototype.description = "";

            /**
             * ApiError detail.
             * @member {string} detail
             * @memberof mts.common.ApiError
             * @instance
             */
            ApiError.prototype.detail = "";

            /**
             * ApiError httpStatus.
             * @member {number} httpStatus
             * @memberof mts.common.ApiError
             * @instance
             */
            ApiError.prototype.httpStatus = 0;

            /**
             * Creates a new ApiError instance using the specified properties.
             * @function create
             * @memberof mts.common.ApiError
             * @static
             * @param {mts.common.IApiError=} [properties] Properties to set
             * @returns {mts.common.ApiError} ApiError instance
             */
            ApiError.create = function create(properties) {
                return new ApiError(properties);
            };

            /**
             * Encodes the specified ApiError message. Does not implicitly {@link mts.common.ApiError.verify|verify} messages.
             * @function encode
             * @memberof mts.common.ApiError
             * @static
             * @param {mts.common.IApiError} message ApiError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApiError.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.detail);
                if (message.httpStatus != null && Object.hasOwnProperty.call(message, "httpStatus"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.httpStatus);
                if (message.app != null && Object.hasOwnProperty.call(message, "app"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.app);
                return writer;
            };

            /**
             * Encodes the specified ApiError message, length delimited. Does not implicitly {@link mts.common.ApiError.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.common.ApiError
             * @static
             * @param {mts.common.IApiError} message ApiError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApiError.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ApiError message from the specified reader or buffer.
             * @function decode
             * @memberof mts.common.ApiError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.common.ApiError} ApiError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApiError.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.ApiError();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 10: {
                            message.app = reader.string();
                            break;
                        }
                    case 1: {
                            message.code = reader.int32();
                            break;
                        }
                    case 2: {
                            message.description = reader.string();
                            break;
                        }
                    case 3: {
                            message.detail = reader.string();
                            break;
                        }
                    case 4: {
                            message.httpStatus = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ApiError message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.common.ApiError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.common.ApiError} ApiError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApiError.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ApiError message.
             * @function verify
             * @memberof mts.common.ApiError
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ApiError.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.app != null && message.hasOwnProperty("app"))
                    if (!$util.isString(message.app))
                        return "app: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isInteger(message.code))
                        return "code: integer expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.detail != null && message.hasOwnProperty("detail"))
                    if (!$util.isString(message.detail))
                        return "detail: string expected";
                if (message.httpStatus != null && message.hasOwnProperty("httpStatus"))
                    if (!$util.isInteger(message.httpStatus))
                        return "httpStatus: integer expected";
                return null;
            };

            /**
             * Creates an ApiError message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.common.ApiError
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.common.ApiError} ApiError
             */
            ApiError.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.common.ApiError)
                    return object;
                let message = new $root.mts.common.ApiError();
                if (object.app != null)
                    message.app = String(object.app);
                if (object.code != null)
                    message.code = object.code | 0;
                if (object.description != null)
                    message.description = String(object.description);
                if (object.detail != null)
                    message.detail = String(object.detail);
                if (object.httpStatus != null)
                    message.httpStatus = object.httpStatus | 0;
                return message;
            };

            /**
             * Creates a plain object from an ApiError message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.common.ApiError
             * @static
             * @param {mts.common.ApiError} message ApiError
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ApiError.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.code = 0;
                    object.description = "";
                    object.detail = "";
                    object.httpStatus = 0;
                    object.app = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.detail != null && message.hasOwnProperty("detail"))
                    object.detail = message.detail;
                if (message.httpStatus != null && message.hasOwnProperty("httpStatus"))
                    object.httpStatus = message.httpStatus;
                if (message.app != null && message.hasOwnProperty("app"))
                    object.app = message.app;
                return object;
            };

            /**
             * Converts this ApiError to JSON.
             * @function toJSON
             * @memberof mts.common.ApiError
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ApiError.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ApiError
             * @function getTypeUrl
             * @memberof mts.common.ApiError
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ApiError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.common.ApiError";
            };

            return ApiError;
        })();

        common.ApiErrorResponse = (function() {

            /**
             * Properties of an ApiErrorResponse.
             * @memberof mts.common
             * @interface IApiErrorResponse
             * @property {mts.common.IApiError|null} [error] ApiErrorResponse error
             */

            /**
             * Constructs a new ApiErrorResponse.
             * @memberof mts.common
             * @classdesc Represents an ApiErrorResponse.
             * @implements IApiErrorResponse
             * @constructor
             * @param {mts.common.IApiErrorResponse=} [properties] Properties to set
             */
            function ApiErrorResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ApiErrorResponse error.
             * @member {mts.common.IApiError|null|undefined} error
             * @memberof mts.common.ApiErrorResponse
             * @instance
             */
            ApiErrorResponse.prototype.error = null;

            /**
             * Creates a new ApiErrorResponse instance using the specified properties.
             * @function create
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {mts.common.IApiErrorResponse=} [properties] Properties to set
             * @returns {mts.common.ApiErrorResponse} ApiErrorResponse instance
             */
            ApiErrorResponse.create = function create(properties) {
                return new ApiErrorResponse(properties);
            };

            /**
             * Encodes the specified ApiErrorResponse message. Does not implicitly {@link mts.common.ApiErrorResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {mts.common.IApiErrorResponse} message ApiErrorResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApiErrorResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    $root.mts.common.ApiError.encode(message.error, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ApiErrorResponse message, length delimited. Does not implicitly {@link mts.common.ApiErrorResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {mts.common.IApiErrorResponse} message ApiErrorResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApiErrorResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ApiErrorResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.common.ApiErrorResponse} ApiErrorResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApiErrorResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.ApiErrorResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.error = $root.mts.common.ApiError.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ApiErrorResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.common.ApiErrorResponse} ApiErrorResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApiErrorResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ApiErrorResponse message.
             * @function verify
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ApiErrorResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error != null && message.hasOwnProperty("error")) {
                    let error = $root.mts.common.ApiError.verify(message.error);
                    if (error)
                        return "error." + error;
                }
                return null;
            };

            /**
             * Creates an ApiErrorResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.common.ApiErrorResponse} ApiErrorResponse
             */
            ApiErrorResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.common.ApiErrorResponse)
                    return object;
                let message = new $root.mts.common.ApiErrorResponse();
                if (object.error != null) {
                    if (typeof object.error !== "object")
                        throw TypeError(".mts.common.ApiErrorResponse.error: object expected");
                    message.error = $root.mts.common.ApiError.fromObject(object.error);
                }
                return message;
            };

            /**
             * Creates a plain object from an ApiErrorResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {mts.common.ApiErrorResponse} message ApiErrorResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ApiErrorResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.error = null;
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = $root.mts.common.ApiError.toObject(message.error, options);
                return object;
            };

            /**
             * Converts this ApiErrorResponse to JSON.
             * @function toJSON
             * @memberof mts.common.ApiErrorResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ApiErrorResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ApiErrorResponse
             * @function getTypeUrl
             * @memberof mts.common.ApiErrorResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ApiErrorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.common.ApiErrorResponse";
            };

            return ApiErrorResponse;
        })();

        common.marketdata = (function() {

            /**
             * Namespace marketdata.
             * @memberof mts.common
             * @namespace
             */
            const marketdata = {};

            marketdata.MarketDataService = (function() {

                /**
                 * Constructs a new MarketDataService service.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a MarketDataService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function MarketDataService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (MarketDataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MarketDataService;

                /**
                 * Creates new MarketDataService service using the specified rpc implementation.
                 * @function create
                 * @memberof mts.common.marketdata.MarketDataService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {MarketDataService} RPC service. Useful where requests and/or responses are streamed.
                 */
                MarketDataService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link mts.common.marketdata.MarketDataService#getQuotes}.
                 * @memberof mts.common.marketdata.MarketDataService
                 * @typedef getQuotesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mts.common.marketdata.QuotesResponse} [response] QuotesResponse
                 */

                /**
                 * Calls getQuotes.
                 * @function getQuotes
                 * @memberof mts.common.marketdata.MarketDataService
                 * @instance
                 * @param {mts.common.marketdata.IQuotesRequest} request QuotesRequest message or plain object
                 * @param {mts.common.marketdata.MarketDataService.getQuotesCallback} callback Node-style callback called with the error, if any, and QuotesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MarketDataService.prototype.getQuotes = function getQuotes(request, callback) {
                    return this.rpcCall(getQuotes, $root.mts.common.marketdata.QuotesRequest, $root.mts.common.marketdata.QuotesResponse, request, callback);
                }, "name", { value: "getQuotes" });

                /**
                 * Calls getQuotes.
                 * @function getQuotes
                 * @memberof mts.common.marketdata.MarketDataService
                 * @instance
                 * @param {mts.common.marketdata.IQuotesRequest} request QuotesRequest message or plain object
                 * @returns {Promise<mts.common.marketdata.QuotesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mts.common.marketdata.MarketDataService#getInstruments}.
                 * @memberof mts.common.marketdata.MarketDataService
                 * @typedef getInstrumentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mts.common.marketdata.GetInstrumentsResponse} [response] GetInstrumentsResponse
                 */

                /**
                 * Calls getInstruments.
                 * @function getInstruments
                 * @memberof mts.common.marketdata.MarketDataService
                 * @instance
                 * @param {mts.common.marketdata.IGetInstrumentsRequest} request GetInstrumentsRequest message or plain object
                 * @param {mts.common.marketdata.MarketDataService.getInstrumentsCallback} callback Node-style callback called with the error, if any, and GetInstrumentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MarketDataService.prototype.getInstruments = function getInstruments(request, callback) {
                    return this.rpcCall(getInstruments, $root.mts.common.marketdata.GetInstrumentsRequest, $root.mts.common.marketdata.GetInstrumentsResponse, request, callback);
                }, "name", { value: "getInstruments" });

                /**
                 * Calls getInstruments.
                 * @function getInstruments
                 * @memberof mts.common.marketdata.MarketDataService
                 * @instance
                 * @param {mts.common.marketdata.IGetInstrumentsRequest} request GetInstrumentsRequest message or plain object
                 * @returns {Promise<mts.common.marketdata.GetInstrumentsResponse>} Promise
                 * @variation 2
                 */

                return MarketDataService;
            })();

            marketdata.QuotesRequest = (function() {

                /**
                 * Properties of a QuotesRequest.
                 * @memberof mts.common.marketdata
                 * @interface IQuotesRequest
                 * @property {Array.<string>|null} [symbols] QuotesRequest symbols
                 */

                /**
                 * Constructs a new QuotesRequest.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a QuotesRequest.
                 * @implements IQuotesRequest
                 * @constructor
                 * @param {mts.common.marketdata.IQuotesRequest=} [properties] Properties to set
                 */
                function QuotesRequest(properties) {
                    this.symbols = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * QuotesRequest symbols.
                 * @member {Array.<string>} symbols
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @instance
                 */
                QuotesRequest.prototype.symbols = $util.emptyArray;

                /**
                 * Creates a new QuotesRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {mts.common.marketdata.IQuotesRequest=} [properties] Properties to set
                 * @returns {mts.common.marketdata.QuotesRequest} QuotesRequest instance
                 */
                QuotesRequest.create = function create(properties) {
                    return new QuotesRequest(properties);
                };

                /**
                 * Encodes the specified QuotesRequest message. Does not implicitly {@link mts.common.marketdata.QuotesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {mts.common.marketdata.IQuotesRequest} message QuotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QuotesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.symbols != null && message.symbols.length)
                        for (let i = 0; i < message.symbols.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbols[i]);
                    return writer;
                };

                /**
                 * Encodes the specified QuotesRequest message, length delimited. Does not implicitly {@link mts.common.marketdata.QuotesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {mts.common.marketdata.IQuotesRequest} message QuotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QuotesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a QuotesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.QuotesRequest} QuotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QuotesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.QuotesRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.symbols && message.symbols.length))
                                    message.symbols = [];
                                message.symbols.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a QuotesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.QuotesRequest} QuotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QuotesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a QuotesRequest message.
                 * @function verify
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QuotesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.symbols != null && message.hasOwnProperty("symbols")) {
                        if (!Array.isArray(message.symbols))
                            return "symbols: array expected";
                        for (let i = 0; i < message.symbols.length; ++i)
                            if (!$util.isString(message.symbols[i]))
                                return "symbols: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a QuotesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.QuotesRequest} QuotesRequest
                 */
                QuotesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.QuotesRequest)
                        return object;
                    let message = new $root.mts.common.marketdata.QuotesRequest();
                    if (object.symbols) {
                        if (!Array.isArray(object.symbols))
                            throw TypeError(".mts.common.marketdata.QuotesRequest.symbols: array expected");
                        message.symbols = [];
                        for (let i = 0; i < object.symbols.length; ++i)
                            message.symbols[i] = String(object.symbols[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a QuotesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {mts.common.marketdata.QuotesRequest} message QuotesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QuotesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.symbols = [];
                    if (message.symbols && message.symbols.length) {
                        object.symbols = [];
                        for (let j = 0; j < message.symbols.length; ++j)
                            object.symbols[j] = message.symbols[j];
                    }
                    return object;
                };

                /**
                 * Converts this QuotesRequest to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QuotesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for QuotesRequest
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.QuotesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QuotesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.QuotesRequest";
                };

                return QuotesRequest;
            })();

            marketdata.QuotesResponse = (function() {

                /**
                 * Properties of a QuotesResponse.
                 * @memberof mts.common.marketdata
                 * @interface IQuotesResponse
                 * @property {Object.<string,mts.common.marketdata.IQuote>|null} [quotes] QuotesResponse quotes
                 */

                /**
                 * Constructs a new QuotesResponse.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a QuotesResponse.
                 * @implements IQuotesResponse
                 * @constructor
                 * @param {mts.common.marketdata.IQuotesResponse=} [properties] Properties to set
                 */
                function QuotesResponse(properties) {
                    this.quotes = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * QuotesResponse quotes.
                 * @member {Object.<string,mts.common.marketdata.IQuote>} quotes
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @instance
                 */
                QuotesResponse.prototype.quotes = $util.emptyObject;

                /**
                 * Creates a new QuotesResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {mts.common.marketdata.IQuotesResponse=} [properties] Properties to set
                 * @returns {mts.common.marketdata.QuotesResponse} QuotesResponse instance
                 */
                QuotesResponse.create = function create(properties) {
                    return new QuotesResponse(properties);
                };

                /**
                 * Encodes the specified QuotesResponse message. Does not implicitly {@link mts.common.marketdata.QuotesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {mts.common.marketdata.IQuotesResponse} message QuotesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QuotesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.quotes != null && Object.hasOwnProperty.call(message, "quotes"))
                        for (let keys = Object.keys(message.quotes), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.mts.common.marketdata.Quote.encode(message.quotes[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };

                /**
                 * Encodes the specified QuotesResponse message, length delimited. Does not implicitly {@link mts.common.marketdata.QuotesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {mts.common.marketdata.IQuotesResponse} message QuotesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QuotesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a QuotesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.QuotesResponse} QuotesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QuotesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.QuotesResponse(), key, value;
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.quotes === $util.emptyObject)
                                    message.quotes = {};
                                let end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    let tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.mts.common.marketdata.Quote.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.quotes[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a QuotesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.QuotesResponse} QuotesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QuotesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a QuotesResponse message.
                 * @function verify
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QuotesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.quotes != null && message.hasOwnProperty("quotes")) {
                        if (!$util.isObject(message.quotes))
                            return "quotes: object expected";
                        let key = Object.keys(message.quotes);
                        for (let i = 0; i < key.length; ++i) {
                            let error = $root.mts.common.marketdata.Quote.verify(message.quotes[key[i]]);
                            if (error)
                                return "quotes." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a QuotesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.QuotesResponse} QuotesResponse
                 */
                QuotesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.QuotesResponse)
                        return object;
                    let message = new $root.mts.common.marketdata.QuotesResponse();
                    if (object.quotes) {
                        if (typeof object.quotes !== "object")
                            throw TypeError(".mts.common.marketdata.QuotesResponse.quotes: object expected");
                        message.quotes = {};
                        for (let keys = Object.keys(object.quotes), i = 0; i < keys.length; ++i) {
                            if (typeof object.quotes[keys[i]] !== "object")
                                throw TypeError(".mts.common.marketdata.QuotesResponse.quotes: object expected");
                            message.quotes[keys[i]] = $root.mts.common.marketdata.Quote.fromObject(object.quotes[keys[i]]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a QuotesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {mts.common.marketdata.QuotesResponse} message QuotesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QuotesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.quotes = {};
                    let keys2;
                    if (message.quotes && (keys2 = Object.keys(message.quotes)).length) {
                        object.quotes = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.quotes[keys2[j]] = $root.mts.common.marketdata.Quote.toObject(message.quotes[keys2[j]], options);
                    }
                    return object;
                };

                /**
                 * Converts this QuotesResponse to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QuotesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for QuotesResponse
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.QuotesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QuotesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.QuotesResponse";
                };

                return QuotesResponse;
            })();

            marketdata.Quote = (function() {

                /**
                 * Properties of a Quote.
                 * @memberof mts.common.marketdata
                 * @interface IQuote
                 * @property {string|null} [symbol] Quote symbol
                 * @property {string|null} [error] Quote error
                 * @property {string|null} [exchange] Quote exchange
                 * @property {string|null} [country] Quote country
                 * @property {number|null} [price] Quote price
                 * @property {number|Long|null} [time] Quote time
                 * @property {number|null} [bid] Quote bid
                 * @property {number|null} [ask] Quote ask
                 * @property {number|null} [open] Quote open
                 * @property {number|null} [high] Quote high
                 * @property {number|null} [low] Quote low
                 * @property {number|null} [close] Quote close
                 * @property {number|Long|null} [priceTime] Quote priceTime
                 */

                /**
                 * Constructs a new Quote.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Quote.
                 * @implements IQuote
                 * @constructor
                 * @param {mts.common.marketdata.IQuote=} [properties] Properties to set
                 */
                function Quote(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Quote symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.symbol = "";

                /**
                 * Quote error.
                 * @member {string} error
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.error = "";

                /**
                 * Quote exchange.
                 * @member {string} exchange
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.exchange = "";

                /**
                 * Quote country.
                 * @member {string} country
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.country = "";

                /**
                 * Quote price.
                 * @member {number} price
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.price = 0;

                /**
                 * Quote time.
                 * @member {number|Long} time
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Quote bid.
                 * @member {number} bid
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.bid = 0;

                /**
                 * Quote ask.
                 * @member {number} ask
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.ask = 0;

                /**
                 * Quote open.
                 * @member {number} open
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.open = 0;

                /**
                 * Quote high.
                 * @member {number} high
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.high = 0;

                /**
                 * Quote low.
                 * @member {number} low
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.low = 0;

                /**
                 * Quote close.
                 * @member {number} close
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.close = 0;

                /**
                 * Quote priceTime.
                 * @member {number|Long} priceTime
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 */
                Quote.prototype.priceTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Quote instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {mts.common.marketdata.IQuote=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Quote} Quote instance
                 */
                Quote.create = function create(properties) {
                    return new Quote(properties);
                };

                /**
                 * Encodes the specified Quote message. Does not implicitly {@link mts.common.marketdata.Quote.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {mts.common.marketdata.IQuote} message Quote message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Quote.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                    if (message.exchange != null && Object.hasOwnProperty.call(message, "exchange"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.exchange);
                    if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.country);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 4, wireType 1 =*/33).double(message.price);
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.time);
                    if (message.bid != null && Object.hasOwnProperty.call(message, "bid"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.bid);
                    if (message.ask != null && Object.hasOwnProperty.call(message, "ask"))
                        writer.uint32(/* id 7, wireType 1 =*/57).double(message.ask);
                    if (message.open != null && Object.hasOwnProperty.call(message, "open"))
                        writer.uint32(/* id 10, wireType 1 =*/81).double(message.open);
                    if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                        writer.uint32(/* id 11, wireType 1 =*/89).double(message.high);
                    if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                        writer.uint32(/* id 12, wireType 1 =*/97).double(message.low);
                    if (message.close != null && Object.hasOwnProperty.call(message, "close"))
                        writer.uint32(/* id 13, wireType 1 =*/105).double(message.close);
                    if (message.priceTime != null && Object.hasOwnProperty.call(message, "priceTime"))
                        writer.uint32(/* id 14, wireType 0 =*/112).int64(message.priceTime);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 100, wireType 2 =*/802).string(message.error);
                    return writer;
                };

                /**
                 * Encodes the specified Quote message, length delimited. Does not implicitly {@link mts.common.marketdata.Quote.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {mts.common.marketdata.IQuote} message Quote message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Quote.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Quote message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Quote} Quote
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Quote.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Quote();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.symbol = reader.string();
                                break;
                            }
                        case 100: {
                                message.error = reader.string();
                                break;
                            }
                        case 2: {
                                message.exchange = reader.string();
                                break;
                            }
                        case 3: {
                                message.country = reader.string();
                                break;
                            }
                        case 4: {
                                message.price = reader.double();
                                break;
                            }
                        case 5: {
                                message.time = reader.int64();
                                break;
                            }
                        case 6: {
                                message.bid = reader.double();
                                break;
                            }
                        case 7: {
                                message.ask = reader.double();
                                break;
                            }
                        case 10: {
                                message.open = reader.double();
                                break;
                            }
                        case 11: {
                                message.high = reader.double();
                                break;
                            }
                        case 12: {
                                message.low = reader.double();
                                break;
                            }
                        case 13: {
                                message.close = reader.double();
                                break;
                            }
                        case 14: {
                                message.priceTime = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Quote message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Quote} Quote
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Quote.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Quote message.
                 * @function verify
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Quote.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    if (message.exchange != null && message.hasOwnProperty("exchange"))
                        if (!$util.isString(message.exchange))
                            return "exchange: string expected";
                    if (message.country != null && message.hasOwnProperty("country"))
                        if (!$util.isString(message.country))
                            return "country: string expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (typeof message.price !== "number")
                            return "price: number expected";
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.bid != null && message.hasOwnProperty("bid"))
                        if (typeof message.bid !== "number")
                            return "bid: number expected";
                    if (message.ask != null && message.hasOwnProperty("ask"))
                        if (typeof message.ask !== "number")
                            return "ask: number expected";
                    if (message.open != null && message.hasOwnProperty("open"))
                        if (typeof message.open !== "number")
                            return "open: number expected";
                    if (message.high != null && message.hasOwnProperty("high"))
                        if (typeof message.high !== "number")
                            return "high: number expected";
                    if (message.low != null && message.hasOwnProperty("low"))
                        if (typeof message.low !== "number")
                            return "low: number expected";
                    if (message.close != null && message.hasOwnProperty("close"))
                        if (typeof message.close !== "number")
                            return "close: number expected";
                    if (message.priceTime != null && message.hasOwnProperty("priceTime"))
                        if (!$util.isInteger(message.priceTime) && !(message.priceTime && $util.isInteger(message.priceTime.low) && $util.isInteger(message.priceTime.high)))
                            return "priceTime: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Quote message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Quote} Quote
                 */
                Quote.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Quote)
                        return object;
                    let message = new $root.mts.common.marketdata.Quote();
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    if (object.error != null)
                        message.error = String(object.error);
                    if (object.exchange != null)
                        message.exchange = String(object.exchange);
                    if (object.country != null)
                        message.country = String(object.country);
                    if (object.price != null)
                        message.price = Number(object.price);
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.bid != null)
                        message.bid = Number(object.bid);
                    if (object.ask != null)
                        message.ask = Number(object.ask);
                    if (object.open != null)
                        message.open = Number(object.open);
                    if (object.high != null)
                        message.high = Number(object.high);
                    if (object.low != null)
                        message.low = Number(object.low);
                    if (object.close != null)
                        message.close = Number(object.close);
                    if (object.priceTime != null)
                        if ($util.Long)
                            (message.priceTime = $util.Long.fromValue(object.priceTime)).unsigned = false;
                        else if (typeof object.priceTime === "string")
                            message.priceTime = parseInt(object.priceTime, 10);
                        else if (typeof object.priceTime === "number")
                            message.priceTime = object.priceTime;
                        else if (typeof object.priceTime === "object")
                            message.priceTime = new $util.LongBits(object.priceTime.low >>> 0, object.priceTime.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Quote message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {mts.common.marketdata.Quote} message Quote
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Quote.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.symbol = "";
                        object.exchange = "";
                        object.country = "";
                        object.price = 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                        object.bid = 0;
                        object.ask = 0;
                        object.open = 0;
                        object.high = 0;
                        object.low = 0;
                        object.close = 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.priceTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.priceTime = options.longs === String ? "0" : 0;
                        object.error = "";
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    if (message.exchange != null && message.hasOwnProperty("exchange"))
                        object.exchange = message.exchange;
                    if (message.country != null && message.hasOwnProperty("country"))
                        object.country = message.country;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.bid != null && message.hasOwnProperty("bid"))
                        object.bid = options.json && !isFinite(message.bid) ? String(message.bid) : message.bid;
                    if (message.ask != null && message.hasOwnProperty("ask"))
                        object.ask = options.json && !isFinite(message.ask) ? String(message.ask) : message.ask;
                    if (message.open != null && message.hasOwnProperty("open"))
                        object.open = options.json && !isFinite(message.open) ? String(message.open) : message.open;
                    if (message.high != null && message.hasOwnProperty("high"))
                        object.high = options.json && !isFinite(message.high) ? String(message.high) : message.high;
                    if (message.low != null && message.hasOwnProperty("low"))
                        object.low = options.json && !isFinite(message.low) ? String(message.low) : message.low;
                    if (message.close != null && message.hasOwnProperty("close"))
                        object.close = options.json && !isFinite(message.close) ? String(message.close) : message.close;
                    if (message.priceTime != null && message.hasOwnProperty("priceTime"))
                        if (typeof message.priceTime === "number")
                            object.priceTime = options.longs === String ? String(message.priceTime) : message.priceTime;
                        else
                            object.priceTime = options.longs === String ? $util.Long.prototype.toString.call(message.priceTime) : options.longs === Number ? new $util.LongBits(message.priceTime.low >>> 0, message.priceTime.high >>> 0).toNumber() : message.priceTime;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    return object;
                };

                /**
                 * Converts this Quote to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Quote
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Quote.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Quote
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Quote
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Quote.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Quote";
                };

                return Quote;
            })();

            marketdata.MarketEvent = (function() {

                /**
                 * Properties of a MarketEvent.
                 * @memberof mts.common.marketdata
                 * @interface IMarketEvent
                 * @property {number|Long|null} [time] MarketEvent time
                 * @property {mts.common.marketdata.IHeartbeat|null} [heartbeat] MarketEvent heartbeat
                 * @property {mts.common.marketdata.ITrade|null} [trade] MarketEvent trade
                 */

                /**
                 * Constructs a new MarketEvent.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a MarketEvent.
                 * @implements IMarketEvent
                 * @constructor
                 * @param {mts.common.marketdata.IMarketEvent=} [properties] Properties to set
                 */
                function MarketEvent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MarketEvent time.
                 * @member {number|Long} time
                 * @memberof mts.common.marketdata.MarketEvent
                 * @instance
                 */
                MarketEvent.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MarketEvent heartbeat.
                 * @member {mts.common.marketdata.IHeartbeat|null|undefined} heartbeat
                 * @memberof mts.common.marketdata.MarketEvent
                 * @instance
                 */
                MarketEvent.prototype.heartbeat = null;

                /**
                 * MarketEvent trade.
                 * @member {mts.common.marketdata.ITrade|null|undefined} trade
                 * @memberof mts.common.marketdata.MarketEvent
                 * @instance
                 */
                MarketEvent.prototype.trade = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * MarketEvent type.
                 * @member {"heartbeat"|"trade"|undefined} type
                 * @memberof mts.common.marketdata.MarketEvent
                 * @instance
                 */
                Object.defineProperty(MarketEvent.prototype, "type", {
                    get: $util.oneOfGetter($oneOfFields = ["heartbeat", "trade"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new MarketEvent instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {mts.common.marketdata.IMarketEvent=} [properties] Properties to set
                 * @returns {mts.common.marketdata.MarketEvent} MarketEvent instance
                 */
                MarketEvent.create = function create(properties) {
                    return new MarketEvent(properties);
                };

                /**
                 * Encodes the specified MarketEvent message. Does not implicitly {@link mts.common.marketdata.MarketEvent.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {mts.common.marketdata.IMarketEvent} message MarketEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarketEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    if (message.heartbeat != null && Object.hasOwnProperty.call(message, "heartbeat"))
                        $root.mts.common.marketdata.Heartbeat.encode(message.heartbeat, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.trade != null && Object.hasOwnProperty.call(message, "trade"))
                        $root.mts.common.marketdata.Trade.encode(message.trade, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MarketEvent message, length delimited. Does not implicitly {@link mts.common.marketdata.MarketEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {mts.common.marketdata.IMarketEvent} message MarketEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarketEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MarketEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.MarketEvent} MarketEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarketEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.MarketEvent();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        case 10: {
                                message.heartbeat = $root.mts.common.marketdata.Heartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.trade = $root.mts.common.marketdata.Trade.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MarketEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.MarketEvent} MarketEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarketEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MarketEvent message.
                 * @function verify
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MarketEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                        properties.type = 1;
                        {
                            let error = $root.mts.common.marketdata.Heartbeat.verify(message.heartbeat);
                            if (error)
                                return "heartbeat." + error;
                        }
                    }
                    if (message.trade != null && message.hasOwnProperty("trade")) {
                        if (properties.type === 1)
                            return "type: multiple values";
                        properties.type = 1;
                        {
                            let error = $root.mts.common.marketdata.Trade.verify(message.trade);
                            if (error)
                                return "trade." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MarketEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.MarketEvent} MarketEvent
                 */
                MarketEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.MarketEvent)
                        return object;
                    let message = new $root.mts.common.marketdata.MarketEvent();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.heartbeat != null) {
                        if (typeof object.heartbeat !== "object")
                            throw TypeError(".mts.common.marketdata.MarketEvent.heartbeat: object expected");
                        message.heartbeat = $root.mts.common.marketdata.Heartbeat.fromObject(object.heartbeat);
                    }
                    if (object.trade != null) {
                        if (typeof object.trade !== "object")
                            throw TypeError(".mts.common.marketdata.MarketEvent.trade: object expected");
                        message.trade = $root.mts.common.marketdata.Trade.fromObject(object.trade);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MarketEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {mts.common.marketdata.MarketEvent} message MarketEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MarketEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                        object.heartbeat = $root.mts.common.marketdata.Heartbeat.toObject(message.heartbeat, options);
                        if (options.oneofs)
                            object.type = "heartbeat";
                    }
                    if (message.trade != null && message.hasOwnProperty("trade")) {
                        object.trade = $root.mts.common.marketdata.Trade.toObject(message.trade, options);
                        if (options.oneofs)
                            object.type = "trade";
                    }
                    return object;
                };

                /**
                 * Converts this MarketEvent to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.MarketEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MarketEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MarketEvent
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.MarketEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MarketEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.MarketEvent";
                };

                return MarketEvent;
            })();

            marketdata.Heartbeat = (function() {

                /**
                 * Properties of a Heartbeat.
                 * @memberof mts.common.marketdata
                 * @interface IHeartbeat
                 * @property {number|Long|null} [time] Heartbeat time
                 */

                /**
                 * Constructs a new Heartbeat.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Heartbeat.
                 * @implements IHeartbeat
                 * @constructor
                 * @param {mts.common.marketdata.IHeartbeat=} [properties] Properties to set
                 */
                function Heartbeat(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Heartbeat time.
                 * @member {number|Long} time
                 * @memberof mts.common.marketdata.Heartbeat
                 * @instance
                 */
                Heartbeat.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Heartbeat instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {mts.common.marketdata.IHeartbeat=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Heartbeat} Heartbeat instance
                 */
                Heartbeat.create = function create(properties) {
                    return new Heartbeat(properties);
                };

                /**
                 * Encodes the specified Heartbeat message. Does not implicitly {@link mts.common.marketdata.Heartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {mts.common.marketdata.IHeartbeat} message Heartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Heartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    return writer;
                };

                /**
                 * Encodes the specified Heartbeat message, length delimited. Does not implicitly {@link mts.common.marketdata.Heartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {mts.common.marketdata.IHeartbeat} message Heartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Heartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Heartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Heartbeat} Heartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Heartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Heartbeat();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Heartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Heartbeat} Heartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Heartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Heartbeat message.
                 * @function verify
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Heartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Heartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Heartbeat} Heartbeat
                 */
                Heartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Heartbeat)
                        return object;
                    let message = new $root.mts.common.marketdata.Heartbeat();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Heartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {mts.common.marketdata.Heartbeat} message Heartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Heartbeat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    return object;
                };

                /**
                 * Converts this Heartbeat to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Heartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Heartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Heartbeat
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Heartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Heartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Heartbeat";
                };

                return Heartbeat;
            })();

            marketdata.Trade = (function() {

                /**
                 * Properties of a Trade.
                 * @memberof mts.common.marketdata
                 * @interface ITrade
                 * @property {string|null} [symbol] Trade symbol
                 * @property {number|Long|null} [time] Trade time
                 * @property {number|null} [price] Trade price
                 */

                /**
                 * Constructs a new Trade.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Trade.
                 * @implements ITrade
                 * @constructor
                 * @param {mts.common.marketdata.ITrade=} [properties] Properties to set
                 */
                function Trade(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Trade symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.Trade
                 * @instance
                 */
                Trade.prototype.symbol = "";

                /**
                 * Trade time.
                 * @member {number|Long} time
                 * @memberof mts.common.marketdata.Trade
                 * @instance
                 */
                Trade.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Trade price.
                 * @member {number} price
                 * @memberof mts.common.marketdata.Trade
                 * @instance
                 */
                Trade.prototype.price = 0;

                /**
                 * Creates a new Trade instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {mts.common.marketdata.ITrade=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Trade} Trade instance
                 */
                Trade.create = function create(properties) {
                    return new Trade(properties);
                };

                /**
                 * Encodes the specified Trade message. Does not implicitly {@link mts.common.marketdata.Trade.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {mts.common.marketdata.ITrade} message Trade message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Trade.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.time);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.price);
                    return writer;
                };

                /**
                 * Encodes the specified Trade message, length delimited. Does not implicitly {@link mts.common.marketdata.Trade.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {mts.common.marketdata.ITrade} message Trade message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Trade.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Trade message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Trade} Trade
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Trade.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Trade();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.symbol = reader.string();
                                break;
                            }
                        case 2: {
                                message.time = reader.int64();
                                break;
                            }
                        case 3: {
                                message.price = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Trade message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Trade} Trade
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Trade.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Trade message.
                 * @function verify
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Trade.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (typeof message.price !== "number")
                            return "price: number expected";
                    return null;
                };

                /**
                 * Creates a Trade message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Trade} Trade
                 */
                Trade.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Trade)
                        return object;
                    let message = new $root.mts.common.marketdata.Trade();
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.price != null)
                        message.price = Number(object.price);
                    return message;
                };

                /**
                 * Creates a plain object from a Trade message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {mts.common.marketdata.Trade} message Trade
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Trade.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.symbol = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                        object.price = 0;
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                    return object;
                };

                /**
                 * Converts this Trade to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Trade
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Trade.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Trade
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Trade
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Trade.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Trade";
                };

                return Trade;
            })();

            marketdata.GetCandlesRequest = (function() {

                /**
                 * Properties of a GetCandlesRequest.
                 * @memberof mts.common.marketdata
                 * @interface IGetCandlesRequest
                 * @property {string|null} [symbol] GetCandlesRequest symbol
                 * @property {Array.<string>|null} [symbols] GetCandlesRequest symbols
                 * @property {string|null} [from] GetCandlesRequest from
                 * @property {string|null} [to] GetCandlesRequest to
                 * @property {string|null} [interval] GetCandlesRequest interval
                 */

                /**
                 * Constructs a new GetCandlesRequest.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a GetCandlesRequest.
                 * @implements IGetCandlesRequest
                 * @constructor
                 * @param {mts.common.marketdata.IGetCandlesRequest=} [properties] Properties to set
                 */
                function GetCandlesRequest(properties) {
                    this.symbols = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCandlesRequest symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @instance
                 */
                GetCandlesRequest.prototype.symbol = "";

                /**
                 * GetCandlesRequest symbols.
                 * @member {Array.<string>} symbols
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @instance
                 */
                GetCandlesRequest.prototype.symbols = $util.emptyArray;

                /**
                 * GetCandlesRequest from.
                 * @member {string} from
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @instance
                 */
                GetCandlesRequest.prototype.from = "";

                /**
                 * GetCandlesRequest to.
                 * @member {string} to
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @instance
                 */
                GetCandlesRequest.prototype.to = "";

                /**
                 * GetCandlesRequest interval.
                 * @member {string} interval
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @instance
                 */
                GetCandlesRequest.prototype.interval = "";

                /**
                 * Creates a new GetCandlesRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {mts.common.marketdata.IGetCandlesRequest=} [properties] Properties to set
                 * @returns {mts.common.marketdata.GetCandlesRequest} GetCandlesRequest instance
                 */
                GetCandlesRequest.create = function create(properties) {
                    return new GetCandlesRequest(properties);
                };

                /**
                 * Encodes the specified GetCandlesRequest message. Does not implicitly {@link mts.common.marketdata.GetCandlesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {mts.common.marketdata.IGetCandlesRequest} message GetCandlesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCandlesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                    if (message.symbols != null && message.symbols.length)
                        for (let i = 0; i < message.symbols.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbols[i]);
                    if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.from);
                    if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.to);
                    if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.interval);
                    return writer;
                };

                /**
                 * Encodes the specified GetCandlesRequest message, length delimited. Does not implicitly {@link mts.common.marketdata.GetCandlesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {mts.common.marketdata.IGetCandlesRequest} message GetCandlesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCandlesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCandlesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.GetCandlesRequest} GetCandlesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCandlesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.GetCandlesRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.symbol = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.symbols && message.symbols.length))
                                    message.symbols = [];
                                message.symbols.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.from = reader.string();
                                break;
                            }
                        case 4: {
                                message.to = reader.string();
                                break;
                            }
                        case 5: {
                                message.interval = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCandlesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.GetCandlesRequest} GetCandlesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCandlesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCandlesRequest message.
                 * @function verify
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCandlesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    if (message.symbols != null && message.hasOwnProperty("symbols")) {
                        if (!Array.isArray(message.symbols))
                            return "symbols: array expected";
                        for (let i = 0; i < message.symbols.length; ++i)
                            if (!$util.isString(message.symbols[i]))
                                return "symbols: string[] expected";
                    }
                    if (message.from != null && message.hasOwnProperty("from"))
                        if (!$util.isString(message.from))
                            return "from: string expected";
                    if (message.to != null && message.hasOwnProperty("to"))
                        if (!$util.isString(message.to))
                            return "to: string expected";
                    if (message.interval != null && message.hasOwnProperty("interval"))
                        if (!$util.isString(message.interval))
                            return "interval: string expected";
                    return null;
                };

                /**
                 * Creates a GetCandlesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.GetCandlesRequest} GetCandlesRequest
                 */
                GetCandlesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.GetCandlesRequest)
                        return object;
                    let message = new $root.mts.common.marketdata.GetCandlesRequest();
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    if (object.symbols) {
                        if (!Array.isArray(object.symbols))
                            throw TypeError(".mts.common.marketdata.GetCandlesRequest.symbols: array expected");
                        message.symbols = [];
                        for (let i = 0; i < object.symbols.length; ++i)
                            message.symbols[i] = String(object.symbols[i]);
                    }
                    if (object.from != null)
                        message.from = String(object.from);
                    if (object.to != null)
                        message.to = String(object.to);
                    if (object.interval != null)
                        message.interval = String(object.interval);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCandlesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {mts.common.marketdata.GetCandlesRequest} message GetCandlesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCandlesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.symbols = [];
                    if (options.defaults) {
                        object.symbol = "";
                        object.from = "";
                        object.to = "";
                        object.interval = "";
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    if (message.symbols && message.symbols.length) {
                        object.symbols = [];
                        for (let j = 0; j < message.symbols.length; ++j)
                            object.symbols[j] = message.symbols[j];
                    }
                    if (message.from != null && message.hasOwnProperty("from"))
                        object.from = message.from;
                    if (message.to != null && message.hasOwnProperty("to"))
                        object.to = message.to;
                    if (message.interval != null && message.hasOwnProperty("interval"))
                        object.interval = message.interval;
                    return object;
                };

                /**
                 * Converts this GetCandlesRequest to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCandlesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetCandlesRequest
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.GetCandlesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCandlesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.GetCandlesRequest";
                };

                return GetCandlesRequest;
            })();

            marketdata.Instrument = (function() {

                /**
                 * Properties of an Instrument.
                 * @memberof mts.common.marketdata
                 * @interface IInstrument
                 * @property {string|null} [symbol] Instrument symbol
                 * @property {string|null} [description] Instrument description
                 * @property {string|null} [mic] Instrument mic
                 * @property {string|null} [country] Instrument country
                 * @property {string|null} [type] Instrument type
                 * @property {Array.<string>|null} [subtypes] Instrument subtypes
                 */

                /**
                 * Constructs a new Instrument.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents an Instrument.
                 * @implements IInstrument
                 * @constructor
                 * @param {mts.common.marketdata.IInstrument=} [properties] Properties to set
                 */
                function Instrument(properties) {
                    this.subtypes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Instrument symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.Instrument
                 * @instance
                 */
                Instrument.prototype.symbol = "";

                /**
                 * Instrument description.
                 * @member {string} description
                 * @memberof mts.common.marketdata.Instrument
                 * @instance
                 */
                Instrument.prototype.description = "";

                /**
                 * Instrument mic.
                 * @member {string} mic
                 * @memberof mts.common.marketdata.Instrument
                 * @instance
                 */
                Instrument.prototype.mic = "";

                /**
                 * Instrument country.
                 * @member {string} country
                 * @memberof mts.common.marketdata.Instrument
                 * @instance
                 */
                Instrument.prototype.country = "";

                /**
                 * Instrument type.
                 * @member {string} type
                 * @memberof mts.common.marketdata.Instrument
                 * @instance
                 */
                Instrument.prototype.type = "";

                /**
                 * Instrument subtypes.
                 * @member {Array.<string>} subtypes
                 * @memberof mts.common.marketdata.Instrument
                 * @instance
                 */
                Instrument.prototype.subtypes = $util.emptyArray;

                /**
                 * Creates a new Instrument instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {mts.common.marketdata.IInstrument=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Instrument} Instrument instance
                 */
                Instrument.create = function create(properties) {
                    return new Instrument(properties);
                };

                /**
                 * Encodes the specified Instrument message. Does not implicitly {@link mts.common.marketdata.Instrument.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {mts.common.marketdata.IInstrument} message Instrument message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Instrument.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                    if (message.mic != null && Object.hasOwnProperty.call(message, "mic"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.mic);
                    if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.country);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.type);
                    if (message.subtypes != null && message.subtypes.length)
                        for (let i = 0; i < message.subtypes.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.subtypes[i]);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.description);
                    return writer;
                };

                /**
                 * Encodes the specified Instrument message, length delimited. Does not implicitly {@link mts.common.marketdata.Instrument.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {mts.common.marketdata.IInstrument} message Instrument message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Instrument.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Instrument message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Instrument} Instrument
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Instrument.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Instrument();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.symbol = reader.string();
                                break;
                            }
                        case 6: {
                                message.description = reader.string();
                                break;
                            }
                        case 2: {
                                message.mic = reader.string();
                                break;
                            }
                        case 3: {
                                message.country = reader.string();
                                break;
                            }
                        case 4: {
                                message.type = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.subtypes && message.subtypes.length))
                                    message.subtypes = [];
                                message.subtypes.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Instrument message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Instrument} Instrument
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Instrument.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Instrument message.
                 * @function verify
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Instrument.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.mic != null && message.hasOwnProperty("mic"))
                        if (!$util.isString(message.mic))
                            return "mic: string expected";
                    if (message.country != null && message.hasOwnProperty("country"))
                        if (!$util.isString(message.country))
                            return "country: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.subtypes != null && message.hasOwnProperty("subtypes")) {
                        if (!Array.isArray(message.subtypes))
                            return "subtypes: array expected";
                        for (let i = 0; i < message.subtypes.length; ++i)
                            if (!$util.isString(message.subtypes[i]))
                                return "subtypes: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates an Instrument message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Instrument} Instrument
                 */
                Instrument.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Instrument)
                        return object;
                    let message = new $root.mts.common.marketdata.Instrument();
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.mic != null)
                        message.mic = String(object.mic);
                    if (object.country != null)
                        message.country = String(object.country);
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.subtypes) {
                        if (!Array.isArray(object.subtypes))
                            throw TypeError(".mts.common.marketdata.Instrument.subtypes: array expected");
                        message.subtypes = [];
                        for (let i = 0; i < object.subtypes.length; ++i)
                            message.subtypes[i] = String(object.subtypes[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Instrument message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {mts.common.marketdata.Instrument} message Instrument
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Instrument.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.subtypes = [];
                    if (options.defaults) {
                        object.symbol = "";
                        object.mic = "";
                        object.country = "";
                        object.type = "";
                        object.description = "";
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    if (message.mic != null && message.hasOwnProperty("mic"))
                        object.mic = message.mic;
                    if (message.country != null && message.hasOwnProperty("country"))
                        object.country = message.country;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.subtypes && message.subtypes.length) {
                        object.subtypes = [];
                        for (let j = 0; j < message.subtypes.length; ++j)
                            object.subtypes[j] = message.subtypes[j];
                    }
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    return object;
                };

                /**
                 * Converts this Instrument to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Instrument
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Instrument.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Instrument
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Instrument
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Instrument.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Instrument";
                };

                return Instrument;
            })();

            marketdata.Candle = (function() {

                /**
                 * Properties of a Candle.
                 * @memberof mts.common.marketdata
                 * @interface ICandle
                 * @property {number|Long|null} [time] Candle time
                 * @property {string|null} [symbol] Candle symbol
                 * @property {number|null} [open] Candle open
                 * @property {number|null} [high] Candle high
                 * @property {number|null} [low] Candle low
                 * @property {number|null} [close] Candle close
                 * @property {number|Long|null} [volume] Candle volume
                 * @property {string|null} [date] Candle date
                 * @property {number|null} [volumeAsDouble] Candle volumeAsDouble
                 */

                /**
                 * Constructs a new Candle.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Candle.
                 * @implements ICandle
                 * @constructor
                 * @param {mts.common.marketdata.ICandle=} [properties] Properties to set
                 */
                function Candle(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Candle time.
                 * @member {number|Long} time
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Candle symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.symbol = "";

                /**
                 * Candle open.
                 * @member {number} open
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.open = 0;

                /**
                 * Candle high.
                 * @member {number} high
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.high = 0;

                /**
                 * Candle low.
                 * @member {number} low
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.low = 0;

                /**
                 * Candle close.
                 * @member {number} close
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.close = 0;

                /**
                 * Candle volume.
                 * @member {number|Long} volume
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Candle date.
                 * @member {string} date
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.date = "";

                /**
                 * Candle volumeAsDouble.
                 * @member {number} volumeAsDouble
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 */
                Candle.prototype.volumeAsDouble = 0;

                /**
                 * Creates a new Candle instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {mts.common.marketdata.ICandle=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Candle} Candle instance
                 */
                Candle.create = function create(properties) {
                    return new Candle(properties);
                };

                /**
                 * Encodes the specified Candle message. Does not implicitly {@link mts.common.marketdata.Candle.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {mts.common.marketdata.ICandle} message Candle message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Candle.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                    if (message.open != null && Object.hasOwnProperty.call(message, "open"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.open);
                    if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                        writer.uint32(/* id 4, wireType 1 =*/33).double(message.high);
                    if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                        writer.uint32(/* id 5, wireType 1 =*/41).double(message.low);
                    if (message.close != null && Object.hasOwnProperty.call(message, "close"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.close);
                    if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.volume);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.date);
                    if (message.volumeAsDouble != null && Object.hasOwnProperty.call(message, "volumeAsDouble"))
                        writer.uint32(/* id 9, wireType 1 =*/73).double(message.volumeAsDouble);
                    return writer;
                };

                /**
                 * Encodes the specified Candle message, length delimited. Does not implicitly {@link mts.common.marketdata.Candle.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {mts.common.marketdata.ICandle} message Candle message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Candle.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Candle message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Candle} Candle
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Candle.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Candle();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        case 2: {
                                message.symbol = reader.string();
                                break;
                            }
                        case 3: {
                                message.open = reader.double();
                                break;
                            }
                        case 4: {
                                message.high = reader.double();
                                break;
                            }
                        case 5: {
                                message.low = reader.double();
                                break;
                            }
                        case 6: {
                                message.close = reader.double();
                                break;
                            }
                        case 7: {
                                message.volume = reader.int64();
                                break;
                            }
                        case 8: {
                                message.date = reader.string();
                                break;
                            }
                        case 9: {
                                message.volumeAsDouble = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Candle message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Candle} Candle
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Candle.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Candle message.
                 * @function verify
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Candle.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    if (message.open != null && message.hasOwnProperty("open"))
                        if (typeof message.open !== "number")
                            return "open: number expected";
                    if (message.high != null && message.hasOwnProperty("high"))
                        if (typeof message.high !== "number")
                            return "high: number expected";
                    if (message.low != null && message.hasOwnProperty("low"))
                        if (typeof message.low !== "number")
                            return "low: number expected";
                    if (message.close != null && message.hasOwnProperty("close"))
                        if (typeof message.close !== "number")
                            return "close: number expected";
                    if (message.volume != null && message.hasOwnProperty("volume"))
                        if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                            return "volume: integer|Long expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    if (message.volumeAsDouble != null && message.hasOwnProperty("volumeAsDouble"))
                        if (typeof message.volumeAsDouble !== "number")
                            return "volumeAsDouble: number expected";
                    return null;
                };

                /**
                 * Creates a Candle message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Candle} Candle
                 */
                Candle.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Candle)
                        return object;
                    let message = new $root.mts.common.marketdata.Candle();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    if (object.open != null)
                        message.open = Number(object.open);
                    if (object.high != null)
                        message.high = Number(object.high);
                    if (object.low != null)
                        message.low = Number(object.low);
                    if (object.close != null)
                        message.close = Number(object.close);
                    if (object.volume != null)
                        if ($util.Long)
                            (message.volume = $util.Long.fromValue(object.volume)).unsigned = false;
                        else if (typeof object.volume === "string")
                            message.volume = parseInt(object.volume, 10);
                        else if (typeof object.volume === "number")
                            message.volume = object.volume;
                        else if (typeof object.volume === "object")
                            message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber();
                    if (object.date != null)
                        message.date = String(object.date);
                    if (object.volumeAsDouble != null)
                        message.volumeAsDouble = Number(object.volumeAsDouble);
                    return message;
                };

                /**
                 * Creates a plain object from a Candle message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {mts.common.marketdata.Candle} message Candle
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Candle.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                        object.symbol = "";
                        object.open = 0;
                        object.high = 0;
                        object.low = 0;
                        object.close = 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.volume = options.longs === String ? "0" : 0;
                        object.date = "";
                        object.volumeAsDouble = 0;
                    }
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    if (message.open != null && message.hasOwnProperty("open"))
                        object.open = options.json && !isFinite(message.open) ? String(message.open) : message.open;
                    if (message.high != null && message.hasOwnProperty("high"))
                        object.high = options.json && !isFinite(message.high) ? String(message.high) : message.high;
                    if (message.low != null && message.hasOwnProperty("low"))
                        object.low = options.json && !isFinite(message.low) ? String(message.low) : message.low;
                    if (message.close != null && message.hasOwnProperty("close"))
                        object.close = options.json && !isFinite(message.close) ? String(message.close) : message.close;
                    if (message.volume != null && message.hasOwnProperty("volume"))
                        if (typeof message.volume === "number")
                            object.volume = options.longs === String ? String(message.volume) : message.volume;
                        else
                            object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber() : message.volume;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    if (message.volumeAsDouble != null && message.hasOwnProperty("volumeAsDouble"))
                        object.volumeAsDouble = options.json && !isFinite(message.volumeAsDouble) ? String(message.volumeAsDouble) : message.volumeAsDouble;
                    return object;
                };

                /**
                 * Converts this Candle to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Candle
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Candle.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Candle
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Candle
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Candle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Candle";
                };

                return Candle;
            })();

            marketdata.GetCandlesResponse = (function() {

                /**
                 * Properties of a GetCandlesResponse.
                 * @memberof mts.common.marketdata
                 * @interface IGetCandlesResponse
                 * @property {Array.<mts.common.marketdata.ICandle>|null} [candles] GetCandlesResponse candles
                 * @property {string|null} [symbol] GetCandlesResponse symbol
                 */

                /**
                 * Constructs a new GetCandlesResponse.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a GetCandlesResponse.
                 * @implements IGetCandlesResponse
                 * @constructor
                 * @param {mts.common.marketdata.IGetCandlesResponse=} [properties] Properties to set
                 */
                function GetCandlesResponse(properties) {
                    this.candles = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCandlesResponse candles.
                 * @member {Array.<mts.common.marketdata.ICandle>} candles
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @instance
                 */
                GetCandlesResponse.prototype.candles = $util.emptyArray;

                /**
                 * GetCandlesResponse symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @instance
                 */
                GetCandlesResponse.prototype.symbol = "";

                /**
                 * Creates a new GetCandlesResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {mts.common.marketdata.IGetCandlesResponse=} [properties] Properties to set
                 * @returns {mts.common.marketdata.GetCandlesResponse} GetCandlesResponse instance
                 */
                GetCandlesResponse.create = function create(properties) {
                    return new GetCandlesResponse(properties);
                };

                /**
                 * Encodes the specified GetCandlesResponse message. Does not implicitly {@link mts.common.marketdata.GetCandlesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {mts.common.marketdata.IGetCandlesResponse} message GetCandlesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCandlesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.candles != null && message.candles.length)
                        for (let i = 0; i < message.candles.length; ++i)
                            $root.mts.common.marketdata.Candle.encode(message.candles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                    return writer;
                };

                /**
                 * Encodes the specified GetCandlesResponse message, length delimited. Does not implicitly {@link mts.common.marketdata.GetCandlesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {mts.common.marketdata.IGetCandlesResponse} message GetCandlesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCandlesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCandlesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.GetCandlesResponse} GetCandlesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCandlesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.GetCandlesResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.candles && message.candles.length))
                                    message.candles = [];
                                message.candles.push($root.mts.common.marketdata.Candle.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.symbol = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCandlesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.GetCandlesResponse} GetCandlesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCandlesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCandlesResponse message.
                 * @function verify
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCandlesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.candles != null && message.hasOwnProperty("candles")) {
                        if (!Array.isArray(message.candles))
                            return "candles: array expected";
                        for (let i = 0; i < message.candles.length; ++i) {
                            let error = $root.mts.common.marketdata.Candle.verify(message.candles[i]);
                            if (error)
                                return "candles." + error;
                        }
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    return null;
                };

                /**
                 * Creates a GetCandlesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.GetCandlesResponse} GetCandlesResponse
                 */
                GetCandlesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.GetCandlesResponse)
                        return object;
                    let message = new $root.mts.common.marketdata.GetCandlesResponse();
                    if (object.candles) {
                        if (!Array.isArray(object.candles))
                            throw TypeError(".mts.common.marketdata.GetCandlesResponse.candles: array expected");
                        message.candles = [];
                        for (let i = 0; i < object.candles.length; ++i) {
                            if (typeof object.candles[i] !== "object")
                                throw TypeError(".mts.common.marketdata.GetCandlesResponse.candles: object expected");
                            message.candles[i] = $root.mts.common.marketdata.Candle.fromObject(object.candles[i]);
                        }
                    }
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCandlesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {mts.common.marketdata.GetCandlesResponse} message GetCandlesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCandlesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.candles = [];
                    if (options.defaults)
                        object.symbol = "";
                    if (message.candles && message.candles.length) {
                        object.candles = [];
                        for (let j = 0; j < message.candles.length; ++j)
                            object.candles[j] = $root.mts.common.marketdata.Candle.toObject(message.candles[j], options);
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    return object;
                };

                /**
                 * Converts this GetCandlesResponse to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCandlesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetCandlesResponse
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.GetCandlesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCandlesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.GetCandlesResponse";
                };

                return GetCandlesResponse;
            })();

            marketdata.GetInstrumentsRequest = (function() {

                /**
                 * Properties of a GetInstrumentsRequest.
                 * @memberof mts.common.marketdata
                 * @interface IGetInstrumentsRequest
                 * @property {Array.<string>|null} [exchanges] GetInstrumentsRequest exchanges
                 */

                /**
                 * Constructs a new GetInstrumentsRequest.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a GetInstrumentsRequest.
                 * @implements IGetInstrumentsRequest
                 * @constructor
                 * @param {mts.common.marketdata.IGetInstrumentsRequest=} [properties] Properties to set
                 */
                function GetInstrumentsRequest(properties) {
                    this.exchanges = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetInstrumentsRequest exchanges.
                 * @member {Array.<string>} exchanges
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @instance
                 */
                GetInstrumentsRequest.prototype.exchanges = $util.emptyArray;

                /**
                 * Creates a new GetInstrumentsRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {mts.common.marketdata.IGetInstrumentsRequest=} [properties] Properties to set
                 * @returns {mts.common.marketdata.GetInstrumentsRequest} GetInstrumentsRequest instance
                 */
                GetInstrumentsRequest.create = function create(properties) {
                    return new GetInstrumentsRequest(properties);
                };

                /**
                 * Encodes the specified GetInstrumentsRequest message. Does not implicitly {@link mts.common.marketdata.GetInstrumentsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {mts.common.marketdata.IGetInstrumentsRequest} message GetInstrumentsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstrumentsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.exchanges != null && message.exchanges.length)
                        for (let i = 0; i < message.exchanges.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.exchanges[i]);
                    return writer;
                };

                /**
                 * Encodes the specified GetInstrumentsRequest message, length delimited. Does not implicitly {@link mts.common.marketdata.GetInstrumentsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {mts.common.marketdata.IGetInstrumentsRequest} message GetInstrumentsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstrumentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetInstrumentsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.GetInstrumentsRequest} GetInstrumentsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstrumentsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.GetInstrumentsRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.exchanges && message.exchanges.length))
                                    message.exchanges = [];
                                message.exchanges.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetInstrumentsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.GetInstrumentsRequest} GetInstrumentsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstrumentsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetInstrumentsRequest message.
                 * @function verify
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInstrumentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.exchanges != null && message.hasOwnProperty("exchanges")) {
                        if (!Array.isArray(message.exchanges))
                            return "exchanges: array expected";
                        for (let i = 0; i < message.exchanges.length; ++i)
                            if (!$util.isString(message.exchanges[i]))
                                return "exchanges: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a GetInstrumentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.GetInstrumentsRequest} GetInstrumentsRequest
                 */
                GetInstrumentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.GetInstrumentsRequest)
                        return object;
                    let message = new $root.mts.common.marketdata.GetInstrumentsRequest();
                    if (object.exchanges) {
                        if (!Array.isArray(object.exchanges))
                            throw TypeError(".mts.common.marketdata.GetInstrumentsRequest.exchanges: array expected");
                        message.exchanges = [];
                        for (let i = 0; i < object.exchanges.length; ++i)
                            message.exchanges[i] = String(object.exchanges[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetInstrumentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {mts.common.marketdata.GetInstrumentsRequest} message GetInstrumentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInstrumentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.exchanges = [];
                    if (message.exchanges && message.exchanges.length) {
                        object.exchanges = [];
                        for (let j = 0; j < message.exchanges.length; ++j)
                            object.exchanges[j] = message.exchanges[j];
                    }
                    return object;
                };

                /**
                 * Converts this GetInstrumentsRequest to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInstrumentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetInstrumentsRequest
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.GetInstrumentsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInstrumentsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.GetInstrumentsRequest";
                };

                return GetInstrumentsRequest;
            })();

            marketdata.GetInstrumentsResponse = (function() {

                /**
                 * Properties of a GetInstrumentsResponse.
                 * @memberof mts.common.marketdata
                 * @interface IGetInstrumentsResponse
                 * @property {Array.<mts.common.marketdata.IInstrument>|null} [instruments] GetInstrumentsResponse instruments
                 */

                /**
                 * Constructs a new GetInstrumentsResponse.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a GetInstrumentsResponse.
                 * @implements IGetInstrumentsResponse
                 * @constructor
                 * @param {mts.common.marketdata.IGetInstrumentsResponse=} [properties] Properties to set
                 */
                function GetInstrumentsResponse(properties) {
                    this.instruments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetInstrumentsResponse instruments.
                 * @member {Array.<mts.common.marketdata.IInstrument>} instruments
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @instance
                 */
                GetInstrumentsResponse.prototype.instruments = $util.emptyArray;

                /**
                 * Creates a new GetInstrumentsResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {mts.common.marketdata.IGetInstrumentsResponse=} [properties] Properties to set
                 * @returns {mts.common.marketdata.GetInstrumentsResponse} GetInstrumentsResponse instance
                 */
                GetInstrumentsResponse.create = function create(properties) {
                    return new GetInstrumentsResponse(properties);
                };

                /**
                 * Encodes the specified GetInstrumentsResponse message. Does not implicitly {@link mts.common.marketdata.GetInstrumentsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {mts.common.marketdata.IGetInstrumentsResponse} message GetInstrumentsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstrumentsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instruments != null && message.instruments.length)
                        for (let i = 0; i < message.instruments.length; ++i)
                            $root.mts.common.marketdata.Instrument.encode(message.instruments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetInstrumentsResponse message, length delimited. Does not implicitly {@link mts.common.marketdata.GetInstrumentsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {mts.common.marketdata.IGetInstrumentsResponse} message GetInstrumentsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstrumentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetInstrumentsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.GetInstrumentsResponse} GetInstrumentsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstrumentsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.GetInstrumentsResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.instruments && message.instruments.length))
                                    message.instruments = [];
                                message.instruments.push($root.mts.common.marketdata.Instrument.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetInstrumentsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.GetInstrumentsResponse} GetInstrumentsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstrumentsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetInstrumentsResponse message.
                 * @function verify
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInstrumentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instruments != null && message.hasOwnProperty("instruments")) {
                        if (!Array.isArray(message.instruments))
                            return "instruments: array expected";
                        for (let i = 0; i < message.instruments.length; ++i) {
                            let error = $root.mts.common.marketdata.Instrument.verify(message.instruments[i]);
                            if (error)
                                return "instruments." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetInstrumentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.GetInstrumentsResponse} GetInstrumentsResponse
                 */
                GetInstrumentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.GetInstrumentsResponse)
                        return object;
                    let message = new $root.mts.common.marketdata.GetInstrumentsResponse();
                    if (object.instruments) {
                        if (!Array.isArray(object.instruments))
                            throw TypeError(".mts.common.marketdata.GetInstrumentsResponse.instruments: array expected");
                        message.instruments = [];
                        for (let i = 0; i < object.instruments.length; ++i) {
                            if (typeof object.instruments[i] !== "object")
                                throw TypeError(".mts.common.marketdata.GetInstrumentsResponse.instruments: object expected");
                            message.instruments[i] = $root.mts.common.marketdata.Instrument.fromObject(object.instruments[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetInstrumentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {mts.common.marketdata.GetInstrumentsResponse} message GetInstrumentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInstrumentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.instruments = [];
                    if (message.instruments && message.instruments.length) {
                        object.instruments = [];
                        for (let j = 0; j < message.instruments.length; ++j)
                            object.instruments[j] = $root.mts.common.marketdata.Instrument.toObject(message.instruments[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetInstrumentsResponse to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInstrumentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetInstrumentsResponse
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.GetInstrumentsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInstrumentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.GetInstrumentsResponse";
                };

                return GetInstrumentsResponse;
            })();

            marketdata.Schedule = (function() {

                /**
                 * Properties of a Schedule.
                 * @memberof mts.common.marketdata
                 * @interface ISchedule
                 * @property {string|null} [id] Schedule id
                 * @property {Array.<string>|null} [holidays] Schedule holidays
                 * @property {Array.<string>|null} [halfDays] Schedule halfDays
                 * @property {string|null} [zoneId] Schedule zoneId
                 */

                /**
                 * Constructs a new Schedule.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Schedule.
                 * @implements ISchedule
                 * @constructor
                 * @param {mts.common.marketdata.ISchedule=} [properties] Properties to set
                 */
                function Schedule(properties) {
                    this.holidays = [];
                    this.halfDays = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Schedule id.
                 * @member {string} id
                 * @memberof mts.common.marketdata.Schedule
                 * @instance
                 */
                Schedule.prototype.id = "";

                /**
                 * Schedule holidays.
                 * @member {Array.<string>} holidays
                 * @memberof mts.common.marketdata.Schedule
                 * @instance
                 */
                Schedule.prototype.holidays = $util.emptyArray;

                /**
                 * Schedule halfDays.
                 * @member {Array.<string>} halfDays
                 * @memberof mts.common.marketdata.Schedule
                 * @instance
                 */
                Schedule.prototype.halfDays = $util.emptyArray;

                /**
                 * Schedule zoneId.
                 * @member {string} zoneId
                 * @memberof mts.common.marketdata.Schedule
                 * @instance
                 */
                Schedule.prototype.zoneId = "";

                /**
                 * Creates a new Schedule instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {mts.common.marketdata.ISchedule=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Schedule} Schedule instance
                 */
                Schedule.create = function create(properties) {
                    return new Schedule(properties);
                };

                /**
                 * Encodes the specified Schedule message. Does not implicitly {@link mts.common.marketdata.Schedule.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {mts.common.marketdata.ISchedule} message Schedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Schedule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.holidays != null && message.holidays.length)
                        for (let i = 0; i < message.holidays.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.holidays[i]);
                    if (message.halfDays != null && message.halfDays.length)
                        for (let i = 0; i < message.halfDays.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.halfDays[i]);
                    if (message.zoneId != null && Object.hasOwnProperty.call(message, "zoneId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.zoneId);
                    return writer;
                };

                /**
                 * Encodes the specified Schedule message, length delimited. Does not implicitly {@link mts.common.marketdata.Schedule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {mts.common.marketdata.ISchedule} message Schedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Schedule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Schedule message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Schedule} Schedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Schedule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Schedule();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.holidays && message.holidays.length))
                                    message.holidays = [];
                                message.holidays.push(reader.string());
                                break;
                            }
                        case 3: {
                                if (!(message.halfDays && message.halfDays.length))
                                    message.halfDays = [];
                                message.halfDays.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.zoneId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Schedule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Schedule} Schedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Schedule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Schedule message.
                 * @function verify
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Schedule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.holidays != null && message.hasOwnProperty("holidays")) {
                        if (!Array.isArray(message.holidays))
                            return "holidays: array expected";
                        for (let i = 0; i < message.holidays.length; ++i)
                            if (!$util.isString(message.holidays[i]))
                                return "holidays: string[] expected";
                    }
                    if (message.halfDays != null && message.hasOwnProperty("halfDays")) {
                        if (!Array.isArray(message.halfDays))
                            return "halfDays: array expected";
                        for (let i = 0; i < message.halfDays.length; ++i)
                            if (!$util.isString(message.halfDays[i]))
                                return "halfDays: string[] expected";
                    }
                    if (message.zoneId != null && message.hasOwnProperty("zoneId"))
                        if (!$util.isString(message.zoneId))
                            return "zoneId: string expected";
                    return null;
                };

                /**
                 * Creates a Schedule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Schedule} Schedule
                 */
                Schedule.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Schedule)
                        return object;
                    let message = new $root.mts.common.marketdata.Schedule();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.holidays) {
                        if (!Array.isArray(object.holidays))
                            throw TypeError(".mts.common.marketdata.Schedule.holidays: array expected");
                        message.holidays = [];
                        for (let i = 0; i < object.holidays.length; ++i)
                            message.holidays[i] = String(object.holidays[i]);
                    }
                    if (object.halfDays) {
                        if (!Array.isArray(object.halfDays))
                            throw TypeError(".mts.common.marketdata.Schedule.halfDays: array expected");
                        message.halfDays = [];
                        for (let i = 0; i < object.halfDays.length; ++i)
                            message.halfDays[i] = String(object.halfDays[i]);
                    }
                    if (object.zoneId != null)
                        message.zoneId = String(object.zoneId);
                    return message;
                };

                /**
                 * Creates a plain object from a Schedule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {mts.common.marketdata.Schedule} message Schedule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Schedule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.holidays = [];
                        object.halfDays = [];
                    }
                    if (options.defaults) {
                        object.id = "";
                        object.zoneId = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.holidays && message.holidays.length) {
                        object.holidays = [];
                        for (let j = 0; j < message.holidays.length; ++j)
                            object.holidays[j] = message.holidays[j];
                    }
                    if (message.halfDays && message.halfDays.length) {
                        object.halfDays = [];
                        for (let j = 0; j < message.halfDays.length; ++j)
                            object.halfDays[j] = message.halfDays[j];
                    }
                    if (message.zoneId != null && message.hasOwnProperty("zoneId"))
                        object.zoneId = message.zoneId;
                    return object;
                };

                /**
                 * Converts this Schedule to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Schedule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Schedule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Schedule
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Schedule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Schedule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Schedule";
                };

                return Schedule;
            })();

            marketdata.Day = (function() {

                /**
                 * Properties of a Day.
                 * @memberof mts.common.marketdata
                 * @interface IDay
                 * @property {string|null} [id] Day id
                 * @property {string|null} [scheduleId] Day scheduleId
                 * @property {string|null} [date] Day date
                 * @property {boolean|null} [holiday] Day holiday
                 * @property {Array.<mts.common.marketdata.ISession>|null} [sessions] Day sessions
                 * @property {boolean|null} [halfDay] Day halfDay
                 */

                /**
                 * Constructs a new Day.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Day.
                 * @implements IDay
                 * @constructor
                 * @param {mts.common.marketdata.IDay=} [properties] Properties to set
                 */
                function Day(properties) {
                    this.sessions = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Day id.
                 * @member {string} id
                 * @memberof mts.common.marketdata.Day
                 * @instance
                 */
                Day.prototype.id = "";

                /**
                 * Day scheduleId.
                 * @member {string} scheduleId
                 * @memberof mts.common.marketdata.Day
                 * @instance
                 */
                Day.prototype.scheduleId = "";

                /**
                 * Day date.
                 * @member {string} date
                 * @memberof mts.common.marketdata.Day
                 * @instance
                 */
                Day.prototype.date = "";

                /**
                 * Day holiday.
                 * @member {boolean} holiday
                 * @memberof mts.common.marketdata.Day
                 * @instance
                 */
                Day.prototype.holiday = false;

                /**
                 * Day sessions.
                 * @member {Array.<mts.common.marketdata.ISession>} sessions
                 * @memberof mts.common.marketdata.Day
                 * @instance
                 */
                Day.prototype.sessions = $util.emptyArray;

                /**
                 * Day halfDay.
                 * @member {boolean} halfDay
                 * @memberof mts.common.marketdata.Day
                 * @instance
                 */
                Day.prototype.halfDay = false;

                /**
                 * Creates a new Day instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {mts.common.marketdata.IDay=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Day} Day instance
                 */
                Day.create = function create(properties) {
                    return new Day(properties);
                };

                /**
                 * Encodes the specified Day message. Does not implicitly {@link mts.common.marketdata.Day.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {mts.common.marketdata.IDay} message Day message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Day.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.scheduleId);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.date);
                    if (message.holiday != null && Object.hasOwnProperty.call(message, "holiday"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.holiday);
                    if (message.sessions != null && message.sessions.length)
                        for (let i = 0; i < message.sessions.length; ++i)
                            $root.mts.common.marketdata.Session.encode(message.sessions[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.halfDay != null && Object.hasOwnProperty.call(message, "halfDay"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.halfDay);
                    return writer;
                };

                /**
                 * Encodes the specified Day message, length delimited. Does not implicitly {@link mts.common.marketdata.Day.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {mts.common.marketdata.IDay} message Day message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Day.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Day message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Day} Day
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Day.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Day();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.scheduleId = reader.string();
                                break;
                            }
                        case 3: {
                                message.date = reader.string();
                                break;
                            }
                        case 4: {
                                message.holiday = reader.bool();
                                break;
                            }
                        case 5: {
                                if (!(message.sessions && message.sessions.length))
                                    message.sessions = [];
                                message.sessions.push($root.mts.common.marketdata.Session.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                message.halfDay = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Day message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Day} Day
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Day.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Day message.
                 * @function verify
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Day.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                        if (!$util.isString(message.scheduleId))
                            return "scheduleId: string expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    if (message.holiday != null && message.hasOwnProperty("holiday"))
                        if (typeof message.holiday !== "boolean")
                            return "holiday: boolean expected";
                    if (message.sessions != null && message.hasOwnProperty("sessions")) {
                        if (!Array.isArray(message.sessions))
                            return "sessions: array expected";
                        for (let i = 0; i < message.sessions.length; ++i) {
                            let error = $root.mts.common.marketdata.Session.verify(message.sessions[i]);
                            if (error)
                                return "sessions." + error;
                        }
                    }
                    if (message.halfDay != null && message.hasOwnProperty("halfDay"))
                        if (typeof message.halfDay !== "boolean")
                            return "halfDay: boolean expected";
                    return null;
                };

                /**
                 * Creates a Day message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Day} Day
                 */
                Day.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Day)
                        return object;
                    let message = new $root.mts.common.marketdata.Day();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.scheduleId != null)
                        message.scheduleId = String(object.scheduleId);
                    if (object.date != null)
                        message.date = String(object.date);
                    if (object.holiday != null)
                        message.holiday = Boolean(object.holiday);
                    if (object.sessions) {
                        if (!Array.isArray(object.sessions))
                            throw TypeError(".mts.common.marketdata.Day.sessions: array expected");
                        message.sessions = [];
                        for (let i = 0; i < object.sessions.length; ++i) {
                            if (typeof object.sessions[i] !== "object")
                                throw TypeError(".mts.common.marketdata.Day.sessions: object expected");
                            message.sessions[i] = $root.mts.common.marketdata.Session.fromObject(object.sessions[i]);
                        }
                    }
                    if (object.halfDay != null)
                        message.halfDay = Boolean(object.halfDay);
                    return message;
                };

                /**
                 * Creates a plain object from a Day message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {mts.common.marketdata.Day} message Day
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Day.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.sessions = [];
                    if (options.defaults) {
                        object.id = "";
                        object.scheduleId = "";
                        object.date = "";
                        object.holiday = false;
                        object.halfDay = false;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                        object.scheduleId = message.scheduleId;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    if (message.holiday != null && message.hasOwnProperty("holiday"))
                        object.holiday = message.holiday;
                    if (message.sessions && message.sessions.length) {
                        object.sessions = [];
                        for (let j = 0; j < message.sessions.length; ++j)
                            object.sessions[j] = $root.mts.common.marketdata.Session.toObject(message.sessions[j], options);
                    }
                    if (message.halfDay != null && message.hasOwnProperty("halfDay"))
                        object.halfDay = message.halfDay;
                    return object;
                };

                /**
                 * Converts this Day to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Day
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Day.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Day
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Day
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Day.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Day";
                };

                return Day;
            })();

            marketdata.Session = (function() {

                /**
                 * Properties of a Session.
                 * @memberof mts.common.marketdata
                 * @interface ISession
                 * @property {string|null} [scheduleId] Session scheduleId
                 * @property {string|null} [dayId] Session dayId
                 * @property {mts.common.marketdata.Session.Type|null} [type] Session type
                 * @property {string|null} [date] Session date
                 * @property {string|null} [startDate] Session startDate
                 * @property {string|null} [endDate] Session endDate
                 * @property {number|Long|null} [startTime] Session startTime
                 * @property {number|Long|null} [endTime] Session endTime
                 */

                /**
                 * Constructs a new Session.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Session.
                 * @implements ISession
                 * @constructor
                 * @param {mts.common.marketdata.ISession=} [properties] Properties to set
                 */
                function Session(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Session scheduleId.
                 * @member {string} scheduleId
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.scheduleId = "";

                /**
                 * Session dayId.
                 * @member {string} dayId
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.dayId = "";

                /**
                 * Session type.
                 * @member {mts.common.marketdata.Session.Type} type
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.type = 0;

                /**
                 * Session date.
                 * @member {string} date
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.date = "";

                /**
                 * Session startDate.
                 * @member {string} startDate
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.startDate = "";

                /**
                 * Session endDate.
                 * @member {string} endDate
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.endDate = "";

                /**
                 * Session startTime.
                 * @member {number|Long} startTime
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Session endTime.
                 * @member {number|Long} endTime
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 */
                Session.prototype.endTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Session instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {mts.common.marketdata.ISession=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Session} Session instance
                 */
                Session.create = function create(properties) {
                    return new Session(properties);
                };

                /**
                 * Encodes the specified Session message. Does not implicitly {@link mts.common.marketdata.Session.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {mts.common.marketdata.ISession} message Session message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Session.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleId);
                    if (message.dayId != null && Object.hasOwnProperty.call(message, "dayId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dayId);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.date);
                    if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.startDate);
                    if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.endDate);
                    if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.startTime);
                    if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.endTime);
                    return writer;
                };

                /**
                 * Encodes the specified Session message, length delimited. Does not implicitly {@link mts.common.marketdata.Session.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {mts.common.marketdata.ISession} message Session message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Session.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Session message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Session} Session
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Session.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Session();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.scheduleId = reader.string();
                                break;
                            }
                        case 2: {
                                message.dayId = reader.string();
                                break;
                            }
                        case 3: {
                                message.type = reader.int32();
                                break;
                            }
                        case 4: {
                                message.date = reader.string();
                                break;
                            }
                        case 5: {
                                message.startDate = reader.string();
                                break;
                            }
                        case 6: {
                                message.endDate = reader.string();
                                break;
                            }
                        case 7: {
                                message.startTime = reader.int64();
                                break;
                            }
                        case 8: {
                                message.endTime = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Session message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Session} Session
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Session.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Session message.
                 * @function verify
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Session.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                        if (!$util.isString(message.scheduleId))
                            return "scheduleId: string expected";
                    if (message.dayId != null && message.hasOwnProperty("dayId"))
                        if (!$util.isString(message.dayId))
                            return "dayId: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        if (!$util.isString(message.startDate))
                            return "startDate: string expected";
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        if (!$util.isString(message.endDate))
                            return "endDate: string expected";
                    if (message.startTime != null && message.hasOwnProperty("startTime"))
                        if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                            return "startTime: integer|Long expected";
                    if (message.endTime != null && message.hasOwnProperty("endTime"))
                        if (!$util.isInteger(message.endTime) && !(message.endTime && $util.isInteger(message.endTime.low) && $util.isInteger(message.endTime.high)))
                            return "endTime: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Session message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Session} Session
                 */
                Session.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Session)
                        return object;
                    let message = new $root.mts.common.marketdata.Session();
                    if (object.scheduleId != null)
                        message.scheduleId = String(object.scheduleId);
                    if (object.dayId != null)
                        message.dayId = String(object.dayId);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "REGULAR":
                    case 0:
                        message.type = 0;
                        break;
                    case "PRE_MARKET":
                    case 1:
                        message.type = 1;
                        break;
                    case "AFTER_HOURS":
                    case 2:
                        message.type = 2;
                        break;
                    case "NO_TRADING":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.date != null)
                        message.date = String(object.date);
                    if (object.startDate != null)
                        message.startDate = String(object.startDate);
                    if (object.endDate != null)
                        message.endDate = String(object.endDate);
                    if (object.startTime != null)
                        if ($util.Long)
                            (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = false;
                        else if (typeof object.startTime === "string")
                            message.startTime = parseInt(object.startTime, 10);
                        else if (typeof object.startTime === "number")
                            message.startTime = object.startTime;
                        else if (typeof object.startTime === "object")
                            message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber();
                    if (object.endTime != null)
                        if ($util.Long)
                            (message.endTime = $util.Long.fromValue(object.endTime)).unsigned = false;
                        else if (typeof object.endTime === "string")
                            message.endTime = parseInt(object.endTime, 10);
                        else if (typeof object.endTime === "number")
                            message.endTime = object.endTime;
                        else if (typeof object.endTime === "object")
                            message.endTime = new $util.LongBits(object.endTime.low >>> 0, object.endTime.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Session message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {mts.common.marketdata.Session} message Session
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Session.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.scheduleId = "";
                        object.dayId = "";
                        object.type = options.enums === String ? "REGULAR" : 0;
                        object.date = "";
                        object.startDate = "";
                        object.endDate = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.endTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTime = options.longs === String ? "0" : 0;
                    }
                    if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                        object.scheduleId = message.scheduleId;
                    if (message.dayId != null && message.hasOwnProperty("dayId"))
                        object.dayId = message.dayId;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.mts.common.marketdata.Session.Type[message.type] === undefined ? message.type : $root.mts.common.marketdata.Session.Type[message.type] : message.type;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = message.startDate;
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        object.endDate = message.endDate;
                    if (message.startTime != null && message.hasOwnProperty("startTime"))
                        if (typeof message.startTime === "number")
                            object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                        else
                            object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber() : message.startTime;
                    if (message.endTime != null && message.hasOwnProperty("endTime"))
                        if (typeof message.endTime === "number")
                            object.endTime = options.longs === String ? String(message.endTime) : message.endTime;
                        else
                            object.endTime = options.longs === String ? $util.Long.prototype.toString.call(message.endTime) : options.longs === Number ? new $util.LongBits(message.endTime.low >>> 0, message.endTime.high >>> 0).toNumber() : message.endTime;
                    return object;
                };

                /**
                 * Converts this Session to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Session
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Session.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Session
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Session
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Session.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Session";
                };

                /**
                 * Type enum.
                 * @name mts.common.marketdata.Session.Type
                 * @enum {number}
                 * @property {number} REGULAR=0 REGULAR value
                 * @property {number} PRE_MARKET=1 PRE_MARKET value
                 * @property {number} AFTER_HOURS=2 AFTER_HOURS value
                 * @property {number} NO_TRADING=3 NO_TRADING value
                 */
                Session.Type = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REGULAR"] = 0;
                    values[valuesById[1] = "PRE_MARKET"] = 1;
                    values[valuesById[2] = "AFTER_HOURS"] = 2;
                    values[valuesById[3] = "NO_TRADING"] = 3;
                    return values;
                })();

                return Session;
            })();

            marketdata.Dividend = (function() {

                /**
                 * Properties of a Dividend.
                 * @memberof mts.common.marketdata
                 * @interface IDividend
                 * @property {string|null} [symbol] Dividend symbol
                 * @property {number|null} [amount] Dividend amount
                 * @property {string|null} [exDate] Dividend exDate
                 * @property {string|null} [recordDate] Dividend recordDate
                 * @property {string|null} [payDate] Dividend payDate
                 * @property {string|null} [type] Dividend type
                 * @property {string|null} [declarationDate] Dividend declarationDate
                 * @property {string|null} [dividendType] Dividend dividendType
                 * @property {number|null} [frequency] Dividend frequency
                 */

                /**
                 * Constructs a new Dividend.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Dividend.
                 * @implements IDividend
                 * @constructor
                 * @param {mts.common.marketdata.IDividend=} [properties] Properties to set
                 */
                function Dividend(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Dividend symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.symbol = "";

                /**
                 * Dividend amount.
                 * @member {number} amount
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.amount = 0;

                /**
                 * Dividend exDate.
                 * @member {string} exDate
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.exDate = "";

                /**
                 * Dividend recordDate.
                 * @member {string} recordDate
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.recordDate = "";

                /**
                 * Dividend payDate.
                 * @member {string} payDate
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.payDate = "";

                /**
                 * Dividend type.
                 * @member {string} type
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.type = "";

                /**
                 * Dividend declarationDate.
                 * @member {string} declarationDate
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.declarationDate = "";

                /**
                 * Dividend dividendType.
                 * @member {string} dividendType
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.dividendType = "";

                /**
                 * Dividend frequency.
                 * @member {number} frequency
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 */
                Dividend.prototype.frequency = 0;

                /**
                 * Creates a new Dividend instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {mts.common.marketdata.IDividend=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Dividend} Dividend instance
                 */
                Dividend.create = function create(properties) {
                    return new Dividend(properties);
                };

                /**
                 * Encodes the specified Dividend message. Does not implicitly {@link mts.common.marketdata.Dividend.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {mts.common.marketdata.IDividend} message Dividend message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Dividend.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                    if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
                    if (message.exDate != null && Object.hasOwnProperty.call(message, "exDate"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.exDate);
                    if (message.recordDate != null && Object.hasOwnProperty.call(message, "recordDate"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.recordDate);
                    if (message.payDate != null && Object.hasOwnProperty.call(message, "payDate"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.payDate);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.type);
                    if (message.declarationDate != null && Object.hasOwnProperty.call(message, "declarationDate"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.declarationDate);
                    if (message.dividendType != null && Object.hasOwnProperty.call(message, "dividendType"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.dividendType);
                    if (message.frequency != null && Object.hasOwnProperty.call(message, "frequency"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.frequency);
                    return writer;
                };

                /**
                 * Encodes the specified Dividend message, length delimited. Does not implicitly {@link mts.common.marketdata.Dividend.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {mts.common.marketdata.IDividend} message Dividend message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Dividend.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Dividend message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Dividend} Dividend
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Dividend.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Dividend();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.symbol = reader.string();
                                break;
                            }
                        case 2: {
                                message.amount = reader.double();
                                break;
                            }
                        case 3: {
                                message.exDate = reader.string();
                                break;
                            }
                        case 4: {
                                message.recordDate = reader.string();
                                break;
                            }
                        case 5: {
                                message.payDate = reader.string();
                                break;
                            }
                        case 6: {
                                message.type = reader.string();
                                break;
                            }
                        case 7: {
                                message.declarationDate = reader.string();
                                break;
                            }
                        case 8: {
                                message.dividendType = reader.string();
                                break;
                            }
                        case 9: {
                                message.frequency = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Dividend message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Dividend} Dividend
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Dividend.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Dividend message.
                 * @function verify
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Dividend.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        if (typeof message.amount !== "number")
                            return "amount: number expected";
                    if (message.exDate != null && message.hasOwnProperty("exDate"))
                        if (!$util.isString(message.exDate))
                            return "exDate: string expected";
                    if (message.recordDate != null && message.hasOwnProperty("recordDate"))
                        if (!$util.isString(message.recordDate))
                            return "recordDate: string expected";
                    if (message.payDate != null && message.hasOwnProperty("payDate"))
                        if (!$util.isString(message.payDate))
                            return "payDate: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.declarationDate != null && message.hasOwnProperty("declarationDate"))
                        if (!$util.isString(message.declarationDate))
                            return "declarationDate: string expected";
                    if (message.dividendType != null && message.hasOwnProperty("dividendType"))
                        if (!$util.isString(message.dividendType))
                            return "dividendType: string expected";
                    if (message.frequency != null && message.hasOwnProperty("frequency"))
                        if (!$util.isInteger(message.frequency))
                            return "frequency: integer expected";
                    return null;
                };

                /**
                 * Creates a Dividend message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Dividend} Dividend
                 */
                Dividend.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Dividend)
                        return object;
                    let message = new $root.mts.common.marketdata.Dividend();
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    if (object.amount != null)
                        message.amount = Number(object.amount);
                    if (object.exDate != null)
                        message.exDate = String(object.exDate);
                    if (object.recordDate != null)
                        message.recordDate = String(object.recordDate);
                    if (object.payDate != null)
                        message.payDate = String(object.payDate);
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.declarationDate != null)
                        message.declarationDate = String(object.declarationDate);
                    if (object.dividendType != null)
                        message.dividendType = String(object.dividendType);
                    if (object.frequency != null)
                        message.frequency = object.frequency | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a Dividend message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {mts.common.marketdata.Dividend} message Dividend
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Dividend.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.symbol = "";
                        object.amount = 0;
                        object.exDate = "";
                        object.recordDate = "";
                        object.payDate = "";
                        object.type = "";
                        object.declarationDate = "";
                        object.dividendType = "";
                        object.frequency = 0;
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                    if (message.exDate != null && message.hasOwnProperty("exDate"))
                        object.exDate = message.exDate;
                    if (message.recordDate != null && message.hasOwnProperty("recordDate"))
                        object.recordDate = message.recordDate;
                    if (message.payDate != null && message.hasOwnProperty("payDate"))
                        object.payDate = message.payDate;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.declarationDate != null && message.hasOwnProperty("declarationDate"))
                        object.declarationDate = message.declarationDate;
                    if (message.dividendType != null && message.hasOwnProperty("dividendType"))
                        object.dividendType = message.dividendType;
                    if (message.frequency != null && message.hasOwnProperty("frequency"))
                        object.frequency = message.frequency;
                    return object;
                };

                /**
                 * Converts this Dividend to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Dividend
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Dividend.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Dividend
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Dividend
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Dividend.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Dividend";
                };

                return Dividend;
            })();

            marketdata.Split = (function() {

                /**
                 * Properties of a Split.
                 * @memberof mts.common.marketdata
                 * @interface ISplit
                 * @property {string|null} [symbol] Split symbol
                 * @property {string|null} [exDate] Split exDate
                 * @property {number|null} [adjustmentFactor] Split adjustmentFactor
                 */

                /**
                 * Constructs a new Split.
                 * @memberof mts.common.marketdata
                 * @classdesc Represents a Split.
                 * @implements ISplit
                 * @constructor
                 * @param {mts.common.marketdata.ISplit=} [properties] Properties to set
                 */
                function Split(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Split symbol.
                 * @member {string} symbol
                 * @memberof mts.common.marketdata.Split
                 * @instance
                 */
                Split.prototype.symbol = "";

                /**
                 * Split exDate.
                 * @member {string} exDate
                 * @memberof mts.common.marketdata.Split
                 * @instance
                 */
                Split.prototype.exDate = "";

                /**
                 * Split adjustmentFactor.
                 * @member {number} adjustmentFactor
                 * @memberof mts.common.marketdata.Split
                 * @instance
                 */
                Split.prototype.adjustmentFactor = 0;

                /**
                 * Creates a new Split instance using the specified properties.
                 * @function create
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {mts.common.marketdata.ISplit=} [properties] Properties to set
                 * @returns {mts.common.marketdata.Split} Split instance
                 */
                Split.create = function create(properties) {
                    return new Split(properties);
                };

                /**
                 * Encodes the specified Split message. Does not implicitly {@link mts.common.marketdata.Split.verify|verify} messages.
                 * @function encode
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {mts.common.marketdata.ISplit} message Split message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Split.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                    if (message.exDate != null && Object.hasOwnProperty.call(message, "exDate"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.exDate);
                    if (message.adjustmentFactor != null && Object.hasOwnProperty.call(message, "adjustmentFactor"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.adjustmentFactor);
                    return writer;
                };

                /**
                 * Encodes the specified Split message, length delimited. Does not implicitly {@link mts.common.marketdata.Split.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {mts.common.marketdata.ISplit} message Split message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Split.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Split message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.common.marketdata.Split} Split
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Split.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.common.marketdata.Split();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.symbol = reader.string();
                                break;
                            }
                        case 2: {
                                message.exDate = reader.string();
                                break;
                            }
                        case 3: {
                                message.adjustmentFactor = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Split message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.common.marketdata.Split} Split
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Split.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Split message.
                 * @function verify
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Split.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        if (!$util.isString(message.symbol))
                            return "symbol: string expected";
                    if (message.exDate != null && message.hasOwnProperty("exDate"))
                        if (!$util.isString(message.exDate))
                            return "exDate: string expected";
                    if (message.adjustmentFactor != null && message.hasOwnProperty("adjustmentFactor"))
                        if (typeof message.adjustmentFactor !== "number")
                            return "adjustmentFactor: number expected";
                    return null;
                };

                /**
                 * Creates a Split message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.common.marketdata.Split} Split
                 */
                Split.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.common.marketdata.Split)
                        return object;
                    let message = new $root.mts.common.marketdata.Split();
                    if (object.symbol != null)
                        message.symbol = String(object.symbol);
                    if (object.exDate != null)
                        message.exDate = String(object.exDate);
                    if (object.adjustmentFactor != null)
                        message.adjustmentFactor = Number(object.adjustmentFactor);
                    return message;
                };

                /**
                 * Creates a plain object from a Split message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {mts.common.marketdata.Split} message Split
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Split.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.symbol = "";
                        object.exDate = "";
                        object.adjustmentFactor = 0;
                    }
                    if (message.symbol != null && message.hasOwnProperty("symbol"))
                        object.symbol = message.symbol;
                    if (message.exDate != null && message.hasOwnProperty("exDate"))
                        object.exDate = message.exDate;
                    if (message.adjustmentFactor != null && message.hasOwnProperty("adjustmentFactor"))
                        object.adjustmentFactor = options.json && !isFinite(message.adjustmentFactor) ? String(message.adjustmentFactor) : message.adjustmentFactor;
                    return object;
                };

                /**
                 * Converts this Split to JSON.
                 * @function toJSON
                 * @memberof mts.common.marketdata.Split
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Split.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Split
                 * @function getTypeUrl
                 * @memberof mts.common.marketdata.Split
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Split.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.common.marketdata.Split";
                };

                return Split;
            })();

            return marketdata;
        })();

        return common;
    })();

    mts.desk = (function() {

        /**
         * Namespace desk.
         * @memberof mts
         * @namespace
         */
        const desk = {};

        /**
         * Access enum.
         * @name mts.desk.Access
         * @enum {number}
         * @property {number} UNKNOWN_ACCESS=0 UNKNOWN_ACCESS value
         * @property {number} PUBLIC=1 PUBLIC value
         * @property {number} PRIVATE=2 PRIVATE value
         * @property {number} UNLISTED=3 UNLISTED value
         */
        desk.Access = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_ACCESS"] = 0;
            values[valuesById[1] = "PUBLIC"] = 1;
            values[valuesById[2] = "PRIVATE"] = 2;
            values[valuesById[3] = "UNLISTED"] = 3;
            return values;
        })();

        desk.Tick = (function() {

            /**
             * Properties of a Tick.
             * @memberof mts.desk
             * @interface ITick
             * @property {string|null} [date] Tick date
             * @property {mts.desk.Tick.Type|null} [type] Tick type
             * @property {boolean|null} [realtime] Tick realtime
             */

            /**
             * Constructs a new Tick.
             * @memberof mts.desk
             * @classdesc Represents a Tick.
             * @implements ITick
             * @constructor
             * @param {mts.desk.ITick=} [properties] Properties to set
             */
            function Tick(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Tick date.
             * @member {string} date
             * @memberof mts.desk.Tick
             * @instance
             */
            Tick.prototype.date = "";

            /**
             * Tick type.
             * @member {mts.desk.Tick.Type} type
             * @memberof mts.desk.Tick
             * @instance
             */
            Tick.prototype.type = 0;

            /**
             * Tick realtime.
             * @member {boolean} realtime
             * @memberof mts.desk.Tick
             * @instance
             */
            Tick.prototype.realtime = false;

            /**
             * Creates a new Tick instance using the specified properties.
             * @function create
             * @memberof mts.desk.Tick
             * @static
             * @param {mts.desk.ITick=} [properties] Properties to set
             * @returns {mts.desk.Tick} Tick instance
             */
            Tick.create = function create(properties) {
                return new Tick(properties);
            };

            /**
             * Encodes the specified Tick message. Does not implicitly {@link mts.desk.Tick.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.Tick
             * @static
             * @param {mts.desk.ITick} message Tick message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tick.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.date);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                if (message.realtime != null && Object.hasOwnProperty.call(message, "realtime"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.realtime);
                return writer;
            };

            /**
             * Encodes the specified Tick message, length delimited. Does not implicitly {@link mts.desk.Tick.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.Tick
             * @static
             * @param {mts.desk.ITick} message Tick message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tick.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Tick message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.Tick
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.Tick} Tick
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tick.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.Tick();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.date = reader.string();
                            break;
                        }
                    case 2: {
                            message.type = reader.int32();
                            break;
                        }
                    case 3: {
                            message.realtime = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Tick message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.Tick
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.Tick} Tick
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tick.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Tick message.
             * @function verify
             * @memberof mts.desk.Tick
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Tick.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.date != null && message.hasOwnProperty("date"))
                    if (!$util.isString(message.date))
                        return "date: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.realtime != null && message.hasOwnProperty("realtime"))
                    if (typeof message.realtime !== "boolean")
                        return "realtime: boolean expected";
                return null;
            };

            /**
             * Creates a Tick message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.Tick
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.Tick} Tick
             */
            Tick.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.Tick)
                    return object;
                let message = new $root.mts.desk.Tick();
                if (object.date != null)
                    message.date = String(object.date);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.type = 0;
                    break;
                case "PREMARKET_OPEN":
                case 1:
                    message.type = 1;
                    break;
                case "OPEN":
                case 2:
                    message.type = 2;
                    break;
                case "CLOSE":
                case 3:
                    message.type = 3;
                    break;
                case "AFTER_HOURS_OPEN":
                case 4:
                    message.type = 4;
                    break;
                case "REGULAR":
                case 5:
                    message.type = 5;
                    break;
                case "EOF":
                case 6:
                    message.type = 6;
                    break;
                case "MAIN":
                case 7:
                    message.type = 7;
                    break;
                case "INIT":
                case 8:
                    message.type = 8;
                    break;
                }
                if (object.realtime != null)
                    message.realtime = Boolean(object.realtime);
                return message;
            };

            /**
             * Creates a plain object from a Tick message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.Tick
             * @static
             * @param {mts.desk.Tick} message Tick
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Tick.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.date = "";
                    object.type = options.enums === String ? "UNKNOWN" : 0;
                    object.realtime = false;
                }
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = message.date;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.desk.Tick.Type[message.type] === undefined ? message.type : $root.mts.desk.Tick.Type[message.type] : message.type;
                if (message.realtime != null && message.hasOwnProperty("realtime"))
                    object.realtime = message.realtime;
                return object;
            };

            /**
             * Converts this Tick to JSON.
             * @function toJSON
             * @memberof mts.desk.Tick
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Tick.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Tick
             * @function getTypeUrl
             * @memberof mts.desk.Tick
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Tick.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.Tick";
            };

            /**
             * Type enum.
             * @name mts.desk.Tick.Type
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} PREMARKET_OPEN=1 PREMARKET_OPEN value
             * @property {number} OPEN=2 OPEN value
             * @property {number} CLOSE=3 CLOSE value
             * @property {number} AFTER_HOURS_OPEN=4 AFTER_HOURS_OPEN value
             * @property {number} REGULAR=5 REGULAR value
             * @property {number} EOF=6 EOF value
             * @property {number} MAIN=7 MAIN value
             * @property {number} INIT=8 INIT value
             */
            Tick.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "PREMARKET_OPEN"] = 1;
                values[valuesById[2] = "OPEN"] = 2;
                values[valuesById[3] = "CLOSE"] = 3;
                values[valuesById[4] = "AFTER_HOURS_OPEN"] = 4;
                values[valuesById[5] = "REGULAR"] = 5;
                values[valuesById[6] = "EOF"] = 6;
                values[valuesById[7] = "MAIN"] = 7;
                values[valuesById[8] = "INIT"] = 8;
                return values;
            })();

            return Tick;
        })();

        desk.AddTraderRequest = (function() {

            /**
             * Properties of an AddTraderRequest.
             * @memberof mts.desk
             * @interface IAddTraderRequest
             * @property {mts.desk.ITrader|null} [trader] AddTraderRequest trader
             */

            /**
             * Constructs a new AddTraderRequest.
             * @memberof mts.desk
             * @classdesc Represents an AddTraderRequest.
             * @implements IAddTraderRequest
             * @constructor
             * @param {mts.desk.IAddTraderRequest=} [properties] Properties to set
             */
            function AddTraderRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddTraderRequest trader.
             * @member {mts.desk.ITrader|null|undefined} trader
             * @memberof mts.desk.AddTraderRequest
             * @instance
             */
            AddTraderRequest.prototype.trader = null;

            /**
             * Creates a new AddTraderRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {mts.desk.IAddTraderRequest=} [properties] Properties to set
             * @returns {mts.desk.AddTraderRequest} AddTraderRequest instance
             */
            AddTraderRequest.create = function create(properties) {
                return new AddTraderRequest(properties);
            };

            /**
             * Encodes the specified AddTraderRequest message. Does not implicitly {@link mts.desk.AddTraderRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {mts.desk.IAddTraderRequest} message AddTraderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trader != null && Object.hasOwnProperty.call(message, "trader"))
                    $root.mts.desk.Trader.encode(message.trader, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddTraderRequest message, length delimited. Does not implicitly {@link mts.desk.AddTraderRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {mts.desk.IAddTraderRequest} message AddTraderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddTraderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.AddTraderRequest} AddTraderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.AddTraderRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.trader = $root.mts.desk.Trader.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddTraderRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.AddTraderRequest} AddTraderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddTraderRequest message.
             * @function verify
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddTraderRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trader != null && message.hasOwnProperty("trader")) {
                    let error = $root.mts.desk.Trader.verify(message.trader);
                    if (error)
                        return "trader." + error;
                }
                return null;
            };

            /**
             * Creates an AddTraderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.AddTraderRequest} AddTraderRequest
             */
            AddTraderRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.AddTraderRequest)
                    return object;
                let message = new $root.mts.desk.AddTraderRequest();
                if (object.trader != null) {
                    if (typeof object.trader !== "object")
                        throw TypeError(".mts.desk.AddTraderRequest.trader: object expected");
                    message.trader = $root.mts.desk.Trader.fromObject(object.trader);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddTraderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {mts.desk.AddTraderRequest} message AddTraderRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddTraderRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.trader = null;
                if (message.trader != null && message.hasOwnProperty("trader"))
                    object.trader = $root.mts.desk.Trader.toObject(message.trader, options);
                return object;
            };

            /**
             * Converts this AddTraderRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.AddTraderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddTraderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddTraderRequest
             * @function getTypeUrl
             * @memberof mts.desk.AddTraderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddTraderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.AddTraderRequest";
            };

            return AddTraderRequest;
        })();

        desk.AddTraderResponse = (function() {

            /**
             * Properties of an AddTraderResponse.
             * @memberof mts.desk
             * @interface IAddTraderResponse
             * @property {mts.desk.ITrader|null} [trader] AddTraderResponse trader
             */

            /**
             * Constructs a new AddTraderResponse.
             * @memberof mts.desk
             * @classdesc Represents an AddTraderResponse.
             * @implements IAddTraderResponse
             * @constructor
             * @param {mts.desk.IAddTraderResponse=} [properties] Properties to set
             */
            function AddTraderResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddTraderResponse trader.
             * @member {mts.desk.ITrader|null|undefined} trader
             * @memberof mts.desk.AddTraderResponse
             * @instance
             */
            AddTraderResponse.prototype.trader = null;

            /**
             * Creates a new AddTraderResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {mts.desk.IAddTraderResponse=} [properties] Properties to set
             * @returns {mts.desk.AddTraderResponse} AddTraderResponse instance
             */
            AddTraderResponse.create = function create(properties) {
                return new AddTraderResponse(properties);
            };

            /**
             * Encodes the specified AddTraderResponse message. Does not implicitly {@link mts.desk.AddTraderResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {mts.desk.IAddTraderResponse} message AddTraderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trader != null && Object.hasOwnProperty.call(message, "trader"))
                    $root.mts.desk.Trader.encode(message.trader, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddTraderResponse message, length delimited. Does not implicitly {@link mts.desk.AddTraderResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {mts.desk.IAddTraderResponse} message AddTraderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddTraderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.AddTraderResponse} AddTraderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.AddTraderResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.trader = $root.mts.desk.Trader.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddTraderResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.AddTraderResponse} AddTraderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddTraderResponse message.
             * @function verify
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddTraderResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trader != null && message.hasOwnProperty("trader")) {
                    let error = $root.mts.desk.Trader.verify(message.trader);
                    if (error)
                        return "trader." + error;
                }
                return null;
            };

            /**
             * Creates an AddTraderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.AddTraderResponse} AddTraderResponse
             */
            AddTraderResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.AddTraderResponse)
                    return object;
                let message = new $root.mts.desk.AddTraderResponse();
                if (object.trader != null) {
                    if (typeof object.trader !== "object")
                        throw TypeError(".mts.desk.AddTraderResponse.trader: object expected");
                    message.trader = $root.mts.desk.Trader.fromObject(object.trader);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddTraderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {mts.desk.AddTraderResponse} message AddTraderResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddTraderResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.trader = null;
                if (message.trader != null && message.hasOwnProperty("trader"))
                    object.trader = $root.mts.desk.Trader.toObject(message.trader, options);
                return object;
            };

            /**
             * Converts this AddTraderResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.AddTraderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddTraderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddTraderResponse
             * @function getTypeUrl
             * @memberof mts.desk.AddTraderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddTraderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.AddTraderResponse";
            };

            return AddTraderResponse;
        })();

        desk.UpdateTraderRequest = (function() {

            /**
             * Properties of an UpdateTraderRequest.
             * @memberof mts.desk
             * @interface IUpdateTraderRequest
             * @property {mts.desk.ITrader|null} [trader] UpdateTraderRequest trader
             */

            /**
             * Constructs a new UpdateTraderRequest.
             * @memberof mts.desk
             * @classdesc Represents an UpdateTraderRequest.
             * @implements IUpdateTraderRequest
             * @constructor
             * @param {mts.desk.IUpdateTraderRequest=} [properties] Properties to set
             */
            function UpdateTraderRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateTraderRequest trader.
             * @member {mts.desk.ITrader|null|undefined} trader
             * @memberof mts.desk.UpdateTraderRequest
             * @instance
             */
            UpdateTraderRequest.prototype.trader = null;

            /**
             * Creates a new UpdateTraderRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {mts.desk.IUpdateTraderRequest=} [properties] Properties to set
             * @returns {mts.desk.UpdateTraderRequest} UpdateTraderRequest instance
             */
            UpdateTraderRequest.create = function create(properties) {
                return new UpdateTraderRequest(properties);
            };

            /**
             * Encodes the specified UpdateTraderRequest message. Does not implicitly {@link mts.desk.UpdateTraderRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {mts.desk.IUpdateTraderRequest} message UpdateTraderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateTraderRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trader != null && Object.hasOwnProperty.call(message, "trader"))
                    $root.mts.desk.Trader.encode(message.trader, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateTraderRequest message, length delimited. Does not implicitly {@link mts.desk.UpdateTraderRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {mts.desk.IUpdateTraderRequest} message UpdateTraderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateTraderRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateTraderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.UpdateTraderRequest} UpdateTraderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateTraderRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.UpdateTraderRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.trader = $root.mts.desk.Trader.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateTraderRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.UpdateTraderRequest} UpdateTraderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateTraderRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateTraderRequest message.
             * @function verify
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateTraderRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trader != null && message.hasOwnProperty("trader")) {
                    let error = $root.mts.desk.Trader.verify(message.trader);
                    if (error)
                        return "trader." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateTraderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.UpdateTraderRequest} UpdateTraderRequest
             */
            UpdateTraderRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.UpdateTraderRequest)
                    return object;
                let message = new $root.mts.desk.UpdateTraderRequest();
                if (object.trader != null) {
                    if (typeof object.trader !== "object")
                        throw TypeError(".mts.desk.UpdateTraderRequest.trader: object expected");
                    message.trader = $root.mts.desk.Trader.fromObject(object.trader);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateTraderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {mts.desk.UpdateTraderRequest} message UpdateTraderRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateTraderRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.trader = null;
                if (message.trader != null && message.hasOwnProperty("trader"))
                    object.trader = $root.mts.desk.Trader.toObject(message.trader, options);
                return object;
            };

            /**
             * Converts this UpdateTraderRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.UpdateTraderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateTraderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateTraderRequest
             * @function getTypeUrl
             * @memberof mts.desk.UpdateTraderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateTraderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.UpdateTraderRequest";
            };

            return UpdateTraderRequest;
        })();

        desk.UpdateTraderResponse = (function() {

            /**
             * Properties of an UpdateTraderResponse.
             * @memberof mts.desk
             * @interface IUpdateTraderResponse
             */

            /**
             * Constructs a new UpdateTraderResponse.
             * @memberof mts.desk
             * @classdesc Represents an UpdateTraderResponse.
             * @implements IUpdateTraderResponse
             * @constructor
             * @param {mts.desk.IUpdateTraderResponse=} [properties] Properties to set
             */
            function UpdateTraderResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateTraderResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {mts.desk.IUpdateTraderResponse=} [properties] Properties to set
             * @returns {mts.desk.UpdateTraderResponse} UpdateTraderResponse instance
             */
            UpdateTraderResponse.create = function create(properties) {
                return new UpdateTraderResponse(properties);
            };

            /**
             * Encodes the specified UpdateTraderResponse message. Does not implicitly {@link mts.desk.UpdateTraderResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {mts.desk.IUpdateTraderResponse} message UpdateTraderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateTraderResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateTraderResponse message, length delimited. Does not implicitly {@link mts.desk.UpdateTraderResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {mts.desk.IUpdateTraderResponse} message UpdateTraderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateTraderResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateTraderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.UpdateTraderResponse} UpdateTraderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateTraderResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.UpdateTraderResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateTraderResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.UpdateTraderResponse} UpdateTraderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateTraderResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateTraderResponse message.
             * @function verify
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateTraderResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateTraderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.UpdateTraderResponse} UpdateTraderResponse
             */
            UpdateTraderResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.UpdateTraderResponse)
                    return object;
                return new $root.mts.desk.UpdateTraderResponse();
            };

            /**
             * Creates a plain object from an UpdateTraderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {mts.desk.UpdateTraderResponse} message UpdateTraderResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateTraderResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateTraderResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.UpdateTraderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateTraderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateTraderResponse
             * @function getTypeUrl
             * @memberof mts.desk.UpdateTraderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateTraderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.UpdateTraderResponse";
            };

            return UpdateTraderResponse;
        })();

        desk.AddTraderInstanceRequest = (function() {

            /**
             * Properties of an AddTraderInstanceRequest.
             * @memberof mts.desk
             * @interface IAddTraderInstanceRequest
             * @property {mts.desk.ITraderInstance|null} [traderInstance] AddTraderInstanceRequest traderInstance
             */

            /**
             * Constructs a new AddTraderInstanceRequest.
             * @memberof mts.desk
             * @classdesc Represents an AddTraderInstanceRequest.
             * @implements IAddTraderInstanceRequest
             * @constructor
             * @param {mts.desk.IAddTraderInstanceRequest=} [properties] Properties to set
             */
            function AddTraderInstanceRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddTraderInstanceRequest traderInstance.
             * @member {mts.desk.ITraderInstance|null|undefined} traderInstance
             * @memberof mts.desk.AddTraderInstanceRequest
             * @instance
             */
            AddTraderInstanceRequest.prototype.traderInstance = null;

            /**
             * Creates a new AddTraderInstanceRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {mts.desk.IAddTraderInstanceRequest=} [properties] Properties to set
             * @returns {mts.desk.AddTraderInstanceRequest} AddTraderInstanceRequest instance
             */
            AddTraderInstanceRequest.create = function create(properties) {
                return new AddTraderInstanceRequest(properties);
            };

            /**
             * Encodes the specified AddTraderInstanceRequest message. Does not implicitly {@link mts.desk.AddTraderInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {mts.desk.IAddTraderInstanceRequest} message AddTraderInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traderInstance != null && Object.hasOwnProperty.call(message, "traderInstance"))
                    $root.mts.desk.TraderInstance.encode(message.traderInstance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddTraderInstanceRequest message, length delimited. Does not implicitly {@link mts.desk.AddTraderInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {mts.desk.IAddTraderInstanceRequest} message AddTraderInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddTraderInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.AddTraderInstanceRequest} AddTraderInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.AddTraderInstanceRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.traderInstance = $root.mts.desk.TraderInstance.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddTraderInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.AddTraderInstanceRequest} AddTraderInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddTraderInstanceRequest message.
             * @function verify
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddTraderInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traderInstance != null && message.hasOwnProperty("traderInstance")) {
                    let error = $root.mts.desk.TraderInstance.verify(message.traderInstance);
                    if (error)
                        return "traderInstance." + error;
                }
                return null;
            };

            /**
             * Creates an AddTraderInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.AddTraderInstanceRequest} AddTraderInstanceRequest
             */
            AddTraderInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.AddTraderInstanceRequest)
                    return object;
                let message = new $root.mts.desk.AddTraderInstanceRequest();
                if (object.traderInstance != null) {
                    if (typeof object.traderInstance !== "object")
                        throw TypeError(".mts.desk.AddTraderInstanceRequest.traderInstance: object expected");
                    message.traderInstance = $root.mts.desk.TraderInstance.fromObject(object.traderInstance);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddTraderInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {mts.desk.AddTraderInstanceRequest} message AddTraderInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddTraderInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.traderInstance = null;
                if (message.traderInstance != null && message.hasOwnProperty("traderInstance"))
                    object.traderInstance = $root.mts.desk.TraderInstance.toObject(message.traderInstance, options);
                return object;
            };

            /**
             * Converts this AddTraderInstanceRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.AddTraderInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddTraderInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddTraderInstanceRequest
             * @function getTypeUrl
             * @memberof mts.desk.AddTraderInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddTraderInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.AddTraderInstanceRequest";
            };

            return AddTraderInstanceRequest;
        })();

        desk.AddTraderInstanceResponse = (function() {

            /**
             * Properties of an AddTraderInstanceResponse.
             * @memberof mts.desk
             * @interface IAddTraderInstanceResponse
             * @property {mts.desk.ITraderInstance|null} [traderInstance] AddTraderInstanceResponse traderInstance
             */

            /**
             * Constructs a new AddTraderInstanceResponse.
             * @memberof mts.desk
             * @classdesc Represents an AddTraderInstanceResponse.
             * @implements IAddTraderInstanceResponse
             * @constructor
             * @param {mts.desk.IAddTraderInstanceResponse=} [properties] Properties to set
             */
            function AddTraderInstanceResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddTraderInstanceResponse traderInstance.
             * @member {mts.desk.ITraderInstance|null|undefined} traderInstance
             * @memberof mts.desk.AddTraderInstanceResponse
             * @instance
             */
            AddTraderInstanceResponse.prototype.traderInstance = null;

            /**
             * Creates a new AddTraderInstanceResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {mts.desk.IAddTraderInstanceResponse=} [properties] Properties to set
             * @returns {mts.desk.AddTraderInstanceResponse} AddTraderInstanceResponse instance
             */
            AddTraderInstanceResponse.create = function create(properties) {
                return new AddTraderInstanceResponse(properties);
            };

            /**
             * Encodes the specified AddTraderInstanceResponse message. Does not implicitly {@link mts.desk.AddTraderInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {mts.desk.IAddTraderInstanceResponse} message AddTraderInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traderInstance != null && Object.hasOwnProperty.call(message, "traderInstance"))
                    $root.mts.desk.TraderInstance.encode(message.traderInstance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddTraderInstanceResponse message, length delimited. Does not implicitly {@link mts.desk.AddTraderInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {mts.desk.IAddTraderInstanceResponse} message AddTraderInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTraderInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddTraderInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.AddTraderInstanceResponse} AddTraderInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.AddTraderInstanceResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.traderInstance = $root.mts.desk.TraderInstance.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddTraderInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.AddTraderInstanceResponse} AddTraderInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTraderInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddTraderInstanceResponse message.
             * @function verify
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddTraderInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traderInstance != null && message.hasOwnProperty("traderInstance")) {
                    let error = $root.mts.desk.TraderInstance.verify(message.traderInstance);
                    if (error)
                        return "traderInstance." + error;
                }
                return null;
            };

            /**
             * Creates an AddTraderInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.AddTraderInstanceResponse} AddTraderInstanceResponse
             */
            AddTraderInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.AddTraderInstanceResponse)
                    return object;
                let message = new $root.mts.desk.AddTraderInstanceResponse();
                if (object.traderInstance != null) {
                    if (typeof object.traderInstance !== "object")
                        throw TypeError(".mts.desk.AddTraderInstanceResponse.traderInstance: object expected");
                    message.traderInstance = $root.mts.desk.TraderInstance.fromObject(object.traderInstance);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddTraderInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {mts.desk.AddTraderInstanceResponse} message AddTraderInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddTraderInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.traderInstance = null;
                if (message.traderInstance != null && message.hasOwnProperty("traderInstance"))
                    object.traderInstance = $root.mts.desk.TraderInstance.toObject(message.traderInstance, options);
                return object;
            };

            /**
             * Converts this AddTraderInstanceResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.AddTraderInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddTraderInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddTraderInstanceResponse
             * @function getTypeUrl
             * @memberof mts.desk.AddTraderInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddTraderInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.AddTraderInstanceResponse";
            };

            return AddTraderInstanceResponse;
        })();

        desk.GetTraderInstanceRequest = (function() {

            /**
             * Properties of a GetTraderInstanceRequest.
             * @memberof mts.desk
             * @interface IGetTraderInstanceRequest
             * @property {string|null} [traderInstanceId] GetTraderInstanceRequest traderInstanceId
             */

            /**
             * Constructs a new GetTraderInstanceRequest.
             * @memberof mts.desk
             * @classdesc Represents a GetTraderInstanceRequest.
             * @implements IGetTraderInstanceRequest
             * @constructor
             * @param {mts.desk.IGetTraderInstanceRequest=} [properties] Properties to set
             */
            function GetTraderInstanceRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTraderInstanceRequest traderInstanceId.
             * @member {string} traderInstanceId
             * @memberof mts.desk.GetTraderInstanceRequest
             * @instance
             */
            GetTraderInstanceRequest.prototype.traderInstanceId = "";

            /**
             * Creates a new GetTraderInstanceRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {mts.desk.IGetTraderInstanceRequest=} [properties] Properties to set
             * @returns {mts.desk.GetTraderInstanceRequest} GetTraderInstanceRequest instance
             */
            GetTraderInstanceRequest.create = function create(properties) {
                return new GetTraderInstanceRequest(properties);
            };

            /**
             * Encodes the specified GetTraderInstanceRequest message. Does not implicitly {@link mts.desk.GetTraderInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {mts.desk.IGetTraderInstanceRequest} message GetTraderInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traderInstanceId != null && Object.hasOwnProperty.call(message, "traderInstanceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderInstanceId);
                return writer;
            };

            /**
             * Encodes the specified GetTraderInstanceRequest message, length delimited. Does not implicitly {@link mts.desk.GetTraderInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {mts.desk.IGetTraderInstanceRequest} message GetTraderInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTraderInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetTraderInstanceRequest} GetTraderInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetTraderInstanceRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.traderInstanceId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTraderInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetTraderInstanceRequest} GetTraderInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTraderInstanceRequest message.
             * @function verify
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTraderInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traderInstanceId != null && message.hasOwnProperty("traderInstanceId"))
                    if (!$util.isString(message.traderInstanceId))
                        return "traderInstanceId: string expected";
                return null;
            };

            /**
             * Creates a GetTraderInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetTraderInstanceRequest} GetTraderInstanceRequest
             */
            GetTraderInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetTraderInstanceRequest)
                    return object;
                let message = new $root.mts.desk.GetTraderInstanceRequest();
                if (object.traderInstanceId != null)
                    message.traderInstanceId = String(object.traderInstanceId);
                return message;
            };

            /**
             * Creates a plain object from a GetTraderInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {mts.desk.GetTraderInstanceRequest} message GetTraderInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTraderInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.traderInstanceId = "";
                if (message.traderInstanceId != null && message.hasOwnProperty("traderInstanceId"))
                    object.traderInstanceId = message.traderInstanceId;
                return object;
            };

            /**
             * Converts this GetTraderInstanceRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.GetTraderInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTraderInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTraderInstanceRequest
             * @function getTypeUrl
             * @memberof mts.desk.GetTraderInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTraderInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetTraderInstanceRequest";
            };

            return GetTraderInstanceRequest;
        })();

        desk.GetTraderInstanceResponse = (function() {

            /**
             * Properties of a GetTraderInstanceResponse.
             * @memberof mts.desk
             * @interface IGetTraderInstanceResponse
             * @property {mts.desk.ITraderInstance|null} [traderInstance] GetTraderInstanceResponse traderInstance
             */

            /**
             * Constructs a new GetTraderInstanceResponse.
             * @memberof mts.desk
             * @classdesc Represents a GetTraderInstanceResponse.
             * @implements IGetTraderInstanceResponse
             * @constructor
             * @param {mts.desk.IGetTraderInstanceResponse=} [properties] Properties to set
             */
            function GetTraderInstanceResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTraderInstanceResponse traderInstance.
             * @member {mts.desk.ITraderInstance|null|undefined} traderInstance
             * @memberof mts.desk.GetTraderInstanceResponse
             * @instance
             */
            GetTraderInstanceResponse.prototype.traderInstance = null;

            /**
             * Creates a new GetTraderInstanceResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {mts.desk.IGetTraderInstanceResponse=} [properties] Properties to set
             * @returns {mts.desk.GetTraderInstanceResponse} GetTraderInstanceResponse instance
             */
            GetTraderInstanceResponse.create = function create(properties) {
                return new GetTraderInstanceResponse(properties);
            };

            /**
             * Encodes the specified GetTraderInstanceResponse message. Does not implicitly {@link mts.desk.GetTraderInstanceResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {mts.desk.IGetTraderInstanceResponse} message GetTraderInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traderInstance != null && Object.hasOwnProperty.call(message, "traderInstance"))
                    $root.mts.desk.TraderInstance.encode(message.traderInstance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetTraderInstanceResponse message, length delimited. Does not implicitly {@link mts.desk.GetTraderInstanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {mts.desk.IGetTraderInstanceResponse} message GetTraderInstanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTraderInstanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetTraderInstanceResponse} GetTraderInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetTraderInstanceResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.traderInstance = $root.mts.desk.TraderInstance.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTraderInstanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetTraderInstanceResponse} GetTraderInstanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTraderInstanceResponse message.
             * @function verify
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTraderInstanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traderInstance != null && message.hasOwnProperty("traderInstance")) {
                    let error = $root.mts.desk.TraderInstance.verify(message.traderInstance);
                    if (error)
                        return "traderInstance." + error;
                }
                return null;
            };

            /**
             * Creates a GetTraderInstanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetTraderInstanceResponse} GetTraderInstanceResponse
             */
            GetTraderInstanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetTraderInstanceResponse)
                    return object;
                let message = new $root.mts.desk.GetTraderInstanceResponse();
                if (object.traderInstance != null) {
                    if (typeof object.traderInstance !== "object")
                        throw TypeError(".mts.desk.GetTraderInstanceResponse.traderInstance: object expected");
                    message.traderInstance = $root.mts.desk.TraderInstance.fromObject(object.traderInstance);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetTraderInstanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {mts.desk.GetTraderInstanceResponse} message GetTraderInstanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTraderInstanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.traderInstance = null;
                if (message.traderInstance != null && message.hasOwnProperty("traderInstance"))
                    object.traderInstance = $root.mts.desk.TraderInstance.toObject(message.traderInstance, options);
                return object;
            };

            /**
             * Converts this GetTraderInstanceResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.GetTraderInstanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTraderInstanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTraderInstanceResponse
             * @function getTypeUrl
             * @memberof mts.desk.GetTraderInstanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTraderInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetTraderInstanceResponse";
            };

            return GetTraderInstanceResponse;
        })();

        desk.UpdateInstanceOptionValuesRequest = (function() {

            /**
             * Properties of an UpdateInstanceOptionValuesRequest.
             * @memberof mts.desk
             * @interface IUpdateInstanceOptionValuesRequest
             * @property {string|null} [instanceId] UpdateInstanceOptionValuesRequest instanceId
             * @property {Object.<string,string>|null} [optionValues] UpdateInstanceOptionValuesRequest optionValues
             */

            /**
             * Constructs a new UpdateInstanceOptionValuesRequest.
             * @memberof mts.desk
             * @classdesc Represents an UpdateInstanceOptionValuesRequest.
             * @implements IUpdateInstanceOptionValuesRequest
             * @constructor
             * @param {mts.desk.IUpdateInstanceOptionValuesRequest=} [properties] Properties to set
             */
            function UpdateInstanceOptionValuesRequest(properties) {
                this.optionValues = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateInstanceOptionValuesRequest instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @instance
             */
            UpdateInstanceOptionValuesRequest.prototype.instanceId = "";

            /**
             * UpdateInstanceOptionValuesRequest optionValues.
             * @member {Object.<string,string>} optionValues
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @instance
             */
            UpdateInstanceOptionValuesRequest.prototype.optionValues = $util.emptyObject;

            /**
             * Creates a new UpdateInstanceOptionValuesRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {mts.desk.IUpdateInstanceOptionValuesRequest=} [properties] Properties to set
             * @returns {mts.desk.UpdateInstanceOptionValuesRequest} UpdateInstanceOptionValuesRequest instance
             */
            UpdateInstanceOptionValuesRequest.create = function create(properties) {
                return new UpdateInstanceOptionValuesRequest(properties);
            };

            /**
             * Encodes the specified UpdateInstanceOptionValuesRequest message. Does not implicitly {@link mts.desk.UpdateInstanceOptionValuesRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {mts.desk.IUpdateInstanceOptionValuesRequest} message UpdateInstanceOptionValuesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceOptionValuesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                if (message.optionValues != null && Object.hasOwnProperty.call(message, "optionValues"))
                    for (let keys = Object.keys(message.optionValues), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.optionValues[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateInstanceOptionValuesRequest message, length delimited. Does not implicitly {@link mts.desk.UpdateInstanceOptionValuesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {mts.desk.IUpdateInstanceOptionValuesRequest} message UpdateInstanceOptionValuesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceOptionValuesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateInstanceOptionValuesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.UpdateInstanceOptionValuesRequest} UpdateInstanceOptionValuesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceOptionValuesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.UpdateInstanceOptionValuesRequest(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.string();
                            break;
                        }
                    case 2: {
                            if (message.optionValues === $util.emptyObject)
                                message.optionValues = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.optionValues[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateInstanceOptionValuesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.UpdateInstanceOptionValuesRequest} UpdateInstanceOptionValuesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceOptionValuesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateInstanceOptionValuesRequest message.
             * @function verify
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateInstanceOptionValuesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                if (message.optionValues != null && message.hasOwnProperty("optionValues")) {
                    if (!$util.isObject(message.optionValues))
                        return "optionValues: object expected";
                    let key = Object.keys(message.optionValues);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.optionValues[key[i]]))
                            return "optionValues: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates an UpdateInstanceOptionValuesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.UpdateInstanceOptionValuesRequest} UpdateInstanceOptionValuesRequest
             */
            UpdateInstanceOptionValuesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.UpdateInstanceOptionValuesRequest)
                    return object;
                let message = new $root.mts.desk.UpdateInstanceOptionValuesRequest();
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                if (object.optionValues) {
                    if (typeof object.optionValues !== "object")
                        throw TypeError(".mts.desk.UpdateInstanceOptionValuesRequest.optionValues: object expected");
                    message.optionValues = {};
                    for (let keys = Object.keys(object.optionValues), i = 0; i < keys.length; ++i)
                        message.optionValues[keys[i]] = String(object.optionValues[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateInstanceOptionValuesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {mts.desk.UpdateInstanceOptionValuesRequest} message UpdateInstanceOptionValuesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateInstanceOptionValuesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.optionValues = {};
                if (options.defaults)
                    object.instanceId = "";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                let keys2;
                if (message.optionValues && (keys2 = Object.keys(message.optionValues)).length) {
                    object.optionValues = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.optionValues[keys2[j]] = message.optionValues[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this UpdateInstanceOptionValuesRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateInstanceOptionValuesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateInstanceOptionValuesRequest
             * @function getTypeUrl
             * @memberof mts.desk.UpdateInstanceOptionValuesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateInstanceOptionValuesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.UpdateInstanceOptionValuesRequest";
            };

            return UpdateInstanceOptionValuesRequest;
        })();

        desk.UpdateInstanceOptionValuesResponse = (function() {

            /**
             * Properties of an UpdateInstanceOptionValuesResponse.
             * @memberof mts.desk
             * @interface IUpdateInstanceOptionValuesResponse
             * @property {string|null} [instanceId] UpdateInstanceOptionValuesResponse instanceId
             * @property {mts.desk.ITraderInstance|null} [instance] UpdateInstanceOptionValuesResponse instance
             */

            /**
             * Constructs a new UpdateInstanceOptionValuesResponse.
             * @memberof mts.desk
             * @classdesc Represents an UpdateInstanceOptionValuesResponse.
             * @implements IUpdateInstanceOptionValuesResponse
             * @constructor
             * @param {mts.desk.IUpdateInstanceOptionValuesResponse=} [properties] Properties to set
             */
            function UpdateInstanceOptionValuesResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateInstanceOptionValuesResponse instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @instance
             */
            UpdateInstanceOptionValuesResponse.prototype.instanceId = "";

            /**
             * UpdateInstanceOptionValuesResponse instance.
             * @member {mts.desk.ITraderInstance|null|undefined} instance
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @instance
             */
            UpdateInstanceOptionValuesResponse.prototype.instance = null;

            /**
             * Creates a new UpdateInstanceOptionValuesResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {mts.desk.IUpdateInstanceOptionValuesResponse=} [properties] Properties to set
             * @returns {mts.desk.UpdateInstanceOptionValuesResponse} UpdateInstanceOptionValuesResponse instance
             */
            UpdateInstanceOptionValuesResponse.create = function create(properties) {
                return new UpdateInstanceOptionValuesResponse(properties);
            };

            /**
             * Encodes the specified UpdateInstanceOptionValuesResponse message. Does not implicitly {@link mts.desk.UpdateInstanceOptionValuesResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {mts.desk.IUpdateInstanceOptionValuesResponse} message UpdateInstanceOptionValuesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceOptionValuesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                if (message.instance != null && Object.hasOwnProperty.call(message, "instance"))
                    $root.mts.desk.TraderInstance.encode(message.instance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateInstanceOptionValuesResponse message, length delimited. Does not implicitly {@link mts.desk.UpdateInstanceOptionValuesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {mts.desk.IUpdateInstanceOptionValuesResponse} message UpdateInstanceOptionValuesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateInstanceOptionValuesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateInstanceOptionValuesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.UpdateInstanceOptionValuesResponse} UpdateInstanceOptionValuesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceOptionValuesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.UpdateInstanceOptionValuesResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.string();
                            break;
                        }
                    case 2: {
                            message.instance = $root.mts.desk.TraderInstance.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateInstanceOptionValuesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.UpdateInstanceOptionValuesResponse} UpdateInstanceOptionValuesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateInstanceOptionValuesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateInstanceOptionValuesResponse message.
             * @function verify
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateInstanceOptionValuesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                if (message.instance != null && message.hasOwnProperty("instance")) {
                    let error = $root.mts.desk.TraderInstance.verify(message.instance);
                    if (error)
                        return "instance." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateInstanceOptionValuesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.UpdateInstanceOptionValuesResponse} UpdateInstanceOptionValuesResponse
             */
            UpdateInstanceOptionValuesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.UpdateInstanceOptionValuesResponse)
                    return object;
                let message = new $root.mts.desk.UpdateInstanceOptionValuesResponse();
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                if (object.instance != null) {
                    if (typeof object.instance !== "object")
                        throw TypeError(".mts.desk.UpdateInstanceOptionValuesResponse.instance: object expected");
                    message.instance = $root.mts.desk.TraderInstance.fromObject(object.instance);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateInstanceOptionValuesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {mts.desk.UpdateInstanceOptionValuesResponse} message UpdateInstanceOptionValuesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateInstanceOptionValuesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.instanceId = "";
                    object.instance = null;
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.instance != null && message.hasOwnProperty("instance"))
                    object.instance = $root.mts.desk.TraderInstance.toObject(message.instance, options);
                return object;
            };

            /**
             * Converts this UpdateInstanceOptionValuesResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateInstanceOptionValuesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateInstanceOptionValuesResponse
             * @function getTypeUrl
             * @memberof mts.desk.UpdateInstanceOptionValuesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateInstanceOptionValuesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.UpdateInstanceOptionValuesResponse";
            };

            return UpdateInstanceOptionValuesResponse;
        })();

        desk.TimeRequest = (function() {

            /**
             * Properties of a TimeRequest.
             * @memberof mts.desk
             * @interface ITimeRequest
             */

            /**
             * Constructs a new TimeRequest.
             * @memberof mts.desk
             * @classdesc Represents a TimeRequest.
             * @implements ITimeRequest
             * @constructor
             * @param {mts.desk.ITimeRequest=} [properties] Properties to set
             */
            function TimeRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new TimeRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {mts.desk.ITimeRequest=} [properties] Properties to set
             * @returns {mts.desk.TimeRequest} TimeRequest instance
             */
            TimeRequest.create = function create(properties) {
                return new TimeRequest(properties);
            };

            /**
             * Encodes the specified TimeRequest message. Does not implicitly {@link mts.desk.TimeRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {mts.desk.ITimeRequest} message TimeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified TimeRequest message, length delimited. Does not implicitly {@link mts.desk.TimeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {mts.desk.ITimeRequest} message TimeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TimeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.TimeRequest} TimeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.TimeRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TimeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.TimeRequest} TimeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TimeRequest message.
             * @function verify
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TimeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a TimeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.TimeRequest} TimeRequest
             */
            TimeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.TimeRequest)
                    return object;
                return new $root.mts.desk.TimeRequest();
            };

            /**
             * Creates a plain object from a TimeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {mts.desk.TimeRequest} message TimeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimeRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TimeRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.TimeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TimeRequest
             * @function getTypeUrl
             * @memberof mts.desk.TimeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TimeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.TimeRequest";
            };

            return TimeRequest;
        })();

        desk.Time = (function() {

            /**
             * Properties of a Time.
             * @memberof mts.desk
             * @interface ITime
             * @property {string|null} [dateTime] Time dateTime
             */

            /**
             * Constructs a new Time.
             * @memberof mts.desk
             * @classdesc Represents a Time.
             * @implements ITime
             * @constructor
             * @param {mts.desk.ITime=} [properties] Properties to set
             */
            function Time(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Time dateTime.
             * @member {string} dateTime
             * @memberof mts.desk.Time
             * @instance
             */
            Time.prototype.dateTime = "";

            /**
             * Creates a new Time instance using the specified properties.
             * @function create
             * @memberof mts.desk.Time
             * @static
             * @param {mts.desk.ITime=} [properties] Properties to set
             * @returns {mts.desk.Time} Time instance
             */
            Time.create = function create(properties) {
                return new Time(properties);
            };

            /**
             * Encodes the specified Time message. Does not implicitly {@link mts.desk.Time.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.Time
             * @static
             * @param {mts.desk.ITime} message Time message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Time.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateTime);
                return writer;
            };

            /**
             * Encodes the specified Time message, length delimited. Does not implicitly {@link mts.desk.Time.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.Time
             * @static
             * @param {mts.desk.ITime} message Time message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Time.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Time message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.Time
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.Time} Time
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Time.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.Time();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dateTime = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Time message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.Time
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.Time} Time
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Time.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Time message.
             * @function verify
             * @memberof mts.desk.Time
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Time.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                    if (!$util.isString(message.dateTime))
                        return "dateTime: string expected";
                return null;
            };

            /**
             * Creates a Time message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.Time
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.Time} Time
             */
            Time.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.Time)
                    return object;
                let message = new $root.mts.desk.Time();
                if (object.dateTime != null)
                    message.dateTime = String(object.dateTime);
                return message;
            };

            /**
             * Creates a plain object from a Time message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.Time
             * @static
             * @param {mts.desk.Time} message Time
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Time.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dateTime = "";
                if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                    object.dateTime = message.dateTime;
                return object;
            };

            /**
             * Converts this Time to JSON.
             * @function toJSON
             * @memberof mts.desk.Time
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Time.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Time
             * @function getTypeUrl
             * @memberof mts.desk.Time
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Time.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.Time";
            };

            return Time;
        })();

        desk.Account = (function() {

            /**
             * Properties of an Account.
             * @memberof mts.desk
             * @interface IAccount
             * @property {string|null} [id] Account id
             */

            /**
             * Constructs a new Account.
             * @memberof mts.desk
             * @classdesc Represents an Account.
             * @implements IAccount
             * @constructor
             * @param {mts.desk.IAccount=} [properties] Properties to set
             */
            function Account(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Account id.
             * @member {string} id
             * @memberof mts.desk.Account
             * @instance
             */
            Account.prototype.id = "";

            /**
             * Creates a new Account instance using the specified properties.
             * @function create
             * @memberof mts.desk.Account
             * @static
             * @param {mts.desk.IAccount=} [properties] Properties to set
             * @returns {mts.desk.Account} Account instance
             */
            Account.create = function create(properties) {
                return new Account(properties);
            };

            /**
             * Encodes the specified Account message. Does not implicitly {@link mts.desk.Account.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.Account
             * @static
             * @param {mts.desk.IAccount} message Account message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Account.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                return writer;
            };

            /**
             * Encodes the specified Account message, length delimited. Does not implicitly {@link mts.desk.Account.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.Account
             * @static
             * @param {mts.desk.IAccount} message Account message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Account.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Account message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.Account
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.Account} Account
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Account.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.Account();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Account message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.Account
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.Account} Account
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Account.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Account message.
             * @function verify
             * @memberof mts.desk.Account
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Account.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                return null;
            };

            /**
             * Creates an Account message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.Account
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.Account} Account
             */
            Account.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.Account)
                    return object;
                let message = new $root.mts.desk.Account();
                if (object.id != null)
                    message.id = String(object.id);
                return message;
            };

            /**
             * Creates a plain object from an Account message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.Account
             * @static
             * @param {mts.desk.Account} message Account
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Account.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = "";
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this Account to JSON.
             * @function toJSON
             * @memberof mts.desk.Account
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Account.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Account
             * @function getTypeUrl
             * @memberof mts.desk.Account
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Account.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.Account";
            };

            return Account;
        })();

        desk.User = (function() {

            /**
             * Properties of a User.
             * @memberof mts.desk
             * @interface IUser
             * @property {string|null} [id] User id
             * @property {string|null} [username] User username
             * @property {string|null} [email] User email
             * @property {string|null} [accountId] User accountId
             * @property {boolean|null} [anonymous] User anonymous
             * @property {number|null} [getCount] User getCount
             * @property {string|null} [created] User created
             * @property {boolean|null} [admin] User admin
             */

            /**
             * Constructs a new User.
             * @memberof mts.desk
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {mts.desk.IUser=} [properties] Properties to set
             */
            function User(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * User id.
             * @member {string} id
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.id = "";

            /**
             * User username.
             * @member {string} username
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.username = "";

            /**
             * User email.
             * @member {string} email
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.email = "";

            /**
             * User accountId.
             * @member {string} accountId
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.accountId = "";

            /**
             * User anonymous.
             * @member {boolean} anonymous
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.anonymous = false;

            /**
             * User getCount.
             * @member {number} getCount
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.getCount = 0;

            /**
             * User created.
             * @member {string} created
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.created = "";

            /**
             * User admin.
             * @member {boolean} admin
             * @memberof mts.desk.User
             * @instance
             */
            User.prototype.admin = false;

            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof mts.desk.User
             * @static
             * @param {mts.desk.IUser=} [properties] Properties to set
             * @returns {mts.desk.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties);
            };

            /**
             * Encodes the specified User message. Does not implicitly {@link mts.desk.User.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.User
             * @static
             * @param {mts.desk.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.accountId);
                if (message.anonymous != null && Object.hasOwnProperty.call(message, "anonymous"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.anonymous);
                if (message.getCount != null && Object.hasOwnProperty.call(message, "getCount"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.getCount);
                if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.created);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.email);
                if (message.admin != null && Object.hasOwnProperty.call(message, "admin"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.admin);
                return writer;
            };

            /**
             * Encodes the specified User message, length delimited. Does not implicitly {@link mts.desk.User.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.User
             * @static
             * @param {mts.desk.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.User();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.username = reader.string();
                            break;
                        }
                    case 7: {
                            message.email = reader.string();
                            break;
                        }
                    case 3: {
                            message.accountId = reader.string();
                            break;
                        }
                    case 4: {
                            message.anonymous = reader.bool();
                            break;
                        }
                    case 5: {
                            message.getCount = reader.int32();
                            break;
                        }
                    case 6: {
                            message.created = reader.string();
                            break;
                        }
                    case 8: {
                            message.admin = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a User message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a User message.
             * @function verify
             * @memberof mts.desk.User
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            User.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                if (message.anonymous != null && message.hasOwnProperty("anonymous"))
                    if (typeof message.anonymous !== "boolean")
                        return "anonymous: boolean expected";
                if (message.getCount != null && message.hasOwnProperty("getCount"))
                    if (!$util.isInteger(message.getCount))
                        return "getCount: integer expected";
                if (message.created != null && message.hasOwnProperty("created"))
                    if (!$util.isString(message.created))
                        return "created: string expected";
                if (message.admin != null && message.hasOwnProperty("admin"))
                    if (typeof message.admin !== "boolean")
                        return "admin: boolean expected";
                return null;
            };

            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.User
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.User} User
             */
            User.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.User)
                    return object;
                let message = new $root.mts.desk.User();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.username != null)
                    message.username = String(object.username);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                if (object.anonymous != null)
                    message.anonymous = Boolean(object.anonymous);
                if (object.getCount != null)
                    message.getCount = object.getCount | 0;
                if (object.created != null)
                    message.created = String(object.created);
                if (object.admin != null)
                    message.admin = Boolean(object.admin);
                return message;
            };

            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.User
             * @static
             * @param {mts.desk.User} message User
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = "";
                    object.username = "";
                    object.accountId = "";
                    object.anonymous = false;
                    object.getCount = 0;
                    object.created = "";
                    object.email = "";
                    object.admin = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.anonymous != null && message.hasOwnProperty("anonymous"))
                    object.anonymous = message.anonymous;
                if (message.getCount != null && message.hasOwnProperty("getCount"))
                    object.getCount = message.getCount;
                if (message.created != null && message.hasOwnProperty("created"))
                    object.created = message.created;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.admin != null && message.hasOwnProperty("admin"))
                    object.admin = message.admin;
                return object;
            };

            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof mts.desk.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for User
             * @function getTypeUrl
             * @memberof mts.desk.User
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.User";
            };

            return User;
        })();

        desk.UserPortfolioFollow = (function() {

            /**
             * Properties of a UserPortfolioFollow.
             * @memberof mts.desk
             * @interface IUserPortfolioFollow
             * @property {string|null} [userId] UserPortfolioFollow userId
             * @property {number|null} [portfolioId] UserPortfolioFollow portfolioId
             * @property {boolean|null} [notificationsEnabled] UserPortfolioFollow notificationsEnabled
             * @property {boolean|null} [notifyTrades] UserPortfolioFollow notifyTrades
             */

            /**
             * Constructs a new UserPortfolioFollow.
             * @memberof mts.desk
             * @classdesc Represents a UserPortfolioFollow.
             * @implements IUserPortfolioFollow
             * @constructor
             * @param {mts.desk.IUserPortfolioFollow=} [properties] Properties to set
             */
            function UserPortfolioFollow(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserPortfolioFollow userId.
             * @member {string} userId
             * @memberof mts.desk.UserPortfolioFollow
             * @instance
             */
            UserPortfolioFollow.prototype.userId = "";

            /**
             * UserPortfolioFollow portfolioId.
             * @member {number} portfolioId
             * @memberof mts.desk.UserPortfolioFollow
             * @instance
             */
            UserPortfolioFollow.prototype.portfolioId = 0;

            /**
             * UserPortfolioFollow notificationsEnabled.
             * @member {boolean} notificationsEnabled
             * @memberof mts.desk.UserPortfolioFollow
             * @instance
             */
            UserPortfolioFollow.prototype.notificationsEnabled = false;

            /**
             * UserPortfolioFollow notifyTrades.
             * @member {boolean} notifyTrades
             * @memberof mts.desk.UserPortfolioFollow
             * @instance
             */
            UserPortfolioFollow.prototype.notifyTrades = false;

            /**
             * Creates a new UserPortfolioFollow instance using the specified properties.
             * @function create
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {mts.desk.IUserPortfolioFollow=} [properties] Properties to set
             * @returns {mts.desk.UserPortfolioFollow} UserPortfolioFollow instance
             */
            UserPortfolioFollow.create = function create(properties) {
                return new UserPortfolioFollow(properties);
            };

            /**
             * Encodes the specified UserPortfolioFollow message. Does not implicitly {@link mts.desk.UserPortfolioFollow.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {mts.desk.IUserPortfolioFollow} message UserPortfolioFollow message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserPortfolioFollow.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.portfolioId);
                if (message.notificationsEnabled != null && Object.hasOwnProperty.call(message, "notificationsEnabled"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.notificationsEnabled);
                if (message.notifyTrades != null && Object.hasOwnProperty.call(message, "notifyTrades"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.notifyTrades);
                return writer;
            };

            /**
             * Encodes the specified UserPortfolioFollow message, length delimited. Does not implicitly {@link mts.desk.UserPortfolioFollow.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {mts.desk.IUserPortfolioFollow} message UserPortfolioFollow message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserPortfolioFollow.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserPortfolioFollow message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.UserPortfolioFollow} UserPortfolioFollow
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserPortfolioFollow.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.UserPortfolioFollow();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            message.portfolioId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.notificationsEnabled = reader.bool();
                            break;
                        }
                    case 4: {
                            message.notifyTrades = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserPortfolioFollow message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.UserPortfolioFollow} UserPortfolioFollow
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserPortfolioFollow.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserPortfolioFollow message.
             * @function verify
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserPortfolioFollow.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isInteger(message.portfolioId))
                        return "portfolioId: integer expected";
                if (message.notificationsEnabled != null && message.hasOwnProperty("notificationsEnabled"))
                    if (typeof message.notificationsEnabled !== "boolean")
                        return "notificationsEnabled: boolean expected";
                if (message.notifyTrades != null && message.hasOwnProperty("notifyTrades"))
                    if (typeof message.notifyTrades !== "boolean")
                        return "notifyTrades: boolean expected";
                return null;
            };

            /**
             * Creates a UserPortfolioFollow message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.UserPortfolioFollow} UserPortfolioFollow
             */
            UserPortfolioFollow.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.UserPortfolioFollow)
                    return object;
                let message = new $root.mts.desk.UserPortfolioFollow();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.portfolioId != null)
                    message.portfolioId = object.portfolioId | 0;
                if (object.notificationsEnabled != null)
                    message.notificationsEnabled = Boolean(object.notificationsEnabled);
                if (object.notifyTrades != null)
                    message.notifyTrades = Boolean(object.notifyTrades);
                return message;
            };

            /**
             * Creates a plain object from a UserPortfolioFollow message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {mts.desk.UserPortfolioFollow} message UserPortfolioFollow
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserPortfolioFollow.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.userId = "";
                    object.portfolioId = 0;
                    object.notificationsEnabled = false;
                    object.notifyTrades = false;
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.notificationsEnabled != null && message.hasOwnProperty("notificationsEnabled"))
                    object.notificationsEnabled = message.notificationsEnabled;
                if (message.notifyTrades != null && message.hasOwnProperty("notifyTrades"))
                    object.notifyTrades = message.notifyTrades;
                return object;
            };

            /**
             * Converts this UserPortfolioFollow to JSON.
             * @function toJSON
             * @memberof mts.desk.UserPortfolioFollow
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserPortfolioFollow.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserPortfolioFollow
             * @function getTypeUrl
             * @memberof mts.desk.UserPortfolioFollow
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserPortfolioFollow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.UserPortfolioFollow";
            };

            return UserPortfolioFollow;
        })();

        desk.AddAccountRequest = (function() {

            /**
             * Properties of an AddAccountRequest.
             * @memberof mts.desk
             * @interface IAddAccountRequest
             * @property {mts.desk.IAccount|null} [account] AddAccountRequest account
             */

            /**
             * Constructs a new AddAccountRequest.
             * @memberof mts.desk
             * @classdesc Represents an AddAccountRequest.
             * @implements IAddAccountRequest
             * @constructor
             * @param {mts.desk.IAddAccountRequest=} [properties] Properties to set
             */
            function AddAccountRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddAccountRequest account.
             * @member {mts.desk.IAccount|null|undefined} account
             * @memberof mts.desk.AddAccountRequest
             * @instance
             */
            AddAccountRequest.prototype.account = null;

            /**
             * Creates a new AddAccountRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {mts.desk.IAddAccountRequest=} [properties] Properties to set
             * @returns {mts.desk.AddAccountRequest} AddAccountRequest instance
             */
            AddAccountRequest.create = function create(properties) {
                return new AddAccountRequest(properties);
            };

            /**
             * Encodes the specified AddAccountRequest message. Does not implicitly {@link mts.desk.AddAccountRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {mts.desk.IAddAccountRequest} message AddAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAccountRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    $root.mts.desk.Account.encode(message.account, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddAccountRequest message, length delimited. Does not implicitly {@link mts.desk.AddAccountRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {mts.desk.IAddAccountRequest} message AddAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddAccountRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.AddAccountRequest} AddAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAccountRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.AddAccountRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.account = $root.mts.desk.Account.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddAccountRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.AddAccountRequest} AddAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddAccountRequest message.
             * @function verify
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddAccountRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.account != null && message.hasOwnProperty("account")) {
                    let error = $root.mts.desk.Account.verify(message.account);
                    if (error)
                        return "account." + error;
                }
                return null;
            };

            /**
             * Creates an AddAccountRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.AddAccountRequest} AddAccountRequest
             */
            AddAccountRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.AddAccountRequest)
                    return object;
                let message = new $root.mts.desk.AddAccountRequest();
                if (object.account != null) {
                    if (typeof object.account !== "object")
                        throw TypeError(".mts.desk.AddAccountRequest.account: object expected");
                    message.account = $root.mts.desk.Account.fromObject(object.account);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddAccountRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {mts.desk.AddAccountRequest} message AddAccountRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddAccountRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.account = null;
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = $root.mts.desk.Account.toObject(message.account, options);
                return object;
            };

            /**
             * Converts this AddAccountRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.AddAccountRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddAccountRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddAccountRequest
             * @function getTypeUrl
             * @memberof mts.desk.AddAccountRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.AddAccountRequest";
            };

            return AddAccountRequest;
        })();

        desk.AddAccountResponse = (function() {

            /**
             * Properties of an AddAccountResponse.
             * @memberof mts.desk
             * @interface IAddAccountResponse
             * @property {mts.desk.IAccount|null} [account] AddAccountResponse account
             */

            /**
             * Constructs a new AddAccountResponse.
             * @memberof mts.desk
             * @classdesc Represents an AddAccountResponse.
             * @implements IAddAccountResponse
             * @constructor
             * @param {mts.desk.IAddAccountResponse=} [properties] Properties to set
             */
            function AddAccountResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddAccountResponse account.
             * @member {mts.desk.IAccount|null|undefined} account
             * @memberof mts.desk.AddAccountResponse
             * @instance
             */
            AddAccountResponse.prototype.account = null;

            /**
             * Creates a new AddAccountResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {mts.desk.IAddAccountResponse=} [properties] Properties to set
             * @returns {mts.desk.AddAccountResponse} AddAccountResponse instance
             */
            AddAccountResponse.create = function create(properties) {
                return new AddAccountResponse(properties);
            };

            /**
             * Encodes the specified AddAccountResponse message. Does not implicitly {@link mts.desk.AddAccountResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {mts.desk.IAddAccountResponse} message AddAccountResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAccountResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    $root.mts.desk.Account.encode(message.account, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddAccountResponse message, length delimited. Does not implicitly {@link mts.desk.AddAccountResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {mts.desk.IAddAccountResponse} message AddAccountResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddAccountResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.AddAccountResponse} AddAccountResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAccountResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.AddAccountResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.account = $root.mts.desk.Account.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddAccountResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.AddAccountResponse} AddAccountResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAccountResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddAccountResponse message.
             * @function verify
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddAccountResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.account != null && message.hasOwnProperty("account")) {
                    let error = $root.mts.desk.Account.verify(message.account);
                    if (error)
                        return "account." + error;
                }
                return null;
            };

            /**
             * Creates an AddAccountResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.AddAccountResponse} AddAccountResponse
             */
            AddAccountResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.AddAccountResponse)
                    return object;
                let message = new $root.mts.desk.AddAccountResponse();
                if (object.account != null) {
                    if (typeof object.account !== "object")
                        throw TypeError(".mts.desk.AddAccountResponse.account: object expected");
                    message.account = $root.mts.desk.Account.fromObject(object.account);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddAccountResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {mts.desk.AddAccountResponse} message AddAccountResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddAccountResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.account = null;
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = $root.mts.desk.Account.toObject(message.account, options);
                return object;
            };

            /**
             * Converts this AddAccountResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.AddAccountResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddAccountResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddAccountResponse
             * @function getTypeUrl
             * @memberof mts.desk.AddAccountResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddAccountResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.AddAccountResponse";
            };

            return AddAccountResponse;
        })();

        desk.GetAccountRequest = (function() {

            /**
             * Properties of a GetAccountRequest.
             * @memberof mts.desk
             * @interface IGetAccountRequest
             * @property {string|null} [id] GetAccountRequest id
             */

            /**
             * Constructs a new GetAccountRequest.
             * @memberof mts.desk
             * @classdesc Represents a GetAccountRequest.
             * @implements IGetAccountRequest
             * @constructor
             * @param {mts.desk.IGetAccountRequest=} [properties] Properties to set
             */
            function GetAccountRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAccountRequest id.
             * @member {string} id
             * @memberof mts.desk.GetAccountRequest
             * @instance
             */
            GetAccountRequest.prototype.id = "";

            /**
             * Creates a new GetAccountRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {mts.desk.IGetAccountRequest=} [properties] Properties to set
             * @returns {mts.desk.GetAccountRequest} GetAccountRequest instance
             */
            GetAccountRequest.create = function create(properties) {
                return new GetAccountRequest(properties);
            };

            /**
             * Encodes the specified GetAccountRequest message. Does not implicitly {@link mts.desk.GetAccountRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {mts.desk.IGetAccountRequest} message GetAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAccountRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                return writer;
            };

            /**
             * Encodes the specified GetAccountRequest message, length delimited. Does not implicitly {@link mts.desk.GetAccountRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {mts.desk.IGetAccountRequest} message GetAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetAccountRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetAccountRequest} GetAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAccountRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetAccountRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetAccountRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetAccountRequest} GetAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetAccountRequest message.
             * @function verify
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAccountRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                return null;
            };

            /**
             * Creates a GetAccountRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetAccountRequest} GetAccountRequest
             */
            GetAccountRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetAccountRequest)
                    return object;
                let message = new $root.mts.desk.GetAccountRequest();
                if (object.id != null)
                    message.id = String(object.id);
                return message;
            };

            /**
             * Creates a plain object from a GetAccountRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {mts.desk.GetAccountRequest} message GetAccountRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAccountRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = "";
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this GetAccountRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.GetAccountRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAccountRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAccountRequest
             * @function getTypeUrl
             * @memberof mts.desk.GetAccountRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetAccountRequest";
            };

            return GetAccountRequest;
        })();

        desk.GetAccountResponse = (function() {

            /**
             * Properties of a GetAccountResponse.
             * @memberof mts.desk
             * @interface IGetAccountResponse
             * @property {mts.desk.IAccount|null} [account] GetAccountResponse account
             */

            /**
             * Constructs a new GetAccountResponse.
             * @memberof mts.desk
             * @classdesc Represents a GetAccountResponse.
             * @implements IGetAccountResponse
             * @constructor
             * @param {mts.desk.IGetAccountResponse=} [properties] Properties to set
             */
            function GetAccountResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAccountResponse account.
             * @member {mts.desk.IAccount|null|undefined} account
             * @memberof mts.desk.GetAccountResponse
             * @instance
             */
            GetAccountResponse.prototype.account = null;

            /**
             * Creates a new GetAccountResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {mts.desk.IGetAccountResponse=} [properties] Properties to set
             * @returns {mts.desk.GetAccountResponse} GetAccountResponse instance
             */
            GetAccountResponse.create = function create(properties) {
                return new GetAccountResponse(properties);
            };

            /**
             * Encodes the specified GetAccountResponse message. Does not implicitly {@link mts.desk.GetAccountResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {mts.desk.IGetAccountResponse} message GetAccountResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAccountResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    $root.mts.desk.Account.encode(message.account, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetAccountResponse message, length delimited. Does not implicitly {@link mts.desk.GetAccountResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {mts.desk.IGetAccountResponse} message GetAccountResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetAccountResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetAccountResponse} GetAccountResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAccountResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetAccountResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.account = $root.mts.desk.Account.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetAccountResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetAccountResponse} GetAccountResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAccountResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetAccountResponse message.
             * @function verify
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAccountResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.account != null && message.hasOwnProperty("account")) {
                    let error = $root.mts.desk.Account.verify(message.account);
                    if (error)
                        return "account." + error;
                }
                return null;
            };

            /**
             * Creates a GetAccountResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetAccountResponse} GetAccountResponse
             */
            GetAccountResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetAccountResponse)
                    return object;
                let message = new $root.mts.desk.GetAccountResponse();
                if (object.account != null) {
                    if (typeof object.account !== "object")
                        throw TypeError(".mts.desk.GetAccountResponse.account: object expected");
                    message.account = $root.mts.desk.Account.fromObject(object.account);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetAccountResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {mts.desk.GetAccountResponse} message GetAccountResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAccountResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.account = null;
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = $root.mts.desk.Account.toObject(message.account, options);
                return object;
            };

            /**
             * Converts this GetAccountResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.GetAccountResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAccountResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAccountResponse
             * @function getTypeUrl
             * @memberof mts.desk.GetAccountResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAccountResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetAccountResponse";
            };

            return GetAccountResponse;
        })();

        desk.AddUserRequest = (function() {

            /**
             * Properties of an AddUserRequest.
             * @memberof mts.desk
             * @interface IAddUserRequest
             * @property {mts.desk.IUser|null} [user] AddUserRequest user
             */

            /**
             * Constructs a new AddUserRequest.
             * @memberof mts.desk
             * @classdesc Represents an AddUserRequest.
             * @implements IAddUserRequest
             * @constructor
             * @param {mts.desk.IAddUserRequest=} [properties] Properties to set
             */
            function AddUserRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddUserRequest user.
             * @member {mts.desk.IUser|null|undefined} user
             * @memberof mts.desk.AddUserRequest
             * @instance
             */
            AddUserRequest.prototype.user = null;

            /**
             * Creates a new AddUserRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {mts.desk.IAddUserRequest=} [properties] Properties to set
             * @returns {mts.desk.AddUserRequest} AddUserRequest instance
             */
            AddUserRequest.create = function create(properties) {
                return new AddUserRequest(properties);
            };

            /**
             * Encodes the specified AddUserRequest message. Does not implicitly {@link mts.desk.AddUserRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {mts.desk.IAddUserRequest} message AddUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddUserRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.mts.desk.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddUserRequest message, length delimited. Does not implicitly {@link mts.desk.AddUserRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {mts.desk.IAddUserRequest} message AddUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.AddUserRequest} AddUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddUserRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.AddUserRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.user = $root.mts.desk.User.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddUserRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.AddUserRequest} AddUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddUserRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddUserRequest message.
             * @function verify
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddUserRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    let error = $root.mts.desk.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };

            /**
             * Creates an AddUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.AddUserRequest} AddUserRequest
             */
            AddUserRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.AddUserRequest)
                    return object;
                let message = new $root.mts.desk.AddUserRequest();
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".mts.desk.AddUserRequest.user: object expected");
                    message.user = $root.mts.desk.User.fromObject(object.user);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {mts.desk.AddUserRequest} message AddUserRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddUserRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.user = null;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.mts.desk.User.toObject(message.user, options);
                return object;
            };

            /**
             * Converts this AddUserRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.AddUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddUserRequest
             * @function getTypeUrl
             * @memberof mts.desk.AddUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.AddUserRequest";
            };

            return AddUserRequest;
        })();

        desk.GetUserRequest = (function() {

            /**
             * Properties of a GetUserRequest.
             * @memberof mts.desk
             * @interface IGetUserRequest
             * @property {string|null} [userId] GetUserRequest userId
             */

            /**
             * Constructs a new GetUserRequest.
             * @memberof mts.desk
             * @classdesc Represents a GetUserRequest.
             * @implements IGetUserRequest
             * @constructor
             * @param {mts.desk.IGetUserRequest=} [properties] Properties to set
             */
            function GetUserRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetUserRequest userId.
             * @member {string} userId
             * @memberof mts.desk.GetUserRequest
             * @instance
             */
            GetUserRequest.prototype.userId = "";

            /**
             * Creates a new GetUserRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {mts.desk.IGetUserRequest=} [properties] Properties to set
             * @returns {mts.desk.GetUserRequest} GetUserRequest instance
             */
            GetUserRequest.create = function create(properties) {
                return new GetUserRequest(properties);
            };

            /**
             * Encodes the specified GetUserRequest message. Does not implicitly {@link mts.desk.GetUserRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {mts.desk.IGetUserRequest} message GetUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                return writer;
            };

            /**
             * Encodes the specified GetUserRequest message, length delimited. Does not implicitly {@link mts.desk.GetUserRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {mts.desk.IGetUserRequest} message GetUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetUserRequest} GetUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetUserRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetUserRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetUserRequest} GetUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetUserRequest message.
             * @function verify
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetUserRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                return null;
            };

            /**
             * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetUserRequest} GetUserRequest
             */
            GetUserRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetUserRequest)
                    return object;
                let message = new $root.mts.desk.GetUserRequest();
                if (object.userId != null)
                    message.userId = String(object.userId);
                return message;
            };

            /**
             * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {mts.desk.GetUserRequest} message GetUserRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.userId = "";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                return object;
            };

            /**
             * Converts this GetUserRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.GetUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserRequest
             * @function getTypeUrl
             * @memberof mts.desk.GetUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetUserRequest";
            };

            return GetUserRequest;
        })();

        desk.LoginRequest = (function() {

            /**
             * Properties of a LoginRequest.
             * @memberof mts.desk
             * @interface ILoginRequest
             * @property {string|null} [userId] LoginRequest userId
             * @property {number|null} [viewportWidth] LoginRequest viewportWidth
             * @property {number|null} [viewportHeight] LoginRequest viewportHeight
             */

            /**
             * Constructs a new LoginRequest.
             * @memberof mts.desk
             * @classdesc Represents a LoginRequest.
             * @implements ILoginRequest
             * @constructor
             * @param {mts.desk.ILoginRequest=} [properties] Properties to set
             */
            function LoginRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginRequest userId.
             * @member {string} userId
             * @memberof mts.desk.LoginRequest
             * @instance
             */
            LoginRequest.prototype.userId = "";

            /**
             * LoginRequest viewportWidth.
             * @member {number} viewportWidth
             * @memberof mts.desk.LoginRequest
             * @instance
             */
            LoginRequest.prototype.viewportWidth = 0;

            /**
             * LoginRequest viewportHeight.
             * @member {number} viewportHeight
             * @memberof mts.desk.LoginRequest
             * @instance
             */
            LoginRequest.prototype.viewportHeight = 0;

            /**
             * Creates a new LoginRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {mts.desk.ILoginRequest=} [properties] Properties to set
             * @returns {mts.desk.LoginRequest} LoginRequest instance
             */
            LoginRequest.create = function create(properties) {
                return new LoginRequest(properties);
            };

            /**
             * Encodes the specified LoginRequest message. Does not implicitly {@link mts.desk.LoginRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {mts.desk.ILoginRequest} message LoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.viewportWidth != null && Object.hasOwnProperty.call(message, "viewportWidth"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.viewportWidth);
                if (message.viewportHeight != null && Object.hasOwnProperty.call(message, "viewportHeight"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.viewportHeight);
                return writer;
            };

            /**
             * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link mts.desk.LoginRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {mts.desk.ILoginRequest} message LoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LoginRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.LoginRequest} LoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.LoginRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 10: {
                            message.viewportWidth = reader.int32();
                            break;
                        }
                    case 11: {
                            message.viewportHeight = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.LoginRequest} LoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LoginRequest message.
             * @function verify
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.viewportWidth != null && message.hasOwnProperty("viewportWidth"))
                    if (!$util.isInteger(message.viewportWidth))
                        return "viewportWidth: integer expected";
                if (message.viewportHeight != null && message.hasOwnProperty("viewportHeight"))
                    if (!$util.isInteger(message.viewportHeight))
                        return "viewportHeight: integer expected";
                return null;
            };

            /**
             * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.LoginRequest} LoginRequest
             */
            LoginRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.LoginRequest)
                    return object;
                let message = new $root.mts.desk.LoginRequest();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.viewportWidth != null)
                    message.viewportWidth = object.viewportWidth | 0;
                if (object.viewportHeight != null)
                    message.viewportHeight = object.viewportHeight | 0;
                return message;
            };

            /**
             * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {mts.desk.LoginRequest} message LoginRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.userId = "";
                    object.viewportWidth = 0;
                    object.viewportHeight = 0;
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.viewportWidth != null && message.hasOwnProperty("viewportWidth"))
                    object.viewportWidth = message.viewportWidth;
                if (message.viewportHeight != null && message.hasOwnProperty("viewportHeight"))
                    object.viewportHeight = message.viewportHeight;
                return object;
            };

            /**
             * Converts this LoginRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.LoginRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LoginRequest
             * @function getTypeUrl
             * @memberof mts.desk.LoginRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.LoginRequest";
            };

            return LoginRequest;
        })();

        desk.LoginResponse = (function() {

            /**
             * Properties of a LoginResponse.
             * @memberof mts.desk
             * @interface ILoginResponse
             * @property {mts.desk.ISession|null} [session] LoginResponse session
             * @property {mts.desk.IUser|null} [user] LoginResponse user
             */

            /**
             * Constructs a new LoginResponse.
             * @memberof mts.desk
             * @classdesc Represents a LoginResponse.
             * @implements ILoginResponse
             * @constructor
             * @param {mts.desk.ILoginResponse=} [properties] Properties to set
             */
            function LoginResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginResponse session.
             * @member {mts.desk.ISession|null|undefined} session
             * @memberof mts.desk.LoginResponse
             * @instance
             */
            LoginResponse.prototype.session = null;

            /**
             * LoginResponse user.
             * @member {mts.desk.IUser|null|undefined} user
             * @memberof mts.desk.LoginResponse
             * @instance
             */
            LoginResponse.prototype.user = null;

            /**
             * Creates a new LoginResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {mts.desk.ILoginResponse=} [properties] Properties to set
             * @returns {mts.desk.LoginResponse} LoginResponse instance
             */
            LoginResponse.create = function create(properties) {
                return new LoginResponse(properties);
            };

            /**
             * Encodes the specified LoginResponse message. Does not implicitly {@link mts.desk.LoginResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {mts.desk.ILoginResponse} message LoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.session != null && Object.hasOwnProperty.call(message, "session"))
                    $root.mts.desk.Session.encode(message.session, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.mts.desk.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link mts.desk.LoginResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {mts.desk.ILoginResponse} message LoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LoginResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.LoginResponse} LoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.LoginResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.session = $root.mts.desk.Session.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.user = $root.mts.desk.User.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.LoginResponse} LoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LoginResponse message.
             * @function verify
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.session != null && message.hasOwnProperty("session")) {
                    let error = $root.mts.desk.Session.verify(message.session);
                    if (error)
                        return "session." + error;
                }
                if (message.user != null && message.hasOwnProperty("user")) {
                    let error = $root.mts.desk.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };

            /**
             * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.LoginResponse} LoginResponse
             */
            LoginResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.LoginResponse)
                    return object;
                let message = new $root.mts.desk.LoginResponse();
                if (object.session != null) {
                    if (typeof object.session !== "object")
                        throw TypeError(".mts.desk.LoginResponse.session: object expected");
                    message.session = $root.mts.desk.Session.fromObject(object.session);
                }
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".mts.desk.LoginResponse.user: object expected");
                    message.user = $root.mts.desk.User.fromObject(object.user);
                }
                return message;
            };

            /**
             * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {mts.desk.LoginResponse} message LoginResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.session = null;
                    object.user = null;
                }
                if (message.session != null && message.hasOwnProperty("session"))
                    object.session = $root.mts.desk.Session.toObject(message.session, options);
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.mts.desk.User.toObject(message.user, options);
                return object;
            };

            /**
             * Converts this LoginResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.LoginResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LoginResponse
             * @function getTypeUrl
             * @memberof mts.desk.LoginResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.LoginResponse";
            };

            return LoginResponse;
        })();

        desk.LogoutRequest = (function() {

            /**
             * Properties of a LogoutRequest.
             * @memberof mts.desk
             * @interface ILogoutRequest
             * @property {string|null} [sessionId] LogoutRequest sessionId
             */

            /**
             * Constructs a new LogoutRequest.
             * @memberof mts.desk
             * @classdesc Represents a LogoutRequest.
             * @implements ILogoutRequest
             * @constructor
             * @param {mts.desk.ILogoutRequest=} [properties] Properties to set
             */
            function LogoutRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogoutRequest sessionId.
             * @member {string} sessionId
             * @memberof mts.desk.LogoutRequest
             * @instance
             */
            LogoutRequest.prototype.sessionId = "";

            /**
             * Creates a new LogoutRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {mts.desk.ILogoutRequest=} [properties] Properties to set
             * @returns {mts.desk.LogoutRequest} LogoutRequest instance
             */
            LogoutRequest.create = function create(properties) {
                return new LogoutRequest(properties);
            };

            /**
             * Encodes the specified LogoutRequest message. Does not implicitly {@link mts.desk.LogoutRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {mts.desk.ILogoutRequest} message LogoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
                return writer;
            };

            /**
             * Encodes the specified LogoutRequest message, length delimited. Does not implicitly {@link mts.desk.LogoutRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {mts.desk.ILogoutRequest} message LogoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LogoutRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.LogoutRequest} LogoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.LogoutRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.sessionId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LogoutRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.LogoutRequest} LogoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LogoutRequest message.
             * @function verify
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogoutRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isString(message.sessionId))
                        return "sessionId: string expected";
                return null;
            };

            /**
             * Creates a LogoutRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.LogoutRequest} LogoutRequest
             */
            LogoutRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.LogoutRequest)
                    return object;
                let message = new $root.mts.desk.LogoutRequest();
                if (object.sessionId != null)
                    message.sessionId = String(object.sessionId);
                return message;
            };

            /**
             * Creates a plain object from a LogoutRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {mts.desk.LogoutRequest} message LogoutRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogoutRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.sessionId = "";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    object.sessionId = message.sessionId;
                return object;
            };

            /**
             * Converts this LogoutRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.LogoutRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogoutRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LogoutRequest
             * @function getTypeUrl
             * @memberof mts.desk.LogoutRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LogoutRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.LogoutRequest";
            };

            return LogoutRequest;
        })();

        desk.LogoutResponse = (function() {

            /**
             * Properties of a LogoutResponse.
             * @memberof mts.desk
             * @interface ILogoutResponse
             */

            /**
             * Constructs a new LogoutResponse.
             * @memberof mts.desk
             * @classdesc Represents a LogoutResponse.
             * @implements ILogoutResponse
             * @constructor
             * @param {mts.desk.ILogoutResponse=} [properties] Properties to set
             */
            function LogoutResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new LogoutResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {mts.desk.ILogoutResponse=} [properties] Properties to set
             * @returns {mts.desk.LogoutResponse} LogoutResponse instance
             */
            LogoutResponse.create = function create(properties) {
                return new LogoutResponse(properties);
            };

            /**
             * Encodes the specified LogoutResponse message. Does not implicitly {@link mts.desk.LogoutResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {mts.desk.ILogoutResponse} message LogoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified LogoutResponse message, length delimited. Does not implicitly {@link mts.desk.LogoutResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {mts.desk.ILogoutResponse} message LogoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LogoutResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.LogoutResponse} LogoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.LogoutResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LogoutResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.LogoutResponse} LogoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LogoutResponse message.
             * @function verify
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogoutResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a LogoutResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.LogoutResponse} LogoutResponse
             */
            LogoutResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.LogoutResponse)
                    return object;
                return new $root.mts.desk.LogoutResponse();
            };

            /**
             * Creates a plain object from a LogoutResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {mts.desk.LogoutResponse} message LogoutResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogoutResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this LogoutResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.LogoutResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogoutResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LogoutResponse
             * @function getTypeUrl
             * @memberof mts.desk.LogoutResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LogoutResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.LogoutResponse";
            };

            return LogoutResponse;
        })();

        desk.StartInstanceRequest = (function() {

            /**
             * Properties of a StartInstanceRequest.
             * @memberof mts.desk
             * @interface IStartInstanceRequest
             * @property {string|null} [instanceId] StartInstanceRequest instanceId
             */

            /**
             * Constructs a new StartInstanceRequest.
             * @memberof mts.desk
             * @classdesc Represents a StartInstanceRequest.
             * @implements IStartInstanceRequest
             * @constructor
             * @param {mts.desk.IStartInstanceRequest=} [properties] Properties to set
             */
            function StartInstanceRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StartInstanceRequest instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.StartInstanceRequest
             * @instance
             */
            StartInstanceRequest.prototype.instanceId = "";

            /**
             * Creates a new StartInstanceRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {mts.desk.IStartInstanceRequest=} [properties] Properties to set
             * @returns {mts.desk.StartInstanceRequest} StartInstanceRequest instance
             */
            StartInstanceRequest.create = function create(properties) {
                return new StartInstanceRequest(properties);
            };

            /**
             * Encodes the specified StartInstanceRequest message. Does not implicitly {@link mts.desk.StartInstanceRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {mts.desk.IStartInstanceRequest} message StartInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                return writer;
            };

            /**
             * Encodes the specified StartInstanceRequest message, length delimited. Does not implicitly {@link mts.desk.StartInstanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {mts.desk.IStartInstanceRequest} message StartInstanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StartInstanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.StartInstanceRequest} StartInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.StartInstanceRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StartInstanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.StartInstanceRequest} StartInstanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StartInstanceRequest message.
             * @function verify
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartInstanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                return null;
            };

            /**
             * Creates a StartInstanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.StartInstanceRequest} StartInstanceRequest
             */
            StartInstanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.StartInstanceRequest)
                    return object;
                let message = new $root.mts.desk.StartInstanceRequest();
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                return message;
            };

            /**
             * Creates a plain object from a StartInstanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {mts.desk.StartInstanceRequest} message StartInstanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartInstanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.instanceId = "";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                return object;
            };

            /**
             * Converts this StartInstanceRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.StartInstanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartInstanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartInstanceRequest
             * @function getTypeUrl
             * @memberof mts.desk.StartInstanceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.StartInstanceRequest";
            };

            return StartInstanceRequest;
        })();

        desk.DeskService = (function() {

            /**
             * Constructs a new DeskService service.
             * @memberof mts.desk
             * @classdesc Represents a DeskService
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function DeskService(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (DeskService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = DeskService;

            /**
             * Creates new DeskService service using the specified rpc implementation.
             * @function create
             * @memberof mts.desk.DeskService
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {DeskService} RPC service. Useful where requests and/or responses are streamed.
             */
            DeskService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link mts.desk.DeskService#addAccount}.
             * @memberof mts.desk.DeskService
             * @typedef addAccountCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.AddAccountResponse} [response] AddAccountResponse
             */

            /**
             * Calls addAccount.
             * @function addAccount
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddAccountRequest} request AddAccountRequest message or plain object
             * @param {mts.desk.DeskService.addAccountCallback} callback Node-style callback called with the error, if any, and AddAccountResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.addAccount = function addAccount(request, callback) {
                return this.rpcCall(addAccount, $root.mts.desk.AddAccountRequest, $root.mts.desk.AddAccountResponse, request, callback);
            }, "name", { value: "addAccount" });

            /**
             * Calls addAccount.
             * @function addAccount
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddAccountRequest} request AddAccountRequest message or plain object
             * @returns {Promise<mts.desk.AddAccountResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getAccount}.
             * @memberof mts.desk.DeskService
             * @typedef getAccountCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.GetAccountResponse} [response] GetAccountResponse
             */

            /**
             * Calls getAccount.
             * @function getAccount
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetAccountRequest} request GetAccountRequest message or plain object
             * @param {mts.desk.DeskService.getAccountCallback} callback Node-style callback called with the error, if any, and GetAccountResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getAccount = function getAccount(request, callback) {
                return this.rpcCall(getAccount, $root.mts.desk.GetAccountRequest, $root.mts.desk.GetAccountResponse, request, callback);
            }, "name", { value: "getAccount" });

            /**
             * Calls getAccount.
             * @function getAccount
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetAccountRequest} request GetAccountRequest message or plain object
             * @returns {Promise<mts.desk.GetAccountResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#addUser}.
             * @memberof mts.desk.DeskService
             * @typedef addUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.User} [response] User
             */

            /**
             * Calls addUser.
             * @function addUser
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddUserRequest} request AddUserRequest message or plain object
             * @param {mts.desk.DeskService.addUserCallback} callback Node-style callback called with the error, if any, and User
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.addUser = function addUser(request, callback) {
                return this.rpcCall(addUser, $root.mts.desk.AddUserRequest, $root.mts.desk.User, request, callback);
            }, "name", { value: "addUser" });

            /**
             * Calls addUser.
             * @function addUser
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddUserRequest} request AddUserRequest message or plain object
             * @returns {Promise<mts.desk.User>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getUser}.
             * @memberof mts.desk.DeskService
             * @typedef getUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.User} [response] User
             */

            /**
             * Calls getUser.
             * @function getUser
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetUserRequest} request GetUserRequest message or plain object
             * @param {mts.desk.DeskService.getUserCallback} callback Node-style callback called with the error, if any, and User
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getUser = function getUser(request, callback) {
                return this.rpcCall(getUser, $root.mts.desk.GetUserRequest, $root.mts.desk.User, request, callback);
            }, "name", { value: "getUser" });

            /**
             * Calls getUser.
             * @function getUser
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetUserRequest} request GetUserRequest message or plain object
             * @returns {Promise<mts.desk.User>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#addTrader}.
             * @memberof mts.desk.DeskService
             * @typedef addTraderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.AddTraderResponse} [response] AddTraderResponse
             */

            /**
             * Calls addTrader.
             * @function addTrader
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddTraderRequest} request AddTraderRequest message or plain object
             * @param {mts.desk.DeskService.addTraderCallback} callback Node-style callback called with the error, if any, and AddTraderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.addTrader = function addTrader(request, callback) {
                return this.rpcCall(addTrader, $root.mts.desk.AddTraderRequest, $root.mts.desk.AddTraderResponse, request, callback);
            }, "name", { value: "addTrader" });

            /**
             * Calls addTrader.
             * @function addTrader
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddTraderRequest} request AddTraderRequest message or plain object
             * @returns {Promise<mts.desk.AddTraderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#deleteTraders}.
             * @memberof mts.desk.DeskService
             * @typedef deleteTradersCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.DeleteTradersResponse} [response] DeleteTradersResponse
             */

            /**
             * Calls deleteTraders.
             * @function deleteTraders
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IDeleteTradersRequest} request DeleteTradersRequest message or plain object
             * @param {mts.desk.DeskService.deleteTradersCallback} callback Node-style callback called with the error, if any, and DeleteTradersResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.deleteTraders = function deleteTraders(request, callback) {
                return this.rpcCall(deleteTraders, $root.mts.desk.DeleteTradersRequest, $root.mts.desk.DeleteTradersResponse, request, callback);
            }, "name", { value: "deleteTraders" });

            /**
             * Calls deleteTraders.
             * @function deleteTraders
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IDeleteTradersRequest} request DeleteTradersRequest message or plain object
             * @returns {Promise<mts.desk.DeleteTradersResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#updateTrader}.
             * @memberof mts.desk.DeskService
             * @typedef updateTraderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.UpdateTraderResponse} [response] UpdateTraderResponse
             */

            /**
             * Calls updateTrader.
             * @function updateTrader
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IUpdateTraderRequest} request UpdateTraderRequest message or plain object
             * @param {mts.desk.DeskService.updateTraderCallback} callback Node-style callback called with the error, if any, and UpdateTraderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.updateTrader = function updateTrader(request, callback) {
                return this.rpcCall(updateTrader, $root.mts.desk.UpdateTraderRequest, $root.mts.desk.UpdateTraderResponse, request, callback);
            }, "name", { value: "updateTrader" });

            /**
             * Calls updateTrader.
             * @function updateTrader
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IUpdateTraderRequest} request UpdateTraderRequest message or plain object
             * @returns {Promise<mts.desk.UpdateTraderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getTrader}.
             * @memberof mts.desk.DeskService
             * @typedef getTraderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.GetTraderResponse} [response] GetTraderResponse
             */

            /**
             * Calls getTrader.
             * @function getTrader
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetTraderRequest} request GetTraderRequest message or plain object
             * @param {mts.desk.DeskService.getTraderCallback} callback Node-style callback called with the error, if any, and GetTraderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getTrader = function getTrader(request, callback) {
                return this.rpcCall(getTrader, $root.mts.desk.GetTraderRequest, $root.mts.desk.GetTraderResponse, request, callback);
            }, "name", { value: "getTrader" });

            /**
             * Calls getTrader.
             * @function getTrader
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetTraderRequest} request GetTraderRequest message or plain object
             * @returns {Promise<mts.desk.GetTraderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#addTraderInstance}.
             * @memberof mts.desk.DeskService
             * @typedef addTraderInstanceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.AddTraderInstanceResponse} [response] AddTraderInstanceResponse
             */

            /**
             * Calls addTraderInstance.
             * @function addTraderInstance
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddTraderInstanceRequest} request AddTraderInstanceRequest message or plain object
             * @param {mts.desk.DeskService.addTraderInstanceCallback} callback Node-style callback called with the error, if any, and AddTraderInstanceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.addTraderInstance = function addTraderInstance(request, callback) {
                return this.rpcCall(addTraderInstance, $root.mts.desk.AddTraderInstanceRequest, $root.mts.desk.AddTraderInstanceResponse, request, callback);
            }, "name", { value: "addTraderInstance" });

            /**
             * Calls addTraderInstance.
             * @function addTraderInstance
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IAddTraderInstanceRequest} request AddTraderInstanceRequest message or plain object
             * @returns {Promise<mts.desk.AddTraderInstanceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getTraderInstance}.
             * @memberof mts.desk.DeskService
             * @typedef getTraderInstanceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.GetTraderInstanceResponse} [response] GetTraderInstanceResponse
             */

            /**
             * Calls getTraderInstance.
             * @function getTraderInstance
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetTraderInstanceRequest} request GetTraderInstanceRequest message or plain object
             * @param {mts.desk.DeskService.getTraderInstanceCallback} callback Node-style callback called with the error, if any, and GetTraderInstanceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getTraderInstance = function getTraderInstance(request, callback) {
                return this.rpcCall(getTraderInstance, $root.mts.desk.GetTraderInstanceRequest, $root.mts.desk.GetTraderInstanceResponse, request, callback);
            }, "name", { value: "getTraderInstance" });

            /**
             * Calls getTraderInstance.
             * @function getTraderInstance
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IGetTraderInstanceRequest} request GetTraderInstanceRequest message or plain object
             * @returns {Promise<mts.desk.GetTraderInstanceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#startInstance}.
             * @memberof mts.desk.DeskService
             * @typedef startInstanceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.TraderInstance} [response] TraderInstance
             */

            /**
             * Calls startInstance.
             * @function startInstance
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IStartInstanceRequest} request StartInstanceRequest message or plain object
             * @param {mts.desk.DeskService.startInstanceCallback} callback Node-style callback called with the error, if any, and TraderInstance
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.startInstance = function startInstance(request, callback) {
                return this.rpcCall(startInstance, $root.mts.desk.StartInstanceRequest, $root.mts.desk.TraderInstance, request, callback);
            }, "name", { value: "startInstance" });

            /**
             * Calls startInstance.
             * @function startInstance
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IStartInstanceRequest} request StartInstanceRequest message or plain object
             * @returns {Promise<mts.desk.TraderInstance>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#updateInstanceOptionValues}.
             * @memberof mts.desk.DeskService
             * @typedef updateInstanceOptionValuesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.UpdateInstanceOptionValuesResponse} [response] UpdateInstanceOptionValuesResponse
             */

            /**
             * Calls updateInstanceOptionValues.
             * @function updateInstanceOptionValues
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IUpdateInstanceOptionValuesRequest} request UpdateInstanceOptionValuesRequest message or plain object
             * @param {mts.desk.DeskService.updateInstanceOptionValuesCallback} callback Node-style callback called with the error, if any, and UpdateInstanceOptionValuesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.updateInstanceOptionValues = function updateInstanceOptionValues(request, callback) {
                return this.rpcCall(updateInstanceOptionValues, $root.mts.desk.UpdateInstanceOptionValuesRequest, $root.mts.desk.UpdateInstanceOptionValuesResponse, request, callback);
            }, "name", { value: "updateInstanceOptionValues" });

            /**
             * Calls updateInstanceOptionValues.
             * @function updateInstanceOptionValues
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.IUpdateInstanceOptionValuesRequest} request UpdateInstanceOptionValuesRequest message or plain object
             * @returns {Promise<mts.desk.UpdateInstanceOptionValuesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#time}.
             * @memberof mts.desk.DeskService
             * @typedef timeCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.desk.Time} [response] Time
             */

            /**
             * Calls time.
             * @function time
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.ITimeRequest} request TimeRequest message or plain object
             * @param {mts.desk.DeskService.timeCallback} callback Node-style callback called with the error, if any, and Time
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.time = function time(request, callback) {
                return this.rpcCall(time, $root.mts.desk.TimeRequest, $root.mts.desk.Time, request, callback);
            }, "name", { value: "time" });

            /**
             * Calls time.
             * @function time
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.desk.ITimeRequest} request TimeRequest message or plain object
             * @returns {Promise<mts.desk.Time>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getCandles}.
             * @memberof mts.desk.DeskService
             * @typedef getCandlesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.common.marketdata.GetCandlesResponse} [response] GetCandlesResponse
             */

            /**
             * * A basic collection of market data calls.
             * *
             * * Should these be moved to their own service?
             * @function getCandles
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.common.marketdata.IGetCandlesRequest} request GetCandlesRequest message or plain object
             * @param {mts.desk.DeskService.getCandlesCallback} callback Node-style callback called with the error, if any, and GetCandlesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getCandles = function getCandles(request, callback) {
                return this.rpcCall(getCandles, $root.mts.common.marketdata.GetCandlesRequest, $root.mts.common.marketdata.GetCandlesResponse, request, callback);
            }, "name", { value: "getCandles" });

            /**
             * * A basic collection of market data calls.
             * *
             * * Should these be moved to their own service?
             * @function getCandles
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.common.marketdata.IGetCandlesRequest} request GetCandlesRequest message or plain object
             * @returns {Promise<mts.common.marketdata.GetCandlesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getInstruments}.
             * @memberof mts.desk.DeskService
             * @typedef getInstrumentsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.common.marketdata.GetInstrumentsResponse} [response] GetInstrumentsResponse
             */

            /**
             * Calls getInstruments.
             * @function getInstruments
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.common.marketdata.IGetInstrumentsRequest} request GetInstrumentsRequest message or plain object
             * @param {mts.desk.DeskService.getInstrumentsCallback} callback Node-style callback called with the error, if any, and GetInstrumentsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getInstruments = function getInstruments(request, callback) {
                return this.rpcCall(getInstruments, $root.mts.common.marketdata.GetInstrumentsRequest, $root.mts.common.marketdata.GetInstrumentsResponse, request, callback);
            }, "name", { value: "getInstruments" });

            /**
             * Calls getInstruments.
             * @function getInstruments
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.common.marketdata.IGetInstrumentsRequest} request GetInstrumentsRequest message or plain object
             * @returns {Promise<mts.common.marketdata.GetInstrumentsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#placeOrder}.
             * @memberof mts.desk.DeskService
             * @typedef placeOrderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.CreateOrderResponse} [response] CreateOrderResponse
             */

            /**
             * Calls placeOrder.
             * @function placeOrder
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.broker.ICreateOrderRequest} request CreateOrderRequest message or plain object
             * @param {mts.desk.DeskService.placeOrderCallback} callback Node-style callback called with the error, if any, and CreateOrderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.placeOrder = function placeOrder(request, callback) {
                return this.rpcCall(placeOrder, $root.mts.broker.CreateOrderRequest, $root.mts.broker.CreateOrderResponse, request, callback);
            }, "name", { value: "placeOrder" });

            /**
             * Calls placeOrder.
             * @function placeOrder
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.broker.ICreateOrderRequest} request CreateOrderRequest message or plain object
             * @returns {Promise<mts.broker.CreateOrderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getOrders}.
             * @memberof mts.desk.DeskService
             * @typedef getOrdersCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.GetOrdersResponse} [response] GetOrdersResponse
             */

            /**
             * Calls getOrders.
             * @function getOrders
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.broker.IGetOrdersRequest} request GetOrdersRequest message or plain object
             * @param {mts.desk.DeskService.getOrdersCallback} callback Node-style callback called with the error, if any, and GetOrdersResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getOrders = function getOrders(request, callback) {
                return this.rpcCall(getOrders, $root.mts.broker.GetOrdersRequest, $root.mts.broker.GetOrdersResponse, request, callback);
            }, "name", { value: "getOrders" });

            /**
             * Calls getOrders.
             * @function getOrders
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.broker.IGetOrdersRequest} request GetOrdersRequest message or plain object
             * @returns {Promise<mts.broker.GetOrdersResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.DeskService#getPositions}.
             * @memberof mts.desk.DeskService
             * @typedef getPositionsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.Positions} [response] Positions
             */

            /**
             * Calls getPositions.
             * @function getPositions
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.broker.IGetPositionsRequest} request GetPositionsRequest message or plain object
             * @param {mts.desk.DeskService.getPositionsCallback} callback Node-style callback called with the error, if any, and Positions
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(DeskService.prototype.getPositions = function getPositions(request, callback) {
                return this.rpcCall(getPositions, $root.mts.broker.GetPositionsRequest, $root.mts.broker.Positions, request, callback);
            }, "name", { value: "getPositions" });

            /**
             * Calls getPositions.
             * @function getPositions
             * @memberof mts.desk.DeskService
             * @instance
             * @param {mts.broker.IGetPositionsRequest} request GetPositionsRequest message or plain object
             * @returns {Promise<mts.broker.Positions>} Promise
             * @variation 2
             */

            return DeskService;
        })();

        desk.TraderService = (function() {

            /**
             * Constructs a new TraderService service.
             * @memberof mts.desk
             * @classdesc Represents a TraderService
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function TraderService(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (TraderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TraderService;

            /**
             * Creates new TraderService service using the specified rpc implementation.
             * @function create
             * @memberof mts.desk.TraderService
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {TraderService} RPC service. Useful where requests and/or responses are streamed.
             */
            TraderService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link mts.desk.TraderService#getCandles}.
             * @memberof mts.desk.TraderService
             * @typedef getCandlesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.common.marketdata.GetCandlesResponse} [response] GetCandlesResponse
             */

            /**
             * Calls getCandles.
             * @function getCandles
             * @memberof mts.desk.TraderService
             * @instance
             * @param {mts.common.marketdata.IGetCandlesRequest} request GetCandlesRequest message or plain object
             * @param {mts.desk.TraderService.getCandlesCallback} callback Node-style callback called with the error, if any, and GetCandlesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(TraderService.prototype.getCandles = function getCandles(request, callback) {
                return this.rpcCall(getCandles, $root.mts.common.marketdata.GetCandlesRequest, $root.mts.common.marketdata.GetCandlesResponse, request, callback);
            }, "name", { value: "getCandles" });

            /**
             * Calls getCandles.
             * @function getCandles
             * @memberof mts.desk.TraderService
             * @instance
             * @param {mts.common.marketdata.IGetCandlesRequest} request GetCandlesRequest message or plain object
             * @returns {Promise<mts.common.marketdata.GetCandlesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.desk.TraderService#getInstruments}.
             * @memberof mts.desk.TraderService
             * @typedef getInstrumentsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.common.marketdata.GetInstrumentsResponse} [response] GetInstrumentsResponse
             */

            /**
             * Calls getInstruments.
             * @function getInstruments
             * @memberof mts.desk.TraderService
             * @instance
             * @param {mts.common.marketdata.IGetInstrumentsRequest} request GetInstrumentsRequest message or plain object
             * @param {mts.desk.TraderService.getInstrumentsCallback} callback Node-style callback called with the error, if any, and GetInstrumentsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(TraderService.prototype.getInstruments = function getInstruments(request, callback) {
                return this.rpcCall(getInstruments, $root.mts.common.marketdata.GetInstrumentsRequest, $root.mts.common.marketdata.GetInstrumentsResponse, request, callback);
            }, "name", { value: "getInstruments" });

            /**
             * Calls getInstruments.
             * @function getInstruments
             * @memberof mts.desk.TraderService
             * @instance
             * @param {mts.common.marketdata.IGetInstrumentsRequest} request GetInstrumentsRequest message or plain object
             * @returns {Promise<mts.common.marketdata.GetInstrumentsResponse>} Promise
             * @variation 2
             */

            return TraderService;
        })();

        /**
         * TimeUnit enum.
         * @name mts.desk.TimeUnit
         * @enum {number}
         * @property {number} UNKNOWN_TIMEUNIT=0 UNKNOWN_TIMEUNIT value
         * @property {number} SECONDS=1 SECONDS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} DAYS=3 DAYS value
         */
        desk.TimeUnit = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_TIMEUNIT"] = 0;
            values[valuesById[1] = "SECONDS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "DAYS"] = 3;
            return values;
        })();

        desk.GetTraderRequest = (function() {

            /**
             * Properties of a GetTraderRequest.
             * @memberof mts.desk
             * @interface IGetTraderRequest
             * @property {string|null} [traderId] GetTraderRequest traderId
             */

            /**
             * Constructs a new GetTraderRequest.
             * @memberof mts.desk
             * @classdesc Represents a GetTraderRequest.
             * @implements IGetTraderRequest
             * @constructor
             * @param {mts.desk.IGetTraderRequest=} [properties] Properties to set
             */
            function GetTraderRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTraderRequest traderId.
             * @member {string} traderId
             * @memberof mts.desk.GetTraderRequest
             * @instance
             */
            GetTraderRequest.prototype.traderId = "";

            /**
             * Creates a new GetTraderRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {mts.desk.IGetTraderRequest=} [properties] Properties to set
             * @returns {mts.desk.GetTraderRequest} GetTraderRequest instance
             */
            GetTraderRequest.create = function create(properties) {
                return new GetTraderRequest(properties);
            };

            /**
             * Encodes the specified GetTraderRequest message. Does not implicitly {@link mts.desk.GetTraderRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {mts.desk.IGetTraderRequest} message GetTraderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traderId != null && Object.hasOwnProperty.call(message, "traderId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderId);
                return writer;
            };

            /**
             * Encodes the specified GetTraderRequest message, length delimited. Does not implicitly {@link mts.desk.GetTraderRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {mts.desk.IGetTraderRequest} message GetTraderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTraderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetTraderRequest} GetTraderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetTraderRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.traderId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTraderRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetTraderRequest} GetTraderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTraderRequest message.
             * @function verify
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTraderRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traderId != null && message.hasOwnProperty("traderId"))
                    if (!$util.isString(message.traderId))
                        return "traderId: string expected";
                return null;
            };

            /**
             * Creates a GetTraderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetTraderRequest} GetTraderRequest
             */
            GetTraderRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetTraderRequest)
                    return object;
                let message = new $root.mts.desk.GetTraderRequest();
                if (object.traderId != null)
                    message.traderId = String(object.traderId);
                return message;
            };

            /**
             * Creates a plain object from a GetTraderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {mts.desk.GetTraderRequest} message GetTraderRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTraderRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.traderId = "";
                if (message.traderId != null && message.hasOwnProperty("traderId"))
                    object.traderId = message.traderId;
                return object;
            };

            /**
             * Converts this GetTraderRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.GetTraderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTraderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTraderRequest
             * @function getTypeUrl
             * @memberof mts.desk.GetTraderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTraderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetTraderRequest";
            };

            return GetTraderRequest;
        })();

        desk.GetTraderResponse = (function() {

            /**
             * Properties of a GetTraderResponse.
             * @memberof mts.desk
             * @interface IGetTraderResponse
             * @property {mts.desk.ITrader|null} [trader] GetTraderResponse trader
             */

            /**
             * Constructs a new GetTraderResponse.
             * @memberof mts.desk
             * @classdesc Represents a GetTraderResponse.
             * @implements IGetTraderResponse
             * @constructor
             * @param {mts.desk.IGetTraderResponse=} [properties] Properties to set
             */
            function GetTraderResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTraderResponse trader.
             * @member {mts.desk.ITrader|null|undefined} trader
             * @memberof mts.desk.GetTraderResponse
             * @instance
             */
            GetTraderResponse.prototype.trader = null;

            /**
             * Creates a new GetTraderResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {mts.desk.IGetTraderResponse=} [properties] Properties to set
             * @returns {mts.desk.GetTraderResponse} GetTraderResponse instance
             */
            GetTraderResponse.create = function create(properties) {
                return new GetTraderResponse(properties);
            };

            /**
             * Encodes the specified GetTraderResponse message. Does not implicitly {@link mts.desk.GetTraderResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {mts.desk.IGetTraderResponse} message GetTraderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trader != null && Object.hasOwnProperty.call(message, "trader"))
                    $root.mts.desk.Trader.encode(message.trader, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetTraderResponse message, length delimited. Does not implicitly {@link mts.desk.GetTraderResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {mts.desk.IGetTraderResponse} message GetTraderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTraderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetTraderResponse} GetTraderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetTraderResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.trader = $root.mts.desk.Trader.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTraderResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetTraderResponse} GetTraderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTraderResponse message.
             * @function verify
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTraderResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trader != null && message.hasOwnProperty("trader")) {
                    let error = $root.mts.desk.Trader.verify(message.trader);
                    if (error)
                        return "trader." + error;
                }
                return null;
            };

            /**
             * Creates a GetTraderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetTraderResponse} GetTraderResponse
             */
            GetTraderResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetTraderResponse)
                    return object;
                let message = new $root.mts.desk.GetTraderResponse();
                if (object.trader != null) {
                    if (typeof object.trader !== "object")
                        throw TypeError(".mts.desk.GetTraderResponse.trader: object expected");
                    message.trader = $root.mts.desk.Trader.fromObject(object.trader);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetTraderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {mts.desk.GetTraderResponse} message GetTraderResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTraderResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.trader = null;
                if (message.trader != null && message.hasOwnProperty("trader"))
                    object.trader = $root.mts.desk.Trader.toObject(message.trader, options);
                return object;
            };

            /**
             * Converts this GetTraderResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.GetTraderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTraderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTraderResponse
             * @function getTypeUrl
             * @memberof mts.desk.GetTraderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTraderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetTraderResponse";
            };

            return GetTraderResponse;
        })();

        desk.Session = (function() {

            /**
             * Properties of a Session.
             * @memberof mts.desk
             * @interface ISession
             * @property {string|null} [id] Session id
             * @property {string|null} [userId] Session userId
             * @property {string|null} [created] Session created
             * @property {string|null} [expired] Session expired
             * @property {string|null} [closed] Session closed
             * @property {string|null} [remoteIp] Session remoteIp
             * @property {string|null} [userAgent] Session userAgent
             * @property {number|null} [viewportWidth] Session viewportWidth
             * @property {number|null} [viewportHeight] Session viewportHeight
             */

            /**
             * Constructs a new Session.
             * @memberof mts.desk
             * @classdesc Represents a Session.
             * @implements ISession
             * @constructor
             * @param {mts.desk.ISession=} [properties] Properties to set
             */
            function Session(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Session id.
             * @member {string} id
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.id = "";

            /**
             * Session userId.
             * @member {string} userId
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.userId = "";

            /**
             * Session created.
             * @member {string} created
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.created = "";

            /**
             * Session expired.
             * @member {string} expired
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.expired = "";

            /**
             * Session closed.
             * @member {string} closed
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.closed = "";

            /**
             * Session remoteIp.
             * @member {string} remoteIp
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.remoteIp = "";

            /**
             * Session userAgent.
             * @member {string} userAgent
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.userAgent = "";

            /**
             * Session viewportWidth.
             * @member {number} viewportWidth
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.viewportWidth = 0;

            /**
             * Session viewportHeight.
             * @member {number} viewportHeight
             * @memberof mts.desk.Session
             * @instance
             */
            Session.prototype.viewportHeight = 0;

            /**
             * Creates a new Session instance using the specified properties.
             * @function create
             * @memberof mts.desk.Session
             * @static
             * @param {mts.desk.ISession=} [properties] Properties to set
             * @returns {mts.desk.Session} Session instance
             */
            Session.create = function create(properties) {
                return new Session(properties);
            };

            /**
             * Encodes the specified Session message. Does not implicitly {@link mts.desk.Session.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.Session
             * @static
             * @param {mts.desk.ISession} message Session message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Session.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
                if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.created);
                if (message.expired != null && Object.hasOwnProperty.call(message, "expired"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.expired);
                if (message.closed != null && Object.hasOwnProperty.call(message, "closed"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.closed);
                if (message.remoteIp != null && Object.hasOwnProperty.call(message, "remoteIp"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.remoteIp);
                if (message.userAgent != null && Object.hasOwnProperty.call(message, "userAgent"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.userAgent);
                if (message.viewportWidth != null && Object.hasOwnProperty.call(message, "viewportWidth"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.viewportWidth);
                if (message.viewportHeight != null && Object.hasOwnProperty.call(message, "viewportHeight"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.viewportHeight);
                return writer;
            };

            /**
             * Encodes the specified Session message, length delimited. Does not implicitly {@link mts.desk.Session.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.Session
             * @static
             * @param {mts.desk.ISession} message Session message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Session.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Session message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.Session
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.Session} Session
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Session.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.Session();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.userId = reader.string();
                            break;
                        }
                    case 3: {
                            message.created = reader.string();
                            break;
                        }
                    case 4: {
                            message.expired = reader.string();
                            break;
                        }
                    case 5: {
                            message.closed = reader.string();
                            break;
                        }
                    case 10: {
                            message.remoteIp = reader.string();
                            break;
                        }
                    case 11: {
                            message.userAgent = reader.string();
                            break;
                        }
                    case 12: {
                            message.viewportWidth = reader.int32();
                            break;
                        }
                    case 13: {
                            message.viewportHeight = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Session message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.Session
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.Session} Session
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Session.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Session message.
             * @function verify
             * @memberof mts.desk.Session
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Session.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.created != null && message.hasOwnProperty("created"))
                    if (!$util.isString(message.created))
                        return "created: string expected";
                if (message.expired != null && message.hasOwnProperty("expired"))
                    if (!$util.isString(message.expired))
                        return "expired: string expected";
                if (message.closed != null && message.hasOwnProperty("closed"))
                    if (!$util.isString(message.closed))
                        return "closed: string expected";
                if (message.remoteIp != null && message.hasOwnProperty("remoteIp"))
                    if (!$util.isString(message.remoteIp))
                        return "remoteIp: string expected";
                if (message.userAgent != null && message.hasOwnProperty("userAgent"))
                    if (!$util.isString(message.userAgent))
                        return "userAgent: string expected";
                if (message.viewportWidth != null && message.hasOwnProperty("viewportWidth"))
                    if (!$util.isInteger(message.viewportWidth))
                        return "viewportWidth: integer expected";
                if (message.viewportHeight != null && message.hasOwnProperty("viewportHeight"))
                    if (!$util.isInteger(message.viewportHeight))
                        return "viewportHeight: integer expected";
                return null;
            };

            /**
             * Creates a Session message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.Session
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.Session} Session
             */
            Session.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.Session)
                    return object;
                let message = new $root.mts.desk.Session();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.created != null)
                    message.created = String(object.created);
                if (object.expired != null)
                    message.expired = String(object.expired);
                if (object.closed != null)
                    message.closed = String(object.closed);
                if (object.remoteIp != null)
                    message.remoteIp = String(object.remoteIp);
                if (object.userAgent != null)
                    message.userAgent = String(object.userAgent);
                if (object.viewportWidth != null)
                    message.viewportWidth = object.viewportWidth | 0;
                if (object.viewportHeight != null)
                    message.viewportHeight = object.viewportHeight | 0;
                return message;
            };

            /**
             * Creates a plain object from a Session message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.Session
             * @static
             * @param {mts.desk.Session} message Session
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Session.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = "";
                    object.userId = "";
                    object.created = "";
                    object.expired = "";
                    object.closed = "";
                    object.remoteIp = "";
                    object.userAgent = "";
                    object.viewportWidth = 0;
                    object.viewportHeight = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.created != null && message.hasOwnProperty("created"))
                    object.created = message.created;
                if (message.expired != null && message.hasOwnProperty("expired"))
                    object.expired = message.expired;
                if (message.closed != null && message.hasOwnProperty("closed"))
                    object.closed = message.closed;
                if (message.remoteIp != null && message.hasOwnProperty("remoteIp"))
                    object.remoteIp = message.remoteIp;
                if (message.userAgent != null && message.hasOwnProperty("userAgent"))
                    object.userAgent = message.userAgent;
                if (message.viewportWidth != null && message.hasOwnProperty("viewportWidth"))
                    object.viewportWidth = message.viewportWidth;
                if (message.viewportHeight != null && message.hasOwnProperty("viewportHeight"))
                    object.viewportHeight = message.viewportHeight;
                return object;
            };

            /**
             * Converts this Session to JSON.
             * @function toJSON
             * @memberof mts.desk.Session
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Session.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Session
             * @function getTypeUrl
             * @memberof mts.desk.Session
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Session.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.Session";
            };

            return Session;
        })();

        desk.TraderInstance = (function() {

            /**
             * Properties of a TraderInstance.
             * @memberof mts.desk
             * @interface ITraderInstance
             * @property {string|null} [id] TraderInstance id
             * @property {string|null} [userId] TraderInstance userId
             * @property {string|null} [traderId] TraderInstance traderId
             * @property {mts.desk.ITrader|null} [trader] TraderInstance trader
             * @property {mts.desk.TraderInstance.State|null} [state] TraderInstance state
             * @property {string|null} [portfolioId] TraderInstance portfolioId
             * @property {string|null} [portfolioName] TraderInstance portfolioName
             * @property {string|null} [brokerAddr] TraderInstance brokerAddr
             * @property {string|null} [brokerAccountId] TraderInstance brokerAccountId
             * @property {string|null} [deskAddr] TraderInstance deskAddr
             * @property {string|null} [error] TraderInstance error
             * @property {number|Long|null} [errorTime] TraderInstance errorTime
             * @property {string|null} [created] TraderInstance created
             * @property {string|null} [started] TraderInstance started
             * @property {string|null} [stopping] TraderInstance stopping
             * @property {string|null} [stopped] TraderInstance stopped
             * @property {string|null} [paused] TraderInstance paused
             * @property {string|null} [finished] TraderInstance finished
             * @property {string|null} [deleted] TraderInstance deleted
             * @property {mts.desk.TraderInstance.Mode|null} [mode] TraderInstance mode
             * @property {string|null} [from] TraderInstance from
             * @property {string|null} [to] TraderInstance to
             * @property {number|null} [heartbeatInterval] TraderInstance heartbeatInterval
             * @property {Object.<string,string>|null} [settings] TraderInstance settings
             * @property {Object.<string,string>|null} [optionValues] TraderInstance optionValues
             * @property {string|null} [runnerId] TraderInstance runnerId
             * @property {boolean|null} [interrupted] TraderInstance interrupted
             * @property {mts.desk.Access|null} [access] TraderInstance access
             */

            /**
             * Constructs a new TraderInstance.
             * @memberof mts.desk
             * @classdesc Stores the state, type and other stats when a trader is run
             * @implements ITraderInstance
             * @constructor
             * @param {mts.desk.ITraderInstance=} [properties] Properties to set
             */
            function TraderInstance(properties) {
                this.settings = {};
                this.optionValues = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TraderInstance id.
             * @member {string} id
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.id = "";

            /**
             * TraderInstance userId.
             * @member {string} userId
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.userId = "";

            /**
             * TraderInstance traderId.
             * @member {string} traderId
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.traderId = "";

            /**
             * TraderInstance trader.
             * @member {mts.desk.ITrader|null|undefined} trader
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.trader = null;

            /**
             * TraderInstance state.
             * @member {mts.desk.TraderInstance.State} state
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.state = 0;

            /**
             * TraderInstance portfolioId.
             * @member {string} portfolioId
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.portfolioId = "";

            /**
             * TraderInstance portfolioName.
             * @member {string} portfolioName
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.portfolioName = "";

            /**
             * TraderInstance brokerAddr.
             * @member {string} brokerAddr
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.brokerAddr = "";

            /**
             * TraderInstance brokerAccountId.
             * @member {string} brokerAccountId
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.brokerAccountId = "";

            /**
             * TraderInstance deskAddr.
             * @member {string} deskAddr
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.deskAddr = "";

            /**
             * TraderInstance error.
             * @member {string} error
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.error = "";

            /**
             * TraderInstance errorTime.
             * @member {number|Long} errorTime
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.errorTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TraderInstance created.
             * @member {string} created
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.created = "";

            /**
             * TraderInstance started.
             * @member {string} started
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.started = "";

            /**
             * TraderInstance stopping.
             * @member {string} stopping
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.stopping = "";

            /**
             * TraderInstance stopped.
             * @member {string} stopped
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.stopped = "";

            /**
             * TraderInstance paused.
             * @member {string} paused
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.paused = "";

            /**
             * TraderInstance finished.
             * @member {string} finished
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.finished = "";

            /**
             * TraderInstance deleted.
             * @member {string} deleted
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.deleted = "";

            /**
             * TraderInstance mode.
             * @member {mts.desk.TraderInstance.Mode} mode
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.mode = 0;

            /**
             * TraderInstance from.
             * @member {string} from
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.from = "";

            /**
             * TraderInstance to.
             * @member {string} to
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.to = "";

            /**
             * TraderInstance heartbeatInterval.
             * @member {number} heartbeatInterval
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.heartbeatInterval = 0;

            /**
             * TraderInstance settings.
             * @member {Object.<string,string>} settings
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.settings = $util.emptyObject;

            /**
             * TraderInstance optionValues.
             * @member {Object.<string,string>} optionValues
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.optionValues = $util.emptyObject;

            /**
             * TraderInstance runnerId.
             * @member {string} runnerId
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.runnerId = "";

            /**
             * TraderInstance interrupted.
             * @member {boolean} interrupted
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.interrupted = false;

            /**
             * TraderInstance access.
             * @member {mts.desk.Access} access
             * @memberof mts.desk.TraderInstance
             * @instance
             */
            TraderInstance.prototype.access = 0;

            /**
             * Creates a new TraderInstance instance using the specified properties.
             * @function create
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {mts.desk.ITraderInstance=} [properties] Properties to set
             * @returns {mts.desk.TraderInstance} TraderInstance instance
             */
            TraderInstance.create = function create(properties) {
                return new TraderInstance(properties);
            };

            /**
             * Encodes the specified TraderInstance message. Does not implicitly {@link mts.desk.TraderInstance.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {mts.desk.ITraderInstance} message TraderInstance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TraderInstance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
                if (message.trader != null && Object.hasOwnProperty.call(message, "trader"))
                    $root.mts.desk.Trader.encode(message.trader, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
                if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.created);
                if (message.started != null && Object.hasOwnProperty.call(message, "started"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.started);
                if (message.finished != null && Object.hasOwnProperty.call(message, "finished"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.finished);
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.portfolioId);
                if (message.brokerAddr != null && Object.hasOwnProperty.call(message, "brokerAddr"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.brokerAddr);
                if (message.brokerAccountId != null && Object.hasOwnProperty.call(message, "brokerAccountId"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.brokerAccountId);
                if (message.deskAddr != null && Object.hasOwnProperty.call(message, "deskAddr"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.deskAddr);
                if (message.deleted != null && Object.hasOwnProperty.call(message, "deleted"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.deleted);
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.to);
                if (message.portfolioName != null && Object.hasOwnProperty.call(message, "portfolioName"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.portfolioName);
                if (message.heartbeatInterval != null && Object.hasOwnProperty.call(message, "heartbeatInterval"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int32(message.heartbeatInterval);
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    for (let keys = Object.keys(message.settings), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 19, wireType 2 =*/154).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.settings[keys[i]]).ldelim();
                if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int32(message.mode);
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.error);
                if (message.traderId != null && Object.hasOwnProperty.call(message, "traderId"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.traderId);
                if (message.optionValues != null && Object.hasOwnProperty.call(message, "optionValues"))
                    for (let keys = Object.keys(message.optionValues), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 23, wireType 2 =*/186).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.optionValues[keys[i]]).ldelim();
                if (message.errorTime != null && Object.hasOwnProperty.call(message, "errorTime"))
                    writer.uint32(/* id 24, wireType 0 =*/192).int64(message.errorTime);
                if (message.stopping != null && Object.hasOwnProperty.call(message, "stopping"))
                    writer.uint32(/* id 25, wireType 2 =*/202).string(message.stopping);
                if (message.stopped != null && Object.hasOwnProperty.call(message, "stopped"))
                    writer.uint32(/* id 26, wireType 2 =*/210).string(message.stopped);
                if (message.paused != null && Object.hasOwnProperty.call(message, "paused"))
                    writer.uint32(/* id 27, wireType 2 =*/218).string(message.paused);
                if (message.runnerId != null && Object.hasOwnProperty.call(message, "runnerId"))
                    writer.uint32(/* id 29, wireType 2 =*/234).string(message.runnerId);
                if (message.interrupted != null && Object.hasOwnProperty.call(message, "interrupted"))
                    writer.uint32(/* id 30, wireType 0 =*/240).bool(message.interrupted);
                if (message.access != null && Object.hasOwnProperty.call(message, "access"))
                    writer.uint32(/* id 31, wireType 0 =*/248).int32(message.access);
                return writer;
            };

            /**
             * Encodes the specified TraderInstance message, length delimited. Does not implicitly {@link mts.desk.TraderInstance.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {mts.desk.ITraderInstance} message TraderInstance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TraderInstance.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TraderInstance message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.TraderInstance} TraderInstance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TraderInstance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.TraderInstance(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.userId = reader.string();
                            break;
                        }
                    case 22: {
                            message.traderId = reader.string();
                            break;
                        }
                    case 3: {
                            message.trader = $root.mts.desk.Trader.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.state = reader.int32();
                            break;
                        }
                    case 8: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    case 16: {
                            message.portfolioName = reader.string();
                            break;
                        }
                    case 9: {
                            message.brokerAddr = reader.string();
                            break;
                        }
                    case 10: {
                            message.brokerAccountId = reader.string();
                            break;
                        }
                    case 11: {
                            message.deskAddr = reader.string();
                            break;
                        }
                    case 21: {
                            message.error = reader.string();
                            break;
                        }
                    case 24: {
                            message.errorTime = reader.int64();
                            break;
                        }
                    case 5: {
                            message.created = reader.string();
                            break;
                        }
                    case 6: {
                            message.started = reader.string();
                            break;
                        }
                    case 25: {
                            message.stopping = reader.string();
                            break;
                        }
                    case 26: {
                            message.stopped = reader.string();
                            break;
                        }
                    case 27: {
                            message.paused = reader.string();
                            break;
                        }
                    case 7: {
                            message.finished = reader.string();
                            break;
                        }
                    case 12: {
                            message.deleted = reader.string();
                            break;
                        }
                    case 20: {
                            message.mode = reader.int32();
                            break;
                        }
                    case 14: {
                            message.from = reader.string();
                            break;
                        }
                    case 15: {
                            message.to = reader.string();
                            break;
                        }
                    case 17: {
                            message.heartbeatInterval = reader.int32();
                            break;
                        }
                    case 19: {
                            if (message.settings === $util.emptyObject)
                                message.settings = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.settings[key] = value;
                            break;
                        }
                    case 23: {
                            if (message.optionValues === $util.emptyObject)
                                message.optionValues = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.optionValues[key] = value;
                            break;
                        }
                    case 29: {
                            message.runnerId = reader.string();
                            break;
                        }
                    case 30: {
                            message.interrupted = reader.bool();
                            break;
                        }
                    case 31: {
                            message.access = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TraderInstance message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.TraderInstance} TraderInstance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TraderInstance.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TraderInstance message.
             * @function verify
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TraderInstance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.traderId != null && message.hasOwnProperty("traderId"))
                    if (!$util.isString(message.traderId))
                        return "traderId: string expected";
                if (message.trader != null && message.hasOwnProperty("trader")) {
                    let error = $root.mts.desk.Trader.verify(message.trader);
                    if (error)
                        return "trader." + error;
                }
                if (message.state != null && message.hasOwnProperty("state"))
                    switch (message.state) {
                    default:
                        return "state: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                if (message.portfolioName != null && message.hasOwnProperty("portfolioName"))
                    if (!$util.isString(message.portfolioName))
                        return "portfolioName: string expected";
                if (message.brokerAddr != null && message.hasOwnProperty("brokerAddr"))
                    if (!$util.isString(message.brokerAddr))
                        return "brokerAddr: string expected";
                if (message.brokerAccountId != null && message.hasOwnProperty("brokerAccountId"))
                    if (!$util.isString(message.brokerAccountId))
                        return "brokerAccountId: string expected";
                if (message.deskAddr != null && message.hasOwnProperty("deskAddr"))
                    if (!$util.isString(message.deskAddr))
                        return "deskAddr: string expected";
                if (message.error != null && message.hasOwnProperty("error"))
                    if (!$util.isString(message.error))
                        return "error: string expected";
                if (message.errorTime != null && message.hasOwnProperty("errorTime"))
                    if (!$util.isInteger(message.errorTime) && !(message.errorTime && $util.isInteger(message.errorTime.low) && $util.isInteger(message.errorTime.high)))
                        return "errorTime: integer|Long expected";
                if (message.created != null && message.hasOwnProperty("created"))
                    if (!$util.isString(message.created))
                        return "created: string expected";
                if (message.started != null && message.hasOwnProperty("started"))
                    if (!$util.isString(message.started))
                        return "started: string expected";
                if (message.stopping != null && message.hasOwnProperty("stopping"))
                    if (!$util.isString(message.stopping))
                        return "stopping: string expected";
                if (message.stopped != null && message.hasOwnProperty("stopped"))
                    if (!$util.isString(message.stopped))
                        return "stopped: string expected";
                if (message.paused != null && message.hasOwnProperty("paused"))
                    if (!$util.isString(message.paused))
                        return "paused: string expected";
                if (message.finished != null && message.hasOwnProperty("finished"))
                    if (!$util.isString(message.finished))
                        return "finished: string expected";
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    if (!$util.isString(message.deleted))
                        return "deleted: string expected";
                if (message.mode != null && message.hasOwnProperty("mode"))
                    switch (message.mode) {
                    default:
                        return "mode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!$util.isString(message.from))
                        return "from: string expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isString(message.to))
                        return "to: string expected";
                if (message.heartbeatInterval != null && message.hasOwnProperty("heartbeatInterval"))
                    if (!$util.isInteger(message.heartbeatInterval))
                        return "heartbeatInterval: integer expected";
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    if (!$util.isObject(message.settings))
                        return "settings: object expected";
                    let key = Object.keys(message.settings);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.settings[key[i]]))
                            return "settings: string{k:string} expected";
                }
                if (message.optionValues != null && message.hasOwnProperty("optionValues")) {
                    if (!$util.isObject(message.optionValues))
                        return "optionValues: object expected";
                    let key = Object.keys(message.optionValues);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.optionValues[key[i]]))
                            return "optionValues: string{k:string} expected";
                }
                if (message.runnerId != null && message.hasOwnProperty("runnerId"))
                    if (!$util.isString(message.runnerId))
                        return "runnerId: string expected";
                if (message.interrupted != null && message.hasOwnProperty("interrupted"))
                    if (typeof message.interrupted !== "boolean")
                        return "interrupted: boolean expected";
                if (message.access != null && message.hasOwnProperty("access"))
                    switch (message.access) {
                    default:
                        return "access: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a TraderInstance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.TraderInstance} TraderInstance
             */
            TraderInstance.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.TraderInstance)
                    return object;
                let message = new $root.mts.desk.TraderInstance();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.traderId != null)
                    message.traderId = String(object.traderId);
                if (object.trader != null) {
                    if (typeof object.trader !== "object")
                        throw TypeError(".mts.desk.TraderInstance.trader: object expected");
                    message.trader = $root.mts.desk.Trader.fromObject(object.trader);
                }
                switch (object.state) {
                default:
                    if (typeof object.state === "number") {
                        message.state = object.state;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.state = 0;
                    break;
                case "NEW":
                case 1:
                    message.state = 1;
                    break;
                case "STARTING":
                case 2:
                    message.state = 2;
                    break;
                case "RUNNING":
                case 3:
                    message.state = 3;
                    break;
                case "STOPPING":
                case 4:
                    message.state = 4;
                    break;
                case "STOPPED":
                case 5:
                    message.state = 5;
                    break;
                case "FINISHED":
                case 6:
                    message.state = 6;
                    break;
                case "ERROR":
                case 7:
                    message.state = 7;
                    break;
                case "PAUSED":
                case 8:
                    message.state = 8;
                    break;
                }
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                if (object.portfolioName != null)
                    message.portfolioName = String(object.portfolioName);
                if (object.brokerAddr != null)
                    message.brokerAddr = String(object.brokerAddr);
                if (object.brokerAccountId != null)
                    message.brokerAccountId = String(object.brokerAccountId);
                if (object.deskAddr != null)
                    message.deskAddr = String(object.deskAddr);
                if (object.error != null)
                    message.error = String(object.error);
                if (object.errorTime != null)
                    if ($util.Long)
                        (message.errorTime = $util.Long.fromValue(object.errorTime)).unsigned = false;
                    else if (typeof object.errorTime === "string")
                        message.errorTime = parseInt(object.errorTime, 10);
                    else if (typeof object.errorTime === "number")
                        message.errorTime = object.errorTime;
                    else if (typeof object.errorTime === "object")
                        message.errorTime = new $util.LongBits(object.errorTime.low >>> 0, object.errorTime.high >>> 0).toNumber();
                if (object.created != null)
                    message.created = String(object.created);
                if (object.started != null)
                    message.started = String(object.started);
                if (object.stopping != null)
                    message.stopping = String(object.stopping);
                if (object.stopped != null)
                    message.stopped = String(object.stopped);
                if (object.paused != null)
                    message.paused = String(object.paused);
                if (object.finished != null)
                    message.finished = String(object.finished);
                if (object.deleted != null)
                    message.deleted = String(object.deleted);
                switch (object.mode) {
                default:
                    if (typeof object.mode === "number") {
                        message.mode = object.mode;
                        break;
                    }
                    break;
                case "UNKNOWN_MODE":
                case 0:
                    message.mode = 0;
                    break;
                case "BACKTEST":
                case 1:
                    message.mode = 1;
                    break;
                case "REALTIME":
                case 2:
                    message.mode = 2;
                    break;
                }
                if (object.from != null)
                    message.from = String(object.from);
                if (object.to != null)
                    message.to = String(object.to);
                if (object.heartbeatInterval != null)
                    message.heartbeatInterval = object.heartbeatInterval | 0;
                if (object.settings) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".mts.desk.TraderInstance.settings: object expected");
                    message.settings = {};
                    for (let keys = Object.keys(object.settings), i = 0; i < keys.length; ++i)
                        message.settings[keys[i]] = String(object.settings[keys[i]]);
                }
                if (object.optionValues) {
                    if (typeof object.optionValues !== "object")
                        throw TypeError(".mts.desk.TraderInstance.optionValues: object expected");
                    message.optionValues = {};
                    for (let keys = Object.keys(object.optionValues), i = 0; i < keys.length; ++i)
                        message.optionValues[keys[i]] = String(object.optionValues[keys[i]]);
                }
                if (object.runnerId != null)
                    message.runnerId = String(object.runnerId);
                if (object.interrupted != null)
                    message.interrupted = Boolean(object.interrupted);
                switch (object.access) {
                default:
                    if (typeof object.access === "number") {
                        message.access = object.access;
                        break;
                    }
                    break;
                case "UNKNOWN_ACCESS":
                case 0:
                    message.access = 0;
                    break;
                case "PUBLIC":
                case 1:
                    message.access = 1;
                    break;
                case "PRIVATE":
                case 2:
                    message.access = 2;
                    break;
                case "UNLISTED":
                case 3:
                    message.access = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a TraderInstance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {mts.desk.TraderInstance} message TraderInstance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TraderInstance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults) {
                    object.settings = {};
                    object.optionValues = {};
                }
                if (options.defaults) {
                    object.id = "";
                    object.userId = "";
                    object.trader = null;
                    object.state = options.enums === String ? "UNKNOWN" : 0;
                    object.created = "";
                    object.started = "";
                    object.finished = "";
                    object.portfolioId = "";
                    object.brokerAddr = "";
                    object.brokerAccountId = "";
                    object.deskAddr = "";
                    object.deleted = "";
                    object.from = "";
                    object.to = "";
                    object.portfolioName = "";
                    object.heartbeatInterval = 0;
                    object.mode = options.enums === String ? "UNKNOWN_MODE" : 0;
                    object.error = "";
                    object.traderId = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.errorTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.errorTime = options.longs === String ? "0" : 0;
                    object.stopping = "";
                    object.stopped = "";
                    object.paused = "";
                    object.runnerId = "";
                    object.interrupted = false;
                    object.access = options.enums === String ? "UNKNOWN_ACCESS" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.trader != null && message.hasOwnProperty("trader"))
                    object.trader = $root.mts.desk.Trader.toObject(message.trader, options);
                if (message.state != null && message.hasOwnProperty("state"))
                    object.state = options.enums === String ? $root.mts.desk.TraderInstance.State[message.state] === undefined ? message.state : $root.mts.desk.TraderInstance.State[message.state] : message.state;
                if (message.created != null && message.hasOwnProperty("created"))
                    object.created = message.created;
                if (message.started != null && message.hasOwnProperty("started"))
                    object.started = message.started;
                if (message.finished != null && message.hasOwnProperty("finished"))
                    object.finished = message.finished;
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.brokerAddr != null && message.hasOwnProperty("brokerAddr"))
                    object.brokerAddr = message.brokerAddr;
                if (message.brokerAccountId != null && message.hasOwnProperty("brokerAccountId"))
                    object.brokerAccountId = message.brokerAccountId;
                if (message.deskAddr != null && message.hasOwnProperty("deskAddr"))
                    object.deskAddr = message.deskAddr;
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    object.deleted = message.deleted;
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                if (message.portfolioName != null && message.hasOwnProperty("portfolioName"))
                    object.portfolioName = message.portfolioName;
                if (message.heartbeatInterval != null && message.hasOwnProperty("heartbeatInterval"))
                    object.heartbeatInterval = message.heartbeatInterval;
                let keys2;
                if (message.settings && (keys2 = Object.keys(message.settings)).length) {
                    object.settings = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.settings[keys2[j]] = message.settings[keys2[j]];
                }
                if (message.mode != null && message.hasOwnProperty("mode"))
                    object.mode = options.enums === String ? $root.mts.desk.TraderInstance.Mode[message.mode] === undefined ? message.mode : $root.mts.desk.TraderInstance.Mode[message.mode] : message.mode;
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = message.error;
                if (message.traderId != null && message.hasOwnProperty("traderId"))
                    object.traderId = message.traderId;
                if (message.optionValues && (keys2 = Object.keys(message.optionValues)).length) {
                    object.optionValues = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.optionValues[keys2[j]] = message.optionValues[keys2[j]];
                }
                if (message.errorTime != null && message.hasOwnProperty("errorTime"))
                    if (typeof message.errorTime === "number")
                        object.errorTime = options.longs === String ? String(message.errorTime) : message.errorTime;
                    else
                        object.errorTime = options.longs === String ? $util.Long.prototype.toString.call(message.errorTime) : options.longs === Number ? new $util.LongBits(message.errorTime.low >>> 0, message.errorTime.high >>> 0).toNumber() : message.errorTime;
                if (message.stopping != null && message.hasOwnProperty("stopping"))
                    object.stopping = message.stopping;
                if (message.stopped != null && message.hasOwnProperty("stopped"))
                    object.stopped = message.stopped;
                if (message.paused != null && message.hasOwnProperty("paused"))
                    object.paused = message.paused;
                if (message.runnerId != null && message.hasOwnProperty("runnerId"))
                    object.runnerId = message.runnerId;
                if (message.interrupted != null && message.hasOwnProperty("interrupted"))
                    object.interrupted = message.interrupted;
                if (message.access != null && message.hasOwnProperty("access"))
                    object.access = options.enums === String ? $root.mts.desk.Access[message.access] === undefined ? message.access : $root.mts.desk.Access[message.access] : message.access;
                return object;
            };

            /**
             * Converts this TraderInstance to JSON.
             * @function toJSON
             * @memberof mts.desk.TraderInstance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TraderInstance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TraderInstance
             * @function getTypeUrl
             * @memberof mts.desk.TraderInstance
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TraderInstance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.TraderInstance";
            };

            /**
             * Mode enum.
             * @name mts.desk.TraderInstance.Mode
             * @enum {number}
             * @property {number} UNKNOWN_MODE=0 UNKNOWN_MODE value
             * @property {number} BACKTEST=1 BACKTEST value
             * @property {number} REALTIME=2 REALTIME value
             */
            TraderInstance.Mode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_MODE"] = 0;
                values[valuesById[1] = "BACKTEST"] = 1;
                values[valuesById[2] = "REALTIME"] = 2;
                return values;
            })();

            /**
             * State enum.
             * @name mts.desk.TraderInstance.State
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} NEW=1 NEW value
             * @property {number} STARTING=2 STARTING value
             * @property {number} RUNNING=3 RUNNING value
             * @property {number} STOPPING=4 STOPPING value
             * @property {number} STOPPED=5 STOPPED value
             * @property {number} FINISHED=6 FINISHED value
             * @property {number} ERROR=7 ERROR value
             * @property {number} PAUSED=8 PAUSED value
             */
            TraderInstance.State = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "NEW"] = 1;
                values[valuesById[2] = "STARTING"] = 2;
                values[valuesById[3] = "RUNNING"] = 3;
                values[valuesById[4] = "STOPPING"] = 4;
                values[valuesById[5] = "STOPPED"] = 5;
                values[valuesById[6] = "FINISHED"] = 6;
                values[valuesById[7] = "ERROR"] = 7;
                values[valuesById[8] = "PAUSED"] = 8;
                return values;
            })();

            return TraderInstance;
        })();

        desk.File = (function() {

            /**
             * Properties of a File.
             * @memberof mts.desk
             * @interface IFile
             * @property {string|null} [name] File name
             * @property {string|null} [contentType] File contentType
             * @property {Uint8Array|null} [content] File content
             */

            /**
             * Constructs a new File.
             * @memberof mts.desk
             * @classdesc Represents a File.
             * @implements IFile
             * @constructor
             * @param {mts.desk.IFile=} [properties] Properties to set
             */
            function File(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * File name.
             * @member {string} name
             * @memberof mts.desk.File
             * @instance
             */
            File.prototype.name = "";

            /**
             * File contentType.
             * @member {string} contentType
             * @memberof mts.desk.File
             * @instance
             */
            File.prototype.contentType = "";

            /**
             * File content.
             * @member {Uint8Array} content
             * @memberof mts.desk.File
             * @instance
             */
            File.prototype.content = $util.newBuffer([]);

            /**
             * Creates a new File instance using the specified properties.
             * @function create
             * @memberof mts.desk.File
             * @static
             * @param {mts.desk.IFile=} [properties] Properties to set
             * @returns {mts.desk.File} File instance
             */
            File.create = function create(properties) {
                return new File(properties);
            };

            /**
             * Encodes the specified File message. Does not implicitly {@link mts.desk.File.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.File
             * @static
             * @param {mts.desk.IFile} message File message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            File.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.contentType);
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.content);
                return writer;
            };

            /**
             * Encodes the specified File message, length delimited. Does not implicitly {@link mts.desk.File.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.File
             * @static
             * @param {mts.desk.IFile} message File message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            File.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a File message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.File
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.File} File
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            File.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.File();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.contentType = reader.string();
                            break;
                        }
                    case 3: {
                            message.content = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a File message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.File
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.File} File
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            File.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a File message.
             * @function verify
             * @memberof mts.desk.File
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            File.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.contentType != null && message.hasOwnProperty("contentType"))
                    if (!$util.isString(message.contentType))
                        return "contentType: string expected";
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!(message.content && typeof message.content.length === "number" || $util.isString(message.content)))
                        return "content: buffer expected";
                return null;
            };

            /**
             * Creates a File message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.File
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.File} File
             */
            File.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.File)
                    return object;
                let message = new $root.mts.desk.File();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.contentType != null)
                    message.contentType = String(object.contentType);
                if (object.content != null)
                    if (typeof object.content === "string")
                        $util.base64.decode(object.content, message.content = $util.newBuffer($util.base64.length(object.content)), 0);
                    else if (object.content.length >= 0)
                        message.content = object.content;
                return message;
            };

            /**
             * Creates a plain object from a File message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.File
             * @static
             * @param {mts.desk.File} message File
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            File.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.contentType = "";
                    if (options.bytes === String)
                        object.content = "";
                    else {
                        object.content = [];
                        if (options.bytes !== Array)
                            object.content = $util.newBuffer(object.content);
                    }
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.contentType != null && message.hasOwnProperty("contentType"))
                    object.contentType = message.contentType;
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = options.bytes === String ? $util.base64.encode(message.content, 0, message.content.length) : options.bytes === Array ? Array.prototype.slice.call(message.content) : message.content;
                return object;
            };

            /**
             * Converts this File to JSON.
             * @function toJSON
             * @memberof mts.desk.File
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            File.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for File
             * @function getTypeUrl
             * @memberof mts.desk.File
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            File.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.File";
            };

            return File;
        })();

        desk.NotificationSetting = (function() {

            /**
             * Properties of a NotificationSetting.
             * @memberof mts.desk
             * @interface INotificationSetting
             * @property {string|null} [notificationType] NotificationSetting notificationType
             * @property {mts.desk.NotificationSetting.NotificationMethod|null} [notificationMethod] NotificationSetting notificationMethod
             */

            /**
             * Constructs a new NotificationSetting.
             * @memberof mts.desk
             * @classdesc Represents a NotificationSetting.
             * @implements INotificationSetting
             * @constructor
             * @param {mts.desk.INotificationSetting=} [properties] Properties to set
             */
            function NotificationSetting(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NotificationSetting notificationType.
             * @member {string} notificationType
             * @memberof mts.desk.NotificationSetting
             * @instance
             */
            NotificationSetting.prototype.notificationType = "";

            /**
             * NotificationSetting notificationMethod.
             * @member {mts.desk.NotificationSetting.NotificationMethod} notificationMethod
             * @memberof mts.desk.NotificationSetting
             * @instance
             */
            NotificationSetting.prototype.notificationMethod = 0;

            /**
             * Creates a new NotificationSetting instance using the specified properties.
             * @function create
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {mts.desk.INotificationSetting=} [properties] Properties to set
             * @returns {mts.desk.NotificationSetting} NotificationSetting instance
             */
            NotificationSetting.create = function create(properties) {
                return new NotificationSetting(properties);
            };

            /**
             * Encodes the specified NotificationSetting message. Does not implicitly {@link mts.desk.NotificationSetting.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {mts.desk.INotificationSetting} message NotificationSetting message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NotificationSetting.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.notificationType != null && Object.hasOwnProperty.call(message, "notificationType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.notificationType);
                if (message.notificationMethod != null && Object.hasOwnProperty.call(message, "notificationMethod"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.notificationMethod);
                return writer;
            };

            /**
             * Encodes the specified NotificationSetting message, length delimited. Does not implicitly {@link mts.desk.NotificationSetting.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {mts.desk.INotificationSetting} message NotificationSetting message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NotificationSetting.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NotificationSetting message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.NotificationSetting} NotificationSetting
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NotificationSetting.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.NotificationSetting();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            message.notificationType = reader.string();
                            break;
                        }
                    case 4: {
                            message.notificationMethod = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NotificationSetting message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.NotificationSetting} NotificationSetting
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NotificationSetting.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NotificationSetting message.
             * @function verify
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NotificationSetting.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                    if (!$util.isString(message.notificationType))
                        return "notificationType: string expected";
                if (message.notificationMethod != null && message.hasOwnProperty("notificationMethod"))
                    switch (message.notificationMethod) {
                    default:
                        return "notificationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a NotificationSetting message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.NotificationSetting} NotificationSetting
             */
            NotificationSetting.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.NotificationSetting)
                    return object;
                let message = new $root.mts.desk.NotificationSetting();
                if (object.notificationType != null)
                    message.notificationType = String(object.notificationType);
                switch (object.notificationMethod) {
                default:
                    if (typeof object.notificationMethod === "number") {
                        message.notificationMethod = object.notificationMethod;
                        break;
                    }
                    break;
                case "UNKNOWN_METHOD":
                case 0:
                    message.notificationMethod = 0;
                    break;
                case "EMAIL":
                case 1:
                    message.notificationMethod = 1;
                    break;
                case "BROWSER":
                case 2:
                    message.notificationMethod = 2;
                    break;
                case "WEBHOOK":
                case 3:
                    message.notificationMethod = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a NotificationSetting message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {mts.desk.NotificationSetting} message NotificationSetting
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NotificationSetting.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.notificationType = "";
                    object.notificationMethod = options.enums === String ? "UNKNOWN_METHOD" : 0;
                }
                if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                    object.notificationType = message.notificationType;
                if (message.notificationMethod != null && message.hasOwnProperty("notificationMethod"))
                    object.notificationMethod = options.enums === String ? $root.mts.desk.NotificationSetting.NotificationMethod[message.notificationMethod] === undefined ? message.notificationMethod : $root.mts.desk.NotificationSetting.NotificationMethod[message.notificationMethod] : message.notificationMethod;
                return object;
            };

            /**
             * Converts this NotificationSetting to JSON.
             * @function toJSON
             * @memberof mts.desk.NotificationSetting
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NotificationSetting.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NotificationSetting
             * @function getTypeUrl
             * @memberof mts.desk.NotificationSetting
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NotificationSetting.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.NotificationSetting";
            };

            /**
             * NotificationMethod enum.
             * @name mts.desk.NotificationSetting.NotificationMethod
             * @enum {number}
             * @property {number} UNKNOWN_METHOD=0 UNKNOWN_METHOD value
             * @property {number} EMAIL=1 EMAIL value
             * @property {number} BROWSER=2 BROWSER value
             * @property {number} WEBHOOK=3 WEBHOOK value
             */
            NotificationSetting.NotificationMethod = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_METHOD"] = 0;
                values[valuesById[1] = "EMAIL"] = 1;
                values[valuesById[2] = "BROWSER"] = 2;
                values[valuesById[3] = "WEBHOOK"] = 3;
                return values;
            })();

            return NotificationSetting;
        })();

        desk.UserInstanceFollow = (function() {

            /**
             * Properties of a UserInstanceFollow.
             * @memberof mts.desk
             * @interface IUserInstanceFollow
             * @property {string|null} [id] UserInstanceFollow id
             * @property {string|null} [instanceId] UserInstanceFollow instanceId
             * @property {string|null} [userId] UserInstanceFollow userId
             * @property {Array.<mts.desk.INotificationSetting>|null} [watchlistNotificationSettings] UserInstanceFollow watchlistNotificationSettings
             * @property {Array.<mts.desk.INotificationSetting>|null} [orderNotifications] UserInstanceFollow orderNotifications
             */

            /**
             * Constructs a new UserInstanceFollow.
             * @memberof mts.desk
             * @classdesc A User instance to follow. This opens up the ability to subscribe & notify to an instance.
             * But "follow" is used because it's a very low commitment.
             * @implements IUserInstanceFollow
             * @constructor
             * @param {mts.desk.IUserInstanceFollow=} [properties] Properties to set
             */
            function UserInstanceFollow(properties) {
                this.watchlistNotificationSettings = [];
                this.orderNotifications = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserInstanceFollow id.
             * @member {string} id
             * @memberof mts.desk.UserInstanceFollow
             * @instance
             */
            UserInstanceFollow.prototype.id = "";

            /**
             * UserInstanceFollow instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.UserInstanceFollow
             * @instance
             */
            UserInstanceFollow.prototype.instanceId = "";

            /**
             * UserInstanceFollow userId.
             * @member {string} userId
             * @memberof mts.desk.UserInstanceFollow
             * @instance
             */
            UserInstanceFollow.prototype.userId = "";

            /**
             * UserInstanceFollow watchlistNotificationSettings.
             * @member {Array.<mts.desk.INotificationSetting>} watchlistNotificationSettings
             * @memberof mts.desk.UserInstanceFollow
             * @instance
             */
            UserInstanceFollow.prototype.watchlistNotificationSettings = $util.emptyArray;

            /**
             * UserInstanceFollow orderNotifications.
             * @member {Array.<mts.desk.INotificationSetting>} orderNotifications
             * @memberof mts.desk.UserInstanceFollow
             * @instance
             */
            UserInstanceFollow.prototype.orderNotifications = $util.emptyArray;

            /**
             * Creates a new UserInstanceFollow instance using the specified properties.
             * @function create
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {mts.desk.IUserInstanceFollow=} [properties] Properties to set
             * @returns {mts.desk.UserInstanceFollow} UserInstanceFollow instance
             */
            UserInstanceFollow.create = function create(properties) {
                return new UserInstanceFollow(properties);
            };

            /**
             * Encodes the specified UserInstanceFollow message. Does not implicitly {@link mts.desk.UserInstanceFollow.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {mts.desk.IUserInstanceFollow} message UserInstanceFollow message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserInstanceFollow.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.instanceId);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
                if (message.watchlistNotificationSettings != null && message.watchlistNotificationSettings.length)
                    for (let i = 0; i < message.watchlistNotificationSettings.length; ++i)
                        $root.mts.desk.NotificationSetting.encode(message.watchlistNotificationSettings[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.orderNotifications != null && message.orderNotifications.length)
                    for (let i = 0; i < message.orderNotifications.length; ++i)
                        $root.mts.desk.NotificationSetting.encode(message.orderNotifications[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UserInstanceFollow message, length delimited. Does not implicitly {@link mts.desk.UserInstanceFollow.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {mts.desk.IUserInstanceFollow} message UserInstanceFollow message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserInstanceFollow.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserInstanceFollow message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.UserInstanceFollow} UserInstanceFollow
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserInstanceFollow.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.UserInstanceFollow();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.instanceId = reader.string();
                            break;
                        }
                    case 3: {
                            message.userId = reader.string();
                            break;
                        }
                    case 10: {
                            if (!(message.watchlistNotificationSettings && message.watchlistNotificationSettings.length))
                                message.watchlistNotificationSettings = [];
                            message.watchlistNotificationSettings.push($root.mts.desk.NotificationSetting.decode(reader, reader.uint32()));
                            break;
                        }
                    case 11: {
                            if (!(message.orderNotifications && message.orderNotifications.length))
                                message.orderNotifications = [];
                            message.orderNotifications.push($root.mts.desk.NotificationSetting.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserInstanceFollow message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.UserInstanceFollow} UserInstanceFollow
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserInstanceFollow.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserInstanceFollow message.
             * @function verify
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserInstanceFollow.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.watchlistNotificationSettings != null && message.hasOwnProperty("watchlistNotificationSettings")) {
                    if (!Array.isArray(message.watchlistNotificationSettings))
                        return "watchlistNotificationSettings: array expected";
                    for (let i = 0; i < message.watchlistNotificationSettings.length; ++i) {
                        let error = $root.mts.desk.NotificationSetting.verify(message.watchlistNotificationSettings[i]);
                        if (error)
                            return "watchlistNotificationSettings." + error;
                    }
                }
                if (message.orderNotifications != null && message.hasOwnProperty("orderNotifications")) {
                    if (!Array.isArray(message.orderNotifications))
                        return "orderNotifications: array expected";
                    for (let i = 0; i < message.orderNotifications.length; ++i) {
                        let error = $root.mts.desk.NotificationSetting.verify(message.orderNotifications[i]);
                        if (error)
                            return "orderNotifications." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a UserInstanceFollow message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.UserInstanceFollow} UserInstanceFollow
             */
            UserInstanceFollow.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.UserInstanceFollow)
                    return object;
                let message = new $root.mts.desk.UserInstanceFollow();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.watchlistNotificationSettings) {
                    if (!Array.isArray(object.watchlistNotificationSettings))
                        throw TypeError(".mts.desk.UserInstanceFollow.watchlistNotificationSettings: array expected");
                    message.watchlistNotificationSettings = [];
                    for (let i = 0; i < object.watchlistNotificationSettings.length; ++i) {
                        if (typeof object.watchlistNotificationSettings[i] !== "object")
                            throw TypeError(".mts.desk.UserInstanceFollow.watchlistNotificationSettings: object expected");
                        message.watchlistNotificationSettings[i] = $root.mts.desk.NotificationSetting.fromObject(object.watchlistNotificationSettings[i]);
                    }
                }
                if (object.orderNotifications) {
                    if (!Array.isArray(object.orderNotifications))
                        throw TypeError(".mts.desk.UserInstanceFollow.orderNotifications: array expected");
                    message.orderNotifications = [];
                    for (let i = 0; i < object.orderNotifications.length; ++i) {
                        if (typeof object.orderNotifications[i] !== "object")
                            throw TypeError(".mts.desk.UserInstanceFollow.orderNotifications: object expected");
                        message.orderNotifications[i] = $root.mts.desk.NotificationSetting.fromObject(object.orderNotifications[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a UserInstanceFollow message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {mts.desk.UserInstanceFollow} message UserInstanceFollow
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserInstanceFollow.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.watchlistNotificationSettings = [];
                    object.orderNotifications = [];
                }
                if (options.defaults) {
                    object.id = "";
                    object.instanceId = "";
                    object.userId = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.watchlistNotificationSettings && message.watchlistNotificationSettings.length) {
                    object.watchlistNotificationSettings = [];
                    for (let j = 0; j < message.watchlistNotificationSettings.length; ++j)
                        object.watchlistNotificationSettings[j] = $root.mts.desk.NotificationSetting.toObject(message.watchlistNotificationSettings[j], options);
                }
                if (message.orderNotifications && message.orderNotifications.length) {
                    object.orderNotifications = [];
                    for (let j = 0; j < message.orderNotifications.length; ++j)
                        object.orderNotifications[j] = $root.mts.desk.NotificationSetting.toObject(message.orderNotifications[j], options);
                }
                return object;
            };

            /**
             * Converts this UserInstanceFollow to JSON.
             * @function toJSON
             * @memberof mts.desk.UserInstanceFollow
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserInstanceFollow.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserInstanceFollow
             * @function getTypeUrl
             * @memberof mts.desk.UserInstanceFollow
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserInstanceFollow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.UserInstanceFollow";
            };

            return UserInstanceFollow;
        })();

        desk.UserInstanceNotificationSetting = (function() {

            /**
             * Properties of a UserInstanceNotificationSetting.
             * @memberof mts.desk
             * @interface IUserInstanceNotificationSetting
             * @property {string|null} [id] UserInstanceNotificationSetting id
             * @property {string|null} [userId] UserInstanceNotificationSetting userId
             * @property {string|null} [instanceId] UserInstanceNotificationSetting instanceId
             * @property {mts.desk.INotificationSetting|null} [notificationSetting] UserInstanceNotificationSetting notificationSetting
             * @property {mts.desk.UserInstanceNotificationSetting.NotificationType|null} [notificationType] UserInstanceNotificationSetting notificationType
             */

            /**
             * Constructs a new UserInstanceNotificationSetting.
             * @memberof mts.desk
             * @classdesc Represents a UserInstanceNotificationSetting.
             * @implements IUserInstanceNotificationSetting
             * @constructor
             * @param {mts.desk.IUserInstanceNotificationSetting=} [properties] Properties to set
             */
            function UserInstanceNotificationSetting(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserInstanceNotificationSetting id.
             * @member {string} id
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @instance
             */
            UserInstanceNotificationSetting.prototype.id = "";

            /**
             * UserInstanceNotificationSetting userId.
             * @member {string} userId
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @instance
             */
            UserInstanceNotificationSetting.prototype.userId = "";

            /**
             * UserInstanceNotificationSetting instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @instance
             */
            UserInstanceNotificationSetting.prototype.instanceId = "";

            /**
             * UserInstanceNotificationSetting notificationSetting.
             * @member {mts.desk.INotificationSetting|null|undefined} notificationSetting
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @instance
             */
            UserInstanceNotificationSetting.prototype.notificationSetting = null;

            /**
             * UserInstanceNotificationSetting notificationType.
             * @member {mts.desk.UserInstanceNotificationSetting.NotificationType} notificationType
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @instance
             */
            UserInstanceNotificationSetting.prototype.notificationType = 0;

            /**
             * Creates a new UserInstanceNotificationSetting instance using the specified properties.
             * @function create
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {mts.desk.IUserInstanceNotificationSetting=} [properties] Properties to set
             * @returns {mts.desk.UserInstanceNotificationSetting} UserInstanceNotificationSetting instance
             */
            UserInstanceNotificationSetting.create = function create(properties) {
                return new UserInstanceNotificationSetting(properties);
            };

            /**
             * Encodes the specified UserInstanceNotificationSetting message. Does not implicitly {@link mts.desk.UserInstanceNotificationSetting.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {mts.desk.IUserInstanceNotificationSetting} message UserInstanceNotificationSetting message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserInstanceNotificationSetting.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.instanceId);
                if (message.notificationSetting != null && Object.hasOwnProperty.call(message, "notificationSetting"))
                    $root.mts.desk.NotificationSetting.encode(message.notificationSetting, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.notificationType != null && Object.hasOwnProperty.call(message, "notificationType"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.notificationType);
                return writer;
            };

            /**
             * Encodes the specified UserInstanceNotificationSetting message, length delimited. Does not implicitly {@link mts.desk.UserInstanceNotificationSetting.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {mts.desk.IUserInstanceNotificationSetting} message UserInstanceNotificationSetting message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserInstanceNotificationSetting.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserInstanceNotificationSetting message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.UserInstanceNotificationSetting} UserInstanceNotificationSetting
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserInstanceNotificationSetting.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.UserInstanceNotificationSetting();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.userId = reader.string();
                            break;
                        }
                    case 3: {
                            message.instanceId = reader.string();
                            break;
                        }
                    case 4: {
                            message.notificationSetting = $root.mts.desk.NotificationSetting.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.notificationType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserInstanceNotificationSetting message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.UserInstanceNotificationSetting} UserInstanceNotificationSetting
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserInstanceNotificationSetting.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserInstanceNotificationSetting message.
             * @function verify
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserInstanceNotificationSetting.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                if (message.notificationSetting != null && message.hasOwnProperty("notificationSetting")) {
                    let error = $root.mts.desk.NotificationSetting.verify(message.notificationSetting);
                    if (error)
                        return "notificationSetting." + error;
                }
                if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                    switch (message.notificationType) {
                    default:
                        return "notificationType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a UserInstanceNotificationSetting message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.UserInstanceNotificationSetting} UserInstanceNotificationSetting
             */
            UserInstanceNotificationSetting.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.UserInstanceNotificationSetting)
                    return object;
                let message = new $root.mts.desk.UserInstanceNotificationSetting();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                if (object.notificationSetting != null) {
                    if (typeof object.notificationSetting !== "object")
                        throw TypeError(".mts.desk.UserInstanceNotificationSetting.notificationSetting: object expected");
                    message.notificationSetting = $root.mts.desk.NotificationSetting.fromObject(object.notificationSetting);
                }
                switch (object.notificationType) {
                default:
                    if (typeof object.notificationType === "number") {
                        message.notificationType = object.notificationType;
                        break;
                    }
                    break;
                case "UNKNOWN_TYPE":
                case 0:
                    message.notificationType = 0;
                    break;
                case "WATCHLIST_CREATED":
                case 1:
                    message.notificationType = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a UserInstanceNotificationSetting message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {mts.desk.UserInstanceNotificationSetting} message UserInstanceNotificationSetting
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserInstanceNotificationSetting.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = "";
                    object.userId = "";
                    object.instanceId = "";
                    object.notificationSetting = null;
                    object.notificationType = options.enums === String ? "UNKNOWN_TYPE" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.notificationSetting != null && message.hasOwnProperty("notificationSetting"))
                    object.notificationSetting = $root.mts.desk.NotificationSetting.toObject(message.notificationSetting, options);
                if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                    object.notificationType = options.enums === String ? $root.mts.desk.UserInstanceNotificationSetting.NotificationType[message.notificationType] === undefined ? message.notificationType : $root.mts.desk.UserInstanceNotificationSetting.NotificationType[message.notificationType] : message.notificationType;
                return object;
            };

            /**
             * Converts this UserInstanceNotificationSetting to JSON.
             * @function toJSON
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserInstanceNotificationSetting.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserInstanceNotificationSetting
             * @function getTypeUrl
             * @memberof mts.desk.UserInstanceNotificationSetting
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserInstanceNotificationSetting.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.UserInstanceNotificationSetting";
            };

            /**
             * NotificationType enum.
             * @name mts.desk.UserInstanceNotificationSetting.NotificationType
             * @enum {number}
             * @property {number} UNKNOWN_TYPE=0 UNKNOWN_TYPE value
             * @property {number} WATCHLIST_CREATED=1 WATCHLIST_CREATED value
             */
            UserInstanceNotificationSetting.NotificationType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_TYPE"] = 0;
                values[valuesById[1] = "WATCHLIST_CREATED"] = 1;
                return values;
            })();

            return UserInstanceNotificationSetting;
        })();

        desk.Watchlist = (function() {

            /**
             * Properties of a Watchlist.
             * @memberof mts.desk
             * @interface IWatchlist
             * @property {string|null} [id] Watchlist id
             * @property {string|null} [instanceId] Watchlist instanceId
             * @property {string|null} [name] Watchlist name
             * @property {mts.desk.ITick|null} [tick] Watchlist tick
             * @property {Array.<mts.desk.Watchlist.ISymbol>|null} [symbols] Watchlist symbols
             */

            /**
             * Constructs a new Watchlist.
             * @memberof mts.desk
             * @classdesc A list of symbols. Because it's so common this has it's own storage unique to watchlists.
             * @implements IWatchlist
             * @constructor
             * @param {mts.desk.IWatchlist=} [properties] Properties to set
             */
            function Watchlist(properties) {
                this.symbols = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Watchlist id.
             * @member {string} id
             * @memberof mts.desk.Watchlist
             * @instance
             */
            Watchlist.prototype.id = "";

            /**
             * Watchlist instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.Watchlist
             * @instance
             */
            Watchlist.prototype.instanceId = "";

            /**
             * Watchlist name.
             * @member {string} name
             * @memberof mts.desk.Watchlist
             * @instance
             */
            Watchlist.prototype.name = "";

            /**
             * Watchlist tick.
             * @member {mts.desk.ITick|null|undefined} tick
             * @memberof mts.desk.Watchlist
             * @instance
             */
            Watchlist.prototype.tick = null;

            /**
             * Watchlist symbols.
             * @member {Array.<mts.desk.Watchlist.ISymbol>} symbols
             * @memberof mts.desk.Watchlist
             * @instance
             */
            Watchlist.prototype.symbols = $util.emptyArray;

            /**
             * Creates a new Watchlist instance using the specified properties.
             * @function create
             * @memberof mts.desk.Watchlist
             * @static
             * @param {mts.desk.IWatchlist=} [properties] Properties to set
             * @returns {mts.desk.Watchlist} Watchlist instance
             */
            Watchlist.create = function create(properties) {
                return new Watchlist(properties);
            };

            /**
             * Encodes the specified Watchlist message. Does not implicitly {@link mts.desk.Watchlist.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.Watchlist
             * @static
             * @param {mts.desk.IWatchlist} message Watchlist message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Watchlist.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.instanceId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.tick != null && Object.hasOwnProperty.call(message, "tick"))
                    $root.mts.desk.Tick.encode(message.tick, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.symbols != null && message.symbols.length)
                    for (let i = 0; i < message.symbols.length; ++i)
                        $root.mts.desk.Watchlist.Symbol.encode(message.symbols[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Watchlist message, length delimited. Does not implicitly {@link mts.desk.Watchlist.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.Watchlist
             * @static
             * @param {mts.desk.IWatchlist} message Watchlist message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Watchlist.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Watchlist message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.Watchlist
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.Watchlist} Watchlist
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Watchlist.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.Watchlist();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.instanceId = reader.string();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.tick = $root.mts.desk.Tick.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            if (!(message.symbols && message.symbols.length))
                                message.symbols = [];
                            message.symbols.push($root.mts.desk.Watchlist.Symbol.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Watchlist message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.Watchlist
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.Watchlist} Watchlist
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Watchlist.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Watchlist message.
             * @function verify
             * @memberof mts.desk.Watchlist
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Watchlist.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.tick != null && message.hasOwnProperty("tick")) {
                    let error = $root.mts.desk.Tick.verify(message.tick);
                    if (error)
                        return "tick." + error;
                }
                if (message.symbols != null && message.hasOwnProperty("symbols")) {
                    if (!Array.isArray(message.symbols))
                        return "symbols: array expected";
                    for (let i = 0; i < message.symbols.length; ++i) {
                        let error = $root.mts.desk.Watchlist.Symbol.verify(message.symbols[i]);
                        if (error)
                            return "symbols." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Watchlist message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.Watchlist
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.Watchlist} Watchlist
             */
            Watchlist.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.Watchlist)
                    return object;
                let message = new $root.mts.desk.Watchlist();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.tick != null) {
                    if (typeof object.tick !== "object")
                        throw TypeError(".mts.desk.Watchlist.tick: object expected");
                    message.tick = $root.mts.desk.Tick.fromObject(object.tick);
                }
                if (object.symbols) {
                    if (!Array.isArray(object.symbols))
                        throw TypeError(".mts.desk.Watchlist.symbols: array expected");
                    message.symbols = [];
                    for (let i = 0; i < object.symbols.length; ++i) {
                        if (typeof object.symbols[i] !== "object")
                            throw TypeError(".mts.desk.Watchlist.symbols: object expected");
                        message.symbols[i] = $root.mts.desk.Watchlist.Symbol.fromObject(object.symbols[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Watchlist message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.Watchlist
             * @static
             * @param {mts.desk.Watchlist} message Watchlist
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Watchlist.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.symbols = [];
                if (options.defaults) {
                    object.id = "";
                    object.instanceId = "";
                    object.name = "";
                    object.tick = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.tick != null && message.hasOwnProperty("tick"))
                    object.tick = $root.mts.desk.Tick.toObject(message.tick, options);
                if (message.symbols && message.symbols.length) {
                    object.symbols = [];
                    for (let j = 0; j < message.symbols.length; ++j)
                        object.symbols[j] = $root.mts.desk.Watchlist.Symbol.toObject(message.symbols[j], options);
                }
                return object;
            };

            /**
             * Converts this Watchlist to JSON.
             * @function toJSON
             * @memberof mts.desk.Watchlist
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Watchlist.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Watchlist
             * @function getTypeUrl
             * @memberof mts.desk.Watchlist
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Watchlist.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.Watchlist";
            };

            Watchlist.Symbol = (function() {

                /**
                 * Properties of a Symbol.
                 * @memberof mts.desk.Watchlist
                 * @interface ISymbol
                 * @property {string|null} [name] Symbol name
                 * @property {string|null} [comment] Symbol comment
                 * @property {Object.<string,string>|null} [data] Symbol data
                 */

                /**
                 * Constructs a new Symbol.
                 * @memberof mts.desk.Watchlist
                 * @classdesc Represents a Symbol.
                 * @implements ISymbol
                 * @constructor
                 * @param {mts.desk.Watchlist.ISymbol=} [properties] Properties to set
                 */
                function Symbol(properties) {
                    this.data = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Symbol name.
                 * @member {string} name
                 * @memberof mts.desk.Watchlist.Symbol
                 * @instance
                 */
                Symbol.prototype.name = "";

                /**
                 * Symbol comment.
                 * @member {string} comment
                 * @memberof mts.desk.Watchlist.Symbol
                 * @instance
                 */
                Symbol.prototype.comment = "";

                /**
                 * Symbol data.
                 * @member {Object.<string,string>} data
                 * @memberof mts.desk.Watchlist.Symbol
                 * @instance
                 */
                Symbol.prototype.data = $util.emptyObject;

                /**
                 * Creates a new Symbol instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {mts.desk.Watchlist.ISymbol=} [properties] Properties to set
                 * @returns {mts.desk.Watchlist.Symbol} Symbol instance
                 */
                Symbol.create = function create(properties) {
                    return new Symbol(properties);
                };

                /**
                 * Encodes the specified Symbol message. Does not implicitly {@link mts.desk.Watchlist.Symbol.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {mts.desk.Watchlist.ISymbol} message Symbol message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Symbol.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.comment);
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        for (let keys = Object.keys(message.data), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.data[keys[i]]).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Symbol message, length delimited. Does not implicitly {@link mts.desk.Watchlist.Symbol.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {mts.desk.Watchlist.ISymbol} message Symbol message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Symbol.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Symbol message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.Watchlist.Symbol} Symbol
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Symbol.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.Watchlist.Symbol(), key, value;
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.comment = reader.string();
                                break;
                            }
                        case 3: {
                                if (message.data === $util.emptyObject)
                                    message.data = {};
                                let end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    let tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.data[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Symbol message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.Watchlist.Symbol} Symbol
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Symbol.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Symbol message.
                 * @function verify
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Symbol.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!$util.isObject(message.data))
                            return "data: object expected";
                        let key = Object.keys(message.data);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.data[key[i]]))
                                return "data: string{k:string} expected";
                    }
                    return null;
                };

                /**
                 * Creates a Symbol message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.Watchlist.Symbol} Symbol
                 */
                Symbol.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.Watchlist.Symbol)
                        return object;
                    let message = new $root.mts.desk.Watchlist.Symbol();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.data) {
                        if (typeof object.data !== "object")
                            throw TypeError(".mts.desk.Watchlist.Symbol.data: object expected");
                        message.data = {};
                        for (let keys = Object.keys(object.data), i = 0; i < keys.length; ++i)
                            message.data[keys[i]] = String(object.data[keys[i]]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Symbol message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {mts.desk.Watchlist.Symbol} message Symbol
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Symbol.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.data = {};
                    if (options.defaults) {
                        object.name = "";
                        object.comment = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    let keys2;
                    if (message.data && (keys2 = Object.keys(message.data)).length) {
                        object.data = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.data[keys2[j]] = message.data[keys2[j]];
                    }
                    return object;
                };

                /**
                 * Converts this Symbol to JSON.
                 * @function toJSON
                 * @memberof mts.desk.Watchlist.Symbol
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Symbol.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Symbol
                 * @function getTypeUrl
                 * @memberof mts.desk.Watchlist.Symbol
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Symbol.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.Watchlist.Symbol";
                };

                return Symbol;
            })();

            return Watchlist;
        })();

        desk.TraderInstanceOutput = (function() {

            /**
             * Properties of a TraderInstanceOutput.
             * @memberof mts.desk
             * @interface ITraderInstanceOutput
             * @property {string|null} [instanceId] TraderInstanceOutput instanceId
             * @property {number|Long|null} [time] TraderInstanceOutput time
             * @property {string|null} [date] TraderInstanceOutput date
             * @property {Array.<mts.desk.TraderInstanceOutput.ILogEntry>|null} [logs] TraderInstanceOutput logs
             */

            /**
             * Constructs a new TraderInstanceOutput.
             * @memberof mts.desk
             * @classdesc Represents a TraderInstanceOutput.
             * @implements ITraderInstanceOutput
             * @constructor
             * @param {mts.desk.ITraderInstanceOutput=} [properties] Properties to set
             */
            function TraderInstanceOutput(properties) {
                this.logs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TraderInstanceOutput instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.TraderInstanceOutput
             * @instance
             */
            TraderInstanceOutput.prototype.instanceId = "";

            /**
             * TraderInstanceOutput time.
             * @member {number|Long} time
             * @memberof mts.desk.TraderInstanceOutput
             * @instance
             */
            TraderInstanceOutput.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TraderInstanceOutput date.
             * @member {string} date
             * @memberof mts.desk.TraderInstanceOutput
             * @instance
             */
            TraderInstanceOutput.prototype.date = "";

            /**
             * TraderInstanceOutput logs.
             * @member {Array.<mts.desk.TraderInstanceOutput.ILogEntry>} logs
             * @memberof mts.desk.TraderInstanceOutput
             * @instance
             */
            TraderInstanceOutput.prototype.logs = $util.emptyArray;

            /**
             * Creates a new TraderInstanceOutput instance using the specified properties.
             * @function create
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {mts.desk.ITraderInstanceOutput=} [properties] Properties to set
             * @returns {mts.desk.TraderInstanceOutput} TraderInstanceOutput instance
             */
            TraderInstanceOutput.create = function create(properties) {
                return new TraderInstanceOutput(properties);
            };

            /**
             * Encodes the specified TraderInstanceOutput message. Does not implicitly {@link mts.desk.TraderInstanceOutput.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {mts.desk.ITraderInstanceOutput} message TraderInstanceOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TraderInstanceOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                if (message.logs != null && message.logs.length)
                    for (let i = 0; i < message.logs.length; ++i)
                        $root.mts.desk.TraderInstanceOutput.LogEntry.encode(message.logs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.time);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.date);
                return writer;
            };

            /**
             * Encodes the specified TraderInstanceOutput message, length delimited. Does not implicitly {@link mts.desk.TraderInstanceOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {mts.desk.ITraderInstanceOutput} message TraderInstanceOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TraderInstanceOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TraderInstanceOutput message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.TraderInstanceOutput} TraderInstanceOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TraderInstanceOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.TraderInstanceOutput();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.string();
                            break;
                        }
                    case 3: {
                            message.time = reader.int64();
                            break;
                        }
                    case 5: {
                            message.date = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.logs && message.logs.length))
                                message.logs = [];
                            message.logs.push($root.mts.desk.TraderInstanceOutput.LogEntry.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TraderInstanceOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.TraderInstanceOutput} TraderInstanceOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TraderInstanceOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TraderInstanceOutput message.
             * @function verify
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TraderInstanceOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                if (message.date != null && message.hasOwnProperty("date"))
                    if (!$util.isString(message.date))
                        return "date: string expected";
                if (message.logs != null && message.hasOwnProperty("logs")) {
                    if (!Array.isArray(message.logs))
                        return "logs: array expected";
                    for (let i = 0; i < message.logs.length; ++i) {
                        let error = $root.mts.desk.TraderInstanceOutput.LogEntry.verify(message.logs[i]);
                        if (error)
                            return "logs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TraderInstanceOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.TraderInstanceOutput} TraderInstanceOutput
             */
            TraderInstanceOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.TraderInstanceOutput)
                    return object;
                let message = new $root.mts.desk.TraderInstanceOutput();
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                if (object.date != null)
                    message.date = String(object.date);
                if (object.logs) {
                    if (!Array.isArray(object.logs))
                        throw TypeError(".mts.desk.TraderInstanceOutput.logs: array expected");
                    message.logs = [];
                    for (let i = 0; i < object.logs.length; ++i) {
                        if (typeof object.logs[i] !== "object")
                            throw TypeError(".mts.desk.TraderInstanceOutput.logs: object expected");
                        message.logs[i] = $root.mts.desk.TraderInstanceOutput.LogEntry.fromObject(object.logs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a TraderInstanceOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {mts.desk.TraderInstanceOutput} message TraderInstanceOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TraderInstanceOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.logs = [];
                if (options.defaults) {
                    object.instanceId = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                    object.date = "";
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.logs && message.logs.length) {
                    object.logs = [];
                    for (let j = 0; j < message.logs.length; ++j)
                        object.logs[j] = $root.mts.desk.TraderInstanceOutput.LogEntry.toObject(message.logs[j], options);
                }
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = message.date;
                return object;
            };

            /**
             * Converts this TraderInstanceOutput to JSON.
             * @function toJSON
             * @memberof mts.desk.TraderInstanceOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TraderInstanceOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TraderInstanceOutput
             * @function getTypeUrl
             * @memberof mts.desk.TraderInstanceOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TraderInstanceOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.TraderInstanceOutput";
            };

            /**
             * OutputSource enum.
             * @name mts.desk.TraderInstanceOutput.OutputSource
             * @enum {number}
             * @property {number} UNKNOWN_SOURCE=0 UNKNOWN_SOURCE value
             * @property {number} CONSOLE=1 CONSOLE value
             * @property {number} MTS_API=2 MTS_API value
             */
            TraderInstanceOutput.OutputSource = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_SOURCE"] = 0;
                values[valuesById[1] = "CONSOLE"] = 1;
                values[valuesById[2] = "MTS_API"] = 2;
                return values;
            })();

            TraderInstanceOutput.LogEntry = (function() {

                /**
                 * Properties of a LogEntry.
                 * @memberof mts.desk.TraderInstanceOutput
                 * @interface ILogEntry
                 * @property {number|Long|null} [time] LogEntry time
                 * @property {string|null} [data] LogEntry data
                 */

                /**
                 * Constructs a new LogEntry.
                 * @memberof mts.desk.TraderInstanceOutput
                 * @classdesc Represents a LogEntry.
                 * @implements ILogEntry
                 * @constructor
                 * @param {mts.desk.TraderInstanceOutput.ILogEntry=} [properties] Properties to set
                 */
                function LogEntry(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LogEntry time.
                 * @member {number|Long} time
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @instance
                 */
                LogEntry.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LogEntry data.
                 * @member {string} data
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @instance
                 */
                LogEntry.prototype.data = "";

                /**
                 * Creates a new LogEntry instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {mts.desk.TraderInstanceOutput.ILogEntry=} [properties] Properties to set
                 * @returns {mts.desk.TraderInstanceOutput.LogEntry} LogEntry instance
                 */
                LogEntry.create = function create(properties) {
                    return new LogEntry(properties);
                };

                /**
                 * Encodes the specified LogEntry message. Does not implicitly {@link mts.desk.TraderInstanceOutput.LogEntry.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {mts.desk.TraderInstanceOutput.ILogEntry} message LogEntry message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogEntry.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.data);
                    return writer;
                };

                /**
                 * Encodes the specified LogEntry message, length delimited. Does not implicitly {@link mts.desk.TraderInstanceOutput.LogEntry.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {mts.desk.TraderInstanceOutput.ILogEntry} message LogEntry message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogEntry.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LogEntry message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.TraderInstanceOutput.LogEntry} LogEntry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogEntry.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.TraderInstanceOutput.LogEntry();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        case 2: {
                                message.data = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LogEntry message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.TraderInstanceOutput.LogEntry} LogEntry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogEntry.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LogEntry message.
                 * @function verify
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogEntry.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.data != null && message.hasOwnProperty("data"))
                        if (!$util.isString(message.data))
                            return "data: string expected";
                    return null;
                };

                /**
                 * Creates a LogEntry message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.TraderInstanceOutput.LogEntry} LogEntry
                 */
                LogEntry.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.TraderInstanceOutput.LogEntry)
                        return object;
                    let message = new $root.mts.desk.TraderInstanceOutput.LogEntry();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.data != null)
                        message.data = String(object.data);
                    return message;
                };

                /**
                 * Creates a plain object from a LogEntry message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {mts.desk.TraderInstanceOutput.LogEntry} message LogEntry
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogEntry.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                        object.data = "";
                    }
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = message.data;
                    return object;
                };

                /**
                 * Converts this LogEntry to JSON.
                 * @function toJSON
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogEntry.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LogEntry
                 * @function getTypeUrl
                 * @memberof mts.desk.TraderInstanceOutput.LogEntry
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LogEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.TraderInstanceOutput.LogEntry";
                };

                return LogEntry;
            })();

            return TraderInstanceOutput;
        })();

        desk.InstanceData = (function() {

            /**
             * Properties of an InstanceData.
             * @memberof mts.desk
             * @interface IInstanceData
             * @property {string|null} [instanceId] InstanceData instanceId
             * @property {string|null} [key] InstanceData key
             * @property {string|null} [value] InstanceData value
             */

            /**
             * Constructs a new InstanceData.
             * @memberof mts.desk
             * @classdesc A simple key-value store for a given instance.
             * @implements IInstanceData
             * @constructor
             * @param {mts.desk.IInstanceData=} [properties] Properties to set
             */
            function InstanceData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InstanceData instanceId.
             * @member {string} instanceId
             * @memberof mts.desk.InstanceData
             * @instance
             */
            InstanceData.prototype.instanceId = "";

            /**
             * InstanceData key.
             * @member {string} key
             * @memberof mts.desk.InstanceData
             * @instance
             */
            InstanceData.prototype.key = "";

            /**
             * InstanceData value.
             * @member {string} value
             * @memberof mts.desk.InstanceData
             * @instance
             */
            InstanceData.prototype.value = "";

            /**
             * Creates a new InstanceData instance using the specified properties.
             * @function create
             * @memberof mts.desk.InstanceData
             * @static
             * @param {mts.desk.IInstanceData=} [properties] Properties to set
             * @returns {mts.desk.InstanceData} InstanceData instance
             */
            InstanceData.create = function create(properties) {
                return new InstanceData(properties);
            };

            /**
             * Encodes the specified InstanceData message. Does not implicitly {@link mts.desk.InstanceData.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.InstanceData
             * @static
             * @param {mts.desk.IInstanceData} message InstanceData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
                return writer;
            };

            /**
             * Encodes the specified InstanceData message, length delimited. Does not implicitly {@link mts.desk.InstanceData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.InstanceData
             * @static
             * @param {mts.desk.IInstanceData} message InstanceData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InstanceData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an InstanceData message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.InstanceData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.InstanceData} InstanceData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.InstanceData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.instanceId = reader.string();
                            break;
                        }
                    case 2: {
                            message.key = reader.string();
                            break;
                        }
                    case 3: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an InstanceData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.InstanceData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.InstanceData} InstanceData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InstanceData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an InstanceData message.
             * @function verify
             * @memberof mts.desk.InstanceData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InstanceData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    if (!$util.isString(message.instanceId))
                        return "instanceId: string expected";
                if (message.key != null && message.hasOwnProperty("key"))
                    if (!$util.isString(message.key))
                        return "key: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };

            /**
             * Creates an InstanceData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.InstanceData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.InstanceData} InstanceData
             */
            InstanceData.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.InstanceData)
                    return object;
                let message = new $root.mts.desk.InstanceData();
                if (object.instanceId != null)
                    message.instanceId = String(object.instanceId);
                if (object.key != null)
                    message.key = String(object.key);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from an InstanceData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.InstanceData
             * @static
             * @param {mts.desk.InstanceData} message InstanceData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InstanceData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.instanceId = "";
                    object.key = "";
                    object.value = "";
                }
                if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                    object.instanceId = message.instanceId;
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this InstanceData to JSON.
             * @function toJSON
             * @memberof mts.desk.InstanceData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InstanceData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for InstanceData
             * @function getTypeUrl
             * @memberof mts.desk.InstanceData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InstanceData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.InstanceData";
            };

            return InstanceData;
        })();

        desk.Trader = (function() {

            /**
             * Properties of a Trader.
             * @memberof mts.desk
             * @interface ITrader
             * @property {string|null} [id] Trader id
             * @property {string|null} [userId] Trader userId
             * @property {string|null} [deleted] Trader deleted
             * @property {number|null} [version] Trader version
             * @property {number|Long|null} [versionTime] Trader versionTime
             * @property {mts.desk.Access|null} [access] Trader access
             * @property {string|null} [name] Trader name
             * @property {string|null} [description] Trader description
             * @property {string|null} [created] Trader created
             * @property {string|null} [updated] Trader updated
             * @property {number|Long|null} [tickInterval] Trader tickInterval
             * @property {mts.desk.TimeUnit|null} [tickIntervalUnit] Trader tickIntervalUnit
             * @property {string|null} [error] Trader error
             * @property {Object.<string,string>|null} [tickHandlers] Trader tickHandlers
             * @property {Array.<mts.desk.Tick.Type>|null} [executeTickTypes] Trader executeTickTypes
             * @property {Object.<string,boolean>|null} [labels] Trader labels
             * @property {Array.<mts.desk.ITraderOption>|null} [options] Trader options
             * @property {mts.desk.IUser|null} [owner] Trader owner
             */

            /**
             * Constructs a new Trader.
             * @memberof mts.desk
             * @classdesc Represents a Trader.
             * @implements ITrader
             * @constructor
             * @param {mts.desk.ITrader=} [properties] Properties to set
             */
            function Trader(properties) {
                this.tickHandlers = {};
                this.executeTickTypes = [];
                this.labels = {};
                this.options = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Trader id.
             * @member {string} id
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.id = "";

            /**
             * Trader userId.
             * @member {string} userId
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.userId = "";

            /**
             * Trader deleted.
             * @member {string} deleted
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.deleted = "";

            /**
             * Trader version.
             * @member {number} version
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.version = 0;

            /**
             * Trader versionTime.
             * @member {number|Long} versionTime
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.versionTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Trader access.
             * @member {mts.desk.Access} access
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.access = 0;

            /**
             * Trader name.
             * @member {string} name
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.name = "";

            /**
             * Trader description.
             * @member {string} description
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.description = "";

            /**
             * Trader created.
             * @member {string} created
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.created = "";

            /**
             * Trader updated.
             * @member {string} updated
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.updated = "";

            /**
             * Trader tickInterval.
             * @member {number|Long} tickInterval
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.tickInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Trader tickIntervalUnit.
             * @member {mts.desk.TimeUnit} tickIntervalUnit
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.tickIntervalUnit = 0;

            /**
             * Trader error.
             * @member {string} error
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.error = "";

            /**
             * Trader tickHandlers.
             * @member {Object.<string,string>} tickHandlers
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.tickHandlers = $util.emptyObject;

            /**
             * Trader executeTickTypes.
             * @member {Array.<mts.desk.Tick.Type>} executeTickTypes
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.executeTickTypes = $util.emptyArray;

            /**
             * Trader labels.
             * @member {Object.<string,boolean>} labels
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.labels = $util.emptyObject;

            /**
             * Trader options.
             * @member {Array.<mts.desk.ITraderOption>} options
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.options = $util.emptyArray;

            /**
             * Trader owner.
             * @member {mts.desk.IUser|null|undefined} owner
             * @memberof mts.desk.Trader
             * @instance
             */
            Trader.prototype.owner = null;

            /**
             * Creates a new Trader instance using the specified properties.
             * @function create
             * @memberof mts.desk.Trader
             * @static
             * @param {mts.desk.ITrader=} [properties] Properties to set
             * @returns {mts.desk.Trader} Trader instance
             */
            Trader.create = function create(properties) {
                return new Trader(properties);
            };

            /**
             * Encodes the specified Trader message. Does not implicitly {@link mts.desk.Trader.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.Trader
             * @static
             * @param {mts.desk.ITrader} message Trader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Trader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.version);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.created);
                if (message.versionTime != null && Object.hasOwnProperty.call(message, "versionTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.versionTime);
                if (message.access != null && Object.hasOwnProperty.call(message, "access"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.access);
                if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.updated);
                if (message.deleted != null && Object.hasOwnProperty.call(message, "deleted"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.deleted);
                if (message.tickInterval != null && Object.hasOwnProperty.call(message, "tickInterval"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int64(message.tickInterval);
                if (message.tickIntervalUnit != null && Object.hasOwnProperty.call(message, "tickIntervalUnit"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int32(message.tickIntervalUnit);
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.error);
                if (message.labels != null && Object.hasOwnProperty.call(message, "labels"))
                    for (let keys = Object.keys(message.labels), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 22, wireType 2 =*/178).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.labels[keys[i]]).ldelim();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 23, wireType 2 =*/186).string(message.userId);
                if (message.tickHandlers != null && Object.hasOwnProperty.call(message, "tickHandlers"))
                    for (let keys = Object.keys(message.tickHandlers), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 24, wireType 2 =*/194).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.tickHandlers[keys[i]]).ldelim();
                if (message.executeTickTypes != null && message.executeTickTypes.length) {
                    writer.uint32(/* id 25, wireType 2 =*/202).fork();
                    for (let i = 0; i < message.executeTickTypes.length; ++i)
                        writer.int32(message.executeTickTypes[i]);
                    writer.ldelim();
                }
                if (message.options != null && message.options.length)
                    for (let i = 0; i < message.options.length; ++i)
                        $root.mts.desk.TraderOption.encode(message.options[i], writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                    $root.mts.desk.User.encode(message.owner, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Trader message, length delimited. Does not implicitly {@link mts.desk.Trader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.Trader
             * @static
             * @param {mts.desk.ITrader} message Trader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Trader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Trader message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.Trader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.Trader} Trader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Trader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.Trader(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 23: {
                            message.userId = reader.string();
                            break;
                        }
                    case 10: {
                            message.deleted = reader.string();
                            break;
                        }
                    case 3: {
                            message.version = reader.int32();
                            break;
                        }
                    case 6: {
                            message.versionTime = reader.int64();
                            break;
                        }
                    case 7: {
                            message.access = reader.int32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.description = reader.string();
                            break;
                        }
                    case 5: {
                            message.created = reader.string();
                            break;
                        }
                    case 8: {
                            message.updated = reader.string();
                            break;
                        }
                    case 17: {
                            message.tickInterval = reader.int64();
                            break;
                        }
                    case 18: {
                            message.tickIntervalUnit = reader.int32();
                            break;
                        }
                    case 21: {
                            message.error = reader.string();
                            break;
                        }
                    case 24: {
                            if (message.tickHandlers === $util.emptyObject)
                                message.tickHandlers = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.tickHandlers[key] = value;
                            break;
                        }
                    case 25: {
                            if (!(message.executeTickTypes && message.executeTickTypes.length))
                                message.executeTickTypes = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.executeTickTypes.push(reader.int32());
                            } else
                                message.executeTickTypes.push(reader.int32());
                            break;
                        }
                    case 22: {
                            if (message.labels === $util.emptyObject)
                                message.labels = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = false;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.labels[key] = value;
                            break;
                        }
                    case 26: {
                            if (!(message.options && message.options.length))
                                message.options = [];
                            message.options.push($root.mts.desk.TraderOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 27: {
                            message.owner = $root.mts.desk.User.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Trader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.Trader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.Trader} Trader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Trader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Trader message.
             * @function verify
             * @memberof mts.desk.Trader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Trader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    if (!$util.isString(message.deleted))
                        return "deleted: string expected";
                if (message.version != null && message.hasOwnProperty("version"))
                    if (!$util.isInteger(message.version))
                        return "version: integer expected";
                if (message.versionTime != null && message.hasOwnProperty("versionTime"))
                    if (!$util.isInteger(message.versionTime) && !(message.versionTime && $util.isInteger(message.versionTime.low) && $util.isInteger(message.versionTime.high)))
                        return "versionTime: integer|Long expected";
                if (message.access != null && message.hasOwnProperty("access"))
                    switch (message.access) {
                    default:
                        return "access: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.created != null && message.hasOwnProperty("created"))
                    if (!$util.isString(message.created))
                        return "created: string expected";
                if (message.updated != null && message.hasOwnProperty("updated"))
                    if (!$util.isString(message.updated))
                        return "updated: string expected";
                if (message.tickInterval != null && message.hasOwnProperty("tickInterval"))
                    if (!$util.isInteger(message.tickInterval) && !(message.tickInterval && $util.isInteger(message.tickInterval.low) && $util.isInteger(message.tickInterval.high)))
                        return "tickInterval: integer|Long expected";
                if (message.tickIntervalUnit != null && message.hasOwnProperty("tickIntervalUnit"))
                    switch (message.tickIntervalUnit) {
                    default:
                        return "tickIntervalUnit: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.error != null && message.hasOwnProperty("error"))
                    if (!$util.isString(message.error))
                        return "error: string expected";
                if (message.tickHandlers != null && message.hasOwnProperty("tickHandlers")) {
                    if (!$util.isObject(message.tickHandlers))
                        return "tickHandlers: object expected";
                    let key = Object.keys(message.tickHandlers);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.tickHandlers[key[i]]))
                            return "tickHandlers: string{k:string} expected";
                }
                if (message.executeTickTypes != null && message.hasOwnProperty("executeTickTypes")) {
                    if (!Array.isArray(message.executeTickTypes))
                        return "executeTickTypes: array expected";
                    for (let i = 0; i < message.executeTickTypes.length; ++i)
                        switch (message.executeTickTypes[i]) {
                        default:
                            return "executeTickTypes: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                }
                if (message.labels != null && message.hasOwnProperty("labels")) {
                    if (!$util.isObject(message.labels))
                        return "labels: object expected";
                    let key = Object.keys(message.labels);
                    for (let i = 0; i < key.length; ++i)
                        if (typeof message.labels[key[i]] !== "boolean")
                            return "labels: boolean{k:string} expected";
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    if (!Array.isArray(message.options))
                        return "options: array expected";
                    for (let i = 0; i < message.options.length; ++i) {
                        let error = $root.mts.desk.TraderOption.verify(message.options[i]);
                        if (error)
                            return "options." + error;
                    }
                }
                if (message.owner != null && message.hasOwnProperty("owner")) {
                    let error = $root.mts.desk.User.verify(message.owner);
                    if (error)
                        return "owner." + error;
                }
                return null;
            };

            /**
             * Creates a Trader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.Trader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.Trader} Trader
             */
            Trader.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.Trader)
                    return object;
                let message = new $root.mts.desk.Trader();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.deleted != null)
                    message.deleted = String(object.deleted);
                if (object.version != null)
                    message.version = object.version | 0;
                if (object.versionTime != null)
                    if ($util.Long)
                        (message.versionTime = $util.Long.fromValue(object.versionTime)).unsigned = false;
                    else if (typeof object.versionTime === "string")
                        message.versionTime = parseInt(object.versionTime, 10);
                    else if (typeof object.versionTime === "number")
                        message.versionTime = object.versionTime;
                    else if (typeof object.versionTime === "object")
                        message.versionTime = new $util.LongBits(object.versionTime.low >>> 0, object.versionTime.high >>> 0).toNumber();
                switch (object.access) {
                default:
                    if (typeof object.access === "number") {
                        message.access = object.access;
                        break;
                    }
                    break;
                case "UNKNOWN_ACCESS":
                case 0:
                    message.access = 0;
                    break;
                case "PUBLIC":
                case 1:
                    message.access = 1;
                    break;
                case "PRIVATE":
                case 2:
                    message.access = 2;
                    break;
                case "UNLISTED":
                case 3:
                    message.access = 3;
                    break;
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.created != null)
                    message.created = String(object.created);
                if (object.updated != null)
                    message.updated = String(object.updated);
                if (object.tickInterval != null)
                    if ($util.Long)
                        (message.tickInterval = $util.Long.fromValue(object.tickInterval)).unsigned = false;
                    else if (typeof object.tickInterval === "string")
                        message.tickInterval = parseInt(object.tickInterval, 10);
                    else if (typeof object.tickInterval === "number")
                        message.tickInterval = object.tickInterval;
                    else if (typeof object.tickInterval === "object")
                        message.tickInterval = new $util.LongBits(object.tickInterval.low >>> 0, object.tickInterval.high >>> 0).toNumber();
                switch (object.tickIntervalUnit) {
                default:
                    if (typeof object.tickIntervalUnit === "number") {
                        message.tickIntervalUnit = object.tickIntervalUnit;
                        break;
                    }
                    break;
                case "UNKNOWN_TIMEUNIT":
                case 0:
                    message.tickIntervalUnit = 0;
                    break;
                case "SECONDS":
                case 1:
                    message.tickIntervalUnit = 1;
                    break;
                case "MINUTES":
                case 2:
                    message.tickIntervalUnit = 2;
                    break;
                case "DAYS":
                case 3:
                    message.tickIntervalUnit = 3;
                    break;
                }
                if (object.error != null)
                    message.error = String(object.error);
                if (object.tickHandlers) {
                    if (typeof object.tickHandlers !== "object")
                        throw TypeError(".mts.desk.Trader.tickHandlers: object expected");
                    message.tickHandlers = {};
                    for (let keys = Object.keys(object.tickHandlers), i = 0; i < keys.length; ++i)
                        message.tickHandlers[keys[i]] = String(object.tickHandlers[keys[i]]);
                }
                if (object.executeTickTypes) {
                    if (!Array.isArray(object.executeTickTypes))
                        throw TypeError(".mts.desk.Trader.executeTickTypes: array expected");
                    message.executeTickTypes = [];
                    for (let i = 0; i < object.executeTickTypes.length; ++i)
                        switch (object.executeTickTypes[i]) {
                        default:
                            if (typeof object.executeTickTypes[i] === "number") {
                                message.executeTickTypes[i] = object.executeTickTypes[i];
                                break;
                            }
                        case "UNKNOWN":
                        case 0:
                            message.executeTickTypes[i] = 0;
                            break;
                        case "PREMARKET_OPEN":
                        case 1:
                            message.executeTickTypes[i] = 1;
                            break;
                        case "OPEN":
                        case 2:
                            message.executeTickTypes[i] = 2;
                            break;
                        case "CLOSE":
                        case 3:
                            message.executeTickTypes[i] = 3;
                            break;
                        case "AFTER_HOURS_OPEN":
                        case 4:
                            message.executeTickTypes[i] = 4;
                            break;
                        case "REGULAR":
                        case 5:
                            message.executeTickTypes[i] = 5;
                            break;
                        case "EOF":
                        case 6:
                            message.executeTickTypes[i] = 6;
                            break;
                        case "MAIN":
                        case 7:
                            message.executeTickTypes[i] = 7;
                            break;
                        case "INIT":
                        case 8:
                            message.executeTickTypes[i] = 8;
                            break;
                        }
                }
                if (object.labels) {
                    if (typeof object.labels !== "object")
                        throw TypeError(".mts.desk.Trader.labels: object expected");
                    message.labels = {};
                    for (let keys = Object.keys(object.labels), i = 0; i < keys.length; ++i)
                        message.labels[keys[i]] = Boolean(object.labels[keys[i]]);
                }
                if (object.options) {
                    if (!Array.isArray(object.options))
                        throw TypeError(".mts.desk.Trader.options: array expected");
                    message.options = [];
                    for (let i = 0; i < object.options.length; ++i) {
                        if (typeof object.options[i] !== "object")
                            throw TypeError(".mts.desk.Trader.options: object expected");
                        message.options[i] = $root.mts.desk.TraderOption.fromObject(object.options[i]);
                    }
                }
                if (object.owner != null) {
                    if (typeof object.owner !== "object")
                        throw TypeError(".mts.desk.Trader.owner: object expected");
                    message.owner = $root.mts.desk.User.fromObject(object.owner);
                }
                return message;
            };

            /**
             * Creates a plain object from a Trader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.Trader
             * @static
             * @param {mts.desk.Trader} message Trader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Trader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.executeTickTypes = [];
                    object.options = [];
                }
                if (options.objects || options.defaults) {
                    object.labels = {};
                    object.tickHandlers = {};
                }
                if (options.defaults) {
                    object.id = "";
                    object.name = "";
                    object.version = 0;
                    object.description = "";
                    object.created = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.versionTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.versionTime = options.longs === String ? "0" : 0;
                    object.access = options.enums === String ? "UNKNOWN_ACCESS" : 0;
                    object.updated = "";
                    object.deleted = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.tickInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tickInterval = options.longs === String ? "0" : 0;
                    object.tickIntervalUnit = options.enums === String ? "UNKNOWN_TIMEUNIT" : 0;
                    object.error = "";
                    object.userId = "";
                    object.owner = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = message.version;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.created != null && message.hasOwnProperty("created"))
                    object.created = message.created;
                if (message.versionTime != null && message.hasOwnProperty("versionTime"))
                    if (typeof message.versionTime === "number")
                        object.versionTime = options.longs === String ? String(message.versionTime) : message.versionTime;
                    else
                        object.versionTime = options.longs === String ? $util.Long.prototype.toString.call(message.versionTime) : options.longs === Number ? new $util.LongBits(message.versionTime.low >>> 0, message.versionTime.high >>> 0).toNumber() : message.versionTime;
                if (message.access != null && message.hasOwnProperty("access"))
                    object.access = options.enums === String ? $root.mts.desk.Access[message.access] === undefined ? message.access : $root.mts.desk.Access[message.access] : message.access;
                if (message.updated != null && message.hasOwnProperty("updated"))
                    object.updated = message.updated;
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    object.deleted = message.deleted;
                if (message.tickInterval != null && message.hasOwnProperty("tickInterval"))
                    if (typeof message.tickInterval === "number")
                        object.tickInterval = options.longs === String ? String(message.tickInterval) : message.tickInterval;
                    else
                        object.tickInterval = options.longs === String ? $util.Long.prototype.toString.call(message.tickInterval) : options.longs === Number ? new $util.LongBits(message.tickInterval.low >>> 0, message.tickInterval.high >>> 0).toNumber() : message.tickInterval;
                if (message.tickIntervalUnit != null && message.hasOwnProperty("tickIntervalUnit"))
                    object.tickIntervalUnit = options.enums === String ? $root.mts.desk.TimeUnit[message.tickIntervalUnit] === undefined ? message.tickIntervalUnit : $root.mts.desk.TimeUnit[message.tickIntervalUnit] : message.tickIntervalUnit;
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = message.error;
                let keys2;
                if (message.labels && (keys2 = Object.keys(message.labels)).length) {
                    object.labels = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.labels[keys2[j]] = message.labels[keys2[j]];
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.tickHandlers && (keys2 = Object.keys(message.tickHandlers)).length) {
                    object.tickHandlers = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.tickHandlers[keys2[j]] = message.tickHandlers[keys2[j]];
                }
                if (message.executeTickTypes && message.executeTickTypes.length) {
                    object.executeTickTypes = [];
                    for (let j = 0; j < message.executeTickTypes.length; ++j)
                        object.executeTickTypes[j] = options.enums === String ? $root.mts.desk.Tick.Type[message.executeTickTypes[j]] === undefined ? message.executeTickTypes[j] : $root.mts.desk.Tick.Type[message.executeTickTypes[j]] : message.executeTickTypes[j];
                }
                if (message.options && message.options.length) {
                    object.options = [];
                    for (let j = 0; j < message.options.length; ++j)
                        object.options[j] = $root.mts.desk.TraderOption.toObject(message.options[j], options);
                }
                if (message.owner != null && message.hasOwnProperty("owner"))
                    object.owner = $root.mts.desk.User.toObject(message.owner, options);
                return object;
            };

            /**
             * Converts this Trader to JSON.
             * @function toJSON
             * @memberof mts.desk.Trader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Trader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Trader
             * @function getTypeUrl
             * @memberof mts.desk.Trader
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Trader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.Trader";
            };

            return Trader;
        })();

        desk.TraderOption = (function() {

            /**
             * Properties of a TraderOption.
             * @memberof mts.desk
             * @interface ITraderOption
             * @property {string|null} [name] TraderOption name
             * @property {string|null} [label] TraderOption label
             * @property {string|null} [defaultValue] TraderOption defaultValue
             * @property {string|null} [description] TraderOption description
             * @property {boolean|null} [required] TraderOption required
             * @property {mts.desk.TraderOption.OptionType|null} [type] TraderOption type
             */

            /**
             * Constructs a new TraderOption.
             * @memberof mts.desk
             * @classdesc A simple implementation of options that a Trader can define.
             * @implements ITraderOption
             * @constructor
             * @param {mts.desk.ITraderOption=} [properties] Properties to set
             */
            function TraderOption(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TraderOption name.
             * @member {string} name
             * @memberof mts.desk.TraderOption
             * @instance
             */
            TraderOption.prototype.name = "";

            /**
             * TraderOption label.
             * @member {string} label
             * @memberof mts.desk.TraderOption
             * @instance
             */
            TraderOption.prototype.label = "";

            /**
             * TraderOption defaultValue.
             * @member {string} defaultValue
             * @memberof mts.desk.TraderOption
             * @instance
             */
            TraderOption.prototype.defaultValue = "";

            /**
             * TraderOption description.
             * @member {string} description
             * @memberof mts.desk.TraderOption
             * @instance
             */
            TraderOption.prototype.description = "";

            /**
             * TraderOption required.
             * @member {boolean} required
             * @memberof mts.desk.TraderOption
             * @instance
             */
            TraderOption.prototype.required = false;

            /**
             * TraderOption type.
             * @member {mts.desk.TraderOption.OptionType} type
             * @memberof mts.desk.TraderOption
             * @instance
             */
            TraderOption.prototype.type = 0;

            /**
             * Creates a new TraderOption instance using the specified properties.
             * @function create
             * @memberof mts.desk.TraderOption
             * @static
             * @param {mts.desk.ITraderOption=} [properties] Properties to set
             * @returns {mts.desk.TraderOption} TraderOption instance
             */
            TraderOption.create = function create(properties) {
                return new TraderOption(properties);
            };

            /**
             * Encodes the specified TraderOption message. Does not implicitly {@link mts.desk.TraderOption.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.TraderOption
             * @static
             * @param {mts.desk.ITraderOption} message TraderOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TraderOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.defaultValue);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.required);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.type);
                return writer;
            };

            /**
             * Encodes the specified TraderOption message, length delimited. Does not implicitly {@link mts.desk.TraderOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.TraderOption
             * @static
             * @param {mts.desk.ITraderOption} message TraderOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TraderOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TraderOption message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.TraderOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.TraderOption} TraderOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TraderOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.TraderOption();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.label = reader.string();
                            break;
                        }
                    case 3: {
                            message.defaultValue = reader.string();
                            break;
                        }
                    case 4: {
                            message.description = reader.string();
                            break;
                        }
                    case 5: {
                            message.required = reader.bool();
                            break;
                        }
                    case 6: {
                            message.type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TraderOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.TraderOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.TraderOption} TraderOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TraderOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TraderOption message.
             * @function verify
             * @memberof mts.desk.TraderOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TraderOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.required != null && message.hasOwnProperty("required"))
                    if (typeof message.required !== "boolean")
                        return "required: boolean expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a TraderOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.TraderOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.TraderOption} TraderOption
             */
            TraderOption.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.TraderOption)
                    return object;
                let message = new $root.mts.desk.TraderOption();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.label != null)
                    message.label = String(object.label);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.required != null)
                    message.required = Boolean(object.required);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UNKNOWN_OPTION_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "STRING":
                case 1:
                    message.type = 1;
                    break;
                case "NUMBER":
                case 2:
                    message.type = 2;
                    break;
                case "BOOLEAN":
                case 3:
                    message.type = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a TraderOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.TraderOption
             * @static
             * @param {mts.desk.TraderOption} message TraderOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TraderOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.label = "";
                    object.defaultValue = "";
                    object.description = "";
                    object.required = false;
                    object.type = options.enums === String ? "UNKNOWN_OPTION_TYPE" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.required != null && message.hasOwnProperty("required"))
                    object.required = message.required;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.desk.TraderOption.OptionType[message.type] === undefined ? message.type : $root.mts.desk.TraderOption.OptionType[message.type] : message.type;
                return object;
            };

            /**
             * Converts this TraderOption to JSON.
             * @function toJSON
             * @memberof mts.desk.TraderOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TraderOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TraderOption
             * @function getTypeUrl
             * @memberof mts.desk.TraderOption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TraderOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.TraderOption";
            };

            /**
             * OptionType enum.
             * @name mts.desk.TraderOption.OptionType
             * @enum {number}
             * @property {number} UNKNOWN_OPTION_TYPE=0 UNKNOWN_OPTION_TYPE value
             * @property {number} STRING=1 STRING value
             * @property {number} NUMBER=2 NUMBER value
             * @property {number} BOOLEAN=3 BOOLEAN value
             */
            TraderOption.OptionType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_OPTION_TYPE"] = 0;
                values[valuesById[1] = "STRING"] = 1;
                values[valuesById[2] = "NUMBER"] = 2;
                values[valuesById[3] = "BOOLEAN"] = 3;
                return values;
            })();

            return TraderOption;
        })();

        desk.GetTraderInstancesResponse = (function() {

            /**
             * Properties of a GetTraderInstancesResponse.
             * @memberof mts.desk
             * @interface IGetTraderInstancesResponse
             * @property {Array.<mts.desk.ITraderInstance>|null} [traderInstances] GetTraderInstancesResponse traderInstances
             */

            /**
             * Constructs a new GetTraderInstancesResponse.
             * @memberof mts.desk
             * @classdesc Represents a GetTraderInstancesResponse.
             * @implements IGetTraderInstancesResponse
             * @constructor
             * @param {mts.desk.IGetTraderInstancesResponse=} [properties] Properties to set
             */
            function GetTraderInstancesResponse(properties) {
                this.traderInstances = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTraderInstancesResponse traderInstances.
             * @member {Array.<mts.desk.ITraderInstance>} traderInstances
             * @memberof mts.desk.GetTraderInstancesResponse
             * @instance
             */
            GetTraderInstancesResponse.prototype.traderInstances = $util.emptyArray;

            /**
             * Creates a new GetTraderInstancesResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {mts.desk.IGetTraderInstancesResponse=} [properties] Properties to set
             * @returns {mts.desk.GetTraderInstancesResponse} GetTraderInstancesResponse instance
             */
            GetTraderInstancesResponse.create = function create(properties) {
                return new GetTraderInstancesResponse(properties);
            };

            /**
             * Encodes the specified GetTraderInstancesResponse message. Does not implicitly {@link mts.desk.GetTraderInstancesResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {mts.desk.IGetTraderInstancesResponse} message GetTraderInstancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstancesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traderInstances != null && message.traderInstances.length)
                    for (let i = 0; i < message.traderInstances.length; ++i)
                        $root.mts.desk.TraderInstance.encode(message.traderInstances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetTraderInstancesResponse message, length delimited. Does not implicitly {@link mts.desk.GetTraderInstancesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {mts.desk.IGetTraderInstancesResponse} message GetTraderInstancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTraderInstancesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetTraderInstancesResponse} GetTraderInstancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstancesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetTraderInstancesResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.traderInstances && message.traderInstances.length))
                                message.traderInstances = [];
                            message.traderInstances.push($root.mts.desk.TraderInstance.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTraderInstancesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetTraderInstancesResponse} GetTraderInstancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstancesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTraderInstancesResponse message.
             * @function verify
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTraderInstancesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traderInstances != null && message.hasOwnProperty("traderInstances")) {
                    if (!Array.isArray(message.traderInstances))
                        return "traderInstances: array expected";
                    for (let i = 0; i < message.traderInstances.length; ++i) {
                        let error = $root.mts.desk.TraderInstance.verify(message.traderInstances[i]);
                        if (error)
                            return "traderInstances." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetTraderInstancesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetTraderInstancesResponse} GetTraderInstancesResponse
             */
            GetTraderInstancesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetTraderInstancesResponse)
                    return object;
                let message = new $root.mts.desk.GetTraderInstancesResponse();
                if (object.traderInstances) {
                    if (!Array.isArray(object.traderInstances))
                        throw TypeError(".mts.desk.GetTraderInstancesResponse.traderInstances: array expected");
                    message.traderInstances = [];
                    for (let i = 0; i < object.traderInstances.length; ++i) {
                        if (typeof object.traderInstances[i] !== "object")
                            throw TypeError(".mts.desk.GetTraderInstancesResponse.traderInstances: object expected");
                        message.traderInstances[i] = $root.mts.desk.TraderInstance.fromObject(object.traderInstances[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetTraderInstancesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {mts.desk.GetTraderInstancesResponse} message GetTraderInstancesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTraderInstancesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.traderInstances = [];
                if (message.traderInstances && message.traderInstances.length) {
                    object.traderInstances = [];
                    for (let j = 0; j < message.traderInstances.length; ++j)
                        object.traderInstances[j] = $root.mts.desk.TraderInstance.toObject(message.traderInstances[j], options);
                }
                return object;
            };

            /**
             * Converts this GetTraderInstancesResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.GetTraderInstancesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTraderInstancesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTraderInstancesResponse
             * @function getTypeUrl
             * @memberof mts.desk.GetTraderInstancesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTraderInstancesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetTraderInstancesResponse";
            };

            return GetTraderInstancesResponse;
        })();

        desk.GetTraderInstanceOutputResponse = (function() {

            /**
             * Properties of a GetTraderInstanceOutputResponse.
             * @memberof mts.desk
             * @interface IGetTraderInstanceOutputResponse
             * @property {Array.<mts.desk.ITraderInstanceOutput>|null} [instanceOutputs] GetTraderInstanceOutputResponse instanceOutputs
             */

            /**
             * Constructs a new GetTraderInstanceOutputResponse.
             * @memberof mts.desk
             * @classdesc Represents a GetTraderInstanceOutputResponse.
             * @implements IGetTraderInstanceOutputResponse
             * @constructor
             * @param {mts.desk.IGetTraderInstanceOutputResponse=} [properties] Properties to set
             */
            function GetTraderInstanceOutputResponse(properties) {
                this.instanceOutputs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTraderInstanceOutputResponse instanceOutputs.
             * @member {Array.<mts.desk.ITraderInstanceOutput>} instanceOutputs
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @instance
             */
            GetTraderInstanceOutputResponse.prototype.instanceOutputs = $util.emptyArray;

            /**
             * Creates a new GetTraderInstanceOutputResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {mts.desk.IGetTraderInstanceOutputResponse=} [properties] Properties to set
             * @returns {mts.desk.GetTraderInstanceOutputResponse} GetTraderInstanceOutputResponse instance
             */
            GetTraderInstanceOutputResponse.create = function create(properties) {
                return new GetTraderInstanceOutputResponse(properties);
            };

            /**
             * Encodes the specified GetTraderInstanceOutputResponse message. Does not implicitly {@link mts.desk.GetTraderInstanceOutputResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {mts.desk.IGetTraderInstanceOutputResponse} message GetTraderInstanceOutputResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstanceOutputResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceOutputs != null && message.instanceOutputs.length)
                    for (let i = 0; i < message.instanceOutputs.length; ++i)
                        $root.mts.desk.TraderInstanceOutput.encode(message.instanceOutputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetTraderInstanceOutputResponse message, length delimited. Does not implicitly {@link mts.desk.GetTraderInstanceOutputResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {mts.desk.IGetTraderInstanceOutputResponse} message GetTraderInstanceOutputResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTraderInstanceOutputResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTraderInstanceOutputResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.GetTraderInstanceOutputResponse} GetTraderInstanceOutputResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstanceOutputResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.GetTraderInstanceOutputResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.instanceOutputs && message.instanceOutputs.length))
                                message.instanceOutputs = [];
                            message.instanceOutputs.push($root.mts.desk.TraderInstanceOutput.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTraderInstanceOutputResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.GetTraderInstanceOutputResponse} GetTraderInstanceOutputResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTraderInstanceOutputResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTraderInstanceOutputResponse message.
             * @function verify
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTraderInstanceOutputResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceOutputs != null && message.hasOwnProperty("instanceOutputs")) {
                    if (!Array.isArray(message.instanceOutputs))
                        return "instanceOutputs: array expected";
                    for (let i = 0; i < message.instanceOutputs.length; ++i) {
                        let error = $root.mts.desk.TraderInstanceOutput.verify(message.instanceOutputs[i]);
                        if (error)
                            return "instanceOutputs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetTraderInstanceOutputResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.GetTraderInstanceOutputResponse} GetTraderInstanceOutputResponse
             */
            GetTraderInstanceOutputResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.GetTraderInstanceOutputResponse)
                    return object;
                let message = new $root.mts.desk.GetTraderInstanceOutputResponse();
                if (object.instanceOutputs) {
                    if (!Array.isArray(object.instanceOutputs))
                        throw TypeError(".mts.desk.GetTraderInstanceOutputResponse.instanceOutputs: array expected");
                    message.instanceOutputs = [];
                    for (let i = 0; i < object.instanceOutputs.length; ++i) {
                        if (typeof object.instanceOutputs[i] !== "object")
                            throw TypeError(".mts.desk.GetTraderInstanceOutputResponse.instanceOutputs: object expected");
                        message.instanceOutputs[i] = $root.mts.desk.TraderInstanceOutput.fromObject(object.instanceOutputs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetTraderInstanceOutputResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {mts.desk.GetTraderInstanceOutputResponse} message GetTraderInstanceOutputResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTraderInstanceOutputResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.instanceOutputs = [];
                if (message.instanceOutputs && message.instanceOutputs.length) {
                    object.instanceOutputs = [];
                    for (let j = 0; j < message.instanceOutputs.length; ++j)
                        object.instanceOutputs[j] = $root.mts.desk.TraderInstanceOutput.toObject(message.instanceOutputs[j], options);
                }
                return object;
            };

            /**
             * Converts this GetTraderInstanceOutputResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTraderInstanceOutputResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTraderInstanceOutputResponse
             * @function getTypeUrl
             * @memberof mts.desk.GetTraderInstanceOutputResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTraderInstanceOutputResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.GetTraderInstanceOutputResponse";
            };

            return GetTraderInstanceOutputResponse;
        })();

        desk.DeleteTradersRequest = (function() {

            /**
             * Properties of a DeleteTradersRequest.
             * @memberof mts.desk
             * @interface IDeleteTradersRequest
             * @property {Array.<string>|null} [traderIds] DeleteTradersRequest traderIds
             */

            /**
             * Constructs a new DeleteTradersRequest.
             * @memberof mts.desk
             * @classdesc Represents a DeleteTradersRequest.
             * @implements IDeleteTradersRequest
             * @constructor
             * @param {mts.desk.IDeleteTradersRequest=} [properties] Properties to set
             */
            function DeleteTradersRequest(properties) {
                this.traderIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteTradersRequest traderIds.
             * @member {Array.<string>} traderIds
             * @memberof mts.desk.DeleteTradersRequest
             * @instance
             */
            DeleteTradersRequest.prototype.traderIds = $util.emptyArray;

            /**
             * Creates a new DeleteTradersRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {mts.desk.IDeleteTradersRequest=} [properties] Properties to set
             * @returns {mts.desk.DeleteTradersRequest} DeleteTradersRequest instance
             */
            DeleteTradersRequest.create = function create(properties) {
                return new DeleteTradersRequest(properties);
            };

            /**
             * Encodes the specified DeleteTradersRequest message. Does not implicitly {@link mts.desk.DeleteTradersRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {mts.desk.IDeleteTradersRequest} message DeleteTradersRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTradersRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traderIds != null && message.traderIds.length)
                    for (let i = 0; i < message.traderIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderIds[i]);
                return writer;
            };

            /**
             * Encodes the specified DeleteTradersRequest message, length delimited. Does not implicitly {@link mts.desk.DeleteTradersRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {mts.desk.IDeleteTradersRequest} message DeleteTradersRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTradersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteTradersRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.DeleteTradersRequest} DeleteTradersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTradersRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.DeleteTradersRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.traderIds && message.traderIds.length))
                                message.traderIds = [];
                            message.traderIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteTradersRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.DeleteTradersRequest} DeleteTradersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTradersRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteTradersRequest message.
             * @function verify
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteTradersRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traderIds != null && message.hasOwnProperty("traderIds")) {
                    if (!Array.isArray(message.traderIds))
                        return "traderIds: array expected";
                    for (let i = 0; i < message.traderIds.length; ++i)
                        if (!$util.isString(message.traderIds[i]))
                            return "traderIds: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DeleteTradersRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.DeleteTradersRequest} DeleteTradersRequest
             */
            DeleteTradersRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.DeleteTradersRequest)
                    return object;
                let message = new $root.mts.desk.DeleteTradersRequest();
                if (object.traderIds) {
                    if (!Array.isArray(object.traderIds))
                        throw TypeError(".mts.desk.DeleteTradersRequest.traderIds: array expected");
                    message.traderIds = [];
                    for (let i = 0; i < object.traderIds.length; ++i)
                        message.traderIds[i] = String(object.traderIds[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DeleteTradersRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {mts.desk.DeleteTradersRequest} message DeleteTradersRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteTradersRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.traderIds = [];
                if (message.traderIds && message.traderIds.length) {
                    object.traderIds = [];
                    for (let j = 0; j < message.traderIds.length; ++j)
                        object.traderIds[j] = message.traderIds[j];
                }
                return object;
            };

            /**
             * Converts this DeleteTradersRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.DeleteTradersRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteTradersRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteTradersRequest
             * @function getTypeUrl
             * @memberof mts.desk.DeleteTradersRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteTradersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.DeleteTradersRequest";
            };

            return DeleteTradersRequest;
        })();

        desk.DeleteTradersResponse = (function() {

            /**
             * Properties of a DeleteTradersResponse.
             * @memberof mts.desk
             * @interface IDeleteTradersResponse
             * @property {number|null} [deletedCount] DeleteTradersResponse deletedCount
             */

            /**
             * Constructs a new DeleteTradersResponse.
             * @memberof mts.desk
             * @classdesc Represents a DeleteTradersResponse.
             * @implements IDeleteTradersResponse
             * @constructor
             * @param {mts.desk.IDeleteTradersResponse=} [properties] Properties to set
             */
            function DeleteTradersResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteTradersResponse deletedCount.
             * @member {number} deletedCount
             * @memberof mts.desk.DeleteTradersResponse
             * @instance
             */
            DeleteTradersResponse.prototype.deletedCount = 0;

            /**
             * Creates a new DeleteTradersResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {mts.desk.IDeleteTradersResponse=} [properties] Properties to set
             * @returns {mts.desk.DeleteTradersResponse} DeleteTradersResponse instance
             */
            DeleteTradersResponse.create = function create(properties) {
                return new DeleteTradersResponse(properties);
            };

            /**
             * Encodes the specified DeleteTradersResponse message. Does not implicitly {@link mts.desk.DeleteTradersResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {mts.desk.IDeleteTradersResponse} message DeleteTradersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTradersResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deletedCount != null && Object.hasOwnProperty.call(message, "deletedCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deletedCount);
                return writer;
            };

            /**
             * Encodes the specified DeleteTradersResponse message, length delimited. Does not implicitly {@link mts.desk.DeleteTradersResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {mts.desk.IDeleteTradersResponse} message DeleteTradersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTradersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteTradersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.DeleteTradersResponse} DeleteTradersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTradersResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.DeleteTradersResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deletedCount = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteTradersResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.DeleteTradersResponse} DeleteTradersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTradersResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteTradersResponse message.
             * @function verify
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteTradersResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deletedCount != null && message.hasOwnProperty("deletedCount"))
                    if (!$util.isInteger(message.deletedCount))
                        return "deletedCount: integer expected";
                return null;
            };

            /**
             * Creates a DeleteTradersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.DeleteTradersResponse} DeleteTradersResponse
             */
            DeleteTradersResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.DeleteTradersResponse)
                    return object;
                let message = new $root.mts.desk.DeleteTradersResponse();
                if (object.deletedCount != null)
                    message.deletedCount = object.deletedCount | 0;
                return message;
            };

            /**
             * Creates a plain object from a DeleteTradersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {mts.desk.DeleteTradersResponse} message DeleteTradersResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteTradersResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.deletedCount = 0;
                if (message.deletedCount != null && message.hasOwnProperty("deletedCount"))
                    object.deletedCount = message.deletedCount;
                return object;
            };

            /**
             * Converts this DeleteTradersResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.DeleteTradersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteTradersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteTradersResponse
             * @function getTypeUrl
             * @memberof mts.desk.DeleteTradersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteTradersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.DeleteTradersResponse";
            };

            return DeleteTradersResponse;
        })();

        desk.DeleteInstancesRequest = (function() {

            /**
             * Properties of a DeleteInstancesRequest.
             * @memberof mts.desk
             * @interface IDeleteInstancesRequest
             * @property {Array.<string>|null} [instanceIds] DeleteInstancesRequest instanceIds
             */

            /**
             * Constructs a new DeleteInstancesRequest.
             * @memberof mts.desk
             * @classdesc Represents a DeleteInstancesRequest.
             * @implements IDeleteInstancesRequest
             * @constructor
             * @param {mts.desk.IDeleteInstancesRequest=} [properties] Properties to set
             */
            function DeleteInstancesRequest(properties) {
                this.instanceIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteInstancesRequest instanceIds.
             * @member {Array.<string>} instanceIds
             * @memberof mts.desk.DeleteInstancesRequest
             * @instance
             */
            DeleteInstancesRequest.prototype.instanceIds = $util.emptyArray;

            /**
             * Creates a new DeleteInstancesRequest instance using the specified properties.
             * @function create
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {mts.desk.IDeleteInstancesRequest=} [properties] Properties to set
             * @returns {mts.desk.DeleteInstancesRequest} DeleteInstancesRequest instance
             */
            DeleteInstancesRequest.create = function create(properties) {
                return new DeleteInstancesRequest(properties);
            };

            /**
             * Encodes the specified DeleteInstancesRequest message. Does not implicitly {@link mts.desk.DeleteInstancesRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {mts.desk.IDeleteInstancesRequest} message DeleteInstancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstancesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.instanceIds != null && message.instanceIds.length)
                    for (let i = 0; i < message.instanceIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceIds[i]);
                return writer;
            };

            /**
             * Encodes the specified DeleteInstancesRequest message, length delimited. Does not implicitly {@link mts.desk.DeleteInstancesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {mts.desk.IDeleteInstancesRequest} message DeleteInstancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstancesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteInstancesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.DeleteInstancesRequest} DeleteInstancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstancesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.DeleteInstancesRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.instanceIds && message.instanceIds.length))
                                message.instanceIds = [];
                            message.instanceIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteInstancesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.DeleteInstancesRequest} DeleteInstancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstancesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteInstancesRequest message.
             * @function verify
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteInstancesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.instanceIds != null && message.hasOwnProperty("instanceIds")) {
                    if (!Array.isArray(message.instanceIds))
                        return "instanceIds: array expected";
                    for (let i = 0; i < message.instanceIds.length; ++i)
                        if (!$util.isString(message.instanceIds[i]))
                            return "instanceIds: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DeleteInstancesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.DeleteInstancesRequest} DeleteInstancesRequest
             */
            DeleteInstancesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.DeleteInstancesRequest)
                    return object;
                let message = new $root.mts.desk.DeleteInstancesRequest();
                if (object.instanceIds) {
                    if (!Array.isArray(object.instanceIds))
                        throw TypeError(".mts.desk.DeleteInstancesRequest.instanceIds: array expected");
                    message.instanceIds = [];
                    for (let i = 0; i < object.instanceIds.length; ++i)
                        message.instanceIds[i] = String(object.instanceIds[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DeleteInstancesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {mts.desk.DeleteInstancesRequest} message DeleteInstancesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteInstancesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.instanceIds = [];
                if (message.instanceIds && message.instanceIds.length) {
                    object.instanceIds = [];
                    for (let j = 0; j < message.instanceIds.length; ++j)
                        object.instanceIds[j] = message.instanceIds[j];
                }
                return object;
            };

            /**
             * Converts this DeleteInstancesRequest to JSON.
             * @function toJSON
             * @memberof mts.desk.DeleteInstancesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteInstancesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteInstancesRequest
             * @function getTypeUrl
             * @memberof mts.desk.DeleteInstancesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteInstancesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.DeleteInstancesRequest";
            };

            return DeleteInstancesRequest;
        })();

        desk.DeleteInstancesResponse = (function() {

            /**
             * Properties of a DeleteInstancesResponse.
             * @memberof mts.desk
             * @interface IDeleteInstancesResponse
             * @property {number|null} [deletedCount] DeleteInstancesResponse deletedCount
             */

            /**
             * Constructs a new DeleteInstancesResponse.
             * @memberof mts.desk
             * @classdesc Represents a DeleteInstancesResponse.
             * @implements IDeleteInstancesResponse
             * @constructor
             * @param {mts.desk.IDeleteInstancesResponse=} [properties] Properties to set
             */
            function DeleteInstancesResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteInstancesResponse deletedCount.
             * @member {number} deletedCount
             * @memberof mts.desk.DeleteInstancesResponse
             * @instance
             */
            DeleteInstancesResponse.prototype.deletedCount = 0;

            /**
             * Creates a new DeleteInstancesResponse instance using the specified properties.
             * @function create
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {mts.desk.IDeleteInstancesResponse=} [properties] Properties to set
             * @returns {mts.desk.DeleteInstancesResponse} DeleteInstancesResponse instance
             */
            DeleteInstancesResponse.create = function create(properties) {
                return new DeleteInstancesResponse(properties);
            };

            /**
             * Encodes the specified DeleteInstancesResponse message. Does not implicitly {@link mts.desk.DeleteInstancesResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {mts.desk.IDeleteInstancesResponse} message DeleteInstancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstancesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deletedCount != null && Object.hasOwnProperty.call(message, "deletedCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deletedCount);
                return writer;
            };

            /**
             * Encodes the specified DeleteInstancesResponse message, length delimited. Does not implicitly {@link mts.desk.DeleteInstancesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {mts.desk.IDeleteInstancesResponse} message DeleteInstancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInstancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteInstancesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.DeleteInstancesResponse} DeleteInstancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstancesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.DeleteInstancesResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deletedCount = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteInstancesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.DeleteInstancesResponse} DeleteInstancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInstancesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteInstancesResponse message.
             * @function verify
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteInstancesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deletedCount != null && message.hasOwnProperty("deletedCount"))
                    if (!$util.isInteger(message.deletedCount))
                        return "deletedCount: integer expected";
                return null;
            };

            /**
             * Creates a DeleteInstancesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.DeleteInstancesResponse} DeleteInstancesResponse
             */
            DeleteInstancesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.DeleteInstancesResponse)
                    return object;
                let message = new $root.mts.desk.DeleteInstancesResponse();
                if (object.deletedCount != null)
                    message.deletedCount = object.deletedCount | 0;
                return message;
            };

            /**
             * Creates a plain object from a DeleteInstancesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {mts.desk.DeleteInstancesResponse} message DeleteInstancesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteInstancesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.deletedCount = 0;
                if (message.deletedCount != null && message.hasOwnProperty("deletedCount"))
                    object.deletedCount = message.deletedCount;
                return object;
            };

            /**
             * Converts this DeleteInstancesResponse to JSON.
             * @function toJSON
             * @memberof mts.desk.DeleteInstancesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteInstancesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteInstancesResponse
             * @function getTypeUrl
             * @memberof mts.desk.DeleteInstancesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteInstancesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.DeleteInstancesResponse";
            };

            return DeleteInstancesResponse;
        })();

        desk.OrderOptions = (function() {

            /**
             * Properties of an OrderOptions.
             * @memberof mts.desk
             * @interface IOrderOptions
             * @property {number|null} [manualFillPrice] OrderOptions manualFillPrice
             * @property {string|null} [tradeId] OrderOptions tradeId
             */

            /**
             * Constructs a new OrderOptions.
             * @memberof mts.desk
             * @classdesc Represents an OrderOptions.
             * @implements IOrderOptions
             * @constructor
             * @param {mts.desk.IOrderOptions=} [properties] Properties to set
             */
            function OrderOptions(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderOptions manualFillPrice.
             * @member {number} manualFillPrice
             * @memberof mts.desk.OrderOptions
             * @instance
             */
            OrderOptions.prototype.manualFillPrice = 0;

            /**
             * OrderOptions tradeId.
             * @member {string} tradeId
             * @memberof mts.desk.OrderOptions
             * @instance
             */
            OrderOptions.prototype.tradeId = "";

            /**
             * Creates a new OrderOptions instance using the specified properties.
             * @function create
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {mts.desk.IOrderOptions=} [properties] Properties to set
             * @returns {mts.desk.OrderOptions} OrderOptions instance
             */
            OrderOptions.create = function create(properties) {
                return new OrderOptions(properties);
            };

            /**
             * Encodes the specified OrderOptions message. Does not implicitly {@link mts.desk.OrderOptions.verify|verify} messages.
             * @function encode
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {mts.desk.IOrderOptions} message OrderOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.manualFillPrice != null && Object.hasOwnProperty.call(message, "manualFillPrice"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.manualFillPrice);
                if (message.tradeId != null && Object.hasOwnProperty.call(message, "tradeId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.tradeId);
                return writer;
            };

            /**
             * Encodes the specified OrderOptions message, length delimited. Does not implicitly {@link mts.desk.OrderOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {mts.desk.IOrderOptions} message OrderOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrderOptions message from the specified reader or buffer.
             * @function decode
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.desk.OrderOptions} OrderOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.OrderOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.manualFillPrice = reader.double();
                            break;
                        }
                    case 2: {
                            message.tradeId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrderOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.desk.OrderOptions} OrderOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrderOptions message.
             * @function verify
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.manualFillPrice != null && message.hasOwnProperty("manualFillPrice"))
                    if (typeof message.manualFillPrice !== "number")
                        return "manualFillPrice: number expected";
                if (message.tradeId != null && message.hasOwnProperty("tradeId"))
                    if (!$util.isString(message.tradeId))
                        return "tradeId: string expected";
                return null;
            };

            /**
             * Creates an OrderOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.desk.OrderOptions} OrderOptions
             */
            OrderOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.desk.OrderOptions)
                    return object;
                let message = new $root.mts.desk.OrderOptions();
                if (object.manualFillPrice != null)
                    message.manualFillPrice = Number(object.manualFillPrice);
                if (object.tradeId != null)
                    message.tradeId = String(object.tradeId);
                return message;
            };

            /**
             * Creates a plain object from an OrderOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {mts.desk.OrderOptions} message OrderOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.manualFillPrice = 0;
                    object.tradeId = "";
                }
                if (message.manualFillPrice != null && message.hasOwnProperty("manualFillPrice"))
                    object.manualFillPrice = options.json && !isFinite(message.manualFillPrice) ? String(message.manualFillPrice) : message.manualFillPrice;
                if (message.tradeId != null && message.hasOwnProperty("tradeId"))
                    object.tradeId = message.tradeId;
                return object;
            };

            /**
             * Converts this OrderOptions to JSON.
             * @function toJSON
             * @memberof mts.desk.OrderOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrderOptions
             * @function getTypeUrl
             * @memberof mts.desk.OrderOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrderOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.desk.OrderOptions";
            };

            return OrderOptions;
        })();

        desk.http = (function() {

            /**
             * Namespace http.
             * @memberof mts.desk
             * @namespace
             */
            const http = {};

            http.GetTraderUpdatesResponse = (function() {

                /**
                 * Properties of a GetTraderUpdatesResponse.
                 * @memberof mts.desk.http
                 * @interface IGetTraderUpdatesResponse
                 * @property {string|null} [traderId] GetTraderUpdatesResponse traderId
                 * @property {mts.desk.ITrader|null} [trader] GetTraderUpdatesResponse trader
                 */

                /**
                 * Constructs a new GetTraderUpdatesResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a GetTraderUpdatesResponse.
                 * @implements IGetTraderUpdatesResponse
                 * @constructor
                 * @param {mts.desk.http.IGetTraderUpdatesResponse=} [properties] Properties to set
                 */
                function GetTraderUpdatesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTraderUpdatesResponse traderId.
                 * @member {string} traderId
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @instance
                 */
                GetTraderUpdatesResponse.prototype.traderId = "";

                /**
                 * GetTraderUpdatesResponse trader.
                 * @member {mts.desk.ITrader|null|undefined} trader
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @instance
                 */
                GetTraderUpdatesResponse.prototype.trader = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * GetTraderUpdatesResponse updateType.
                 * @member {"trader"|undefined} updateType
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @instance
                 */
                Object.defineProperty(GetTraderUpdatesResponse.prototype, "updateType", {
                    get: $util.oneOfGetter($oneOfFields = ["trader"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new GetTraderUpdatesResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {mts.desk.http.IGetTraderUpdatesResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.GetTraderUpdatesResponse} GetTraderUpdatesResponse instance
                 */
                GetTraderUpdatesResponse.create = function create(properties) {
                    return new GetTraderUpdatesResponse(properties);
                };

                /**
                 * Encodes the specified GetTraderUpdatesResponse message. Does not implicitly {@link mts.desk.http.GetTraderUpdatesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {mts.desk.http.IGetTraderUpdatesResponse} message GetTraderUpdatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTraderUpdatesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.traderId != null && Object.hasOwnProperty.call(message, "traderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderId);
                    if (message.trader != null && Object.hasOwnProperty.call(message, "trader"))
                        $root.mts.desk.Trader.encode(message.trader, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetTraderUpdatesResponse message, length delimited. Does not implicitly {@link mts.desk.http.GetTraderUpdatesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {mts.desk.http.IGetTraderUpdatesResponse} message GetTraderUpdatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTraderUpdatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetTraderUpdatesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.GetTraderUpdatesResponse} GetTraderUpdatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTraderUpdatesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetTraderUpdatesResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.traderId = reader.string();
                                break;
                            }
                        case 10: {
                                message.trader = $root.mts.desk.Trader.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetTraderUpdatesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.GetTraderUpdatesResponse} GetTraderUpdatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTraderUpdatesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetTraderUpdatesResponse message.
                 * @function verify
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTraderUpdatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.traderId != null && message.hasOwnProperty("traderId"))
                        if (!$util.isString(message.traderId))
                            return "traderId: string expected";
                    if (message.trader != null && message.hasOwnProperty("trader")) {
                        properties.updateType = 1;
                        {
                            let error = $root.mts.desk.Trader.verify(message.trader);
                            if (error)
                                return "trader." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetTraderUpdatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.GetTraderUpdatesResponse} GetTraderUpdatesResponse
                 */
                GetTraderUpdatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.GetTraderUpdatesResponse)
                        return object;
                    let message = new $root.mts.desk.http.GetTraderUpdatesResponse();
                    if (object.traderId != null)
                        message.traderId = String(object.traderId);
                    if (object.trader != null) {
                        if (typeof object.trader !== "object")
                            throw TypeError(".mts.desk.http.GetTraderUpdatesResponse.trader: object expected");
                        message.trader = $root.mts.desk.Trader.fromObject(object.trader);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetTraderUpdatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {mts.desk.http.GetTraderUpdatesResponse} message GetTraderUpdatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTraderUpdatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.traderId = "";
                    if (message.traderId != null && message.hasOwnProperty("traderId"))
                        object.traderId = message.traderId;
                    if (message.trader != null && message.hasOwnProperty("trader")) {
                        object.trader = $root.mts.desk.Trader.toObject(message.trader, options);
                        if (options.oneofs)
                            object.updateType = "trader";
                    }
                    return object;
                };

                /**
                 * Converts this GetTraderUpdatesResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTraderUpdatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetTraderUpdatesResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.GetTraderUpdatesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTraderUpdatesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.GetTraderUpdatesResponse";
                };

                return GetTraderUpdatesResponse;
            })();

            http.GetTraderInstanceUpdatesResponse = (function() {

                /**
                 * Properties of a GetTraderInstanceUpdatesResponse.
                 * @memberof mts.desk.http
                 * @interface IGetTraderInstanceUpdatesResponse
                 * @property {string|null} [traderInstanceId] GetTraderInstanceUpdatesResponse traderInstanceId
                 * @property {number|Long|null} [sequence] GetTraderInstanceUpdatesResponse sequence
                 * @property {mts.desk.ITraderInstance|null} [traderInstance] GetTraderInstanceUpdatesResponse traderInstance
                 * @property {mts.desk.events.ITraderInstanceStateChange|null} [stateChange] GetTraderInstanceUpdatesResponse stateChange
                 * @property {mts.desk.events.ITickEvent|null} [tickEvent] GetTraderInstanceUpdatesResponse tickEvent
                 * @property {mts.desk.ITraderInstanceOutput|null} [instanceOutput] GetTraderInstanceUpdatesResponse instanceOutput
                 * @property {mts.desk.http.IHeartbeat|null} [heartbeat] GetTraderInstanceUpdatesResponse heartbeat
                 * @property {mts.desk.events.IWatchlistCreated|null} [watchlistCreated] GetTraderInstanceUpdatesResponse watchlistCreated
                 */

                /**
                 * Constructs a new GetTraderInstanceUpdatesResponse.
                 * @memberof mts.desk.http
                 * @classdesc GET /traderinstance-events/{instanceId} SSE event stream message
                 * We don't send desk events directly because this may combine messages from multiple sources
                 * @implements IGetTraderInstanceUpdatesResponse
                 * @constructor
                 * @param {mts.desk.http.IGetTraderInstanceUpdatesResponse=} [properties] Properties to set
                 */
                function GetTraderInstanceUpdatesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTraderInstanceUpdatesResponse traderInstanceId.
                 * @member {string} traderInstanceId
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.traderInstanceId = "";

                /**
                 * GetTraderInstanceUpdatesResponse sequence.
                 * @member {number|Long} sequence
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.sequence = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * GetTraderInstanceUpdatesResponse traderInstance.
                 * @member {mts.desk.ITraderInstance|null|undefined} traderInstance
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.traderInstance = null;

                /**
                 * GetTraderInstanceUpdatesResponse stateChange.
                 * @member {mts.desk.events.ITraderInstanceStateChange|null|undefined} stateChange
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.stateChange = null;

                /**
                 * GetTraderInstanceUpdatesResponse tickEvent.
                 * @member {mts.desk.events.ITickEvent|null|undefined} tickEvent
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.tickEvent = null;

                /**
                 * GetTraderInstanceUpdatesResponse instanceOutput.
                 * @member {mts.desk.ITraderInstanceOutput|null|undefined} instanceOutput
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.instanceOutput = null;

                /**
                 * GetTraderInstanceUpdatesResponse heartbeat.
                 * @member {mts.desk.http.IHeartbeat|null|undefined} heartbeat
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.heartbeat = null;

                /**
                 * GetTraderInstanceUpdatesResponse watchlistCreated.
                 * @member {mts.desk.events.IWatchlistCreated|null|undefined} watchlistCreated
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                GetTraderInstanceUpdatesResponse.prototype.watchlistCreated = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * GetTraderInstanceUpdatesResponse updateType.
                 * @member {"traderInstance"|"stateChange"|"tickEvent"|"instanceOutput"|"heartbeat"|"watchlistCreated"|undefined} updateType
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 */
                Object.defineProperty(GetTraderInstanceUpdatesResponse.prototype, "updateType", {
                    get: $util.oneOfGetter($oneOfFields = ["traderInstance", "stateChange", "tickEvent", "instanceOutput", "heartbeat", "watchlistCreated"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new GetTraderInstanceUpdatesResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {mts.desk.http.IGetTraderInstanceUpdatesResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.GetTraderInstanceUpdatesResponse} GetTraderInstanceUpdatesResponse instance
                 */
                GetTraderInstanceUpdatesResponse.create = function create(properties) {
                    return new GetTraderInstanceUpdatesResponse(properties);
                };

                /**
                 * Encodes the specified GetTraderInstanceUpdatesResponse message. Does not implicitly {@link mts.desk.http.GetTraderInstanceUpdatesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {mts.desk.http.IGetTraderInstanceUpdatesResponse} message GetTraderInstanceUpdatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTraderInstanceUpdatesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.traderInstanceId != null && Object.hasOwnProperty.call(message, "traderInstanceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderInstanceId);
                    if (message.sequence != null && Object.hasOwnProperty.call(message, "sequence"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sequence);
                    if (message.traderInstance != null && Object.hasOwnProperty.call(message, "traderInstance"))
                        $root.mts.desk.TraderInstance.encode(message.traderInstance, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.stateChange != null && Object.hasOwnProperty.call(message, "stateChange"))
                        $root.mts.desk.events.TraderInstanceStateChange.encode(message.stateChange, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.tickEvent != null && Object.hasOwnProperty.call(message, "tickEvent"))
                        $root.mts.desk.events.TickEvent.encode(message.tickEvent, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.instanceOutput != null && Object.hasOwnProperty.call(message, "instanceOutput"))
                        $root.mts.desk.TraderInstanceOutput.encode(message.instanceOutput, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.heartbeat != null && Object.hasOwnProperty.call(message, "heartbeat"))
                        $root.mts.desk.http.Heartbeat.encode(message.heartbeat, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.watchlistCreated != null && Object.hasOwnProperty.call(message, "watchlistCreated"))
                        $root.mts.desk.events.WatchlistCreated.encode(message.watchlistCreated, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetTraderInstanceUpdatesResponse message, length delimited. Does not implicitly {@link mts.desk.http.GetTraderInstanceUpdatesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {mts.desk.http.IGetTraderInstanceUpdatesResponse} message GetTraderInstanceUpdatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTraderInstanceUpdatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetTraderInstanceUpdatesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.GetTraderInstanceUpdatesResponse} GetTraderInstanceUpdatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTraderInstanceUpdatesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetTraderInstanceUpdatesResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.traderInstanceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.sequence = reader.int64();
                                break;
                            }
                        case 10: {
                                message.traderInstance = $root.mts.desk.TraderInstance.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.stateChange = $root.mts.desk.events.TraderInstanceStateChange.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.tickEvent = $root.mts.desk.events.TickEvent.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.instanceOutput = $root.mts.desk.TraderInstanceOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.heartbeat = $root.mts.desk.http.Heartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.watchlistCreated = $root.mts.desk.events.WatchlistCreated.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetTraderInstanceUpdatesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.GetTraderInstanceUpdatesResponse} GetTraderInstanceUpdatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTraderInstanceUpdatesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetTraderInstanceUpdatesResponse message.
                 * @function verify
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTraderInstanceUpdatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.traderInstanceId != null && message.hasOwnProperty("traderInstanceId"))
                        if (!$util.isString(message.traderInstanceId))
                            return "traderInstanceId: string expected";
                    if (message.sequence != null && message.hasOwnProperty("sequence"))
                        if (!$util.isInteger(message.sequence) && !(message.sequence && $util.isInteger(message.sequence.low) && $util.isInteger(message.sequence.high)))
                            return "sequence: integer|Long expected";
                    if (message.traderInstance != null && message.hasOwnProperty("traderInstance")) {
                        properties.updateType = 1;
                        {
                            let error = $root.mts.desk.TraderInstance.verify(message.traderInstance);
                            if (error)
                                return "traderInstance." + error;
                        }
                    }
                    if (message.stateChange != null && message.hasOwnProperty("stateChange")) {
                        if (properties.updateType === 1)
                            return "updateType: multiple values";
                        properties.updateType = 1;
                        {
                            let error = $root.mts.desk.events.TraderInstanceStateChange.verify(message.stateChange);
                            if (error)
                                return "stateChange." + error;
                        }
                    }
                    if (message.tickEvent != null && message.hasOwnProperty("tickEvent")) {
                        if (properties.updateType === 1)
                            return "updateType: multiple values";
                        properties.updateType = 1;
                        {
                            let error = $root.mts.desk.events.TickEvent.verify(message.tickEvent);
                            if (error)
                                return "tickEvent." + error;
                        }
                    }
                    if (message.instanceOutput != null && message.hasOwnProperty("instanceOutput")) {
                        if (properties.updateType === 1)
                            return "updateType: multiple values";
                        properties.updateType = 1;
                        {
                            let error = $root.mts.desk.TraderInstanceOutput.verify(message.instanceOutput);
                            if (error)
                                return "instanceOutput." + error;
                        }
                    }
                    if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                        if (properties.updateType === 1)
                            return "updateType: multiple values";
                        properties.updateType = 1;
                        {
                            let error = $root.mts.desk.http.Heartbeat.verify(message.heartbeat);
                            if (error)
                                return "heartbeat." + error;
                        }
                    }
                    if (message.watchlistCreated != null && message.hasOwnProperty("watchlistCreated")) {
                        if (properties.updateType === 1)
                            return "updateType: multiple values";
                        properties.updateType = 1;
                        {
                            let error = $root.mts.desk.events.WatchlistCreated.verify(message.watchlistCreated);
                            if (error)
                                return "watchlistCreated." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetTraderInstanceUpdatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.GetTraderInstanceUpdatesResponse} GetTraderInstanceUpdatesResponse
                 */
                GetTraderInstanceUpdatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.GetTraderInstanceUpdatesResponse)
                        return object;
                    let message = new $root.mts.desk.http.GetTraderInstanceUpdatesResponse();
                    if (object.traderInstanceId != null)
                        message.traderInstanceId = String(object.traderInstanceId);
                    if (object.sequence != null)
                        if ($util.Long)
                            (message.sequence = $util.Long.fromValue(object.sequence)).unsigned = false;
                        else if (typeof object.sequence === "string")
                            message.sequence = parseInt(object.sequence, 10);
                        else if (typeof object.sequence === "number")
                            message.sequence = object.sequence;
                        else if (typeof object.sequence === "object")
                            message.sequence = new $util.LongBits(object.sequence.low >>> 0, object.sequence.high >>> 0).toNumber();
                    if (object.traderInstance != null) {
                        if (typeof object.traderInstance !== "object")
                            throw TypeError(".mts.desk.http.GetTraderInstanceUpdatesResponse.traderInstance: object expected");
                        message.traderInstance = $root.mts.desk.TraderInstance.fromObject(object.traderInstance);
                    }
                    if (object.stateChange != null) {
                        if (typeof object.stateChange !== "object")
                            throw TypeError(".mts.desk.http.GetTraderInstanceUpdatesResponse.stateChange: object expected");
                        message.stateChange = $root.mts.desk.events.TraderInstanceStateChange.fromObject(object.stateChange);
                    }
                    if (object.tickEvent != null) {
                        if (typeof object.tickEvent !== "object")
                            throw TypeError(".mts.desk.http.GetTraderInstanceUpdatesResponse.tickEvent: object expected");
                        message.tickEvent = $root.mts.desk.events.TickEvent.fromObject(object.tickEvent);
                    }
                    if (object.instanceOutput != null) {
                        if (typeof object.instanceOutput !== "object")
                            throw TypeError(".mts.desk.http.GetTraderInstanceUpdatesResponse.instanceOutput: object expected");
                        message.instanceOutput = $root.mts.desk.TraderInstanceOutput.fromObject(object.instanceOutput);
                    }
                    if (object.heartbeat != null) {
                        if (typeof object.heartbeat !== "object")
                            throw TypeError(".mts.desk.http.GetTraderInstanceUpdatesResponse.heartbeat: object expected");
                        message.heartbeat = $root.mts.desk.http.Heartbeat.fromObject(object.heartbeat);
                    }
                    if (object.watchlistCreated != null) {
                        if (typeof object.watchlistCreated !== "object")
                            throw TypeError(".mts.desk.http.GetTraderInstanceUpdatesResponse.watchlistCreated: object expected");
                        message.watchlistCreated = $root.mts.desk.events.WatchlistCreated.fromObject(object.watchlistCreated);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetTraderInstanceUpdatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {mts.desk.http.GetTraderInstanceUpdatesResponse} message GetTraderInstanceUpdatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTraderInstanceUpdatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.traderInstanceId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sequence = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sequence = options.longs === String ? "0" : 0;
                    }
                    if (message.traderInstanceId != null && message.hasOwnProperty("traderInstanceId"))
                        object.traderInstanceId = message.traderInstanceId;
                    if (message.sequence != null && message.hasOwnProperty("sequence"))
                        if (typeof message.sequence === "number")
                            object.sequence = options.longs === String ? String(message.sequence) : message.sequence;
                        else
                            object.sequence = options.longs === String ? $util.Long.prototype.toString.call(message.sequence) : options.longs === Number ? new $util.LongBits(message.sequence.low >>> 0, message.sequence.high >>> 0).toNumber() : message.sequence;
                    if (message.traderInstance != null && message.hasOwnProperty("traderInstance")) {
                        object.traderInstance = $root.mts.desk.TraderInstance.toObject(message.traderInstance, options);
                        if (options.oneofs)
                            object.updateType = "traderInstance";
                    }
                    if (message.stateChange != null && message.hasOwnProperty("stateChange")) {
                        object.stateChange = $root.mts.desk.events.TraderInstanceStateChange.toObject(message.stateChange, options);
                        if (options.oneofs)
                            object.updateType = "stateChange";
                    }
                    if (message.tickEvent != null && message.hasOwnProperty("tickEvent")) {
                        object.tickEvent = $root.mts.desk.events.TickEvent.toObject(message.tickEvent, options);
                        if (options.oneofs)
                            object.updateType = "tickEvent";
                    }
                    if (message.instanceOutput != null && message.hasOwnProperty("instanceOutput")) {
                        object.instanceOutput = $root.mts.desk.TraderInstanceOutput.toObject(message.instanceOutput, options);
                        if (options.oneofs)
                            object.updateType = "instanceOutput";
                    }
                    if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                        object.heartbeat = $root.mts.desk.http.Heartbeat.toObject(message.heartbeat, options);
                        if (options.oneofs)
                            object.updateType = "heartbeat";
                    }
                    if (message.watchlistCreated != null && message.hasOwnProperty("watchlistCreated")) {
                        object.watchlistCreated = $root.mts.desk.events.WatchlistCreated.toObject(message.watchlistCreated, options);
                        if (options.oneofs)
                            object.updateType = "watchlistCreated";
                    }
                    return object;
                };

                /**
                 * Converts this GetTraderInstanceUpdatesResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTraderInstanceUpdatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetTraderInstanceUpdatesResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.GetTraderInstanceUpdatesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTraderInstanceUpdatesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.GetTraderInstanceUpdatesResponse";
                };

                return GetTraderInstanceUpdatesResponse;
            })();

            http.Heartbeat = (function() {

                /**
                 * Properties of a Heartbeat.
                 * @memberof mts.desk.http
                 * @interface IHeartbeat
                 * @property {number|Long|null} [time] Heartbeat time
                 * @property {string|null} [date] Heartbeat date
                 */

                /**
                 * Constructs a new Heartbeat.
                 * @memberof mts.desk.http
                 * @classdesc Represents a Heartbeat.
                 * @implements IHeartbeat
                 * @constructor
                 * @param {mts.desk.http.IHeartbeat=} [properties] Properties to set
                 */
                function Heartbeat(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Heartbeat time.
                 * @member {number|Long} time
                 * @memberof mts.desk.http.Heartbeat
                 * @instance
                 */
                Heartbeat.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Heartbeat date.
                 * @member {string} date
                 * @memberof mts.desk.http.Heartbeat
                 * @instance
                 */
                Heartbeat.prototype.date = "";

                /**
                 * Creates a new Heartbeat instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {mts.desk.http.IHeartbeat=} [properties] Properties to set
                 * @returns {mts.desk.http.Heartbeat} Heartbeat instance
                 */
                Heartbeat.create = function create(properties) {
                    return new Heartbeat(properties);
                };

                /**
                 * Encodes the specified Heartbeat message. Does not implicitly {@link mts.desk.http.Heartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {mts.desk.http.IHeartbeat} message Heartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Heartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
                    return writer;
                };

                /**
                 * Encodes the specified Heartbeat message, length delimited. Does not implicitly {@link mts.desk.http.Heartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {mts.desk.http.IHeartbeat} message Heartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Heartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Heartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.Heartbeat} Heartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Heartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.Heartbeat();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        case 2: {
                                message.date = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Heartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.Heartbeat} Heartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Heartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Heartbeat message.
                 * @function verify
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Heartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    return null;
                };

                /**
                 * Creates a Heartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.Heartbeat} Heartbeat
                 */
                Heartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.Heartbeat)
                        return object;
                    let message = new $root.mts.desk.http.Heartbeat();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.date != null)
                        message.date = String(object.date);
                    return message;
                };

                /**
                 * Creates a plain object from a Heartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {mts.desk.http.Heartbeat} message Heartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Heartbeat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                        object.date = "";
                    }
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    return object;
                };

                /**
                 * Converts this Heartbeat to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.Heartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Heartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Heartbeat
                 * @function getTypeUrl
                 * @memberof mts.desk.http.Heartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Heartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.Heartbeat";
                };

                return Heartbeat;
            })();

            http.CheckScriptSyntaxRequest = (function() {

                /**
                 * Properties of a CheckScriptSyntaxRequest.
                 * @memberof mts.desk.http
                 * @interface ICheckScriptSyntaxRequest
                 * @property {string|null} [code] CheckScriptSyntaxRequest code
                 */

                /**
                 * Constructs a new CheckScriptSyntaxRequest.
                 * @memberof mts.desk.http
                 * @classdesc Represents a CheckScriptSyntaxRequest.
                 * @implements ICheckScriptSyntaxRequest
                 * @constructor
                 * @param {mts.desk.http.ICheckScriptSyntaxRequest=} [properties] Properties to set
                 */
                function CheckScriptSyntaxRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CheckScriptSyntaxRequest code.
                 * @member {string} code
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @instance
                 */
                CheckScriptSyntaxRequest.prototype.code = "";

                /**
                 * Creates a new CheckScriptSyntaxRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {mts.desk.http.ICheckScriptSyntaxRequest=} [properties] Properties to set
                 * @returns {mts.desk.http.CheckScriptSyntaxRequest} CheckScriptSyntaxRequest instance
                 */
                CheckScriptSyntaxRequest.create = function create(properties) {
                    return new CheckScriptSyntaxRequest(properties);
                };

                /**
                 * Encodes the specified CheckScriptSyntaxRequest message. Does not implicitly {@link mts.desk.http.CheckScriptSyntaxRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {mts.desk.http.ICheckScriptSyntaxRequest} message CheckScriptSyntaxRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CheckScriptSyntaxRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                    return writer;
                };

                /**
                 * Encodes the specified CheckScriptSyntaxRequest message, length delimited. Does not implicitly {@link mts.desk.http.CheckScriptSyntaxRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {mts.desk.http.ICheckScriptSyntaxRequest} message CheckScriptSyntaxRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CheckScriptSyntaxRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CheckScriptSyntaxRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.CheckScriptSyntaxRequest} CheckScriptSyntaxRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CheckScriptSyntaxRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.CheckScriptSyntaxRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.code = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CheckScriptSyntaxRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.CheckScriptSyntaxRequest} CheckScriptSyntaxRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CheckScriptSyntaxRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CheckScriptSyntaxRequest message.
                 * @function verify
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CheckScriptSyntaxRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    return null;
                };

                /**
                 * Creates a CheckScriptSyntaxRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.CheckScriptSyntaxRequest} CheckScriptSyntaxRequest
                 */
                CheckScriptSyntaxRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.CheckScriptSyntaxRequest)
                        return object;
                    let message = new $root.mts.desk.http.CheckScriptSyntaxRequest();
                    if (object.code != null)
                        message.code = String(object.code);
                    return message;
                };

                /**
                 * Creates a plain object from a CheckScriptSyntaxRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {mts.desk.http.CheckScriptSyntaxRequest} message CheckScriptSyntaxRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CheckScriptSyntaxRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.code = "";
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    return object;
                };

                /**
                 * Converts this CheckScriptSyntaxRequest to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CheckScriptSyntaxRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CheckScriptSyntaxRequest
                 * @function getTypeUrl
                 * @memberof mts.desk.http.CheckScriptSyntaxRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CheckScriptSyntaxRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.CheckScriptSyntaxRequest";
                };

                return CheckScriptSyntaxRequest;
            })();

            http.CheckScriptSyntaxResponse = (function() {

                /**
                 * Properties of a CheckScriptSyntaxResponse.
                 * @memberof mts.desk.http
                 * @interface ICheckScriptSyntaxResponse
                 * @property {boolean|null} [success] CheckScriptSyntaxResponse success
                 * @property {string|null} [errorMessage] CheckScriptSyntaxResponse errorMessage
                 * @property {number|null} [lineNumber] CheckScriptSyntaxResponse lineNumber
                 */

                /**
                 * Constructs a new CheckScriptSyntaxResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a CheckScriptSyntaxResponse.
                 * @implements ICheckScriptSyntaxResponse
                 * @constructor
                 * @param {mts.desk.http.ICheckScriptSyntaxResponse=} [properties] Properties to set
                 */
                function CheckScriptSyntaxResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CheckScriptSyntaxResponse success.
                 * @member {boolean} success
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @instance
                 */
                CheckScriptSyntaxResponse.prototype.success = false;

                /**
                 * CheckScriptSyntaxResponse errorMessage.
                 * @member {string} errorMessage
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @instance
                 */
                CheckScriptSyntaxResponse.prototype.errorMessage = "";

                /**
                 * CheckScriptSyntaxResponse lineNumber.
                 * @member {number} lineNumber
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @instance
                 */
                CheckScriptSyntaxResponse.prototype.lineNumber = 0;

                /**
                 * Creates a new CheckScriptSyntaxResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {mts.desk.http.ICheckScriptSyntaxResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.CheckScriptSyntaxResponse} CheckScriptSyntaxResponse instance
                 */
                CheckScriptSyntaxResponse.create = function create(properties) {
                    return new CheckScriptSyntaxResponse(properties);
                };

                /**
                 * Encodes the specified CheckScriptSyntaxResponse message. Does not implicitly {@link mts.desk.http.CheckScriptSyntaxResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {mts.desk.http.ICheckScriptSyntaxResponse} message CheckScriptSyntaxResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CheckScriptSyntaxResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
                    if (message.lineNumber != null && Object.hasOwnProperty.call(message, "lineNumber"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.lineNumber);
                    return writer;
                };

                /**
                 * Encodes the specified CheckScriptSyntaxResponse message, length delimited. Does not implicitly {@link mts.desk.http.CheckScriptSyntaxResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {mts.desk.http.ICheckScriptSyntaxResponse} message CheckScriptSyntaxResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CheckScriptSyntaxResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CheckScriptSyntaxResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.CheckScriptSyntaxResponse} CheckScriptSyntaxResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CheckScriptSyntaxResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.CheckScriptSyntaxResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.success = reader.bool();
                                break;
                            }
                        case 2: {
                                message.errorMessage = reader.string();
                                break;
                            }
                        case 3: {
                                message.lineNumber = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CheckScriptSyntaxResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.CheckScriptSyntaxResponse} CheckScriptSyntaxResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CheckScriptSyntaxResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CheckScriptSyntaxResponse message.
                 * @function verify
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CheckScriptSyntaxResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.success != null && message.hasOwnProperty("success"))
                        if (typeof message.success !== "boolean")
                            return "success: boolean expected";
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    if (message.lineNumber != null && message.hasOwnProperty("lineNumber"))
                        if (!$util.isInteger(message.lineNumber))
                            return "lineNumber: integer expected";
                    return null;
                };

                /**
                 * Creates a CheckScriptSyntaxResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.CheckScriptSyntaxResponse} CheckScriptSyntaxResponse
                 */
                CheckScriptSyntaxResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.CheckScriptSyntaxResponse)
                        return object;
                    let message = new $root.mts.desk.http.CheckScriptSyntaxResponse();
                    if (object.success != null)
                        message.success = Boolean(object.success);
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    if (object.lineNumber != null)
                        message.lineNumber = object.lineNumber | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a CheckScriptSyntaxResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {mts.desk.http.CheckScriptSyntaxResponse} message CheckScriptSyntaxResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CheckScriptSyntaxResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.success = false;
                        object.errorMessage = "";
                        object.lineNumber = 0;
                    }
                    if (message.success != null && message.hasOwnProperty("success"))
                        object.success = message.success;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    if (message.lineNumber != null && message.hasOwnProperty("lineNumber"))
                        object.lineNumber = message.lineNumber;
                    return object;
                };

                /**
                 * Converts this CheckScriptSyntaxResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CheckScriptSyntaxResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CheckScriptSyntaxResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.CheckScriptSyntaxResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CheckScriptSyntaxResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.CheckScriptSyntaxResponse";
                };

                return CheckScriptSyntaxResponse;
            })();

            http.PostScriptRequest = (function() {

                /**
                 * Properties of a PostScriptRequest.
                 * @memberof mts.desk.http
                 * @interface IPostScriptRequest
                 * @property {mts.desk.ITrader|null} [trader] PostScriptRequest trader
                 * @property {string|null} [code] PostScriptRequest code
                 */

                /**
                 * Constructs a new PostScriptRequest.
                 * @memberof mts.desk.http
                 * @classdesc Represents a PostScriptRequest.
                 * @implements IPostScriptRequest
                 * @constructor
                 * @param {mts.desk.http.IPostScriptRequest=} [properties] Properties to set
                 */
                function PostScriptRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PostScriptRequest trader.
                 * @member {mts.desk.ITrader|null|undefined} trader
                 * @memberof mts.desk.http.PostScriptRequest
                 * @instance
                 */
                PostScriptRequest.prototype.trader = null;

                /**
                 * PostScriptRequest code.
                 * @member {string} code
                 * @memberof mts.desk.http.PostScriptRequest
                 * @instance
                 */
                PostScriptRequest.prototype.code = "";

                /**
                 * Creates a new PostScriptRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {mts.desk.http.IPostScriptRequest=} [properties] Properties to set
                 * @returns {mts.desk.http.PostScriptRequest} PostScriptRequest instance
                 */
                PostScriptRequest.create = function create(properties) {
                    return new PostScriptRequest(properties);
                };

                /**
                 * Encodes the specified PostScriptRequest message. Does not implicitly {@link mts.desk.http.PostScriptRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {mts.desk.http.IPostScriptRequest} message PostScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostScriptRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.trader != null && Object.hasOwnProperty.call(message, "trader"))
                        $root.mts.desk.Trader.encode(message.trader, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                    return writer;
                };

                /**
                 * Encodes the specified PostScriptRequest message, length delimited. Does not implicitly {@link mts.desk.http.PostScriptRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {mts.desk.http.IPostScriptRequest} message PostScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostScriptRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PostScriptRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.PostScriptRequest} PostScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostScriptRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.PostScriptRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.trader = $root.mts.desk.Trader.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.code = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PostScriptRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.PostScriptRequest} PostScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostScriptRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PostScriptRequest message.
                 * @function verify
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PostScriptRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.trader != null && message.hasOwnProperty("trader")) {
                        let error = $root.mts.desk.Trader.verify(message.trader);
                        if (error)
                            return "trader." + error;
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    return null;
                };

                /**
                 * Creates a PostScriptRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.PostScriptRequest} PostScriptRequest
                 */
                PostScriptRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.PostScriptRequest)
                        return object;
                    let message = new $root.mts.desk.http.PostScriptRequest();
                    if (object.trader != null) {
                        if (typeof object.trader !== "object")
                            throw TypeError(".mts.desk.http.PostScriptRequest.trader: object expected");
                        message.trader = $root.mts.desk.Trader.fromObject(object.trader);
                    }
                    if (object.code != null)
                        message.code = String(object.code);
                    return message;
                };

                /**
                 * Creates a plain object from a PostScriptRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {mts.desk.http.PostScriptRequest} message PostScriptRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PostScriptRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.trader = null;
                        object.code = "";
                    }
                    if (message.trader != null && message.hasOwnProperty("trader"))
                        object.trader = $root.mts.desk.Trader.toObject(message.trader, options);
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    return object;
                };

                /**
                 * Converts this PostScriptRequest to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.PostScriptRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PostScriptRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PostScriptRequest
                 * @function getTypeUrl
                 * @memberof mts.desk.http.PostScriptRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PostScriptRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.PostScriptRequest";
                };

                return PostScriptRequest;
            })();

            http.PostScriptResponse = (function() {

                /**
                 * Properties of a PostScriptResponse.
                 * @memberof mts.desk.http
                 * @interface IPostScriptResponse
                 * @property {number|null} [status] PostScriptResponse status
                 * @property {string|null} [stdout] PostScriptResponse stdout
                 * @property {string|null} [stderr] PostScriptResponse stderr
                 * @property {number|null} [elapsedTimeMillis] PostScriptResponse elapsedTimeMillis
                 */

                /**
                 * Constructs a new PostScriptResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a PostScriptResponse.
                 * @implements IPostScriptResponse
                 * @constructor
                 * @param {mts.desk.http.IPostScriptResponse=} [properties] Properties to set
                 */
                function PostScriptResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PostScriptResponse status.
                 * @member {number} status
                 * @memberof mts.desk.http.PostScriptResponse
                 * @instance
                 */
                PostScriptResponse.prototype.status = 0;

                /**
                 * PostScriptResponse stdout.
                 * @member {string} stdout
                 * @memberof mts.desk.http.PostScriptResponse
                 * @instance
                 */
                PostScriptResponse.prototype.stdout = "";

                /**
                 * PostScriptResponse stderr.
                 * @member {string} stderr
                 * @memberof mts.desk.http.PostScriptResponse
                 * @instance
                 */
                PostScriptResponse.prototype.stderr = "";

                /**
                 * PostScriptResponse elapsedTimeMillis.
                 * @member {number} elapsedTimeMillis
                 * @memberof mts.desk.http.PostScriptResponse
                 * @instance
                 */
                PostScriptResponse.prototype.elapsedTimeMillis = 0;

                /**
                 * Creates a new PostScriptResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {mts.desk.http.IPostScriptResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.PostScriptResponse} PostScriptResponse instance
                 */
                PostScriptResponse.create = function create(properties) {
                    return new PostScriptResponse(properties);
                };

                /**
                 * Encodes the specified PostScriptResponse message. Does not implicitly {@link mts.desk.http.PostScriptResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {mts.desk.http.IPostScriptResponse} message PostScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostScriptResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.stdout != null && Object.hasOwnProperty.call(message, "stdout"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.stdout);
                    if (message.stderr != null && Object.hasOwnProperty.call(message, "stderr"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.stderr);
                    if (message.elapsedTimeMillis != null && Object.hasOwnProperty.call(message, "elapsedTimeMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.elapsedTimeMillis);
                    return writer;
                };

                /**
                 * Encodes the specified PostScriptResponse message, length delimited. Does not implicitly {@link mts.desk.http.PostScriptResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {mts.desk.http.IPostScriptResponse} message PostScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostScriptResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PostScriptResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.PostScriptResponse} PostScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostScriptResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.PostScriptResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                message.stdout = reader.string();
                                break;
                            }
                        case 3: {
                                message.stderr = reader.string();
                                break;
                            }
                        case 4: {
                                message.elapsedTimeMillis = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PostScriptResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.PostScriptResponse} PostScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostScriptResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PostScriptResponse message.
                 * @function verify
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PostScriptResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        if (!$util.isInteger(message.status))
                            return "status: integer expected";
                    if (message.stdout != null && message.hasOwnProperty("stdout"))
                        if (!$util.isString(message.stdout))
                            return "stdout: string expected";
                    if (message.stderr != null && message.hasOwnProperty("stderr"))
                        if (!$util.isString(message.stderr))
                            return "stderr: string expected";
                    if (message.elapsedTimeMillis != null && message.hasOwnProperty("elapsedTimeMillis"))
                        if (!$util.isInteger(message.elapsedTimeMillis))
                            return "elapsedTimeMillis: integer expected";
                    return null;
                };

                /**
                 * Creates a PostScriptResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.PostScriptResponse} PostScriptResponse
                 */
                PostScriptResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.PostScriptResponse)
                        return object;
                    let message = new $root.mts.desk.http.PostScriptResponse();
                    if (object.status != null)
                        message.status = object.status | 0;
                    if (object.stdout != null)
                        message.stdout = String(object.stdout);
                    if (object.stderr != null)
                        message.stderr = String(object.stderr);
                    if (object.elapsedTimeMillis != null)
                        message.elapsedTimeMillis = object.elapsedTimeMillis | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a PostScriptResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {mts.desk.http.PostScriptResponse} message PostScriptResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PostScriptResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.status = 0;
                        object.stdout = "";
                        object.stderr = "";
                        object.elapsedTimeMillis = 0;
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = message.status;
                    if (message.stdout != null && message.hasOwnProperty("stdout"))
                        object.stdout = message.stdout;
                    if (message.stderr != null && message.hasOwnProperty("stderr"))
                        object.stderr = message.stderr;
                    if (message.elapsedTimeMillis != null && message.hasOwnProperty("elapsedTimeMillis"))
                        object.elapsedTimeMillis = message.elapsedTimeMillis;
                    return object;
                };

                /**
                 * Converts this PostScriptResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.PostScriptResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PostScriptResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PostScriptResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.PostScriptResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PostScriptResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.PostScriptResponse";
                };

                return PostScriptResponse;
            })();

            http.GetInstancesRequest = (function() {

                /**
                 * Properties of a GetInstancesRequest.
                 * @memberof mts.desk.http
                 * @interface IGetInstancesRequest
                 * @property {mts.desk.http.GetInstancesRequest.IFilters|null} [filters] GetInstancesRequest filters
                 */

                /**
                 * Constructs a new GetInstancesRequest.
                 * @memberof mts.desk.http
                 * @classdesc Represents a GetInstancesRequest.
                 * @implements IGetInstancesRequest
                 * @constructor
                 * @param {mts.desk.http.IGetInstancesRequest=} [properties] Properties to set
                 */
                function GetInstancesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetInstancesRequest filters.
                 * @member {mts.desk.http.GetInstancesRequest.IFilters|null|undefined} filters
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @instance
                 */
                GetInstancesRequest.prototype.filters = null;

                /**
                 * Creates a new GetInstancesRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {mts.desk.http.IGetInstancesRequest=} [properties] Properties to set
                 * @returns {mts.desk.http.GetInstancesRequest} GetInstancesRequest instance
                 */
                GetInstancesRequest.create = function create(properties) {
                    return new GetInstancesRequest(properties);
                };

                /**
                 * Encodes the specified GetInstancesRequest message. Does not implicitly {@link mts.desk.http.GetInstancesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {mts.desk.http.IGetInstancesRequest} message GetInstancesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstancesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.filters != null && Object.hasOwnProperty.call(message, "filters"))
                        $root.mts.desk.http.GetInstancesRequest.Filters.encode(message.filters, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetInstancesRequest message, length delimited. Does not implicitly {@link mts.desk.http.GetInstancesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {mts.desk.http.IGetInstancesRequest} message GetInstancesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstancesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetInstancesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.GetInstancesRequest} GetInstancesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstancesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetInstancesRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.filters = $root.mts.desk.http.GetInstancesRequest.Filters.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetInstancesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.GetInstancesRequest} GetInstancesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstancesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetInstancesRequest message.
                 * @function verify
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInstancesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.filters != null && message.hasOwnProperty("filters")) {
                        let error = $root.mts.desk.http.GetInstancesRequest.Filters.verify(message.filters);
                        if (error)
                            return "filters." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetInstancesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.GetInstancesRequest} GetInstancesRequest
                 */
                GetInstancesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.GetInstancesRequest)
                        return object;
                    let message = new $root.mts.desk.http.GetInstancesRequest();
                    if (object.filters != null) {
                        if (typeof object.filters !== "object")
                            throw TypeError(".mts.desk.http.GetInstancesRequest.filters: object expected");
                        message.filters = $root.mts.desk.http.GetInstancesRequest.Filters.fromObject(object.filters);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetInstancesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {mts.desk.http.GetInstancesRequest} message GetInstancesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInstancesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.filters = null;
                    if (message.filters != null && message.hasOwnProperty("filters"))
                        object.filters = $root.mts.desk.http.GetInstancesRequest.Filters.toObject(message.filters, options);
                    return object;
                };

                /**
                 * Converts this GetInstancesRequest to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInstancesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetInstancesRequest
                 * @function getTypeUrl
                 * @memberof mts.desk.http.GetInstancesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInstancesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.GetInstancesRequest";
                };

                GetInstancesRequest.Filters = (function() {

                    /**
                     * Properties of a Filters.
                     * @memberof mts.desk.http.GetInstancesRequest
                     * @interface IFilters
                     * @property {Array.<mts.desk.TraderInstance.Mode>|null} [modes] Filters modes
                     */

                    /**
                     * Constructs a new Filters.
                     * @memberof mts.desk.http.GetInstancesRequest
                     * @classdesc Represents a Filters.
                     * @implements IFilters
                     * @constructor
                     * @param {mts.desk.http.GetInstancesRequest.IFilters=} [properties] Properties to set
                     */
                    function Filters(properties) {
                        this.modes = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Filters modes.
                     * @member {Array.<mts.desk.TraderInstance.Mode>} modes
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @instance
                     */
                    Filters.prototype.modes = $util.emptyArray;

                    /**
                     * Creates a new Filters instance using the specified properties.
                     * @function create
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {mts.desk.http.GetInstancesRequest.IFilters=} [properties] Properties to set
                     * @returns {mts.desk.http.GetInstancesRequest.Filters} Filters instance
                     */
                    Filters.create = function create(properties) {
                        return new Filters(properties);
                    };

                    /**
                     * Encodes the specified Filters message. Does not implicitly {@link mts.desk.http.GetInstancesRequest.Filters.verify|verify} messages.
                     * @function encode
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {mts.desk.http.GetInstancesRequest.IFilters} message Filters message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Filters.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.modes != null && message.modes.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (let i = 0; i < message.modes.length; ++i)
                                writer.int32(message.modes[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };

                    /**
                     * Encodes the specified Filters message, length delimited. Does not implicitly {@link mts.desk.http.GetInstancesRequest.Filters.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {mts.desk.http.GetInstancesRequest.IFilters} message Filters message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Filters.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Filters message from the specified reader or buffer.
                     * @function decode
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {mts.desk.http.GetInstancesRequest.Filters} Filters
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Filters.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetInstancesRequest.Filters();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.modes && message.modes.length))
                                        message.modes = [];
                                    if ((tag & 7) === 2) {
                                        let end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.modes.push(reader.int32());
                                    } else
                                        message.modes.push(reader.int32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Filters message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {mts.desk.http.GetInstancesRequest.Filters} Filters
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Filters.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Filters message.
                     * @function verify
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Filters.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.modes != null && message.hasOwnProperty("modes")) {
                            if (!Array.isArray(message.modes))
                                return "modes: array expected";
                            for (let i = 0; i < message.modes.length; ++i)
                                switch (message.modes[i]) {
                                default:
                                    return "modes: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                    break;
                                }
                        }
                        return null;
                    };

                    /**
                     * Creates a Filters message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mts.desk.http.GetInstancesRequest.Filters} Filters
                     */
                    Filters.fromObject = function fromObject(object) {
                        if (object instanceof $root.mts.desk.http.GetInstancesRequest.Filters)
                            return object;
                        let message = new $root.mts.desk.http.GetInstancesRequest.Filters();
                        if (object.modes) {
                            if (!Array.isArray(object.modes))
                                throw TypeError(".mts.desk.http.GetInstancesRequest.Filters.modes: array expected");
                            message.modes = [];
                            for (let i = 0; i < object.modes.length; ++i)
                                switch (object.modes[i]) {
                                default:
                                    if (typeof object.modes[i] === "number") {
                                        message.modes[i] = object.modes[i];
                                        break;
                                    }
                                case "UNKNOWN_MODE":
                                case 0:
                                    message.modes[i] = 0;
                                    break;
                                case "BACKTEST":
                                case 1:
                                    message.modes[i] = 1;
                                    break;
                                case "REALTIME":
                                case 2:
                                    message.modes[i] = 2;
                                    break;
                                }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Filters message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {mts.desk.http.GetInstancesRequest.Filters} message Filters
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Filters.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.modes = [];
                        if (message.modes && message.modes.length) {
                            object.modes = [];
                            for (let j = 0; j < message.modes.length; ++j)
                                object.modes[j] = options.enums === String ? $root.mts.desk.TraderInstance.Mode[message.modes[j]] === undefined ? message.modes[j] : $root.mts.desk.TraderInstance.Mode[message.modes[j]] : message.modes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this Filters to JSON.
                     * @function toJSON
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Filters.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Filters
                     * @function getTypeUrl
                     * @memberof mts.desk.http.GetInstancesRequest.Filters
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Filters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/mts.desk.http.GetInstancesRequest.Filters";
                    };

                    return Filters;
                })();

                return GetInstancesRequest;
            })();

            http.GetInstanceResponse = (function() {

                /**
                 * Properties of a GetInstanceResponse.
                 * @memberof mts.desk.http
                 * @interface IGetInstanceResponse
                 * @property {mts.desk.ITraderInstance|null} [instance] GetInstanceResponse instance
                 * @property {mts.portfolio.IPortfolio|null} [portfolio] GetInstanceResponse portfolio
                 * @property {Array.<mts.desk.IWatchlist>|null} [watchlists] GetInstanceResponse watchlists
                 * @property {Array.<mts.desk.IFile>|null} [files] GetInstanceResponse files
                 */

                /**
                 * Constructs a new GetInstanceResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a GetInstanceResponse.
                 * @implements IGetInstanceResponse
                 * @constructor
                 * @param {mts.desk.http.IGetInstanceResponse=} [properties] Properties to set
                 */
                function GetInstanceResponse(properties) {
                    this.watchlists = [];
                    this.files = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetInstanceResponse instance.
                 * @member {mts.desk.ITraderInstance|null|undefined} instance
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @instance
                 */
                GetInstanceResponse.prototype.instance = null;

                /**
                 * GetInstanceResponse portfolio.
                 * @member {mts.portfolio.IPortfolio|null|undefined} portfolio
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @instance
                 */
                GetInstanceResponse.prototype.portfolio = null;

                /**
                 * GetInstanceResponse watchlists.
                 * @member {Array.<mts.desk.IWatchlist>} watchlists
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @instance
                 */
                GetInstanceResponse.prototype.watchlists = $util.emptyArray;

                /**
                 * GetInstanceResponse files.
                 * @member {Array.<mts.desk.IFile>} files
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @instance
                 */
                GetInstanceResponse.prototype.files = $util.emptyArray;

                /**
                 * Creates a new GetInstanceResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {mts.desk.http.IGetInstanceResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.GetInstanceResponse} GetInstanceResponse instance
                 */
                GetInstanceResponse.create = function create(properties) {
                    return new GetInstanceResponse(properties);
                };

                /**
                 * Encodes the specified GetInstanceResponse message. Does not implicitly {@link mts.desk.http.GetInstanceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {mts.desk.http.IGetInstanceResponse} message GetInstanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstanceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instance != null && Object.hasOwnProperty.call(message, "instance"))
                        $root.mts.desk.TraderInstance.encode(message.instance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.portfolio != null && Object.hasOwnProperty.call(message, "portfolio"))
                        $root.mts.portfolio.Portfolio.encode(message.portfolio, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.watchlists != null && message.watchlists.length)
                        for (let i = 0; i < message.watchlists.length; ++i)
                            $root.mts.desk.Watchlist.encode(message.watchlists[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.files != null && message.files.length)
                        for (let i = 0; i < message.files.length; ++i)
                            $root.mts.desk.File.encode(message.files[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetInstanceResponse message, length delimited. Does not implicitly {@link mts.desk.http.GetInstanceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {mts.desk.http.IGetInstanceResponse} message GetInstanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetInstanceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.GetInstanceResponse} GetInstanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstanceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetInstanceResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.instance = $root.mts.desk.TraderInstance.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.portfolio = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.watchlists && message.watchlists.length))
                                    message.watchlists = [];
                                message.watchlists.push($root.mts.desk.Watchlist.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.files && message.files.length))
                                    message.files = [];
                                message.files.push($root.mts.desk.File.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetInstanceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.GetInstanceResponse} GetInstanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetInstanceResponse message.
                 * @function verify
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInstanceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instance != null && message.hasOwnProperty("instance")) {
                        let error = $root.mts.desk.TraderInstance.verify(message.instance);
                        if (error)
                            return "instance." + error;
                    }
                    if (message.portfolio != null && message.hasOwnProperty("portfolio")) {
                        let error = $root.mts.portfolio.Portfolio.verify(message.portfolio);
                        if (error)
                            return "portfolio." + error;
                    }
                    if (message.watchlists != null && message.hasOwnProperty("watchlists")) {
                        if (!Array.isArray(message.watchlists))
                            return "watchlists: array expected";
                        for (let i = 0; i < message.watchlists.length; ++i) {
                            let error = $root.mts.desk.Watchlist.verify(message.watchlists[i]);
                            if (error)
                                return "watchlists." + error;
                        }
                    }
                    if (message.files != null && message.hasOwnProperty("files")) {
                        if (!Array.isArray(message.files))
                            return "files: array expected";
                        for (let i = 0; i < message.files.length; ++i) {
                            let error = $root.mts.desk.File.verify(message.files[i]);
                            if (error)
                                return "files." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetInstanceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.GetInstanceResponse} GetInstanceResponse
                 */
                GetInstanceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.GetInstanceResponse)
                        return object;
                    let message = new $root.mts.desk.http.GetInstanceResponse();
                    if (object.instance != null) {
                        if (typeof object.instance !== "object")
                            throw TypeError(".mts.desk.http.GetInstanceResponse.instance: object expected");
                        message.instance = $root.mts.desk.TraderInstance.fromObject(object.instance);
                    }
                    if (object.portfolio != null) {
                        if (typeof object.portfolio !== "object")
                            throw TypeError(".mts.desk.http.GetInstanceResponse.portfolio: object expected");
                        message.portfolio = $root.mts.portfolio.Portfolio.fromObject(object.portfolio);
                    }
                    if (object.watchlists) {
                        if (!Array.isArray(object.watchlists))
                            throw TypeError(".mts.desk.http.GetInstanceResponse.watchlists: array expected");
                        message.watchlists = [];
                        for (let i = 0; i < object.watchlists.length; ++i) {
                            if (typeof object.watchlists[i] !== "object")
                                throw TypeError(".mts.desk.http.GetInstanceResponse.watchlists: object expected");
                            message.watchlists[i] = $root.mts.desk.Watchlist.fromObject(object.watchlists[i]);
                        }
                    }
                    if (object.files) {
                        if (!Array.isArray(object.files))
                            throw TypeError(".mts.desk.http.GetInstanceResponse.files: array expected");
                        message.files = [];
                        for (let i = 0; i < object.files.length; ++i) {
                            if (typeof object.files[i] !== "object")
                                throw TypeError(".mts.desk.http.GetInstanceResponse.files: object expected");
                            message.files[i] = $root.mts.desk.File.fromObject(object.files[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetInstanceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {mts.desk.http.GetInstanceResponse} message GetInstanceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInstanceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.watchlists = [];
                        object.files = [];
                    }
                    if (options.defaults) {
                        object.instance = null;
                        object.portfolio = null;
                    }
                    if (message.instance != null && message.hasOwnProperty("instance"))
                        object.instance = $root.mts.desk.TraderInstance.toObject(message.instance, options);
                    if (message.portfolio != null && message.hasOwnProperty("portfolio"))
                        object.portfolio = $root.mts.portfolio.Portfolio.toObject(message.portfolio, options);
                    if (message.watchlists && message.watchlists.length) {
                        object.watchlists = [];
                        for (let j = 0; j < message.watchlists.length; ++j)
                            object.watchlists[j] = $root.mts.desk.Watchlist.toObject(message.watchlists[j], options);
                    }
                    if (message.files && message.files.length) {
                        object.files = [];
                        for (let j = 0; j < message.files.length; ++j)
                            object.files[j] = $root.mts.desk.File.toObject(message.files[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetInstanceResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInstanceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetInstanceResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.GetInstanceResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.GetInstanceResponse";
                };

                return GetInstanceResponse;
            })();

            http.PutUserInstanceNotificationSettingsRequest = (function() {

                /**
                 * Properties of a PutUserInstanceNotificationSettingsRequest.
                 * @memberof mts.desk.http
                 * @interface IPutUserInstanceNotificationSettingsRequest
                 * @property {string|null} [userId] PutUserInstanceNotificationSettingsRequest userId
                 * @property {string|null} [instanceId] PutUserInstanceNotificationSettingsRequest instanceId
                 * @property {Array.<mts.desk.IUserInstanceNotificationSetting>|null} [notificationSettings] PutUserInstanceNotificationSettingsRequest notificationSettings
                 */

                /**
                 * Constructs a new PutUserInstanceNotificationSettingsRequest.
                 * @memberof mts.desk.http
                 * @classdesc Represents a PutUserInstanceNotificationSettingsRequest.
                 * @implements IPutUserInstanceNotificationSettingsRequest
                 * @constructor
                 * @param {mts.desk.http.IPutUserInstanceNotificationSettingsRequest=} [properties] Properties to set
                 */
                function PutUserInstanceNotificationSettingsRequest(properties) {
                    this.notificationSettings = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PutUserInstanceNotificationSettingsRequest userId.
                 * @member {string} userId
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @instance
                 */
                PutUserInstanceNotificationSettingsRequest.prototype.userId = "";

                /**
                 * PutUserInstanceNotificationSettingsRequest instanceId.
                 * @member {string} instanceId
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @instance
                 */
                PutUserInstanceNotificationSettingsRequest.prototype.instanceId = "";

                /**
                 * PutUserInstanceNotificationSettingsRequest notificationSettings.
                 * @member {Array.<mts.desk.IUserInstanceNotificationSetting>} notificationSettings
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @instance
                 */
                PutUserInstanceNotificationSettingsRequest.prototype.notificationSettings = $util.emptyArray;

                /**
                 * Creates a new PutUserInstanceNotificationSettingsRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {mts.desk.http.IPutUserInstanceNotificationSettingsRequest=} [properties] Properties to set
                 * @returns {mts.desk.http.PutUserInstanceNotificationSettingsRequest} PutUserInstanceNotificationSettingsRequest instance
                 */
                PutUserInstanceNotificationSettingsRequest.create = function create(properties) {
                    return new PutUserInstanceNotificationSettingsRequest(properties);
                };

                /**
                 * Encodes the specified PutUserInstanceNotificationSettingsRequest message. Does not implicitly {@link mts.desk.http.PutUserInstanceNotificationSettingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {mts.desk.http.IPutUserInstanceNotificationSettingsRequest} message PutUserInstanceNotificationSettingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PutUserInstanceNotificationSettingsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.instanceId);
                    if (message.notificationSettings != null && message.notificationSettings.length)
                        for (let i = 0; i < message.notificationSettings.length; ++i)
                            $root.mts.desk.UserInstanceNotificationSetting.encode(message.notificationSettings[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PutUserInstanceNotificationSettingsRequest message, length delimited. Does not implicitly {@link mts.desk.http.PutUserInstanceNotificationSettingsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {mts.desk.http.IPutUserInstanceNotificationSettingsRequest} message PutUserInstanceNotificationSettingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PutUserInstanceNotificationSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PutUserInstanceNotificationSettingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.PutUserInstanceNotificationSettingsRequest} PutUserInstanceNotificationSettingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PutUserInstanceNotificationSettingsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.PutUserInstanceNotificationSettingsRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.instanceId = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.notificationSettings && message.notificationSettings.length))
                                    message.notificationSettings = [];
                                message.notificationSettings.push($root.mts.desk.UserInstanceNotificationSetting.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PutUserInstanceNotificationSettingsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.PutUserInstanceNotificationSettingsRequest} PutUserInstanceNotificationSettingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PutUserInstanceNotificationSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PutUserInstanceNotificationSettingsRequest message.
                 * @function verify
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PutUserInstanceNotificationSettingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        if (!$util.isString(message.instanceId))
                            return "instanceId: string expected";
                    if (message.notificationSettings != null && message.hasOwnProperty("notificationSettings")) {
                        if (!Array.isArray(message.notificationSettings))
                            return "notificationSettings: array expected";
                        for (let i = 0; i < message.notificationSettings.length; ++i) {
                            let error = $root.mts.desk.UserInstanceNotificationSetting.verify(message.notificationSettings[i]);
                            if (error)
                                return "notificationSettings." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PutUserInstanceNotificationSettingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.PutUserInstanceNotificationSettingsRequest} PutUserInstanceNotificationSettingsRequest
                 */
                PutUserInstanceNotificationSettingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.PutUserInstanceNotificationSettingsRequest)
                        return object;
                    let message = new $root.mts.desk.http.PutUserInstanceNotificationSettingsRequest();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.instanceId != null)
                        message.instanceId = String(object.instanceId);
                    if (object.notificationSettings) {
                        if (!Array.isArray(object.notificationSettings))
                            throw TypeError(".mts.desk.http.PutUserInstanceNotificationSettingsRequest.notificationSettings: array expected");
                        message.notificationSettings = [];
                        for (let i = 0; i < object.notificationSettings.length; ++i) {
                            if (typeof object.notificationSettings[i] !== "object")
                                throw TypeError(".mts.desk.http.PutUserInstanceNotificationSettingsRequest.notificationSettings: object expected");
                            message.notificationSettings[i] = $root.mts.desk.UserInstanceNotificationSetting.fromObject(object.notificationSettings[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PutUserInstanceNotificationSettingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {mts.desk.http.PutUserInstanceNotificationSettingsRequest} message PutUserInstanceNotificationSettingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PutUserInstanceNotificationSettingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.notificationSettings = [];
                    if (options.defaults) {
                        object.userId = "";
                        object.instanceId = "";
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        object.instanceId = message.instanceId;
                    if (message.notificationSettings && message.notificationSettings.length) {
                        object.notificationSettings = [];
                        for (let j = 0; j < message.notificationSettings.length; ++j)
                            object.notificationSettings[j] = $root.mts.desk.UserInstanceNotificationSetting.toObject(message.notificationSettings[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PutUserInstanceNotificationSettingsRequest to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PutUserInstanceNotificationSettingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PutUserInstanceNotificationSettingsRequest
                 * @function getTypeUrl
                 * @memberof mts.desk.http.PutUserInstanceNotificationSettingsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PutUserInstanceNotificationSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.PutUserInstanceNotificationSettingsRequest";
                };

                return PutUserInstanceNotificationSettingsRequest;
            })();

            http.GetUserInstanceNotificationSettingsResponse = (function() {

                /**
                 * Properties of a GetUserInstanceNotificationSettingsResponse.
                 * @memberof mts.desk.http
                 * @interface IGetUserInstanceNotificationSettingsResponse
                 * @property {Array.<mts.desk.IUserInstanceNotificationSetting>|null} [notificationSettings] GetUserInstanceNotificationSettingsResponse notificationSettings
                 */

                /**
                 * Constructs a new GetUserInstanceNotificationSettingsResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a GetUserInstanceNotificationSettingsResponse.
                 * @implements IGetUserInstanceNotificationSettingsResponse
                 * @constructor
                 * @param {mts.desk.http.IGetUserInstanceNotificationSettingsResponse=} [properties] Properties to set
                 */
                function GetUserInstanceNotificationSettingsResponse(properties) {
                    this.notificationSettings = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetUserInstanceNotificationSettingsResponse notificationSettings.
                 * @member {Array.<mts.desk.IUserInstanceNotificationSetting>} notificationSettings
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @instance
                 */
                GetUserInstanceNotificationSettingsResponse.prototype.notificationSettings = $util.emptyArray;

                /**
                 * Creates a new GetUserInstanceNotificationSettingsResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {mts.desk.http.IGetUserInstanceNotificationSettingsResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.GetUserInstanceNotificationSettingsResponse} GetUserInstanceNotificationSettingsResponse instance
                 */
                GetUserInstanceNotificationSettingsResponse.create = function create(properties) {
                    return new GetUserInstanceNotificationSettingsResponse(properties);
                };

                /**
                 * Encodes the specified GetUserInstanceNotificationSettingsResponse message. Does not implicitly {@link mts.desk.http.GetUserInstanceNotificationSettingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {mts.desk.http.IGetUserInstanceNotificationSettingsResponse} message GetUserInstanceNotificationSettingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserInstanceNotificationSettingsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.notificationSettings != null && message.notificationSettings.length)
                        for (let i = 0; i < message.notificationSettings.length; ++i)
                            $root.mts.desk.UserInstanceNotificationSetting.encode(message.notificationSettings[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetUserInstanceNotificationSettingsResponse message, length delimited. Does not implicitly {@link mts.desk.http.GetUserInstanceNotificationSettingsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {mts.desk.http.IGetUserInstanceNotificationSettingsResponse} message GetUserInstanceNotificationSettingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserInstanceNotificationSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetUserInstanceNotificationSettingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.GetUserInstanceNotificationSettingsResponse} GetUserInstanceNotificationSettingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserInstanceNotificationSettingsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetUserInstanceNotificationSettingsResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.notificationSettings && message.notificationSettings.length))
                                    message.notificationSettings = [];
                                message.notificationSettings.push($root.mts.desk.UserInstanceNotificationSetting.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetUserInstanceNotificationSettingsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.GetUserInstanceNotificationSettingsResponse} GetUserInstanceNotificationSettingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserInstanceNotificationSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetUserInstanceNotificationSettingsResponse message.
                 * @function verify
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserInstanceNotificationSettingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.notificationSettings != null && message.hasOwnProperty("notificationSettings")) {
                        if (!Array.isArray(message.notificationSettings))
                            return "notificationSettings: array expected";
                        for (let i = 0; i < message.notificationSettings.length; ++i) {
                            let error = $root.mts.desk.UserInstanceNotificationSetting.verify(message.notificationSettings[i]);
                            if (error)
                                return "notificationSettings." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetUserInstanceNotificationSettingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.GetUserInstanceNotificationSettingsResponse} GetUserInstanceNotificationSettingsResponse
                 */
                GetUserInstanceNotificationSettingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.GetUserInstanceNotificationSettingsResponse)
                        return object;
                    let message = new $root.mts.desk.http.GetUserInstanceNotificationSettingsResponse();
                    if (object.notificationSettings) {
                        if (!Array.isArray(object.notificationSettings))
                            throw TypeError(".mts.desk.http.GetUserInstanceNotificationSettingsResponse.notificationSettings: array expected");
                        message.notificationSettings = [];
                        for (let i = 0; i < object.notificationSettings.length; ++i) {
                            if (typeof object.notificationSettings[i] !== "object")
                                throw TypeError(".mts.desk.http.GetUserInstanceNotificationSettingsResponse.notificationSettings: object expected");
                            message.notificationSettings[i] = $root.mts.desk.UserInstanceNotificationSetting.fromObject(object.notificationSettings[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetUserInstanceNotificationSettingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {mts.desk.http.GetUserInstanceNotificationSettingsResponse} message GetUserInstanceNotificationSettingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserInstanceNotificationSettingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.notificationSettings = [];
                    if (message.notificationSettings && message.notificationSettings.length) {
                        object.notificationSettings = [];
                        for (let j = 0; j < message.notificationSettings.length; ++j)
                            object.notificationSettings[j] = $root.mts.desk.UserInstanceNotificationSetting.toObject(message.notificationSettings[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetUserInstanceNotificationSettingsResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserInstanceNotificationSettingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetUserInstanceNotificationSettingsResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.GetUserInstanceNotificationSettingsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserInstanceNotificationSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.GetUserInstanceNotificationSettingsResponse";
                };

                return GetUserInstanceNotificationSettingsResponse;
            })();

            http.GetTradersResponse = (function() {

                /**
                 * Properties of a GetTradersResponse.
                 * @memberof mts.desk.http
                 * @interface IGetTradersResponse
                 * @property {Array.<mts.desk.ITrader>|null} [traders] GetTradersResponse traders
                 */

                /**
                 * Constructs a new GetTradersResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a GetTradersResponse.
                 * @implements IGetTradersResponse
                 * @constructor
                 * @param {mts.desk.http.IGetTradersResponse=} [properties] Properties to set
                 */
                function GetTradersResponse(properties) {
                    this.traders = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTradersResponse traders.
                 * @member {Array.<mts.desk.ITrader>} traders
                 * @memberof mts.desk.http.GetTradersResponse
                 * @instance
                 */
                GetTradersResponse.prototype.traders = $util.emptyArray;

                /**
                 * Creates a new GetTradersResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {mts.desk.http.IGetTradersResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.GetTradersResponse} GetTradersResponse instance
                 */
                GetTradersResponse.create = function create(properties) {
                    return new GetTradersResponse(properties);
                };

                /**
                 * Encodes the specified GetTradersResponse message. Does not implicitly {@link mts.desk.http.GetTradersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {mts.desk.http.IGetTradersResponse} message GetTradersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTradersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.traders != null && message.traders.length)
                        for (let i = 0; i < message.traders.length; ++i)
                            $root.mts.desk.Trader.encode(message.traders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetTradersResponse message, length delimited. Does not implicitly {@link mts.desk.http.GetTradersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {mts.desk.http.IGetTradersResponse} message GetTradersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTradersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetTradersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.GetTradersResponse} GetTradersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTradersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetTradersResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.traders && message.traders.length))
                                    message.traders = [];
                                message.traders.push($root.mts.desk.Trader.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetTradersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.GetTradersResponse} GetTradersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTradersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetTradersResponse message.
                 * @function verify
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTradersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.traders != null && message.hasOwnProperty("traders")) {
                        if (!Array.isArray(message.traders))
                            return "traders: array expected";
                        for (let i = 0; i < message.traders.length; ++i) {
                            let error = $root.mts.desk.Trader.verify(message.traders[i]);
                            if (error)
                                return "traders." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetTradersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.GetTradersResponse} GetTradersResponse
                 */
                GetTradersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.GetTradersResponse)
                        return object;
                    let message = new $root.mts.desk.http.GetTradersResponse();
                    if (object.traders) {
                        if (!Array.isArray(object.traders))
                            throw TypeError(".mts.desk.http.GetTradersResponse.traders: array expected");
                        message.traders = [];
                        for (let i = 0; i < object.traders.length; ++i) {
                            if (typeof object.traders[i] !== "object")
                                throw TypeError(".mts.desk.http.GetTradersResponse.traders: object expected");
                            message.traders[i] = $root.mts.desk.Trader.fromObject(object.traders[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetTradersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {mts.desk.http.GetTradersResponse} message GetTradersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTradersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.traders = [];
                    if (message.traders && message.traders.length) {
                        object.traders = [];
                        for (let j = 0; j < message.traders.length; ++j)
                            object.traders[j] = $root.mts.desk.Trader.toObject(message.traders[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetTradersResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.GetTradersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTradersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetTradersResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.GetTradersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTradersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.GetTradersResponse";
                };

                return GetTradersResponse;
            })();

            http.GetWatchlistResponse = (function() {

                /**
                 * Properties of a GetWatchlistResponse.
                 * @memberof mts.desk.http
                 * @interface IGetWatchlistResponse
                 * @property {mts.desk.IWatchlist|null} [watchlist] GetWatchlistResponse watchlist
                 */

                /**
                 * Constructs a new GetWatchlistResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a GetWatchlistResponse.
                 * @implements IGetWatchlistResponse
                 * @constructor
                 * @param {mts.desk.http.IGetWatchlistResponse=} [properties] Properties to set
                 */
                function GetWatchlistResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetWatchlistResponse watchlist.
                 * @member {mts.desk.IWatchlist|null|undefined} watchlist
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @instance
                 */
                GetWatchlistResponse.prototype.watchlist = null;

                /**
                 * Creates a new GetWatchlistResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {mts.desk.http.IGetWatchlistResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.GetWatchlistResponse} GetWatchlistResponse instance
                 */
                GetWatchlistResponse.create = function create(properties) {
                    return new GetWatchlistResponse(properties);
                };

                /**
                 * Encodes the specified GetWatchlistResponse message. Does not implicitly {@link mts.desk.http.GetWatchlistResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {mts.desk.http.IGetWatchlistResponse} message GetWatchlistResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWatchlistResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.watchlist != null && Object.hasOwnProperty.call(message, "watchlist"))
                        $root.mts.desk.Watchlist.encode(message.watchlist, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetWatchlistResponse message, length delimited. Does not implicitly {@link mts.desk.http.GetWatchlistResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {mts.desk.http.IGetWatchlistResponse} message GetWatchlistResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWatchlistResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetWatchlistResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.GetWatchlistResponse} GetWatchlistResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWatchlistResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.GetWatchlistResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.watchlist = $root.mts.desk.Watchlist.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetWatchlistResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.GetWatchlistResponse} GetWatchlistResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWatchlistResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetWatchlistResponse message.
                 * @function verify
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetWatchlistResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.watchlist != null && message.hasOwnProperty("watchlist")) {
                        let error = $root.mts.desk.Watchlist.verify(message.watchlist);
                        if (error)
                            return "watchlist." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetWatchlistResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.GetWatchlistResponse} GetWatchlistResponse
                 */
                GetWatchlistResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.GetWatchlistResponse)
                        return object;
                    let message = new $root.mts.desk.http.GetWatchlistResponse();
                    if (object.watchlist != null) {
                        if (typeof object.watchlist !== "object")
                            throw TypeError(".mts.desk.http.GetWatchlistResponse.watchlist: object expected");
                        message.watchlist = $root.mts.desk.Watchlist.fromObject(object.watchlist);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetWatchlistResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {mts.desk.http.GetWatchlistResponse} message GetWatchlistResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWatchlistResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.watchlist = null;
                    if (message.watchlist != null && message.hasOwnProperty("watchlist"))
                        object.watchlist = $root.mts.desk.Watchlist.toObject(message.watchlist, options);
                    return object;
                };

                /**
                 * Converts this GetWatchlistResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWatchlistResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetWatchlistResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.GetWatchlistResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetWatchlistResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.GetWatchlistResponse";
                };

                return GetWatchlistResponse;
            })();

            http.Activity = (function() {

                /**
                 * Properties of an Activity.
                 * @memberof mts.desk.http
                 * @interface IActivity
                 * @property {string|null} [userId] Activity userId
                 * @property {string|null} [date] Activity date
                 * @property {string|null} [action] Activity action
                 */

                /**
                 * Constructs a new Activity.
                 * @memberof mts.desk.http
                 * @classdesc Represents an Activity.
                 * @implements IActivity
                 * @constructor
                 * @param {mts.desk.http.IActivity=} [properties] Properties to set
                 */
                function Activity(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Activity userId.
                 * @member {string} userId
                 * @memberof mts.desk.http.Activity
                 * @instance
                 */
                Activity.prototype.userId = "";

                /**
                 * Activity date.
                 * @member {string} date
                 * @memberof mts.desk.http.Activity
                 * @instance
                 */
                Activity.prototype.date = "";

                /**
                 * Activity action.
                 * @member {string} action
                 * @memberof mts.desk.http.Activity
                 * @instance
                 */
                Activity.prototype.action = "";

                /**
                 * Creates a new Activity instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {mts.desk.http.IActivity=} [properties] Properties to set
                 * @returns {mts.desk.http.Activity} Activity instance
                 */
                Activity.create = function create(properties) {
                    return new Activity(properties);
                };

                /**
                 * Encodes the specified Activity message. Does not implicitly {@link mts.desk.http.Activity.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {mts.desk.http.IActivity} message Activity message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Activity.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.action);
                    return writer;
                };

                /**
                 * Encodes the specified Activity message, length delimited. Does not implicitly {@link mts.desk.http.Activity.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {mts.desk.http.IActivity} message Activity message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Activity.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Activity message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.Activity} Activity
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Activity.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.Activity();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.date = reader.string();
                                break;
                            }
                        case 3: {
                                message.action = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Activity message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.Activity} Activity
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Activity.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Activity message.
                 * @function verify
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Activity.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    if (message.action != null && message.hasOwnProperty("action"))
                        if (!$util.isString(message.action))
                            return "action: string expected";
                    return null;
                };

                /**
                 * Creates an Activity message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.Activity} Activity
                 */
                Activity.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.Activity)
                        return object;
                    let message = new $root.mts.desk.http.Activity();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.date != null)
                        message.date = String(object.date);
                    if (object.action != null)
                        message.action = String(object.action);
                    return message;
                };

                /**
                 * Creates a plain object from an Activity message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {mts.desk.http.Activity} message Activity
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Activity.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userId = "";
                        object.date = "";
                        object.action = "";
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = message.action;
                    return object;
                };

                /**
                 * Converts this Activity to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.Activity
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Activity.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Activity
                 * @function getTypeUrl
                 * @memberof mts.desk.http.Activity
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Activity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.Activity";
                };

                /**
                 * Origin enum.
                 * @name mts.desk.http.Activity.Origin
                 * @enum {number}
                 * @property {number} WEB=0 WEB value
                 * @property {number} BACKEND=1 BACKEND value
                 */
                Activity.Origin = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "WEB"] = 0;
                    values[valuesById[1] = "BACKEND"] = 1;
                    return values;
                })();

                return Activity;
            })();

            http.PostActivityRequest = (function() {

                /**
                 * Properties of a PostActivityRequest.
                 * @memberof mts.desk.http
                 * @interface IPostActivityRequest
                 * @property {mts.desk.http.IActivity|null} [activity] PostActivityRequest activity
                 */

                /**
                 * Constructs a new PostActivityRequest.
                 * @memberof mts.desk.http
                 * @classdesc Represents a PostActivityRequest.
                 * @implements IPostActivityRequest
                 * @constructor
                 * @param {mts.desk.http.IPostActivityRequest=} [properties] Properties to set
                 */
                function PostActivityRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PostActivityRequest activity.
                 * @member {mts.desk.http.IActivity|null|undefined} activity
                 * @memberof mts.desk.http.PostActivityRequest
                 * @instance
                 */
                PostActivityRequest.prototype.activity = null;

                /**
                 * Creates a new PostActivityRequest instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {mts.desk.http.IPostActivityRequest=} [properties] Properties to set
                 * @returns {mts.desk.http.PostActivityRequest} PostActivityRequest instance
                 */
                PostActivityRequest.create = function create(properties) {
                    return new PostActivityRequest(properties);
                };

                /**
                 * Encodes the specified PostActivityRequest message. Does not implicitly {@link mts.desk.http.PostActivityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {mts.desk.http.IPostActivityRequest} message PostActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostActivityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.activity != null && Object.hasOwnProperty.call(message, "activity"))
                        $root.mts.desk.http.Activity.encode(message.activity, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PostActivityRequest message, length delimited. Does not implicitly {@link mts.desk.http.PostActivityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {mts.desk.http.IPostActivityRequest} message PostActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostActivityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PostActivityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.PostActivityRequest} PostActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostActivityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.PostActivityRequest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.activity = $root.mts.desk.http.Activity.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PostActivityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.PostActivityRequest} PostActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostActivityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PostActivityRequest message.
                 * @function verify
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PostActivityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.activity != null && message.hasOwnProperty("activity")) {
                        let error = $root.mts.desk.http.Activity.verify(message.activity);
                        if (error)
                            return "activity." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PostActivityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.PostActivityRequest} PostActivityRequest
                 */
                PostActivityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.PostActivityRequest)
                        return object;
                    let message = new $root.mts.desk.http.PostActivityRequest();
                    if (object.activity != null) {
                        if (typeof object.activity !== "object")
                            throw TypeError(".mts.desk.http.PostActivityRequest.activity: object expected");
                        message.activity = $root.mts.desk.http.Activity.fromObject(object.activity);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PostActivityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {mts.desk.http.PostActivityRequest} message PostActivityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PostActivityRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.activity = null;
                    if (message.activity != null && message.hasOwnProperty("activity"))
                        object.activity = $root.mts.desk.http.Activity.toObject(message.activity, options);
                    return object;
                };

                /**
                 * Converts this PostActivityRequest to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.PostActivityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PostActivityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PostActivityRequest
                 * @function getTypeUrl
                 * @memberof mts.desk.http.PostActivityRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PostActivityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.PostActivityRequest";
                };

                return PostActivityRequest;
            })();

            http.PostActivityResponse = (function() {

                /**
                 * Properties of a PostActivityResponse.
                 * @memberof mts.desk.http
                 * @interface IPostActivityResponse
                 */

                /**
                 * Constructs a new PostActivityResponse.
                 * @memberof mts.desk.http
                 * @classdesc Represents a PostActivityResponse.
                 * @implements IPostActivityResponse
                 * @constructor
                 * @param {mts.desk.http.IPostActivityResponse=} [properties] Properties to set
                 */
                function PostActivityResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new PostActivityResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {mts.desk.http.IPostActivityResponse=} [properties] Properties to set
                 * @returns {mts.desk.http.PostActivityResponse} PostActivityResponse instance
                 */
                PostActivityResponse.create = function create(properties) {
                    return new PostActivityResponse(properties);
                };

                /**
                 * Encodes the specified PostActivityResponse message. Does not implicitly {@link mts.desk.http.PostActivityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {mts.desk.http.IPostActivityResponse} message PostActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostActivityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified PostActivityResponse message, length delimited. Does not implicitly {@link mts.desk.http.PostActivityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {mts.desk.http.IPostActivityResponse} message PostActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PostActivityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PostActivityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.http.PostActivityResponse} PostActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostActivityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.http.PostActivityResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PostActivityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.http.PostActivityResponse} PostActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PostActivityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PostActivityResponse message.
                 * @function verify
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PostActivityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PostActivityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.http.PostActivityResponse} PostActivityResponse
                 */
                PostActivityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.http.PostActivityResponse)
                        return object;
                    return new $root.mts.desk.http.PostActivityResponse();
                };

                /**
                 * Creates a plain object from a PostActivityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {mts.desk.http.PostActivityResponse} message PostActivityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PostActivityResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PostActivityResponse to JSON.
                 * @function toJSON
                 * @memberof mts.desk.http.PostActivityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PostActivityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PostActivityResponse
                 * @function getTypeUrl
                 * @memberof mts.desk.http.PostActivityResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PostActivityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.http.PostActivityResponse";
                };

                return PostActivityResponse;
            })();

            return http;
        })();

        desk.events = (function() {

            /**
             * Namespace events.
             * @memberof mts.desk
             * @namespace
             */
            const events = {};

            events.TraderInstanceStateChange = (function() {

                /**
                 * Properties of a TraderInstanceStateChange.
                 * @memberof mts.desk.events
                 * @interface ITraderInstanceStateChange
                 * @property {string|null} [traderInstanceId] TraderInstanceStateChange traderInstanceId
                 * @property {mts.desk.TraderInstance.State|null} [previousState] TraderInstanceStateChange previousState
                 * @property {mts.desk.TraderInstance.State|null} [state] TraderInstanceStateChange state
                 */

                /**
                 * Constructs a new TraderInstanceStateChange.
                 * @memberof mts.desk.events
                 * @classdesc Represents a TraderInstanceStateChange.
                 * @implements ITraderInstanceStateChange
                 * @constructor
                 * @param {mts.desk.events.ITraderInstanceStateChange=} [properties] Properties to set
                 */
                function TraderInstanceStateChange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TraderInstanceStateChange traderInstanceId.
                 * @member {string} traderInstanceId
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @instance
                 */
                TraderInstanceStateChange.prototype.traderInstanceId = "";

                /**
                 * TraderInstanceStateChange previousState.
                 * @member {mts.desk.TraderInstance.State} previousState
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @instance
                 */
                TraderInstanceStateChange.prototype.previousState = 0;

                /**
                 * TraderInstanceStateChange state.
                 * @member {mts.desk.TraderInstance.State} state
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @instance
                 */
                TraderInstanceStateChange.prototype.state = 0;

                /**
                 * Creates a new TraderInstanceStateChange instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {mts.desk.events.ITraderInstanceStateChange=} [properties] Properties to set
                 * @returns {mts.desk.events.TraderInstanceStateChange} TraderInstanceStateChange instance
                 */
                TraderInstanceStateChange.create = function create(properties) {
                    return new TraderInstanceStateChange(properties);
                };

                /**
                 * Encodes the specified TraderInstanceStateChange message. Does not implicitly {@link mts.desk.events.TraderInstanceStateChange.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {mts.desk.events.ITraderInstanceStateChange} message TraderInstanceStateChange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceStateChange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.traderInstanceId != null && Object.hasOwnProperty.call(message, "traderInstanceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderInstanceId);
                    if (message.previousState != null && Object.hasOwnProperty.call(message, "previousState"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.previousState);
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.state);
                    return writer;
                };

                /**
                 * Encodes the specified TraderInstanceStateChange message, length delimited. Does not implicitly {@link mts.desk.events.TraderInstanceStateChange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {mts.desk.events.ITraderInstanceStateChange} message TraderInstanceStateChange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceStateChange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TraderInstanceStateChange message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.TraderInstanceStateChange} TraderInstanceStateChange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceStateChange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.TraderInstanceStateChange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.traderInstanceId = reader.string();
                                break;
                            }
                        case 11: {
                                message.previousState = reader.int32();
                                break;
                            }
                        case 12: {
                                message.state = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TraderInstanceStateChange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.TraderInstanceStateChange} TraderInstanceStateChange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceStateChange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TraderInstanceStateChange message.
                 * @function verify
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TraderInstanceStateChange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.traderInstanceId != null && message.hasOwnProperty("traderInstanceId"))
                        if (!$util.isString(message.traderInstanceId))
                            return "traderInstanceId: string expected";
                    if (message.previousState != null && message.hasOwnProperty("previousState"))
                        switch (message.previousState) {
                        default:
                            return "previousState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TraderInstanceStateChange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.TraderInstanceStateChange} TraderInstanceStateChange
                 */
                TraderInstanceStateChange.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.TraderInstanceStateChange)
                        return object;
                    let message = new $root.mts.desk.events.TraderInstanceStateChange();
                    if (object.traderInstanceId != null)
                        message.traderInstanceId = String(object.traderInstanceId);
                    switch (object.previousState) {
                    default:
                        if (typeof object.previousState === "number") {
                            message.previousState = object.previousState;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.previousState = 0;
                        break;
                    case "NEW":
                    case 1:
                        message.previousState = 1;
                        break;
                    case "STARTING":
                    case 2:
                        message.previousState = 2;
                        break;
                    case "RUNNING":
                    case 3:
                        message.previousState = 3;
                        break;
                    case "STOPPING":
                    case 4:
                        message.previousState = 4;
                        break;
                    case "STOPPED":
                    case 5:
                        message.previousState = 5;
                        break;
                    case "FINISHED":
                    case 6:
                        message.previousState = 6;
                        break;
                    case "ERROR":
                    case 7:
                        message.previousState = 7;
                        break;
                    case "PAUSED":
                    case 8:
                        message.previousState = 8;
                        break;
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.state = 0;
                        break;
                    case "NEW":
                    case 1:
                        message.state = 1;
                        break;
                    case "STARTING":
                    case 2:
                        message.state = 2;
                        break;
                    case "RUNNING":
                    case 3:
                        message.state = 3;
                        break;
                    case "STOPPING":
                    case 4:
                        message.state = 4;
                        break;
                    case "STOPPED":
                    case 5:
                        message.state = 5;
                        break;
                    case "FINISHED":
                    case 6:
                        message.state = 6;
                        break;
                    case "ERROR":
                    case 7:
                        message.state = 7;
                        break;
                    case "PAUSED":
                    case 8:
                        message.state = 8;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TraderInstanceStateChange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {mts.desk.events.TraderInstanceStateChange} message TraderInstanceStateChange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TraderInstanceStateChange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.traderInstanceId = "";
                        object.previousState = options.enums === String ? "UNKNOWN" : 0;
                        object.state = options.enums === String ? "UNKNOWN" : 0;
                    }
                    if (message.traderInstanceId != null && message.hasOwnProperty("traderInstanceId"))
                        object.traderInstanceId = message.traderInstanceId;
                    if (message.previousState != null && message.hasOwnProperty("previousState"))
                        object.previousState = options.enums === String ? $root.mts.desk.TraderInstance.State[message.previousState] === undefined ? message.previousState : $root.mts.desk.TraderInstance.State[message.previousState] : message.previousState;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.mts.desk.TraderInstance.State[message.state] === undefined ? message.state : $root.mts.desk.TraderInstance.State[message.state] : message.state;
                    return object;
                };

                /**
                 * Converts this TraderInstanceStateChange to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TraderInstanceStateChange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TraderInstanceStateChange
                 * @function getTypeUrl
                 * @memberof mts.desk.events.TraderInstanceStateChange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TraderInstanceStateChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.TraderInstanceStateChange";
                };

                return TraderInstanceStateChange;
            })();

            events.TickEvent = (function() {

                /**
                 * Properties of a TickEvent.
                 * @memberof mts.desk.events
                 * @interface ITickEvent
                 * @property {string|null} [instanceId] TickEvent instanceId
                 * @property {mts.desk.ITick|null} [tick] TickEvent tick
                 */

                /**
                 * Constructs a new TickEvent.
                 * @memberof mts.desk.events
                 * @classdesc Represents a TickEvent.
                 * @implements ITickEvent
                 * @constructor
                 * @param {mts.desk.events.ITickEvent=} [properties] Properties to set
                 */
                function TickEvent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TickEvent instanceId.
                 * @member {string} instanceId
                 * @memberof mts.desk.events.TickEvent
                 * @instance
                 */
                TickEvent.prototype.instanceId = "";

                /**
                 * TickEvent tick.
                 * @member {mts.desk.ITick|null|undefined} tick
                 * @memberof mts.desk.events.TickEvent
                 * @instance
                 */
                TickEvent.prototype.tick = null;

                /**
                 * Creates a new TickEvent instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {mts.desk.events.ITickEvent=} [properties] Properties to set
                 * @returns {mts.desk.events.TickEvent} TickEvent instance
                 */
                TickEvent.create = function create(properties) {
                    return new TickEvent(properties);
                };

                /**
                 * Encodes the specified TickEvent message. Does not implicitly {@link mts.desk.events.TickEvent.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {mts.desk.events.ITickEvent} message TickEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TickEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                    if (message.tick != null && Object.hasOwnProperty.call(message, "tick"))
                        $root.mts.desk.Tick.encode(message.tick, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified TickEvent message, length delimited. Does not implicitly {@link mts.desk.events.TickEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {mts.desk.events.ITickEvent} message TickEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TickEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TickEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.TickEvent} TickEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TickEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.TickEvent();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.instanceId = reader.string();
                                break;
                            }
                        case 10: {
                                message.tick = $root.mts.desk.Tick.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TickEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.TickEvent} TickEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TickEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TickEvent message.
                 * @function verify
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TickEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        if (!$util.isString(message.instanceId))
                            return "instanceId: string expected";
                    if (message.tick != null && message.hasOwnProperty("tick")) {
                        let error = $root.mts.desk.Tick.verify(message.tick);
                        if (error)
                            return "tick." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TickEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.TickEvent} TickEvent
                 */
                TickEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.TickEvent)
                        return object;
                    let message = new $root.mts.desk.events.TickEvent();
                    if (object.instanceId != null)
                        message.instanceId = String(object.instanceId);
                    if (object.tick != null) {
                        if (typeof object.tick !== "object")
                            throw TypeError(".mts.desk.events.TickEvent.tick: object expected");
                        message.tick = $root.mts.desk.Tick.fromObject(object.tick);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TickEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {mts.desk.events.TickEvent} message TickEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TickEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.instanceId = "";
                        object.tick = null;
                    }
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        object.instanceId = message.instanceId;
                    if (message.tick != null && message.hasOwnProperty("tick"))
                        object.tick = $root.mts.desk.Tick.toObject(message.tick, options);
                    return object;
                };

                /**
                 * Converts this TickEvent to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.TickEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TickEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TickEvent
                 * @function getTypeUrl
                 * @memberof mts.desk.events.TickEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TickEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.TickEvent";
                };

                return TickEvent;
            })();

            events.TraderInstanceAdded = (function() {

                /**
                 * Properties of a TraderInstanceAdded.
                 * @memberof mts.desk.events
                 * @interface ITraderInstanceAdded
                 * @property {string|null} [instanceId] TraderInstanceAdded instanceId
                 */

                /**
                 * Constructs a new TraderInstanceAdded.
                 * @memberof mts.desk.events
                 * @classdesc Represents a TraderInstanceAdded.
                 * @implements ITraderInstanceAdded
                 * @constructor
                 * @param {mts.desk.events.ITraderInstanceAdded=} [properties] Properties to set
                 */
                function TraderInstanceAdded(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TraderInstanceAdded instanceId.
                 * @member {string} instanceId
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @instance
                 */
                TraderInstanceAdded.prototype.instanceId = "";

                /**
                 * Creates a new TraderInstanceAdded instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {mts.desk.events.ITraderInstanceAdded=} [properties] Properties to set
                 * @returns {mts.desk.events.TraderInstanceAdded} TraderInstanceAdded instance
                 */
                TraderInstanceAdded.create = function create(properties) {
                    return new TraderInstanceAdded(properties);
                };

                /**
                 * Encodes the specified TraderInstanceAdded message. Does not implicitly {@link mts.desk.events.TraderInstanceAdded.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {mts.desk.events.ITraderInstanceAdded} message TraderInstanceAdded message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceAdded.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                    return writer;
                };

                /**
                 * Encodes the specified TraderInstanceAdded message, length delimited. Does not implicitly {@link mts.desk.events.TraderInstanceAdded.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {mts.desk.events.ITraderInstanceAdded} message TraderInstanceAdded message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceAdded.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TraderInstanceAdded message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.TraderInstanceAdded} TraderInstanceAdded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceAdded.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.TraderInstanceAdded();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.instanceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TraderInstanceAdded message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.TraderInstanceAdded} TraderInstanceAdded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceAdded.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TraderInstanceAdded message.
                 * @function verify
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TraderInstanceAdded.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        if (!$util.isString(message.instanceId))
                            return "instanceId: string expected";
                    return null;
                };

                /**
                 * Creates a TraderInstanceAdded message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.TraderInstanceAdded} TraderInstanceAdded
                 */
                TraderInstanceAdded.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.TraderInstanceAdded)
                        return object;
                    let message = new $root.mts.desk.events.TraderInstanceAdded();
                    if (object.instanceId != null)
                        message.instanceId = String(object.instanceId);
                    return message;
                };

                /**
                 * Creates a plain object from a TraderInstanceAdded message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {mts.desk.events.TraderInstanceAdded} message TraderInstanceAdded
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TraderInstanceAdded.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.instanceId = "";
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        object.instanceId = message.instanceId;
                    return object;
                };

                /**
                 * Converts this TraderInstanceAdded to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TraderInstanceAdded.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TraderInstanceAdded
                 * @function getTypeUrl
                 * @memberof mts.desk.events.TraderInstanceAdded
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TraderInstanceAdded.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.TraderInstanceAdded";
                };

                return TraderInstanceAdded;
            })();

            events.TraderInstanceUpdated = (function() {

                /**
                 * Properties of a TraderInstanceUpdated.
                 * @memberof mts.desk.events
                 * @interface ITraderInstanceUpdated
                 * @property {string|null} [instanceId] TraderInstanceUpdated instanceId
                 */

                /**
                 * Constructs a new TraderInstanceUpdated.
                 * @memberof mts.desk.events
                 * @classdesc Represents a TraderInstanceUpdated.
                 * @implements ITraderInstanceUpdated
                 * @constructor
                 * @param {mts.desk.events.ITraderInstanceUpdated=} [properties] Properties to set
                 */
                function TraderInstanceUpdated(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TraderInstanceUpdated instanceId.
                 * @member {string} instanceId
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @instance
                 */
                TraderInstanceUpdated.prototype.instanceId = "";

                /**
                 * Creates a new TraderInstanceUpdated instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {mts.desk.events.ITraderInstanceUpdated=} [properties] Properties to set
                 * @returns {mts.desk.events.TraderInstanceUpdated} TraderInstanceUpdated instance
                 */
                TraderInstanceUpdated.create = function create(properties) {
                    return new TraderInstanceUpdated(properties);
                };

                /**
                 * Encodes the specified TraderInstanceUpdated message. Does not implicitly {@link mts.desk.events.TraderInstanceUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {mts.desk.events.ITraderInstanceUpdated} message TraderInstanceUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceUpdated.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                    return writer;
                };

                /**
                 * Encodes the specified TraderInstanceUpdated message, length delimited. Does not implicitly {@link mts.desk.events.TraderInstanceUpdated.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {mts.desk.events.ITraderInstanceUpdated} message TraderInstanceUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceUpdated.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TraderInstanceUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.TraderInstanceUpdated} TraderInstanceUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceUpdated.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.TraderInstanceUpdated();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.instanceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TraderInstanceUpdated message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.TraderInstanceUpdated} TraderInstanceUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceUpdated.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TraderInstanceUpdated message.
                 * @function verify
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TraderInstanceUpdated.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        if (!$util.isString(message.instanceId))
                            return "instanceId: string expected";
                    return null;
                };

                /**
                 * Creates a TraderInstanceUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.TraderInstanceUpdated} TraderInstanceUpdated
                 */
                TraderInstanceUpdated.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.TraderInstanceUpdated)
                        return object;
                    let message = new $root.mts.desk.events.TraderInstanceUpdated();
                    if (object.instanceId != null)
                        message.instanceId = String(object.instanceId);
                    return message;
                };

                /**
                 * Creates a plain object from a TraderInstanceUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {mts.desk.events.TraderInstanceUpdated} message TraderInstanceUpdated
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TraderInstanceUpdated.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.instanceId = "";
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        object.instanceId = message.instanceId;
                    return object;
                };

                /**
                 * Converts this TraderInstanceUpdated to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TraderInstanceUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TraderInstanceUpdated
                 * @function getTypeUrl
                 * @memberof mts.desk.events.TraderInstanceUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TraderInstanceUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.TraderInstanceUpdated";
                };

                return TraderInstanceUpdated;
            })();

            events.TraderAdded = (function() {

                /**
                 * Properties of a TraderAdded.
                 * @memberof mts.desk.events
                 * @interface ITraderAdded
                 * @property {string|null} [traderId] TraderAdded traderId
                 */

                /**
                 * Constructs a new TraderAdded.
                 * @memberof mts.desk.events
                 * @classdesc Represents a TraderAdded.
                 * @implements ITraderAdded
                 * @constructor
                 * @param {mts.desk.events.ITraderAdded=} [properties] Properties to set
                 */
                function TraderAdded(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TraderAdded traderId.
                 * @member {string} traderId
                 * @memberof mts.desk.events.TraderAdded
                 * @instance
                 */
                TraderAdded.prototype.traderId = "";

                /**
                 * Creates a new TraderAdded instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {mts.desk.events.ITraderAdded=} [properties] Properties to set
                 * @returns {mts.desk.events.TraderAdded} TraderAdded instance
                 */
                TraderAdded.create = function create(properties) {
                    return new TraderAdded(properties);
                };

                /**
                 * Encodes the specified TraderAdded message. Does not implicitly {@link mts.desk.events.TraderAdded.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {mts.desk.events.ITraderAdded} message TraderAdded message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderAdded.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.traderId != null && Object.hasOwnProperty.call(message, "traderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderId);
                    return writer;
                };

                /**
                 * Encodes the specified TraderAdded message, length delimited. Does not implicitly {@link mts.desk.events.TraderAdded.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {mts.desk.events.ITraderAdded} message TraderAdded message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderAdded.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TraderAdded message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.TraderAdded} TraderAdded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderAdded.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.TraderAdded();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.traderId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TraderAdded message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.TraderAdded} TraderAdded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderAdded.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TraderAdded message.
                 * @function verify
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TraderAdded.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.traderId != null && message.hasOwnProperty("traderId"))
                        if (!$util.isString(message.traderId))
                            return "traderId: string expected";
                    return null;
                };

                /**
                 * Creates a TraderAdded message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.TraderAdded} TraderAdded
                 */
                TraderAdded.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.TraderAdded)
                        return object;
                    let message = new $root.mts.desk.events.TraderAdded();
                    if (object.traderId != null)
                        message.traderId = String(object.traderId);
                    return message;
                };

                /**
                 * Creates a plain object from a TraderAdded message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {mts.desk.events.TraderAdded} message TraderAdded
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TraderAdded.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.traderId = "";
                    if (message.traderId != null && message.hasOwnProperty("traderId"))
                        object.traderId = message.traderId;
                    return object;
                };

                /**
                 * Converts this TraderAdded to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.TraderAdded
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TraderAdded.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TraderAdded
                 * @function getTypeUrl
                 * @memberof mts.desk.events.TraderAdded
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TraderAdded.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.TraderAdded";
                };

                return TraderAdded;
            })();

            events.TraderUpdated = (function() {

                /**
                 * Properties of a TraderUpdated.
                 * @memberof mts.desk.events
                 * @interface ITraderUpdated
                 * @property {string|null} [traderId] TraderUpdated traderId
                 */

                /**
                 * Constructs a new TraderUpdated.
                 * @memberof mts.desk.events
                 * @classdesc Represents a TraderUpdated.
                 * @implements ITraderUpdated
                 * @constructor
                 * @param {mts.desk.events.ITraderUpdated=} [properties] Properties to set
                 */
                function TraderUpdated(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TraderUpdated traderId.
                 * @member {string} traderId
                 * @memberof mts.desk.events.TraderUpdated
                 * @instance
                 */
                TraderUpdated.prototype.traderId = "";

                /**
                 * Creates a new TraderUpdated instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {mts.desk.events.ITraderUpdated=} [properties] Properties to set
                 * @returns {mts.desk.events.TraderUpdated} TraderUpdated instance
                 */
                TraderUpdated.create = function create(properties) {
                    return new TraderUpdated(properties);
                };

                /**
                 * Encodes the specified TraderUpdated message. Does not implicitly {@link mts.desk.events.TraderUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {mts.desk.events.ITraderUpdated} message TraderUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderUpdated.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.traderId != null && Object.hasOwnProperty.call(message, "traderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.traderId);
                    return writer;
                };

                /**
                 * Encodes the specified TraderUpdated message, length delimited. Does not implicitly {@link mts.desk.events.TraderUpdated.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {mts.desk.events.ITraderUpdated} message TraderUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderUpdated.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TraderUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.TraderUpdated} TraderUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderUpdated.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.TraderUpdated();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.traderId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TraderUpdated message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.TraderUpdated} TraderUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderUpdated.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TraderUpdated message.
                 * @function verify
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TraderUpdated.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.traderId != null && message.hasOwnProperty("traderId"))
                        if (!$util.isString(message.traderId))
                            return "traderId: string expected";
                    return null;
                };

                /**
                 * Creates a TraderUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.TraderUpdated} TraderUpdated
                 */
                TraderUpdated.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.TraderUpdated)
                        return object;
                    let message = new $root.mts.desk.events.TraderUpdated();
                    if (object.traderId != null)
                        message.traderId = String(object.traderId);
                    return message;
                };

                /**
                 * Creates a plain object from a TraderUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {mts.desk.events.TraderUpdated} message TraderUpdated
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TraderUpdated.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.traderId = "";
                    if (message.traderId != null && message.hasOwnProperty("traderId"))
                        object.traderId = message.traderId;
                    return object;
                };

                /**
                 * Converts this TraderUpdated to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.TraderUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TraderUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TraderUpdated
                 * @function getTypeUrl
                 * @memberof mts.desk.events.TraderUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TraderUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.TraderUpdated";
                };

                return TraderUpdated;
            })();

            events.TraderInstanceUpdatedEvent = (function() {

                /**
                 * Properties of a TraderInstanceUpdatedEvent.
                 * @memberof mts.desk.events
                 * @interface ITraderInstanceUpdatedEvent
                 * @property {mts.desk.ITraderInstance|null} [instance] TraderInstanceUpdatedEvent instance
                 */

                /**
                 * Constructs a new TraderInstanceUpdatedEvent.
                 * @memberof mts.desk.events
                 * @classdesc Represents a TraderInstanceUpdatedEvent.
                 * @implements ITraderInstanceUpdatedEvent
                 * @constructor
                 * @param {mts.desk.events.ITraderInstanceUpdatedEvent=} [properties] Properties to set
                 */
                function TraderInstanceUpdatedEvent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TraderInstanceUpdatedEvent instance.
                 * @member {mts.desk.ITraderInstance|null|undefined} instance
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @instance
                 */
                TraderInstanceUpdatedEvent.prototype.instance = null;

                /**
                 * Creates a new TraderInstanceUpdatedEvent instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {mts.desk.events.ITraderInstanceUpdatedEvent=} [properties] Properties to set
                 * @returns {mts.desk.events.TraderInstanceUpdatedEvent} TraderInstanceUpdatedEvent instance
                 */
                TraderInstanceUpdatedEvent.create = function create(properties) {
                    return new TraderInstanceUpdatedEvent(properties);
                };

                /**
                 * Encodes the specified TraderInstanceUpdatedEvent message. Does not implicitly {@link mts.desk.events.TraderInstanceUpdatedEvent.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {mts.desk.events.ITraderInstanceUpdatedEvent} message TraderInstanceUpdatedEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceUpdatedEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instance != null && Object.hasOwnProperty.call(message, "instance"))
                        $root.mts.desk.TraderInstance.encode(message.instance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified TraderInstanceUpdatedEvent message, length delimited. Does not implicitly {@link mts.desk.events.TraderInstanceUpdatedEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {mts.desk.events.ITraderInstanceUpdatedEvent} message TraderInstanceUpdatedEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TraderInstanceUpdatedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TraderInstanceUpdatedEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.TraderInstanceUpdatedEvent} TraderInstanceUpdatedEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceUpdatedEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.TraderInstanceUpdatedEvent();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.instance = $root.mts.desk.TraderInstance.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TraderInstanceUpdatedEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.TraderInstanceUpdatedEvent} TraderInstanceUpdatedEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TraderInstanceUpdatedEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TraderInstanceUpdatedEvent message.
                 * @function verify
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TraderInstanceUpdatedEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instance != null && message.hasOwnProperty("instance")) {
                        let error = $root.mts.desk.TraderInstance.verify(message.instance);
                        if (error)
                            return "instance." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TraderInstanceUpdatedEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.TraderInstanceUpdatedEvent} TraderInstanceUpdatedEvent
                 */
                TraderInstanceUpdatedEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.TraderInstanceUpdatedEvent)
                        return object;
                    let message = new $root.mts.desk.events.TraderInstanceUpdatedEvent();
                    if (object.instance != null) {
                        if (typeof object.instance !== "object")
                            throw TypeError(".mts.desk.events.TraderInstanceUpdatedEvent.instance: object expected");
                        message.instance = $root.mts.desk.TraderInstance.fromObject(object.instance);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TraderInstanceUpdatedEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {mts.desk.events.TraderInstanceUpdatedEvent} message TraderInstanceUpdatedEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TraderInstanceUpdatedEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.instance = null;
                    if (message.instance != null && message.hasOwnProperty("instance"))
                        object.instance = $root.mts.desk.TraderInstance.toObject(message.instance, options);
                    return object;
                };

                /**
                 * Converts this TraderInstanceUpdatedEvent to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TraderInstanceUpdatedEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TraderInstanceUpdatedEvent
                 * @function getTypeUrl
                 * @memberof mts.desk.events.TraderInstanceUpdatedEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TraderInstanceUpdatedEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.TraderInstanceUpdatedEvent";
                };

                return TraderInstanceUpdatedEvent;
            })();

            events.WatchlistCreated = (function() {

                /**
                 * Properties of a WatchlistCreated.
                 * @memberof mts.desk.events
                 * @interface IWatchlistCreated
                 * @property {mts.desk.IWatchlist|null} [watchlist] WatchlistCreated watchlist
                 */

                /**
                 * Constructs a new WatchlistCreated.
                 * @memberof mts.desk.events
                 * @classdesc Represents a WatchlistCreated.
                 * @implements IWatchlistCreated
                 * @constructor
                 * @param {mts.desk.events.IWatchlistCreated=} [properties] Properties to set
                 */
                function WatchlistCreated(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WatchlistCreated watchlist.
                 * @member {mts.desk.IWatchlist|null|undefined} watchlist
                 * @memberof mts.desk.events.WatchlistCreated
                 * @instance
                 */
                WatchlistCreated.prototype.watchlist = null;

                /**
                 * Creates a new WatchlistCreated instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {mts.desk.events.IWatchlistCreated=} [properties] Properties to set
                 * @returns {mts.desk.events.WatchlistCreated} WatchlistCreated instance
                 */
                WatchlistCreated.create = function create(properties) {
                    return new WatchlistCreated(properties);
                };

                /**
                 * Encodes the specified WatchlistCreated message. Does not implicitly {@link mts.desk.events.WatchlistCreated.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {mts.desk.events.IWatchlistCreated} message WatchlistCreated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WatchlistCreated.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.watchlist != null && Object.hasOwnProperty.call(message, "watchlist"))
                        $root.mts.desk.Watchlist.encode(message.watchlist, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified WatchlistCreated message, length delimited. Does not implicitly {@link mts.desk.events.WatchlistCreated.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {mts.desk.events.IWatchlistCreated} message WatchlistCreated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WatchlistCreated.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WatchlistCreated message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.WatchlistCreated} WatchlistCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WatchlistCreated.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.WatchlistCreated();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.watchlist = $root.mts.desk.Watchlist.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WatchlistCreated message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.WatchlistCreated} WatchlistCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WatchlistCreated.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WatchlistCreated message.
                 * @function verify
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WatchlistCreated.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.watchlist != null && message.hasOwnProperty("watchlist")) {
                        let error = $root.mts.desk.Watchlist.verify(message.watchlist);
                        if (error)
                            return "watchlist." + error;
                    }
                    return null;
                };

                /**
                 * Creates a WatchlistCreated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.WatchlistCreated} WatchlistCreated
                 */
                WatchlistCreated.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.WatchlistCreated)
                        return object;
                    let message = new $root.mts.desk.events.WatchlistCreated();
                    if (object.watchlist != null) {
                        if (typeof object.watchlist !== "object")
                            throw TypeError(".mts.desk.events.WatchlistCreated.watchlist: object expected");
                        message.watchlist = $root.mts.desk.Watchlist.fromObject(object.watchlist);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WatchlistCreated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {mts.desk.events.WatchlistCreated} message WatchlistCreated
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WatchlistCreated.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.watchlist = null;
                    if (message.watchlist != null && message.hasOwnProperty("watchlist"))
                        object.watchlist = $root.mts.desk.Watchlist.toObject(message.watchlist, options);
                    return object;
                };

                /**
                 * Converts this WatchlistCreated to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.WatchlistCreated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WatchlistCreated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WatchlistCreated
                 * @function getTypeUrl
                 * @memberof mts.desk.events.WatchlistCreated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WatchlistCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.WatchlistCreated";
                };

                return WatchlistCreated;
            })();

            events.InstanceOutputEvent = (function() {

                /**
                 * Properties of an InstanceOutputEvent.
                 * @memberof mts.desk.events
                 * @interface IInstanceOutputEvent
                 * @property {string|null} [instanceId] InstanceOutputEvent instanceId
                 * @property {mts.desk.ITraderInstanceOutput|null} [instanceOutput] InstanceOutputEvent instanceOutput
                 */

                /**
                 * Constructs a new InstanceOutputEvent.
                 * @memberof mts.desk.events
                 * @classdesc Represents an InstanceOutputEvent.
                 * @implements IInstanceOutputEvent
                 * @constructor
                 * @param {mts.desk.events.IInstanceOutputEvent=} [properties] Properties to set
                 */
                function InstanceOutputEvent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InstanceOutputEvent instanceId.
                 * @member {string} instanceId
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @instance
                 */
                InstanceOutputEvent.prototype.instanceId = "";

                /**
                 * InstanceOutputEvent instanceOutput.
                 * @member {mts.desk.ITraderInstanceOutput|null|undefined} instanceOutput
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @instance
                 */
                InstanceOutputEvent.prototype.instanceOutput = null;

                /**
                 * Creates a new InstanceOutputEvent instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {mts.desk.events.IInstanceOutputEvent=} [properties] Properties to set
                 * @returns {mts.desk.events.InstanceOutputEvent} InstanceOutputEvent instance
                 */
                InstanceOutputEvent.create = function create(properties) {
                    return new InstanceOutputEvent(properties);
                };

                /**
                 * Encodes the specified InstanceOutputEvent message. Does not implicitly {@link mts.desk.events.InstanceOutputEvent.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {mts.desk.events.IInstanceOutputEvent} message InstanceOutputEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InstanceOutputEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.instanceId);
                    if (message.instanceOutput != null && Object.hasOwnProperty.call(message, "instanceOutput"))
                        $root.mts.desk.TraderInstanceOutput.encode(message.instanceOutput, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InstanceOutputEvent message, length delimited. Does not implicitly {@link mts.desk.events.InstanceOutputEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {mts.desk.events.IInstanceOutputEvent} message InstanceOutputEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InstanceOutputEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InstanceOutputEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.InstanceOutputEvent} InstanceOutputEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InstanceOutputEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.InstanceOutputEvent();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.instanceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.instanceOutput = $root.mts.desk.TraderInstanceOutput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InstanceOutputEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.InstanceOutputEvent} InstanceOutputEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InstanceOutputEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InstanceOutputEvent message.
                 * @function verify
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InstanceOutputEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        if (!$util.isString(message.instanceId))
                            return "instanceId: string expected";
                    if (message.instanceOutput != null && message.hasOwnProperty("instanceOutput")) {
                        let error = $root.mts.desk.TraderInstanceOutput.verify(message.instanceOutput);
                        if (error)
                            return "instanceOutput." + error;
                    }
                    return null;
                };

                /**
                 * Creates an InstanceOutputEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.InstanceOutputEvent} InstanceOutputEvent
                 */
                InstanceOutputEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.InstanceOutputEvent)
                        return object;
                    let message = new $root.mts.desk.events.InstanceOutputEvent();
                    if (object.instanceId != null)
                        message.instanceId = String(object.instanceId);
                    if (object.instanceOutput != null) {
                        if (typeof object.instanceOutput !== "object")
                            throw TypeError(".mts.desk.events.InstanceOutputEvent.instanceOutput: object expected");
                        message.instanceOutput = $root.mts.desk.TraderInstanceOutput.fromObject(object.instanceOutput);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InstanceOutputEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {mts.desk.events.InstanceOutputEvent} message InstanceOutputEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InstanceOutputEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.instanceId = "";
                        object.instanceOutput = null;
                    }
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        object.instanceId = message.instanceId;
                    if (message.instanceOutput != null && message.hasOwnProperty("instanceOutput"))
                        object.instanceOutput = $root.mts.desk.TraderInstanceOutput.toObject(message.instanceOutput, options);
                    return object;
                };

                /**
                 * Converts this InstanceOutputEvent to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InstanceOutputEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InstanceOutputEvent
                 * @function getTypeUrl
                 * @memberof mts.desk.events.InstanceOutputEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InstanceOutputEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.InstanceOutputEvent";
                };

                return InstanceOutputEvent;
            })();

            events.DeskEvent = (function() {

                /**
                 * Properties of a DeskEvent.
                 * @memberof mts.desk.events
                 * @interface IDeskEvent
                 * @property {number|Long|null} [time] DeskEvent time
                 * @property {mts.desk.events.ITickEvent|null} [tickEvent] DeskEvent tickEvent
                 * @property {mts.desk.events.ITraderInstanceStateChange|null} [traderInstanceStateChange] DeskEvent traderInstanceStateChange
                 * @property {mts.desk.events.ITraderInstanceAdded|null} [traderInstanceAdded] DeskEvent traderInstanceAdded
                 * @property {mts.desk.events.ITraderInstanceUpdatedEvent|null} [traderInstanceUpdated] DeskEvent traderInstanceUpdated
                 * @property {mts.desk.events.ITraderAdded|null} [traderAdded] DeskEvent traderAdded
                 * @property {mts.desk.events.ITraderUpdated|null} [traderUpdated] DeskEvent traderUpdated
                 * @property {mts.desk.events.IWatchlistCreated|null} [watchlistCreated] DeskEvent watchlistCreated
                 * @property {mts.desk.events.IInstanceOutputEvent|null} [instanceOutputEvent] DeskEvent instanceOutputEvent
                 */

                /**
                 * Constructs a new DeskEvent.
                 * @memberof mts.desk.events
                 * @classdesc Desk produced events. Mostly about which traders are running, and updates on their status.
                 * @implements IDeskEvent
                 * @constructor
                 * @param {mts.desk.events.IDeskEvent=} [properties] Properties to set
                 */
                function DeskEvent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeskEvent time.
                 * @member {number|Long} time
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DeskEvent tickEvent.
                 * @member {mts.desk.events.ITickEvent|null|undefined} tickEvent
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.tickEvent = null;

                /**
                 * DeskEvent traderInstanceStateChange.
                 * @member {mts.desk.events.ITraderInstanceStateChange|null|undefined} traderInstanceStateChange
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.traderInstanceStateChange = null;

                /**
                 * DeskEvent traderInstanceAdded.
                 * @member {mts.desk.events.ITraderInstanceAdded|null|undefined} traderInstanceAdded
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.traderInstanceAdded = null;

                /**
                 * DeskEvent traderInstanceUpdated.
                 * @member {mts.desk.events.ITraderInstanceUpdatedEvent|null|undefined} traderInstanceUpdated
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.traderInstanceUpdated = null;

                /**
                 * DeskEvent traderAdded.
                 * @member {mts.desk.events.ITraderAdded|null|undefined} traderAdded
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.traderAdded = null;

                /**
                 * DeskEvent traderUpdated.
                 * @member {mts.desk.events.ITraderUpdated|null|undefined} traderUpdated
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.traderUpdated = null;

                /**
                 * DeskEvent watchlistCreated.
                 * @member {mts.desk.events.IWatchlistCreated|null|undefined} watchlistCreated
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.watchlistCreated = null;

                /**
                 * DeskEvent instanceOutputEvent.
                 * @member {mts.desk.events.IInstanceOutputEvent|null|undefined} instanceOutputEvent
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                DeskEvent.prototype.instanceOutputEvent = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * DeskEvent eventType.
                 * @member {"tickEvent"|"traderInstanceStateChange"|"traderInstanceAdded"|"traderInstanceUpdated"|"traderAdded"|"traderUpdated"|"watchlistCreated"|"instanceOutputEvent"|undefined} eventType
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 */
                Object.defineProperty(DeskEvent.prototype, "eventType", {
                    get: $util.oneOfGetter($oneOfFields = ["tickEvent", "traderInstanceStateChange", "traderInstanceAdded", "traderInstanceUpdated", "traderAdded", "traderUpdated", "watchlistCreated", "instanceOutputEvent"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new DeskEvent instance using the specified properties.
                 * @function create
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {mts.desk.events.IDeskEvent=} [properties] Properties to set
                 * @returns {mts.desk.events.DeskEvent} DeskEvent instance
                 */
                DeskEvent.create = function create(properties) {
                    return new DeskEvent(properties);
                };

                /**
                 * Encodes the specified DeskEvent message. Does not implicitly {@link mts.desk.events.DeskEvent.verify|verify} messages.
                 * @function encode
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {mts.desk.events.IDeskEvent} message DeskEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeskEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    if (message.tickEvent != null && Object.hasOwnProperty.call(message, "tickEvent"))
                        $root.mts.desk.events.TickEvent.encode(message.tickEvent, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.traderInstanceStateChange != null && Object.hasOwnProperty.call(message, "traderInstanceStateChange"))
                        $root.mts.desk.events.TraderInstanceStateChange.encode(message.traderInstanceStateChange, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.traderInstanceAdded != null && Object.hasOwnProperty.call(message, "traderInstanceAdded"))
                        $root.mts.desk.events.TraderInstanceAdded.encode(message.traderInstanceAdded, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.traderAdded != null && Object.hasOwnProperty.call(message, "traderAdded"))
                        $root.mts.desk.events.TraderAdded.encode(message.traderAdded, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.traderUpdated != null && Object.hasOwnProperty.call(message, "traderUpdated"))
                        $root.mts.desk.events.TraderUpdated.encode(message.traderUpdated, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.traderInstanceUpdated != null && Object.hasOwnProperty.call(message, "traderInstanceUpdated"))
                        $root.mts.desk.events.TraderInstanceUpdatedEvent.encode(message.traderInstanceUpdated, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.watchlistCreated != null && Object.hasOwnProperty.call(message, "watchlistCreated"))
                        $root.mts.desk.events.WatchlistCreated.encode(message.watchlistCreated, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.instanceOutputEvent != null && Object.hasOwnProperty.call(message, "instanceOutputEvent"))
                        $root.mts.desk.events.InstanceOutputEvent.encode(message.instanceOutputEvent, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified DeskEvent message, length delimited. Does not implicitly {@link mts.desk.events.DeskEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {mts.desk.events.IDeskEvent} message DeskEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeskEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DeskEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.desk.events.DeskEvent} DeskEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeskEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.desk.events.DeskEvent();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        case 10: {
                                message.tickEvent = $root.mts.desk.events.TickEvent.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.traderInstanceStateChange = $root.mts.desk.events.TraderInstanceStateChange.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.traderInstanceAdded = $root.mts.desk.events.TraderInstanceAdded.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.traderInstanceUpdated = $root.mts.desk.events.TraderInstanceUpdatedEvent.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.traderAdded = $root.mts.desk.events.TraderAdded.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.traderUpdated = $root.mts.desk.events.TraderUpdated.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.watchlistCreated = $root.mts.desk.events.WatchlistCreated.decode(reader, reader.uint32());
                                break;
                            }
                        case 20: {
                                message.instanceOutputEvent = $root.mts.desk.events.InstanceOutputEvent.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DeskEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.desk.events.DeskEvent} DeskEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeskEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DeskEvent message.
                 * @function verify
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeskEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.tickEvent != null && message.hasOwnProperty("tickEvent")) {
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.TickEvent.verify(message.tickEvent);
                            if (error)
                                return "tickEvent." + error;
                        }
                    }
                    if (message.traderInstanceStateChange != null && message.hasOwnProperty("traderInstanceStateChange")) {
                        if (properties.eventType === 1)
                            return "eventType: multiple values";
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.TraderInstanceStateChange.verify(message.traderInstanceStateChange);
                            if (error)
                                return "traderInstanceStateChange." + error;
                        }
                    }
                    if (message.traderInstanceAdded != null && message.hasOwnProperty("traderInstanceAdded")) {
                        if (properties.eventType === 1)
                            return "eventType: multiple values";
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.TraderInstanceAdded.verify(message.traderInstanceAdded);
                            if (error)
                                return "traderInstanceAdded." + error;
                        }
                    }
                    if (message.traderInstanceUpdated != null && message.hasOwnProperty("traderInstanceUpdated")) {
                        if (properties.eventType === 1)
                            return "eventType: multiple values";
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.TraderInstanceUpdatedEvent.verify(message.traderInstanceUpdated);
                            if (error)
                                return "traderInstanceUpdated." + error;
                        }
                    }
                    if (message.traderAdded != null && message.hasOwnProperty("traderAdded")) {
                        if (properties.eventType === 1)
                            return "eventType: multiple values";
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.TraderAdded.verify(message.traderAdded);
                            if (error)
                                return "traderAdded." + error;
                        }
                    }
                    if (message.traderUpdated != null && message.hasOwnProperty("traderUpdated")) {
                        if (properties.eventType === 1)
                            return "eventType: multiple values";
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.TraderUpdated.verify(message.traderUpdated);
                            if (error)
                                return "traderUpdated." + error;
                        }
                    }
                    if (message.watchlistCreated != null && message.hasOwnProperty("watchlistCreated")) {
                        if (properties.eventType === 1)
                            return "eventType: multiple values";
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.WatchlistCreated.verify(message.watchlistCreated);
                            if (error)
                                return "watchlistCreated." + error;
                        }
                    }
                    if (message.instanceOutputEvent != null && message.hasOwnProperty("instanceOutputEvent")) {
                        if (properties.eventType === 1)
                            return "eventType: multiple values";
                        properties.eventType = 1;
                        {
                            let error = $root.mts.desk.events.InstanceOutputEvent.verify(message.instanceOutputEvent);
                            if (error)
                                return "instanceOutputEvent." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a DeskEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.desk.events.DeskEvent} DeskEvent
                 */
                DeskEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.desk.events.DeskEvent)
                        return object;
                    let message = new $root.mts.desk.events.DeskEvent();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.tickEvent != null) {
                        if (typeof object.tickEvent !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.tickEvent: object expected");
                        message.tickEvent = $root.mts.desk.events.TickEvent.fromObject(object.tickEvent);
                    }
                    if (object.traderInstanceStateChange != null) {
                        if (typeof object.traderInstanceStateChange !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.traderInstanceStateChange: object expected");
                        message.traderInstanceStateChange = $root.mts.desk.events.TraderInstanceStateChange.fromObject(object.traderInstanceStateChange);
                    }
                    if (object.traderInstanceAdded != null) {
                        if (typeof object.traderInstanceAdded !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.traderInstanceAdded: object expected");
                        message.traderInstanceAdded = $root.mts.desk.events.TraderInstanceAdded.fromObject(object.traderInstanceAdded);
                    }
                    if (object.traderInstanceUpdated != null) {
                        if (typeof object.traderInstanceUpdated !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.traderInstanceUpdated: object expected");
                        message.traderInstanceUpdated = $root.mts.desk.events.TraderInstanceUpdatedEvent.fromObject(object.traderInstanceUpdated);
                    }
                    if (object.traderAdded != null) {
                        if (typeof object.traderAdded !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.traderAdded: object expected");
                        message.traderAdded = $root.mts.desk.events.TraderAdded.fromObject(object.traderAdded);
                    }
                    if (object.traderUpdated != null) {
                        if (typeof object.traderUpdated !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.traderUpdated: object expected");
                        message.traderUpdated = $root.mts.desk.events.TraderUpdated.fromObject(object.traderUpdated);
                    }
                    if (object.watchlistCreated != null) {
                        if (typeof object.watchlistCreated !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.watchlistCreated: object expected");
                        message.watchlistCreated = $root.mts.desk.events.WatchlistCreated.fromObject(object.watchlistCreated);
                    }
                    if (object.instanceOutputEvent != null) {
                        if (typeof object.instanceOutputEvent !== "object")
                            throw TypeError(".mts.desk.events.DeskEvent.instanceOutputEvent: object expected");
                        message.instanceOutputEvent = $root.mts.desk.events.InstanceOutputEvent.fromObject(object.instanceOutputEvent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeskEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {mts.desk.events.DeskEvent} message DeskEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeskEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.tickEvent != null && message.hasOwnProperty("tickEvent")) {
                        object.tickEvent = $root.mts.desk.events.TickEvent.toObject(message.tickEvent, options);
                        if (options.oneofs)
                            object.eventType = "tickEvent";
                    }
                    if (message.traderInstanceStateChange != null && message.hasOwnProperty("traderInstanceStateChange")) {
                        object.traderInstanceStateChange = $root.mts.desk.events.TraderInstanceStateChange.toObject(message.traderInstanceStateChange, options);
                        if (options.oneofs)
                            object.eventType = "traderInstanceStateChange";
                    }
                    if (message.traderInstanceAdded != null && message.hasOwnProperty("traderInstanceAdded")) {
                        object.traderInstanceAdded = $root.mts.desk.events.TraderInstanceAdded.toObject(message.traderInstanceAdded, options);
                        if (options.oneofs)
                            object.eventType = "traderInstanceAdded";
                    }
                    if (message.traderAdded != null && message.hasOwnProperty("traderAdded")) {
                        object.traderAdded = $root.mts.desk.events.TraderAdded.toObject(message.traderAdded, options);
                        if (options.oneofs)
                            object.eventType = "traderAdded";
                    }
                    if (message.traderUpdated != null && message.hasOwnProperty("traderUpdated")) {
                        object.traderUpdated = $root.mts.desk.events.TraderUpdated.toObject(message.traderUpdated, options);
                        if (options.oneofs)
                            object.eventType = "traderUpdated";
                    }
                    if (message.traderInstanceUpdated != null && message.hasOwnProperty("traderInstanceUpdated")) {
                        object.traderInstanceUpdated = $root.mts.desk.events.TraderInstanceUpdatedEvent.toObject(message.traderInstanceUpdated, options);
                        if (options.oneofs)
                            object.eventType = "traderInstanceUpdated";
                    }
                    if (message.watchlistCreated != null && message.hasOwnProperty("watchlistCreated")) {
                        object.watchlistCreated = $root.mts.desk.events.WatchlistCreated.toObject(message.watchlistCreated, options);
                        if (options.oneofs)
                            object.eventType = "watchlistCreated";
                    }
                    if (message.instanceOutputEvent != null && message.hasOwnProperty("instanceOutputEvent")) {
                        object.instanceOutputEvent = $root.mts.desk.events.InstanceOutputEvent.toObject(message.instanceOutputEvent, options);
                        if (options.oneofs)
                            object.eventType = "instanceOutputEvent";
                    }
                    return object;
                };

                /**
                 * Converts this DeskEvent to JSON.
                 * @function toJSON
                 * @memberof mts.desk.events.DeskEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeskEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeskEvent
                 * @function getTypeUrl
                 * @memberof mts.desk.events.DeskEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeskEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.desk.events.DeskEvent";
                };

                return DeskEvent;
            })();

            return events;
        })();

        return desk;
    })();

    mts.broker = (function() {

        /**
         * Namespace broker.
         * @memberof mts
         * @namespace
         */
        const broker = {};

        broker.BrokerAdmin = (function() {

            /**
             * Constructs a new BrokerAdmin service.
             * @memberof mts.broker
             * @classdesc Represents a BrokerAdmin
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function BrokerAdmin(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (BrokerAdmin.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BrokerAdmin;

            /**
             * Creates new BrokerAdmin service using the specified rpc implementation.
             * @function create
             * @memberof mts.broker.BrokerAdmin
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {BrokerAdmin} RPC service. Useful where requests and/or responses are streamed.
             */
            BrokerAdmin.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link mts.broker.BrokerAdmin#listAccounts}.
             * @memberof mts.broker.BrokerAdmin
             * @typedef listAccountsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.ListAccountsResponse} [response] ListAccountsResponse
             */

            /**
             * Calls listAccounts.
             * @function listAccounts
             * @memberof mts.broker.BrokerAdmin
             * @instance
             * @param {mts.broker.IListAccountsRequest} request ListAccountsRequest message or plain object
             * @param {mts.broker.BrokerAdmin.listAccountsCallback} callback Node-style callback called with the error, if any, and ListAccountsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerAdmin.prototype.listAccounts = function listAccounts(request, callback) {
                return this.rpcCall(listAccounts, $root.mts.broker.ListAccountsRequest, $root.mts.broker.ListAccountsResponse, request, callback);
            }, "name", { value: "listAccounts" });

            /**
             * Calls listAccounts.
             * @function listAccounts
             * @memberof mts.broker.BrokerAdmin
             * @instance
             * @param {mts.broker.IListAccountsRequest} request ListAccountsRequest message or plain object
             * @returns {Promise<mts.broker.ListAccountsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerAdmin#addAccount}.
             * @memberof mts.broker.BrokerAdmin
             * @typedef addAccountCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.Account} [response] Account
             */

            /**
             * Calls addAccount.
             * @function addAccount
             * @memberof mts.broker.BrokerAdmin
             * @instance
             * @param {mts.broker.IAddAccountRequest} request AddAccountRequest message or plain object
             * @param {mts.broker.BrokerAdmin.addAccountCallback} callback Node-style callback called with the error, if any, and Account
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerAdmin.prototype.addAccount = function addAccount(request, callback) {
                return this.rpcCall(addAccount, $root.mts.broker.AddAccountRequest, $root.mts.broker.Account, request, callback);
            }, "name", { value: "addAccount" });

            /**
             * Calls addAccount.
             * @function addAccount
             * @memberof mts.broker.BrokerAdmin
             * @instance
             * @param {mts.broker.IAddAccountRequest} request AddAccountRequest message or plain object
             * @returns {Promise<mts.broker.Account>} Promise
             * @variation 2
             */

            return BrokerAdmin;
        })();

        broker.BrokerClientService = (function() {

            /**
             * Constructs a new BrokerClientService service.
             * @memberof mts.broker
             * @classdesc Represents a BrokerClientService
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function BrokerClientService(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (BrokerClientService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BrokerClientService;

            /**
             * Creates new BrokerClientService service using the specified rpc implementation.
             * @function create
             * @memberof mts.broker.BrokerClientService
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {BrokerClientService} RPC service. Useful where requests and/or responses are streamed.
             */
            BrokerClientService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#check}.
             * @memberof mts.broker.BrokerClientService
             * @typedef checkCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.HealthCheckResponse} [response] HealthCheckResponse
             */

            /**
             * Calls check.
             * @function check
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IHealthCheckRequest} request HealthCheckRequest message or plain object
             * @param {mts.broker.BrokerClientService.checkCallback} callback Node-style callback called with the error, if any, and HealthCheckResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.check = function check(request, callback) {
                return this.rpcCall(check, $root.mts.broker.HealthCheckRequest, $root.mts.broker.HealthCheckResponse, request, callback);
            }, "name", { value: "check" });

            /**
             * Calls check.
             * @function check
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IHealthCheckRequest} request HealthCheckRequest message or plain object
             * @returns {Promise<mts.broker.HealthCheckResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#watch}.
             * @memberof mts.broker.BrokerClientService
             * @typedef watchCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.HealthCheckResponse} [response] HealthCheckResponse
             */

            /**
             * Calls watch.
             * @function watch
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IHealthCheckRequest} request HealthCheckRequest message or plain object
             * @param {mts.broker.BrokerClientService.watchCallback} callback Node-style callback called with the error, if any, and HealthCheckResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.watch = function watch(request, callback) {
                return this.rpcCall(watch, $root.mts.broker.HealthCheckRequest, $root.mts.broker.HealthCheckResponse, request, callback);
            }, "name", { value: "watch" });

            /**
             * Calls watch.
             * @function watch
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IHealthCheckRequest} request HealthCheckRequest message or plain object
             * @returns {Promise<mts.broker.HealthCheckResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#status}.
             * @memberof mts.broker.BrokerClientService
             * @typedef statusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.StatusResponse} [response] StatusResponse
             */

            /**
             * Calls status.
             * @function status
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IStatusRequest} request StatusRequest message or plain object
             * @param {mts.broker.BrokerClientService.statusCallback} callback Node-style callback called with the error, if any, and StatusResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.status = function status(request, callback) {
                return this.rpcCall(status, $root.mts.broker.StatusRequest, $root.mts.broker.StatusResponse, request, callback);
            }, "name", { value: "status" });

            /**
             * Calls status.
             * @function status
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IStatusRequest} request StatusRequest message or plain object
             * @returns {Promise<mts.broker.StatusResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#createAccount}.
             * @memberof mts.broker.BrokerClientService
             * @typedef createAccountCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.Account} [response] Account
             */

            /**
             * Calls createAccount.
             * @function createAccount
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.ICreateAccountRequest} request CreateAccountRequest message or plain object
             * @param {mts.broker.BrokerClientService.createAccountCallback} callback Node-style callback called with the error, if any, and Account
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.createAccount = function createAccount(request, callback) {
                return this.rpcCall(createAccount, $root.mts.broker.CreateAccountRequest, $root.mts.broker.Account, request, callback);
            }, "name", { value: "createAccount" });

            /**
             * Calls createAccount.
             * @function createAccount
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.ICreateAccountRequest} request CreateAccountRequest message or plain object
             * @returns {Promise<mts.broker.Account>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#getAccount}.
             * @memberof mts.broker.BrokerClientService
             * @typedef getAccountCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.Account} [response] Account
             */

            /**
             * Calls getAccount.
             * @function getAccount
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IGetAccountRequest} request GetAccountRequest message or plain object
             * @param {mts.broker.BrokerClientService.getAccountCallback} callback Node-style callback called with the error, if any, and Account
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.getAccount = function getAccount(request, callback) {
                return this.rpcCall(getAccount, $root.mts.broker.GetAccountRequest, $root.mts.broker.Account, request, callback);
            }, "name", { value: "getAccount" });

            /**
             * Calls getAccount.
             * @function getAccount
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IGetAccountRequest} request GetAccountRequest message or plain object
             * @returns {Promise<mts.broker.Account>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#placeOrder}.
             * @memberof mts.broker.BrokerClientService
             * @typedef placeOrderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.CreateOrderResponse} [response] CreateOrderResponse
             */

            /**
             * * Order methods
             * @function placeOrder
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.ICreateOrderRequest} request CreateOrderRequest message or plain object
             * @param {mts.broker.BrokerClientService.placeOrderCallback} callback Node-style callback called with the error, if any, and CreateOrderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.placeOrder = function placeOrder(request, callback) {
                return this.rpcCall(placeOrder, $root.mts.broker.CreateOrderRequest, $root.mts.broker.CreateOrderResponse, request, callback);
            }, "name", { value: "placeOrder" });

            /**
             * * Order methods
             * @function placeOrder
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.ICreateOrderRequest} request CreateOrderRequest message or plain object
             * @returns {Promise<mts.broker.CreateOrderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#cancelOrder}.
             * @memberof mts.broker.BrokerClientService
             * @typedef cancelOrderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.CancelOrderResponse} [response] CancelOrderResponse
             */

            /**
             * Calls cancelOrder.
             * @function cancelOrder
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.ICancelOrderRequest} request CancelOrderRequest message or plain object
             * @param {mts.broker.BrokerClientService.cancelOrderCallback} callback Node-style callback called with the error, if any, and CancelOrderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.cancelOrder = function cancelOrder(request, callback) {
                return this.rpcCall(cancelOrder, $root.mts.broker.CancelOrderRequest, $root.mts.broker.CancelOrderResponse, request, callback);
            }, "name", { value: "cancelOrder" });

            /**
             * Calls cancelOrder.
             * @function cancelOrder
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.ICancelOrderRequest} request CancelOrderRequest message or plain object
             * @returns {Promise<mts.broker.CancelOrderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#getOrders}.
             * @memberof mts.broker.BrokerClientService
             * @typedef getOrdersCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.GetOrdersResponse} [response] GetOrdersResponse
             */

            /**
             * Calls getOrders.
             * @function getOrders
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IGetOrdersRequest} request GetOrdersRequest message or plain object
             * @param {mts.broker.BrokerClientService.getOrdersCallback} callback Node-style callback called with the error, if any, and GetOrdersResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.getOrders = function getOrders(request, callback) {
                return this.rpcCall(getOrders, $root.mts.broker.GetOrdersRequest, $root.mts.broker.GetOrdersResponse, request, callback);
            }, "name", { value: "getOrders" });

            /**
             * Calls getOrders.
             * @function getOrders
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IGetOrdersRequest} request GetOrdersRequest message or plain object
             * @returns {Promise<mts.broker.GetOrdersResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#getPositions}.
             * @memberof mts.broker.BrokerClientService
             * @typedef getPositionsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.Positions} [response] Positions
             */

            /**
             * Calls getPositions.
             * @function getPositions
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IGetPositionsRequest} request GetPositionsRequest message or plain object
             * @param {mts.broker.BrokerClientService.getPositionsCallback} callback Node-style callback called with the error, if any, and Positions
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.getPositions = function getPositions(request, callback) {
                return this.rpcCall(getPositions, $root.mts.broker.GetPositionsRequest, $root.mts.broker.Positions, request, callback);
            }, "name", { value: "getPositions" });

            /**
             * Calls getPositions.
             * @function getPositions
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IGetPositionsRequest} request GetPositionsRequest message or plain object
             * @returns {Promise<mts.broker.Positions>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#accountUpdates}.
             * @memberof mts.broker.BrokerClientService
             * @typedef accountUpdatesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.UpdateMessage} [response] UpdateMessage
             */

            /**
             * Calls accountUpdates.
             * @function accountUpdates
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IAccountUpdatesRequest} request AccountUpdatesRequest message or plain object
             * @param {mts.broker.BrokerClientService.accountUpdatesCallback} callback Node-style callback called with the error, if any, and UpdateMessage
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.accountUpdates = function accountUpdates(request, callback) {
                return this.rpcCall(accountUpdates, $root.mts.broker.AccountUpdatesRequest, $root.mts.broker.UpdateMessage, request, callback);
            }, "name", { value: "accountUpdates" });

            /**
             * Calls accountUpdates.
             * @function accountUpdates
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IAccountUpdatesRequest} request AccountUpdatesRequest message or plain object
             * @returns {Promise<mts.broker.UpdateMessage>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.broker.BrokerClientService#deposit}.
             * @memberof mts.broker.BrokerClientService
             * @typedef depositCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.broker.DepositResponse} [response] DepositResponse
             */

            /**
             * Calls deposit.
             * @function deposit
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IDepositRequest} request DepositRequest message or plain object
             * @param {mts.broker.BrokerClientService.depositCallback} callback Node-style callback called with the error, if any, and DepositResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BrokerClientService.prototype.deposit = function deposit(request, callback) {
                return this.rpcCall(deposit, $root.mts.broker.DepositRequest, $root.mts.broker.DepositResponse, request, callback);
            }, "name", { value: "deposit" });

            /**
             * Calls deposit.
             * @function deposit
             * @memberof mts.broker.BrokerClientService
             * @instance
             * @param {mts.broker.IDepositRequest} request DepositRequest message or plain object
             * @returns {Promise<mts.broker.DepositResponse>} Promise
             * @variation 2
             */

            return BrokerClientService;
        })();

        broker.DepositRequest = (function() {

            /**
             * Properties of a DepositRequest.
             * @memberof mts.broker
             * @interface IDepositRequest
             * @property {string|null} [accountId] DepositRequest accountId
             * @property {number|null} [amount] DepositRequest amount
             * @property {string|null} [date] DepositRequest date
             */

            /**
             * Constructs a new DepositRequest.
             * @memberof mts.broker
             * @classdesc Represents a DepositRequest.
             * @implements IDepositRequest
             * @constructor
             * @param {mts.broker.IDepositRequest=} [properties] Properties to set
             */
            function DepositRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DepositRequest accountId.
             * @member {string} accountId
             * @memberof mts.broker.DepositRequest
             * @instance
             */
            DepositRequest.prototype.accountId = "";

            /**
             * DepositRequest amount.
             * @member {number} amount
             * @memberof mts.broker.DepositRequest
             * @instance
             */
            DepositRequest.prototype.amount = 0;

            /**
             * DepositRequest date.
             * @member {string} date
             * @memberof mts.broker.DepositRequest
             * @instance
             */
            DepositRequest.prototype.date = "";

            /**
             * Creates a new DepositRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {mts.broker.IDepositRequest=} [properties] Properties to set
             * @returns {mts.broker.DepositRequest} DepositRequest instance
             */
            DepositRequest.create = function create(properties) {
                return new DepositRequest(properties);
            };

            /**
             * Encodes the specified DepositRequest message. Does not implicitly {@link mts.broker.DepositRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {mts.broker.IDepositRequest} message DepositRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DepositRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.date);
                return writer;
            };

            /**
             * Encodes the specified DepositRequest message, length delimited. Does not implicitly {@link mts.broker.DepositRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {mts.broker.IDepositRequest} message DepositRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DepositRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DepositRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.DepositRequest} DepositRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DepositRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.DepositRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accountId = reader.string();
                            break;
                        }
                    case 2: {
                            message.amount = reader.double();
                            break;
                        }
                    case 3: {
                            message.date = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DepositRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.DepositRequest} DepositRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DepositRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DepositRequest message.
             * @function verify
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DepositRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount !== "number")
                        return "amount: number expected";
                if (message.date != null && message.hasOwnProperty("date"))
                    if (!$util.isString(message.date))
                        return "date: string expected";
                return null;
            };

            /**
             * Creates a DepositRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.DepositRequest} DepositRequest
             */
            DepositRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.DepositRequest)
                    return object;
                let message = new $root.mts.broker.DepositRequest();
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                if (object.amount != null)
                    message.amount = Number(object.amount);
                if (object.date != null)
                    message.date = String(object.date);
                return message;
            };

            /**
             * Creates a plain object from a DepositRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {mts.broker.DepositRequest} message DepositRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DepositRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.accountId = "";
                    object.amount = 0;
                    object.date = "";
                }
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = message.date;
                return object;
            };

            /**
             * Converts this DepositRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.DepositRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DepositRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DepositRequest
             * @function getTypeUrl
             * @memberof mts.broker.DepositRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DepositRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.DepositRequest";
            };

            return DepositRequest;
        })();

        broker.DepositResponse = (function() {

            /**
             * Properties of a DepositResponse.
             * @memberof mts.broker
             * @interface IDepositResponse
             */

            /**
             * Constructs a new DepositResponse.
             * @memberof mts.broker
             * @classdesc Represents a DepositResponse.
             * @implements IDepositResponse
             * @constructor
             * @param {mts.broker.IDepositResponse=} [properties] Properties to set
             */
            function DepositResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DepositResponse instance using the specified properties.
             * @function create
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {mts.broker.IDepositResponse=} [properties] Properties to set
             * @returns {mts.broker.DepositResponse} DepositResponse instance
             */
            DepositResponse.create = function create(properties) {
                return new DepositResponse(properties);
            };

            /**
             * Encodes the specified DepositResponse message. Does not implicitly {@link mts.broker.DepositResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {mts.broker.IDepositResponse} message DepositResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DepositResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DepositResponse message, length delimited. Does not implicitly {@link mts.broker.DepositResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {mts.broker.IDepositResponse} message DepositResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DepositResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DepositResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.DepositResponse} DepositResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DepositResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.DepositResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DepositResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.DepositResponse} DepositResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DepositResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DepositResponse message.
             * @function verify
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DepositResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DepositResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.DepositResponse} DepositResponse
             */
            DepositResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.DepositResponse)
                    return object;
                return new $root.mts.broker.DepositResponse();
            };

            /**
             * Creates a plain object from a DepositResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {mts.broker.DepositResponse} message DepositResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DepositResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DepositResponse to JSON.
             * @function toJSON
             * @memberof mts.broker.DepositResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DepositResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DepositResponse
             * @function getTypeUrl
             * @memberof mts.broker.DepositResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DepositResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.DepositResponse";
            };

            return DepositResponse;
        })();

        broker.StatusRequest = (function() {

            /**
             * Properties of a StatusRequest.
             * @memberof mts.broker
             * @interface IStatusRequest
             */

            /**
             * Constructs a new StatusRequest.
             * @memberof mts.broker
             * @classdesc Represents a StatusRequest.
             * @implements IStatusRequest
             * @constructor
             * @param {mts.broker.IStatusRequest=} [properties] Properties to set
             */
            function StatusRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new StatusRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {mts.broker.IStatusRequest=} [properties] Properties to set
             * @returns {mts.broker.StatusRequest} StatusRequest instance
             */
            StatusRequest.create = function create(properties) {
                return new StatusRequest(properties);
            };

            /**
             * Encodes the specified StatusRequest message. Does not implicitly {@link mts.broker.StatusRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {mts.broker.IStatusRequest} message StatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified StatusRequest message, length delimited. Does not implicitly {@link mts.broker.StatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {mts.broker.IStatusRequest} message StatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.StatusRequest} StatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.StatusRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.StatusRequest} StatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StatusRequest message.
             * @function verify
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a StatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.StatusRequest} StatusRequest
             */
            StatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.StatusRequest)
                    return object;
                return new $root.mts.broker.StatusRequest();
            };

            /**
             * Creates a plain object from a StatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {mts.broker.StatusRequest} message StatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StatusRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this StatusRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.StatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StatusRequest
             * @function getTypeUrl
             * @memberof mts.broker.StatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.StatusRequest";
            };

            return StatusRequest;
        })();

        broker.StatusResponse = (function() {

            /**
             * Properties of a StatusResponse.
             * @memberof mts.broker
             * @interface IStatusResponse
             * @property {Object.<string,string>|null} [properties] StatusResponse properties
             */

            /**
             * Constructs a new StatusResponse.
             * @memberof mts.broker
             * @classdesc Represents a StatusResponse.
             * @implements IStatusResponse
             * @constructor
             * @param {mts.broker.IStatusResponse=} [properties] Properties to set
             */
            function StatusResponse(properties) {
                this.properties = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StatusResponse properties.
             * @member {Object.<string,string>} properties
             * @memberof mts.broker.StatusResponse
             * @instance
             */
            StatusResponse.prototype.properties = $util.emptyObject;

            /**
             * Creates a new StatusResponse instance using the specified properties.
             * @function create
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {mts.broker.IStatusResponse=} [properties] Properties to set
             * @returns {mts.broker.StatusResponse} StatusResponse instance
             */
            StatusResponse.create = function create(properties) {
                return new StatusResponse(properties);
            };

            /**
             * Encodes the specified StatusResponse message. Does not implicitly {@link mts.broker.StatusResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {mts.broker.IStatusResponse} message StatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.properties != null && Object.hasOwnProperty.call(message, "properties"))
                    for (let keys = Object.keys(message.properties), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.properties[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified StatusResponse message, length delimited. Does not implicitly {@link mts.broker.StatusResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {mts.broker.IStatusResponse} message StatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.StatusResponse} StatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.StatusResponse(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (message.properties === $util.emptyObject)
                                message.properties = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.properties[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StatusResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.StatusResponse} StatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StatusResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StatusResponse message.
             * @function verify
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.properties != null && message.hasOwnProperty("properties")) {
                    if (!$util.isObject(message.properties))
                        return "properties: object expected";
                    let key = Object.keys(message.properties);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.properties[key[i]]))
                            return "properties: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates a StatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.StatusResponse} StatusResponse
             */
            StatusResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.StatusResponse)
                    return object;
                let message = new $root.mts.broker.StatusResponse();
                if (object.properties) {
                    if (typeof object.properties !== "object")
                        throw TypeError(".mts.broker.StatusResponse.properties: object expected");
                    message.properties = {};
                    for (let keys = Object.keys(object.properties), i = 0; i < keys.length; ++i)
                        message.properties[keys[i]] = String(object.properties[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from a StatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {mts.broker.StatusResponse} message StatusResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StatusResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.properties = {};
                let keys2;
                if (message.properties && (keys2 = Object.keys(message.properties)).length) {
                    object.properties = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.properties[keys2[j]] = message.properties[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this StatusResponse to JSON.
             * @function toJSON
             * @memberof mts.broker.StatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StatusResponse
             * @function getTypeUrl
             * @memberof mts.broker.StatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.StatusResponse";
            };

            return StatusResponse;
        })();

        broker.HealthCheckRequest = (function() {

            /**
             * Properties of a HealthCheckRequest.
             * @memberof mts.broker
             * @interface IHealthCheckRequest
             * @property {string|null} [service] HealthCheckRequest service
             */

            /**
             * Constructs a new HealthCheckRequest.
             * @memberof mts.broker
             * @classdesc Represents a HealthCheckRequest.
             * @implements IHealthCheckRequest
             * @constructor
             * @param {mts.broker.IHealthCheckRequest=} [properties] Properties to set
             */
            function HealthCheckRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HealthCheckRequest service.
             * @member {string} service
             * @memberof mts.broker.HealthCheckRequest
             * @instance
             */
            HealthCheckRequest.prototype.service = "";

            /**
             * Creates a new HealthCheckRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {mts.broker.IHealthCheckRequest=} [properties] Properties to set
             * @returns {mts.broker.HealthCheckRequest} HealthCheckRequest instance
             */
            HealthCheckRequest.create = function create(properties) {
                return new HealthCheckRequest(properties);
            };

            /**
             * Encodes the specified HealthCheckRequest message. Does not implicitly {@link mts.broker.HealthCheckRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {mts.broker.IHealthCheckRequest} message HealthCheckRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HealthCheckRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.service != null && Object.hasOwnProperty.call(message, "service"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.service);
                return writer;
            };

            /**
             * Encodes the specified HealthCheckRequest message, length delimited. Does not implicitly {@link mts.broker.HealthCheckRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {mts.broker.IHealthCheckRequest} message HealthCheckRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HealthCheckRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HealthCheckRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.HealthCheckRequest} HealthCheckRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HealthCheckRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.HealthCheckRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.service = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HealthCheckRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.HealthCheckRequest} HealthCheckRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HealthCheckRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HealthCheckRequest message.
             * @function verify
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HealthCheckRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.service != null && message.hasOwnProperty("service"))
                    if (!$util.isString(message.service))
                        return "service: string expected";
                return null;
            };

            /**
             * Creates a HealthCheckRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.HealthCheckRequest} HealthCheckRequest
             */
            HealthCheckRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.HealthCheckRequest)
                    return object;
                let message = new $root.mts.broker.HealthCheckRequest();
                if (object.service != null)
                    message.service = String(object.service);
                return message;
            };

            /**
             * Creates a plain object from a HealthCheckRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {mts.broker.HealthCheckRequest} message HealthCheckRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HealthCheckRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.service = "";
                if (message.service != null && message.hasOwnProperty("service"))
                    object.service = message.service;
                return object;
            };

            /**
             * Converts this HealthCheckRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.HealthCheckRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HealthCheckRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HealthCheckRequest
             * @function getTypeUrl
             * @memberof mts.broker.HealthCheckRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HealthCheckRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.HealthCheckRequest";
            };

            return HealthCheckRequest;
        })();

        broker.HealthCheckResponse = (function() {

            /**
             * Properties of a HealthCheckResponse.
             * @memberof mts.broker
             * @interface IHealthCheckResponse
             * @property {mts.broker.HealthCheckResponse.ServingStatus|null} [status] HealthCheckResponse status
             */

            /**
             * Constructs a new HealthCheckResponse.
             * @memberof mts.broker
             * @classdesc Represents a HealthCheckResponse.
             * @implements IHealthCheckResponse
             * @constructor
             * @param {mts.broker.IHealthCheckResponse=} [properties] Properties to set
             */
            function HealthCheckResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HealthCheckResponse status.
             * @member {mts.broker.HealthCheckResponse.ServingStatus} status
             * @memberof mts.broker.HealthCheckResponse
             * @instance
             */
            HealthCheckResponse.prototype.status = 0;

            /**
             * Creates a new HealthCheckResponse instance using the specified properties.
             * @function create
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {mts.broker.IHealthCheckResponse=} [properties] Properties to set
             * @returns {mts.broker.HealthCheckResponse} HealthCheckResponse instance
             */
            HealthCheckResponse.create = function create(properties) {
                return new HealthCheckResponse(properties);
            };

            /**
             * Encodes the specified HealthCheckResponse message. Does not implicitly {@link mts.broker.HealthCheckResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {mts.broker.IHealthCheckResponse} message HealthCheckResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HealthCheckResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                return writer;
            };

            /**
             * Encodes the specified HealthCheckResponse message, length delimited. Does not implicitly {@link mts.broker.HealthCheckResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {mts.broker.IHealthCheckResponse} message HealthCheckResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HealthCheckResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HealthCheckResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.HealthCheckResponse} HealthCheckResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HealthCheckResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.HealthCheckResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.status = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HealthCheckResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.HealthCheckResponse} HealthCheckResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HealthCheckResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HealthCheckResponse message.
             * @function verify
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HealthCheckResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a HealthCheckResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.HealthCheckResponse} HealthCheckResponse
             */
            HealthCheckResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.HealthCheckResponse)
                    return object;
                let message = new $root.mts.broker.HealthCheckResponse();
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "SERVING":
                case 1:
                    message.status = 1;
                    break;
                case "NOT_SERVING":
                case 2:
                    message.status = 2;
                    break;
                case "SERVICE_UNKNOWN":
                case 3:
                    message.status = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a HealthCheckResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {mts.broker.HealthCheckResponse} message HealthCheckResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HealthCheckResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.status = options.enums === String ? "UNKNOWN" : 0;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.mts.broker.HealthCheckResponse.ServingStatus[message.status] === undefined ? message.status : $root.mts.broker.HealthCheckResponse.ServingStatus[message.status] : message.status;
                return object;
            };

            /**
             * Converts this HealthCheckResponse to JSON.
             * @function toJSON
             * @memberof mts.broker.HealthCheckResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HealthCheckResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HealthCheckResponse
             * @function getTypeUrl
             * @memberof mts.broker.HealthCheckResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HealthCheckResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.HealthCheckResponse";
            };

            /**
             * ServingStatus enum.
             * @name mts.broker.HealthCheckResponse.ServingStatus
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} SERVING=1 SERVING value
             * @property {number} NOT_SERVING=2 NOT_SERVING value
             * @property {number} SERVICE_UNKNOWN=3 SERVICE_UNKNOWN value
             */
            HealthCheckResponse.ServingStatus = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "SERVING"] = 1;
                values[valuesById[2] = "NOT_SERVING"] = 2;
                values[valuesById[3] = "SERVICE_UNKNOWN"] = 3;
                return values;
            })();

            return HealthCheckResponse;
        })();

        broker.AccountUpdatesRequest = (function() {

            /**
             * Properties of an AccountUpdatesRequest.
             * @memberof mts.broker
             * @interface IAccountUpdatesRequest
             * @property {string|null} [accountId] AccountUpdatesRequest accountId
             */

            /**
             * Constructs a new AccountUpdatesRequest.
             * @memberof mts.broker
             * @classdesc Represents an AccountUpdatesRequest.
             * @implements IAccountUpdatesRequest
             * @constructor
             * @param {mts.broker.IAccountUpdatesRequest=} [properties] Properties to set
             */
            function AccountUpdatesRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AccountUpdatesRequest accountId.
             * @member {string} accountId
             * @memberof mts.broker.AccountUpdatesRequest
             * @instance
             */
            AccountUpdatesRequest.prototype.accountId = "";

            /**
             * Creates a new AccountUpdatesRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {mts.broker.IAccountUpdatesRequest=} [properties] Properties to set
             * @returns {mts.broker.AccountUpdatesRequest} AccountUpdatesRequest instance
             */
            AccountUpdatesRequest.create = function create(properties) {
                return new AccountUpdatesRequest(properties);
            };

            /**
             * Encodes the specified AccountUpdatesRequest message. Does not implicitly {@link mts.broker.AccountUpdatesRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {mts.broker.IAccountUpdatesRequest} message AccountUpdatesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AccountUpdatesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                return writer;
            };

            /**
             * Encodes the specified AccountUpdatesRequest message, length delimited. Does not implicitly {@link mts.broker.AccountUpdatesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {mts.broker.IAccountUpdatesRequest} message AccountUpdatesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AccountUpdatesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AccountUpdatesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.AccountUpdatesRequest} AccountUpdatesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AccountUpdatesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.AccountUpdatesRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accountId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AccountUpdatesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.AccountUpdatesRequest} AccountUpdatesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AccountUpdatesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AccountUpdatesRequest message.
             * @function verify
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AccountUpdatesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                return null;
            };

            /**
             * Creates an AccountUpdatesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.AccountUpdatesRequest} AccountUpdatesRequest
             */
            AccountUpdatesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.AccountUpdatesRequest)
                    return object;
                let message = new $root.mts.broker.AccountUpdatesRequest();
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                return message;
            };

            /**
             * Creates a plain object from an AccountUpdatesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {mts.broker.AccountUpdatesRequest} message AccountUpdatesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AccountUpdatesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.accountId = "";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                return object;
            };

            /**
             * Converts this AccountUpdatesRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.AccountUpdatesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AccountUpdatesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AccountUpdatesRequest
             * @function getTypeUrl
             * @memberof mts.broker.AccountUpdatesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AccountUpdatesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.AccountUpdatesRequest";
            };

            return AccountUpdatesRequest;
        })();

        broker.UpdateMessage = (function() {

            /**
             * Properties of an UpdateMessage.
             * @memberof mts.broker
             * @interface IUpdateMessage
             * @property {mts.broker.IOrder|null} [order] UpdateMessage order
             */

            /**
             * Constructs a new UpdateMessage.
             * @memberof mts.broker
             * @classdesc Represents an UpdateMessage.
             * @implements IUpdateMessage
             * @constructor
             * @param {mts.broker.IUpdateMessage=} [properties] Properties to set
             */
            function UpdateMessage(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateMessage order.
             * @member {mts.broker.IOrder|null|undefined} order
             * @memberof mts.broker.UpdateMessage
             * @instance
             */
            UpdateMessage.prototype.order = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * UpdateMessage type.
             * @member {"order"|undefined} type
             * @memberof mts.broker.UpdateMessage
             * @instance
             */
            Object.defineProperty(UpdateMessage.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["order"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new UpdateMessage instance using the specified properties.
             * @function create
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {mts.broker.IUpdateMessage=} [properties] Properties to set
             * @returns {mts.broker.UpdateMessage} UpdateMessage instance
             */
            UpdateMessage.create = function create(properties) {
                return new UpdateMessage(properties);
            };

            /**
             * Encodes the specified UpdateMessage message. Does not implicitly {@link mts.broker.UpdateMessage.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {mts.broker.IUpdateMessage} message UpdateMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.mts.broker.Order.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateMessage message, length delimited. Does not implicitly {@link mts.broker.UpdateMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {mts.broker.IUpdateMessage} message UpdateMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateMessage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateMessage message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.UpdateMessage} UpdateMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.UpdateMessage();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order = $root.mts.broker.Order.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.UpdateMessage} UpdateMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateMessage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateMessage message.
             * @function verify
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.order != null && message.hasOwnProperty("order")) {
                    properties.type = 1;
                    {
                        let error = $root.mts.broker.Order.verify(message.order);
                        if (error)
                            return "order." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an UpdateMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.UpdateMessage} UpdateMessage
             */
            UpdateMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.UpdateMessage)
                    return object;
                let message = new $root.mts.broker.UpdateMessage();
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".mts.broker.UpdateMessage.order: object expected");
                    message.order = $root.mts.broker.Order.fromObject(object.order);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {mts.broker.UpdateMessage} message UpdateMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.order != null && message.hasOwnProperty("order")) {
                    object.order = $root.mts.broker.Order.toObject(message.order, options);
                    if (options.oneofs)
                        object.type = "order";
                }
                return object;
            };

            /**
             * Converts this UpdateMessage to JSON.
             * @function toJSON
             * @memberof mts.broker.UpdateMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateMessage
             * @function getTypeUrl
             * @memberof mts.broker.UpdateMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.UpdateMessage";
            };

            return UpdateMessage;
        })();

        broker.GetPositionsRequest = (function() {

            /**
             * Properties of a GetPositionsRequest.
             * @memberof mts.broker
             * @interface IGetPositionsRequest
             * @property {string|null} [accountId] GetPositionsRequest accountId
             */

            /**
             * Constructs a new GetPositionsRequest.
             * @memberof mts.broker
             * @classdesc Represents a GetPositionsRequest.
             * @implements IGetPositionsRequest
             * @constructor
             * @param {mts.broker.IGetPositionsRequest=} [properties] Properties to set
             */
            function GetPositionsRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetPositionsRequest accountId.
             * @member {string} accountId
             * @memberof mts.broker.GetPositionsRequest
             * @instance
             */
            GetPositionsRequest.prototype.accountId = "";

            /**
             * Creates a new GetPositionsRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {mts.broker.IGetPositionsRequest=} [properties] Properties to set
             * @returns {mts.broker.GetPositionsRequest} GetPositionsRequest instance
             */
            GetPositionsRequest.create = function create(properties) {
                return new GetPositionsRequest(properties);
            };

            /**
             * Encodes the specified GetPositionsRequest message. Does not implicitly {@link mts.broker.GetPositionsRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {mts.broker.IGetPositionsRequest} message GetPositionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPositionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                return writer;
            };

            /**
             * Encodes the specified GetPositionsRequest message, length delimited. Does not implicitly {@link mts.broker.GetPositionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {mts.broker.IGetPositionsRequest} message GetPositionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPositionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPositionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.GetPositionsRequest} GetPositionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPositionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.GetPositionsRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accountId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPositionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.GetPositionsRequest} GetPositionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPositionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPositionsRequest message.
             * @function verify
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPositionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                return null;
            };

            /**
             * Creates a GetPositionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.GetPositionsRequest} GetPositionsRequest
             */
            GetPositionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.GetPositionsRequest)
                    return object;
                let message = new $root.mts.broker.GetPositionsRequest();
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                return message;
            };

            /**
             * Creates a plain object from a GetPositionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {mts.broker.GetPositionsRequest} message GetPositionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPositionsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.accountId = "";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                return object;
            };

            /**
             * Converts this GetPositionsRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.GetPositionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPositionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPositionsRequest
             * @function getTypeUrl
             * @memberof mts.broker.GetPositionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPositionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.GetPositionsRequest";
            };

            return GetPositionsRequest;
        })();

        broker.Positions = (function() {

            /**
             * Properties of a Positions.
             * @memberof mts.broker
             * @interface IPositions
             * @property {Array.<mts.broker.IPosition>|null} [position] Positions position
             */

            /**
             * Constructs a new Positions.
             * @memberof mts.broker
             * @classdesc Represents a Positions.
             * @implements IPositions
             * @constructor
             * @param {mts.broker.IPositions=} [properties] Properties to set
             */
            function Positions(properties) {
                this.position = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Positions position.
             * @member {Array.<mts.broker.IPosition>} position
             * @memberof mts.broker.Positions
             * @instance
             */
            Positions.prototype.position = $util.emptyArray;

            /**
             * Creates a new Positions instance using the specified properties.
             * @function create
             * @memberof mts.broker.Positions
             * @static
             * @param {mts.broker.IPositions=} [properties] Properties to set
             * @returns {mts.broker.Positions} Positions instance
             */
            Positions.create = function create(properties) {
                return new Positions(properties);
            };

            /**
             * Encodes the specified Positions message. Does not implicitly {@link mts.broker.Positions.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.Positions
             * @static
             * @param {mts.broker.IPositions} message Positions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Positions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.position != null && message.position.length)
                    for (let i = 0; i < message.position.length; ++i)
                        $root.mts.broker.Position.encode(message.position[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Positions message, length delimited. Does not implicitly {@link mts.broker.Positions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.Positions
             * @static
             * @param {mts.broker.IPositions} message Positions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Positions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Positions message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.Positions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.Positions} Positions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Positions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.Positions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.position && message.position.length))
                                message.position = [];
                            message.position.push($root.mts.broker.Position.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Positions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.Positions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.Positions} Positions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Positions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Positions message.
             * @function verify
             * @memberof mts.broker.Positions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Positions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.position != null && message.hasOwnProperty("position")) {
                    if (!Array.isArray(message.position))
                        return "position: array expected";
                    for (let i = 0; i < message.position.length; ++i) {
                        let error = $root.mts.broker.Position.verify(message.position[i]);
                        if (error)
                            return "position." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Positions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.Positions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.Positions} Positions
             */
            Positions.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.Positions)
                    return object;
                let message = new $root.mts.broker.Positions();
                if (object.position) {
                    if (!Array.isArray(object.position))
                        throw TypeError(".mts.broker.Positions.position: array expected");
                    message.position = [];
                    for (let i = 0; i < object.position.length; ++i) {
                        if (typeof object.position[i] !== "object")
                            throw TypeError(".mts.broker.Positions.position: object expected");
                        message.position[i] = $root.mts.broker.Position.fromObject(object.position[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Positions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.Positions
             * @static
             * @param {mts.broker.Positions} message Positions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Positions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.position = [];
                if (message.position && message.position.length) {
                    object.position = [];
                    for (let j = 0; j < message.position.length; ++j)
                        object.position[j] = $root.mts.broker.Position.toObject(message.position[j], options);
                }
                return object;
            };

            /**
             * Converts this Positions to JSON.
             * @function toJSON
             * @memberof mts.broker.Positions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Positions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Positions
             * @function getTypeUrl
             * @memberof mts.broker.Positions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Positions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.Positions";
            };

            return Positions;
        })();

        broker.Position = (function() {

            /**
             * Properties of a Position.
             * @memberof mts.broker
             * @interface IPosition
             * @property {string|null} [accountId] Position accountId
             * @property {string|null} [symbol] Position symbol
             * @property {number|null} [quantity] Position quantity
             * @property {number|null} [averagePrice] Position averagePrice
             */

            /**
             * Constructs a new Position.
             * @memberof mts.broker
             * @classdesc Represents a Position.
             * @implements IPosition
             * @constructor
             * @param {mts.broker.IPosition=} [properties] Properties to set
             */
            function Position(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Position accountId.
             * @member {string} accountId
             * @memberof mts.broker.Position
             * @instance
             */
            Position.prototype.accountId = "";

            /**
             * Position symbol.
             * @member {string} symbol
             * @memberof mts.broker.Position
             * @instance
             */
            Position.prototype.symbol = "";

            /**
             * Position quantity.
             * @member {number} quantity
             * @memberof mts.broker.Position
             * @instance
             */
            Position.prototype.quantity = 0;

            /**
             * Position averagePrice.
             * @member {number} averagePrice
             * @memberof mts.broker.Position
             * @instance
             */
            Position.prototype.averagePrice = 0;

            /**
             * Creates a new Position instance using the specified properties.
             * @function create
             * @memberof mts.broker.Position
             * @static
             * @param {mts.broker.IPosition=} [properties] Properties to set
             * @returns {mts.broker.Position} Position instance
             */
            Position.create = function create(properties) {
                return new Position(properties);
            };

            /**
             * Encodes the specified Position message. Does not implicitly {@link mts.broker.Position.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.Position
             * @static
             * @param {mts.broker.IPosition} message Position message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Position.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.quantity);
                if (message.averagePrice != null && Object.hasOwnProperty.call(message, "averagePrice"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.averagePrice);
                return writer;
            };

            /**
             * Encodes the specified Position message, length delimited. Does not implicitly {@link mts.broker.Position.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.Position
             * @static
             * @param {mts.broker.IPosition} message Position message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Position.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Position message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.Position
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.Position} Position
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Position.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.Position();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accountId = reader.string();
                            break;
                        }
                    case 2: {
                            message.symbol = reader.string();
                            break;
                        }
                    case 3: {
                            message.quantity = reader.int32();
                            break;
                        }
                    case 4: {
                            message.averagePrice = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Position message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.Position
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.Position} Position
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Position.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Position message.
             * @function verify
             * @memberof mts.broker.Position
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Position.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.averagePrice != null && message.hasOwnProperty("averagePrice"))
                    if (typeof message.averagePrice !== "number")
                        return "averagePrice: number expected";
                return null;
            };

            /**
             * Creates a Position message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.Position
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.Position} Position
             */
            Position.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.Position)
                    return object;
                let message = new $root.mts.broker.Position();
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                if (object.averagePrice != null)
                    message.averagePrice = Number(object.averagePrice);
                return message;
            };

            /**
             * Creates a plain object from a Position message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.Position
             * @static
             * @param {mts.broker.Position} message Position
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Position.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.accountId = "";
                    object.symbol = "";
                    object.quantity = 0;
                    object.averagePrice = 0;
                }
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.averagePrice != null && message.hasOwnProperty("averagePrice"))
                    object.averagePrice = options.json && !isFinite(message.averagePrice) ? String(message.averagePrice) : message.averagePrice;
                return object;
            };

            /**
             * Converts this Position to JSON.
             * @function toJSON
             * @memberof mts.broker.Position
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Position.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Position
             * @function getTypeUrl
             * @memberof mts.broker.Position
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Position.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.Position";
            };

            return Position;
        })();

        broker.CreateAccountRequest = (function() {

            /**
             * Properties of a CreateAccountRequest.
             * @memberof mts.broker
             * @interface ICreateAccountRequest
             * @property {number|null} [cash] CreateAccountRequest cash
             * @property {string|null} [portfolioId] CreateAccountRequest portfolioId
             * @property {boolean|null} [backtest] CreateAccountRequest backtest
             */

            /**
             * Constructs a new CreateAccountRequest.
             * @memberof mts.broker
             * @classdesc Represents a CreateAccountRequest.
             * @implements ICreateAccountRequest
             * @constructor
             * @param {mts.broker.ICreateAccountRequest=} [properties] Properties to set
             */
            function CreateAccountRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateAccountRequest cash.
             * @member {number} cash
             * @memberof mts.broker.CreateAccountRequest
             * @instance
             */
            CreateAccountRequest.prototype.cash = 0;

            /**
             * CreateAccountRequest portfolioId.
             * @member {string} portfolioId
             * @memberof mts.broker.CreateAccountRequest
             * @instance
             */
            CreateAccountRequest.prototype.portfolioId = "";

            /**
             * CreateAccountRequest backtest.
             * @member {boolean} backtest
             * @memberof mts.broker.CreateAccountRequest
             * @instance
             */
            CreateAccountRequest.prototype.backtest = false;

            /**
             * Creates a new CreateAccountRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {mts.broker.ICreateAccountRequest=} [properties] Properties to set
             * @returns {mts.broker.CreateAccountRequest} CreateAccountRequest instance
             */
            CreateAccountRequest.create = function create(properties) {
                return new CreateAccountRequest(properties);
            };

            /**
             * Encodes the specified CreateAccountRequest message. Does not implicitly {@link mts.broker.CreateAccountRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {mts.broker.ICreateAccountRequest} message CreateAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAccountRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.cash);
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.portfolioId);
                if (message.backtest != null && Object.hasOwnProperty.call(message, "backtest"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.backtest);
                return writer;
            };

            /**
             * Encodes the specified CreateAccountRequest message, length delimited. Does not implicitly {@link mts.broker.CreateAccountRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {mts.broker.ICreateAccountRequest} message CreateAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateAccountRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.CreateAccountRequest} CreateAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAccountRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.CreateAccountRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.cash = reader.double();
                            break;
                        }
                    case 2: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    case 3: {
                            message.backtest = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateAccountRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.CreateAccountRequest} CreateAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateAccountRequest message.
             * @function verify
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateAccountRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cash != null && message.hasOwnProperty("cash"))
                    if (typeof message.cash !== "number")
                        return "cash: number expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    if (typeof message.backtest !== "boolean")
                        return "backtest: boolean expected";
                return null;
            };

            /**
             * Creates a CreateAccountRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.CreateAccountRequest} CreateAccountRequest
             */
            CreateAccountRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.CreateAccountRequest)
                    return object;
                let message = new $root.mts.broker.CreateAccountRequest();
                if (object.cash != null)
                    message.cash = Number(object.cash);
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                if (object.backtest != null)
                    message.backtest = Boolean(object.backtest);
                return message;
            };

            /**
             * Creates a plain object from a CreateAccountRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {mts.broker.CreateAccountRequest} message CreateAccountRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAccountRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.cash = 0;
                    object.portfolioId = "";
                    object.backtest = false;
                }
                if (message.cash != null && message.hasOwnProperty("cash"))
                    object.cash = options.json && !isFinite(message.cash) ? String(message.cash) : message.cash;
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    object.backtest = message.backtest;
                return object;
            };

            /**
             * Converts this CreateAccountRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.CreateAccountRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAccountRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateAccountRequest
             * @function getTypeUrl
             * @memberof mts.broker.CreateAccountRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.CreateAccountRequest";
            };

            return CreateAccountRequest;
        })();

        broker.GetAccountRequest = (function() {

            /**
             * Properties of a GetAccountRequest.
             * @memberof mts.broker
             * @interface IGetAccountRequest
             * @property {string|null} [id] GetAccountRequest id
             */

            /**
             * Constructs a new GetAccountRequest.
             * @memberof mts.broker
             * @classdesc Represents a GetAccountRequest.
             * @implements IGetAccountRequest
             * @constructor
             * @param {mts.broker.IGetAccountRequest=} [properties] Properties to set
             */
            function GetAccountRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAccountRequest id.
             * @member {string} id
             * @memberof mts.broker.GetAccountRequest
             * @instance
             */
            GetAccountRequest.prototype.id = "";

            /**
             * Creates a new GetAccountRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {mts.broker.IGetAccountRequest=} [properties] Properties to set
             * @returns {mts.broker.GetAccountRequest} GetAccountRequest instance
             */
            GetAccountRequest.create = function create(properties) {
                return new GetAccountRequest(properties);
            };

            /**
             * Encodes the specified GetAccountRequest message. Does not implicitly {@link mts.broker.GetAccountRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {mts.broker.IGetAccountRequest} message GetAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAccountRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                return writer;
            };

            /**
             * Encodes the specified GetAccountRequest message, length delimited. Does not implicitly {@link mts.broker.GetAccountRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {mts.broker.IGetAccountRequest} message GetAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetAccountRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.GetAccountRequest} GetAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAccountRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.GetAccountRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetAccountRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.GetAccountRequest} GetAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetAccountRequest message.
             * @function verify
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAccountRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                return null;
            };

            /**
             * Creates a GetAccountRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.GetAccountRequest} GetAccountRequest
             */
            GetAccountRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.GetAccountRequest)
                    return object;
                let message = new $root.mts.broker.GetAccountRequest();
                if (object.id != null)
                    message.id = String(object.id);
                return message;
            };

            /**
             * Creates a plain object from a GetAccountRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {mts.broker.GetAccountRequest} message GetAccountRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAccountRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = "";
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this GetAccountRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.GetAccountRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAccountRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAccountRequest
             * @function getTypeUrl
             * @memberof mts.broker.GetAccountRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.GetAccountRequest";
            };

            return GetAccountRequest;
        })();

        broker.Account = (function() {

            /**
             * Properties of an Account.
             * @memberof mts.broker
             * @interface IAccount
             * @property {string|null} [id] Account id
             * @property {number|null} [portfolioId] Account portfolioId
             * @property {boolean|null} [backtest] Account backtest
             * @property {number|null} [cash] Account cash
             */

            /**
             * Constructs a new Account.
             * @memberof mts.broker
             * @classdesc Represents an Account.
             * @implements IAccount
             * @constructor
             * @param {mts.broker.IAccount=} [properties] Properties to set
             */
            function Account(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Account id.
             * @member {string} id
             * @memberof mts.broker.Account
             * @instance
             */
            Account.prototype.id = "";

            /**
             * Account portfolioId.
             * @member {number} portfolioId
             * @memberof mts.broker.Account
             * @instance
             */
            Account.prototype.portfolioId = 0;

            /**
             * Account backtest.
             * @member {boolean} backtest
             * @memberof mts.broker.Account
             * @instance
             */
            Account.prototype.backtest = false;

            /**
             * Account cash.
             * @member {number} cash
             * @memberof mts.broker.Account
             * @instance
             */
            Account.prototype.cash = 0;

            /**
             * Creates a new Account instance using the specified properties.
             * @function create
             * @memberof mts.broker.Account
             * @static
             * @param {mts.broker.IAccount=} [properties] Properties to set
             * @returns {mts.broker.Account} Account instance
             */
            Account.create = function create(properties) {
                return new Account(properties);
            };

            /**
             * Encodes the specified Account message. Does not implicitly {@link mts.broker.Account.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.Account
             * @static
             * @param {mts.broker.IAccount} message Account message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Account.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.portfolioId);
                if (message.backtest != null && Object.hasOwnProperty.call(message, "backtest"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.backtest);
                if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.cash);
                return writer;
            };

            /**
             * Encodes the specified Account message, length delimited. Does not implicitly {@link mts.broker.Account.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.Account
             * @static
             * @param {mts.broker.IAccount} message Account message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Account.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Account message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.Account
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.Account} Account
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Account.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.Account();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.portfolioId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.backtest = reader.bool();
                            break;
                        }
                    case 4: {
                            message.cash = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Account message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.Account
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.Account} Account
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Account.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Account message.
             * @function verify
             * @memberof mts.broker.Account
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Account.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isInteger(message.portfolioId))
                        return "portfolioId: integer expected";
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    if (typeof message.backtest !== "boolean")
                        return "backtest: boolean expected";
                if (message.cash != null && message.hasOwnProperty("cash"))
                    if (typeof message.cash !== "number")
                        return "cash: number expected";
                return null;
            };

            /**
             * Creates an Account message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.Account
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.Account} Account
             */
            Account.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.Account)
                    return object;
                let message = new $root.mts.broker.Account();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.portfolioId != null)
                    message.portfolioId = object.portfolioId | 0;
                if (object.backtest != null)
                    message.backtest = Boolean(object.backtest);
                if (object.cash != null)
                    message.cash = Number(object.cash);
                return message;
            };

            /**
             * Creates a plain object from an Account message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.Account
             * @static
             * @param {mts.broker.Account} message Account
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Account.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = "";
                    object.portfolioId = 0;
                    object.backtest = false;
                    object.cash = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    object.backtest = message.backtest;
                if (message.cash != null && message.hasOwnProperty("cash"))
                    object.cash = options.json && !isFinite(message.cash) ? String(message.cash) : message.cash;
                return object;
            };

            /**
             * Converts this Account to JSON.
             * @function toJSON
             * @memberof mts.broker.Account
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Account.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Account
             * @function getTypeUrl
             * @memberof mts.broker.Account
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Account.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.Account";
            };

            return Account;
        })();

        broker.Request = (function() {

            /**
             * Properties of a Request.
             * @memberof mts.broker
             * @interface IRequest
             * @property {mts.broker.Request.Type|null} [type] Request type
             * @property {mts.broker.ICreateOrderRequest|null} [createOrderRequest] Request createOrderRequest
             * @property {mts.broker.ICancelOrderRequest|null} [cancelRequest] Request cancelRequest
             * @property {mts.broker.IGetOrdersRequest|null} [getOrdersRequest] Request getOrdersRequest
             */

            /**
             * Constructs a new Request.
             * @memberof mts.broker
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {mts.broker.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Request type.
             * @member {mts.broker.Request.Type} type
             * @memberof mts.broker.Request
             * @instance
             */
            Request.prototype.type = 0;

            /**
             * Request createOrderRequest.
             * @member {mts.broker.ICreateOrderRequest|null|undefined} createOrderRequest
             * @memberof mts.broker.Request
             * @instance
             */
            Request.prototype.createOrderRequest = null;

            /**
             * Request cancelRequest.
             * @member {mts.broker.ICancelOrderRequest|null|undefined} cancelRequest
             * @memberof mts.broker.Request
             * @instance
             */
            Request.prototype.cancelRequest = null;

            /**
             * Request getOrdersRequest.
             * @member {mts.broker.IGetOrdersRequest|null|undefined} getOrdersRequest
             * @memberof mts.broker.Request
             * @instance
             */
            Request.prototype.getOrdersRequest = null;

            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof mts.broker.Request
             * @static
             * @param {mts.broker.IRequest=} [properties] Properties to set
             * @returns {mts.broker.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };

            /**
             * Encodes the specified Request message. Does not implicitly {@link mts.broker.Request.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.Request
             * @static
             * @param {mts.broker.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.createOrderRequest != null && Object.hasOwnProperty.call(message, "createOrderRequest"))
                    $root.mts.broker.CreateOrderRequest.encode(message.createOrderRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.cancelRequest != null && Object.hasOwnProperty.call(message, "cancelRequest"))
                    $root.mts.broker.CancelOrderRequest.encode(message.cancelRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.getOrdersRequest != null && Object.hasOwnProperty.call(message, "getOrdersRequest"))
                    $root.mts.broker.GetOrdersRequest.encode(message.getOrdersRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link mts.broker.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.Request
             * @static
             * @param {mts.broker.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.Request();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type = reader.int32();
                            break;
                        }
                    case 3: {
                            message.createOrderRequest = $root.mts.broker.CreateOrderRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.cancelRequest = $root.mts.broker.CancelOrderRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.getOrdersRequest = $root.mts.broker.GetOrdersRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Request message.
             * @function verify
             * @memberof mts.broker.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.createOrderRequest != null && message.hasOwnProperty("createOrderRequest")) {
                    let error = $root.mts.broker.CreateOrderRequest.verify(message.createOrderRequest);
                    if (error)
                        return "createOrderRequest." + error;
                }
                if (message.cancelRequest != null && message.hasOwnProperty("cancelRequest")) {
                    let error = $root.mts.broker.CancelOrderRequest.verify(message.cancelRequest);
                    if (error)
                        return "cancelRequest." + error;
                }
                if (message.getOrdersRequest != null && message.hasOwnProperty("getOrdersRequest")) {
                    let error = $root.mts.broker.GetOrdersRequest.verify(message.getOrdersRequest);
                    if (error)
                        return "getOrdersRequest." + error;
                }
                return null;
            };

            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.Request)
                    return object;
                let message = new $root.mts.broker.Request();
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "Unknown":
                case 0:
                    message.type = 0;
                    break;
                case "CreateOrderRequest":
                case 1:
                    message.type = 1;
                    break;
                case "CancelOrderRequest":
                case 2:
                    message.type = 2;
                    break;
                case "GetOrdersRequest":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.createOrderRequest != null) {
                    if (typeof object.createOrderRequest !== "object")
                        throw TypeError(".mts.broker.Request.createOrderRequest: object expected");
                    message.createOrderRequest = $root.mts.broker.CreateOrderRequest.fromObject(object.createOrderRequest);
                }
                if (object.cancelRequest != null) {
                    if (typeof object.cancelRequest !== "object")
                        throw TypeError(".mts.broker.Request.cancelRequest: object expected");
                    message.cancelRequest = $root.mts.broker.CancelOrderRequest.fromObject(object.cancelRequest);
                }
                if (object.getOrdersRequest != null) {
                    if (typeof object.getOrdersRequest !== "object")
                        throw TypeError(".mts.broker.Request.getOrdersRequest: object expected");
                    message.getOrdersRequest = $root.mts.broker.GetOrdersRequest.fromObject(object.getOrdersRequest);
                }
                return message;
            };

            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.Request
             * @static
             * @param {mts.broker.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "Unknown" : 0;
                    object.createOrderRequest = null;
                    object.cancelRequest = null;
                    object.getOrdersRequest = null;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.broker.Request.Type[message.type] === undefined ? message.type : $root.mts.broker.Request.Type[message.type] : message.type;
                if (message.createOrderRequest != null && message.hasOwnProperty("createOrderRequest"))
                    object.createOrderRequest = $root.mts.broker.CreateOrderRequest.toObject(message.createOrderRequest, options);
                if (message.cancelRequest != null && message.hasOwnProperty("cancelRequest"))
                    object.cancelRequest = $root.mts.broker.CancelOrderRequest.toObject(message.cancelRequest, options);
                if (message.getOrdersRequest != null && message.hasOwnProperty("getOrdersRequest"))
                    object.getOrdersRequest = $root.mts.broker.GetOrdersRequest.toObject(message.getOrdersRequest, options);
                return object;
            };

            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof mts.broker.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof mts.broker.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.Request";
            };

            /**
             * Type enum.
             * @name mts.broker.Request.Type
             * @enum {number}
             * @property {number} Unknown=0 Unknown value
             * @property {number} CreateOrderRequest=1 CreateOrderRequest value
             * @property {number} CancelOrderRequest=2 CancelOrderRequest value
             * @property {number} GetOrdersRequest=3 GetOrdersRequest value
             */
            Request.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "Unknown"] = 0;
                values[valuesById[1] = "CreateOrderRequest"] = 1;
                values[valuesById[2] = "CancelOrderRequest"] = 2;
                values[valuesById[3] = "GetOrdersRequest"] = 3;
                return values;
            })();

            return Request;
        })();

        broker.Response = (function() {

            /**
             * Properties of a Response.
             * @memberof mts.broker
             * @interface IResponse
             * @property {mts.broker.Response.Type|null} [type] Response type
             * @property {number|Long|null} [requestId] Response requestId
             * @property {mts.broker.ICreateOrderResponse|null} [createOrderResponse] Response createOrderResponse
             * @property {mts.broker.ICancelOrderResponse|null} [cancelOrderResponse] Response cancelOrderResponse
             * @property {mts.broker.IGetOrdersResponse|null} [getOrdersResponse] Response getOrdersResponse
             */

            /**
             * Constructs a new Response.
             * @memberof mts.broker
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {mts.broker.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Response type.
             * @member {mts.broker.Response.Type} type
             * @memberof mts.broker.Response
             * @instance
             */
            Response.prototype.type = 0;

            /**
             * Response requestId.
             * @member {number|Long} requestId
             * @memberof mts.broker.Response
             * @instance
             */
            Response.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Response createOrderResponse.
             * @member {mts.broker.ICreateOrderResponse|null|undefined} createOrderResponse
             * @memberof mts.broker.Response
             * @instance
             */
            Response.prototype.createOrderResponse = null;

            /**
             * Response cancelOrderResponse.
             * @member {mts.broker.ICancelOrderResponse|null|undefined} cancelOrderResponse
             * @memberof mts.broker.Response
             * @instance
             */
            Response.prototype.cancelOrderResponse = null;

            /**
             * Response getOrdersResponse.
             * @member {mts.broker.IGetOrdersResponse|null|undefined} getOrdersResponse
             * @memberof mts.broker.Response
             * @instance
             */
            Response.prototype.getOrdersResponse = null;

            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof mts.broker.Response
             * @static
             * @param {mts.broker.IResponse=} [properties] Properties to set
             * @returns {mts.broker.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };

            /**
             * Encodes the specified Response message. Does not implicitly {@link mts.broker.Response.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.Response
             * @static
             * @param {mts.broker.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.requestId);
                if (message.createOrderResponse != null && Object.hasOwnProperty.call(message, "createOrderResponse"))
                    $root.mts.broker.CreateOrderResponse.encode(message.createOrderResponse, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.cancelOrderResponse != null && Object.hasOwnProperty.call(message, "cancelOrderResponse"))
                    $root.mts.broker.CancelOrderResponse.encode(message.cancelOrderResponse, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.getOrdersResponse != null && Object.hasOwnProperty.call(message, "getOrdersResponse"))
                    $root.mts.broker.GetOrdersResponse.encode(message.getOrdersResponse, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link mts.broker.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.Response
             * @static
             * @param {mts.broker.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.Response();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.requestId = reader.int64();
                            break;
                        }
                    case 4: {
                            message.createOrderResponse = $root.mts.broker.CreateOrderResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.cancelOrderResponse = $root.mts.broker.CancelOrderResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.getOrdersResponse = $root.mts.broker.GetOrdersResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Response message.
             * @function verify
             * @memberof mts.broker.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.createOrderResponse != null && message.hasOwnProperty("createOrderResponse")) {
                    let error = $root.mts.broker.CreateOrderResponse.verify(message.createOrderResponse);
                    if (error)
                        return "createOrderResponse." + error;
                }
                if (message.cancelOrderResponse != null && message.hasOwnProperty("cancelOrderResponse")) {
                    let error = $root.mts.broker.CancelOrderResponse.verify(message.cancelOrderResponse);
                    if (error)
                        return "cancelOrderResponse." + error;
                }
                if (message.getOrdersResponse != null && message.hasOwnProperty("getOrdersResponse")) {
                    let error = $root.mts.broker.GetOrdersResponse.verify(message.getOrdersResponse);
                    if (error)
                        return "getOrdersResponse." + error;
                }
                return null;
            };

            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.Response)
                    return object;
                let message = new $root.mts.broker.Response();
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UnknownType":
                case 0:
                    message.type = 0;
                    break;
                case "CreateOrderResponse":
                case 1:
                    message.type = 1;
                    break;
                case "CancelOrderResponse":
                case 2:
                    message.type = 2;
                    break;
                case "GetOrdersResponse":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = false;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber();
                if (object.createOrderResponse != null) {
                    if (typeof object.createOrderResponse !== "object")
                        throw TypeError(".mts.broker.Response.createOrderResponse: object expected");
                    message.createOrderResponse = $root.mts.broker.CreateOrderResponse.fromObject(object.createOrderResponse);
                }
                if (object.cancelOrderResponse != null) {
                    if (typeof object.cancelOrderResponse !== "object")
                        throw TypeError(".mts.broker.Response.cancelOrderResponse: object expected");
                    message.cancelOrderResponse = $root.mts.broker.CancelOrderResponse.fromObject(object.cancelOrderResponse);
                }
                if (object.getOrdersResponse != null) {
                    if (typeof object.getOrdersResponse !== "object")
                        throw TypeError(".mts.broker.Response.getOrdersResponse: object expected");
                    message.getOrdersResponse = $root.mts.broker.GetOrdersResponse.fromObject(object.getOrdersResponse);
                }
                return message;
            };

            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.Response
             * @static
             * @param {mts.broker.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "UnknownType" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                    object.createOrderResponse = null;
                    object.cancelOrderResponse = null;
                    object.getOrdersResponse = null;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.broker.Response.Type[message.type] === undefined ? message.type : $root.mts.broker.Response.Type[message.type] : message.type;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber() : message.requestId;
                if (message.createOrderResponse != null && message.hasOwnProperty("createOrderResponse"))
                    object.createOrderResponse = $root.mts.broker.CreateOrderResponse.toObject(message.createOrderResponse, options);
                if (message.cancelOrderResponse != null && message.hasOwnProperty("cancelOrderResponse"))
                    object.cancelOrderResponse = $root.mts.broker.CancelOrderResponse.toObject(message.cancelOrderResponse, options);
                if (message.getOrdersResponse != null && message.hasOwnProperty("getOrdersResponse"))
                    object.getOrdersResponse = $root.mts.broker.GetOrdersResponse.toObject(message.getOrdersResponse, options);
                return object;
            };

            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof mts.broker.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof mts.broker.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.Response";
            };

            /**
             * Type enum.
             * @name mts.broker.Response.Type
             * @enum {number}
             * @property {number} UnknownType=0 UnknownType value
             * @property {number} CreateOrderResponse=1 CreateOrderResponse value
             * @property {number} CancelOrderResponse=2 CancelOrderResponse value
             * @property {number} GetOrdersResponse=3 GetOrdersResponse value
             */
            Response.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UnknownType"] = 0;
                values[valuesById[1] = "CreateOrderResponse"] = 1;
                values[valuesById[2] = "CancelOrderResponse"] = 2;
                values[valuesById[3] = "GetOrdersResponse"] = 3;
                return values;
            })();

            return Response;
        })();

        broker.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof mts.broker
             * @interface IOrder
             * @property {string|null} [id] Order id
             * @property {string|null} [accountId] Order accountId
             * @property {string|null} [notes] Order notes
             * @property {number|Long|null} [acceptedTime] Order acceptedTime
             * @property {number|Long|null} [canceledTime] Order canceledTime
             * @property {number|Long|null} [executedTime] Order executedTime
             * @property {number|Long|null} [rejectedTime] Order rejectedTime
             * @property {mts.broker.Order.Status|null} [status] Order status
             * @property {string|null} [rejectedReason] Order rejectedReason
             * @property {string|null} [description] Order description
             * @property {mts.broker.Order.Type|null} [type] Order type
             * @property {number|null} [price] Order price
             * @property {number|null} [fillPrice] Order fillPrice
             * @property {string|null} [fillDetail] Order fillDetail
             * @property {string|null} [symbol] Order symbol
             * @property {number|null} [quantity] Order quantity
             * @property {mts.broker.Order.Transaction|null} [transaction] Order transaction
             * @property {string|null} [parentId] Order parentId
             * @property {string|null} [uuid] Order uuid
             * @property {mts.broker.IOrder|null} [profitTaker] Order profitTaker
             * @property {boolean|null} [backtest] Order backtest
             * @property {Object.<string,string>|null} [customData] Order customData
             */

            /**
             * Constructs a new Order.
             * @memberof mts.broker
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {mts.broker.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                this.customData = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Order id.
             * @member {string} id
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.id = "";

            /**
             * Order accountId.
             * @member {string} accountId
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.accountId = "";

            /**
             * Order notes.
             * @member {string} notes
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.notes = "";

            /**
             * Order acceptedTime.
             * @member {number|Long} acceptedTime
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.acceptedTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order canceledTime.
             * @member {number|Long} canceledTime
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.canceledTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order executedTime.
             * @member {number|Long} executedTime
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.executedTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order rejectedTime.
             * @member {number|Long} rejectedTime
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.rejectedTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order status.
             * @member {mts.broker.Order.Status} status
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.status = 0;

            /**
             * Order rejectedReason.
             * @member {string} rejectedReason
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.rejectedReason = "";

            /**
             * Order description.
             * @member {string} description
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.description = "";

            /**
             * Order type.
             * @member {mts.broker.Order.Type} type
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.type = 0;

            /**
             * Order price.
             * @member {number} price
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.price = 0;

            /**
             * Order fillPrice.
             * @member {number} fillPrice
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.fillPrice = 0;

            /**
             * Order fillDetail.
             * @member {string} fillDetail
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.fillDetail = "";

            /**
             * Order symbol.
             * @member {string} symbol
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.symbol = "";

            /**
             * Order quantity.
             * @member {number} quantity
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.quantity = 0;

            /**
             * Order transaction.
             * @member {mts.broker.Order.Transaction} transaction
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.transaction = 0;

            /**
             * Order parentId.
             * @member {string} parentId
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.parentId = "";

            /**
             * Order uuid.
             * @member {string} uuid
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.uuid = "";

            /**
             * Order profitTaker.
             * @member {mts.broker.IOrder|null|undefined} profitTaker
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.profitTaker = null;

            /**
             * Order backtest.
             * @member {boolean} backtest
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.backtest = false;

            /**
             * Order customData.
             * @member {Object.<string,string>} customData
             * @memberof mts.broker.Order
             * @instance
             */
            Order.prototype.customData = $util.emptyObject;

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof mts.broker.Order
             * @static
             * @param {mts.broker.IOrder=} [properties] Properties to set
             * @returns {mts.broker.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link mts.broker.Order.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.Order
             * @static
             * @param {mts.broker.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.accountId);
                if (message.notes != null && Object.hasOwnProperty.call(message, "notes"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.notes);
                if (message.acceptedTime != null && Object.hasOwnProperty.call(message, "acceptedTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.acceptedTime);
                if (message.canceledTime != null && Object.hasOwnProperty.call(message, "canceledTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.canceledTime);
                if (message.executedTime != null && Object.hasOwnProperty.call(message, "executedTime"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.executedTime);
                if (message.rejectedTime != null && Object.hasOwnProperty.call(message, "rejectedTime"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.rejectedTime);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
                if (message.rejectedReason != null && Object.hasOwnProperty.call(message, "rejectedReason"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.rejectedReason);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.description);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.type);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 14, wireType 1 =*/113).double(message.price);
                if (message.fillPrice != null && Object.hasOwnProperty.call(message, "fillPrice"))
                    writer.uint32(/* id 15, wireType 1 =*/121).double(message.fillPrice);
                if (message.fillDetail != null && Object.hasOwnProperty.call(message, "fillDetail"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.fillDetail);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.symbol);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int32(message.quantity);
                if (message.transaction != null && Object.hasOwnProperty.call(message, "transaction"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int32(message.transaction);
                if (message.parentId != null && Object.hasOwnProperty.call(message, "parentId"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.parentId);
                if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.uuid);
                if (message.profitTaker != null && Object.hasOwnProperty.call(message, "profitTaker"))
                    $root.mts.broker.Order.encode(message.profitTaker, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.backtest != null && Object.hasOwnProperty.call(message, "backtest"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.backtest);
                if (message.customData != null && Object.hasOwnProperty.call(message, "customData"))
                    for (let keys = Object.keys(message.customData), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 24, wireType 2 =*/194).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.customData[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Order message, length delimited. Does not implicitly {@link mts.broker.Order.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.Order
             * @static
             * @param {mts.broker.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.Order(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.accountId = reader.string();
                            break;
                        }
                    case 3: {
                            message.notes = reader.string();
                            break;
                        }
                    case 5: {
                            message.acceptedTime = reader.int64();
                            break;
                        }
                    case 6: {
                            message.canceledTime = reader.int64();
                            break;
                        }
                    case 7: {
                            message.executedTime = reader.int64();
                            break;
                        }
                    case 8: {
                            message.rejectedTime = reader.int64();
                            break;
                        }
                    case 9: {
                            message.status = reader.int32();
                            break;
                        }
                    case 10: {
                            message.rejectedReason = reader.string();
                            break;
                        }
                    case 11: {
                            message.description = reader.string();
                            break;
                        }
                    case 12: {
                            message.type = reader.int32();
                            break;
                        }
                    case 14: {
                            message.price = reader.double();
                            break;
                        }
                    case 15: {
                            message.fillPrice = reader.double();
                            break;
                        }
                    case 16: {
                            message.fillDetail = reader.string();
                            break;
                        }
                    case 17: {
                            message.symbol = reader.string();
                            break;
                        }
                    case 18: {
                            message.quantity = reader.int32();
                            break;
                        }
                    case 19: {
                            message.transaction = reader.int32();
                            break;
                        }
                    case 20: {
                            message.parentId = reader.string();
                            break;
                        }
                    case 21: {
                            message.uuid = reader.string();
                            break;
                        }
                    case 22: {
                            message.profitTaker = $root.mts.broker.Order.decode(reader, reader.uint32());
                            break;
                        }
                    case 23: {
                            message.backtest = reader.bool();
                            break;
                        }
                    case 24: {
                            if (message.customData === $util.emptyObject)
                                message.customData = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.customData[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Order message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof mts.broker.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                if (message.notes != null && message.hasOwnProperty("notes"))
                    if (!$util.isString(message.notes))
                        return "notes: string expected";
                if (message.acceptedTime != null && message.hasOwnProperty("acceptedTime"))
                    if (!$util.isInteger(message.acceptedTime) && !(message.acceptedTime && $util.isInteger(message.acceptedTime.low) && $util.isInteger(message.acceptedTime.high)))
                        return "acceptedTime: integer|Long expected";
                if (message.canceledTime != null && message.hasOwnProperty("canceledTime"))
                    if (!$util.isInteger(message.canceledTime) && !(message.canceledTime && $util.isInteger(message.canceledTime.low) && $util.isInteger(message.canceledTime.high)))
                        return "canceledTime: integer|Long expected";
                if (message.executedTime != null && message.hasOwnProperty("executedTime"))
                    if (!$util.isInteger(message.executedTime) && !(message.executedTime && $util.isInteger(message.executedTime.low) && $util.isInteger(message.executedTime.high)))
                        return "executedTime: integer|Long expected";
                if (message.rejectedTime != null && message.hasOwnProperty("rejectedTime"))
                    if (!$util.isInteger(message.rejectedTime) && !(message.rejectedTime && $util.isInteger(message.rejectedTime.low) && $util.isInteger(message.rejectedTime.high)))
                        return "rejectedTime: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.rejectedReason != null && message.hasOwnProperty("rejectedReason"))
                    if (!$util.isString(message.rejectedReason))
                        return "rejectedReason: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price !== "number")
                        return "price: number expected";
                if (message.fillPrice != null && message.hasOwnProperty("fillPrice"))
                    if (typeof message.fillPrice !== "number")
                        return "fillPrice: number expected";
                if (message.fillDetail != null && message.hasOwnProperty("fillDetail"))
                    if (!$util.isString(message.fillDetail))
                        return "fillDetail: string expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.transaction != null && message.hasOwnProperty("transaction"))
                    switch (message.transaction) {
                    default:
                        return "transaction: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.parentId != null && message.hasOwnProperty("parentId"))
                    if (!$util.isString(message.parentId))
                        return "parentId: string expected";
                if (message.uuid != null && message.hasOwnProperty("uuid"))
                    if (!$util.isString(message.uuid))
                        return "uuid: string expected";
                if (message.profitTaker != null && message.hasOwnProperty("profitTaker")) {
                    let error = $root.mts.broker.Order.verify(message.profitTaker);
                    if (error)
                        return "profitTaker." + error;
                }
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    if (typeof message.backtest !== "boolean")
                        return "backtest: boolean expected";
                if (message.customData != null && message.hasOwnProperty("customData")) {
                    if (!$util.isObject(message.customData))
                        return "customData: object expected";
                    let key = Object.keys(message.customData);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.customData[key[i]]))
                            return "customData: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.Order)
                    return object;
                let message = new $root.mts.broker.Order();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                if (object.notes != null)
                    message.notes = String(object.notes);
                if (object.acceptedTime != null)
                    if ($util.Long)
                        (message.acceptedTime = $util.Long.fromValue(object.acceptedTime)).unsigned = false;
                    else if (typeof object.acceptedTime === "string")
                        message.acceptedTime = parseInt(object.acceptedTime, 10);
                    else if (typeof object.acceptedTime === "number")
                        message.acceptedTime = object.acceptedTime;
                    else if (typeof object.acceptedTime === "object")
                        message.acceptedTime = new $util.LongBits(object.acceptedTime.low >>> 0, object.acceptedTime.high >>> 0).toNumber();
                if (object.canceledTime != null)
                    if ($util.Long)
                        (message.canceledTime = $util.Long.fromValue(object.canceledTime)).unsigned = false;
                    else if (typeof object.canceledTime === "string")
                        message.canceledTime = parseInt(object.canceledTime, 10);
                    else if (typeof object.canceledTime === "number")
                        message.canceledTime = object.canceledTime;
                    else if (typeof object.canceledTime === "object")
                        message.canceledTime = new $util.LongBits(object.canceledTime.low >>> 0, object.canceledTime.high >>> 0).toNumber();
                if (object.executedTime != null)
                    if ($util.Long)
                        (message.executedTime = $util.Long.fromValue(object.executedTime)).unsigned = false;
                    else if (typeof object.executedTime === "string")
                        message.executedTime = parseInt(object.executedTime, 10);
                    else if (typeof object.executedTime === "number")
                        message.executedTime = object.executedTime;
                    else if (typeof object.executedTime === "object")
                        message.executedTime = new $util.LongBits(object.executedTime.low >>> 0, object.executedTime.high >>> 0).toNumber();
                if (object.rejectedTime != null)
                    if ($util.Long)
                        (message.rejectedTime = $util.Long.fromValue(object.rejectedTime)).unsigned = false;
                    else if (typeof object.rejectedTime === "string")
                        message.rejectedTime = parseInt(object.rejectedTime, 10);
                    else if (typeof object.rejectedTime === "number")
                        message.rejectedTime = object.rejectedTime;
                    else if (typeof object.rejectedTime === "object")
                        message.rejectedTime = new $util.LongBits(object.rejectedTime.low >>> 0, object.rejectedTime.high >>> 0).toNumber();
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "UNKNOWN_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "SUBMITTED":
                case 1:
                    message.status = 1;
                    break;
                case "ACCEPTED":
                case 2:
                    message.status = 2;
                    break;
                case "REJECTED":
                case 3:
                    message.status = 3;
                    break;
                case "FILLED":
                case 4:
                    message.status = 4;
                    break;
                case "CANCELED":
                case 5:
                    message.status = 5;
                    break;
                case "NEW_ORDER":
                case 6:
                    message.status = 6;
                    break;
                }
                if (object.rejectedReason != null)
                    message.rejectedReason = String(object.rejectedReason);
                if (object.description != null)
                    message.description = String(object.description);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UNKNOWN_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "MARKET":
                case 1:
                    message.type = 1;
                    break;
                case "LIMIT":
                case 2:
                    message.type = 2;
                    break;
                case "STOP":
                case 3:
                    message.type = 3;
                    break;
                case "STOP_LIMIT":
                case 4:
                    message.type = 4;
                    break;
                }
                if (object.price != null)
                    message.price = Number(object.price);
                if (object.fillPrice != null)
                    message.fillPrice = Number(object.fillPrice);
                if (object.fillDetail != null)
                    message.fillDetail = String(object.fillDetail);
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                switch (object.transaction) {
                default:
                    if (typeof object.transaction === "number") {
                        message.transaction = object.transaction;
                        break;
                    }
                    break;
                case "UNKNOWN_TRANSACTION":
                case 0:
                    message.transaction = 0;
                    break;
                case "BUY":
                case 1:
                    message.transaction = 1;
                    break;
                case "SELL":
                case 2:
                    message.transaction = 2;
                    break;
                case "SHORT":
                case 3:
                    message.transaction = 3;
                    break;
                case "COVER":
                case 4:
                    message.transaction = 4;
                    break;
                }
                if (object.parentId != null)
                    message.parentId = String(object.parentId);
                if (object.uuid != null)
                    message.uuid = String(object.uuid);
                if (object.profitTaker != null) {
                    if (typeof object.profitTaker !== "object")
                        throw TypeError(".mts.broker.Order.profitTaker: object expected");
                    message.profitTaker = $root.mts.broker.Order.fromObject(object.profitTaker);
                }
                if (object.backtest != null)
                    message.backtest = Boolean(object.backtest);
                if (object.customData) {
                    if (typeof object.customData !== "object")
                        throw TypeError(".mts.broker.Order.customData: object expected");
                    message.customData = {};
                    for (let keys = Object.keys(object.customData), i = 0; i < keys.length; ++i)
                        message.customData[keys[i]] = String(object.customData[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.Order
             * @static
             * @param {mts.broker.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.customData = {};
                if (options.defaults) {
                    object.id = "";
                    object.accountId = "";
                    object.notes = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.acceptedTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.acceptedTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.canceledTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.canceledTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.executedTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.executedTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.rejectedTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.rejectedTime = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "UNKNOWN_STATUS" : 0;
                    object.rejectedReason = "";
                    object.description = "";
                    object.type = options.enums === String ? "UNKNOWN_TYPE" : 0;
                    object.price = 0;
                    object.fillPrice = 0;
                    object.fillDetail = "";
                    object.symbol = "";
                    object.quantity = 0;
                    object.transaction = options.enums === String ? "UNKNOWN_TRANSACTION" : 0;
                    object.parentId = "";
                    object.uuid = "";
                    object.profitTaker = null;
                    object.backtest = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.notes != null && message.hasOwnProperty("notes"))
                    object.notes = message.notes;
                if (message.acceptedTime != null && message.hasOwnProperty("acceptedTime"))
                    if (typeof message.acceptedTime === "number")
                        object.acceptedTime = options.longs === String ? String(message.acceptedTime) : message.acceptedTime;
                    else
                        object.acceptedTime = options.longs === String ? $util.Long.prototype.toString.call(message.acceptedTime) : options.longs === Number ? new $util.LongBits(message.acceptedTime.low >>> 0, message.acceptedTime.high >>> 0).toNumber() : message.acceptedTime;
                if (message.canceledTime != null && message.hasOwnProperty("canceledTime"))
                    if (typeof message.canceledTime === "number")
                        object.canceledTime = options.longs === String ? String(message.canceledTime) : message.canceledTime;
                    else
                        object.canceledTime = options.longs === String ? $util.Long.prototype.toString.call(message.canceledTime) : options.longs === Number ? new $util.LongBits(message.canceledTime.low >>> 0, message.canceledTime.high >>> 0).toNumber() : message.canceledTime;
                if (message.executedTime != null && message.hasOwnProperty("executedTime"))
                    if (typeof message.executedTime === "number")
                        object.executedTime = options.longs === String ? String(message.executedTime) : message.executedTime;
                    else
                        object.executedTime = options.longs === String ? $util.Long.prototype.toString.call(message.executedTime) : options.longs === Number ? new $util.LongBits(message.executedTime.low >>> 0, message.executedTime.high >>> 0).toNumber() : message.executedTime;
                if (message.rejectedTime != null && message.hasOwnProperty("rejectedTime"))
                    if (typeof message.rejectedTime === "number")
                        object.rejectedTime = options.longs === String ? String(message.rejectedTime) : message.rejectedTime;
                    else
                        object.rejectedTime = options.longs === String ? $util.Long.prototype.toString.call(message.rejectedTime) : options.longs === Number ? new $util.LongBits(message.rejectedTime.low >>> 0, message.rejectedTime.high >>> 0).toNumber() : message.rejectedTime;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.mts.broker.Order.Status[message.status] === undefined ? message.status : $root.mts.broker.Order.Status[message.status] : message.status;
                if (message.rejectedReason != null && message.hasOwnProperty("rejectedReason"))
                    object.rejectedReason = message.rejectedReason;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.broker.Order.Type[message.type] === undefined ? message.type : $root.mts.broker.Order.Type[message.type] : message.type;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                if (message.fillPrice != null && message.hasOwnProperty("fillPrice"))
                    object.fillPrice = options.json && !isFinite(message.fillPrice) ? String(message.fillPrice) : message.fillPrice;
                if (message.fillDetail != null && message.hasOwnProperty("fillDetail"))
                    object.fillDetail = message.fillDetail;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.transaction != null && message.hasOwnProperty("transaction"))
                    object.transaction = options.enums === String ? $root.mts.broker.Order.Transaction[message.transaction] === undefined ? message.transaction : $root.mts.broker.Order.Transaction[message.transaction] : message.transaction;
                if (message.parentId != null && message.hasOwnProperty("parentId"))
                    object.parentId = message.parentId;
                if (message.uuid != null && message.hasOwnProperty("uuid"))
                    object.uuid = message.uuid;
                if (message.profitTaker != null && message.hasOwnProperty("profitTaker"))
                    object.profitTaker = $root.mts.broker.Order.toObject(message.profitTaker, options);
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    object.backtest = message.backtest;
                let keys2;
                if (message.customData && (keys2 = Object.keys(message.customData)).length) {
                    object.customData = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.customData[keys2[j]] = message.customData[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof mts.broker.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Order
             * @function getTypeUrl
             * @memberof mts.broker.Order
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.Order";
            };

            /**
             * Status enum.
             * @name mts.broker.Order.Status
             * @enum {number}
             * @property {number} UNKNOWN_STATUS=0 UNKNOWN_STATUS value
             * @property {number} SUBMITTED=1 SUBMITTED value
             * @property {number} ACCEPTED=2 ACCEPTED value
             * @property {number} REJECTED=3 REJECTED value
             * @property {number} FILLED=4 FILLED value
             * @property {number} CANCELED=5 CANCELED value
             * @property {number} NEW_ORDER=6 NEW_ORDER value
             */
            Order.Status = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_STATUS"] = 0;
                values[valuesById[1] = "SUBMITTED"] = 1;
                values[valuesById[2] = "ACCEPTED"] = 2;
                values[valuesById[3] = "REJECTED"] = 3;
                values[valuesById[4] = "FILLED"] = 4;
                values[valuesById[5] = "CANCELED"] = 5;
                values[valuesById[6] = "NEW_ORDER"] = 6;
                return values;
            })();

            /**
             * Type enum.
             * @name mts.broker.Order.Type
             * @enum {number}
             * @property {number} UNKNOWN_TYPE=0 UNKNOWN_TYPE value
             * @property {number} MARKET=1 MARKET value
             * @property {number} LIMIT=2 LIMIT value
             * @property {number} STOP=3 STOP value
             * @property {number} STOP_LIMIT=4 STOP_LIMIT value
             */
            Order.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_TYPE"] = 0;
                values[valuesById[1] = "MARKET"] = 1;
                values[valuesById[2] = "LIMIT"] = 2;
                values[valuesById[3] = "STOP"] = 3;
                values[valuesById[4] = "STOP_LIMIT"] = 4;
                return values;
            })();

            /**
             * Transaction enum.
             * @name mts.broker.Order.Transaction
             * @enum {number}
             * @property {number} UNKNOWN_TRANSACTION=0 UNKNOWN_TRANSACTION value
             * @property {number} BUY=1 BUY value
             * @property {number} SELL=2 SELL value
             * @property {number} SHORT=3 SHORT value
             * @property {number} COVER=4 COVER value
             */
            Order.Transaction = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_TRANSACTION"] = 0;
                values[valuesById[1] = "BUY"] = 1;
                values[valuesById[2] = "SELL"] = 2;
                values[valuesById[3] = "SHORT"] = 3;
                values[valuesById[4] = "COVER"] = 4;
                return values;
            })();

            return Order;
        })();

        broker.CreateOrderRequest = (function() {

            /**
             * Properties of a CreateOrderRequest.
             * @memberof mts.broker
             * @interface ICreateOrderRequest
             * @property {string|null} [accountId] CreateOrderRequest accountId
             * @property {string|null} [commentary] CreateOrderRequest commentary
             * @property {mts.broker.Order.Type|null} [type] CreateOrderRequest type
             * @property {number|null} [price] CreateOrderRequest price
             * @property {mts.broker.IOrder|null} [order] CreateOrderRequest order
             * @property {string|null} [symbol] CreateOrderRequest symbol
             * @property {number|null} [quantity] CreateOrderRequest quantity
             * @property {mts.broker.IOrder|null} [profitTaker] CreateOrderRequest profitTaker
             * @property {mts.broker.IOrder|null} [stopLoss] CreateOrderRequest stopLoss
             * @property {string|null} [manualDate] CreateOrderRequest manualDate
             * @property {number|null} [manualFillPrice] CreateOrderRequest manualFillPrice
             * @property {boolean|null} [backtest] CreateOrderRequest backtest
             * @property {Object.<string,string>|null} [customData] CreateOrderRequest customData
             */

            /**
             * Constructs a new CreateOrderRequest.
             * @memberof mts.broker
             * @classdesc Represents a CreateOrderRequest.
             * @implements ICreateOrderRequest
             * @constructor
             * @param {mts.broker.ICreateOrderRequest=} [properties] Properties to set
             */
            function CreateOrderRequest(properties) {
                this.customData = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateOrderRequest accountId.
             * @member {string} accountId
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.accountId = "";

            /**
             * CreateOrderRequest commentary.
             * @member {string} commentary
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.commentary = "";

            /**
             * CreateOrderRequest type.
             * @member {mts.broker.Order.Type} type
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.type = 0;

            /**
             * CreateOrderRequest price.
             * @member {number} price
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.price = 0;

            /**
             * CreateOrderRequest order.
             * @member {mts.broker.IOrder|null|undefined} order
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.order = null;

            /**
             * CreateOrderRequest symbol.
             * @member {string} symbol
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.symbol = "";

            /**
             * CreateOrderRequest quantity.
             * @member {number} quantity
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.quantity = 0;

            /**
             * CreateOrderRequest profitTaker.
             * @member {mts.broker.IOrder|null|undefined} profitTaker
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.profitTaker = null;

            /**
             * CreateOrderRequest stopLoss.
             * @member {mts.broker.IOrder|null|undefined} stopLoss
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.stopLoss = null;

            /**
             * CreateOrderRequest manualDate.
             * @member {string} manualDate
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.manualDate = "";

            /**
             * CreateOrderRequest manualFillPrice.
             * @member {number} manualFillPrice
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.manualFillPrice = 0;

            /**
             * CreateOrderRequest backtest.
             * @member {boolean} backtest
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.backtest = false;

            /**
             * CreateOrderRequest customData.
             * @member {Object.<string,string>} customData
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             */
            CreateOrderRequest.prototype.customData = $util.emptyObject;

            /**
             * Creates a new CreateOrderRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {mts.broker.ICreateOrderRequest=} [properties] Properties to set
             * @returns {mts.broker.CreateOrderRequest} CreateOrderRequest instance
             */
            CreateOrderRequest.create = function create(properties) {
                return new CreateOrderRequest(properties);
            };

            /**
             * Encodes the specified CreateOrderRequest message. Does not implicitly {@link mts.broker.CreateOrderRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {mts.broker.ICreateOrderRequest} message CreateOrderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrderRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                if (message.commentary != null && Object.hasOwnProperty.call(message, "commentary"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.commentary);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.price);
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.mts.broker.Order.encode(message.order, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.symbol);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.quantity);
                if (message.profitTaker != null && Object.hasOwnProperty.call(message, "profitTaker"))
                    $root.mts.broker.Order.encode(message.profitTaker, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.stopLoss != null && Object.hasOwnProperty.call(message, "stopLoss"))
                    $root.mts.broker.Order.encode(message.stopLoss, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.manualDate != null && Object.hasOwnProperty.call(message, "manualDate"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.manualDate);
                if (message.manualFillPrice != null && Object.hasOwnProperty.call(message, "manualFillPrice"))
                    writer.uint32(/* id 12, wireType 1 =*/97).double(message.manualFillPrice);
                if (message.backtest != null && Object.hasOwnProperty.call(message, "backtest"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.backtest);
                if (message.customData != null && Object.hasOwnProperty.call(message, "customData"))
                    for (let keys = Object.keys(message.customData), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 14, wireType 2 =*/114).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.customData[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateOrderRequest message, length delimited. Does not implicitly {@link mts.broker.CreateOrderRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {mts.broker.ICreateOrderRequest} message CreateOrderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrderRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateOrderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.CreateOrderRequest} CreateOrderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrderRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.CreateOrderRequest(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accountId = reader.string();
                            break;
                        }
                    case 2: {
                            message.commentary = reader.string();
                            break;
                        }
                    case 3: {
                            message.type = reader.int32();
                            break;
                        }
                    case 5: {
                            message.price = reader.double();
                            break;
                        }
                    case 6: {
                            message.order = $root.mts.broker.Order.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.symbol = reader.string();
                            break;
                        }
                    case 8: {
                            message.quantity = reader.int32();
                            break;
                        }
                    case 9: {
                            message.profitTaker = $root.mts.broker.Order.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.stopLoss = $root.mts.broker.Order.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.manualDate = reader.string();
                            break;
                        }
                    case 12: {
                            message.manualFillPrice = reader.double();
                            break;
                        }
                    case 13: {
                            message.backtest = reader.bool();
                            break;
                        }
                    case 14: {
                            if (message.customData === $util.emptyObject)
                                message.customData = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.customData[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateOrderRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.CreateOrderRequest} CreateOrderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrderRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateOrderRequest message.
             * @function verify
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateOrderRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    if (!$util.isString(message.commentary))
                        return "commentary: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price !== "number")
                        return "price: number expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    let error = $root.mts.broker.Order.verify(message.order);
                    if (error)
                        return "order." + error;
                }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.profitTaker != null && message.hasOwnProperty("profitTaker")) {
                    let error = $root.mts.broker.Order.verify(message.profitTaker);
                    if (error)
                        return "profitTaker." + error;
                }
                if (message.stopLoss != null && message.hasOwnProperty("stopLoss")) {
                    let error = $root.mts.broker.Order.verify(message.stopLoss);
                    if (error)
                        return "stopLoss." + error;
                }
                if (message.manualDate != null && message.hasOwnProperty("manualDate"))
                    if (!$util.isString(message.manualDate))
                        return "manualDate: string expected";
                if (message.manualFillPrice != null && message.hasOwnProperty("manualFillPrice"))
                    if (typeof message.manualFillPrice !== "number")
                        return "manualFillPrice: number expected";
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    if (typeof message.backtest !== "boolean")
                        return "backtest: boolean expected";
                if (message.customData != null && message.hasOwnProperty("customData")) {
                    if (!$util.isObject(message.customData))
                        return "customData: object expected";
                    let key = Object.keys(message.customData);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.customData[key[i]]))
                            return "customData: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates a CreateOrderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.CreateOrderRequest} CreateOrderRequest
             */
            CreateOrderRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.CreateOrderRequest)
                    return object;
                let message = new $root.mts.broker.CreateOrderRequest();
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                if (object.commentary != null)
                    message.commentary = String(object.commentary);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UNKNOWN_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "MARKET":
                case 1:
                    message.type = 1;
                    break;
                case "LIMIT":
                case 2:
                    message.type = 2;
                    break;
                case "STOP":
                case 3:
                    message.type = 3;
                    break;
                case "STOP_LIMIT":
                case 4:
                    message.type = 4;
                    break;
                }
                if (object.price != null)
                    message.price = Number(object.price);
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".mts.broker.CreateOrderRequest.order: object expected");
                    message.order = $root.mts.broker.Order.fromObject(object.order);
                }
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                if (object.profitTaker != null) {
                    if (typeof object.profitTaker !== "object")
                        throw TypeError(".mts.broker.CreateOrderRequest.profitTaker: object expected");
                    message.profitTaker = $root.mts.broker.Order.fromObject(object.profitTaker);
                }
                if (object.stopLoss != null) {
                    if (typeof object.stopLoss !== "object")
                        throw TypeError(".mts.broker.CreateOrderRequest.stopLoss: object expected");
                    message.stopLoss = $root.mts.broker.Order.fromObject(object.stopLoss);
                }
                if (object.manualDate != null)
                    message.manualDate = String(object.manualDate);
                if (object.manualFillPrice != null)
                    message.manualFillPrice = Number(object.manualFillPrice);
                if (object.backtest != null)
                    message.backtest = Boolean(object.backtest);
                if (object.customData) {
                    if (typeof object.customData !== "object")
                        throw TypeError(".mts.broker.CreateOrderRequest.customData: object expected");
                    message.customData = {};
                    for (let keys = Object.keys(object.customData), i = 0; i < keys.length; ++i)
                        message.customData[keys[i]] = String(object.customData[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateOrderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {mts.broker.CreateOrderRequest} message CreateOrderRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateOrderRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.customData = {};
                if (options.defaults) {
                    object.accountId = "";
                    object.commentary = "";
                    object.type = options.enums === String ? "UNKNOWN_TYPE" : 0;
                    object.price = 0;
                    object.order = null;
                    object.symbol = "";
                    object.quantity = 0;
                    object.profitTaker = null;
                    object.stopLoss = null;
                    object.manualDate = "";
                    object.manualFillPrice = 0;
                    object.backtest = false;
                }
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    object.commentary = message.commentary;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.broker.Order.Type[message.type] === undefined ? message.type : $root.mts.broker.Order.Type[message.type] : message.type;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                if (message.order != null && message.hasOwnProperty("order"))
                    object.order = $root.mts.broker.Order.toObject(message.order, options);
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.profitTaker != null && message.hasOwnProperty("profitTaker"))
                    object.profitTaker = $root.mts.broker.Order.toObject(message.profitTaker, options);
                if (message.stopLoss != null && message.hasOwnProperty("stopLoss"))
                    object.stopLoss = $root.mts.broker.Order.toObject(message.stopLoss, options);
                if (message.manualDate != null && message.hasOwnProperty("manualDate"))
                    object.manualDate = message.manualDate;
                if (message.manualFillPrice != null && message.hasOwnProperty("manualFillPrice"))
                    object.manualFillPrice = options.json && !isFinite(message.manualFillPrice) ? String(message.manualFillPrice) : message.manualFillPrice;
                if (message.backtest != null && message.hasOwnProperty("backtest"))
                    object.backtest = message.backtest;
                let keys2;
                if (message.customData && (keys2 = Object.keys(message.customData)).length) {
                    object.customData = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.customData[keys2[j]] = message.customData[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this CreateOrderRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.CreateOrderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateOrderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateOrderRequest
             * @function getTypeUrl
             * @memberof mts.broker.CreateOrderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateOrderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.CreateOrderRequest";
            };

            return CreateOrderRequest;
        })();

        broker.CreateOrderResponse = (function() {

            /**
             * Properties of a CreateOrderResponse.
             * @memberof mts.broker
             * @interface ICreateOrderResponse
             * @property {mts.broker.IOrder|null} [order] CreateOrderResponse order
             */

            /**
             * Constructs a new CreateOrderResponse.
             * @memberof mts.broker
             * @classdesc Represents a CreateOrderResponse.
             * @implements ICreateOrderResponse
             * @constructor
             * @param {mts.broker.ICreateOrderResponse=} [properties] Properties to set
             */
            function CreateOrderResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateOrderResponse order.
             * @member {mts.broker.IOrder|null|undefined} order
             * @memberof mts.broker.CreateOrderResponse
             * @instance
             */
            CreateOrderResponse.prototype.order = null;

            /**
             * Creates a new CreateOrderResponse instance using the specified properties.
             * @function create
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {mts.broker.ICreateOrderResponse=} [properties] Properties to set
             * @returns {mts.broker.CreateOrderResponse} CreateOrderResponse instance
             */
            CreateOrderResponse.create = function create(properties) {
                return new CreateOrderResponse(properties);
            };

            /**
             * Encodes the specified CreateOrderResponse message. Does not implicitly {@link mts.broker.CreateOrderResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {mts.broker.ICreateOrderResponse} message CreateOrderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrderResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.mts.broker.Order.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateOrderResponse message, length delimited. Does not implicitly {@link mts.broker.CreateOrderResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {mts.broker.ICreateOrderResponse} message CreateOrderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrderResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateOrderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.CreateOrderResponse} CreateOrderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrderResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.CreateOrderResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.order = $root.mts.broker.Order.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateOrderResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.CreateOrderResponse} CreateOrderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrderResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateOrderResponse message.
             * @function verify
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateOrderResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    let error = $root.mts.broker.Order.verify(message.order);
                    if (error)
                        return "order." + error;
                }
                return null;
            };

            /**
             * Creates a CreateOrderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.CreateOrderResponse} CreateOrderResponse
             */
            CreateOrderResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.CreateOrderResponse)
                    return object;
                let message = new $root.mts.broker.CreateOrderResponse();
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".mts.broker.CreateOrderResponse.order: object expected");
                    message.order = $root.mts.broker.Order.fromObject(object.order);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateOrderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {mts.broker.CreateOrderResponse} message CreateOrderResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateOrderResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.order = null;
                if (message.order != null && message.hasOwnProperty("order"))
                    object.order = $root.mts.broker.Order.toObject(message.order, options);
                return object;
            };

            /**
             * Converts this CreateOrderResponse to JSON.
             * @function toJSON
             * @memberof mts.broker.CreateOrderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateOrderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateOrderResponse
             * @function getTypeUrl
             * @memberof mts.broker.CreateOrderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateOrderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.CreateOrderResponse";
            };

            return CreateOrderResponse;
        })();

        broker.CancelOrderRequest = (function() {

            /**
             * Properties of a CancelOrderRequest.
             * @memberof mts.broker
             * @interface ICancelOrderRequest
             * @property {string|null} [accountId] CancelOrderRequest accountId
             * @property {string|null} [orderId] CancelOrderRequest orderId
             */

            /**
             * Constructs a new CancelOrderRequest.
             * @memberof mts.broker
             * @classdesc Represents a CancelOrderRequest.
             * @implements ICancelOrderRequest
             * @constructor
             * @param {mts.broker.ICancelOrderRequest=} [properties] Properties to set
             */
            function CancelOrderRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CancelOrderRequest accountId.
             * @member {string} accountId
             * @memberof mts.broker.CancelOrderRequest
             * @instance
             */
            CancelOrderRequest.prototype.accountId = "";

            /**
             * CancelOrderRequest orderId.
             * @member {string} orderId
             * @memberof mts.broker.CancelOrderRequest
             * @instance
             */
            CancelOrderRequest.prototype.orderId = "";

            /**
             * Creates a new CancelOrderRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {mts.broker.ICancelOrderRequest=} [properties] Properties to set
             * @returns {mts.broker.CancelOrderRequest} CancelOrderRequest instance
             */
            CancelOrderRequest.create = function create(properties) {
                return new CancelOrderRequest(properties);
            };

            /**
             * Encodes the specified CancelOrderRequest message. Does not implicitly {@link mts.broker.CancelOrderRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {mts.broker.ICancelOrderRequest} message CancelOrderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelOrderRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderId);
                return writer;
            };

            /**
             * Encodes the specified CancelOrderRequest message, length delimited. Does not implicitly {@link mts.broker.CancelOrderRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {mts.broker.ICancelOrderRequest} message CancelOrderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelOrderRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CancelOrderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.CancelOrderRequest} CancelOrderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelOrderRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.CancelOrderRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accountId = reader.string();
                            break;
                        }
                    case 2: {
                            message.orderId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CancelOrderRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.CancelOrderRequest} CancelOrderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelOrderRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CancelOrderRequest message.
             * @function verify
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelOrderRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isString(message.orderId))
                        return "orderId: string expected";
                return null;
            };

            /**
             * Creates a CancelOrderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.CancelOrderRequest} CancelOrderRequest
             */
            CancelOrderRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.CancelOrderRequest)
                    return object;
                let message = new $root.mts.broker.CancelOrderRequest();
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                if (object.orderId != null)
                    message.orderId = String(object.orderId);
                return message;
            };

            /**
             * Creates a plain object from a CancelOrderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {mts.broker.CancelOrderRequest} message CancelOrderRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelOrderRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.accountId = "";
                    object.orderId = "";
                }
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    object.orderId = message.orderId;
                return object;
            };

            /**
             * Converts this CancelOrderRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.CancelOrderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelOrderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CancelOrderRequest
             * @function getTypeUrl
             * @memberof mts.broker.CancelOrderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelOrderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.CancelOrderRequest";
            };

            return CancelOrderRequest;
        })();

        broker.CancelOrderResponse = (function() {

            /**
             * Properties of a CancelOrderResponse.
             * @memberof mts.broker
             * @interface ICancelOrderResponse
             * @property {mts.broker.IOrder|null} [order] CancelOrderResponse order
             */

            /**
             * Constructs a new CancelOrderResponse.
             * @memberof mts.broker
             * @classdesc Represents a CancelOrderResponse.
             * @implements ICancelOrderResponse
             * @constructor
             * @param {mts.broker.ICancelOrderResponse=} [properties] Properties to set
             */
            function CancelOrderResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CancelOrderResponse order.
             * @member {mts.broker.IOrder|null|undefined} order
             * @memberof mts.broker.CancelOrderResponse
             * @instance
             */
            CancelOrderResponse.prototype.order = null;

            /**
             * Creates a new CancelOrderResponse instance using the specified properties.
             * @function create
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {mts.broker.ICancelOrderResponse=} [properties] Properties to set
             * @returns {mts.broker.CancelOrderResponse} CancelOrderResponse instance
             */
            CancelOrderResponse.create = function create(properties) {
                return new CancelOrderResponse(properties);
            };

            /**
             * Encodes the specified CancelOrderResponse message. Does not implicitly {@link mts.broker.CancelOrderResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {mts.broker.ICancelOrderResponse} message CancelOrderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelOrderResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.mts.broker.Order.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CancelOrderResponse message, length delimited. Does not implicitly {@link mts.broker.CancelOrderResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {mts.broker.ICancelOrderResponse} message CancelOrderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelOrderResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CancelOrderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.CancelOrderResponse} CancelOrderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelOrderResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.CancelOrderResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order = $root.mts.broker.Order.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CancelOrderResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.CancelOrderResponse} CancelOrderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelOrderResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CancelOrderResponse message.
             * @function verify
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelOrderResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    let error = $root.mts.broker.Order.verify(message.order);
                    if (error)
                        return "order." + error;
                }
                return null;
            };

            /**
             * Creates a CancelOrderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.CancelOrderResponse} CancelOrderResponse
             */
            CancelOrderResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.CancelOrderResponse)
                    return object;
                let message = new $root.mts.broker.CancelOrderResponse();
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".mts.broker.CancelOrderResponse.order: object expected");
                    message.order = $root.mts.broker.Order.fromObject(object.order);
                }
                return message;
            };

            /**
             * Creates a plain object from a CancelOrderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {mts.broker.CancelOrderResponse} message CancelOrderResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelOrderResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.order = null;
                if (message.order != null && message.hasOwnProperty("order"))
                    object.order = $root.mts.broker.Order.toObject(message.order, options);
                return object;
            };

            /**
             * Converts this CancelOrderResponse to JSON.
             * @function toJSON
             * @memberof mts.broker.CancelOrderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelOrderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CancelOrderResponse
             * @function getTypeUrl
             * @memberof mts.broker.CancelOrderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelOrderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.CancelOrderResponse";
            };

            return CancelOrderResponse;
        })();

        broker.GetOrdersRequest = (function() {

            /**
             * Properties of a GetOrdersRequest.
             * @memberof mts.broker
             * @interface IGetOrdersRequest
             * @property {string|null} [accountId] GetOrdersRequest accountId
             * @property {mts.broker.Order.Status|null} [status] GetOrdersRequest status
             */

            /**
             * Constructs a new GetOrdersRequest.
             * @memberof mts.broker
             * @classdesc Represents a GetOrdersRequest.
             * @implements IGetOrdersRequest
             * @constructor
             * @param {mts.broker.IGetOrdersRequest=} [properties] Properties to set
             */
            function GetOrdersRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrdersRequest accountId.
             * @member {string} accountId
             * @memberof mts.broker.GetOrdersRequest
             * @instance
             */
            GetOrdersRequest.prototype.accountId = "";

            /**
             * GetOrdersRequest status.
             * @member {mts.broker.Order.Status} status
             * @memberof mts.broker.GetOrdersRequest
             * @instance
             */
            GetOrdersRequest.prototype.status = 0;

            /**
             * Creates a new GetOrdersRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {mts.broker.IGetOrdersRequest=} [properties] Properties to set
             * @returns {mts.broker.GetOrdersRequest} GetOrdersRequest instance
             */
            GetOrdersRequest.create = function create(properties) {
                return new GetOrdersRequest(properties);
            };

            /**
             * Encodes the specified GetOrdersRequest message. Does not implicitly {@link mts.broker.GetOrdersRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {mts.broker.IGetOrdersRequest} message GetOrdersRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrdersRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                return writer;
            };

            /**
             * Encodes the specified GetOrdersRequest message, length delimited. Does not implicitly {@link mts.broker.GetOrdersRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {mts.broker.IGetOrdersRequest} message GetOrdersRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrdersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetOrdersRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.GetOrdersRequest} GetOrdersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrdersRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.GetOrdersRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accountId = reader.string();
                            break;
                        }
                    case 2: {
                            message.status = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetOrdersRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.GetOrdersRequest} GetOrdersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrdersRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetOrdersRequest message.
             * @function verify
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrdersRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isString(message.accountId))
                        return "accountId: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetOrdersRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.GetOrdersRequest} GetOrdersRequest
             */
            GetOrdersRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.GetOrdersRequest)
                    return object;
                let message = new $root.mts.broker.GetOrdersRequest();
                if (object.accountId != null)
                    message.accountId = String(object.accountId);
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "UNKNOWN_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "SUBMITTED":
                case 1:
                    message.status = 1;
                    break;
                case "ACCEPTED":
                case 2:
                    message.status = 2;
                    break;
                case "REJECTED":
                case 3:
                    message.status = 3;
                    break;
                case "FILLED":
                case 4:
                    message.status = 4;
                    break;
                case "CANCELED":
                case 5:
                    message.status = 5;
                    break;
                case "NEW_ORDER":
                case 6:
                    message.status = 6;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrdersRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {mts.broker.GetOrdersRequest} message GetOrdersRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrdersRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.accountId = "";
                    object.status = options.enums === String ? "UNKNOWN_STATUS" : 0;
                }
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.mts.broker.Order.Status[message.status] === undefined ? message.status : $root.mts.broker.Order.Status[message.status] : message.status;
                return object;
            };

            /**
             * Converts this GetOrdersRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.GetOrdersRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrdersRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetOrdersRequest
             * @function getTypeUrl
             * @memberof mts.broker.GetOrdersRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetOrdersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.GetOrdersRequest";
            };

            return GetOrdersRequest;
        })();

        broker.GetOrdersResponse = (function() {

            /**
             * Properties of a GetOrdersResponse.
             * @memberof mts.broker
             * @interface IGetOrdersResponse
             * @property {Array.<mts.broker.IOrder>|null} [orders] GetOrdersResponse orders
             */

            /**
             * Constructs a new GetOrdersResponse.
             * @memberof mts.broker
             * @classdesc Represents a GetOrdersResponse.
             * @implements IGetOrdersResponse
             * @constructor
             * @param {mts.broker.IGetOrdersResponse=} [properties] Properties to set
             */
            function GetOrdersResponse(properties) {
                this.orders = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrdersResponse orders.
             * @member {Array.<mts.broker.IOrder>} orders
             * @memberof mts.broker.GetOrdersResponse
             * @instance
             */
            GetOrdersResponse.prototype.orders = $util.emptyArray;

            /**
             * Creates a new GetOrdersResponse instance using the specified properties.
             * @function create
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {mts.broker.IGetOrdersResponse=} [properties] Properties to set
             * @returns {mts.broker.GetOrdersResponse} GetOrdersResponse instance
             */
            GetOrdersResponse.create = function create(properties) {
                return new GetOrdersResponse(properties);
            };

            /**
             * Encodes the specified GetOrdersResponse message. Does not implicitly {@link mts.broker.GetOrdersResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {mts.broker.IGetOrdersResponse} message GetOrdersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrdersResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orders != null && message.orders.length)
                    for (let i = 0; i < message.orders.length; ++i)
                        $root.mts.broker.Order.encode(message.orders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetOrdersResponse message, length delimited. Does not implicitly {@link mts.broker.GetOrdersResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {mts.broker.IGetOrdersResponse} message GetOrdersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrdersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetOrdersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.GetOrdersResponse} GetOrdersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrdersResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.GetOrdersResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.orders && message.orders.length))
                                message.orders = [];
                            message.orders.push($root.mts.broker.Order.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetOrdersResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.GetOrdersResponse} GetOrdersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrdersResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetOrdersResponse message.
             * @function verify
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrdersResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orders != null && message.hasOwnProperty("orders")) {
                    if (!Array.isArray(message.orders))
                        return "orders: array expected";
                    for (let i = 0; i < message.orders.length; ++i) {
                        let error = $root.mts.broker.Order.verify(message.orders[i]);
                        if (error)
                            return "orders." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetOrdersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.GetOrdersResponse} GetOrdersResponse
             */
            GetOrdersResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.GetOrdersResponse)
                    return object;
                let message = new $root.mts.broker.GetOrdersResponse();
                if (object.orders) {
                    if (!Array.isArray(object.orders))
                        throw TypeError(".mts.broker.GetOrdersResponse.orders: array expected");
                    message.orders = [];
                    for (let i = 0; i < object.orders.length; ++i) {
                        if (typeof object.orders[i] !== "object")
                            throw TypeError(".mts.broker.GetOrdersResponse.orders: object expected");
                        message.orders[i] = $root.mts.broker.Order.fromObject(object.orders[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrdersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {mts.broker.GetOrdersResponse} message GetOrdersResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrdersResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.orders = [];
                if (message.orders && message.orders.length) {
                    object.orders = [];
                    for (let j = 0; j < message.orders.length; ++j)
                        object.orders[j] = $root.mts.broker.Order.toObject(message.orders[j], options);
                }
                return object;
            };

            /**
             * Converts this GetOrdersResponse to JSON.
             * @function toJSON
             * @memberof mts.broker.GetOrdersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrdersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetOrdersResponse
             * @function getTypeUrl
             * @memberof mts.broker.GetOrdersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetOrdersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.GetOrdersResponse";
            };

            return GetOrdersResponse;
        })();

        broker.AddAccountRequest = (function() {

            /**
             * Properties of an AddAccountRequest.
             * @memberof mts.broker
             * @interface IAddAccountRequest
             * @property {string|null} [name] AddAccountRequest name
             * @property {number|null} [initialDeposit] AddAccountRequest initialDeposit
             */

            /**
             * Constructs a new AddAccountRequest.
             * @memberof mts.broker
             * @classdesc Represents an AddAccountRequest.
             * @implements IAddAccountRequest
             * @constructor
             * @param {mts.broker.IAddAccountRequest=} [properties] Properties to set
             */
            function AddAccountRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddAccountRequest name.
             * @member {string} name
             * @memberof mts.broker.AddAccountRequest
             * @instance
             */
            AddAccountRequest.prototype.name = "";

            /**
             * AddAccountRequest initialDeposit.
             * @member {number} initialDeposit
             * @memberof mts.broker.AddAccountRequest
             * @instance
             */
            AddAccountRequest.prototype.initialDeposit = 0;

            /**
             * Creates a new AddAccountRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {mts.broker.IAddAccountRequest=} [properties] Properties to set
             * @returns {mts.broker.AddAccountRequest} AddAccountRequest instance
             */
            AddAccountRequest.create = function create(properties) {
                return new AddAccountRequest(properties);
            };

            /**
             * Encodes the specified AddAccountRequest message. Does not implicitly {@link mts.broker.AddAccountRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {mts.broker.IAddAccountRequest} message AddAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAccountRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.initialDeposit != null && Object.hasOwnProperty.call(message, "initialDeposit"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.initialDeposit);
                return writer;
            };

            /**
             * Encodes the specified AddAccountRequest message, length delimited. Does not implicitly {@link mts.broker.AddAccountRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {mts.broker.IAddAccountRequest} message AddAccountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddAccountRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.AddAccountRequest} AddAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAccountRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.AddAccountRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.initialDeposit = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddAccountRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.AddAccountRequest} AddAccountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddAccountRequest message.
             * @function verify
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddAccountRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.initialDeposit != null && message.hasOwnProperty("initialDeposit"))
                    if (typeof message.initialDeposit !== "number")
                        return "initialDeposit: number expected";
                return null;
            };

            /**
             * Creates an AddAccountRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.AddAccountRequest} AddAccountRequest
             */
            AddAccountRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.AddAccountRequest)
                    return object;
                let message = new $root.mts.broker.AddAccountRequest();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.initialDeposit != null)
                    message.initialDeposit = Number(object.initialDeposit);
                return message;
            };

            /**
             * Creates a plain object from an AddAccountRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {mts.broker.AddAccountRequest} message AddAccountRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddAccountRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.initialDeposit = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.initialDeposit != null && message.hasOwnProperty("initialDeposit"))
                    object.initialDeposit = options.json && !isFinite(message.initialDeposit) ? String(message.initialDeposit) : message.initialDeposit;
                return object;
            };

            /**
             * Converts this AddAccountRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.AddAccountRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddAccountRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddAccountRequest
             * @function getTypeUrl
             * @memberof mts.broker.AddAccountRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.AddAccountRequest";
            };

            return AddAccountRequest;
        })();

        broker.ListAccountsRequest = (function() {

            /**
             * Properties of a ListAccountsRequest.
             * @memberof mts.broker
             * @interface IListAccountsRequest
             */

            /**
             * Constructs a new ListAccountsRequest.
             * @memberof mts.broker
             * @classdesc Represents a ListAccountsRequest.
             * @implements IListAccountsRequest
             * @constructor
             * @param {mts.broker.IListAccountsRequest=} [properties] Properties to set
             */
            function ListAccountsRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ListAccountsRequest instance using the specified properties.
             * @function create
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {mts.broker.IListAccountsRequest=} [properties] Properties to set
             * @returns {mts.broker.ListAccountsRequest} ListAccountsRequest instance
             */
            ListAccountsRequest.create = function create(properties) {
                return new ListAccountsRequest(properties);
            };

            /**
             * Encodes the specified ListAccountsRequest message. Does not implicitly {@link mts.broker.ListAccountsRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {mts.broker.IListAccountsRequest} message ListAccountsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListAccountsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ListAccountsRequest message, length delimited. Does not implicitly {@link mts.broker.ListAccountsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {mts.broker.IListAccountsRequest} message ListAccountsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListAccountsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListAccountsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.ListAccountsRequest} ListAccountsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListAccountsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.ListAccountsRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListAccountsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.ListAccountsRequest} ListAccountsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListAccountsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListAccountsRequest message.
             * @function verify
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListAccountsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ListAccountsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.ListAccountsRequest} ListAccountsRequest
             */
            ListAccountsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.ListAccountsRequest)
                    return object;
                return new $root.mts.broker.ListAccountsRequest();
            };

            /**
             * Creates a plain object from a ListAccountsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {mts.broker.ListAccountsRequest} message ListAccountsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListAccountsRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ListAccountsRequest to JSON.
             * @function toJSON
             * @memberof mts.broker.ListAccountsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListAccountsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListAccountsRequest
             * @function getTypeUrl
             * @memberof mts.broker.ListAccountsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListAccountsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.ListAccountsRequest";
            };

            return ListAccountsRequest;
        })();

        broker.ListAccountsResponse = (function() {

            /**
             * Properties of a ListAccountsResponse.
             * @memberof mts.broker
             * @interface IListAccountsResponse
             * @property {Array.<mts.broker.IAccount>|null} [accounts] ListAccountsResponse accounts
             */

            /**
             * Constructs a new ListAccountsResponse.
             * @memberof mts.broker
             * @classdesc Represents a ListAccountsResponse.
             * @implements IListAccountsResponse
             * @constructor
             * @param {mts.broker.IListAccountsResponse=} [properties] Properties to set
             */
            function ListAccountsResponse(properties) {
                this.accounts = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListAccountsResponse accounts.
             * @member {Array.<mts.broker.IAccount>} accounts
             * @memberof mts.broker.ListAccountsResponse
             * @instance
             */
            ListAccountsResponse.prototype.accounts = $util.emptyArray;

            /**
             * Creates a new ListAccountsResponse instance using the specified properties.
             * @function create
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {mts.broker.IListAccountsResponse=} [properties] Properties to set
             * @returns {mts.broker.ListAccountsResponse} ListAccountsResponse instance
             */
            ListAccountsResponse.create = function create(properties) {
                return new ListAccountsResponse(properties);
            };

            /**
             * Encodes the specified ListAccountsResponse message. Does not implicitly {@link mts.broker.ListAccountsResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {mts.broker.IListAccountsResponse} message ListAccountsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListAccountsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accounts != null && message.accounts.length)
                    for (let i = 0; i < message.accounts.length; ++i)
                        $root.mts.broker.Account.encode(message.accounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ListAccountsResponse message, length delimited. Does not implicitly {@link mts.broker.ListAccountsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {mts.broker.IListAccountsResponse} message ListAccountsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListAccountsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListAccountsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.broker.ListAccountsResponse} ListAccountsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListAccountsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.broker.ListAccountsResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.accounts && message.accounts.length))
                                message.accounts = [];
                            message.accounts.push($root.mts.broker.Account.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListAccountsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.broker.ListAccountsResponse} ListAccountsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListAccountsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListAccountsResponse message.
             * @function verify
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListAccountsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accounts != null && message.hasOwnProperty("accounts")) {
                    if (!Array.isArray(message.accounts))
                        return "accounts: array expected";
                    for (let i = 0; i < message.accounts.length; ++i) {
                        let error = $root.mts.broker.Account.verify(message.accounts[i]);
                        if (error)
                            return "accounts." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ListAccountsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.broker.ListAccountsResponse} ListAccountsResponse
             */
            ListAccountsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.broker.ListAccountsResponse)
                    return object;
                let message = new $root.mts.broker.ListAccountsResponse();
                if (object.accounts) {
                    if (!Array.isArray(object.accounts))
                        throw TypeError(".mts.broker.ListAccountsResponse.accounts: array expected");
                    message.accounts = [];
                    for (let i = 0; i < object.accounts.length; ++i) {
                        if (typeof object.accounts[i] !== "object")
                            throw TypeError(".mts.broker.ListAccountsResponse.accounts: object expected");
                        message.accounts[i] = $root.mts.broker.Account.fromObject(object.accounts[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ListAccountsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {mts.broker.ListAccountsResponse} message ListAccountsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListAccountsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.accounts = [];
                if (message.accounts && message.accounts.length) {
                    object.accounts = [];
                    for (let j = 0; j < message.accounts.length; ++j)
                        object.accounts[j] = $root.mts.broker.Account.toObject(message.accounts[j], options);
                }
                return object;
            };

            /**
             * Converts this ListAccountsResponse to JSON.
             * @function toJSON
             * @memberof mts.broker.ListAccountsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListAccountsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListAccountsResponse
             * @function getTypeUrl
             * @memberof mts.broker.ListAccountsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListAccountsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.broker.ListAccountsResponse";
            };

            return ListAccountsResponse;
        })();

        return broker;
    })();

    mts.portfolio = (function() {

        /**
         * Namespace portfolio.
         * @memberof mts
         * @namespace
         */
        const portfolio = {};

        portfolio.PortfolioService = (function() {

            /**
             * Constructs a new PortfolioService service.
             * @memberof mts.portfolio
             * @classdesc Represents a PortfolioService
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function PortfolioService(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (PortfolioService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PortfolioService;

            /**
             * Creates new PortfolioService service using the specified rpc implementation.
             * @function create
             * @memberof mts.portfolio.PortfolioService
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {PortfolioService} RPC service. Useful where requests and/or responses are streamed.
             */
            PortfolioService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#addPortfolio}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef addPortfolioCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.Portfolio} [response] Portfolio
             */

            /**
             * Calls addPortfolio.
             * @function addPortfolio
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IAddPortfolioRequest} request AddPortfolioRequest message or plain object
             * @param {mts.portfolio.PortfolioService.addPortfolioCallback} callback Node-style callback called with the error, if any, and Portfolio
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.addPortfolio = function addPortfolio(request, callback) {
                return this.rpcCall(addPortfolio, $root.mts.portfolio.AddPortfolioRequest, $root.mts.portfolio.Portfolio, request, callback);
            }, "name", { value: "addPortfolio" });

            /**
             * Calls addPortfolio.
             * @function addPortfolio
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IAddPortfolioRequest} request AddPortfolioRequest message or plain object
             * @returns {Promise<mts.portfolio.Portfolio>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#addTransaction}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef addTransactionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.Tx} [response] Tx
             */

            /**
             * Calls addTransaction.
             * @function addTransaction
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IAddTransactionRequest} request AddTransactionRequest message or plain object
             * @param {mts.portfolio.PortfolioService.addTransactionCallback} callback Node-style callback called with the error, if any, and Tx
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.addTransaction = function addTransaction(request, callback) {
                return this.rpcCall(addTransaction, $root.mts.portfolio.AddTransactionRequest, $root.mts.portfolio.Tx, request, callback);
            }, "name", { value: "addTransaction" });

            /**
             * Calls addTransaction.
             * @function addTransaction
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IAddTransactionRequest} request AddTransactionRequest message or plain object
             * @returns {Promise<mts.portfolio.Tx>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#getPortfolio}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef getPortfolioCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.GetPortfolioResponse} [response] GetPortfolioResponse
             */

            /**
             * Calls getPortfolio.
             * @function getPortfolio
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IGetPortfolioRequest} request GetPortfolioRequest message or plain object
             * @param {mts.portfolio.PortfolioService.getPortfolioCallback} callback Node-style callback called with the error, if any, and GetPortfolioResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.getPortfolio = function getPortfolio(request, callback) {
                return this.rpcCall(getPortfolio, $root.mts.portfolio.GetPortfolioRequest, $root.mts.portfolio.GetPortfolioResponse, request, callback);
            }, "name", { value: "getPortfolio" });

            /**
             * Calls getPortfolio.
             * @function getPortfolio
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IGetPortfolioRequest} request GetPortfolioRequest message or plain object
             * @returns {Promise<mts.portfolio.GetPortfolioResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#endPortfolio}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef endPortfolioCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.EndPortfolioResponse} [response] EndPortfolioResponse
             */

            /**
             * Calls endPortfolio.
             * @function endPortfolio
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IEndPortfolioRequest} request EndPortfolioRequest message or plain object
             * @param {mts.portfolio.PortfolioService.endPortfolioCallback} callback Node-style callback called with the error, if any, and EndPortfolioResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.endPortfolio = function endPortfolio(request, callback) {
                return this.rpcCall(endPortfolio, $root.mts.portfolio.EndPortfolioRequest, $root.mts.portfolio.EndPortfolioResponse, request, callback);
            }, "name", { value: "endPortfolio" });

            /**
             * Calls endPortfolio.
             * @function endPortfolio
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IEndPortfolioRequest} request EndPortfolioRequest message or plain object
             * @returns {Promise<mts.portfolio.EndPortfolioResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#getTransactions}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef getTransactionsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.GetTransactionsResponse} [response] GetTransactionsResponse
             */

            /**
             * Calls getTransactions.
             * @function getTransactions
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IGetTransactionsRequest} request GetTransactionsRequest message or plain object
             * @param {mts.portfolio.PortfolioService.getTransactionsCallback} callback Node-style callback called with the error, if any, and GetTransactionsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.getTransactions = function getTransactions(request, callback) {
                return this.rpcCall(getTransactions, $root.mts.portfolio.GetTransactionsRequest, $root.mts.portfolio.GetTransactionsResponse, request, callback);
            }, "name", { value: "getTransactions" });

            /**
             * Calls getTransactions.
             * @function getTransactions
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IGetTransactionsRequest} request GetTransactionsRequest message or plain object
             * @returns {Promise<mts.portfolio.GetTransactionsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#getPositions}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef getPositionsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.GetPositionsResponse} [response] GetPositionsResponse
             */

            /**
             * Calls getPositions.
             * @function getPositions
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IGetPositionsRequest} request GetPositionsRequest message or plain object
             * @param {mts.portfolio.PortfolioService.getPositionsCallback} callback Node-style callback called with the error, if any, and GetPositionsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.getPositions = function getPositions(request, callback) {
                return this.rpcCall(getPositions, $root.mts.portfolio.GetPositionsRequest, $root.mts.portfolio.GetPositionsResponse, request, callback);
            }, "name", { value: "getPositions" });

            /**
             * Calls getPositions.
             * @function getPositions
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IGetPositionsRequest} request GetPositionsRequest message or plain object
             * @returns {Promise<mts.portfolio.GetPositionsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#buildHistoricalPerformance}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef buildHistoricalPerformanceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.BuildHistoricalPerformanceResponse} [response] BuildHistoricalPerformanceResponse
             */

            /**
             * Calls buildHistoricalPerformance.
             * @function buildHistoricalPerformance
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IBuildRequest} request BuildRequest message or plain object
             * @param {mts.portfolio.PortfolioService.buildHistoricalPerformanceCallback} callback Node-style callback called with the error, if any, and BuildHistoricalPerformanceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.buildHistoricalPerformance = function buildHistoricalPerformance(request, callback) {
                return this.rpcCall(buildHistoricalPerformance, $root.mts.portfolio.BuildRequest, $root.mts.portfolio.BuildHistoricalPerformanceResponse, request, callback);
            }, "name", { value: "buildHistoricalPerformance" });

            /**
             * Calls buildHistoricalPerformance.
             * @function buildHistoricalPerformance
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IBuildRequest} request BuildRequest message or plain object
             * @returns {Promise<mts.portfolio.BuildHistoricalPerformanceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link mts.portfolio.PortfolioService#reset}.
             * @memberof mts.portfolio.PortfolioService
             * @typedef resetCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {mts.portfolio.ResetResponse} [response] ResetResponse
             */

            /**
             * Calls reset.
             * @function reset
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IResetRequest} request ResetRequest message or plain object
             * @param {mts.portfolio.PortfolioService.resetCallback} callback Node-style callback called with the error, if any, and ResetResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(PortfolioService.prototype.reset = function reset(request, callback) {
                return this.rpcCall(reset, $root.mts.portfolio.ResetRequest, $root.mts.portfolio.ResetResponse, request, callback);
            }, "name", { value: "reset" });

            /**
             * Calls reset.
             * @function reset
             * @memberof mts.portfolio.PortfolioService
             * @instance
             * @param {mts.portfolio.IResetRequest} request ResetRequest message or plain object
             * @returns {Promise<mts.portfolio.ResetResponse>} Promise
             * @variation 2
             */

            return PortfolioService;
        })();

        portfolio.ResetRequest = (function() {

            /**
             * Properties of a ResetRequest.
             * @memberof mts.portfolio
             * @interface IResetRequest
             * @property {string|null} [portfolioId] ResetRequest portfolioId
             */

            /**
             * Constructs a new ResetRequest.
             * @memberof mts.portfolio
             * @classdesc Represents a ResetRequest.
             * @implements IResetRequest
             * @constructor
             * @param {mts.portfolio.IResetRequest=} [properties] Properties to set
             */
            function ResetRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ResetRequest portfolioId.
             * @member {string} portfolioId
             * @memberof mts.portfolio.ResetRequest
             * @instance
             */
            ResetRequest.prototype.portfolioId = "";

            /**
             * Creates a new ResetRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {mts.portfolio.IResetRequest=} [properties] Properties to set
             * @returns {mts.portfolio.ResetRequest} ResetRequest instance
             */
            ResetRequest.create = function create(properties) {
                return new ResetRequest(properties);
            };

            /**
             * Encodes the specified ResetRequest message. Does not implicitly {@link mts.portfolio.ResetRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {mts.portfolio.IResetRequest} message ResetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.portfolioId);
                return writer;
            };

            /**
             * Encodes the specified ResetRequest message, length delimited. Does not implicitly {@link mts.portfolio.ResetRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {mts.portfolio.IResetRequest} message ResetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ResetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.ResetRequest} ResetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.ResetRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ResetRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.ResetRequest} ResetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ResetRequest message.
             * @function verify
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResetRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                return null;
            };

            /**
             * Creates a ResetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.ResetRequest} ResetRequest
             */
            ResetRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.ResetRequest)
                    return object;
                let message = new $root.mts.portfolio.ResetRequest();
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                return message;
            };

            /**
             * Creates a plain object from a ResetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {mts.portfolio.ResetRequest} message ResetRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResetRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.portfolioId = "";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                return object;
            };

            /**
             * Converts this ResetRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.ResetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ResetRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.ResetRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.ResetRequest";
            };

            return ResetRequest;
        })();

        portfolio.ResetResponse = (function() {

            /**
             * Properties of a ResetResponse.
             * @memberof mts.portfolio
             * @interface IResetResponse
             * @property {mts.portfolio.IPortfolio|null} [portfolio] ResetResponse portfolio
             */

            /**
             * Constructs a new ResetResponse.
             * @memberof mts.portfolio
             * @classdesc Represents a ResetResponse.
             * @implements IResetResponse
             * @constructor
             * @param {mts.portfolio.IResetResponse=} [properties] Properties to set
             */
            function ResetResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ResetResponse portfolio.
             * @member {mts.portfolio.IPortfolio|null|undefined} portfolio
             * @memberof mts.portfolio.ResetResponse
             * @instance
             */
            ResetResponse.prototype.portfolio = null;

            /**
             * Creates a new ResetResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {mts.portfolio.IResetResponse=} [properties] Properties to set
             * @returns {mts.portfolio.ResetResponse} ResetResponse instance
             */
            ResetResponse.create = function create(properties) {
                return new ResetResponse(properties);
            };

            /**
             * Encodes the specified ResetResponse message. Does not implicitly {@link mts.portfolio.ResetResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {mts.portfolio.IResetResponse} message ResetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolio != null && Object.hasOwnProperty.call(message, "portfolio"))
                    $root.mts.portfolio.Portfolio.encode(message.portfolio, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ResetResponse message, length delimited. Does not implicitly {@link mts.portfolio.ResetResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {mts.portfolio.IResetResponse} message ResetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ResetResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.ResetResponse} ResetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.ResetResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolio = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ResetResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.ResetResponse} ResetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ResetResponse message.
             * @function verify
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResetResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolio != null && message.hasOwnProperty("portfolio")) {
                    let error = $root.mts.portfolio.Portfolio.verify(message.portfolio);
                    if (error)
                        return "portfolio." + error;
                }
                return null;
            };

            /**
             * Creates a ResetResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.ResetResponse} ResetResponse
             */
            ResetResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.ResetResponse)
                    return object;
                let message = new $root.mts.portfolio.ResetResponse();
                if (object.portfolio != null) {
                    if (typeof object.portfolio !== "object")
                        throw TypeError(".mts.portfolio.ResetResponse.portfolio: object expected");
                    message.portfolio = $root.mts.portfolio.Portfolio.fromObject(object.portfolio);
                }
                return message;
            };

            /**
             * Creates a plain object from a ResetResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {mts.portfolio.ResetResponse} message ResetResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResetResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.portfolio = null;
                if (message.portfolio != null && message.hasOwnProperty("portfolio"))
                    object.portfolio = $root.mts.portfolio.Portfolio.toObject(message.portfolio, options);
                return object;
            };

            /**
             * Converts this ResetResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.ResetResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResetResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ResetResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.ResetResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.ResetResponse";
            };

            return ResetResponse;
        })();

        portfolio.EndPortfolioRequest = (function() {

            /**
             * Properties of an EndPortfolioRequest.
             * @memberof mts.portfolio
             * @interface IEndPortfolioRequest
             * @property {string|null} [portfolioId] EndPortfolioRequest portfolioId
             * @property {string|null} [endDate] EndPortfolioRequest endDate
             */

            /**
             * Constructs a new EndPortfolioRequest.
             * @memberof mts.portfolio
             * @classdesc Represents an EndPortfolioRequest.
             * @implements IEndPortfolioRequest
             * @constructor
             * @param {mts.portfolio.IEndPortfolioRequest=} [properties] Properties to set
             */
            function EndPortfolioRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EndPortfolioRequest portfolioId.
             * @member {string} portfolioId
             * @memberof mts.portfolio.EndPortfolioRequest
             * @instance
             */
            EndPortfolioRequest.prototype.portfolioId = "";

            /**
             * EndPortfolioRequest endDate.
             * @member {string} endDate
             * @memberof mts.portfolio.EndPortfolioRequest
             * @instance
             */
            EndPortfolioRequest.prototype.endDate = "";

            /**
             * Creates a new EndPortfolioRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {mts.portfolio.IEndPortfolioRequest=} [properties] Properties to set
             * @returns {mts.portfolio.EndPortfolioRequest} EndPortfolioRequest instance
             */
            EndPortfolioRequest.create = function create(properties) {
                return new EndPortfolioRequest(properties);
            };

            /**
             * Encodes the specified EndPortfolioRequest message. Does not implicitly {@link mts.portfolio.EndPortfolioRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {mts.portfolio.IEndPortfolioRequest} message EndPortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EndPortfolioRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.portfolioId);
                if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.endDate);
                return writer;
            };

            /**
             * Encodes the specified EndPortfolioRequest message, length delimited. Does not implicitly {@link mts.portfolio.EndPortfolioRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {mts.portfolio.IEndPortfolioRequest} message EndPortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EndPortfolioRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EndPortfolioRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.EndPortfolioRequest} EndPortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EndPortfolioRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.EndPortfolioRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    case 2: {
                            message.endDate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EndPortfolioRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.EndPortfolioRequest} EndPortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EndPortfolioRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EndPortfolioRequest message.
             * @function verify
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EndPortfolioRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                if (message.endDate != null && message.hasOwnProperty("endDate"))
                    if (!$util.isString(message.endDate))
                        return "endDate: string expected";
                return null;
            };

            /**
             * Creates an EndPortfolioRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.EndPortfolioRequest} EndPortfolioRequest
             */
            EndPortfolioRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.EndPortfolioRequest)
                    return object;
                let message = new $root.mts.portfolio.EndPortfolioRequest();
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                if (object.endDate != null)
                    message.endDate = String(object.endDate);
                return message;
            };

            /**
             * Creates a plain object from an EndPortfolioRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {mts.portfolio.EndPortfolioRequest} message EndPortfolioRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EndPortfolioRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.portfolioId = "";
                    object.endDate = "";
                }
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.endDate != null && message.hasOwnProperty("endDate"))
                    object.endDate = message.endDate;
                return object;
            };

            /**
             * Converts this EndPortfolioRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.EndPortfolioRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EndPortfolioRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EndPortfolioRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.EndPortfolioRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EndPortfolioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.EndPortfolioRequest";
            };

            return EndPortfolioRequest;
        })();

        portfolio.EndPortfolioResponse = (function() {

            /**
             * Properties of an EndPortfolioResponse.
             * @memberof mts.portfolio
             * @interface IEndPortfolioResponse
             */

            /**
             * Constructs a new EndPortfolioResponse.
             * @memberof mts.portfolio
             * @classdesc Represents an EndPortfolioResponse.
             * @implements IEndPortfolioResponse
             * @constructor
             * @param {mts.portfolio.IEndPortfolioResponse=} [properties] Properties to set
             */
            function EndPortfolioResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new EndPortfolioResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {mts.portfolio.IEndPortfolioResponse=} [properties] Properties to set
             * @returns {mts.portfolio.EndPortfolioResponse} EndPortfolioResponse instance
             */
            EndPortfolioResponse.create = function create(properties) {
                return new EndPortfolioResponse(properties);
            };

            /**
             * Encodes the specified EndPortfolioResponse message. Does not implicitly {@link mts.portfolio.EndPortfolioResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {mts.portfolio.IEndPortfolioResponse} message EndPortfolioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EndPortfolioResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified EndPortfolioResponse message, length delimited. Does not implicitly {@link mts.portfolio.EndPortfolioResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {mts.portfolio.IEndPortfolioResponse} message EndPortfolioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EndPortfolioResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EndPortfolioResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.EndPortfolioResponse} EndPortfolioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EndPortfolioResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.EndPortfolioResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EndPortfolioResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.EndPortfolioResponse} EndPortfolioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EndPortfolioResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EndPortfolioResponse message.
             * @function verify
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EndPortfolioResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an EndPortfolioResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.EndPortfolioResponse} EndPortfolioResponse
             */
            EndPortfolioResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.EndPortfolioResponse)
                    return object;
                return new $root.mts.portfolio.EndPortfolioResponse();
            };

            /**
             * Creates a plain object from an EndPortfolioResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {mts.portfolio.EndPortfolioResponse} message EndPortfolioResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EndPortfolioResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this EndPortfolioResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.EndPortfolioResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EndPortfolioResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EndPortfolioResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.EndPortfolioResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EndPortfolioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.EndPortfolioResponse";
            };

            return EndPortfolioResponse;
        })();

        portfolio.BuildRequest = (function() {

            /**
             * Properties of a BuildRequest.
             * @memberof mts.portfolio
             * @interface IBuildRequest
             * @property {string|null} [portfolioId] BuildRequest portfolioId
             */

            /**
             * Constructs a new BuildRequest.
             * @memberof mts.portfolio
             * @classdesc Represents a BuildRequest.
             * @implements IBuildRequest
             * @constructor
             * @param {mts.portfolio.IBuildRequest=} [properties] Properties to set
             */
            function BuildRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuildRequest portfolioId.
             * @member {string} portfolioId
             * @memberof mts.portfolio.BuildRequest
             * @instance
             */
            BuildRequest.prototype.portfolioId = "";

            /**
             * Creates a new BuildRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {mts.portfolio.IBuildRequest=} [properties] Properties to set
             * @returns {mts.portfolio.BuildRequest} BuildRequest instance
             */
            BuildRequest.create = function create(properties) {
                return new BuildRequest(properties);
            };

            /**
             * Encodes the specified BuildRequest message. Does not implicitly {@link mts.portfolio.BuildRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {mts.portfolio.IBuildRequest} message BuildRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.portfolioId);
                return writer;
            };

            /**
             * Encodes the specified BuildRequest message, length delimited. Does not implicitly {@link mts.portfolio.BuildRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {mts.portfolio.IBuildRequest} message BuildRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuildRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.BuildRequest} BuildRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.BuildRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuildRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.BuildRequest} BuildRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuildRequest message.
             * @function verify
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuildRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                return null;
            };

            /**
             * Creates a BuildRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.BuildRequest} BuildRequest
             */
            BuildRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.BuildRequest)
                    return object;
                let message = new $root.mts.portfolio.BuildRequest();
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                return message;
            };

            /**
             * Creates a plain object from a BuildRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {mts.portfolio.BuildRequest} message BuildRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuildRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.portfolioId = "";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                return object;
            };

            /**
             * Converts this BuildRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.BuildRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuildRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuildRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.BuildRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuildRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.BuildRequest";
            };

            return BuildRequest;
        })();

        portfolio.BuildHistoricalPerformanceResponse = (function() {

            /**
             * Properties of a BuildHistoricalPerformanceResponse.
             * @memberof mts.portfolio
             * @interface IBuildHistoricalPerformanceResponse
             */

            /**
             * Constructs a new BuildHistoricalPerformanceResponse.
             * @memberof mts.portfolio
             * @classdesc Represents a BuildHistoricalPerformanceResponse.
             * @implements IBuildHistoricalPerformanceResponse
             * @constructor
             * @param {mts.portfolio.IBuildHistoricalPerformanceResponse=} [properties] Properties to set
             */
            function BuildHistoricalPerformanceResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BuildHistoricalPerformanceResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {mts.portfolio.IBuildHistoricalPerformanceResponse=} [properties] Properties to set
             * @returns {mts.portfolio.BuildHistoricalPerformanceResponse} BuildHistoricalPerformanceResponse instance
             */
            BuildHistoricalPerformanceResponse.create = function create(properties) {
                return new BuildHistoricalPerformanceResponse(properties);
            };

            /**
             * Encodes the specified BuildHistoricalPerformanceResponse message. Does not implicitly {@link mts.portfolio.BuildHistoricalPerformanceResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {mts.portfolio.IBuildHistoricalPerformanceResponse} message BuildHistoricalPerformanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildHistoricalPerformanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BuildHistoricalPerformanceResponse message, length delimited. Does not implicitly {@link mts.portfolio.BuildHistoricalPerformanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {mts.portfolio.IBuildHistoricalPerformanceResponse} message BuildHistoricalPerformanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildHistoricalPerformanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuildHistoricalPerformanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.BuildHistoricalPerformanceResponse} BuildHistoricalPerformanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildHistoricalPerformanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.BuildHistoricalPerformanceResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuildHistoricalPerformanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.BuildHistoricalPerformanceResponse} BuildHistoricalPerformanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildHistoricalPerformanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuildHistoricalPerformanceResponse message.
             * @function verify
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuildHistoricalPerformanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BuildHistoricalPerformanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.BuildHistoricalPerformanceResponse} BuildHistoricalPerformanceResponse
             */
            BuildHistoricalPerformanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.BuildHistoricalPerformanceResponse)
                    return object;
                return new $root.mts.portfolio.BuildHistoricalPerformanceResponse();
            };

            /**
             * Creates a plain object from a BuildHistoricalPerformanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {mts.portfolio.BuildHistoricalPerformanceResponse} message BuildHistoricalPerformanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuildHistoricalPerformanceResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BuildHistoricalPerformanceResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuildHistoricalPerformanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuildHistoricalPerformanceResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.BuildHistoricalPerformanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuildHistoricalPerformanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.BuildHistoricalPerformanceResponse";
            };

            return BuildHistoricalPerformanceResponse;
        })();

        portfolio.GetPositionsRequest = (function() {

            /**
             * Properties of a GetPositionsRequest.
             * @memberof mts.portfolio
             * @interface IGetPositionsRequest
             * @property {string|null} [portfolioId] GetPositionsRequest portfolioId
             */

            /**
             * Constructs a new GetPositionsRequest.
             * @memberof mts.portfolio
             * @classdesc Represents a GetPositionsRequest.
             * @implements IGetPositionsRequest
             * @constructor
             * @param {mts.portfolio.IGetPositionsRequest=} [properties] Properties to set
             */
            function GetPositionsRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetPositionsRequest portfolioId.
             * @member {string} portfolioId
             * @memberof mts.portfolio.GetPositionsRequest
             * @instance
             */
            GetPositionsRequest.prototype.portfolioId = "";

            /**
             * Creates a new GetPositionsRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {mts.portfolio.IGetPositionsRequest=} [properties] Properties to set
             * @returns {mts.portfolio.GetPositionsRequest} GetPositionsRequest instance
             */
            GetPositionsRequest.create = function create(properties) {
                return new GetPositionsRequest(properties);
            };

            /**
             * Encodes the specified GetPositionsRequest message. Does not implicitly {@link mts.portfolio.GetPositionsRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {mts.portfolio.IGetPositionsRequest} message GetPositionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPositionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.portfolioId);
                return writer;
            };

            /**
             * Encodes the specified GetPositionsRequest message, length delimited. Does not implicitly {@link mts.portfolio.GetPositionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {mts.portfolio.IGetPositionsRequest} message GetPositionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPositionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPositionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.GetPositionsRequest} GetPositionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPositionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.GetPositionsRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPositionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.GetPositionsRequest} GetPositionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPositionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPositionsRequest message.
             * @function verify
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPositionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                return null;
            };

            /**
             * Creates a GetPositionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.GetPositionsRequest} GetPositionsRequest
             */
            GetPositionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.GetPositionsRequest)
                    return object;
                let message = new $root.mts.portfolio.GetPositionsRequest();
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                return message;
            };

            /**
             * Creates a plain object from a GetPositionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {mts.portfolio.GetPositionsRequest} message GetPositionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPositionsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.portfolioId = "";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                return object;
            };

            /**
             * Converts this GetPositionsRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.GetPositionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPositionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPositionsRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.GetPositionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPositionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.GetPositionsRequest";
            };

            return GetPositionsRequest;
        })();

        portfolio.GetPositionsResponse = (function() {

            /**
             * Properties of a GetPositionsResponse.
             * @memberof mts.portfolio
             * @interface IGetPositionsResponse
             * @property {mts.portfolio.IPositions|null} [positions] GetPositionsResponse positions
             */

            /**
             * Constructs a new GetPositionsResponse.
             * @memberof mts.portfolio
             * @classdesc Represents a GetPositionsResponse.
             * @implements IGetPositionsResponse
             * @constructor
             * @param {mts.portfolio.IGetPositionsResponse=} [properties] Properties to set
             */
            function GetPositionsResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetPositionsResponse positions.
             * @member {mts.portfolio.IPositions|null|undefined} positions
             * @memberof mts.portfolio.GetPositionsResponse
             * @instance
             */
            GetPositionsResponse.prototype.positions = null;

            /**
             * Creates a new GetPositionsResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {mts.portfolio.IGetPositionsResponse=} [properties] Properties to set
             * @returns {mts.portfolio.GetPositionsResponse} GetPositionsResponse instance
             */
            GetPositionsResponse.create = function create(properties) {
                return new GetPositionsResponse(properties);
            };

            /**
             * Encodes the specified GetPositionsResponse message. Does not implicitly {@link mts.portfolio.GetPositionsResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {mts.portfolio.IGetPositionsResponse} message GetPositionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPositionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.positions != null && Object.hasOwnProperty.call(message, "positions"))
                    $root.mts.portfolio.Positions.encode(message.positions, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetPositionsResponse message, length delimited. Does not implicitly {@link mts.portfolio.GetPositionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {mts.portfolio.IGetPositionsResponse} message GetPositionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPositionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPositionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.GetPositionsResponse} GetPositionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPositionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.GetPositionsResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.positions = $root.mts.portfolio.Positions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPositionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.GetPositionsResponse} GetPositionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPositionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPositionsResponse message.
             * @function verify
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPositionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.positions != null && message.hasOwnProperty("positions")) {
                    let error = $root.mts.portfolio.Positions.verify(message.positions);
                    if (error)
                        return "positions." + error;
                }
                return null;
            };

            /**
             * Creates a GetPositionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.GetPositionsResponse} GetPositionsResponse
             */
            GetPositionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.GetPositionsResponse)
                    return object;
                let message = new $root.mts.portfolio.GetPositionsResponse();
                if (object.positions != null) {
                    if (typeof object.positions !== "object")
                        throw TypeError(".mts.portfolio.GetPositionsResponse.positions: object expected");
                    message.positions = $root.mts.portfolio.Positions.fromObject(object.positions);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetPositionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {mts.portfolio.GetPositionsResponse} message GetPositionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPositionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.positions = null;
                if (message.positions != null && message.hasOwnProperty("positions"))
                    object.positions = $root.mts.portfolio.Positions.toObject(message.positions, options);
                return object;
            };

            /**
             * Converts this GetPositionsResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.GetPositionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPositionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPositionsResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.GetPositionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPositionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.GetPositionsResponse";
            };

            return GetPositionsResponse;
        })();

        portfolio.GetTransactionsRequest = (function() {

            /**
             * Properties of a GetTransactionsRequest.
             * @memberof mts.portfolio
             * @interface IGetTransactionsRequest
             * @property {string|null} [portfolioId] GetTransactionsRequest portfolioId
             */

            /**
             * Constructs a new GetTransactionsRequest.
             * @memberof mts.portfolio
             * @classdesc Represents a GetTransactionsRequest.
             * @implements IGetTransactionsRequest
             * @constructor
             * @param {mts.portfolio.IGetTransactionsRequest=} [properties] Properties to set
             */
            function GetTransactionsRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTransactionsRequest portfolioId.
             * @member {string} portfolioId
             * @memberof mts.portfolio.GetTransactionsRequest
             * @instance
             */
            GetTransactionsRequest.prototype.portfolioId = "";

            /**
             * Creates a new GetTransactionsRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {mts.portfolio.IGetTransactionsRequest=} [properties] Properties to set
             * @returns {mts.portfolio.GetTransactionsRequest} GetTransactionsRequest instance
             */
            GetTransactionsRequest.create = function create(properties) {
                return new GetTransactionsRequest(properties);
            };

            /**
             * Encodes the specified GetTransactionsRequest message. Does not implicitly {@link mts.portfolio.GetTransactionsRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {mts.portfolio.IGetTransactionsRequest} message GetTransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTransactionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.portfolioId);
                return writer;
            };

            /**
             * Encodes the specified GetTransactionsRequest message, length delimited. Does not implicitly {@link mts.portfolio.GetTransactionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {mts.portfolio.IGetTransactionsRequest} message GetTransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTransactionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTransactionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.GetTransactionsRequest} GetTransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTransactionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.GetTransactionsRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTransactionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.GetTransactionsRequest} GetTransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTransactionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTransactionsRequest message.
             * @function verify
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTransactionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                return null;
            };

            /**
             * Creates a GetTransactionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.GetTransactionsRequest} GetTransactionsRequest
             */
            GetTransactionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.GetTransactionsRequest)
                    return object;
                let message = new $root.mts.portfolio.GetTransactionsRequest();
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                return message;
            };

            /**
             * Creates a plain object from a GetTransactionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {mts.portfolio.GetTransactionsRequest} message GetTransactionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTransactionsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.portfolioId = "";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                return object;
            };

            /**
             * Converts this GetTransactionsRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.GetTransactionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTransactionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTransactionsRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.GetTransactionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTransactionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.GetTransactionsRequest";
            };

            return GetTransactionsRequest;
        })();

        portfolio.GetTransactionsResponse = (function() {

            /**
             * Properties of a GetTransactionsResponse.
             * @memberof mts.portfolio
             * @interface IGetTransactionsResponse
             * @property {number|null} [txCount] GetTransactionsResponse txCount
             * @property {Array.<mts.portfolio.ITx>|null} [transactions] GetTransactionsResponse transactions
             */

            /**
             * Constructs a new GetTransactionsResponse.
             * @memberof mts.portfolio
             * @classdesc Represents a GetTransactionsResponse.
             * @implements IGetTransactionsResponse
             * @constructor
             * @param {mts.portfolio.IGetTransactionsResponse=} [properties] Properties to set
             */
            function GetTransactionsResponse(properties) {
                this.transactions = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetTransactionsResponse txCount.
             * @member {number} txCount
             * @memberof mts.portfolio.GetTransactionsResponse
             * @instance
             */
            GetTransactionsResponse.prototype.txCount = 0;

            /**
             * GetTransactionsResponse transactions.
             * @member {Array.<mts.portfolio.ITx>} transactions
             * @memberof mts.portfolio.GetTransactionsResponse
             * @instance
             */
            GetTransactionsResponse.prototype.transactions = $util.emptyArray;

            /**
             * Creates a new GetTransactionsResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {mts.portfolio.IGetTransactionsResponse=} [properties] Properties to set
             * @returns {mts.portfolio.GetTransactionsResponse} GetTransactionsResponse instance
             */
            GetTransactionsResponse.create = function create(properties) {
                return new GetTransactionsResponse(properties);
            };

            /**
             * Encodes the specified GetTransactionsResponse message. Does not implicitly {@link mts.portfolio.GetTransactionsResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {mts.portfolio.IGetTransactionsResponse} message GetTransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTransactionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.txCount != null && Object.hasOwnProperty.call(message, "txCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.txCount);
                if (message.transactions != null && message.transactions.length)
                    for (let i = 0; i < message.transactions.length; ++i)
                        $root.mts.portfolio.Tx.encode(message.transactions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetTransactionsResponse message, length delimited. Does not implicitly {@link mts.portfolio.GetTransactionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {mts.portfolio.IGetTransactionsResponse} message GetTransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTransactionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetTransactionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.GetTransactionsResponse} GetTransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTransactionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.GetTransactionsResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.txCount = reader.int32();
                            break;
                        }
                    case 2: {
                            if (!(message.transactions && message.transactions.length))
                                message.transactions = [];
                            message.transactions.push($root.mts.portfolio.Tx.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetTransactionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.GetTransactionsResponse} GetTransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTransactionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetTransactionsResponse message.
             * @function verify
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTransactionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.txCount != null && message.hasOwnProperty("txCount"))
                    if (!$util.isInteger(message.txCount))
                        return "txCount: integer expected";
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (let i = 0; i < message.transactions.length; ++i) {
                        let error = $root.mts.portfolio.Tx.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetTransactionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.GetTransactionsResponse} GetTransactionsResponse
             */
            GetTransactionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.GetTransactionsResponse)
                    return object;
                let message = new $root.mts.portfolio.GetTransactionsResponse();
                if (object.txCount != null)
                    message.txCount = object.txCount | 0;
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".mts.portfolio.GetTransactionsResponse.transactions: array expected");
                    message.transactions = [];
                    for (let i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".mts.portfolio.GetTransactionsResponse.transactions: object expected");
                        message.transactions[i] = $root.mts.portfolio.Tx.fromObject(object.transactions[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetTransactionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {mts.portfolio.GetTransactionsResponse} message GetTransactionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTransactionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.transactions = [];
                if (options.defaults)
                    object.txCount = 0;
                if (message.txCount != null && message.hasOwnProperty("txCount"))
                    object.txCount = message.txCount;
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (let j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.mts.portfolio.Tx.toObject(message.transactions[j], options);
                }
                return object;
            };

            /**
             * Converts this GetTransactionsResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.GetTransactionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTransactionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTransactionsResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.GetTransactionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTransactionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.GetTransactionsResponse";
            };

            return GetTransactionsResponse;
        })();

        portfolio.GetPortfolioRequest = (function() {

            /**
             * Properties of a GetPortfolioRequest.
             * @memberof mts.portfolio
             * @interface IGetPortfolioRequest
             * @property {string|null} [portfolioId] GetPortfolioRequest portfolioId
             */

            /**
             * Constructs a new GetPortfolioRequest.
             * @memberof mts.portfolio
             * @classdesc Represents a GetPortfolioRequest.
             * @implements IGetPortfolioRequest
             * @constructor
             * @param {mts.portfolio.IGetPortfolioRequest=} [properties] Properties to set
             */
            function GetPortfolioRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetPortfolioRequest portfolioId.
             * @member {string} portfolioId
             * @memberof mts.portfolio.GetPortfolioRequest
             * @instance
             */
            GetPortfolioRequest.prototype.portfolioId = "";

            /**
             * Creates a new GetPortfolioRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {mts.portfolio.IGetPortfolioRequest=} [properties] Properties to set
             * @returns {mts.portfolio.GetPortfolioRequest} GetPortfolioRequest instance
             */
            GetPortfolioRequest.create = function create(properties) {
                return new GetPortfolioRequest(properties);
            };

            /**
             * Encodes the specified GetPortfolioRequest message. Does not implicitly {@link mts.portfolio.GetPortfolioRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {mts.portfolio.IGetPortfolioRequest} message GetPortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPortfolioRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.portfolioId);
                return writer;
            };

            /**
             * Encodes the specified GetPortfolioRequest message, length delimited. Does not implicitly {@link mts.portfolio.GetPortfolioRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {mts.portfolio.IGetPortfolioRequest} message GetPortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPortfolioRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPortfolioRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.GetPortfolioRequest} GetPortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPortfolioRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.GetPortfolioRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolioId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPortfolioRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.GetPortfolioRequest} GetPortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPortfolioRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPortfolioRequest message.
             * @function verify
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPortfolioRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isString(message.portfolioId))
                        return "portfolioId: string expected";
                return null;
            };

            /**
             * Creates a GetPortfolioRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.GetPortfolioRequest} GetPortfolioRequest
             */
            GetPortfolioRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.GetPortfolioRequest)
                    return object;
                let message = new $root.mts.portfolio.GetPortfolioRequest();
                if (object.portfolioId != null)
                    message.portfolioId = String(object.portfolioId);
                return message;
            };

            /**
             * Creates a plain object from a GetPortfolioRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {mts.portfolio.GetPortfolioRequest} message GetPortfolioRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPortfolioRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.portfolioId = "";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                return object;
            };

            /**
             * Converts this GetPortfolioRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.GetPortfolioRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPortfolioRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPortfolioRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.GetPortfolioRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPortfolioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.GetPortfolioRequest";
            };

            return GetPortfolioRequest;
        })();

        portfolio.GetPortfolioResponse = (function() {

            /**
             * Properties of a GetPortfolioResponse.
             * @memberof mts.portfolio
             * @interface IGetPortfolioResponse
             * @property {mts.portfolio.IPortfolio|null} [portfolio] GetPortfolioResponse portfolio
             * @property {mts.portfolio.IPositions|null} [positions] GetPortfolioResponse positions
             */

            /**
             * Constructs a new GetPortfolioResponse.
             * @memberof mts.portfolio
             * @classdesc Represents a GetPortfolioResponse.
             * @implements IGetPortfolioResponse
             * @constructor
             * @param {mts.portfolio.IGetPortfolioResponse=} [properties] Properties to set
             */
            function GetPortfolioResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetPortfolioResponse portfolio.
             * @member {mts.portfolio.IPortfolio|null|undefined} portfolio
             * @memberof mts.portfolio.GetPortfolioResponse
             * @instance
             */
            GetPortfolioResponse.prototype.portfolio = null;

            /**
             * GetPortfolioResponse positions.
             * @member {mts.portfolio.IPositions|null|undefined} positions
             * @memberof mts.portfolio.GetPortfolioResponse
             * @instance
             */
            GetPortfolioResponse.prototype.positions = null;

            /**
             * Creates a new GetPortfolioResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {mts.portfolio.IGetPortfolioResponse=} [properties] Properties to set
             * @returns {mts.portfolio.GetPortfolioResponse} GetPortfolioResponse instance
             */
            GetPortfolioResponse.create = function create(properties) {
                return new GetPortfolioResponse(properties);
            };

            /**
             * Encodes the specified GetPortfolioResponse message. Does not implicitly {@link mts.portfolio.GetPortfolioResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {mts.portfolio.IGetPortfolioResponse} message GetPortfolioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPortfolioResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolio != null && Object.hasOwnProperty.call(message, "portfolio"))
                    $root.mts.portfolio.Portfolio.encode(message.portfolio, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.positions != null && Object.hasOwnProperty.call(message, "positions"))
                    $root.mts.portfolio.Positions.encode(message.positions, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetPortfolioResponse message, length delimited. Does not implicitly {@link mts.portfolio.GetPortfolioResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {mts.portfolio.IGetPortfolioResponse} message GetPortfolioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPortfolioResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPortfolioResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.GetPortfolioResponse} GetPortfolioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPortfolioResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.GetPortfolioResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolio = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.positions = $root.mts.portfolio.Positions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPortfolioResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.GetPortfolioResponse} GetPortfolioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPortfolioResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPortfolioResponse message.
             * @function verify
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPortfolioResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolio != null && message.hasOwnProperty("portfolio")) {
                    let error = $root.mts.portfolio.Portfolio.verify(message.portfolio);
                    if (error)
                        return "portfolio." + error;
                }
                if (message.positions != null && message.hasOwnProperty("positions")) {
                    let error = $root.mts.portfolio.Positions.verify(message.positions);
                    if (error)
                        return "positions." + error;
                }
                return null;
            };

            /**
             * Creates a GetPortfolioResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.GetPortfolioResponse} GetPortfolioResponse
             */
            GetPortfolioResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.GetPortfolioResponse)
                    return object;
                let message = new $root.mts.portfolio.GetPortfolioResponse();
                if (object.portfolio != null) {
                    if (typeof object.portfolio !== "object")
                        throw TypeError(".mts.portfolio.GetPortfolioResponse.portfolio: object expected");
                    message.portfolio = $root.mts.portfolio.Portfolio.fromObject(object.portfolio);
                }
                if (object.positions != null) {
                    if (typeof object.positions !== "object")
                        throw TypeError(".mts.portfolio.GetPortfolioResponse.positions: object expected");
                    message.positions = $root.mts.portfolio.Positions.fromObject(object.positions);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetPortfolioResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {mts.portfolio.GetPortfolioResponse} message GetPortfolioResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPortfolioResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.portfolio = null;
                    object.positions = null;
                }
                if (message.portfolio != null && message.hasOwnProperty("portfolio"))
                    object.portfolio = $root.mts.portfolio.Portfolio.toObject(message.portfolio, options);
                if (message.positions != null && message.hasOwnProperty("positions"))
                    object.positions = $root.mts.portfolio.Positions.toObject(message.positions, options);
                return object;
            };

            /**
             * Converts this GetPortfolioResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.GetPortfolioResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPortfolioResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPortfolioResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.GetPortfolioResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPortfolioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.GetPortfolioResponse";
            };

            return GetPortfolioResponse;
        })();

        portfolio.AddPortfolioRequest = (function() {

            /**
             * Properties of an AddPortfolioRequest.
             * @memberof mts.portfolio
             * @interface IAddPortfolioRequest
             * @property {string|null} [name] AddPortfolioRequest name
             * @property {string|null} [userId] AddPortfolioRequest userId
             * @property {string|null} [commissionSchedule] AddPortfolioRequest commissionSchedule
             * @property {number|null} [cash] AddPortfolioRequest cash
             * @property {boolean|null} [resetIfExists] AddPortfolioRequest resetIfExists
             * @property {string|null} [description] AddPortfolioRequest description
             */

            /**
             * Constructs a new AddPortfolioRequest.
             * @memberof mts.portfolio
             * @classdesc Represents an AddPortfolioRequest.
             * @implements IAddPortfolioRequest
             * @constructor
             * @param {mts.portfolio.IAddPortfolioRequest=} [properties] Properties to set
             */
            function AddPortfolioRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddPortfolioRequest name.
             * @member {string} name
             * @memberof mts.portfolio.AddPortfolioRequest
             * @instance
             */
            AddPortfolioRequest.prototype.name = "";

            /**
             * AddPortfolioRequest userId.
             * @member {string} userId
             * @memberof mts.portfolio.AddPortfolioRequest
             * @instance
             */
            AddPortfolioRequest.prototype.userId = "";

            /**
             * AddPortfolioRequest commissionSchedule.
             * @member {string} commissionSchedule
             * @memberof mts.portfolio.AddPortfolioRequest
             * @instance
             */
            AddPortfolioRequest.prototype.commissionSchedule = "";

            /**
             * AddPortfolioRequest cash.
             * @member {number} cash
             * @memberof mts.portfolio.AddPortfolioRequest
             * @instance
             */
            AddPortfolioRequest.prototype.cash = 0;

            /**
             * AddPortfolioRequest resetIfExists.
             * @member {boolean} resetIfExists
             * @memberof mts.portfolio.AddPortfolioRequest
             * @instance
             */
            AddPortfolioRequest.prototype.resetIfExists = false;

            /**
             * AddPortfolioRequest description.
             * @member {string} description
             * @memberof mts.portfolio.AddPortfolioRequest
             * @instance
             */
            AddPortfolioRequest.prototype.description = "";

            /**
             * Creates a new AddPortfolioRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {mts.portfolio.IAddPortfolioRequest=} [properties] Properties to set
             * @returns {mts.portfolio.AddPortfolioRequest} AddPortfolioRequest instance
             */
            AddPortfolioRequest.create = function create(properties) {
                return new AddPortfolioRequest(properties);
            };

            /**
             * Encodes the specified AddPortfolioRequest message. Does not implicitly {@link mts.portfolio.AddPortfolioRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {mts.portfolio.IAddPortfolioRequest} message AddPortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddPortfolioRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.commissionSchedule != null && Object.hasOwnProperty.call(message, "commissionSchedule"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.commissionSchedule);
                if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.cash);
                if (message.resetIfExists != null && Object.hasOwnProperty.call(message, "resetIfExists"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.resetIfExists);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.userId);
                return writer;
            };

            /**
             * Encodes the specified AddPortfolioRequest message, length delimited. Does not implicitly {@link mts.portfolio.AddPortfolioRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {mts.portfolio.IAddPortfolioRequest} message AddPortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddPortfolioRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddPortfolioRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.AddPortfolioRequest} AddPortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddPortfolioRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.AddPortfolioRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 6: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            message.commissionSchedule = reader.string();
                            break;
                        }
                    case 3: {
                            message.cash = reader.double();
                            break;
                        }
                    case 4: {
                            message.resetIfExists = reader.bool();
                            break;
                        }
                    case 5: {
                            message.description = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddPortfolioRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.AddPortfolioRequest} AddPortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddPortfolioRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddPortfolioRequest message.
             * @function verify
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddPortfolioRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.commissionSchedule != null && message.hasOwnProperty("commissionSchedule"))
                    if (!$util.isString(message.commissionSchedule))
                        return "commissionSchedule: string expected";
                if (message.cash != null && message.hasOwnProperty("cash"))
                    if (typeof message.cash !== "number")
                        return "cash: number expected";
                if (message.resetIfExists != null && message.hasOwnProperty("resetIfExists"))
                    if (typeof message.resetIfExists !== "boolean")
                        return "resetIfExists: boolean expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates an AddPortfolioRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.AddPortfolioRequest} AddPortfolioRequest
             */
            AddPortfolioRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.AddPortfolioRequest)
                    return object;
                let message = new $root.mts.portfolio.AddPortfolioRequest();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.commissionSchedule != null)
                    message.commissionSchedule = String(object.commissionSchedule);
                if (object.cash != null)
                    message.cash = Number(object.cash);
                if (object.resetIfExists != null)
                    message.resetIfExists = Boolean(object.resetIfExists);
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from an AddPortfolioRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {mts.portfolio.AddPortfolioRequest} message AddPortfolioRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddPortfolioRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.commissionSchedule = "";
                    object.cash = 0;
                    object.resetIfExists = false;
                    object.description = "";
                    object.userId = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.commissionSchedule != null && message.hasOwnProperty("commissionSchedule"))
                    object.commissionSchedule = message.commissionSchedule;
                if (message.cash != null && message.hasOwnProperty("cash"))
                    object.cash = options.json && !isFinite(message.cash) ? String(message.cash) : message.cash;
                if (message.resetIfExists != null && message.hasOwnProperty("resetIfExists"))
                    object.resetIfExists = message.resetIfExists;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                return object;
            };

            /**
             * Converts this AddPortfolioRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.AddPortfolioRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddPortfolioRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddPortfolioRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.AddPortfolioRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddPortfolioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.AddPortfolioRequest";
            };

            return AddPortfolioRequest;
        })();

        portfolio.AddPortfolioResponse = (function() {

            /**
             * Properties of an AddPortfolioResponse.
             * @memberof mts.portfolio
             * @interface IAddPortfolioResponse
             * @property {mts.portfolio.IPortfolio|null} [portfolio] AddPortfolioResponse portfolio
             */

            /**
             * Constructs a new AddPortfolioResponse.
             * @memberof mts.portfolio
             * @classdesc Represents an AddPortfolioResponse.
             * @implements IAddPortfolioResponse
             * @constructor
             * @param {mts.portfolio.IAddPortfolioResponse=} [properties] Properties to set
             */
            function AddPortfolioResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddPortfolioResponse portfolio.
             * @member {mts.portfolio.IPortfolio|null|undefined} portfolio
             * @memberof mts.portfolio.AddPortfolioResponse
             * @instance
             */
            AddPortfolioResponse.prototype.portfolio = null;

            /**
             * Creates a new AddPortfolioResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {mts.portfolio.IAddPortfolioResponse=} [properties] Properties to set
             * @returns {mts.portfolio.AddPortfolioResponse} AddPortfolioResponse instance
             */
            AddPortfolioResponse.create = function create(properties) {
                return new AddPortfolioResponse(properties);
            };

            /**
             * Encodes the specified AddPortfolioResponse message. Does not implicitly {@link mts.portfolio.AddPortfolioResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {mts.portfolio.IAddPortfolioResponse} message AddPortfolioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddPortfolioResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolio != null && Object.hasOwnProperty.call(message, "portfolio"))
                    $root.mts.portfolio.Portfolio.encode(message.portfolio, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddPortfolioResponse message, length delimited. Does not implicitly {@link mts.portfolio.AddPortfolioResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {mts.portfolio.IAddPortfolioResponse} message AddPortfolioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddPortfolioResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddPortfolioResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.AddPortfolioResponse} AddPortfolioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddPortfolioResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.AddPortfolioResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolio = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddPortfolioResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.AddPortfolioResponse} AddPortfolioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddPortfolioResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddPortfolioResponse message.
             * @function verify
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddPortfolioResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolio != null && message.hasOwnProperty("portfolio")) {
                    let error = $root.mts.portfolio.Portfolio.verify(message.portfolio);
                    if (error)
                        return "portfolio." + error;
                }
                return null;
            };

            /**
             * Creates an AddPortfolioResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.AddPortfolioResponse} AddPortfolioResponse
             */
            AddPortfolioResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.AddPortfolioResponse)
                    return object;
                let message = new $root.mts.portfolio.AddPortfolioResponse();
                if (object.portfolio != null) {
                    if (typeof object.portfolio !== "object")
                        throw TypeError(".mts.portfolio.AddPortfolioResponse.portfolio: object expected");
                    message.portfolio = $root.mts.portfolio.Portfolio.fromObject(object.portfolio);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddPortfolioResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {mts.portfolio.AddPortfolioResponse} message AddPortfolioResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddPortfolioResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.portfolio = null;
                if (message.portfolio != null && message.hasOwnProperty("portfolio"))
                    object.portfolio = $root.mts.portfolio.Portfolio.toObject(message.portfolio, options);
                return object;
            };

            /**
             * Converts this AddPortfolioResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.AddPortfolioResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddPortfolioResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddPortfolioResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.AddPortfolioResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddPortfolioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.AddPortfolioResponse";
            };

            return AddPortfolioResponse;
        })();

        portfolio.RenamePortfolioRequest = (function() {

            /**
             * Properties of a RenamePortfolioRequest.
             * @memberof mts.portfolio
             * @interface IRenamePortfolioRequest
             * @property {string|null} [name] RenamePortfolioRequest name
             */

            /**
             * Constructs a new RenamePortfolioRequest.
             * @memberof mts.portfolio
             * @classdesc Represents a RenamePortfolioRequest.
             * @implements IRenamePortfolioRequest
             * @constructor
             * @param {mts.portfolio.IRenamePortfolioRequest=} [properties] Properties to set
             */
            function RenamePortfolioRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RenamePortfolioRequest name.
             * @member {string} name
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @instance
             */
            RenamePortfolioRequest.prototype.name = "";

            /**
             * Creates a new RenamePortfolioRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {mts.portfolio.IRenamePortfolioRequest=} [properties] Properties to set
             * @returns {mts.portfolio.RenamePortfolioRequest} RenamePortfolioRequest instance
             */
            RenamePortfolioRequest.create = function create(properties) {
                return new RenamePortfolioRequest(properties);
            };

            /**
             * Encodes the specified RenamePortfolioRequest message. Does not implicitly {@link mts.portfolio.RenamePortfolioRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {mts.portfolio.IRenamePortfolioRequest} message RenamePortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RenamePortfolioRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified RenamePortfolioRequest message, length delimited. Does not implicitly {@link mts.portfolio.RenamePortfolioRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {mts.portfolio.IRenamePortfolioRequest} message RenamePortfolioRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RenamePortfolioRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RenamePortfolioRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.RenamePortfolioRequest} RenamePortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RenamePortfolioRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.RenamePortfolioRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RenamePortfolioRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.RenamePortfolioRequest} RenamePortfolioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RenamePortfolioRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RenamePortfolioRequest message.
             * @function verify
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RenamePortfolioRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a RenamePortfolioRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.RenamePortfolioRequest} RenamePortfolioRequest
             */
            RenamePortfolioRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.RenamePortfolioRequest)
                    return object;
                let message = new $root.mts.portfolio.RenamePortfolioRequest();
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a RenamePortfolioRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {mts.portfolio.RenamePortfolioRequest} message RenamePortfolioRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RenamePortfolioRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.name = "";
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this RenamePortfolioRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RenamePortfolioRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RenamePortfolioRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.RenamePortfolioRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RenamePortfolioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.RenamePortfolioRequest";
            };

            return RenamePortfolioRequest;
        })();

        portfolio.GetPortfoliosResponse = (function() {

            /**
             * Properties of a GetPortfoliosResponse.
             * @memberof mts.portfolio
             * @interface IGetPortfoliosResponse
             * @property {Array.<mts.portfolio.IPortfolio>|null} [portfolios] GetPortfoliosResponse portfolios
             */

            /**
             * Constructs a new GetPortfoliosResponse.
             * @memberof mts.portfolio
             * @classdesc Represents a GetPortfoliosResponse.
             * @implements IGetPortfoliosResponse
             * @constructor
             * @param {mts.portfolio.IGetPortfoliosResponse=} [properties] Properties to set
             */
            function GetPortfoliosResponse(properties) {
                this.portfolios = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetPortfoliosResponse portfolios.
             * @member {Array.<mts.portfolio.IPortfolio>} portfolios
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @instance
             */
            GetPortfoliosResponse.prototype.portfolios = $util.emptyArray;

            /**
             * Creates a new GetPortfoliosResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {mts.portfolio.IGetPortfoliosResponse=} [properties] Properties to set
             * @returns {mts.portfolio.GetPortfoliosResponse} GetPortfoliosResponse instance
             */
            GetPortfoliosResponse.create = function create(properties) {
                return new GetPortfoliosResponse(properties);
            };

            /**
             * Encodes the specified GetPortfoliosResponse message. Does not implicitly {@link mts.portfolio.GetPortfoliosResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {mts.portfolio.IGetPortfoliosResponse} message GetPortfoliosResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPortfoliosResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolios != null && message.portfolios.length)
                    for (let i = 0; i < message.portfolios.length; ++i)
                        $root.mts.portfolio.Portfolio.encode(message.portfolios[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetPortfoliosResponse message, length delimited. Does not implicitly {@link mts.portfolio.GetPortfoliosResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {mts.portfolio.IGetPortfoliosResponse} message GetPortfoliosResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPortfoliosResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPortfoliosResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.GetPortfoliosResponse} GetPortfoliosResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPortfoliosResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.GetPortfoliosResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.portfolios && message.portfolios.length))
                                message.portfolios = [];
                            message.portfolios.push($root.mts.portfolio.Portfolio.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPortfoliosResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.GetPortfoliosResponse} GetPortfoliosResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPortfoliosResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPortfoliosResponse message.
             * @function verify
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPortfoliosResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolios != null && message.hasOwnProperty("portfolios")) {
                    if (!Array.isArray(message.portfolios))
                        return "portfolios: array expected";
                    for (let i = 0; i < message.portfolios.length; ++i) {
                        let error = $root.mts.portfolio.Portfolio.verify(message.portfolios[i]);
                        if (error)
                            return "portfolios." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetPortfoliosResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.GetPortfoliosResponse} GetPortfoliosResponse
             */
            GetPortfoliosResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.GetPortfoliosResponse)
                    return object;
                let message = new $root.mts.portfolio.GetPortfoliosResponse();
                if (object.portfolios) {
                    if (!Array.isArray(object.portfolios))
                        throw TypeError(".mts.portfolio.GetPortfoliosResponse.portfolios: array expected");
                    message.portfolios = [];
                    for (let i = 0; i < object.portfolios.length; ++i) {
                        if (typeof object.portfolios[i] !== "object")
                            throw TypeError(".mts.portfolio.GetPortfoliosResponse.portfolios: object expected");
                        message.portfolios[i] = $root.mts.portfolio.Portfolio.fromObject(object.portfolios[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetPortfoliosResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {mts.portfolio.GetPortfoliosResponse} message GetPortfoliosResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPortfoliosResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.portfolios = [];
                if (message.portfolios && message.portfolios.length) {
                    object.portfolios = [];
                    for (let j = 0; j < message.portfolios.length; ++j)
                        object.portfolios[j] = $root.mts.portfolio.Portfolio.toObject(message.portfolios[j], options);
                }
                return object;
            };

            /**
             * Converts this GetPortfoliosResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPortfoliosResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPortfoliosResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.GetPortfoliosResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPortfoliosResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.GetPortfoliosResponse";
            };

            return GetPortfoliosResponse;
        })();

        portfolio.AddTransactionRequest = (function() {

            /**
             * Properties of an AddTransactionRequest.
             * @memberof mts.portfolio
             * @interface IAddTransactionRequest
             * @property {number|null} [portfolioId] AddTransactionRequest portfolioId
             * @property {string|null} [commentary] AddTransactionRequest commentary
             * @property {string|null} [description] AddTransactionRequest description
             * @property {number|null} [price] AddTransactionRequest price
             * @property {number|Long|null} [quantity] AddTransactionRequest quantity
             * @property {string|null} [symbol] AddTransactionRequest symbol
             * @property {string|null} [date] AddTransactionRequest date
             * @property {mts.portfolio.TxType|null} [type] AddTransactionRequest type
             * @property {number|null} [amount] AddTransactionRequest amount
             * @property {number|null} [commission] AddTransactionRequest commission
             * @property {Object.<string,string>|null} [brokerFields] AddTransactionRequest brokerFields
             * @property {string|null} [brokerExecutionId] AddTransactionRequest brokerExecutionId
             * @property {string|null} [tradeId] AddTransactionRequest tradeId
             * @property {Object.<string,string>|null} [customData] AddTransactionRequest customData
             */

            /**
             * Constructs a new AddTransactionRequest.
             * @memberof mts.portfolio
             * @classdesc Represents an AddTransactionRequest.
             * @implements IAddTransactionRequest
             * @constructor
             * @param {mts.portfolio.IAddTransactionRequest=} [properties] Properties to set
             */
            function AddTransactionRequest(properties) {
                this.brokerFields = {};
                this.customData = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddTransactionRequest portfolioId.
             * @member {number} portfolioId
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.portfolioId = 0;

            /**
             * AddTransactionRequest commentary.
             * @member {string} commentary
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.commentary = "";

            /**
             * AddTransactionRequest description.
             * @member {string} description
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.description = "";

            /**
             * AddTransactionRequest price.
             * @member {number} price
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.price = 0;

            /**
             * AddTransactionRequest quantity.
             * @member {number|Long} quantity
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * AddTransactionRequest symbol.
             * @member {string} symbol
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.symbol = "";

            /**
             * AddTransactionRequest date.
             * @member {string} date
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.date = "";

            /**
             * AddTransactionRequest type.
             * @member {mts.portfolio.TxType} type
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.type = 0;

            /**
             * AddTransactionRequest amount.
             * @member {number} amount
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.amount = 0;

            /**
             * AddTransactionRequest commission.
             * @member {number} commission
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.commission = 0;

            /**
             * AddTransactionRequest brokerFields.
             * @member {Object.<string,string>} brokerFields
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.brokerFields = $util.emptyObject;

            /**
             * AddTransactionRequest brokerExecutionId.
             * @member {string} brokerExecutionId
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.brokerExecutionId = "";

            /**
             * AddTransactionRequest tradeId.
             * @member {string} tradeId
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.tradeId = "";

            /**
             * AddTransactionRequest customData.
             * @member {Object.<string,string>} customData
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             */
            AddTransactionRequest.prototype.customData = $util.emptyObject;

            /**
             * Creates a new AddTransactionRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {mts.portfolio.IAddTransactionRequest=} [properties] Properties to set
             * @returns {mts.portfolio.AddTransactionRequest} AddTransactionRequest instance
             */
            AddTransactionRequest.create = function create(properties) {
                return new AddTransactionRequest(properties);
            };

            /**
             * Encodes the specified AddTransactionRequest message. Does not implicitly {@link mts.portfolio.AddTransactionRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {mts.portfolio.IAddTransactionRequest} message AddTransactionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTransactionRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.commentary != null && Object.hasOwnProperty.call(message, "commentary"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.commentary);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.price);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.quantity);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.symbol);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.date);
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int32(message.portfolioId);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 30, wireType 0 =*/240).int32(message.type);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 31, wireType 1 =*/249).double(message.amount);
                if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
                    writer.uint32(/* id 32, wireType 1 =*/257).double(message.commission);
                if (message.brokerFields != null && Object.hasOwnProperty.call(message, "brokerFields"))
                    for (let keys = Object.keys(message.brokerFields), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 33, wireType 2 =*/266).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.brokerFields[keys[i]]).ldelim();
                if (message.brokerExecutionId != null && Object.hasOwnProperty.call(message, "brokerExecutionId"))
                    writer.uint32(/* id 34, wireType 2 =*/274).string(message.brokerExecutionId);
                if (message.tradeId != null && Object.hasOwnProperty.call(message, "tradeId"))
                    writer.uint32(/* id 35, wireType 2 =*/282).string(message.tradeId);
                if (message.customData != null && Object.hasOwnProperty.call(message, "customData"))
                    for (let keys = Object.keys(message.customData), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 36, wireType 2 =*/290).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.customData[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddTransactionRequest message, length delimited. Does not implicitly {@link mts.portfolio.AddTransactionRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {mts.portfolio.IAddTransactionRequest} message AddTransactionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTransactionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddTransactionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.AddTransactionRequest} AddTransactionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTransactionRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.AddTransactionRequest(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 20: {
                            message.portfolioId = reader.int32();
                            break;
                        }
                    case 1: {
                            message.commentary = reader.string();
                            break;
                        }
                    case 2: {
                            message.description = reader.string();
                            break;
                        }
                    case 4: {
                            message.price = reader.double();
                            break;
                        }
                    case 6: {
                            message.quantity = reader.int64();
                            break;
                        }
                    case 7: {
                            message.symbol = reader.string();
                            break;
                        }
                    case 9: {
                            message.date = reader.string();
                            break;
                        }
                    case 30: {
                            message.type = reader.int32();
                            break;
                        }
                    case 31: {
                            message.amount = reader.double();
                            break;
                        }
                    case 32: {
                            message.commission = reader.double();
                            break;
                        }
                    case 33: {
                            if (message.brokerFields === $util.emptyObject)
                                message.brokerFields = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.brokerFields[key] = value;
                            break;
                        }
                    case 34: {
                            message.brokerExecutionId = reader.string();
                            break;
                        }
                    case 35: {
                            message.tradeId = reader.string();
                            break;
                        }
                    case 36: {
                            if (message.customData === $util.emptyObject)
                                message.customData = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.customData[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddTransactionRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.AddTransactionRequest} AddTransactionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTransactionRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddTransactionRequest message.
             * @function verify
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddTransactionRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isInteger(message.portfolioId))
                        return "portfolioId: integer expected";
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    if (!$util.isString(message.commentary))
                        return "commentary: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price !== "number")
                        return "price: number expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                        return "quantity: integer|Long expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.date != null && message.hasOwnProperty("date"))
                    if (!$util.isString(message.date))
                        return "date: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount !== "number")
                        return "amount: number expected";
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (typeof message.commission !== "number")
                        return "commission: number expected";
                if (message.brokerFields != null && message.hasOwnProperty("brokerFields")) {
                    if (!$util.isObject(message.brokerFields))
                        return "brokerFields: object expected";
                    let key = Object.keys(message.brokerFields);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.brokerFields[key[i]]))
                            return "brokerFields: string{k:string} expected";
                }
                if (message.brokerExecutionId != null && message.hasOwnProperty("brokerExecutionId"))
                    if (!$util.isString(message.brokerExecutionId))
                        return "brokerExecutionId: string expected";
                if (message.tradeId != null && message.hasOwnProperty("tradeId"))
                    if (!$util.isString(message.tradeId))
                        return "tradeId: string expected";
                if (message.customData != null && message.hasOwnProperty("customData")) {
                    if (!$util.isObject(message.customData))
                        return "customData: object expected";
                    let key = Object.keys(message.customData);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.customData[key[i]]))
                            return "customData: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates an AddTransactionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.AddTransactionRequest} AddTransactionRequest
             */
            AddTransactionRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.AddTransactionRequest)
                    return object;
                let message = new $root.mts.portfolio.AddTransactionRequest();
                if (object.portfolioId != null)
                    message.portfolioId = object.portfolioId | 0;
                if (object.commentary != null)
                    message.commentary = String(object.commentary);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.price != null)
                    message.price = Number(object.price);
                if (object.quantity != null)
                    if ($util.Long)
                        (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                    else if (typeof object.quantity === "string")
                        message.quantity = parseInt(object.quantity, 10);
                    else if (typeof object.quantity === "number")
                        message.quantity = object.quantity;
                    else if (typeof object.quantity === "object")
                        message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.date != null)
                    message.date = String(object.date);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UNKNOWN_TX_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "DEPOSIT":
                case 1:
                    message.type = 1;
                    break;
                case "WITHDRAWL":
                case 2:
                    message.type = 2;
                    break;
                case "SALE":
                case 3:
                    message.type = 3;
                    break;
                case "DIVIDEND":
                case 4:
                    message.type = 4;
                    break;
                }
                if (object.amount != null)
                    message.amount = Number(object.amount);
                if (object.commission != null)
                    message.commission = Number(object.commission);
                if (object.brokerFields) {
                    if (typeof object.brokerFields !== "object")
                        throw TypeError(".mts.portfolio.AddTransactionRequest.brokerFields: object expected");
                    message.brokerFields = {};
                    for (let keys = Object.keys(object.brokerFields), i = 0; i < keys.length; ++i)
                        message.brokerFields[keys[i]] = String(object.brokerFields[keys[i]]);
                }
                if (object.brokerExecutionId != null)
                    message.brokerExecutionId = String(object.brokerExecutionId);
                if (object.tradeId != null)
                    message.tradeId = String(object.tradeId);
                if (object.customData) {
                    if (typeof object.customData !== "object")
                        throw TypeError(".mts.portfolio.AddTransactionRequest.customData: object expected");
                    message.customData = {};
                    for (let keys = Object.keys(object.customData), i = 0; i < keys.length; ++i)
                        message.customData[keys[i]] = String(object.customData[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddTransactionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {mts.portfolio.AddTransactionRequest} message AddTransactionRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddTransactionRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults) {
                    object.brokerFields = {};
                    object.customData = {};
                }
                if (options.defaults) {
                    object.commentary = "";
                    object.description = "";
                    object.price = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.quantity = options.longs === String ? "0" : 0;
                    object.symbol = "";
                    object.date = "";
                    object.portfolioId = 0;
                    object.type = options.enums === String ? "UNKNOWN_TX_TYPE" : 0;
                    object.amount = 0;
                    object.commission = 0;
                    object.brokerExecutionId = "";
                    object.tradeId = "";
                }
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    object.commentary = message.commentary;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (typeof message.quantity === "number")
                        object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                    else
                        object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = message.date;
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.portfolio.TxType[message.type] === undefined ? message.type : $root.mts.portfolio.TxType[message.type] : message.type;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                if (message.commission != null && message.hasOwnProperty("commission"))
                    object.commission = options.json && !isFinite(message.commission) ? String(message.commission) : message.commission;
                let keys2;
                if (message.brokerFields && (keys2 = Object.keys(message.brokerFields)).length) {
                    object.brokerFields = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.brokerFields[keys2[j]] = message.brokerFields[keys2[j]];
                }
                if (message.brokerExecutionId != null && message.hasOwnProperty("brokerExecutionId"))
                    object.brokerExecutionId = message.brokerExecutionId;
                if (message.tradeId != null && message.hasOwnProperty("tradeId"))
                    object.tradeId = message.tradeId;
                if (message.customData && (keys2 = Object.keys(message.customData)).length) {
                    object.customData = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.customData[keys2[j]] = message.customData[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this AddTransactionRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.AddTransactionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddTransactionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddTransactionRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.AddTransactionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddTransactionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.AddTransactionRequest";
            };

            return AddTransactionRequest;
        })();

        portfolio.AddTransactionResponse = (function() {

            /**
             * Properties of an AddTransactionResponse.
             * @memberof mts.portfolio
             * @interface IAddTransactionResponse
             * @property {mts.portfolio.ITx|null} [tx] AddTransactionResponse tx
             */

            /**
             * Constructs a new AddTransactionResponse.
             * @memberof mts.portfolio
             * @classdesc Represents an AddTransactionResponse.
             * @implements IAddTransactionResponse
             * @constructor
             * @param {mts.portfolio.IAddTransactionResponse=} [properties] Properties to set
             */
            function AddTransactionResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddTransactionResponse tx.
             * @member {mts.portfolio.ITx|null|undefined} tx
             * @memberof mts.portfolio.AddTransactionResponse
             * @instance
             */
            AddTransactionResponse.prototype.tx = null;

            /**
             * Creates a new AddTransactionResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {mts.portfolio.IAddTransactionResponse=} [properties] Properties to set
             * @returns {mts.portfolio.AddTransactionResponse} AddTransactionResponse instance
             */
            AddTransactionResponse.create = function create(properties) {
                return new AddTransactionResponse(properties);
            };

            /**
             * Encodes the specified AddTransactionResponse message. Does not implicitly {@link mts.portfolio.AddTransactionResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {mts.portfolio.IAddTransactionResponse} message AddTransactionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTransactionResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tx != null && Object.hasOwnProperty.call(message, "tx"))
                    $root.mts.portfolio.Tx.encode(message.tx, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddTransactionResponse message, length delimited. Does not implicitly {@link mts.portfolio.AddTransactionResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {mts.portfolio.IAddTransactionResponse} message AddTransactionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddTransactionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddTransactionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.AddTransactionResponse} AddTransactionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTransactionResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.AddTransactionResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tx = $root.mts.portfolio.Tx.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddTransactionResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.AddTransactionResponse} AddTransactionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddTransactionResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddTransactionResponse message.
             * @function verify
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddTransactionResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tx != null && message.hasOwnProperty("tx")) {
                    let error = $root.mts.portfolio.Tx.verify(message.tx);
                    if (error)
                        return "tx." + error;
                }
                return null;
            };

            /**
             * Creates an AddTransactionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.AddTransactionResponse} AddTransactionResponse
             */
            AddTransactionResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.AddTransactionResponse)
                    return object;
                let message = new $root.mts.portfolio.AddTransactionResponse();
                if (object.tx != null) {
                    if (typeof object.tx !== "object")
                        throw TypeError(".mts.portfolio.AddTransactionResponse.tx: object expected");
                    message.tx = $root.mts.portfolio.Tx.fromObject(object.tx);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddTransactionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {mts.portfolio.AddTransactionResponse} message AddTransactionResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddTransactionResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.tx = null;
                if (message.tx != null && message.hasOwnProperty("tx"))
                    object.tx = $root.mts.portfolio.Tx.toObject(message.tx, options);
                return object;
            };

            /**
             * Converts this AddTransactionResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.AddTransactionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddTransactionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddTransactionResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.AddTransactionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddTransactionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.AddTransactionResponse";
            };

            return AddTransactionResponse;
        })();

        portfolio.DepositRequest = (function() {

            /**
             * Properties of a DepositRequest.
             * @memberof mts.portfolio
             * @interface IDepositRequest
             * @property {string|null} [date] DepositRequest date
             * @property {number|null} [amount] DepositRequest amount
             */

            /**
             * Constructs a new DepositRequest.
             * @memberof mts.portfolio
             * @classdesc Represents a DepositRequest.
             * @implements IDepositRequest
             * @constructor
             * @param {mts.portfolio.IDepositRequest=} [properties] Properties to set
             */
            function DepositRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DepositRequest date.
             * @member {string} date
             * @memberof mts.portfolio.DepositRequest
             * @instance
             */
            DepositRequest.prototype.date = "";

            /**
             * DepositRequest amount.
             * @member {number} amount
             * @memberof mts.portfolio.DepositRequest
             * @instance
             */
            DepositRequest.prototype.amount = 0;

            /**
             * Creates a new DepositRequest instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {mts.portfolio.IDepositRequest=} [properties] Properties to set
             * @returns {mts.portfolio.DepositRequest} DepositRequest instance
             */
            DepositRequest.create = function create(properties) {
                return new DepositRequest(properties);
            };

            /**
             * Encodes the specified DepositRequest message. Does not implicitly {@link mts.portfolio.DepositRequest.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {mts.portfolio.IDepositRequest} message DepositRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DepositRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.date);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
                return writer;
            };

            /**
             * Encodes the specified DepositRequest message, length delimited. Does not implicitly {@link mts.portfolio.DepositRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {mts.portfolio.IDepositRequest} message DepositRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DepositRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DepositRequest message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.DepositRequest} DepositRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DepositRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.DepositRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.date = reader.string();
                            break;
                        }
                    case 2: {
                            message.amount = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DepositRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.DepositRequest} DepositRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DepositRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DepositRequest message.
             * @function verify
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DepositRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.date != null && message.hasOwnProperty("date"))
                    if (!$util.isString(message.date))
                        return "date: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount !== "number")
                        return "amount: number expected";
                return null;
            };

            /**
             * Creates a DepositRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.DepositRequest} DepositRequest
             */
            DepositRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.DepositRequest)
                    return object;
                let message = new $root.mts.portfolio.DepositRequest();
                if (object.date != null)
                    message.date = String(object.date);
                if (object.amount != null)
                    message.amount = Number(object.amount);
                return message;
            };

            /**
             * Creates a plain object from a DepositRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {mts.portfolio.DepositRequest} message DepositRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DepositRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.date = "";
                    object.amount = 0;
                }
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = message.date;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                return object;
            };

            /**
             * Converts this DepositRequest to JSON.
             * @function toJSON
             * @memberof mts.portfolio.DepositRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DepositRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DepositRequest
             * @function getTypeUrl
             * @memberof mts.portfolio.DepositRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DepositRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.DepositRequest";
            };

            return DepositRequest;
        })();

        portfolio.Portfolio = (function() {

            /**
             * Properties of a Portfolio.
             * @memberof mts.portfolio
             * @interface IPortfolio
             * @property {number|null} [id] Portfolio id
             * @property {number|null} [cash] Portfolio cash
             * @property {number|null} [totalDeposits] Portfolio totalDeposits
             * @property {number|null} [equity] Portfolio equity
             * @property {string|null} [name] Portfolio name
             * @property {string|null} [commissionSchedule] Portfolio commissionSchedule
             * @property {number|null} [buyingPower] Portfolio buyingPower
             * @property {number|null} [totalValue] Portfolio totalValue
             * @property {number|null} [percentCash] Portfolio percentCash
             * @property {number|null} [returnYtd] Portfolio returnYtd
             * @property {number|null} [returnAll] Portfolio returnAll
             * @property {number|null} [return90] Portfolio return90
             * @property {number|null} [return365] Portfolio return365
             * @property {number|null} [closedPositionsCount] Portfolio closedPositionsCount
             * @property {string|null} [brokerAddr] Portfolio brokerAddr
             * @property {string|null} [brokerAccount] Portfolio brokerAccount
             * @property {string|null} [created] Portfolio created
             * @property {string|null} [startDate] Portfolio startDate
             * @property {string|null} [endDate] Portfolio endDate
             * @property {string|null} [error] Portfolio error
             * @property {string|null} [description] Portfolio description
             * @property {string|null} [updated] Portfolio updated
             * @property {number|null} [largestWin] Portfolio largestWin
             * @property {number|null} [largestLoss] Portfolio largestLoss
             * @property {number|null} [averageWin] Portfolio averageWin
             * @property {number|null} [averageLoss] Portfolio averageLoss
             * @property {number|null} [averageWinAmount] Portfolio averageWinAmount
             * @property {number|null} [averageLossAmount] Portfolio averageLossAmount
             * @property {number|null} [profitFactor] Portfolio profitFactor
             * @property {number|null} [maxDrawdown] Portfolio maxDrawdown
             * @property {number|null} [maxDrawdownPercent] Portfolio maxDrawdownPercent
             * @property {number|null} [totalDividends] Portfolio totalDividends
             * @property {number|null} [monthsTracked] Portfolio monthsTracked
             * @property {number|null} [averageTradeDuration] Portfolio averageTradeDuration
             * @property {number|null} [profitableTrades] Portfolio profitableTrades
             * @property {number|null} [profitableTradesPercent] Portfolio profitableTradesPercent
             * @property {number|null} [profitableMonths] Portfolio profitableMonths
             * @property {number|null} [profitableMonthsPercent] Portfolio profitableMonthsPercent
             * @property {number|null} [wins] Portfolio wins
             * @property {number|null} [losses] Portfolio losses
             */

            /**
             * Constructs a new Portfolio.
             * @memberof mts.portfolio
             * @classdesc Represents a Portfolio.
             * @implements IPortfolio
             * @constructor
             * @param {mts.portfolio.IPortfolio=} [properties] Properties to set
             */
            function Portfolio(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Portfolio id.
             * @member {number} id
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.id = 0;

            /**
             * Portfolio cash.
             * @member {number} cash
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.cash = 0;

            /**
             * Portfolio totalDeposits.
             * @member {number} totalDeposits
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.totalDeposits = 0;

            /**
             * Portfolio equity.
             * @member {number} equity
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.equity = 0;

            /**
             * Portfolio name.
             * @member {string} name
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.name = "";

            /**
             * Portfolio commissionSchedule.
             * @member {string} commissionSchedule
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.commissionSchedule = "";

            /**
             * Portfolio buyingPower.
             * @member {number} buyingPower
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.buyingPower = 0;

            /**
             * Portfolio totalValue.
             * @member {number} totalValue
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.totalValue = 0;

            /**
             * Portfolio percentCash.
             * @member {number} percentCash
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.percentCash = 0;

            /**
             * Portfolio returnYtd.
             * @member {number} returnYtd
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.returnYtd = 0;

            /**
             * Portfolio returnAll.
             * @member {number} returnAll
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.returnAll = 0;

            /**
             * Portfolio return90.
             * @member {number} return90
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.return90 = 0;

            /**
             * Portfolio return365.
             * @member {number} return365
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.return365 = 0;

            /**
             * Portfolio closedPositionsCount.
             * @member {number} closedPositionsCount
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.closedPositionsCount = 0;

            /**
             * Portfolio brokerAddr.
             * @member {string} brokerAddr
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.brokerAddr = "";

            /**
             * Portfolio brokerAccount.
             * @member {string} brokerAccount
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.brokerAccount = "";

            /**
             * Portfolio created.
             * @member {string} created
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.created = "";

            /**
             * Portfolio startDate.
             * @member {string} startDate
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.startDate = "";

            /**
             * Portfolio endDate.
             * @member {string} endDate
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.endDate = "";

            /**
             * Portfolio error.
             * @member {string} error
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.error = "";

            /**
             * Portfolio description.
             * @member {string} description
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.description = "";

            /**
             * Portfolio updated.
             * @member {string} updated
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.updated = "";

            /**
             * Portfolio largestWin.
             * @member {number} largestWin
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.largestWin = 0;

            /**
             * Portfolio largestLoss.
             * @member {number} largestLoss
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.largestLoss = 0;

            /**
             * Portfolio averageWin.
             * @member {number} averageWin
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.averageWin = 0;

            /**
             * Portfolio averageLoss.
             * @member {number} averageLoss
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.averageLoss = 0;

            /**
             * Portfolio averageWinAmount.
             * @member {number} averageWinAmount
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.averageWinAmount = 0;

            /**
             * Portfolio averageLossAmount.
             * @member {number} averageLossAmount
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.averageLossAmount = 0;

            /**
             * Portfolio profitFactor.
             * @member {number} profitFactor
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.profitFactor = 0;

            /**
             * Portfolio maxDrawdown.
             * @member {number} maxDrawdown
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.maxDrawdown = 0;

            /**
             * Portfolio maxDrawdownPercent.
             * @member {number} maxDrawdownPercent
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.maxDrawdownPercent = 0;

            /**
             * Portfolio totalDividends.
             * @member {number} totalDividends
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.totalDividends = 0;

            /**
             * Portfolio monthsTracked.
             * @member {number} monthsTracked
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.monthsTracked = 0;

            /**
             * Portfolio averageTradeDuration.
             * @member {number} averageTradeDuration
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.averageTradeDuration = 0;

            /**
             * Portfolio profitableTrades.
             * @member {number} profitableTrades
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.profitableTrades = 0;

            /**
             * Portfolio profitableTradesPercent.
             * @member {number} profitableTradesPercent
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.profitableTradesPercent = 0;

            /**
             * Portfolio profitableMonths.
             * @member {number} profitableMonths
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.profitableMonths = 0;

            /**
             * Portfolio profitableMonthsPercent.
             * @member {number} profitableMonthsPercent
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.profitableMonthsPercent = 0;

            /**
             * Portfolio wins.
             * @member {number} wins
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.wins = 0;

            /**
             * Portfolio losses.
             * @member {number} losses
             * @memberof mts.portfolio.Portfolio
             * @instance
             */
            Portfolio.prototype.losses = 0;

            /**
             * Creates a new Portfolio instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {mts.portfolio.IPortfolio=} [properties] Properties to set
             * @returns {mts.portfolio.Portfolio} Portfolio instance
             */
            Portfolio.create = function create(properties) {
                return new Portfolio(properties);
            };

            /**
             * Encodes the specified Portfolio message. Does not implicitly {@link mts.portfolio.Portfolio.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {mts.portfolio.IPortfolio} message Portfolio message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Portfolio.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.cash);
                if (message.totalDeposits != null && Object.hasOwnProperty.call(message, "totalDeposits"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.totalDeposits);
                if (message.equity != null && Object.hasOwnProperty.call(message, "equity"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.equity);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                if (message.commissionSchedule != null && Object.hasOwnProperty.call(message, "commissionSchedule"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.commissionSchedule);
                if (message.buyingPower != null && Object.hasOwnProperty.call(message, "buyingPower"))
                    writer.uint32(/* id 7, wireType 1 =*/57).double(message.buyingPower);
                if (message.totalValue != null && Object.hasOwnProperty.call(message, "totalValue"))
                    writer.uint32(/* id 8, wireType 1 =*/65).double(message.totalValue);
                if (message.percentCash != null && Object.hasOwnProperty.call(message, "percentCash"))
                    writer.uint32(/* id 9, wireType 1 =*/73).double(message.percentCash);
                if (message.returnYtd != null && Object.hasOwnProperty.call(message, "returnYtd"))
                    writer.uint32(/* id 10, wireType 1 =*/81).double(message.returnYtd);
                if (message.returnAll != null && Object.hasOwnProperty.call(message, "returnAll"))
                    writer.uint32(/* id 11, wireType 1 =*/89).double(message.returnAll);
                if (message.return90 != null && Object.hasOwnProperty.call(message, "return90"))
                    writer.uint32(/* id 12, wireType 1 =*/97).double(message.return90);
                if (message.return365 != null && Object.hasOwnProperty.call(message, "return365"))
                    writer.uint32(/* id 13, wireType 1 =*/105).double(message.return365);
                if (message.closedPositionsCount != null && Object.hasOwnProperty.call(message, "closedPositionsCount"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.closedPositionsCount);
                if (message.brokerAddr != null && Object.hasOwnProperty.call(message, "brokerAddr"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.brokerAddr);
                if (message.brokerAccount != null && Object.hasOwnProperty.call(message, "brokerAccount"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.brokerAccount);
                if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.created);
                if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                    writer.uint32(/* id 18, wireType 2 =*/146).string(message.startDate);
                if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.endDate);
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.error);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.description);
                if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.updated);
                if (message.largestWin != null && Object.hasOwnProperty.call(message, "largestWin"))
                    writer.uint32(/* id 100, wireType 1 =*/801).double(message.largestWin);
                if (message.largestLoss != null && Object.hasOwnProperty.call(message, "largestLoss"))
                    writer.uint32(/* id 101, wireType 1 =*/809).double(message.largestLoss);
                if (message.averageWin != null && Object.hasOwnProperty.call(message, "averageWin"))
                    writer.uint32(/* id 102, wireType 1 =*/817).double(message.averageWin);
                if (message.averageLoss != null && Object.hasOwnProperty.call(message, "averageLoss"))
                    writer.uint32(/* id 103, wireType 1 =*/825).double(message.averageLoss);
                if (message.averageWinAmount != null && Object.hasOwnProperty.call(message, "averageWinAmount"))
                    writer.uint32(/* id 104, wireType 1 =*/833).double(message.averageWinAmount);
                if (message.averageLossAmount != null && Object.hasOwnProperty.call(message, "averageLossAmount"))
                    writer.uint32(/* id 105, wireType 1 =*/841).double(message.averageLossAmount);
                if (message.profitFactor != null && Object.hasOwnProperty.call(message, "profitFactor"))
                    writer.uint32(/* id 106, wireType 1 =*/849).double(message.profitFactor);
                if (message.maxDrawdown != null && Object.hasOwnProperty.call(message, "maxDrawdown"))
                    writer.uint32(/* id 107, wireType 1 =*/857).double(message.maxDrawdown);
                if (message.maxDrawdownPercent != null && Object.hasOwnProperty.call(message, "maxDrawdownPercent"))
                    writer.uint32(/* id 108, wireType 1 =*/865).double(message.maxDrawdownPercent);
                if (message.totalDividends != null && Object.hasOwnProperty.call(message, "totalDividends"))
                    writer.uint32(/* id 109, wireType 1 =*/873).double(message.totalDividends);
                if (message.monthsTracked != null && Object.hasOwnProperty.call(message, "monthsTracked"))
                    writer.uint32(/* id 110, wireType 0 =*/880).int32(message.monthsTracked);
                if (message.averageTradeDuration != null && Object.hasOwnProperty.call(message, "averageTradeDuration"))
                    writer.uint32(/* id 111, wireType 1 =*/889).double(message.averageTradeDuration);
                if (message.profitableTrades != null && Object.hasOwnProperty.call(message, "profitableTrades"))
                    writer.uint32(/* id 112, wireType 0 =*/896).int32(message.profitableTrades);
                if (message.profitableTradesPercent != null && Object.hasOwnProperty.call(message, "profitableTradesPercent"))
                    writer.uint32(/* id 113, wireType 1 =*/905).double(message.profitableTradesPercent);
                if (message.profitableMonths != null && Object.hasOwnProperty.call(message, "profitableMonths"))
                    writer.uint32(/* id 114, wireType 0 =*/912).int32(message.profitableMonths);
                if (message.profitableMonthsPercent != null && Object.hasOwnProperty.call(message, "profitableMonthsPercent"))
                    writer.uint32(/* id 115, wireType 1 =*/921).double(message.profitableMonthsPercent);
                if (message.wins != null && Object.hasOwnProperty.call(message, "wins"))
                    writer.uint32(/* id 116, wireType 0 =*/928).int32(message.wins);
                if (message.losses != null && Object.hasOwnProperty.call(message, "losses"))
                    writer.uint32(/* id 117, wireType 0 =*/936).int32(message.losses);
                return writer;
            };

            /**
             * Encodes the specified Portfolio message, length delimited. Does not implicitly {@link mts.portfolio.Portfolio.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {mts.portfolio.IPortfolio} message Portfolio message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Portfolio.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Portfolio message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.Portfolio} Portfolio
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Portfolio.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.Portfolio();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.cash = reader.double();
                            break;
                        }
                    case 3: {
                            message.totalDeposits = reader.double();
                            break;
                        }
                    case 4: {
                            message.equity = reader.double();
                            break;
                        }
                    case 5: {
                            message.name = reader.string();
                            break;
                        }
                    case 6: {
                            message.commissionSchedule = reader.string();
                            break;
                        }
                    case 7: {
                            message.buyingPower = reader.double();
                            break;
                        }
                    case 8: {
                            message.totalValue = reader.double();
                            break;
                        }
                    case 9: {
                            message.percentCash = reader.double();
                            break;
                        }
                    case 10: {
                            message.returnYtd = reader.double();
                            break;
                        }
                    case 11: {
                            message.returnAll = reader.double();
                            break;
                        }
                    case 12: {
                            message.return90 = reader.double();
                            break;
                        }
                    case 13: {
                            message.return365 = reader.double();
                            break;
                        }
                    case 14: {
                            message.closedPositionsCount = reader.int32();
                            break;
                        }
                    case 15: {
                            message.brokerAddr = reader.string();
                            break;
                        }
                    case 16: {
                            message.brokerAccount = reader.string();
                            break;
                        }
                    case 17: {
                            message.created = reader.string();
                            break;
                        }
                    case 18: {
                            message.startDate = reader.string();
                            break;
                        }
                    case 19: {
                            message.endDate = reader.string();
                            break;
                        }
                    case 20: {
                            message.error = reader.string();
                            break;
                        }
                    case 21: {
                            message.description = reader.string();
                            break;
                        }
                    case 22: {
                            message.updated = reader.string();
                            break;
                        }
                    case 100: {
                            message.largestWin = reader.double();
                            break;
                        }
                    case 101: {
                            message.largestLoss = reader.double();
                            break;
                        }
                    case 102: {
                            message.averageWin = reader.double();
                            break;
                        }
                    case 103: {
                            message.averageLoss = reader.double();
                            break;
                        }
                    case 104: {
                            message.averageWinAmount = reader.double();
                            break;
                        }
                    case 105: {
                            message.averageLossAmount = reader.double();
                            break;
                        }
                    case 106: {
                            message.profitFactor = reader.double();
                            break;
                        }
                    case 107: {
                            message.maxDrawdown = reader.double();
                            break;
                        }
                    case 108: {
                            message.maxDrawdownPercent = reader.double();
                            break;
                        }
                    case 109: {
                            message.totalDividends = reader.double();
                            break;
                        }
                    case 110: {
                            message.monthsTracked = reader.int32();
                            break;
                        }
                    case 111: {
                            message.averageTradeDuration = reader.double();
                            break;
                        }
                    case 112: {
                            message.profitableTrades = reader.int32();
                            break;
                        }
                    case 113: {
                            message.profitableTradesPercent = reader.double();
                            break;
                        }
                    case 114: {
                            message.profitableMonths = reader.int32();
                            break;
                        }
                    case 115: {
                            message.profitableMonthsPercent = reader.double();
                            break;
                        }
                    case 116: {
                            message.wins = reader.int32();
                            break;
                        }
                    case 117: {
                            message.losses = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Portfolio message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.Portfolio} Portfolio
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Portfolio.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Portfolio message.
             * @function verify
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Portfolio.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.cash != null && message.hasOwnProperty("cash"))
                    if (typeof message.cash !== "number")
                        return "cash: number expected";
                if (message.totalDeposits != null && message.hasOwnProperty("totalDeposits"))
                    if (typeof message.totalDeposits !== "number")
                        return "totalDeposits: number expected";
                if (message.equity != null && message.hasOwnProperty("equity"))
                    if (typeof message.equity !== "number")
                        return "equity: number expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.commissionSchedule != null && message.hasOwnProperty("commissionSchedule"))
                    if (!$util.isString(message.commissionSchedule))
                        return "commissionSchedule: string expected";
                if (message.buyingPower != null && message.hasOwnProperty("buyingPower"))
                    if (typeof message.buyingPower !== "number")
                        return "buyingPower: number expected";
                if (message.totalValue != null && message.hasOwnProperty("totalValue"))
                    if (typeof message.totalValue !== "number")
                        return "totalValue: number expected";
                if (message.percentCash != null && message.hasOwnProperty("percentCash"))
                    if (typeof message.percentCash !== "number")
                        return "percentCash: number expected";
                if (message.returnYtd != null && message.hasOwnProperty("returnYtd"))
                    if (typeof message.returnYtd !== "number")
                        return "returnYtd: number expected";
                if (message.returnAll != null && message.hasOwnProperty("returnAll"))
                    if (typeof message.returnAll !== "number")
                        return "returnAll: number expected";
                if (message.return90 != null && message.hasOwnProperty("return90"))
                    if (typeof message.return90 !== "number")
                        return "return90: number expected";
                if (message.return365 != null && message.hasOwnProperty("return365"))
                    if (typeof message.return365 !== "number")
                        return "return365: number expected";
                if (message.closedPositionsCount != null && message.hasOwnProperty("closedPositionsCount"))
                    if (!$util.isInteger(message.closedPositionsCount))
                        return "closedPositionsCount: integer expected";
                if (message.brokerAddr != null && message.hasOwnProperty("brokerAddr"))
                    if (!$util.isString(message.brokerAddr))
                        return "brokerAddr: string expected";
                if (message.brokerAccount != null && message.hasOwnProperty("brokerAccount"))
                    if (!$util.isString(message.brokerAccount))
                        return "brokerAccount: string expected";
                if (message.created != null && message.hasOwnProperty("created"))
                    if (!$util.isString(message.created))
                        return "created: string expected";
                if (message.startDate != null && message.hasOwnProperty("startDate"))
                    if (!$util.isString(message.startDate))
                        return "startDate: string expected";
                if (message.endDate != null && message.hasOwnProperty("endDate"))
                    if (!$util.isString(message.endDate))
                        return "endDate: string expected";
                if (message.error != null && message.hasOwnProperty("error"))
                    if (!$util.isString(message.error))
                        return "error: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.updated != null && message.hasOwnProperty("updated"))
                    if (!$util.isString(message.updated))
                        return "updated: string expected";
                if (message.largestWin != null && message.hasOwnProperty("largestWin"))
                    if (typeof message.largestWin !== "number")
                        return "largestWin: number expected";
                if (message.largestLoss != null && message.hasOwnProperty("largestLoss"))
                    if (typeof message.largestLoss !== "number")
                        return "largestLoss: number expected";
                if (message.averageWin != null && message.hasOwnProperty("averageWin"))
                    if (typeof message.averageWin !== "number")
                        return "averageWin: number expected";
                if (message.averageLoss != null && message.hasOwnProperty("averageLoss"))
                    if (typeof message.averageLoss !== "number")
                        return "averageLoss: number expected";
                if (message.averageWinAmount != null && message.hasOwnProperty("averageWinAmount"))
                    if (typeof message.averageWinAmount !== "number")
                        return "averageWinAmount: number expected";
                if (message.averageLossAmount != null && message.hasOwnProperty("averageLossAmount"))
                    if (typeof message.averageLossAmount !== "number")
                        return "averageLossAmount: number expected";
                if (message.profitFactor != null && message.hasOwnProperty("profitFactor"))
                    if (typeof message.profitFactor !== "number")
                        return "profitFactor: number expected";
                if (message.maxDrawdown != null && message.hasOwnProperty("maxDrawdown"))
                    if (typeof message.maxDrawdown !== "number")
                        return "maxDrawdown: number expected";
                if (message.maxDrawdownPercent != null && message.hasOwnProperty("maxDrawdownPercent"))
                    if (typeof message.maxDrawdownPercent !== "number")
                        return "maxDrawdownPercent: number expected";
                if (message.totalDividends != null && message.hasOwnProperty("totalDividends"))
                    if (typeof message.totalDividends !== "number")
                        return "totalDividends: number expected";
                if (message.monthsTracked != null && message.hasOwnProperty("monthsTracked"))
                    if (!$util.isInteger(message.monthsTracked))
                        return "monthsTracked: integer expected";
                if (message.averageTradeDuration != null && message.hasOwnProperty("averageTradeDuration"))
                    if (typeof message.averageTradeDuration !== "number")
                        return "averageTradeDuration: number expected";
                if (message.profitableTrades != null && message.hasOwnProperty("profitableTrades"))
                    if (!$util.isInteger(message.profitableTrades))
                        return "profitableTrades: integer expected";
                if (message.profitableTradesPercent != null && message.hasOwnProperty("profitableTradesPercent"))
                    if (typeof message.profitableTradesPercent !== "number")
                        return "profitableTradesPercent: number expected";
                if (message.profitableMonths != null && message.hasOwnProperty("profitableMonths"))
                    if (!$util.isInteger(message.profitableMonths))
                        return "profitableMonths: integer expected";
                if (message.profitableMonthsPercent != null && message.hasOwnProperty("profitableMonthsPercent"))
                    if (typeof message.profitableMonthsPercent !== "number")
                        return "profitableMonthsPercent: number expected";
                if (message.wins != null && message.hasOwnProperty("wins"))
                    if (!$util.isInteger(message.wins))
                        return "wins: integer expected";
                if (message.losses != null && message.hasOwnProperty("losses"))
                    if (!$util.isInteger(message.losses))
                        return "losses: integer expected";
                return null;
            };

            /**
             * Creates a Portfolio message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.Portfolio} Portfolio
             */
            Portfolio.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.Portfolio)
                    return object;
                let message = new $root.mts.portfolio.Portfolio();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.cash != null)
                    message.cash = Number(object.cash);
                if (object.totalDeposits != null)
                    message.totalDeposits = Number(object.totalDeposits);
                if (object.equity != null)
                    message.equity = Number(object.equity);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.commissionSchedule != null)
                    message.commissionSchedule = String(object.commissionSchedule);
                if (object.buyingPower != null)
                    message.buyingPower = Number(object.buyingPower);
                if (object.totalValue != null)
                    message.totalValue = Number(object.totalValue);
                if (object.percentCash != null)
                    message.percentCash = Number(object.percentCash);
                if (object.returnYtd != null)
                    message.returnYtd = Number(object.returnYtd);
                if (object.returnAll != null)
                    message.returnAll = Number(object.returnAll);
                if (object.return90 != null)
                    message.return90 = Number(object.return90);
                if (object.return365 != null)
                    message.return365 = Number(object.return365);
                if (object.closedPositionsCount != null)
                    message.closedPositionsCount = object.closedPositionsCount | 0;
                if (object.brokerAddr != null)
                    message.brokerAddr = String(object.brokerAddr);
                if (object.brokerAccount != null)
                    message.brokerAccount = String(object.brokerAccount);
                if (object.created != null)
                    message.created = String(object.created);
                if (object.startDate != null)
                    message.startDate = String(object.startDate);
                if (object.endDate != null)
                    message.endDate = String(object.endDate);
                if (object.error != null)
                    message.error = String(object.error);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.updated != null)
                    message.updated = String(object.updated);
                if (object.largestWin != null)
                    message.largestWin = Number(object.largestWin);
                if (object.largestLoss != null)
                    message.largestLoss = Number(object.largestLoss);
                if (object.averageWin != null)
                    message.averageWin = Number(object.averageWin);
                if (object.averageLoss != null)
                    message.averageLoss = Number(object.averageLoss);
                if (object.averageWinAmount != null)
                    message.averageWinAmount = Number(object.averageWinAmount);
                if (object.averageLossAmount != null)
                    message.averageLossAmount = Number(object.averageLossAmount);
                if (object.profitFactor != null)
                    message.profitFactor = Number(object.profitFactor);
                if (object.maxDrawdown != null)
                    message.maxDrawdown = Number(object.maxDrawdown);
                if (object.maxDrawdownPercent != null)
                    message.maxDrawdownPercent = Number(object.maxDrawdownPercent);
                if (object.totalDividends != null)
                    message.totalDividends = Number(object.totalDividends);
                if (object.monthsTracked != null)
                    message.monthsTracked = object.monthsTracked | 0;
                if (object.averageTradeDuration != null)
                    message.averageTradeDuration = Number(object.averageTradeDuration);
                if (object.profitableTrades != null)
                    message.profitableTrades = object.profitableTrades | 0;
                if (object.profitableTradesPercent != null)
                    message.profitableTradesPercent = Number(object.profitableTradesPercent);
                if (object.profitableMonths != null)
                    message.profitableMonths = object.profitableMonths | 0;
                if (object.profitableMonthsPercent != null)
                    message.profitableMonthsPercent = Number(object.profitableMonthsPercent);
                if (object.wins != null)
                    message.wins = object.wins | 0;
                if (object.losses != null)
                    message.losses = object.losses | 0;
                return message;
            };

            /**
             * Creates a plain object from a Portfolio message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {mts.portfolio.Portfolio} message Portfolio
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Portfolio.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.cash = 0;
                    object.totalDeposits = 0;
                    object.equity = 0;
                    object.name = "";
                    object.commissionSchedule = "";
                    object.buyingPower = 0;
                    object.totalValue = 0;
                    object.percentCash = 0;
                    object.returnYtd = 0;
                    object.returnAll = 0;
                    object.return90 = 0;
                    object.return365 = 0;
                    object.closedPositionsCount = 0;
                    object.brokerAddr = "";
                    object.brokerAccount = "";
                    object.created = "";
                    object.startDate = "";
                    object.endDate = "";
                    object.error = "";
                    object.description = "";
                    object.updated = "";
                    object.largestWin = 0;
                    object.largestLoss = 0;
                    object.averageWin = 0;
                    object.averageLoss = 0;
                    object.averageWinAmount = 0;
                    object.averageLossAmount = 0;
                    object.profitFactor = 0;
                    object.maxDrawdown = 0;
                    object.maxDrawdownPercent = 0;
                    object.totalDividends = 0;
                    object.monthsTracked = 0;
                    object.averageTradeDuration = 0;
                    object.profitableTrades = 0;
                    object.profitableTradesPercent = 0;
                    object.profitableMonths = 0;
                    object.profitableMonthsPercent = 0;
                    object.wins = 0;
                    object.losses = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.cash != null && message.hasOwnProperty("cash"))
                    object.cash = options.json && !isFinite(message.cash) ? String(message.cash) : message.cash;
                if (message.totalDeposits != null && message.hasOwnProperty("totalDeposits"))
                    object.totalDeposits = options.json && !isFinite(message.totalDeposits) ? String(message.totalDeposits) : message.totalDeposits;
                if (message.equity != null && message.hasOwnProperty("equity"))
                    object.equity = options.json && !isFinite(message.equity) ? String(message.equity) : message.equity;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.commissionSchedule != null && message.hasOwnProperty("commissionSchedule"))
                    object.commissionSchedule = message.commissionSchedule;
                if (message.buyingPower != null && message.hasOwnProperty("buyingPower"))
                    object.buyingPower = options.json && !isFinite(message.buyingPower) ? String(message.buyingPower) : message.buyingPower;
                if (message.totalValue != null && message.hasOwnProperty("totalValue"))
                    object.totalValue = options.json && !isFinite(message.totalValue) ? String(message.totalValue) : message.totalValue;
                if (message.percentCash != null && message.hasOwnProperty("percentCash"))
                    object.percentCash = options.json && !isFinite(message.percentCash) ? String(message.percentCash) : message.percentCash;
                if (message.returnYtd != null && message.hasOwnProperty("returnYtd"))
                    object.returnYtd = options.json && !isFinite(message.returnYtd) ? String(message.returnYtd) : message.returnYtd;
                if (message.returnAll != null && message.hasOwnProperty("returnAll"))
                    object.returnAll = options.json && !isFinite(message.returnAll) ? String(message.returnAll) : message.returnAll;
                if (message.return90 != null && message.hasOwnProperty("return90"))
                    object.return90 = options.json && !isFinite(message.return90) ? String(message.return90) : message.return90;
                if (message.return365 != null && message.hasOwnProperty("return365"))
                    object.return365 = options.json && !isFinite(message.return365) ? String(message.return365) : message.return365;
                if (message.closedPositionsCount != null && message.hasOwnProperty("closedPositionsCount"))
                    object.closedPositionsCount = message.closedPositionsCount;
                if (message.brokerAddr != null && message.hasOwnProperty("brokerAddr"))
                    object.brokerAddr = message.brokerAddr;
                if (message.brokerAccount != null && message.hasOwnProperty("brokerAccount"))
                    object.brokerAccount = message.brokerAccount;
                if (message.created != null && message.hasOwnProperty("created"))
                    object.created = message.created;
                if (message.startDate != null && message.hasOwnProperty("startDate"))
                    object.startDate = message.startDate;
                if (message.endDate != null && message.hasOwnProperty("endDate"))
                    object.endDate = message.endDate;
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = message.error;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.updated != null && message.hasOwnProperty("updated"))
                    object.updated = message.updated;
                if (message.largestWin != null && message.hasOwnProperty("largestWin"))
                    object.largestWin = options.json && !isFinite(message.largestWin) ? String(message.largestWin) : message.largestWin;
                if (message.largestLoss != null && message.hasOwnProperty("largestLoss"))
                    object.largestLoss = options.json && !isFinite(message.largestLoss) ? String(message.largestLoss) : message.largestLoss;
                if (message.averageWin != null && message.hasOwnProperty("averageWin"))
                    object.averageWin = options.json && !isFinite(message.averageWin) ? String(message.averageWin) : message.averageWin;
                if (message.averageLoss != null && message.hasOwnProperty("averageLoss"))
                    object.averageLoss = options.json && !isFinite(message.averageLoss) ? String(message.averageLoss) : message.averageLoss;
                if (message.averageWinAmount != null && message.hasOwnProperty("averageWinAmount"))
                    object.averageWinAmount = options.json && !isFinite(message.averageWinAmount) ? String(message.averageWinAmount) : message.averageWinAmount;
                if (message.averageLossAmount != null && message.hasOwnProperty("averageLossAmount"))
                    object.averageLossAmount = options.json && !isFinite(message.averageLossAmount) ? String(message.averageLossAmount) : message.averageLossAmount;
                if (message.profitFactor != null && message.hasOwnProperty("profitFactor"))
                    object.profitFactor = options.json && !isFinite(message.profitFactor) ? String(message.profitFactor) : message.profitFactor;
                if (message.maxDrawdown != null && message.hasOwnProperty("maxDrawdown"))
                    object.maxDrawdown = options.json && !isFinite(message.maxDrawdown) ? String(message.maxDrawdown) : message.maxDrawdown;
                if (message.maxDrawdownPercent != null && message.hasOwnProperty("maxDrawdownPercent"))
                    object.maxDrawdownPercent = options.json && !isFinite(message.maxDrawdownPercent) ? String(message.maxDrawdownPercent) : message.maxDrawdownPercent;
                if (message.totalDividends != null && message.hasOwnProperty("totalDividends"))
                    object.totalDividends = options.json && !isFinite(message.totalDividends) ? String(message.totalDividends) : message.totalDividends;
                if (message.monthsTracked != null && message.hasOwnProperty("monthsTracked"))
                    object.monthsTracked = message.monthsTracked;
                if (message.averageTradeDuration != null && message.hasOwnProperty("averageTradeDuration"))
                    object.averageTradeDuration = options.json && !isFinite(message.averageTradeDuration) ? String(message.averageTradeDuration) : message.averageTradeDuration;
                if (message.profitableTrades != null && message.hasOwnProperty("profitableTrades"))
                    object.profitableTrades = message.profitableTrades;
                if (message.profitableTradesPercent != null && message.hasOwnProperty("profitableTradesPercent"))
                    object.profitableTradesPercent = options.json && !isFinite(message.profitableTradesPercent) ? String(message.profitableTradesPercent) : message.profitableTradesPercent;
                if (message.profitableMonths != null && message.hasOwnProperty("profitableMonths"))
                    object.profitableMonths = message.profitableMonths;
                if (message.profitableMonthsPercent != null && message.hasOwnProperty("profitableMonthsPercent"))
                    object.profitableMonthsPercent = options.json && !isFinite(message.profitableMonthsPercent) ? String(message.profitableMonthsPercent) : message.profitableMonthsPercent;
                if (message.wins != null && message.hasOwnProperty("wins"))
                    object.wins = message.wins;
                if (message.losses != null && message.hasOwnProperty("losses"))
                    object.losses = message.losses;
                return object;
            };

            /**
             * Converts this Portfolio to JSON.
             * @function toJSON
             * @memberof mts.portfolio.Portfolio
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Portfolio.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Portfolio
             * @function getTypeUrl
             * @memberof mts.portfolio.Portfolio
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Portfolio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.Portfolio";
            };

            return Portfolio;
        })();

        portfolio.Positions = (function() {

            /**
             * Properties of a Positions.
             * @memberof mts.portfolio
             * @interface IPositions
             * @property {Array.<mts.portfolio.IPosition>|null} [positions] Positions positions
             */

            /**
             * Constructs a new Positions.
             * @memberof mts.portfolio
             * @classdesc Represents a Positions.
             * @implements IPositions
             * @constructor
             * @param {mts.portfolio.IPositions=} [properties] Properties to set
             */
            function Positions(properties) {
                this.positions = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Positions positions.
             * @member {Array.<mts.portfolio.IPosition>} positions
             * @memberof mts.portfolio.Positions
             * @instance
             */
            Positions.prototype.positions = $util.emptyArray;

            /**
             * Creates a new Positions instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.Positions
             * @static
             * @param {mts.portfolio.IPositions=} [properties] Properties to set
             * @returns {mts.portfolio.Positions} Positions instance
             */
            Positions.create = function create(properties) {
                return new Positions(properties);
            };

            /**
             * Encodes the specified Positions message. Does not implicitly {@link mts.portfolio.Positions.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.Positions
             * @static
             * @param {mts.portfolio.IPositions} message Positions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Positions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.positions != null && message.positions.length)
                    for (let i = 0; i < message.positions.length; ++i)
                        $root.mts.portfolio.Position.encode(message.positions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Positions message, length delimited. Does not implicitly {@link mts.portfolio.Positions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.Positions
             * @static
             * @param {mts.portfolio.IPositions} message Positions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Positions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Positions message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.Positions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.Positions} Positions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Positions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.Positions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.positions && message.positions.length))
                                message.positions = [];
                            message.positions.push($root.mts.portfolio.Position.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Positions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.Positions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.Positions} Positions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Positions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Positions message.
             * @function verify
             * @memberof mts.portfolio.Positions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Positions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.positions != null && message.hasOwnProperty("positions")) {
                    if (!Array.isArray(message.positions))
                        return "positions: array expected";
                    for (let i = 0; i < message.positions.length; ++i) {
                        let error = $root.mts.portfolio.Position.verify(message.positions[i]);
                        if (error)
                            return "positions." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Positions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.Positions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.Positions} Positions
             */
            Positions.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.Positions)
                    return object;
                let message = new $root.mts.portfolio.Positions();
                if (object.positions) {
                    if (!Array.isArray(object.positions))
                        throw TypeError(".mts.portfolio.Positions.positions: array expected");
                    message.positions = [];
                    for (let i = 0; i < object.positions.length; ++i) {
                        if (typeof object.positions[i] !== "object")
                            throw TypeError(".mts.portfolio.Positions.positions: object expected");
                        message.positions[i] = $root.mts.portfolio.Position.fromObject(object.positions[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Positions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.Positions
             * @static
             * @param {mts.portfolio.Positions} message Positions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Positions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.positions = [];
                if (message.positions && message.positions.length) {
                    object.positions = [];
                    for (let j = 0; j < message.positions.length; ++j)
                        object.positions[j] = $root.mts.portfolio.Position.toObject(message.positions[j], options);
                }
                return object;
            };

            /**
             * Converts this Positions to JSON.
             * @function toJSON
             * @memberof mts.portfolio.Positions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Positions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Positions
             * @function getTypeUrl
             * @memberof mts.portfolio.Positions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Positions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.Positions";
            };

            return Positions;
        })();

        portfolio.Position = (function() {

            /**
             * Properties of a Position.
             * @memberof mts.portfolio
             * @interface IPosition
             * @property {number|null} [id] Position id
             * @property {number|null} [portfolioId] Position portfolioId
             * @property {string|null} [symbol] Position symbol
             * @property {number|null} [quantity] Position quantity
             * @property {number|null} [totalCost] Position totalCost
             * @property {number|null} [realized] Position realized
             * @property {number|null} [portfolioPercent] Position portfolioPercent
             * @property {string|null} [openedDate] Position openedDate
             * @property {string|null} [closedDate] Position closedDate
             * @property {string|null} [description] Position description
             * @property {number|null} [lastPrice] Position lastPrice
             * @property {number|null} [gain] Position gain
             * @property {number|null} [comboId] Position comboId
             * @property {string|null} [commentary] Position commentary
             * @property {number|null} [txId] Position txId
             * @property {number|null} [costBasis] Position costBasis
             * @property {number|null} [marketValue] Position marketValue
             * @property {number|null} [tradeCount] Position tradeCount
             * @property {number|null} [initialQuantity] Position initialQuantity
             * @property {number|null} [entryTxId] Position entryTxId
             * @property {number|null} [entryPrice] Position entryPrice
             * @property {number|null} [exitPrice] Position exitPrice
             * @property {number|null} [gainPercent] Position gainPercent
             */

            /**
             * Constructs a new Position.
             * @memberof mts.portfolio
             * @classdesc Represents a Position.
             * @implements IPosition
             * @constructor
             * @param {mts.portfolio.IPosition=} [properties] Properties to set
             */
            function Position(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Position id.
             * @member {number} id
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.id = 0;

            /**
             * Position portfolioId.
             * @member {number} portfolioId
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.portfolioId = 0;

            /**
             * Position symbol.
             * @member {string} symbol
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.symbol = "";

            /**
             * Position quantity.
             * @member {number} quantity
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.quantity = 0;

            /**
             * Position totalCost.
             * @member {number} totalCost
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.totalCost = 0;

            /**
             * Position realized.
             * @member {number} realized
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.realized = 0;

            /**
             * Position portfolioPercent.
             * @member {number} portfolioPercent
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.portfolioPercent = 0;

            /**
             * Position openedDate.
             * @member {string} openedDate
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.openedDate = "";

            /**
             * Position closedDate.
             * @member {string} closedDate
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.closedDate = "";

            /**
             * Position description.
             * @member {string} description
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.description = "";

            /**
             * Position lastPrice.
             * @member {number} lastPrice
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.lastPrice = 0;

            /**
             * Position gain.
             * @member {number} gain
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.gain = 0;

            /**
             * Position comboId.
             * @member {number} comboId
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.comboId = 0;

            /**
             * Position commentary.
             * @member {string} commentary
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.commentary = "";

            /**
             * Position txId.
             * @member {number} txId
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.txId = 0;

            /**
             * Position costBasis.
             * @member {number} costBasis
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.costBasis = 0;

            /**
             * Position marketValue.
             * @member {number} marketValue
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.marketValue = 0;

            /**
             * Position tradeCount.
             * @member {number} tradeCount
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.tradeCount = 0;

            /**
             * Position initialQuantity.
             * @member {number} initialQuantity
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.initialQuantity = 0;

            /**
             * Position entryTxId.
             * @member {number} entryTxId
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.entryTxId = 0;

            /**
             * Position entryPrice.
             * @member {number} entryPrice
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.entryPrice = 0;

            /**
             * Position exitPrice.
             * @member {number} exitPrice
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.exitPrice = 0;

            /**
             * Position gainPercent.
             * @member {number} gainPercent
             * @memberof mts.portfolio.Position
             * @instance
             */
            Position.prototype.gainPercent = 0;

            /**
             * Creates a new Position instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.Position
             * @static
             * @param {mts.portfolio.IPosition=} [properties] Properties to set
             * @returns {mts.portfolio.Position} Position instance
             */
            Position.create = function create(properties) {
                return new Position(properties);
            };

            /**
             * Encodes the specified Position message. Does not implicitly {@link mts.portfolio.Position.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.Position
             * @static
             * @param {mts.portfolio.IPosition} message Position message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Position.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.portfolioId);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.symbol);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.quantity);
                if (message.totalCost != null && Object.hasOwnProperty.call(message, "totalCost"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.totalCost);
                if (message.realized != null && Object.hasOwnProperty.call(message, "realized"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.realized);
                if (message.portfolioPercent != null && Object.hasOwnProperty.call(message, "portfolioPercent"))
                    writer.uint32(/* id 7, wireType 1 =*/57).double(message.portfolioPercent);
                if (message.openedDate != null && Object.hasOwnProperty.call(message, "openedDate"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.openedDate);
                if (message.closedDate != null && Object.hasOwnProperty.call(message, "closedDate"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.closedDate);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.description);
                if (message.lastPrice != null && Object.hasOwnProperty.call(message, "lastPrice"))
                    writer.uint32(/* id 11, wireType 1 =*/89).double(message.lastPrice);
                if (message.gain != null && Object.hasOwnProperty.call(message, "gain"))
                    writer.uint32(/* id 12, wireType 1 =*/97).double(message.gain);
                if (message.comboId != null && Object.hasOwnProperty.call(message, "comboId"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.comboId);
                if (message.commentary != null && Object.hasOwnProperty.call(message, "commentary"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.commentary);
                if (message.txId != null && Object.hasOwnProperty.call(message, "txId"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.txId);
                if (message.costBasis != null && Object.hasOwnProperty.call(message, "costBasis"))
                    writer.uint32(/* id 16, wireType 1 =*/129).double(message.costBasis);
                if (message.marketValue != null && Object.hasOwnProperty.call(message, "marketValue"))
                    writer.uint32(/* id 17, wireType 1 =*/137).double(message.marketValue);
                if (message.tradeCount != null && Object.hasOwnProperty.call(message, "tradeCount"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int32(message.tradeCount);
                if (message.initialQuantity != null && Object.hasOwnProperty.call(message, "initialQuantity"))
                    writer.uint32(/* id 20, wireType 1 =*/161).double(message.initialQuantity);
                if (message.entryTxId != null && Object.hasOwnProperty.call(message, "entryTxId"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int32(message.entryTxId);
                if (message.entryPrice != null && Object.hasOwnProperty.call(message, "entryPrice"))
                    writer.uint32(/* id 22, wireType 1 =*/177).double(message.entryPrice);
                if (message.exitPrice != null && Object.hasOwnProperty.call(message, "exitPrice"))
                    writer.uint32(/* id 23, wireType 1 =*/185).double(message.exitPrice);
                if (message.gainPercent != null && Object.hasOwnProperty.call(message, "gainPercent"))
                    writer.uint32(/* id 25, wireType 1 =*/201).double(message.gainPercent);
                return writer;
            };

            /**
             * Encodes the specified Position message, length delimited. Does not implicitly {@link mts.portfolio.Position.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.Position
             * @static
             * @param {mts.portfolio.IPosition} message Position message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Position.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Position message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.Position
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.Position} Position
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Position.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.Position();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.portfolioId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.symbol = reader.string();
                            break;
                        }
                    case 4: {
                            message.quantity = reader.double();
                            break;
                        }
                    case 5: {
                            message.totalCost = reader.double();
                            break;
                        }
                    case 6: {
                            message.realized = reader.double();
                            break;
                        }
                    case 7: {
                            message.portfolioPercent = reader.double();
                            break;
                        }
                    case 8: {
                            message.openedDate = reader.string();
                            break;
                        }
                    case 9: {
                            message.closedDate = reader.string();
                            break;
                        }
                    case 10: {
                            message.description = reader.string();
                            break;
                        }
                    case 11: {
                            message.lastPrice = reader.double();
                            break;
                        }
                    case 12: {
                            message.gain = reader.double();
                            break;
                        }
                    case 13: {
                            message.comboId = reader.int32();
                            break;
                        }
                    case 14: {
                            message.commentary = reader.string();
                            break;
                        }
                    case 15: {
                            message.txId = reader.int32();
                            break;
                        }
                    case 16: {
                            message.costBasis = reader.double();
                            break;
                        }
                    case 17: {
                            message.marketValue = reader.double();
                            break;
                        }
                    case 18: {
                            message.tradeCount = reader.int32();
                            break;
                        }
                    case 20: {
                            message.initialQuantity = reader.double();
                            break;
                        }
                    case 21: {
                            message.entryTxId = reader.int32();
                            break;
                        }
                    case 22: {
                            message.entryPrice = reader.double();
                            break;
                        }
                    case 23: {
                            message.exitPrice = reader.double();
                            break;
                        }
                    case 25: {
                            message.gainPercent = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Position message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.Position
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.Position} Position
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Position.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Position message.
             * @function verify
             * @memberof mts.portfolio.Position
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Position.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isInteger(message.portfolioId))
                        return "portfolioId: integer expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (typeof message.quantity !== "number")
                        return "quantity: number expected";
                if (message.totalCost != null && message.hasOwnProperty("totalCost"))
                    if (typeof message.totalCost !== "number")
                        return "totalCost: number expected";
                if (message.realized != null && message.hasOwnProperty("realized"))
                    if (typeof message.realized !== "number")
                        return "realized: number expected";
                if (message.portfolioPercent != null && message.hasOwnProperty("portfolioPercent"))
                    if (typeof message.portfolioPercent !== "number")
                        return "portfolioPercent: number expected";
                if (message.openedDate != null && message.hasOwnProperty("openedDate"))
                    if (!$util.isString(message.openedDate))
                        return "openedDate: string expected";
                if (message.closedDate != null && message.hasOwnProperty("closedDate"))
                    if (!$util.isString(message.closedDate))
                        return "closedDate: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.lastPrice != null && message.hasOwnProperty("lastPrice"))
                    if (typeof message.lastPrice !== "number")
                        return "lastPrice: number expected";
                if (message.gain != null && message.hasOwnProperty("gain"))
                    if (typeof message.gain !== "number")
                        return "gain: number expected";
                if (message.comboId != null && message.hasOwnProperty("comboId"))
                    if (!$util.isInteger(message.comboId))
                        return "comboId: integer expected";
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    if (!$util.isString(message.commentary))
                        return "commentary: string expected";
                if (message.txId != null && message.hasOwnProperty("txId"))
                    if (!$util.isInteger(message.txId))
                        return "txId: integer expected";
                if (message.costBasis != null && message.hasOwnProperty("costBasis"))
                    if (typeof message.costBasis !== "number")
                        return "costBasis: number expected";
                if (message.marketValue != null && message.hasOwnProperty("marketValue"))
                    if (typeof message.marketValue !== "number")
                        return "marketValue: number expected";
                if (message.tradeCount != null && message.hasOwnProperty("tradeCount"))
                    if (!$util.isInteger(message.tradeCount))
                        return "tradeCount: integer expected";
                if (message.initialQuantity != null && message.hasOwnProperty("initialQuantity"))
                    if (typeof message.initialQuantity !== "number")
                        return "initialQuantity: number expected";
                if (message.entryTxId != null && message.hasOwnProperty("entryTxId"))
                    if (!$util.isInteger(message.entryTxId))
                        return "entryTxId: integer expected";
                if (message.entryPrice != null && message.hasOwnProperty("entryPrice"))
                    if (typeof message.entryPrice !== "number")
                        return "entryPrice: number expected";
                if (message.exitPrice != null && message.hasOwnProperty("exitPrice"))
                    if (typeof message.exitPrice !== "number")
                        return "exitPrice: number expected";
                if (message.gainPercent != null && message.hasOwnProperty("gainPercent"))
                    if (typeof message.gainPercent !== "number")
                        return "gainPercent: number expected";
                return null;
            };

            /**
             * Creates a Position message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.Position
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.Position} Position
             */
            Position.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.Position)
                    return object;
                let message = new $root.mts.portfolio.Position();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.portfolioId != null)
                    message.portfolioId = object.portfolioId | 0;
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.quantity != null)
                    message.quantity = Number(object.quantity);
                if (object.totalCost != null)
                    message.totalCost = Number(object.totalCost);
                if (object.realized != null)
                    message.realized = Number(object.realized);
                if (object.portfolioPercent != null)
                    message.portfolioPercent = Number(object.portfolioPercent);
                if (object.openedDate != null)
                    message.openedDate = String(object.openedDate);
                if (object.closedDate != null)
                    message.closedDate = String(object.closedDate);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.lastPrice != null)
                    message.lastPrice = Number(object.lastPrice);
                if (object.gain != null)
                    message.gain = Number(object.gain);
                if (object.comboId != null)
                    message.comboId = object.comboId | 0;
                if (object.commentary != null)
                    message.commentary = String(object.commentary);
                if (object.txId != null)
                    message.txId = object.txId | 0;
                if (object.costBasis != null)
                    message.costBasis = Number(object.costBasis);
                if (object.marketValue != null)
                    message.marketValue = Number(object.marketValue);
                if (object.tradeCount != null)
                    message.tradeCount = object.tradeCount | 0;
                if (object.initialQuantity != null)
                    message.initialQuantity = Number(object.initialQuantity);
                if (object.entryTxId != null)
                    message.entryTxId = object.entryTxId | 0;
                if (object.entryPrice != null)
                    message.entryPrice = Number(object.entryPrice);
                if (object.exitPrice != null)
                    message.exitPrice = Number(object.exitPrice);
                if (object.gainPercent != null)
                    message.gainPercent = Number(object.gainPercent);
                return message;
            };

            /**
             * Creates a plain object from a Position message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.Position
             * @static
             * @param {mts.portfolio.Position} message Position
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Position.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.portfolioId = 0;
                    object.symbol = "";
                    object.quantity = 0;
                    object.totalCost = 0;
                    object.realized = 0;
                    object.portfolioPercent = 0;
                    object.openedDate = "";
                    object.closedDate = "";
                    object.description = "";
                    object.lastPrice = 0;
                    object.gain = 0;
                    object.comboId = 0;
                    object.commentary = "";
                    object.txId = 0;
                    object.costBasis = 0;
                    object.marketValue = 0;
                    object.tradeCount = 0;
                    object.initialQuantity = 0;
                    object.entryTxId = 0;
                    object.entryPrice = 0;
                    object.exitPrice = 0;
                    object.gainPercent = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = options.json && !isFinite(message.quantity) ? String(message.quantity) : message.quantity;
                if (message.totalCost != null && message.hasOwnProperty("totalCost"))
                    object.totalCost = options.json && !isFinite(message.totalCost) ? String(message.totalCost) : message.totalCost;
                if (message.realized != null && message.hasOwnProperty("realized"))
                    object.realized = options.json && !isFinite(message.realized) ? String(message.realized) : message.realized;
                if (message.portfolioPercent != null && message.hasOwnProperty("portfolioPercent"))
                    object.portfolioPercent = options.json && !isFinite(message.portfolioPercent) ? String(message.portfolioPercent) : message.portfolioPercent;
                if (message.openedDate != null && message.hasOwnProperty("openedDate"))
                    object.openedDate = message.openedDate;
                if (message.closedDate != null && message.hasOwnProperty("closedDate"))
                    object.closedDate = message.closedDate;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.lastPrice != null && message.hasOwnProperty("lastPrice"))
                    object.lastPrice = options.json && !isFinite(message.lastPrice) ? String(message.lastPrice) : message.lastPrice;
                if (message.gain != null && message.hasOwnProperty("gain"))
                    object.gain = options.json && !isFinite(message.gain) ? String(message.gain) : message.gain;
                if (message.comboId != null && message.hasOwnProperty("comboId"))
                    object.comboId = message.comboId;
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    object.commentary = message.commentary;
                if (message.txId != null && message.hasOwnProperty("txId"))
                    object.txId = message.txId;
                if (message.costBasis != null && message.hasOwnProperty("costBasis"))
                    object.costBasis = options.json && !isFinite(message.costBasis) ? String(message.costBasis) : message.costBasis;
                if (message.marketValue != null && message.hasOwnProperty("marketValue"))
                    object.marketValue = options.json && !isFinite(message.marketValue) ? String(message.marketValue) : message.marketValue;
                if (message.tradeCount != null && message.hasOwnProperty("tradeCount"))
                    object.tradeCount = message.tradeCount;
                if (message.initialQuantity != null && message.hasOwnProperty("initialQuantity"))
                    object.initialQuantity = options.json && !isFinite(message.initialQuantity) ? String(message.initialQuantity) : message.initialQuantity;
                if (message.entryTxId != null && message.hasOwnProperty("entryTxId"))
                    object.entryTxId = message.entryTxId;
                if (message.entryPrice != null && message.hasOwnProperty("entryPrice"))
                    object.entryPrice = options.json && !isFinite(message.entryPrice) ? String(message.entryPrice) : message.entryPrice;
                if (message.exitPrice != null && message.hasOwnProperty("exitPrice"))
                    object.exitPrice = options.json && !isFinite(message.exitPrice) ? String(message.exitPrice) : message.exitPrice;
                if (message.gainPercent != null && message.hasOwnProperty("gainPercent"))
                    object.gainPercent = options.json && !isFinite(message.gainPercent) ? String(message.gainPercent) : message.gainPercent;
                return object;
            };

            /**
             * Converts this Position to JSON.
             * @function toJSON
             * @memberof mts.portfolio.Position
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Position.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Position
             * @function getTypeUrl
             * @memberof mts.portfolio.Position
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Position.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.Position";
            };

            return Position;
        })();

        portfolio.PerformanceResponse = (function() {

            /**
             * Properties of a PerformanceResponse.
             * @memberof mts.portfolio
             * @interface IPerformanceResponse
             * @property {string|null} [benchmarkSymbol] PerformanceResponse benchmarkSymbol
             * @property {string|null} [riskFreeSymbol] PerformanceResponse riskFreeSymbol
             * @property {mts.portfolio.IPortfolio|null} [folio] PerformanceResponse folio
             * @property {Array.<mts.portfolio.PerformanceResponse.IDay>|null} [days] PerformanceResponse days
             */

            /**
             * Constructs a new PerformanceResponse.
             * @memberof mts.portfolio
             * @classdesc Represents a PerformanceResponse.
             * @implements IPerformanceResponse
             * @constructor
             * @param {mts.portfolio.IPerformanceResponse=} [properties] Properties to set
             */
            function PerformanceResponse(properties) {
                this.days = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PerformanceResponse benchmarkSymbol.
             * @member {string} benchmarkSymbol
             * @memberof mts.portfolio.PerformanceResponse
             * @instance
             */
            PerformanceResponse.prototype.benchmarkSymbol = "";

            /**
             * PerformanceResponse riskFreeSymbol.
             * @member {string} riskFreeSymbol
             * @memberof mts.portfolio.PerformanceResponse
             * @instance
             */
            PerformanceResponse.prototype.riskFreeSymbol = "";

            /**
             * PerformanceResponse folio.
             * @member {mts.portfolio.IPortfolio|null|undefined} folio
             * @memberof mts.portfolio.PerformanceResponse
             * @instance
             */
            PerformanceResponse.prototype.folio = null;

            /**
             * PerformanceResponse days.
             * @member {Array.<mts.portfolio.PerformanceResponse.IDay>} days
             * @memberof mts.portfolio.PerformanceResponse
             * @instance
             */
            PerformanceResponse.prototype.days = $util.emptyArray;

            /**
             * Creates a new PerformanceResponse instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {mts.portfolio.IPerformanceResponse=} [properties] Properties to set
             * @returns {mts.portfolio.PerformanceResponse} PerformanceResponse instance
             */
            PerformanceResponse.create = function create(properties) {
                return new PerformanceResponse(properties);
            };

            /**
             * Encodes the specified PerformanceResponse message. Does not implicitly {@link mts.portfolio.PerformanceResponse.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {mts.portfolio.IPerformanceResponse} message PerformanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PerformanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.benchmarkSymbol != null && Object.hasOwnProperty.call(message, "benchmarkSymbol"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.benchmarkSymbol);
                if (message.riskFreeSymbol != null && Object.hasOwnProperty.call(message, "riskFreeSymbol"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.riskFreeSymbol);
                if (message.folio != null && Object.hasOwnProperty.call(message, "folio"))
                    $root.mts.portfolio.Portfolio.encode(message.folio, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.days != null && message.days.length)
                    for (let i = 0; i < message.days.length; ++i)
                        $root.mts.portfolio.PerformanceResponse.Day.encode(message.days[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PerformanceResponse message, length delimited. Does not implicitly {@link mts.portfolio.PerformanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {mts.portfolio.IPerformanceResponse} message PerformanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PerformanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PerformanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.PerformanceResponse} PerformanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PerformanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.PerformanceResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.benchmarkSymbol = reader.string();
                            break;
                        }
                    case 2: {
                            message.riskFreeSymbol = reader.string();
                            break;
                        }
                    case 3: {
                            message.folio = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            if (!(message.days && message.days.length))
                                message.days = [];
                            message.days.push($root.mts.portfolio.PerformanceResponse.Day.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PerformanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.PerformanceResponse} PerformanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PerformanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PerformanceResponse message.
             * @function verify
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PerformanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.benchmarkSymbol != null && message.hasOwnProperty("benchmarkSymbol"))
                    if (!$util.isString(message.benchmarkSymbol))
                        return "benchmarkSymbol: string expected";
                if (message.riskFreeSymbol != null && message.hasOwnProperty("riskFreeSymbol"))
                    if (!$util.isString(message.riskFreeSymbol))
                        return "riskFreeSymbol: string expected";
                if (message.folio != null && message.hasOwnProperty("folio")) {
                    let error = $root.mts.portfolio.Portfolio.verify(message.folio);
                    if (error)
                        return "folio." + error;
                }
                if (message.days != null && message.hasOwnProperty("days")) {
                    if (!Array.isArray(message.days))
                        return "days: array expected";
                    for (let i = 0; i < message.days.length; ++i) {
                        let error = $root.mts.portfolio.PerformanceResponse.Day.verify(message.days[i]);
                        if (error)
                            return "days." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PerformanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.PerformanceResponse} PerformanceResponse
             */
            PerformanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.PerformanceResponse)
                    return object;
                let message = new $root.mts.portfolio.PerformanceResponse();
                if (object.benchmarkSymbol != null)
                    message.benchmarkSymbol = String(object.benchmarkSymbol);
                if (object.riskFreeSymbol != null)
                    message.riskFreeSymbol = String(object.riskFreeSymbol);
                if (object.folio != null) {
                    if (typeof object.folio !== "object")
                        throw TypeError(".mts.portfolio.PerformanceResponse.folio: object expected");
                    message.folio = $root.mts.portfolio.Portfolio.fromObject(object.folio);
                }
                if (object.days) {
                    if (!Array.isArray(object.days))
                        throw TypeError(".mts.portfolio.PerformanceResponse.days: array expected");
                    message.days = [];
                    for (let i = 0; i < object.days.length; ++i) {
                        if (typeof object.days[i] !== "object")
                            throw TypeError(".mts.portfolio.PerformanceResponse.days: object expected");
                        message.days[i] = $root.mts.portfolio.PerformanceResponse.Day.fromObject(object.days[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PerformanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {mts.portfolio.PerformanceResponse} message PerformanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PerformanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.days = [];
                if (options.defaults) {
                    object.benchmarkSymbol = "";
                    object.riskFreeSymbol = "";
                    object.folio = null;
                }
                if (message.benchmarkSymbol != null && message.hasOwnProperty("benchmarkSymbol"))
                    object.benchmarkSymbol = message.benchmarkSymbol;
                if (message.riskFreeSymbol != null && message.hasOwnProperty("riskFreeSymbol"))
                    object.riskFreeSymbol = message.riskFreeSymbol;
                if (message.folio != null && message.hasOwnProperty("folio"))
                    object.folio = $root.mts.portfolio.Portfolio.toObject(message.folio, options);
                if (message.days && message.days.length) {
                    object.days = [];
                    for (let j = 0; j < message.days.length; ++j)
                        object.days[j] = $root.mts.portfolio.PerformanceResponse.Day.toObject(message.days[j], options);
                }
                return object;
            };

            /**
             * Converts this PerformanceResponse to JSON.
             * @function toJSON
             * @memberof mts.portfolio.PerformanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PerformanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PerformanceResponse
             * @function getTypeUrl
             * @memberof mts.portfolio.PerformanceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PerformanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.PerformanceResponse";
            };

            PerformanceResponse.Day = (function() {

                /**
                 * Properties of a Day.
                 * @memberof mts.portfolio.PerformanceResponse
                 * @interface IDay
                 * @property {string|null} [date] Day date
                 * @property {number|null} [folioReturn] Day folioReturn
                 * @property {number|null} [benchmarkReturn] Day benchmarkReturn
                 * @property {boolean|null} [holiday] Day holiday
                 * @property {number|null} [riskfreeReturn] Day riskfreeReturn
                 * @property {number|null} [cash] Day cash
                 * @property {number|null} [totalValue] Day totalValue
                 */

                /**
                 * Constructs a new Day.
                 * @memberof mts.portfolio.PerformanceResponse
                 * @classdesc Represents a Day.
                 * @implements IDay
                 * @constructor
                 * @param {mts.portfolio.PerformanceResponse.IDay=} [properties] Properties to set
                 */
                function Day(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Day date.
                 * @member {string} date
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 */
                Day.prototype.date = "";

                /**
                 * Day folioReturn.
                 * @member {number} folioReturn
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 */
                Day.prototype.folioReturn = 0;

                /**
                 * Day benchmarkReturn.
                 * @member {number} benchmarkReturn
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 */
                Day.prototype.benchmarkReturn = 0;

                /**
                 * Day holiday.
                 * @member {boolean} holiday
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 */
                Day.prototype.holiday = false;

                /**
                 * Day riskfreeReturn.
                 * @member {number} riskfreeReturn
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 */
                Day.prototype.riskfreeReturn = 0;

                /**
                 * Day cash.
                 * @member {number} cash
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 */
                Day.prototype.cash = 0;

                /**
                 * Day totalValue.
                 * @member {number} totalValue
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 */
                Day.prototype.totalValue = 0;

                /**
                 * Creates a new Day instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {mts.portfolio.PerformanceResponse.IDay=} [properties] Properties to set
                 * @returns {mts.portfolio.PerformanceResponse.Day} Day instance
                 */
                Day.create = function create(properties) {
                    return new Day(properties);
                };

                /**
                 * Encodes the specified Day message. Does not implicitly {@link mts.portfolio.PerformanceResponse.Day.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {mts.portfolio.PerformanceResponse.IDay} message Day message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Day.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.date);
                    if (message.folioReturn != null && Object.hasOwnProperty.call(message, "folioReturn"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.folioReturn);
                    if (message.benchmarkReturn != null && Object.hasOwnProperty.call(message, "benchmarkReturn"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.benchmarkReturn);
                    if (message.holiday != null && Object.hasOwnProperty.call(message, "holiday"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.holiday);
                    if (message.riskfreeReturn != null && Object.hasOwnProperty.call(message, "riskfreeReturn"))
                        writer.uint32(/* id 5, wireType 1 =*/41).double(message.riskfreeReturn);
                    if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.cash);
                    if (message.totalValue != null && Object.hasOwnProperty.call(message, "totalValue"))
                        writer.uint32(/* id 7, wireType 1 =*/57).double(message.totalValue);
                    return writer;
                };

                /**
                 * Encodes the specified Day message, length delimited. Does not implicitly {@link mts.portfolio.PerformanceResponse.Day.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {mts.portfolio.PerformanceResponse.IDay} message Day message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Day.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Day message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.PerformanceResponse.Day} Day
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Day.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.PerformanceResponse.Day();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.date = reader.string();
                                break;
                            }
                        case 2: {
                                message.folioReturn = reader.double();
                                break;
                            }
                        case 3: {
                                message.benchmarkReturn = reader.double();
                                break;
                            }
                        case 4: {
                                message.holiday = reader.bool();
                                break;
                            }
                        case 5: {
                                message.riskfreeReturn = reader.double();
                                break;
                            }
                        case 6: {
                                message.cash = reader.double();
                                break;
                            }
                        case 7: {
                                message.totalValue = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Day message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.PerformanceResponse.Day} Day
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Day.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Day message.
                 * @function verify
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Day.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    if (message.folioReturn != null && message.hasOwnProperty("folioReturn"))
                        if (typeof message.folioReturn !== "number")
                            return "folioReturn: number expected";
                    if (message.benchmarkReturn != null && message.hasOwnProperty("benchmarkReturn"))
                        if (typeof message.benchmarkReturn !== "number")
                            return "benchmarkReturn: number expected";
                    if (message.holiday != null && message.hasOwnProperty("holiday"))
                        if (typeof message.holiday !== "boolean")
                            return "holiday: boolean expected";
                    if (message.riskfreeReturn != null && message.hasOwnProperty("riskfreeReturn"))
                        if (typeof message.riskfreeReturn !== "number")
                            return "riskfreeReturn: number expected";
                    if (message.cash != null && message.hasOwnProperty("cash"))
                        if (typeof message.cash !== "number")
                            return "cash: number expected";
                    if (message.totalValue != null && message.hasOwnProperty("totalValue"))
                        if (typeof message.totalValue !== "number")
                            return "totalValue: number expected";
                    return null;
                };

                /**
                 * Creates a Day message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.PerformanceResponse.Day} Day
                 */
                Day.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.PerformanceResponse.Day)
                        return object;
                    let message = new $root.mts.portfolio.PerformanceResponse.Day();
                    if (object.date != null)
                        message.date = String(object.date);
                    if (object.folioReturn != null)
                        message.folioReturn = Number(object.folioReturn);
                    if (object.benchmarkReturn != null)
                        message.benchmarkReturn = Number(object.benchmarkReturn);
                    if (object.holiday != null)
                        message.holiday = Boolean(object.holiday);
                    if (object.riskfreeReturn != null)
                        message.riskfreeReturn = Number(object.riskfreeReturn);
                    if (object.cash != null)
                        message.cash = Number(object.cash);
                    if (object.totalValue != null)
                        message.totalValue = Number(object.totalValue);
                    return message;
                };

                /**
                 * Creates a plain object from a Day message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {mts.portfolio.PerformanceResponse.Day} message Day
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Day.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.date = "";
                        object.folioReturn = 0;
                        object.benchmarkReturn = 0;
                        object.holiday = false;
                        object.riskfreeReturn = 0;
                        object.cash = 0;
                        object.totalValue = 0;
                    }
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    if (message.folioReturn != null && message.hasOwnProperty("folioReturn"))
                        object.folioReturn = options.json && !isFinite(message.folioReturn) ? String(message.folioReturn) : message.folioReturn;
                    if (message.benchmarkReturn != null && message.hasOwnProperty("benchmarkReturn"))
                        object.benchmarkReturn = options.json && !isFinite(message.benchmarkReturn) ? String(message.benchmarkReturn) : message.benchmarkReturn;
                    if (message.holiday != null && message.hasOwnProperty("holiday"))
                        object.holiday = message.holiday;
                    if (message.riskfreeReturn != null && message.hasOwnProperty("riskfreeReturn"))
                        object.riskfreeReturn = options.json && !isFinite(message.riskfreeReturn) ? String(message.riskfreeReturn) : message.riskfreeReturn;
                    if (message.cash != null && message.hasOwnProperty("cash"))
                        object.cash = options.json && !isFinite(message.cash) ? String(message.cash) : message.cash;
                    if (message.totalValue != null && message.hasOwnProperty("totalValue"))
                        object.totalValue = options.json && !isFinite(message.totalValue) ? String(message.totalValue) : message.totalValue;
                    return object;
                };

                /**
                 * Converts this Day to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Day.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Day
                 * @function getTypeUrl
                 * @memberof mts.portfolio.PerformanceResponse.Day
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Day.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.PerformanceResponse.Day";
                };

                return Day;
            })();

            return PerformanceResponse;
        })();

        portfolio.ProfitLossByMonth = (function() {

            /**
             * Properties of a ProfitLossByMonth.
             * @memberof mts.portfolio
             * @interface IProfitLossByMonth
             * @property {Array.<mts.portfolio.ProfitLossByMonth.IMonth>|null} [months] ProfitLossByMonth months
             * @property {number|null} [maxDrawdown] ProfitLossByMonth maxDrawdown
             * @property {number|null} [maxDrawdownPercent] ProfitLossByMonth maxDrawdownPercent
             * @property {number|null} [winPercentage] ProfitLossByMonth winPercentage
             */

            /**
             * Constructs a new ProfitLossByMonth.
             * @memberof mts.portfolio
             * @classdesc Represents a ProfitLossByMonth.
             * @implements IProfitLossByMonth
             * @constructor
             * @param {mts.portfolio.IProfitLossByMonth=} [properties] Properties to set
             */
            function ProfitLossByMonth(properties) {
                this.months = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ProfitLossByMonth months.
             * @member {Array.<mts.portfolio.ProfitLossByMonth.IMonth>} months
             * @memberof mts.portfolio.ProfitLossByMonth
             * @instance
             */
            ProfitLossByMonth.prototype.months = $util.emptyArray;

            /**
             * ProfitLossByMonth maxDrawdown.
             * @member {number} maxDrawdown
             * @memberof mts.portfolio.ProfitLossByMonth
             * @instance
             */
            ProfitLossByMonth.prototype.maxDrawdown = 0;

            /**
             * ProfitLossByMonth maxDrawdownPercent.
             * @member {number} maxDrawdownPercent
             * @memberof mts.portfolio.ProfitLossByMonth
             * @instance
             */
            ProfitLossByMonth.prototype.maxDrawdownPercent = 0;

            /**
             * ProfitLossByMonth winPercentage.
             * @member {number} winPercentage
             * @memberof mts.portfolio.ProfitLossByMonth
             * @instance
             */
            ProfitLossByMonth.prototype.winPercentage = 0;

            /**
             * Creates a new ProfitLossByMonth instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {mts.portfolio.IProfitLossByMonth=} [properties] Properties to set
             * @returns {mts.portfolio.ProfitLossByMonth} ProfitLossByMonth instance
             */
            ProfitLossByMonth.create = function create(properties) {
                return new ProfitLossByMonth(properties);
            };

            /**
             * Encodes the specified ProfitLossByMonth message. Does not implicitly {@link mts.portfolio.ProfitLossByMonth.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {mts.portfolio.IProfitLossByMonth} message ProfitLossByMonth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProfitLossByMonth.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.months != null && message.months.length)
                    for (let i = 0; i < message.months.length; ++i)
                        $root.mts.portfolio.ProfitLossByMonth.Month.encode(message.months[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.maxDrawdown != null && Object.hasOwnProperty.call(message, "maxDrawdown"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.maxDrawdown);
                if (message.maxDrawdownPercent != null && Object.hasOwnProperty.call(message, "maxDrawdownPercent"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.maxDrawdownPercent);
                if (message.winPercentage != null && Object.hasOwnProperty.call(message, "winPercentage"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.winPercentage);
                return writer;
            };

            /**
             * Encodes the specified ProfitLossByMonth message, length delimited. Does not implicitly {@link mts.portfolio.ProfitLossByMonth.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {mts.portfolio.IProfitLossByMonth} message ProfitLossByMonth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProfitLossByMonth.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ProfitLossByMonth message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.ProfitLossByMonth} ProfitLossByMonth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProfitLossByMonth.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.ProfitLossByMonth();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.months && message.months.length))
                                message.months = [];
                            message.months.push($root.mts.portfolio.ProfitLossByMonth.Month.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.maxDrawdown = reader.double();
                            break;
                        }
                    case 3: {
                            message.maxDrawdownPercent = reader.double();
                            break;
                        }
                    case 4: {
                            message.winPercentage = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ProfitLossByMonth message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.ProfitLossByMonth} ProfitLossByMonth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProfitLossByMonth.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ProfitLossByMonth message.
             * @function verify
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProfitLossByMonth.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.months != null && message.hasOwnProperty("months")) {
                    if (!Array.isArray(message.months))
                        return "months: array expected";
                    for (let i = 0; i < message.months.length; ++i) {
                        let error = $root.mts.portfolio.ProfitLossByMonth.Month.verify(message.months[i]);
                        if (error)
                            return "months." + error;
                    }
                }
                if (message.maxDrawdown != null && message.hasOwnProperty("maxDrawdown"))
                    if (typeof message.maxDrawdown !== "number")
                        return "maxDrawdown: number expected";
                if (message.maxDrawdownPercent != null && message.hasOwnProperty("maxDrawdownPercent"))
                    if (typeof message.maxDrawdownPercent !== "number")
                        return "maxDrawdownPercent: number expected";
                if (message.winPercentage != null && message.hasOwnProperty("winPercentage"))
                    if (typeof message.winPercentage !== "number")
                        return "winPercentage: number expected";
                return null;
            };

            /**
             * Creates a ProfitLossByMonth message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.ProfitLossByMonth} ProfitLossByMonth
             */
            ProfitLossByMonth.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.ProfitLossByMonth)
                    return object;
                let message = new $root.mts.portfolio.ProfitLossByMonth();
                if (object.months) {
                    if (!Array.isArray(object.months))
                        throw TypeError(".mts.portfolio.ProfitLossByMonth.months: array expected");
                    message.months = [];
                    for (let i = 0; i < object.months.length; ++i) {
                        if (typeof object.months[i] !== "object")
                            throw TypeError(".mts.portfolio.ProfitLossByMonth.months: object expected");
                        message.months[i] = $root.mts.portfolio.ProfitLossByMonth.Month.fromObject(object.months[i]);
                    }
                }
                if (object.maxDrawdown != null)
                    message.maxDrawdown = Number(object.maxDrawdown);
                if (object.maxDrawdownPercent != null)
                    message.maxDrawdownPercent = Number(object.maxDrawdownPercent);
                if (object.winPercentage != null)
                    message.winPercentage = Number(object.winPercentage);
                return message;
            };

            /**
             * Creates a plain object from a ProfitLossByMonth message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {mts.portfolio.ProfitLossByMonth} message ProfitLossByMonth
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProfitLossByMonth.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.months = [];
                if (options.defaults) {
                    object.maxDrawdown = 0;
                    object.maxDrawdownPercent = 0;
                    object.winPercentage = 0;
                }
                if (message.months && message.months.length) {
                    object.months = [];
                    for (let j = 0; j < message.months.length; ++j)
                        object.months[j] = $root.mts.portfolio.ProfitLossByMonth.Month.toObject(message.months[j], options);
                }
                if (message.maxDrawdown != null && message.hasOwnProperty("maxDrawdown"))
                    object.maxDrawdown = options.json && !isFinite(message.maxDrawdown) ? String(message.maxDrawdown) : message.maxDrawdown;
                if (message.maxDrawdownPercent != null && message.hasOwnProperty("maxDrawdownPercent"))
                    object.maxDrawdownPercent = options.json && !isFinite(message.maxDrawdownPercent) ? String(message.maxDrawdownPercent) : message.maxDrawdownPercent;
                if (message.winPercentage != null && message.hasOwnProperty("winPercentage"))
                    object.winPercentage = options.json && !isFinite(message.winPercentage) ? String(message.winPercentage) : message.winPercentage;
                return object;
            };

            /**
             * Converts this ProfitLossByMonth to JSON.
             * @function toJSON
             * @memberof mts.portfolio.ProfitLossByMonth
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProfitLossByMonth.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ProfitLossByMonth
             * @function getTypeUrl
             * @memberof mts.portfolio.ProfitLossByMonth
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProfitLossByMonth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.ProfitLossByMonth";
            };

            ProfitLossByMonth.Month = (function() {

                /**
                 * Properties of a Month.
                 * @memberof mts.portfolio.ProfitLossByMonth
                 * @interface IMonth
                 * @property {string|null} [date] Month date
                 * @property {number|null} [gain] Month gain
                 */

                /**
                 * Constructs a new Month.
                 * @memberof mts.portfolio.ProfitLossByMonth
                 * @classdesc Represents a Month.
                 * @implements IMonth
                 * @constructor
                 * @param {mts.portfolio.ProfitLossByMonth.IMonth=} [properties] Properties to set
                 */
                function Month(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Month date.
                 * @member {string} date
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @instance
                 */
                Month.prototype.date = "";

                /**
                 * Month gain.
                 * @member {number} gain
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @instance
                 */
                Month.prototype.gain = 0;

                /**
                 * Creates a new Month instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {mts.portfolio.ProfitLossByMonth.IMonth=} [properties] Properties to set
                 * @returns {mts.portfolio.ProfitLossByMonth.Month} Month instance
                 */
                Month.create = function create(properties) {
                    return new Month(properties);
                };

                /**
                 * Encodes the specified Month message. Does not implicitly {@link mts.portfolio.ProfitLossByMonth.Month.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {mts.portfolio.ProfitLossByMonth.IMonth} message Month message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Month.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.date);
                    if (message.gain != null && Object.hasOwnProperty.call(message, "gain"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.gain);
                    return writer;
                };

                /**
                 * Encodes the specified Month message, length delimited. Does not implicitly {@link mts.portfolio.ProfitLossByMonth.Month.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {mts.portfolio.ProfitLossByMonth.IMonth} message Month message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Month.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Month message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.ProfitLossByMonth.Month} Month
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Month.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.ProfitLossByMonth.Month();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.date = reader.string();
                                break;
                            }
                        case 2: {
                                message.gain = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Month message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.ProfitLossByMonth.Month} Month
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Month.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Month message.
                 * @function verify
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Month.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    if (message.gain != null && message.hasOwnProperty("gain"))
                        if (typeof message.gain !== "number")
                            return "gain: number expected";
                    return null;
                };

                /**
                 * Creates a Month message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.ProfitLossByMonth.Month} Month
                 */
                Month.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.ProfitLossByMonth.Month)
                        return object;
                    let message = new $root.mts.portfolio.ProfitLossByMonth.Month();
                    if (object.date != null)
                        message.date = String(object.date);
                    if (object.gain != null)
                        message.gain = Number(object.gain);
                    return message;
                };

                /**
                 * Creates a plain object from a Month message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {mts.portfolio.ProfitLossByMonth.Month} message Month
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Month.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.date = "";
                        object.gain = 0;
                    }
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    if (message.gain != null && message.hasOwnProperty("gain"))
                        object.gain = options.json && !isFinite(message.gain) ? String(message.gain) : message.gain;
                    return object;
                };

                /**
                 * Converts this Month to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Month.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Month
                 * @function getTypeUrl
                 * @memberof mts.portfolio.ProfitLossByMonth.Month
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Month.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.ProfitLossByMonth.Month";
                };

                return Month;
            })();

            return ProfitLossByMonth;
        })();

        portfolio.PositionSummary = (function() {

            /**
             * Properties of a PositionSummary.
             * @memberof mts.portfolio
             * @interface IPositionSummary
             * @property {mts.portfolio.PositionSummary.Type|null} [type] PositionSummary type
             * @property {Array.<mts.portfolio.IPosition>|null} [positions] PositionSummary positions
             */

            /**
             * Constructs a new PositionSummary.
             * @memberof mts.portfolio
             * @classdesc Represents a PositionSummary.
             * @implements IPositionSummary
             * @constructor
             * @param {mts.portfolio.IPositionSummary=} [properties] Properties to set
             */
            function PositionSummary(properties) {
                this.positions = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PositionSummary type.
             * @member {mts.portfolio.PositionSummary.Type} type
             * @memberof mts.portfolio.PositionSummary
             * @instance
             */
            PositionSummary.prototype.type = 0;

            /**
             * PositionSummary positions.
             * @member {Array.<mts.portfolio.IPosition>} positions
             * @memberof mts.portfolio.PositionSummary
             * @instance
             */
            PositionSummary.prototype.positions = $util.emptyArray;

            /**
             * Creates a new PositionSummary instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {mts.portfolio.IPositionSummary=} [properties] Properties to set
             * @returns {mts.portfolio.PositionSummary} PositionSummary instance
             */
            PositionSummary.create = function create(properties) {
                return new PositionSummary(properties);
            };

            /**
             * Encodes the specified PositionSummary message. Does not implicitly {@link mts.portfolio.PositionSummary.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {mts.portfolio.IPositionSummary} message PositionSummary message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PositionSummary.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.positions != null && message.positions.length)
                    for (let i = 0; i < message.positions.length; ++i)
                        $root.mts.portfolio.Position.encode(message.positions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PositionSummary message, length delimited. Does not implicitly {@link mts.portfolio.PositionSummary.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {mts.portfolio.IPositionSummary} message PositionSummary message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PositionSummary.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PositionSummary message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.PositionSummary} PositionSummary
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PositionSummary.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.PositionSummary();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type = reader.int32();
                            break;
                        }
                    case 2: {
                            if (!(message.positions && message.positions.length))
                                message.positions = [];
                            message.positions.push($root.mts.portfolio.Position.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PositionSummary message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.PositionSummary} PositionSummary
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PositionSummary.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PositionSummary message.
             * @function verify
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PositionSummary.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.positions != null && message.hasOwnProperty("positions")) {
                    if (!Array.isArray(message.positions))
                        return "positions: array expected";
                    for (let i = 0; i < message.positions.length; ++i) {
                        let error = $root.mts.portfolio.Position.verify(message.positions[i]);
                        if (error)
                            return "positions." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PositionSummary message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.PositionSummary} PositionSummary
             */
            PositionSummary.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.PositionSummary)
                    return object;
                let message = new $root.mts.portfolio.PositionSummary();
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UNKNOWN_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "SINGLE":
                case 1:
                    message.type = 1;
                    break;
                case "SPREAD":
                case 2:
                    message.type = 2;
                    break;
                }
                if (object.positions) {
                    if (!Array.isArray(object.positions))
                        throw TypeError(".mts.portfolio.PositionSummary.positions: array expected");
                    message.positions = [];
                    for (let i = 0; i < object.positions.length; ++i) {
                        if (typeof object.positions[i] !== "object")
                            throw TypeError(".mts.portfolio.PositionSummary.positions: object expected");
                        message.positions[i] = $root.mts.portfolio.Position.fromObject(object.positions[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PositionSummary message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {mts.portfolio.PositionSummary} message PositionSummary
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PositionSummary.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.positions = [];
                if (options.defaults)
                    object.type = options.enums === String ? "UNKNOWN_TYPE" : 0;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.portfolio.PositionSummary.Type[message.type] === undefined ? message.type : $root.mts.portfolio.PositionSummary.Type[message.type] : message.type;
                if (message.positions && message.positions.length) {
                    object.positions = [];
                    for (let j = 0; j < message.positions.length; ++j)
                        object.positions[j] = $root.mts.portfolio.Position.toObject(message.positions[j], options);
                }
                return object;
            };

            /**
             * Converts this PositionSummary to JSON.
             * @function toJSON
             * @memberof mts.portfolio.PositionSummary
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PositionSummary.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PositionSummary
             * @function getTypeUrl
             * @memberof mts.portfolio.PositionSummary
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PositionSummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.PositionSummary";
            };

            /**
             * Type enum.
             * @name mts.portfolio.PositionSummary.Type
             * @enum {number}
             * @property {number} UNKNOWN_TYPE=0 UNKNOWN_TYPE value
             * @property {number} SINGLE=1 SINGLE value
             * @property {number} SPREAD=2 SPREAD value
             */
            PositionSummary.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_TYPE"] = 0;
                values[valuesById[1] = "SINGLE"] = 1;
                values[valuesById[2] = "SPREAD"] = 2;
                return values;
            })();

            return PositionSummary;
        })();

        portfolio.AnnualReturns = (function() {

            /**
             * Properties of an AnnualReturns.
             * @memberof mts.portfolio
             * @interface IAnnualReturns
             * @property {Array.<mts.portfolio.AnnualReturns.IYear>|null} [years] AnnualReturns years
             */

            /**
             * Constructs a new AnnualReturns.
             * @memberof mts.portfolio
             * @classdesc Represents an AnnualReturns.
             * @implements IAnnualReturns
             * @constructor
             * @param {mts.portfolio.IAnnualReturns=} [properties] Properties to set
             */
            function AnnualReturns(properties) {
                this.years = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AnnualReturns years.
             * @member {Array.<mts.portfolio.AnnualReturns.IYear>} years
             * @memberof mts.portfolio.AnnualReturns
             * @instance
             */
            AnnualReturns.prototype.years = $util.emptyArray;

            /**
             * Creates a new AnnualReturns instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {mts.portfolio.IAnnualReturns=} [properties] Properties to set
             * @returns {mts.portfolio.AnnualReturns} AnnualReturns instance
             */
            AnnualReturns.create = function create(properties) {
                return new AnnualReturns(properties);
            };

            /**
             * Encodes the specified AnnualReturns message. Does not implicitly {@link mts.portfolio.AnnualReturns.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {mts.portfolio.IAnnualReturns} message AnnualReturns message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnnualReturns.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.years != null && message.years.length)
                    for (let i = 0; i < message.years.length; ++i)
                        $root.mts.portfolio.AnnualReturns.Year.encode(message.years[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AnnualReturns message, length delimited. Does not implicitly {@link mts.portfolio.AnnualReturns.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {mts.portfolio.IAnnualReturns} message AnnualReturns message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnnualReturns.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AnnualReturns message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.AnnualReturns} AnnualReturns
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnnualReturns.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.AnnualReturns();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.years && message.years.length))
                                message.years = [];
                            message.years.push($root.mts.portfolio.AnnualReturns.Year.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AnnualReturns message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.AnnualReturns} AnnualReturns
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnnualReturns.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AnnualReturns message.
             * @function verify
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AnnualReturns.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.years != null && message.hasOwnProperty("years")) {
                    if (!Array.isArray(message.years))
                        return "years: array expected";
                    for (let i = 0; i < message.years.length; ++i) {
                        let error = $root.mts.portfolio.AnnualReturns.Year.verify(message.years[i]);
                        if (error)
                            return "years." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AnnualReturns message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.AnnualReturns} AnnualReturns
             */
            AnnualReturns.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.AnnualReturns)
                    return object;
                let message = new $root.mts.portfolio.AnnualReturns();
                if (object.years) {
                    if (!Array.isArray(object.years))
                        throw TypeError(".mts.portfolio.AnnualReturns.years: array expected");
                    message.years = [];
                    for (let i = 0; i < object.years.length; ++i) {
                        if (typeof object.years[i] !== "object")
                            throw TypeError(".mts.portfolio.AnnualReturns.years: object expected");
                        message.years[i] = $root.mts.portfolio.AnnualReturns.Year.fromObject(object.years[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AnnualReturns message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {mts.portfolio.AnnualReturns} message AnnualReturns
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AnnualReturns.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.years = [];
                if (message.years && message.years.length) {
                    object.years = [];
                    for (let j = 0; j < message.years.length; ++j)
                        object.years[j] = $root.mts.portfolio.AnnualReturns.Year.toObject(message.years[j], options);
                }
                return object;
            };

            /**
             * Converts this AnnualReturns to JSON.
             * @function toJSON
             * @memberof mts.portfolio.AnnualReturns
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AnnualReturns.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AnnualReturns
             * @function getTypeUrl
             * @memberof mts.portfolio.AnnualReturns
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AnnualReturns.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.AnnualReturns";
            };

            AnnualReturns.Year = (function() {

                /**
                 * Properties of a Year.
                 * @memberof mts.portfolio.AnnualReturns
                 * @interface IYear
                 * @property {number|null} [year] Year year
                 * @property {number|null} [folioPercent] Year folioPercent
                 */

                /**
                 * Constructs a new Year.
                 * @memberof mts.portfolio.AnnualReturns
                 * @classdesc Represents a Year.
                 * @implements IYear
                 * @constructor
                 * @param {mts.portfolio.AnnualReturns.IYear=} [properties] Properties to set
                 */
                function Year(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Year year.
                 * @member {number} year
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @instance
                 */
                Year.prototype.year = 0;

                /**
                 * Year folioPercent.
                 * @member {number} folioPercent
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @instance
                 */
                Year.prototype.folioPercent = 0;

                /**
                 * Creates a new Year instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {mts.portfolio.AnnualReturns.IYear=} [properties] Properties to set
                 * @returns {mts.portfolio.AnnualReturns.Year} Year instance
                 */
                Year.create = function create(properties) {
                    return new Year(properties);
                };

                /**
                 * Encodes the specified Year message. Does not implicitly {@link mts.portfolio.AnnualReturns.Year.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {mts.portfolio.AnnualReturns.IYear} message Year message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Year.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                    if (message.folioPercent != null && Object.hasOwnProperty.call(message, "folioPercent"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.folioPercent);
                    return writer;
                };

                /**
                 * Encodes the specified Year message, length delimited. Does not implicitly {@link mts.portfolio.AnnualReturns.Year.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {mts.portfolio.AnnualReturns.IYear} message Year message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Year.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Year message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.AnnualReturns.Year} Year
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Year.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.AnnualReturns.Year();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int32();
                                break;
                            }
                        case 2: {
                                message.folioPercent = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Year message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.AnnualReturns.Year} Year
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Year.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Year message.
                 * @function verify
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Year.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.folioPercent != null && message.hasOwnProperty("folioPercent"))
                        if (typeof message.folioPercent !== "number")
                            return "folioPercent: number expected";
                    return null;
                };

                /**
                 * Creates a Year message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.AnnualReturns.Year} Year
                 */
                Year.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.AnnualReturns.Year)
                        return object;
                    let message = new $root.mts.portfolio.AnnualReturns.Year();
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.folioPercent != null)
                        message.folioPercent = Number(object.folioPercent);
                    return message;
                };

                /**
                 * Creates a plain object from a Year message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {mts.portfolio.AnnualReturns.Year} message Year
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Year.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.year = 0;
                        object.folioPercent = 0;
                    }
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.folioPercent != null && message.hasOwnProperty("folioPercent"))
                        object.folioPercent = options.json && !isFinite(message.folioPercent) ? String(message.folioPercent) : message.folioPercent;
                    return object;
                };

                /**
                 * Converts this Year to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Year.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Year
                 * @function getTypeUrl
                 * @memberof mts.portfolio.AnnualReturns.Year
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Year.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.AnnualReturns.Year";
                };

                return Year;
            })();

            return AnnualReturns;
        })();

        /**
         * TxType enum.
         * @name mts.portfolio.TxType
         * @enum {number}
         * @property {number} UNKNOWN_TX_TYPE=0 UNKNOWN_TX_TYPE value
         * @property {number} DEPOSIT=1 DEPOSIT value
         * @property {number} WITHDRAWL=2 WITHDRAWL value
         * @property {number} SALE=3 SALE value
         * @property {number} DIVIDEND=4 DIVIDEND value
         */
        portfolio.TxType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_TX_TYPE"] = 0;
            values[valuesById[1] = "DEPOSIT"] = 1;
            values[valuesById[2] = "WITHDRAWL"] = 2;
            values[valuesById[3] = "SALE"] = 3;
            values[valuesById[4] = "DIVIDEND"] = 4;
            return values;
        })();

        /**
         * TxSaleType enum.
         * @name mts.portfolio.TxSaleType
         * @enum {number}
         * @property {number} UNKNOWN_SALE_TYPE=0 UNKNOWN_SALE_TYPE value
         * @property {number} BUY=1 BUY value
         * @property {number} SELL=2 SELL value
         * @property {number} SHORT=3 SHORT value
         * @property {number} COVER=4 COVER value
         */
        portfolio.TxSaleType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_SALE_TYPE"] = 0;
            values[valuesById[1] = "BUY"] = 1;
            values[valuesById[2] = "SELL"] = 2;
            values[valuesById[3] = "SHORT"] = 3;
            values[valuesById[4] = "COVER"] = 4;
            return values;
        })();

        portfolio.Tx = (function() {

            /**
             * Properties of a Tx.
             * @memberof mts.portfolio
             * @interface ITx
             * @property {number|null} [id] Tx id
             * @property {number|null} [orderId] Tx orderId
             * @property {number|null} [folioId] Tx folioId
             * @property {mts.portfolio.TxType|null} [type] Tx type
             * @property {number|null} [amount] Tx amount
             * @property {string|null} [date] Tx date
             * @property {string|null} [entryDate] Tx entryDate
             * @property {number|null} [commission] Tx commission
             * @property {number|null} [quantity] Tx quantity
             * @property {string|null} [symbol] Tx symbol
             * @property {number|null} [price] Tx price
             * @property {string|null} [description] Tx description
             * @property {string|null} [commentary] Tx commentary
             * @property {number|null} [costBasis] Tx costBasis
             * @property {number|null} [gain] Tx gain
             * @property {number|null} [gainPercent] Tx gainPercent
             * @property {boolean|null} [isExit] Tx isExit
             * @property {number|null} [cash] Tx cash
             * @property {number|null} [entryPrice] Tx entryPrice
             * @property {number|null} [exitPrice] Tx exitPrice
             * @property {boolean|null} [skipPositionValidation] Tx skipPositionValidation
             * @property {boolean|null} [skipMetricsUpdate] Tx skipMetricsUpdate
             * @property {boolean|null} [skipBuyingPowerCheck] Tx skipBuyingPowerCheck
             * @property {mts.portfolio.TxSaleType|null} [saleType] Tx saleType
             * @property {number|null} [positionId] Tx positionId
             * @property {Object.<string,string>|null} [brokerData] Tx brokerData
             * @property {string|null} [brokerDataJson] Tx brokerDataJson
             * @property {string|null} [brokerExecutionId] Tx brokerExecutionId
             * @property {string|null} [tradeId] Tx tradeId
             * @property {number|null} [profitLoss] Tx profitLoss
             * @property {Object.<string,string>|null} [customData] Tx customData
             */

            /**
             * Constructs a new Tx.
             * @memberof mts.portfolio
             * @classdesc Represents a Tx.
             * @implements ITx
             * @constructor
             * @param {mts.portfolio.ITx=} [properties] Properties to set
             */
            function Tx(properties) {
                this.brokerData = {};
                this.customData = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Tx id.
             * @member {number} id
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.id = 0;

            /**
             * Tx orderId.
             * @member {number} orderId
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.orderId = 0;

            /**
             * Tx folioId.
             * @member {number} folioId
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.folioId = 0;

            /**
             * Tx type.
             * @member {mts.portfolio.TxType} type
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.type = 0;

            /**
             * Tx amount.
             * @member {number} amount
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.amount = 0;

            /**
             * Tx date.
             * @member {string} date
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.date = "";

            /**
             * Tx entryDate.
             * @member {string} entryDate
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.entryDate = "";

            /**
             * Tx commission.
             * @member {number} commission
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.commission = 0;

            /**
             * Tx quantity.
             * @member {number} quantity
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.quantity = 0;

            /**
             * Tx symbol.
             * @member {string} symbol
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.symbol = "";

            /**
             * Tx price.
             * @member {number} price
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.price = 0;

            /**
             * Tx description.
             * @member {string} description
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.description = "";

            /**
             * Tx commentary.
             * @member {string} commentary
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.commentary = "";

            /**
             * Tx costBasis.
             * @member {number} costBasis
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.costBasis = 0;

            /**
             * Tx gain.
             * @member {number} gain
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.gain = 0;

            /**
             * Tx gainPercent.
             * @member {number} gainPercent
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.gainPercent = 0;

            /**
             * Tx isExit.
             * @member {boolean} isExit
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.isExit = false;

            /**
             * Tx cash.
             * @member {number} cash
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.cash = 0;

            /**
             * Tx entryPrice.
             * @member {number} entryPrice
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.entryPrice = 0;

            /**
             * Tx exitPrice.
             * @member {number} exitPrice
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.exitPrice = 0;

            /**
             * Tx skipPositionValidation.
             * @member {boolean} skipPositionValidation
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.skipPositionValidation = false;

            /**
             * Tx skipMetricsUpdate.
             * @member {boolean} skipMetricsUpdate
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.skipMetricsUpdate = false;

            /**
             * Tx skipBuyingPowerCheck.
             * @member {boolean} skipBuyingPowerCheck
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.skipBuyingPowerCheck = false;

            /**
             * Tx saleType.
             * @member {mts.portfolio.TxSaleType} saleType
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.saleType = 0;

            /**
             * Tx positionId.
             * @member {number} positionId
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.positionId = 0;

            /**
             * Tx brokerData.
             * @member {Object.<string,string>} brokerData
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.brokerData = $util.emptyObject;

            /**
             * Tx brokerDataJson.
             * @member {string} brokerDataJson
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.brokerDataJson = "";

            /**
             * Tx brokerExecutionId.
             * @member {string} brokerExecutionId
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.brokerExecutionId = "";

            /**
             * Tx tradeId.
             * @member {string} tradeId
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.tradeId = "";

            /**
             * Tx profitLoss.
             * @member {number} profitLoss
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.profitLoss = 0;

            /**
             * Tx customData.
             * @member {Object.<string,string>} customData
             * @memberof mts.portfolio.Tx
             * @instance
             */
            Tx.prototype.customData = $util.emptyObject;

            /**
             * Creates a new Tx instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.Tx
             * @static
             * @param {mts.portfolio.ITx=} [properties] Properties to set
             * @returns {mts.portfolio.Tx} Tx instance
             */
            Tx.create = function create(properties) {
                return new Tx(properties);
            };

            /**
             * Encodes the specified Tx message. Does not implicitly {@link mts.portfolio.Tx.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.Tx
             * @static
             * @param {mts.portfolio.ITx} message Tx message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tx.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderId);
                if (message.folioId != null && Object.hasOwnProperty.call(message, "folioId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.folioId);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.amount);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.date);
                if (message.entryDate != null && Object.hasOwnProperty.call(message, "entryDate"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.entryDate);
                if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
                    writer.uint32(/* id 9, wireType 1 =*/73).double(message.commission);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 10, wireType 1 =*/81).double(message.quantity);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.symbol);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 12, wireType 1 =*/97).double(message.price);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.description);
                if (message.commentary != null && Object.hasOwnProperty.call(message, "commentary"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.commentary);
                if (message.costBasis != null && Object.hasOwnProperty.call(message, "costBasis"))
                    writer.uint32(/* id 15, wireType 1 =*/121).double(message.costBasis);
                if (message.gain != null && Object.hasOwnProperty.call(message, "gain"))
                    writer.uint32(/* id 16, wireType 1 =*/129).double(message.gain);
                if (message.gainPercent != null && Object.hasOwnProperty.call(message, "gainPercent"))
                    writer.uint32(/* id 17, wireType 1 =*/137).double(message.gainPercent);
                if (message.isExit != null && Object.hasOwnProperty.call(message, "isExit"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isExit);
                if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
                    writer.uint32(/* id 19, wireType 1 =*/153).double(message.cash);
                if (message.entryPrice != null && Object.hasOwnProperty.call(message, "entryPrice"))
                    writer.uint32(/* id 20, wireType 1 =*/161).double(message.entryPrice);
                if (message.exitPrice != null && Object.hasOwnProperty.call(message, "exitPrice"))
                    writer.uint32(/* id 21, wireType 1 =*/169).double(message.exitPrice);
                if (message.skipPositionValidation != null && Object.hasOwnProperty.call(message, "skipPositionValidation"))
                    writer.uint32(/* id 22, wireType 0 =*/176).bool(message.skipPositionValidation);
                if (message.skipMetricsUpdate != null && Object.hasOwnProperty.call(message, "skipMetricsUpdate"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.skipMetricsUpdate);
                if (message.skipBuyingPowerCheck != null && Object.hasOwnProperty.call(message, "skipBuyingPowerCheck"))
                    writer.uint32(/* id 24, wireType 0 =*/192).bool(message.skipBuyingPowerCheck);
                if (message.saleType != null && Object.hasOwnProperty.call(message, "saleType"))
                    writer.uint32(/* id 25, wireType 0 =*/200).int32(message.saleType);
                if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                    writer.uint32(/* id 26, wireType 0 =*/208).int32(message.positionId);
                if (message.brokerData != null && Object.hasOwnProperty.call(message, "brokerData"))
                    for (let keys = Object.keys(message.brokerData), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 33, wireType 2 =*/266).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.brokerData[keys[i]]).ldelim();
                if (message.brokerDataJson != null && Object.hasOwnProperty.call(message, "brokerDataJson"))
                    writer.uint32(/* id 34, wireType 2 =*/274).string(message.brokerDataJson);
                if (message.brokerExecutionId != null && Object.hasOwnProperty.call(message, "brokerExecutionId"))
                    writer.uint32(/* id 35, wireType 2 =*/282).string(message.brokerExecutionId);
                if (message.tradeId != null && Object.hasOwnProperty.call(message, "tradeId"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.tradeId);
                if (message.profitLoss != null && Object.hasOwnProperty.call(message, "profitLoss"))
                    writer.uint32(/* id 37, wireType 1 =*/297).double(message.profitLoss);
                if (message.customData != null && Object.hasOwnProperty.call(message, "customData"))
                    for (let keys = Object.keys(message.customData), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 38, wireType 2 =*/306).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.customData[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Tx message, length delimited. Does not implicitly {@link mts.portfolio.Tx.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.Tx
             * @static
             * @param {mts.portfolio.ITx} message Tx message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tx.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Tx message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.Tx
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.Tx} Tx
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tx.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.Tx(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.orderId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.folioId = reader.int32();
                            break;
                        }
                    case 5: {
                            message.type = reader.int32();
                            break;
                        }
                    case 6: {
                            message.amount = reader.double();
                            break;
                        }
                    case 7: {
                            message.date = reader.string();
                            break;
                        }
                    case 8: {
                            message.entryDate = reader.string();
                            break;
                        }
                    case 9: {
                            message.commission = reader.double();
                            break;
                        }
                    case 10: {
                            message.quantity = reader.double();
                            break;
                        }
                    case 11: {
                            message.symbol = reader.string();
                            break;
                        }
                    case 12: {
                            message.price = reader.double();
                            break;
                        }
                    case 13: {
                            message.description = reader.string();
                            break;
                        }
                    case 14: {
                            message.commentary = reader.string();
                            break;
                        }
                    case 15: {
                            message.costBasis = reader.double();
                            break;
                        }
                    case 16: {
                            message.gain = reader.double();
                            break;
                        }
                    case 17: {
                            message.gainPercent = reader.double();
                            break;
                        }
                    case 18: {
                            message.isExit = reader.bool();
                            break;
                        }
                    case 19: {
                            message.cash = reader.double();
                            break;
                        }
                    case 20: {
                            message.entryPrice = reader.double();
                            break;
                        }
                    case 21: {
                            message.exitPrice = reader.double();
                            break;
                        }
                    case 22: {
                            message.skipPositionValidation = reader.bool();
                            break;
                        }
                    case 23: {
                            message.skipMetricsUpdate = reader.bool();
                            break;
                        }
                    case 24: {
                            message.skipBuyingPowerCheck = reader.bool();
                            break;
                        }
                    case 25: {
                            message.saleType = reader.int32();
                            break;
                        }
                    case 26: {
                            message.positionId = reader.int32();
                            break;
                        }
                    case 33: {
                            if (message.brokerData === $util.emptyObject)
                                message.brokerData = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.brokerData[key] = value;
                            break;
                        }
                    case 34: {
                            message.brokerDataJson = reader.string();
                            break;
                        }
                    case 35: {
                            message.brokerExecutionId = reader.string();
                            break;
                        }
                    case 36: {
                            message.tradeId = reader.string();
                            break;
                        }
                    case 37: {
                            message.profitLoss = reader.double();
                            break;
                        }
                    case 38: {
                            if (message.customData === $util.emptyObject)
                                message.customData = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.customData[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Tx message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.Tx
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.Tx} Tx
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tx.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Tx message.
             * @function verify
             * @memberof mts.portfolio.Tx
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Tx.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId))
                        return "orderId: integer expected";
                if (message.folioId != null && message.hasOwnProperty("folioId"))
                    if (!$util.isInteger(message.folioId))
                        return "folioId: integer expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount !== "number")
                        return "amount: number expected";
                if (message.date != null && message.hasOwnProperty("date"))
                    if (!$util.isString(message.date))
                        return "date: string expected";
                if (message.entryDate != null && message.hasOwnProperty("entryDate"))
                    if (!$util.isString(message.entryDate))
                        return "entryDate: string expected";
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (typeof message.commission !== "number")
                        return "commission: number expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (typeof message.quantity !== "number")
                        return "quantity: number expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price !== "number")
                        return "price: number expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    if (!$util.isString(message.commentary))
                        return "commentary: string expected";
                if (message.costBasis != null && message.hasOwnProperty("costBasis"))
                    if (typeof message.costBasis !== "number")
                        return "costBasis: number expected";
                if (message.gain != null && message.hasOwnProperty("gain"))
                    if (typeof message.gain !== "number")
                        return "gain: number expected";
                if (message.gainPercent != null && message.hasOwnProperty("gainPercent"))
                    if (typeof message.gainPercent !== "number")
                        return "gainPercent: number expected";
                if (message.isExit != null && message.hasOwnProperty("isExit"))
                    if (typeof message.isExit !== "boolean")
                        return "isExit: boolean expected";
                if (message.cash != null && message.hasOwnProperty("cash"))
                    if (typeof message.cash !== "number")
                        return "cash: number expected";
                if (message.entryPrice != null && message.hasOwnProperty("entryPrice"))
                    if (typeof message.entryPrice !== "number")
                        return "entryPrice: number expected";
                if (message.exitPrice != null && message.hasOwnProperty("exitPrice"))
                    if (typeof message.exitPrice !== "number")
                        return "exitPrice: number expected";
                if (message.skipPositionValidation != null && message.hasOwnProperty("skipPositionValidation"))
                    if (typeof message.skipPositionValidation !== "boolean")
                        return "skipPositionValidation: boolean expected";
                if (message.skipMetricsUpdate != null && message.hasOwnProperty("skipMetricsUpdate"))
                    if (typeof message.skipMetricsUpdate !== "boolean")
                        return "skipMetricsUpdate: boolean expected";
                if (message.skipBuyingPowerCheck != null && message.hasOwnProperty("skipBuyingPowerCheck"))
                    if (typeof message.skipBuyingPowerCheck !== "boolean")
                        return "skipBuyingPowerCheck: boolean expected";
                if (message.saleType != null && message.hasOwnProperty("saleType"))
                    switch (message.saleType) {
                    default:
                        return "saleType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (!$util.isInteger(message.positionId))
                        return "positionId: integer expected";
                if (message.brokerData != null && message.hasOwnProperty("brokerData")) {
                    if (!$util.isObject(message.brokerData))
                        return "brokerData: object expected";
                    let key = Object.keys(message.brokerData);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.brokerData[key[i]]))
                            return "brokerData: string{k:string} expected";
                }
                if (message.brokerDataJson != null && message.hasOwnProperty("brokerDataJson"))
                    if (!$util.isString(message.brokerDataJson))
                        return "brokerDataJson: string expected";
                if (message.brokerExecutionId != null && message.hasOwnProperty("brokerExecutionId"))
                    if (!$util.isString(message.brokerExecutionId))
                        return "brokerExecutionId: string expected";
                if (message.tradeId != null && message.hasOwnProperty("tradeId"))
                    if (!$util.isString(message.tradeId))
                        return "tradeId: string expected";
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    if (typeof message.profitLoss !== "number")
                        return "profitLoss: number expected";
                if (message.customData != null && message.hasOwnProperty("customData")) {
                    if (!$util.isObject(message.customData))
                        return "customData: object expected";
                    let key = Object.keys(message.customData);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.customData[key[i]]))
                            return "customData: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates a Tx message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.Tx
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.Tx} Tx
             */
            Tx.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.Tx)
                    return object;
                let message = new $root.mts.portfolio.Tx();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.orderId != null)
                    message.orderId = object.orderId | 0;
                if (object.folioId != null)
                    message.folioId = object.folioId | 0;
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "UNKNOWN_TX_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "DEPOSIT":
                case 1:
                    message.type = 1;
                    break;
                case "WITHDRAWL":
                case 2:
                    message.type = 2;
                    break;
                case "SALE":
                case 3:
                    message.type = 3;
                    break;
                case "DIVIDEND":
                case 4:
                    message.type = 4;
                    break;
                }
                if (object.amount != null)
                    message.amount = Number(object.amount);
                if (object.date != null)
                    message.date = String(object.date);
                if (object.entryDate != null)
                    message.entryDate = String(object.entryDate);
                if (object.commission != null)
                    message.commission = Number(object.commission);
                if (object.quantity != null)
                    message.quantity = Number(object.quantity);
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.price != null)
                    message.price = Number(object.price);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.commentary != null)
                    message.commentary = String(object.commentary);
                if (object.costBasis != null)
                    message.costBasis = Number(object.costBasis);
                if (object.gain != null)
                    message.gain = Number(object.gain);
                if (object.gainPercent != null)
                    message.gainPercent = Number(object.gainPercent);
                if (object.isExit != null)
                    message.isExit = Boolean(object.isExit);
                if (object.cash != null)
                    message.cash = Number(object.cash);
                if (object.entryPrice != null)
                    message.entryPrice = Number(object.entryPrice);
                if (object.exitPrice != null)
                    message.exitPrice = Number(object.exitPrice);
                if (object.skipPositionValidation != null)
                    message.skipPositionValidation = Boolean(object.skipPositionValidation);
                if (object.skipMetricsUpdate != null)
                    message.skipMetricsUpdate = Boolean(object.skipMetricsUpdate);
                if (object.skipBuyingPowerCheck != null)
                    message.skipBuyingPowerCheck = Boolean(object.skipBuyingPowerCheck);
                switch (object.saleType) {
                default:
                    if (typeof object.saleType === "number") {
                        message.saleType = object.saleType;
                        break;
                    }
                    break;
                case "UNKNOWN_SALE_TYPE":
                case 0:
                    message.saleType = 0;
                    break;
                case "BUY":
                case 1:
                    message.saleType = 1;
                    break;
                case "SELL":
                case 2:
                    message.saleType = 2;
                    break;
                case "SHORT":
                case 3:
                    message.saleType = 3;
                    break;
                case "COVER":
                case 4:
                    message.saleType = 4;
                    break;
                }
                if (object.positionId != null)
                    message.positionId = object.positionId | 0;
                if (object.brokerData) {
                    if (typeof object.brokerData !== "object")
                        throw TypeError(".mts.portfolio.Tx.brokerData: object expected");
                    message.brokerData = {};
                    for (let keys = Object.keys(object.brokerData), i = 0; i < keys.length; ++i)
                        message.brokerData[keys[i]] = String(object.brokerData[keys[i]]);
                }
                if (object.brokerDataJson != null)
                    message.brokerDataJson = String(object.brokerDataJson);
                if (object.brokerExecutionId != null)
                    message.brokerExecutionId = String(object.brokerExecutionId);
                if (object.tradeId != null)
                    message.tradeId = String(object.tradeId);
                if (object.profitLoss != null)
                    message.profitLoss = Number(object.profitLoss);
                if (object.customData) {
                    if (typeof object.customData !== "object")
                        throw TypeError(".mts.portfolio.Tx.customData: object expected");
                    message.customData = {};
                    for (let keys = Object.keys(object.customData), i = 0; i < keys.length; ++i)
                        message.customData[keys[i]] = String(object.customData[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from a Tx message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.Tx
             * @static
             * @param {mts.portfolio.Tx} message Tx
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Tx.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults) {
                    object.brokerData = {};
                    object.customData = {};
                }
                if (options.defaults) {
                    object.id = 0;
                    object.orderId = 0;
                    object.folioId = 0;
                    object.type = options.enums === String ? "UNKNOWN_TX_TYPE" : 0;
                    object.amount = 0;
                    object.date = "";
                    object.entryDate = "";
                    object.commission = 0;
                    object.quantity = 0;
                    object.symbol = "";
                    object.price = 0;
                    object.description = "";
                    object.commentary = "";
                    object.costBasis = 0;
                    object.gain = 0;
                    object.gainPercent = 0;
                    object.isExit = false;
                    object.cash = 0;
                    object.entryPrice = 0;
                    object.exitPrice = 0;
                    object.skipPositionValidation = false;
                    object.skipMetricsUpdate = false;
                    object.skipBuyingPowerCheck = false;
                    object.saleType = options.enums === String ? "UNKNOWN_SALE_TYPE" : 0;
                    object.positionId = 0;
                    object.brokerDataJson = "";
                    object.brokerExecutionId = "";
                    object.tradeId = "";
                    object.profitLoss = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    object.orderId = message.orderId;
                if (message.folioId != null && message.hasOwnProperty("folioId"))
                    object.folioId = message.folioId;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.mts.portfolio.TxType[message.type] === undefined ? message.type : $root.mts.portfolio.TxType[message.type] : message.type;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = message.date;
                if (message.entryDate != null && message.hasOwnProperty("entryDate"))
                    object.entryDate = message.entryDate;
                if (message.commission != null && message.hasOwnProperty("commission"))
                    object.commission = options.json && !isFinite(message.commission) ? String(message.commission) : message.commission;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = options.json && !isFinite(message.quantity) ? String(message.quantity) : message.quantity;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.commentary != null && message.hasOwnProperty("commentary"))
                    object.commentary = message.commentary;
                if (message.costBasis != null && message.hasOwnProperty("costBasis"))
                    object.costBasis = options.json && !isFinite(message.costBasis) ? String(message.costBasis) : message.costBasis;
                if (message.gain != null && message.hasOwnProperty("gain"))
                    object.gain = options.json && !isFinite(message.gain) ? String(message.gain) : message.gain;
                if (message.gainPercent != null && message.hasOwnProperty("gainPercent"))
                    object.gainPercent = options.json && !isFinite(message.gainPercent) ? String(message.gainPercent) : message.gainPercent;
                if (message.isExit != null && message.hasOwnProperty("isExit"))
                    object.isExit = message.isExit;
                if (message.cash != null && message.hasOwnProperty("cash"))
                    object.cash = options.json && !isFinite(message.cash) ? String(message.cash) : message.cash;
                if (message.entryPrice != null && message.hasOwnProperty("entryPrice"))
                    object.entryPrice = options.json && !isFinite(message.entryPrice) ? String(message.entryPrice) : message.entryPrice;
                if (message.exitPrice != null && message.hasOwnProperty("exitPrice"))
                    object.exitPrice = options.json && !isFinite(message.exitPrice) ? String(message.exitPrice) : message.exitPrice;
                if (message.skipPositionValidation != null && message.hasOwnProperty("skipPositionValidation"))
                    object.skipPositionValidation = message.skipPositionValidation;
                if (message.skipMetricsUpdate != null && message.hasOwnProperty("skipMetricsUpdate"))
                    object.skipMetricsUpdate = message.skipMetricsUpdate;
                if (message.skipBuyingPowerCheck != null && message.hasOwnProperty("skipBuyingPowerCheck"))
                    object.skipBuyingPowerCheck = message.skipBuyingPowerCheck;
                if (message.saleType != null && message.hasOwnProperty("saleType"))
                    object.saleType = options.enums === String ? $root.mts.portfolio.TxSaleType[message.saleType] === undefined ? message.saleType : $root.mts.portfolio.TxSaleType[message.saleType] : message.saleType;
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    object.positionId = message.positionId;
                let keys2;
                if (message.brokerData && (keys2 = Object.keys(message.brokerData)).length) {
                    object.brokerData = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.brokerData[keys2[j]] = message.brokerData[keys2[j]];
                }
                if (message.brokerDataJson != null && message.hasOwnProperty("brokerDataJson"))
                    object.brokerDataJson = message.brokerDataJson;
                if (message.brokerExecutionId != null && message.hasOwnProperty("brokerExecutionId"))
                    object.brokerExecutionId = message.brokerExecutionId;
                if (message.tradeId != null && message.hasOwnProperty("tradeId"))
                    object.tradeId = message.tradeId;
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    object.profitLoss = options.json && !isFinite(message.profitLoss) ? String(message.profitLoss) : message.profitLoss;
                if (message.customData && (keys2 = Object.keys(message.customData)).length) {
                    object.customData = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.customData[keys2[j]] = message.customData[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this Tx to JSON.
             * @function toJSON
             * @memberof mts.portfolio.Tx
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Tx.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Tx
             * @function getTypeUrl
             * @memberof mts.portfolio.Tx
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Tx.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.Tx";
            };

            return Tx;
        })();

        portfolio.Performance = (function() {

            /**
             * Properties of a Performance.
             * @memberof mts.portfolio
             * @interface IPerformance
             * @property {number|null} [portfolioId] Performance portfolioId
             * @property {string|null} [date] Performance date
             * @property {number|null} [close] Performance close
             * @property {number|null} [cash] Performance cash
             * @property {number|null} [gainPercent] Performance gainPercent
             */

            /**
             * Constructs a new Performance.
             * @memberof mts.portfolio
             * @classdesc Represents a Performance.
             * @implements IPerformance
             * @constructor
             * @param {mts.portfolio.IPerformance=} [properties] Properties to set
             */
            function Performance(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Performance portfolioId.
             * @member {number} portfolioId
             * @memberof mts.portfolio.Performance
             * @instance
             */
            Performance.prototype.portfolioId = 0;

            /**
             * Performance date.
             * @member {string} date
             * @memberof mts.portfolio.Performance
             * @instance
             */
            Performance.prototype.date = "";

            /**
             * Performance close.
             * @member {number} close
             * @memberof mts.portfolio.Performance
             * @instance
             */
            Performance.prototype.close = 0;

            /**
             * Performance cash.
             * @member {number} cash
             * @memberof mts.portfolio.Performance
             * @instance
             */
            Performance.prototype.cash = 0;

            /**
             * Performance gainPercent.
             * @member {number} gainPercent
             * @memberof mts.portfolio.Performance
             * @instance
             */
            Performance.prototype.gainPercent = 0;

            /**
             * Creates a new Performance instance using the specified properties.
             * @function create
             * @memberof mts.portfolio.Performance
             * @static
             * @param {mts.portfolio.IPerformance=} [properties] Properties to set
             * @returns {mts.portfolio.Performance} Performance instance
             */
            Performance.create = function create(properties) {
                return new Performance(properties);
            };

            /**
             * Encodes the specified Performance message. Does not implicitly {@link mts.portfolio.Performance.verify|verify} messages.
             * @function encode
             * @memberof mts.portfolio.Performance
             * @static
             * @param {mts.portfolio.IPerformance} message Performance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Performance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.portfolioId);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
                if (message.close != null && Object.hasOwnProperty.call(message, "close"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.close);
                if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.cash);
                if (message.gainPercent != null && Object.hasOwnProperty.call(message, "gainPercent"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.gainPercent);
                return writer;
            };

            /**
             * Encodes the specified Performance message, length delimited. Does not implicitly {@link mts.portfolio.Performance.verify|verify} messages.
             * @function encodeDelimited
             * @memberof mts.portfolio.Performance
             * @static
             * @param {mts.portfolio.IPerformance} message Performance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Performance.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Performance message from the specified reader or buffer.
             * @function decode
             * @memberof mts.portfolio.Performance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mts.portfolio.Performance} Performance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Performance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.Performance();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.portfolioId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.date = reader.string();
                            break;
                        }
                    case 3: {
                            message.close = reader.double();
                            break;
                        }
                    case 4: {
                            message.cash = reader.double();
                            break;
                        }
                    case 5: {
                            message.gainPercent = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Performance message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof mts.portfolio.Performance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {mts.portfolio.Performance} Performance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Performance.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Performance message.
             * @function verify
             * @memberof mts.portfolio.Performance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Performance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    if (!$util.isInteger(message.portfolioId))
                        return "portfolioId: integer expected";
                if (message.date != null && message.hasOwnProperty("date"))
                    if (!$util.isString(message.date))
                        return "date: string expected";
                if (message.close != null && message.hasOwnProperty("close"))
                    if (typeof message.close !== "number")
                        return "close: number expected";
                if (message.cash != null && message.hasOwnProperty("cash"))
                    if (typeof message.cash !== "number")
                        return "cash: number expected";
                if (message.gainPercent != null && message.hasOwnProperty("gainPercent"))
                    if (typeof message.gainPercent !== "number")
                        return "gainPercent: number expected";
                return null;
            };

            /**
             * Creates a Performance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mts.portfolio.Performance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mts.portfolio.Performance} Performance
             */
            Performance.fromObject = function fromObject(object) {
                if (object instanceof $root.mts.portfolio.Performance)
                    return object;
                let message = new $root.mts.portfolio.Performance();
                if (object.portfolioId != null)
                    message.portfolioId = object.portfolioId | 0;
                if (object.date != null)
                    message.date = String(object.date);
                if (object.close != null)
                    message.close = Number(object.close);
                if (object.cash != null)
                    message.cash = Number(object.cash);
                if (object.gainPercent != null)
                    message.gainPercent = Number(object.gainPercent);
                return message;
            };

            /**
             * Creates a plain object from a Performance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mts.portfolio.Performance
             * @static
             * @param {mts.portfolio.Performance} message Performance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Performance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.portfolioId = 0;
                    object.date = "";
                    object.close = 0;
                    object.cash = 0;
                    object.gainPercent = 0;
                }
                if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                    object.portfolioId = message.portfolioId;
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = message.date;
                if (message.close != null && message.hasOwnProperty("close"))
                    object.close = options.json && !isFinite(message.close) ? String(message.close) : message.close;
                if (message.cash != null && message.hasOwnProperty("cash"))
                    object.cash = options.json && !isFinite(message.cash) ? String(message.cash) : message.cash;
                if (message.gainPercent != null && message.hasOwnProperty("gainPercent"))
                    object.gainPercent = options.json && !isFinite(message.gainPercent) ? String(message.gainPercent) : message.gainPercent;
                return object;
            };

            /**
             * Converts this Performance to JSON.
             * @function toJSON
             * @memberof mts.portfolio.Performance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Performance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Performance
             * @function getTypeUrl
             * @memberof mts.portfolio.Performance
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Performance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mts.portfolio.Performance";
            };

            return Performance;
        })();

        portfolio.events = (function() {

            /**
             * Namespace events.
             * @memberof mts.portfolio
             * @namespace
             */
            const events = {};

            events.PortfolioEvent = (function() {

                /**
                 * Properties of a PortfolioEvent.
                 * @memberof mts.portfolio.events
                 * @interface IPortfolioEvent
                 * @property {number|Long|null} [time] PortfolioEvent time
                 * @property {string|null} [portfolioId] PortfolioEvent portfolioId
                 * @property {mts.portfolio.events.ITransactionAdded|null} [transactionAdded] PortfolioEvent transactionAdded
                 * @property {mts.portfolio.events.IKeepAlive|null} [keepAlive] PortfolioEvent keepAlive
                 * @property {mts.portfolio.events.IPortfolioUpdated|null} [portfolioUpdated] PortfolioEvent portfolioUpdated
                 */

                /**
                 * Constructs a new PortfolioEvent.
                 * @memberof mts.portfolio.events
                 * @classdesc Represents a PortfolioEvent.
                 * @implements IPortfolioEvent
                 * @constructor
                 * @param {mts.portfolio.events.IPortfolioEvent=} [properties] Properties to set
                 */
                function PortfolioEvent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PortfolioEvent time.
                 * @member {number|Long} time
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @instance
                 */
                PortfolioEvent.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PortfolioEvent portfolioId.
                 * @member {string} portfolioId
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @instance
                 */
                PortfolioEvent.prototype.portfolioId = "";

                /**
                 * PortfolioEvent transactionAdded.
                 * @member {mts.portfolio.events.ITransactionAdded|null|undefined} transactionAdded
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @instance
                 */
                PortfolioEvent.prototype.transactionAdded = null;

                /**
                 * PortfolioEvent keepAlive.
                 * @member {mts.portfolio.events.IKeepAlive|null|undefined} keepAlive
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @instance
                 */
                PortfolioEvent.prototype.keepAlive = null;

                /**
                 * PortfolioEvent portfolioUpdated.
                 * @member {mts.portfolio.events.IPortfolioUpdated|null|undefined} portfolioUpdated
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @instance
                 */
                PortfolioEvent.prototype.portfolioUpdated = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * PortfolioEvent type.
                 * @member {"transactionAdded"|"keepAlive"|"portfolioUpdated"|undefined} type
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @instance
                 */
                Object.defineProperty(PortfolioEvent.prototype, "type", {
                    get: $util.oneOfGetter($oneOfFields = ["transactionAdded", "keepAlive", "portfolioUpdated"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new PortfolioEvent instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {mts.portfolio.events.IPortfolioEvent=} [properties] Properties to set
                 * @returns {mts.portfolio.events.PortfolioEvent} PortfolioEvent instance
                 */
                PortfolioEvent.create = function create(properties) {
                    return new PortfolioEvent(properties);
                };

                /**
                 * Encodes the specified PortfolioEvent message. Does not implicitly {@link mts.portfolio.events.PortfolioEvent.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {mts.portfolio.events.IPortfolioEvent} message PortfolioEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortfolioEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.portfolioId);
                    if (message.transactionAdded != null && Object.hasOwnProperty.call(message, "transactionAdded"))
                        $root.mts.portfolio.events.TransactionAdded.encode(message.transactionAdded, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.keepAlive != null && Object.hasOwnProperty.call(message, "keepAlive"))
                        $root.mts.portfolio.events.KeepAlive.encode(message.keepAlive, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.portfolioUpdated != null && Object.hasOwnProperty.call(message, "portfolioUpdated"))
                        $root.mts.portfolio.events.PortfolioUpdated.encode(message.portfolioUpdated, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PortfolioEvent message, length delimited. Does not implicitly {@link mts.portfolio.events.PortfolioEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {mts.portfolio.events.IPortfolioEvent} message PortfolioEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortfolioEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PortfolioEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.events.PortfolioEvent} PortfolioEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortfolioEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.events.PortfolioEvent();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        case 2: {
                                message.portfolioId = reader.string();
                                break;
                            }
                        case 10: {
                                message.transactionAdded = $root.mts.portfolio.events.TransactionAdded.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.keepAlive = $root.mts.portfolio.events.KeepAlive.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.portfolioUpdated = $root.mts.portfolio.events.PortfolioUpdated.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PortfolioEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.events.PortfolioEvent} PortfolioEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortfolioEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PortfolioEvent message.
                 * @function verify
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PortfolioEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                        if (!$util.isString(message.portfolioId))
                            return "portfolioId: string expected";
                    if (message.transactionAdded != null && message.hasOwnProperty("transactionAdded")) {
                        properties.type = 1;
                        {
                            let error = $root.mts.portfolio.events.TransactionAdded.verify(message.transactionAdded);
                            if (error)
                                return "transactionAdded." + error;
                        }
                    }
                    if (message.keepAlive != null && message.hasOwnProperty("keepAlive")) {
                        if (properties.type === 1)
                            return "type: multiple values";
                        properties.type = 1;
                        {
                            let error = $root.mts.portfolio.events.KeepAlive.verify(message.keepAlive);
                            if (error)
                                return "keepAlive." + error;
                        }
                    }
                    if (message.portfolioUpdated != null && message.hasOwnProperty("portfolioUpdated")) {
                        if (properties.type === 1)
                            return "type: multiple values";
                        properties.type = 1;
                        {
                            let error = $root.mts.portfolio.events.PortfolioUpdated.verify(message.portfolioUpdated);
                            if (error)
                                return "portfolioUpdated." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PortfolioEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.events.PortfolioEvent} PortfolioEvent
                 */
                PortfolioEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.events.PortfolioEvent)
                        return object;
                    let message = new $root.mts.portfolio.events.PortfolioEvent();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.portfolioId != null)
                        message.portfolioId = String(object.portfolioId);
                    if (object.transactionAdded != null) {
                        if (typeof object.transactionAdded !== "object")
                            throw TypeError(".mts.portfolio.events.PortfolioEvent.transactionAdded: object expected");
                        message.transactionAdded = $root.mts.portfolio.events.TransactionAdded.fromObject(object.transactionAdded);
                    }
                    if (object.keepAlive != null) {
                        if (typeof object.keepAlive !== "object")
                            throw TypeError(".mts.portfolio.events.PortfolioEvent.keepAlive: object expected");
                        message.keepAlive = $root.mts.portfolio.events.KeepAlive.fromObject(object.keepAlive);
                    }
                    if (object.portfolioUpdated != null) {
                        if (typeof object.portfolioUpdated !== "object")
                            throw TypeError(".mts.portfolio.events.PortfolioEvent.portfolioUpdated: object expected");
                        message.portfolioUpdated = $root.mts.portfolio.events.PortfolioUpdated.fromObject(object.portfolioUpdated);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PortfolioEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {mts.portfolio.events.PortfolioEvent} message PortfolioEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PortfolioEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                        object.portfolioId = "";
                    }
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                        object.portfolioId = message.portfolioId;
                    if (message.transactionAdded != null && message.hasOwnProperty("transactionAdded")) {
                        object.transactionAdded = $root.mts.portfolio.events.TransactionAdded.toObject(message.transactionAdded, options);
                        if (options.oneofs)
                            object.type = "transactionAdded";
                    }
                    if (message.keepAlive != null && message.hasOwnProperty("keepAlive")) {
                        object.keepAlive = $root.mts.portfolio.events.KeepAlive.toObject(message.keepAlive, options);
                        if (options.oneofs)
                            object.type = "keepAlive";
                    }
                    if (message.portfolioUpdated != null && message.hasOwnProperty("portfolioUpdated")) {
                        object.portfolioUpdated = $root.mts.portfolio.events.PortfolioUpdated.toObject(message.portfolioUpdated, options);
                        if (options.oneofs)
                            object.type = "portfolioUpdated";
                    }
                    return object;
                };

                /**
                 * Converts this PortfolioEvent to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PortfolioEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PortfolioEvent
                 * @function getTypeUrl
                 * @memberof mts.portfolio.events.PortfolioEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PortfolioEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.events.PortfolioEvent";
                };

                return PortfolioEvent;
            })();

            events.PortfolioUpdated = (function() {

                /**
                 * Properties of a PortfolioUpdated.
                 * @memberof mts.portfolio.events
                 * @interface IPortfolioUpdated
                 * @property {mts.portfolio.IPortfolio|null} [portfolio] PortfolioUpdated portfolio
                 */

                /**
                 * Constructs a new PortfolioUpdated.
                 * @memberof mts.portfolio.events
                 * @classdesc Represents a PortfolioUpdated.
                 * @implements IPortfolioUpdated
                 * @constructor
                 * @param {mts.portfolio.events.IPortfolioUpdated=} [properties] Properties to set
                 */
                function PortfolioUpdated(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PortfolioUpdated portfolio.
                 * @member {mts.portfolio.IPortfolio|null|undefined} portfolio
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @instance
                 */
                PortfolioUpdated.prototype.portfolio = null;

                /**
                 * Creates a new PortfolioUpdated instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {mts.portfolio.events.IPortfolioUpdated=} [properties] Properties to set
                 * @returns {mts.portfolio.events.PortfolioUpdated} PortfolioUpdated instance
                 */
                PortfolioUpdated.create = function create(properties) {
                    return new PortfolioUpdated(properties);
                };

                /**
                 * Encodes the specified PortfolioUpdated message. Does not implicitly {@link mts.portfolio.events.PortfolioUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {mts.portfolio.events.IPortfolioUpdated} message PortfolioUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortfolioUpdated.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.portfolio != null && Object.hasOwnProperty.call(message, "portfolio"))
                        $root.mts.portfolio.Portfolio.encode(message.portfolio, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PortfolioUpdated message, length delimited. Does not implicitly {@link mts.portfolio.events.PortfolioUpdated.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {mts.portfolio.events.IPortfolioUpdated} message PortfolioUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortfolioUpdated.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PortfolioUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.events.PortfolioUpdated} PortfolioUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortfolioUpdated.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.events.PortfolioUpdated();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.portfolio = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PortfolioUpdated message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.events.PortfolioUpdated} PortfolioUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortfolioUpdated.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PortfolioUpdated message.
                 * @function verify
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PortfolioUpdated.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.portfolio != null && message.hasOwnProperty("portfolio")) {
                        let error = $root.mts.portfolio.Portfolio.verify(message.portfolio);
                        if (error)
                            return "portfolio." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PortfolioUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.events.PortfolioUpdated} PortfolioUpdated
                 */
                PortfolioUpdated.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.events.PortfolioUpdated)
                        return object;
                    let message = new $root.mts.portfolio.events.PortfolioUpdated();
                    if (object.portfolio != null) {
                        if (typeof object.portfolio !== "object")
                            throw TypeError(".mts.portfolio.events.PortfolioUpdated.portfolio: object expected");
                        message.portfolio = $root.mts.portfolio.Portfolio.fromObject(object.portfolio);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PortfolioUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {mts.portfolio.events.PortfolioUpdated} message PortfolioUpdated
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PortfolioUpdated.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.portfolio = null;
                    if (message.portfolio != null && message.hasOwnProperty("portfolio"))
                        object.portfolio = $root.mts.portfolio.Portfolio.toObject(message.portfolio, options);
                    return object;
                };

                /**
                 * Converts this PortfolioUpdated to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PortfolioUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PortfolioUpdated
                 * @function getTypeUrl
                 * @memberof mts.portfolio.events.PortfolioUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PortfolioUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.events.PortfolioUpdated";
                };

                return PortfolioUpdated;
            })();

            events.TransactionAdded = (function() {

                /**
                 * Properties of a TransactionAdded.
                 * @memberof mts.portfolio.events
                 * @interface ITransactionAdded
                 * @property {mts.portfolio.ITx|null} [transaction] TransactionAdded transaction
                 */

                /**
                 * Constructs a new TransactionAdded.
                 * @memberof mts.portfolio.events
                 * @classdesc Represents a TransactionAdded.
                 * @implements ITransactionAdded
                 * @constructor
                 * @param {mts.portfolio.events.ITransactionAdded=} [properties] Properties to set
                 */
                function TransactionAdded(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TransactionAdded transaction.
                 * @member {mts.portfolio.ITx|null|undefined} transaction
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @instance
                 */
                TransactionAdded.prototype.transaction = null;

                /**
                 * Creates a new TransactionAdded instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {mts.portfolio.events.ITransactionAdded=} [properties] Properties to set
                 * @returns {mts.portfolio.events.TransactionAdded} TransactionAdded instance
                 */
                TransactionAdded.create = function create(properties) {
                    return new TransactionAdded(properties);
                };

                /**
                 * Encodes the specified TransactionAdded message. Does not implicitly {@link mts.portfolio.events.TransactionAdded.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {mts.portfolio.events.ITransactionAdded} message TransactionAdded message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TransactionAdded.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transaction != null && Object.hasOwnProperty.call(message, "transaction"))
                        $root.mts.portfolio.Tx.encode(message.transaction, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified TransactionAdded message, length delimited. Does not implicitly {@link mts.portfolio.events.TransactionAdded.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {mts.portfolio.events.ITransactionAdded} message TransactionAdded message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TransactionAdded.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TransactionAdded message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.events.TransactionAdded} TransactionAdded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TransactionAdded.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.events.TransactionAdded();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.transaction = $root.mts.portfolio.Tx.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TransactionAdded message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.events.TransactionAdded} TransactionAdded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TransactionAdded.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TransactionAdded message.
                 * @function verify
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TransactionAdded.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transaction != null && message.hasOwnProperty("transaction")) {
                        let error = $root.mts.portfolio.Tx.verify(message.transaction);
                        if (error)
                            return "transaction." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TransactionAdded message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.events.TransactionAdded} TransactionAdded
                 */
                TransactionAdded.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.events.TransactionAdded)
                        return object;
                    let message = new $root.mts.portfolio.events.TransactionAdded();
                    if (object.transaction != null) {
                        if (typeof object.transaction !== "object")
                            throw TypeError(".mts.portfolio.events.TransactionAdded.transaction: object expected");
                        message.transaction = $root.mts.portfolio.Tx.fromObject(object.transaction);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TransactionAdded message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {mts.portfolio.events.TransactionAdded} message TransactionAdded
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TransactionAdded.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.transaction = null;
                    if (message.transaction != null && message.hasOwnProperty("transaction"))
                        object.transaction = $root.mts.portfolio.Tx.toObject(message.transaction, options);
                    return object;
                };

                /**
                 * Converts this TransactionAdded to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TransactionAdded.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TransactionAdded
                 * @function getTypeUrl
                 * @memberof mts.portfolio.events.TransactionAdded
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TransactionAdded.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.events.TransactionAdded";
                };

                return TransactionAdded;
            })();

            events.KeepAlive = (function() {

                /**
                 * Properties of a KeepAlive.
                 * @memberof mts.portfolio.events
                 * @interface IKeepAlive
                 */

                /**
                 * Constructs a new KeepAlive.
                 * @memberof mts.portfolio.events
                 * @classdesc Represents a KeepAlive.
                 * @implements IKeepAlive
                 * @constructor
                 * @param {mts.portfolio.events.IKeepAlive=} [properties] Properties to set
                 */
                function KeepAlive(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new KeepAlive instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {mts.portfolio.events.IKeepAlive=} [properties] Properties to set
                 * @returns {mts.portfolio.events.KeepAlive} KeepAlive instance
                 */
                KeepAlive.create = function create(properties) {
                    return new KeepAlive(properties);
                };

                /**
                 * Encodes the specified KeepAlive message. Does not implicitly {@link mts.portfolio.events.KeepAlive.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {mts.portfolio.events.IKeepAlive} message KeepAlive message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KeepAlive.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified KeepAlive message, length delimited. Does not implicitly {@link mts.portfolio.events.KeepAlive.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {mts.portfolio.events.IKeepAlive} message KeepAlive message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KeepAlive.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a KeepAlive message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.events.KeepAlive} KeepAlive
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KeepAlive.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.events.KeepAlive();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a KeepAlive message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.events.KeepAlive} KeepAlive
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KeepAlive.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a KeepAlive message.
                 * @function verify
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                KeepAlive.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a KeepAlive message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.events.KeepAlive} KeepAlive
                 */
                KeepAlive.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.events.KeepAlive)
                        return object;
                    return new $root.mts.portfolio.events.KeepAlive();
                };

                /**
                 * Creates a plain object from a KeepAlive message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {mts.portfolio.events.KeepAlive} message KeepAlive
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                KeepAlive.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this KeepAlive to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.events.KeepAlive
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                KeepAlive.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for KeepAlive
                 * @function getTypeUrl
                 * @memberof mts.portfolio.events.KeepAlive
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                KeepAlive.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.events.KeepAlive";
                };

                return KeepAlive;
            })();

            return events;
        })();

        portfolio.http = (function() {

            /**
             * Namespace http.
             * @memberof mts.portfolio
             * @namespace
             */
            const http = {};

            http.GetPortfolioStreamResponse = (function() {

                /**
                 * Properties of a GetPortfolioStreamResponse.
                 * @memberof mts.portfolio.http
                 * @interface IGetPortfolioStreamResponse
                 * @property {number|Long|null} [time] GetPortfolioStreamResponse time
                 * @property {string|null} [portfolioId] GetPortfolioStreamResponse portfolioId
                 * @property {number|Long|null} [sequence] GetPortfolioStreamResponse sequence
                 * @property {mts.portfolio.events.IPortfolioUpdated|null} [portfolioUpdated] GetPortfolioStreamResponse portfolioUpdated
                 * @property {mts.portfolio.IPortfolio|null} [portfolioDelta] GetPortfolioStreamResponse portfolioDelta
                 * @property {mts.portfolio.IPositions|null} [positions] GetPortfolioStreamResponse positions
                 * @property {mts.portfolio.ITx|null} [transactionAdded] GetPortfolioStreamResponse transactionAdded
                 * @property {mts.portfolio.IPortfolio|null} [portfolio] GetPortfolioStreamResponse portfolio
                 */

                /**
                 * Constructs a new GetPortfolioStreamResponse.
                 * @memberof mts.portfolio.http
                 * @classdesc Represents a GetPortfolioStreamResponse.
                 * @implements IGetPortfolioStreamResponse
                 * @constructor
                 * @param {mts.portfolio.http.IGetPortfolioStreamResponse=} [properties] Properties to set
                 */
                function GetPortfolioStreamResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetPortfolioStreamResponse time.
                 * @member {number|Long} time
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * GetPortfolioStreamResponse portfolioId.
                 * @member {string} portfolioId
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.portfolioId = "";

                /**
                 * GetPortfolioStreamResponse sequence.
                 * @member {number|Long} sequence
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.sequence = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * GetPortfolioStreamResponse portfolioUpdated.
                 * @member {mts.portfolio.events.IPortfolioUpdated|null|undefined} portfolioUpdated
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.portfolioUpdated = null;

                /**
                 * GetPortfolioStreamResponse portfolioDelta.
                 * @member {mts.portfolio.IPortfolio|null|undefined} portfolioDelta
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.portfolioDelta = null;

                /**
                 * GetPortfolioStreamResponse positions.
                 * @member {mts.portfolio.IPositions|null|undefined} positions
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.positions = null;

                /**
                 * GetPortfolioStreamResponse transactionAdded.
                 * @member {mts.portfolio.ITx|null|undefined} transactionAdded
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.transactionAdded = null;

                /**
                 * GetPortfolioStreamResponse portfolio.
                 * @member {mts.portfolio.IPortfolio|null|undefined} portfolio
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                GetPortfolioStreamResponse.prototype.portfolio = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * GetPortfolioStreamResponse messageType.
                 * @member {"portfolioUpdated"|"portfolioDelta"|"positions"|"transactionAdded"|"portfolio"|undefined} messageType
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 */
                Object.defineProperty(GetPortfolioStreamResponse.prototype, "messageType", {
                    get: $util.oneOfGetter($oneOfFields = ["portfolioUpdated", "portfolioDelta", "positions", "transactionAdded", "portfolio"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new GetPortfolioStreamResponse instance using the specified properties.
                 * @function create
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {mts.portfolio.http.IGetPortfolioStreamResponse=} [properties] Properties to set
                 * @returns {mts.portfolio.http.GetPortfolioStreamResponse} GetPortfolioStreamResponse instance
                 */
                GetPortfolioStreamResponse.create = function create(properties) {
                    return new GetPortfolioStreamResponse(properties);
                };

                /**
                 * Encodes the specified GetPortfolioStreamResponse message. Does not implicitly {@link mts.portfolio.http.GetPortfolioStreamResponse.verify|verify} messages.
                 * @function encode
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {mts.portfolio.http.IGetPortfolioStreamResponse} message GetPortfolioStreamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPortfolioStreamResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                    if (message.portfolioId != null && Object.hasOwnProperty.call(message, "portfolioId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.portfolioId);
                    if (message.sequence != null && Object.hasOwnProperty.call(message, "sequence"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.sequence);
                    if (message.portfolioUpdated != null && Object.hasOwnProperty.call(message, "portfolioUpdated"))
                        $root.mts.portfolio.events.PortfolioUpdated.encode(message.portfolioUpdated, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.portfolioDelta != null && Object.hasOwnProperty.call(message, "portfolioDelta"))
                        $root.mts.portfolio.Portfolio.encode(message.portfolioDelta, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.positions != null && Object.hasOwnProperty.call(message, "positions"))
                        $root.mts.portfolio.Positions.encode(message.positions, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.transactionAdded != null && Object.hasOwnProperty.call(message, "transactionAdded"))
                        $root.mts.portfolio.Tx.encode(message.transactionAdded, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.portfolio != null && Object.hasOwnProperty.call(message, "portfolio"))
                        $root.mts.portfolio.Portfolio.encode(message.portfolio, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetPortfolioStreamResponse message, length delimited. Does not implicitly {@link mts.portfolio.http.GetPortfolioStreamResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {mts.portfolio.http.IGetPortfolioStreamResponse} message GetPortfolioStreamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPortfolioStreamResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetPortfolioStreamResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {mts.portfolio.http.GetPortfolioStreamResponse} GetPortfolioStreamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPortfolioStreamResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mts.portfolio.http.GetPortfolioStreamResponse();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.time = reader.int64();
                                break;
                            }
                        case 2: {
                                message.portfolioId = reader.string();
                                break;
                            }
                        case 3: {
                                message.sequence = reader.int64();
                                break;
                            }
                        case 10: {
                                message.portfolioUpdated = $root.mts.portfolio.events.PortfolioUpdated.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.portfolioDelta = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.positions = $root.mts.portfolio.Positions.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.transactionAdded = $root.mts.portfolio.Tx.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.portfolio = $root.mts.portfolio.Portfolio.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetPortfolioStreamResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {mts.portfolio.http.GetPortfolioStreamResponse} GetPortfolioStreamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPortfolioStreamResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetPortfolioStreamResponse message.
                 * @function verify
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPortfolioStreamResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                            return "time: integer|Long expected";
                    if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                        if (!$util.isString(message.portfolioId))
                            return "portfolioId: string expected";
                    if (message.sequence != null && message.hasOwnProperty("sequence"))
                        if (!$util.isInteger(message.sequence) && !(message.sequence && $util.isInteger(message.sequence.low) && $util.isInteger(message.sequence.high)))
                            return "sequence: integer|Long expected";
                    if (message.portfolioUpdated != null && message.hasOwnProperty("portfolioUpdated")) {
                        properties.messageType = 1;
                        {
                            let error = $root.mts.portfolio.events.PortfolioUpdated.verify(message.portfolioUpdated);
                            if (error)
                                return "portfolioUpdated." + error;
                        }
                    }
                    if (message.portfolioDelta != null && message.hasOwnProperty("portfolioDelta")) {
                        if (properties.messageType === 1)
                            return "messageType: multiple values";
                        properties.messageType = 1;
                        {
                            let error = $root.mts.portfolio.Portfolio.verify(message.portfolioDelta);
                            if (error)
                                return "portfolioDelta." + error;
                        }
                    }
                    if (message.positions != null && message.hasOwnProperty("positions")) {
                        if (properties.messageType === 1)
                            return "messageType: multiple values";
                        properties.messageType = 1;
                        {
                            let error = $root.mts.portfolio.Positions.verify(message.positions);
                            if (error)
                                return "positions." + error;
                        }
                    }
                    if (message.transactionAdded != null && message.hasOwnProperty("transactionAdded")) {
                        if (properties.messageType === 1)
                            return "messageType: multiple values";
                        properties.messageType = 1;
                        {
                            let error = $root.mts.portfolio.Tx.verify(message.transactionAdded);
                            if (error)
                                return "transactionAdded." + error;
                        }
                    }
                    if (message.portfolio != null && message.hasOwnProperty("portfolio")) {
                        if (properties.messageType === 1)
                            return "messageType: multiple values";
                        properties.messageType = 1;
                        {
                            let error = $root.mts.portfolio.Portfolio.verify(message.portfolio);
                            if (error)
                                return "portfolio." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetPortfolioStreamResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mts.portfolio.http.GetPortfolioStreamResponse} GetPortfolioStreamResponse
                 */
                GetPortfolioStreamResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mts.portfolio.http.GetPortfolioStreamResponse)
                        return object;
                    let message = new $root.mts.portfolio.http.GetPortfolioStreamResponse();
                    if (object.time != null)
                        if ($util.Long)
                            (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                        else if (typeof object.time === "string")
                            message.time = parseInt(object.time, 10);
                        else if (typeof object.time === "number")
                            message.time = object.time;
                        else if (typeof object.time === "object")
                            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                    if (object.portfolioId != null)
                        message.portfolioId = String(object.portfolioId);
                    if (object.sequence != null)
                        if ($util.Long)
                            (message.sequence = $util.Long.fromValue(object.sequence)).unsigned = false;
                        else if (typeof object.sequence === "string")
                            message.sequence = parseInt(object.sequence, 10);
                        else if (typeof object.sequence === "number")
                            message.sequence = object.sequence;
                        else if (typeof object.sequence === "object")
                            message.sequence = new $util.LongBits(object.sequence.low >>> 0, object.sequence.high >>> 0).toNumber();
                    if (object.portfolioUpdated != null) {
                        if (typeof object.portfolioUpdated !== "object")
                            throw TypeError(".mts.portfolio.http.GetPortfolioStreamResponse.portfolioUpdated: object expected");
                        message.portfolioUpdated = $root.mts.portfolio.events.PortfolioUpdated.fromObject(object.portfolioUpdated);
                    }
                    if (object.portfolioDelta != null) {
                        if (typeof object.portfolioDelta !== "object")
                            throw TypeError(".mts.portfolio.http.GetPortfolioStreamResponse.portfolioDelta: object expected");
                        message.portfolioDelta = $root.mts.portfolio.Portfolio.fromObject(object.portfolioDelta);
                    }
                    if (object.positions != null) {
                        if (typeof object.positions !== "object")
                            throw TypeError(".mts.portfolio.http.GetPortfolioStreamResponse.positions: object expected");
                        message.positions = $root.mts.portfolio.Positions.fromObject(object.positions);
                    }
                    if (object.transactionAdded != null) {
                        if (typeof object.transactionAdded !== "object")
                            throw TypeError(".mts.portfolio.http.GetPortfolioStreamResponse.transactionAdded: object expected");
                        message.transactionAdded = $root.mts.portfolio.Tx.fromObject(object.transactionAdded);
                    }
                    if (object.portfolio != null) {
                        if (typeof object.portfolio !== "object")
                            throw TypeError(".mts.portfolio.http.GetPortfolioStreamResponse.portfolio: object expected");
                        message.portfolio = $root.mts.portfolio.Portfolio.fromObject(object.portfolio);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetPortfolioStreamResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {mts.portfolio.http.GetPortfolioStreamResponse} message GetPortfolioStreamResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPortfolioStreamResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.time = options.longs === String ? "0" : 0;
                        object.portfolioId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sequence = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sequence = options.longs === String ? "0" : 0;
                    }
                    if (message.time != null && message.hasOwnProperty("time"))
                        if (typeof message.time === "number")
                            object.time = options.longs === String ? String(message.time) : message.time;
                        else
                            object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                    if (message.portfolioId != null && message.hasOwnProperty("portfolioId"))
                        object.portfolioId = message.portfolioId;
                    if (message.sequence != null && message.hasOwnProperty("sequence"))
                        if (typeof message.sequence === "number")
                            object.sequence = options.longs === String ? String(message.sequence) : message.sequence;
                        else
                            object.sequence = options.longs === String ? $util.Long.prototype.toString.call(message.sequence) : options.longs === Number ? new $util.LongBits(message.sequence.low >>> 0, message.sequence.high >>> 0).toNumber() : message.sequence;
                    if (message.portfolioUpdated != null && message.hasOwnProperty("portfolioUpdated")) {
                        object.portfolioUpdated = $root.mts.portfolio.events.PortfolioUpdated.toObject(message.portfolioUpdated, options);
                        if (options.oneofs)
                            object.messageType = "portfolioUpdated";
                    }
                    if (message.portfolioDelta != null && message.hasOwnProperty("portfolioDelta")) {
                        object.portfolioDelta = $root.mts.portfolio.Portfolio.toObject(message.portfolioDelta, options);
                        if (options.oneofs)
                            object.messageType = "portfolioDelta";
                    }
                    if (message.positions != null && message.hasOwnProperty("positions")) {
                        object.positions = $root.mts.portfolio.Positions.toObject(message.positions, options);
                        if (options.oneofs)
                            object.messageType = "positions";
                    }
                    if (message.transactionAdded != null && message.hasOwnProperty("transactionAdded")) {
                        object.transactionAdded = $root.mts.portfolio.Tx.toObject(message.transactionAdded, options);
                        if (options.oneofs)
                            object.messageType = "transactionAdded";
                    }
                    if (message.portfolio != null && message.hasOwnProperty("portfolio")) {
                        object.portfolio = $root.mts.portfolio.Portfolio.toObject(message.portfolio, options);
                        if (options.oneofs)
                            object.messageType = "portfolio";
                    }
                    return object;
                };

                /**
                 * Converts this GetPortfolioStreamResponse to JSON.
                 * @function toJSON
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPortfolioStreamResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetPortfolioStreamResponse
                 * @function getTypeUrl
                 * @memberof mts.portfolio.http.GetPortfolioStreamResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPortfolioStreamResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/mts.portfolio.http.GetPortfolioStreamResponse";
                };

                return GetPortfolioStreamResponse;
            })();

            return http;
        })();

        return portfolio;
    })();

    return mts;
})();

export { $root as default };
