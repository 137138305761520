import { CaretRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useState } from 'react'
import { mts } from '../../desk_protos'
import { RunBacktestModal } from './RunBacktestModal'

/**
 *
 * If props.instance is
 */
export const RunBacktestButton: React.FC<{
    trader: mts.desk.Trader
    disabled: boolean
    instance?: mts.desk.TraderInstance
}> = (props) => {
    const [runModalOpen, setRunModalOpen] = useState(false)

    const showRunModal = () => {
        setRunModalOpen(true)
    }

    const handleModalClose = () => {
        setRunModalOpen(false)
    }

    return (
        <div>
            <RunBacktestModal
                open={runModalOpen}
                onClose={handleModalClose}
                trader={props.trader}
                instance={props.instance}
            />
            <Button onClick={showRunModal} disabled={props.disabled} className="mts-run-backtest-btn">
                <CaretRightOutlined />
                Run Backtest
            </Button>
        </div>
    )
}
