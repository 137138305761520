import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Form, MenuProps, Space, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { DeskContext } from '../../../App'
import { Toolbar, ToolbarItem } from '../../../components/Toolbar'
import { mts } from '../../../desk_protos'

import Editor from '@monaco-editor/react'
import { Signal, useSignal } from '@preact/signals-react'
import { Snippet, SnippetFile } from '../../../data/SnippetsManager'
import { TraderOption } from './TraderOption'

// An react FC that renders a form to edit trader options
export const OptionsForm: React.FC<{
    options: Signal<mts.desk.TraderOption[]>
    onChange: (options: mts.desk.TraderOption[]) => void
}> = (props) => {
    const [form] = Form.useForm()
    const [, setRefreshCount] = useState(0)
    const options = useSignal<mts.desk.TraderOption[]>(props.options.value)
    const saving = useSignal(false)

    // effect for trader
    useEffect(() => {
    }, [props.options])

    const refresh = () => {
        setRefreshCount((count) => count + 1)
    }

    const renderOption = (option: mts.desk.TraderOption, index: number) => {
        return (
            <TraderOption
                key={index}
                index={index}
                option={option}
                onRemove={handleRemove}
                onChange={handleChange}
            />
        )
    }
    // Update options list on change

    const add = () => {
        // Add a new setting to the trader.options
        const newOption = mts.desk.TraderOption.create({
            name: '',
            label: '',
            type: mts.desk.TraderOption.OptionType.STRING,
        })
        console.debug('adding blank option', newOption)
        options.value.push(newOption)
        refresh()
    }

    const handleRemove = (index: number) => {
        // Remove the setting from the trader.options
        options.value.splice(index, 1)
        refresh()
    }

    const handleChange = (index: number, option: mts.desk.TraderOption) => {
        // Update the setting in the trader.options
        
        options.value[index] = option
        console.debug('options updated', options.value)
    }

    const save = () => {
        console.log('saving options', options.value)
        props.onChange(options.value)
    }

    const handleSaveClick = () => {
        if (form.isFieldsValidating()) {
            return
        }
        saving.value = true

        // Save the options to the trader
        form.validateFields()
            .then((values) => {
                save()
            })
            .catch((err) => {
                console.error('validation error', err)
            })
            .finally(() => {
                saving.value = false
            })
    }

    return (
        <div style={{ maxWidth: '1000px', width: '100%' }}>
            <Typography.Paragraph>
                <p>
                    Options are a way to customize how your Trader behaves. Some common options would be:
                    symbol, quantity, depositAmount, scaleAmount etc.
                </p>

                <p>
                    These options are entered when an instance is created, either in backtest or realtime
                    mode.{' '}
                </p>

                <ul>
                    <li>
                        <b>name</b> - The name of the option
                    </li>
                    <li>
                        <b>label</b> - The label of the option
                    </li>
                    <li>
                        <b>type</b> - The type of the option
                    </li>
                    <li>
                        <b>description</b> - The description of the option
                    </li>
                </ul>
            </Typography.Paragraph>
            <br />
            <Form style={{ width: '100%' }} form={form}>
                {options.value.map((option, i) => renderOption(option, i))}
                <Form.Item>
                    <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
                        Add field
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button loading={saving.value} type="primary" onClick={handleSaveClick}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export const JavascriptSetting: React.FC<{
    onChange: (code: string, ev: any) => void
    code: string
}> = (props) => {
    const [code, setCode] = useState(props.code)
    const appCtx = useContext(DeskContext)
    //const monaco = useMonaco()

    useEffect(() => {
        // Get the Monaco instance
        // Configure the built-in TypeScript language service for JavaScript
        //monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        //  target: monaco.languages.typescript.ScriptTarget.ES2015,
        //  allowNonTsExtensions: true,
        //});
        // Set up ES6 language service
        // compiler options
        /*
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2020,
        allowNonTsExtensions: true,
        moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        module: monaco.languages.typescript.ModuleKind.CommonJS,
        noEmit: true,
        typeRoots: ["node_modules/@types"]
    });
    
    monaco.languages.typescript.typescriptDefaults.addExtraLib(
        'export declare function add(a: number, b: number): number',
        'file:///node_modules/@types/math/index.d.ts'
    );
    
    const model = monaco.editor.createModel(
        `import {add} from 'math';\nconst x = add(3, 5);\n`,
        'typescript',
        monaco.Uri.parse('file:///index.tsx')
    );
    */
        // Use the built-in completion provider for JavaScript
        /*
        monaco.languages.registerCompletionItemProvider('javascript', {
          provideCompletionItems: (model: any, position: any, context: any) => {
            // Use the TypeScript language service to get completion items
            const filePath = model.uri.path;
            const offset = model.getOffsetAt(position);
            const suggestions = monaco.languages.typescript.getSuggestionForWord(filePath, model.getValue(), offset, context);
    
            // Convert TypeScript suggestions to JavaScript suggestions
            const javascriptSuggestions = suggestions.map((tsSuggestion: any) => ({
              ...tsSuggestion,
              kind: monaco.languages.CompletionItemKind[tsSuggestion.kind],
            }));
    
            return {
              suggestions: javascriptSuggestions,
            };
          },
        });
        */
    }, [])

    useEffect(() => {
        setCode(props.code)
    }, [props.code])

    const handleChange = (newCode: any, ev: any) => {
        setCode(newCode)
        //debounceCheckSyntax(newCode)
        if (props.onChange) {
            props.onChange(newCode, ev)
        }
    }

    /*
    const handleMount = useCallback((editor: any, monaco: any) => {
        // Your code to handle editor mount
        console.log('Mounted', editor, monaco)

        editor.setModelMarkers(editor.getModel(), 'error', [
            {
                startLineNumber: 1,
                startColumn: 1,
                endLineNumber: 1,
                endColumn: 1,
                message: 'oh no',
                severity: monaco.MarkerSeverity.Error,
            },
        ])
    }, [])
    */

    const options = {
        selectOnLineNumbers: false,
        minimap: {
            enabled: false,
        },
        scrollbar: {},
        automaticLayout: true,
    }

    const theme = appCtx.settings.themeName === 'dark' ? 'vs-dark' : 'vs-light'
    return (
        <Editor language="javascript" theme={theme} value={code} options={options} onChange={handleChange} />
    )
}

type CustomFormItemProps = {
    value?: string
    onChange?: (code: string) => void
}

export const snippets: Snippet[] = [
    {
        name: 'Invest 1,000/month in SPY',
        code: `
if (mts.isFirstTradingDayOfMonth()) {
    mts.deposit(1000.0)
    const quote = mts.getQuote('SPY')
    const qty = Math.floor(1000.0 / quote.price) 
    mts.buy(qty, 'SPY')
}
`,
    },
    {
        name: 'Quote',
        code: `
const LocalDate = Java.type('java.time.LocalDate')
const date = LocalDate.parse('2024-01-02')
console.log('quote', mts.quote('SPY'))
`,
    },
    {
        name: 'Candle for 1 day',
        code: `// Fetch daily candle for 2024-01-02
let from = Instant.parse('2023-01-01T09:30:00-05:00')
let to = Instant.parse('2023-12-31T09:30:00-05:00')
console.log('quote', mts.getCandles(['SPY'], from, to, '1d'))
`,
    },
]

export const snippetsByName = new Map(snippets.map((snippet) => [snippet.name, snippet]))

// List of menu items for the script downdown with field name -> key, and label -> key
const items = snippets.map((snippet) => ({
    key: snippet.name,
    label: snippet.name,
}))

export const ScriptExamplesDropdown: React.FC<{
    onSnippetSelect: (snippet: Snippet) => void
    label: string
}> = (props) => {
    const handleMenuClick: MenuProps['onClick'] = (e) => {
        const snippetName = e.key
        const snippet = snippetsByName.get(snippetName)
        if (!snippet) {
            throw new Error(`Unknown snippet key ${snippetName}`)
        }
        console.log('insert', snippet.code)
        props.onSnippetSelect(snippet)
    }

    const menuProps = {
        items,
        onClick: handleMenuClick,
    }

    return (
        <div className="script-examples-wrapper">
            <Space>
                <Dropdown menu={menuProps}>
                    <Button>
                        {props.label}
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </Space>
        </div>
    )
}

/**
 * A demo that changes it's number value on click.
 * @param value initialValue passed by parent Form.Item.
 * @param onChange a callback for Form.Item to read its child component's value.
 * @constructor
 */
export const JavascriptFormItem: React.FC<CustomFormItemProps> = ({ value, onChange }) => {
    const [code, setCode] = useState(value === undefined ? '' : value)

    const change = (code: string) => {
        setCode(code)
        if (typeof onChange === 'function') {
            onChange(code)
        }
    }

    return (
        <div className="js-form-item">
            {/*<ScriptExamplesDropdown label="Insert Snippet..." onSnippetSelect={handleInsertSnippet} />*/}
            <JavascriptSetting onChange={change} code={code} />
        </div>
    )
}

/**
 * Renders a single pane that is a Javascript editor. Along with a menu
 */
export const JavascriptEditor: React.FC<{
    code: string
    tickType: mts.desk.Tick.Type
    onChange: (tickType: mts.desk.Tick.Type, code: string) => void
}> = (props) => {
    const [code, setCode] = useState(props.code)

    useEffect(() => {
        setCode(props.code)
    }, [props.code])

    const change = (code: string) => {
        setCode(code)
        props.onChange(props.tickType, code)
    }

    const handleInsertSnippet = (snippet: Snippet) => {
        //setCode(code + "\n" + snippet.code)
        //props.onChange(props.tickType, code + "\n" + snippet.code)
    }

    return (
        <EditorWrapper>
            
            <JavascriptSetting onChange={change} code={code} />
        </EditorWrapper>
    )
}

const EditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    .react-monaco-editor-container {
        border: 1px solid ${(props) => props.theme.antd.colorBorder};
    }
`
