import { Checkbox, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useEffect, useState } from 'react'
import { mts } from '../../desk_protos'
import { OptionValues } from '../../pages/instances/TraderInstancePage'

/**
 * Generate a list of form items using trader.options
 */
export const OptionValueItemsForm: React.FC<{
    options: mts.desk.ITraderOption[]
    optionValues: OptionValues
    onOptionValuesChange: (values: OptionValues) => void
}> = (props) => {
    const [form] = useForm()
    const [options] = useState(props.options as mts.desk.TraderOption[])
    const [optionValues, setOptionValues] = useState(props.optionValues)

    // Set form values on props.optionValues change
    useEffect(() => {
        const v: { [key: string]: any } = {}
        for (let key in optionValues) {
            const value = optionValues[key]
            if (value === 'true' || value === 'false') {
                v[key] = value === 'true'
            // TODO stupid, need to refactor this to not compare to 'undefined'
            } else if (value === undefined || value === 'undefined') {
                v[key] = undefined
            } else {
                v[key] = String(value)
            }
        }
        form.setFieldsValue(v)
    }, [form, optionValues])

    const handleValuesChange = (changedValues: any, allValues: any) => {
        let v: { [key: string]: any } = {}
        for (let key in allValues) {
            v[key] = String(allValues[key])
        }
        setOptionValues(v)
        props.onOptionValuesChange(v)
    }

    return (
        <Form
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 25 }}
            initialValues={{}}
            requiredMark={true}
            form={form}
            onValuesChange={handleValuesChange}
        >
            {options.map((option) => {
                if (option.type === mts.desk.TraderOption.OptionType.BOOLEAN) {
                    return (
                        <Form.Item
                            key={option.name}
                            name={option.name}
                            label={option.label}
                            extra={option.description}
                            rules={[{ required: option.required }]}
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    )
                } else if (option.type === mts.desk.TraderOption.OptionType.STRING) {
                    return (
                        <Form.Item
                            key={option.name}
                            name={option.name}
                            label={option.label}
                            extra={option.description}
                            rules={[{ required: option.required }]}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return (
                        <Form.Item
                            key={option.name}
                            name={option.name}
                            label={option.label}
                            extra={option.description}
                            rules={[{ required: option.required }]}
                        >
                            <Input />
                        </Form.Item>
                    )
                }
            })}
        </Form>
    )
}
