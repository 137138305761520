import { DateTime } from "luxon"

export class Formatters {
    static humanDate(dt?: DateTime): string {
        if (!dt) {
            return ""
        }
        const diff = DateTime.now().diff(dt, ["seconds", "minutes", "hours", "days", "years", "months"])
        const { days, hours, minutes, seconds, months } = diff

        if (months > 0 || days > 2) {
            return dt.toFormat("yyyy-MM-dd")
        }

        if (days > 0) {
            return `${days} day${days > 1 ? "s" : ""}, ${hours} hour${hours > 1 ? "s" : ""} ago`
        }
        if (hours > 0) {
            return `${diff.hours} hour${hours > 1 ? "s" : ""}, ${minutes} minute${minutes > 1 ? "s" : ""} ago`
        }
        if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? "s" : ""} ago`
        }
        if (seconds < 5) {
            return `now`
        }
        if (seconds < 15) {
            return "a few seconds ago"
        }

        return `now`
    }

    static diffBetweenDates(from: DateTime, to: DateTime): string {
        const diff = to.diff(from, ["days", "minutes"])
        const { days } = diff
        return `${days} day${days > 1 ? "s" : ""}`
    }

    static humanDateISO(isoDateTime: string): string {
        if (isoDateTime === "") {
            return ""
        }
        return this.humanDate(DateTime.fromISO(isoDateTime))
    }
}
