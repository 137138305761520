import { Alert, Button, DatePicker, Form, Modal, Typography, message } from "antd"
import { useForm } from "antd/es/form/Form"
import { DateTime } from "luxon"
import luxonGenerateConfig from "rc-picker/lib/generate/luxon"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Config, DeskContext, DeskContextValue } from "../../App"
import { mts } from "../../desk_protos"
import Checkbox from "antd/es/checkbox/Checkbox"

const LuxonDatePicker = DatePicker.generatePicker<DateTime>(luxonGenerateConfig)

/**
 * POST's trader to the desk. This in effect creates a new trader instance, if it's a backtest it's immediately run
 *
 * @throws mts.common.ApiError if the trader cannot be added.
 */
const addTraderInstance = async (appCtx: DeskContextValue, traderInstance: mts.desk.TraderInstance) => {
    const request = mts.desk.AddTraderInstanceRequest.create({
        traderInstance,
    })
    // POST to the desk runner endpoint
    const url = `${Config.DESK_ADDR}/traderinstances`

    console.log(`POST ${url} addTraderInstanceRequest`, request)

    const resp = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/protobuf",
            "Content-type": "application/protobuf",
            "x-mts-session-id": appCtx.session.id,
        },
        body: mts.desk.AddTraderInstanceRequest.encode(request).finish(),
    })
    if (resp.ok) {
        const data = await resp.arrayBuffer()
        let response = mts.desk.AddTraderInstanceResponse.decode(new Uint8Array(data))
        return response
    }
    const data = await resp.arrayBuffer()
    const apiError = mts.common.ApiErrorResponse.decode(new Uint8Array(data))
    throw apiError
}

export const CreateInstanceModal: React.FC<{
    trader?: mts.desk.Trader
    open: boolean
    onClose: () => void
}> = (props) => {
    const [error, setError] = useState("")
    const [trader, setTrader] = useState(props.trader)
    const [saving, setSaving] = useState(false)
    const [form] = useForm()
    const appCtx = useContext(DeskContext)

    const navigate = useNavigate()

    useEffect(() => {
        console.log("Set trader", trader)
        setTrader(props.trader)
    }, [props.trader, trader])

    if (!trader && props.open) {
        console.error("props.trader cannot be undefined when props.open=true")
        return <></>
    }
    if (!trader) {
        return <></>
    }

    /*
     * Create a new Trader Instance from a trader
     */
    const createInstance = async () => {
        try {
            console.log("Creating instance")

            const values = form.getFieldsValue()
            console.debug("From values", values)

            const newTraderInstance = mts.desk.TraderInstance.create({
                userId: appCtx.session.userId,
                traderId: trader.id,
                mode: mts.desk.TraderInstance.Mode.REALTIME,
                settings: {},
                heartbeatInterval: 1.0
            })

            if (!appCtx) {
                throw new Error("We need the app context to be set...")
            }
            const addTraderInstanceResponse = await addTraderInstance(appCtx, newTraderInstance)
            const traderInstance = addTraderInstanceResponse?.traderInstance as mts.desk.ITraderInstance
            const url = `/instances/${traderInstance.id}`
            if (values.redirect) {
                navigate(url)
            } else {
                //message.info((<div>Created <Link to={url}>TraderInstance#{traderInstance.id}</Link></div>))
                message.info(`Created TraderInstance#${traderInstance.id}`)
                props.onClose()
            }
        } catch (err) {
            if (err instanceof mts.common.ApiError) {
                const { code, detail, description } = err as mts.common.ApiError
                setError(`Failed to add instance: (${code}-${description}): ${detail}`)
            } else {
                setError(`Unknown error: ${JSON.stringify(err)}`)
            }
        } finally {
            setSaving(false)
        }
    }

    const handleOk = () => {
        console.log("values", form.getFieldsValue())
        form.validateFields()
            .then(createInstance)
            .catch((err) => {})
    }

    const handleClose = () => {
        props.onClose()
    }

    return (
        <Modal
            title={`Create Instance`}
            onOk={handleOk}
            destroyOnClose={false}
            onCancel={handleClose}
            open={props.open}
            transitionName=""
            footer={[
                <Button key="back" onClick={handleClose}>
                    Close
                </Button>,
                <Button key="submit" type="primary" loading={saving} onClick={handleOk}>
                    Create
                </Button>,
            ]}
        >
            <Typography.Paragraph>
                Create a new trader instance which can be started and stopped on demand.
            </Typography.Paragraph>
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 25 }}
                initialValues={{}}
                requiredMark={true}
                form={form}
                name="run-trader"
                style={{ maxWidth: 1000 }}
            >
                <Form.Item label="Trader#">{trader.id}</Form.Item>

                <Form.Item
                    name="assignportfolio"
                    label=""
                    valuePropName="checked"
                    initialValue={true}
                    wrapperCol={{ offset: 5 }}
                >
                    <Checkbox>Assign new Portfolio automatically</Checkbox>
                </Form.Item>

                <Form.Item
                    name="redirect"
                    label=""
                    valuePropName="checked"
                    initialValue={true}
                    wrapperCol={{ offset: 5 }}
                >
                    <Checkbox>Redirect to the newly created instance</Checkbox>
                </Form.Item>
            </Form>
            {error && <Alert message="Error" description={error} type="error" showIcon />}
        </Modal>
    )
}
