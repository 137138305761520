import { useSignal } from '@preact/signals-react'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

function formatCurrency(value?: number, precision?: number): string {
    if (!value) {
        return '-'
    }
    if (!precision) {
        precision = 4
    }
    value = parseFloat(value.toFixed(precision))
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value)
}

/**
 * Displays a currency value, with optional animation on change
 */
export const Currency: React.FC<{
    value?: number
    name?: string
    animate: boolean
    type: 'currency' | 'percentage'
    precision?: number
    color?: boolean
}> = (props) => {
    //const [value, setValue] = useState(props.value)
    const [animateCls, setAnimateCls] = useState('')
    const timeoutId = useSignal<NodeJS.Timeout | undefined>(undefined)
    const formattedValue = useSignal<string>(formatCurrency(props.value, props.precision))
    const [useColor] = useState(props.color === undefined ? false : props.color)
    const value = useSignal<number | undefined>(undefined)

    // When value changes, set the animate class to true, then after 5 seconds, set it back to false
    useEffect(() => {
        // if the value is the same, do nothing, prevent infinite loop.
        if (props.value === value.value) {
            return
        }

        // If current value is undefined, just set it, nothing else
        if (value.value === undefined) {
        } else if (props.value === undefined) {
        } else {
            // Increase
            if (value === undefined || props.value > value.value) {
                setAnimateCls('animate-up')
            } else if (props.value < value.value) {
                setAnimateCls('animate-down')
            }

            // Clear existing timeout id if there is one
            if (timeoutId.value) {
                clearTimeout(timeoutId.value)
            }

            let latestTimeoutId = setTimeout(() => {
                setAnimateCls('')
                timeoutId.value = undefined
            }, 2000)
            timeoutId.value = latestTimeoutId
        }

        // After 100millis
        // TODO: format the number as currency
        if (props.type === 'currency') {
            if (props.value) {
                formattedValue.value = formatCurrency(props.value, props.precision)
            } else {
                formattedValue.value = '-'
            }
        } else if (props.type === 'percentage') {
            if (props.value) {
                formattedValue.value = `${props.value}%`
            } else {
                formattedValue.value = '-'
            }
        }

        value.value = props.value
    }, [animateCls, formattedValue, props.precision, props.type, props.value, timeoutId, value])

    // If props.props is true, and the value is positive then add the 'color-positive' class
    // else if value is 0.0 than add the 'color-neutral' class, otherwise color-negative
    const colorCls = () => {
        const v = props.value
        if (useColor) {
            if (v && v > 0) {
                return 'color-positive'
            } else if (v && v < 0) {
                return 'color-negative'
            }
        }
        return 'color-neutral'
    }

    return (
        <Wrapper>
            <div className={animateCls + ' ' + colorCls()}>{formattedValue.value}</div>
        </Wrapper>
    )
}

const Wrapper = styled.span`
    div {
        display: inline-block;
        min-width: 100px;
        
    }
    .animate-up {
        background: green;
        color: white;
    }
    .animate-down {
        background: red;
        color white;
    }
    .color-positive {
        color: #66CC66;
    }
    .color-negative {
        color: #CC0000;
    }
`
