import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { mts } from '../../desk_protos'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { deskService } from '../../App'
import { Page } from '../../components/pages'
import { Toolbar, ToolbarItem } from '../../components/Toolbar'
import Title from 'antd/es/typography/Title'
import { Button, Descriptions } from 'antd'
import styled from 'styled-components'
import { GuidePopover } from '../../components/GuidePopover'
import { InstanceNotificationsButton } from '../instances/InstanceNotificationsButton'

export const WatchlistPage: React.FC<{}> = (props) => {
    const { watchlistId } = useParams()
    const [watchlist, setWatchlist] = useState<mts.desk.Watchlist>()
    const [instance, setInstance] = useState<mts.desk.TraderInstance>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<mts.common.ApiError | null>(null)
    const navigate = useNavigate()

    const isPhone = useMediaQuery({ query: '(max-width: 600px)' })

    useEffect(() => {
        const load = async () => {
            setLoading(true)
            deskService
                .getWatchlist(watchlistId as string)
                .then((resp) => setWatchlist(resp.watchlist as mts.desk.Watchlist))
                .catch((err: mts.common.ApiError) => setError(err))
                .finally(() => setLoading(false))
        }

        load()
    }, [watchlistId])

    if (error) {
        console.log('error', error)
        return <div>Error: {error.description}</div>
    }

    if (loading) {
        return <Page>Loading... {watchlistId}</Page>
    }

    if (!watchlist) {
        return <Page>Watchlist not found</Page>
    }

    return (
        <StyledWatchlistPage>
            <Toolbar>
                <ToolbarItem>
                    <Title>Watchlist</Title>
                </ToolbarItem>
                <div style={{ flex: '1' }} />
                <ToolbarItem>
                    <GuidePopover storageKey="guide.instance.notifications" content="W">
                        <InstanceNotificationsButton instanceId={watchlist.instanceId} />
                    </GuidePopover>
                </ToolbarItem>
            </Toolbar>

            <Descriptions bordered column={1}>
                <Descriptions.Item label="ID">{watchlist.id}</Descriptions.Item>
                <Descriptions.Item label="Tick">{JSON.stringify(watchlist?.tick)}</Descriptions.Item>
            </Descriptions>

            <table className="watchlist-symbols">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                    {watchlist.symbols.map((symbol) => (
                        <tr key={symbol.name}>
                            <td className="name">{symbol.name}</td>
                            <td className="comment">{symbol.comment}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </StyledWatchlistPage>
    )
}

// styled table for watchlist symbols and comments
const StyledWatchlistPage = styled(Page)`
    table.watchlist-symbols {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        th {
            background-color: #f5f5f5;
            padding: 10px;
            font-weight: bold;
        }
        td {
            padding: 10px;
            border-bottom: 1px solid #ddd;
        }
        .name {
            font-weight: bold;
        }
        .comment {
            color: #888;
        }
        tr:nth-child(even) {
            background-color: #f9f9f9;
        }
    }
`

export default WatchlistPage
