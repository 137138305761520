import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Formatters } from './utils/formatters'

/**
 * Display last updated value
 */
export const AutoUpdateDate: React.FC<{ lastUpdated?: DateTime; updateInterval?: number }> = (props) => {
    const [formattedDate, setFormattedDate] = useState('')
    const [lastUpdated, setLastUpdated] = useState(props.lastUpdated)
    // Use update interval from props or default to 30 seconds
    const [updateInterval, setUpdateInterval] = useState(props.updateInterval || 30)

    useEffect(() => setLastUpdated(props.lastUpdated), [props.lastUpdated])

    useEffect(() => {
        setFormattedDate(Formatters.humanDate(lastUpdated))
        const tid = setInterval(() => {
            setFormattedDate(Formatters.humanDate(lastUpdated))
        }, updateInterval * 1000)
        return () => clearTimeout(tid)
    }, [lastUpdated, updateInterval])

    return (
        <span className="last-updated">
            {/*<Paragraph>Last Updated: {lastUpdated?.toLocaleString(DateTime.DATETIME_MED)}</Paragraph>*/}
            {formattedDate}
        </span>
    )
}

export default AutoUpdateDate
