import { Button, message } from 'antd'
import modal from 'antd/es/modal'
import { useState } from 'react'
import { deskService } from '../../App'
import { mts } from '../../desk_protos'
import { useNavigate } from 'react-router-dom'

/**
 *
 * If props.instance is
 */
export const DeleteButton: React.FC<{
    trader: mts.desk.Trader
}> = (props) => {
    const [modalApi, contextHolder2] = modal.useModal()
    const [messageApi, contextHolder] = message.useMessage()
    const [deleting, setDeleting] = useState(false)
    const [selectedTraders] = useState<mts.desk.Trader[]>([props.trader])
    const navigate = useNavigate()

    const handleDeleteClick = () => {
        if (deleting || !selectedTraders) {
            return
        }

        modalApi.confirm({
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete the selected traders?',
            onOk: () => {
                setDeleting(true)
                deskService
                    .deleteTraders(selectedTraders)
                    .then((resp) => {
                        messageApi.info(`${resp.deletedCount} Traders deleted`)
                        // Redirect to the traders page
                        navigate('/traders')
                    })
                    .catch((err) => console.error(err))
                    .finally(() => setDeleting(false))
            },
            onCancel: () => {
                // Do nothing
            },
        })
    }

    return (
        <>
            {contextHolder}
            {contextHolder2}
            <Button danger onClick={handleDeleteClick} disabled={deleting} className="mts-delete-btn">
                Delete
            </Button>
        </>
    )
}
