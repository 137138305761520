import { Layout } from 'antd'
import styled from 'styled-components'
import TopMenu from './TopMenu'
import { Link } from 'react-router-dom'
import ProfileIcon from './ProfileIcon'

const StyledHeader = styled(Layout.Header)`
height: 45px;
    background: ${(props) => props.theme.antd.colorBgElevated};
    .menu {
        background: ${(props) => props.theme.antd.colorBgLayout};
    }
    border-bottom: 1px solid ${(props) => props.theme.antd.colorBorder};
    .logo {
        color: ${(props) => props.theme.antd.colorText};
        font-size: 20px;
        font-weight: bold;
        padding: ${(props) => props.theme.antd.padding}px;
        padding-top: 0px;
        padding-bottom: 0px;
        text-align: center;
    }
    .logo:hover {
        cursor: pointer;
    }
`

export const MyHeader = () => {
    return (
        <StyledHeader>
            <Link className="logo" to="/">
                MTS
            </Link>
            <TopMenu />
            <ProfileIcon />
        </StyledHeader>
    )
}

export default MyHeader
