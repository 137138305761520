import { CaretRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useState } from 'react'
import { mts } from '../../desk_protos'
import { RunBacktestModal } from '../traders/RunBacktestModal'
import { InstanceNotificationsModal } from './InstanceNotificationsModal'

/**
 *
 * If props.instance is
 */
export const InstanceNotificationsButton: React.FC<{
    instanceId: string
}> = (props) => {
    const [runModalOpen, setRunModalOpen] = useState(false)

    const showRunModal = () => {
        setRunModalOpen(true)
    }

    const handleModalClose = () => {
        setRunModalOpen(false)
    }

    return (
        <div>
            <InstanceNotificationsModal
                open={runModalOpen}
                onClose={handleModalClose}
                instanceId={props.instanceId}
            />
            <Button onClick={showRunModal}>
                Notifications
            </Button>
        </div>
    )
}
