import Table, { ColumnsType } from "antd/es/table"
import React from "react"
import { mts } from "../../desk_protos"

/**
 * Transactions table. Initially load all transactions in descending order, use SSE to update.
 *
 * http://api.mtsbots.com/portfolios
 */
export const PositionsTable: React.FC<{
    positions?: mts.portfolio.Positions
}> = (props) => {
    const { positions } = props

    const columns: ColumnsType<mts.portfolio.IPosition> = [
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            render(value, record, index) {
                return value
            },
        },
        {
            title: "Qty",
            dataIndex: "quantity",
            key: "quantity",
            render: (value) => value,
        },
        {
            title: "Cost Basis",
            dataIndex: "costBasis",
            key: "costBasis",
            render: (value) => value,
        },
        {
            title: "Total Cost",
            dataIndex: "totalCost",
            key: "totalCost",
            render: (value) => value,
        },
        {
            title: "Market Value",
            dataIndex: "",
            key: "marketValue",
            render: (value, position) =>
                position?.quantity && position?.lastPrice ? position.quantity * position.lastPrice : "-",
        },
        {
            title: "Price",
            dataIndex: "lastPrice",
            key: "lastPrice",
            render: (value) => value,
        },
        {
            title: "Gain",
            dataIndex: "gain",
            key: "gain",
            render: (value) => value,
        },
        {
            title: "Gain %",
            dataIndex: "gainPercent",
            key: "gainPercent",
            render: (value) => `${value}%`,
        },
    ]

    return (
        <>
            <Table
                rowKey="symbol"
                key="symbol"
                columns={columns}
                dataSource={positions?.positions}
                pagination={{ hideOnSinglePage: true }}
            />
        </>
    )
}

export default PositionsTable
