import { DeskContextValue } from '../App'
import { mts } from '../desk_protos'
import { mtsFetchProtobuf } from './common'

type Instance = mts.desk.TraderInstance

// move all functions below to it's own class DeskService
export class DeskService {
    private appCtx?: DeskContextValue

    private deskAddr = ''

    public setAppCtx(appCtx: DeskContextValue) {
        this.appCtx = appCtx
        this.deskAddr = appCtx.config.deskAddr
    }

    /**
     *
     * @param url
     * @param init
     * @returns
     * @throws Error on low level fetch error
     * @throws ApiError on any other non-200 status code
     */
    mtsFetchUser = async (url: string, init?: RequestInit): Promise<mts.desk.User> => {
        try {
            const resp = await fetch(url, {
                headers: {
                    Accept: 'application/protobuf',
                },
            })
            if (resp.ok) {
                const data = await resp.arrayBuffer()
                const msg = mts.desk.User.decode(new Uint8Array(data))
                return msg
            }
            const data = await resp.arrayBuffer()
            const apiError = mts.common.ApiErrorResponse.decode(new Uint8Array(data))
            throw apiError
        } catch (err) {
            if (err instanceof mts.common.ApiErrorResponse) {
                throw err.error
            }
            throw new Error(`Failed: url=${url}, error=${JSON.stringify(err)}`)
        }
    }

    async login() {}

    /**
     * Low level fetch routine for a given Protobuf message
     */
    async fetchProtobufOld<T>(url: string, decodeFunc: any, init?: RequestInit): Promise<T> {
        const defaultRequestInit: RequestInit = {
            method: 'GET',
            headers: {
                accept: 'application/protobuf',
                'content-type': 'application/protobuf',
                'x-mts-session-id': this.appCtx === undefined ? '' : this.appCtx.session.id,
            },
        }
        // Merge init defaultInit
        const requestInit: RequestInit = { ...defaultRequestInit, ...init }

        const resp = await fetch(url, requestInit)

        if (resp.ok) {
            const data = await resp.arrayBuffer()
            const msg = decodeFunc(new Uint8Array(data))
            console.debug(`${requestInit.method} ${url} 200 response`, msg)
            return msg
        }

        // If content type is protobuf then assume it's an ApiErrorResponse object
        if (resp.headers.get('content-type') === 'application/protobuf') {
            const data = await resp.arrayBuffer()
            const err = mts.common.ApiErrorResponse.decode(new Uint8Array(data)).error
            console.error(`${requestInit.method} ${url} non-200 response with ApiError`, err)
            throw err
            //throw new Error(`${err?.httpStatus}-${err?.description}: ${err?.detail}`)
        }
        // If content type is not protobuf then assume it's a text error
        const data = await resp.text()
        console.error(`${requestInit.method} ${url} non-200 response with text error`, data)
        throw new Error(`Failed: url=${url}, status=${resp.status}, error=${data}`)
    }

    checkScriptSyntax = async (appCtx: DeskContextValue, code: string) => {
        const request = mts.desk.http.CheckScriptSyntaxRequest.create({
            code,
        })
        const url = `${appCtx.config.deskAddr}/script-engine/check-syntax`

        console.debug(`POST ${url}`, request)

        try {
            const resp = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/protobuf',
                    'Content-type': 'application/protobuf',
                    'x-mts-session-id': appCtx.session.id,
                },
                body: mts.desk.http.CheckScriptSyntaxRequest.encode(request).finish(),
            })
            if (resp.ok) {
                const data = await resp.arrayBuffer()
                const response = mts.desk.http.CheckScriptSyntaxResponse.decode(new Uint8Array(data))
                console.log('Check syntax response', response)
                return response
            }
            const data = await resp.arrayBuffer()
            const apiError = mts.common.ApiErrorResponse.decode(new Uint8Array(data))
            console.log('Check syntax response', resp)
            throw apiError
        } catch (err) {
            console.error(`Check syntax failed: ${err}`)
            if (err instanceof mts.common.ApiErrorResponse) {
                throw err.error
            }
            throw new Error(`Failed: url=${url}, error=${err}`)
        }
    }

    async deleteTraders(traders: mts.desk.Trader[]) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const request = mts.desk.DeleteTradersRequest.create({
            traderIds: traders.map((trader) => trader.id),
        })
        console.log('request', request)
        const url = `${this.appCtx.config.deskAddr}/traders`

        return this.fetchProtobufOld<mts.desk.DeleteTradersResponse>(
            url,
            mts.desk.DeleteTradersResponse.decode,
            {
                method: 'DELETE',
                body: mts.desk.DeleteTradersRequest.encode(request).finish(),
            }
        )
    }

    async deleteInstances(instances: Instance[]) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const request = mts.desk.DeleteInstancesRequest.create({
            instanceIds: instances.map((instance) => instance.id),
        })
        console.log('request', request)
        const url = `${this.appCtx.config.deskAddr}/traderinstances`

        return this.fetchProtobufOld<mts.desk.DeleteTradersResponse>(
            url,
            mts.desk.DeleteTradersResponse.decode,
            {
                method: 'DELETE',
                body: mts.desk.DeleteInstancesRequest.encode(request).finish(),
            }
        )
    }

    async getTraders() {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        let url = `${this.appCtx.config.deskAddr}/traders`
        return this.fetchProtobufOld<mts.desk.http.GetTradersResponse>(
            url,
            mts.desk.http.GetTradersResponse.decode
        )
    }

    async getInstance(
        instanceId: string,
        includeWatchlists: boolean
    ): Promise<mts.desk.http.GetInstanceResponse> {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        let url = `${this.deskAddr}/traderinstances/${instanceId}`
        if (includeWatchlists) {
            url += '?includeWatchlists=true'
        }
        return this.fetchProtobufOld<mts.desk.http.GetInstanceResponse>(
            url,
            mts.desk.http.GetInstanceResponse.decode
        )
    }

    async getInstances(filters: mts.desk.http.GetInstancesRequest.Filters) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        let url = `${this.appCtx.config.deskAddr}/traderinstances`
        return this.fetchProtobufOld<mts.desk.GetTraderInstancesResponse>(
            url,
            mts.desk.GetTraderInstancesResponse.decode
        )
    }

    async deleteInstance(instance: mts.desk.TraderInstance) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const url = `${this.appCtx.config.deskAddr}/traderinstances/${instance.id}`

        return this.fetchProtobufOld<mts.desk.DeleteTradersResponse>(
            url,
            mts.desk.DeleteTradersResponse.decode,
            {
                method: 'DELETE',
                headers: {
                    Accept: 'application/protobuf',
                    'Content-type': 'application/protobuf',
                    'x-mts-session-id': this.appCtx.session.id,
                },
            }
        )
    }

    async runScript(trader: mts.desk.Trader, code: string): Promise<mts.desk.http.PostScriptResponse> {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }

        const request = mts.desk.http.PostScriptRequest.create({
            trader,
            code,
        })

        const url = `${this.deskAddr}/scripts`

        return mtsFetchProtobuf<mts.desk.http.PostScriptResponse>(
            this.appCtx,
            url,
            mts.desk.http.PostScriptResponse.decode,
            {
                method: 'POST',
                body: mts.desk.http.PostScriptRequest.encode(request).finish(),
            }
        )
    }

    async updateInstanceState(instance: mts.desk.TraderInstance, state: mts.desk.TraderInstance.State) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const url = `${this.deskAddr}/traderinstances/${instance.id}/state`

        return this.fetchProtobufOld<mts.desk.TraderInstance>(url, mts.desk.TraderInstance.decode, {
            method: 'PUT',
            body: mts.desk.TraderInstance.State[state],
        })
    }

    async getInstanceOutput(instance: mts.desk.TraderInstance) {
        const url = `${this.deskAddr}/traderinstances/${instance.id}/output`

        return this.fetchProtobufOld<mts.desk.GetTraderInstanceOutputResponse>(
            url,
            mts.desk.GetTraderInstanceOutputResponse.decode
        )
    }

    /**
     * POST's trader to the desk. This in effect creates a new trader instance, if it's a backtest it's immediately run
     *
     * @throws mts.common.ApiError if the trader cannot be added.
     */
    async addTraderInstance(appCtx: DeskContextValue, traderInstance: mts.desk.TraderInstance) {
        const request = mts.desk.AddTraderInstanceRequest.create({
            traderInstance,
        })
        // POST to the desk runner endpoint
        const url = `${this.deskAddr}/traderinstances`

        console.log(`POST ${url} addTraderInstanceRequest`, request)

        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/protobuf',
                'Content-type': 'application/protobuf',
                'x-mts-session-id': appCtx.session.id,
            },
            body: mts.desk.AddTraderInstanceRequest.encode(request).finish(),
        })
        if (resp.ok) {
            const data = await resp.arrayBuffer()
            let response = mts.desk.AddTraderInstanceResponse.decode(new Uint8Array(data))
            return response
        }
        const data = await resp.arrayBuffer()
        const apiError = mts.common.ApiErrorResponse.decode(new Uint8Array(data))
        throw apiError
    }

    async updateInstanceOptionValues(
        instance: mts.desk.TraderInstance,
        optionValues: any
    ): Promise<mts.desk.UpdateInstanceOptionValuesResponse> {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const request = mts.desk.UpdateInstanceOptionValuesRequest.create({
            instanceId: instance.id,
            optionValues: optionValues,
        })
        const url = `${this.deskAddr}/traderinstances/${instance.id}/optionValues`

        console.log(`PUT ${url} updateInstanceOptionValuesRequest`, request)

        return mtsFetchProtobuf<mts.desk.UpdateInstanceOptionValuesResponse>(
            this.appCtx,
            url,
            mts.desk.UpdateInstanceOptionValuesResponse.decode,
            {
                method: 'PUT',
                body: mts.desk.UpdateInstanceOptionValuesRequest.encode(request).finish(),
            }
        )
    }

    // Uses the /users/{userId}/activities endpoint to post a PostActivityRequest
    async addActivity(action: string): Promise<mts.desk.http.PostActivityResponse> {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const activity = mts.desk.http.Activity.create({
            action,
            userId: this.appCtx.session.userId,
        })
        const request = mts.desk.http.PostActivityRequest.create({
            activity,
        })
        const url = `${this.deskAddr}/users/${this.appCtx.session.userId}/activities`

        console.log(`POST ${url} addActivity`, request)

        return mtsFetchProtobuf<mts.desk.http.PostActivityResponse>(
            this.appCtx,
            url,
            mts.desk.http.PostActivityResponse.decode,
            {
                method: 'POST',
                body: mts.desk.http.PostActivityRequest.encode(request).finish(),
            }
        )
    }

    async updateInstanceAccess(instance: mts.desk.TraderInstance, access: mts.desk.Access) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const url = `${this.deskAddr}/traderinstances/${instance.id}/access`

        return mtsFetchProtobuf<pbString>(this.appCtx, url, mts.desk.Access, {
            method: 'PUT',
            body: mts.desk.Access[access],
        })
    }

    async getWatchlist(watchlistId: string) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const url = `${this.deskAddr}/watchlists/${watchlistId}`
        return mtsFetchProtobuf<mts.desk.http.GetWatchlistResponse>(
            this.appCtx,
            url,
            mts.desk.http.GetWatchlistResponse.decode
        )
    }

    async getUserInstanceNotificationSettings(instanceId: string): Promise<mts.desk.http.GetUserInstanceNotificationSettingsResponse> {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const user = this.appCtx.user
        const url = `${this.deskAddr}/users/${user.id}/instances/${instanceId}/notificationsettings`

        return mtsFetchProtobuf<mts.desk.http.GetUserInstanceNotificationSettingsResponse>(
            this.appCtx,
            url,
            mts.desk.http.GetUserInstanceNotificationSettingsResponse.decode
        )
    }

    async putUserInstanceNotificationSettings(instanceId: string, settings: mts.desk.UserInstanceNotificationSetting[]) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const user = this.appCtx.user
        const url = `${this.deskAddr}/users/${user.id}/instances/${instanceId}/notificationsettings`

        const request = mts.desk.http.PutUserInstanceNotificationSettingsRequest.create({
            notificationSettings: settings,
        })

        return mtsFetchProtobuf<mts.desk.UserInstanceNotificationSetting>(
            this.appCtx,
            url,
            undefined,
            {
                method: 'PUT',
                body: mts.desk.http.PutUserInstanceNotificationSettingsRequest.encode(request).finish(),
            }
        )
    }
}

class pbString {
    constructor(public value: string) {}

    decode(data: Uint8Array) {
        return new pbString(new TextDecoder().decode(data))
    }
}
