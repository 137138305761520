import { Editor } from '@monaco-editor/react'
import { useSignal } from '@preact/signals-react'
import {
    Alert,
    Button,
    Checkbox,
    Form,
    Input,
    Layout,
    Menu,
    MenuProps,
    Select,
    Spin,
    Typography,
    message,
    theme,
} from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import Sider from 'antd/es/layout/Sider'
import { Content } from 'antd/es/layout/layout'
import Title from 'antd/es/typography/Title'
import { last } from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Config, DeskContext, DeskContextValue, deskService } from '../../../App'
import AutoUpdateDate from '../../../components/AutoUpdateDate'
import { GuidePopover } from '../../../components/GuidePopover'
import { Toolbar, ToolbarItem } from '../../../components/Toolbar'
import { mts } from '../../../desk_protos'
import { RunBacktestButton } from '../RunBacktestButton'
import { JavascriptEditor, OptionsForm } from './OptionsForm'
import { TraderLabels } from './TraderLabels'
import { DeleteButton } from '../DeleteButton'

const { ApiError } = mts.common
const { Trader, Tick } = mts.desk

const tickTypeOptions = Object.keys(mts.desk.Tick.Type)
    .filter((k) => k !== 'UNKNOWN')
    .map((k) => {
        return {
            label: k,
            value: k,
        }
    })

const saveTrader = async (appCtx: DeskContextValue, trader: mts.desk.Trader) => {
    const request = mts.desk.UpdateTraderRequest.create({ trader })
    const url = `${Config.DESK_ADDR}/traders/${trader.id}`

    // TODO refactor to use deskService.saveTrader
    try {
        const resp = await fetch(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/protobuf',
                'Content-type': 'application/protobuf',
                'x-mts-session-id': appCtx.session.id,
            },
            body: mts.desk.UpdateTraderRequest.encode(request).finish(),
        })
        if (resp.ok) {
            const data = await resp.arrayBuffer()
            const response = mts.desk.UpdateTraderResponse.decode(new Uint8Array(data))
            return response
        }
        const data = await resp.arrayBuffer()
        const apiError = mts.common.ApiErrorResponse.decode(new Uint8Array(data))
        console.log('Save trader response', resp)
        throw apiError
    } catch (err) {
        console.error('Failed to add trader', err)
        if (err instanceof mts.common.ApiErrorResponse) {
            throw err.error
        }
        throw new Error(`Failed: url=${url}, error=${JSON.stringify(err)}`)
    }
}

const save = async (
    trader: mts.desk.Trader,
    form: any,
    name: string,
    description: string,
    labelsParam: string[],
    appCtx: DeskContextValue
) => {
    let formValues = form.getFieldsValue()

    // formValues.access is a string for mts.desk.Access, converto enum index
    const accessEnum = mts.desk.Access[formValues.access as keyof typeof mts.desk.Access]

    // convert labels arg which is a string[] to a [k: string]: boolean object
    const labelsObj: { [k: string]: boolean } = {}
    labelsParam.forEach((label) => {
        labelsObj[label] = true
    })

    // Construct new trader object using editor values
    const newTrader = Trader.create({
        ...trader,
        name: name,
        access: accessEnum,
        tickHandlers: trader.tickHandlers,
        description: description,
        executeTickTypes: trader.executeTickTypes,
        labels: labelsObj,
    })

    console.log('Saving trader', newTrader, formValues)
    if (!appCtx) {
        throw new Error('We need the app context to be set...')
    }
    return saveTrader(appCtx, newTrader)
}

/**
 *
 * @param props Trader form component in it's entirety. This component can configure any trader
 * in the system and provides Run buttons. It includes everything from the Form title, to submission logic
 * and submit buttons.
 */
const TraderEditor: React.FC<{
    trader: mts.desk.Trader
}> = (props) => {
    const [trader, setTrader] = useState(props.trader)
    const [activeMenuKey, setActiveMenuKey] = useState<string>()
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<string>()
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState<any>()
    const appCtx = React.useContext(DeskContext)
    const [messageApi, contextHolder] = message.useMessage()
    const navigate = useNavigate()
    const [quickRunLoading, setQuickRunLoading] = useState(false)
    const [savedDate, setSavedDate] = useState<DateTime>()
    const [quickrunResponse, setQuickrunResponse] = useState<mts.desk.http.PostScriptResponse>()
    const [dirty, setDirty] = useState(false)

    const saveTimerId = useSignal<NodeJS.Timeout | undefined>(undefined)
    const descriptionSignal = useSignal(props.trader.description)
    const nameSignal = useSignal(props.trader.name)
    const labelsSignal = useSignal(Object.keys(props.trader.labels))
    const optionsSignal = useSignal(props.trader.options as mts.desk.TraderOption[])

    const getTickTypeIndexValue = (enumName: any) => mts.desk.Tick.Type[enumName]

    // Handle error change
    useEffect(() => {
        if (!error) {
            return
        }
        messageApi.error(error)
    }, [error, messageApi])

    // Load from props.trader change
    useEffect(() => {
        console.log('Loading from props.trader', props.trader)
        setTrader(props.trader)

        // If there is a hash, use it to set the active menu key, the hash will look like menukey=GENERAL
        const hash = window.location.hash
        if (hash) {
            const menuKey = last(hash.split('='))
            setActiveMenuKey(menuKey)
        } else {
            setActiveMenuKey('GENERAL')
        }
    }, [form, props.trader])

    // When trader changes, load the form with the new trader values
    useEffect(() => {
        console.log('Loading Trader', trader)
        let newInitialValues: any = {
            name: trader.name,
            labels: trader.labels,
            tickTypes: trader.executeTickTypes,
            access: String(mts.desk.Access[trader.access]),
        }
        console.debug('load initial values', newInitialValues)
        setInitialValues(newInitialValues)
        form.setFieldsValue(newInitialValues)

        //setActiveMenuKey("GENERAL")
    }, [form, props.trader])

    const mysave = useCallback(
        async (saveNow = false) => {
            const s = async () => {
                try {
                    setError(undefined)
                    await save(
                        trader,
                        form,
                        nameSignal.value,
                        descriptionSignal.value,
                        labelsSignal.value,
                        appCtx
                    )
                    setSavedDate(DateTime.now())
                } catch (err) {
                    if (err instanceof ApiError) {
                        let { code, detail, description } = err as mts.common.ApiError
                        setError(`Failed to save trader: (${code}-${description}): ${detail}`)
                    } else {
                        console.error('Unknown error saving trader', err)
                        setError(`Unknown error saving trader, check console`)
                    }
                } finally {
                    setDirty(false)
                }
            }

            // if there is already a schedule save active, cancel it
            clearTimeout(saveTimerId.value)

            if (saveNow) {
                await s()
                return
            }

            // Schedule a save in 2 seconds
            setDirty(true)
            saveTimerId.value = setTimeout(s, 2000)

            //setSaving(false)
        },
        [appCtx, form, trader]
    )

    // Ensure trader is saved on unmount
    useEffect(() => {
        return () => {
            console.log('TraderEditor unmounting, saving trader')
            if (dirty) {
                mysave(true)
            }
        }
    }, [])

    const onMenuSelect = (item: any) => {
        navigate(`${window.location.pathname}#menukey=${item.key}`)
        setActiveMenuKey(item.key)
        console.log(`Active menu key set to ${item.key}`)
    }

    const handleCodeChange = async (tickType: mts.desk.Tick.Type, code: string) => {
        console.log('Code change')

        trader.tickHandlers[mts.desk.Tick.Type[tickType]] = code
        mysave()
    }

    // Returns true if the following
    const getDisplayValue = (menuKey: string) => {
        if (activeMenuKey === menuKey) {
            return 'flex'
        }
        return 'none'
    }

    const handleExitEditorClick = () => {
        navigate(`/traders/${trader.id}`)
    }

    const eventHandlers = trader.executeTickTypes.map((wantedTickType) => ({
        label: mts.desk.Tick.Type[wantedTickType],
        key: mts.desk.Tick.Type[wantedTickType],
    }))

    const items: MenuProps['items'] = [
        { key: 'GENERAL', label: 'General' },
        { key: 'OPTIONS', label: 'Options' },
        {
            label: 'Event Handlers',
            key: 'event_handlers',
            children: eventHandlers,
        },
    ]

    const handleTickTypesChange = (values: CheckboxValueType[]) => {
        setTrader(mts.desk.Trader.create({ ...trader, executeTickTypes: values as mts.desk.Tick.Type[] }))
        mysave()
    }

    useEffect(() => {
        if (quickrunResponse) {
            messageApi.info('Finished')
            console.log('================= Quick run output===========================')
            console.log(quickrunResponse.stdout)
            console.log('==============================================================')
        }
    }, [messageApi, quickrunResponse])

    const quickrun = () => {
        const code = trader.tickHandlers[activeMenuKey as string]

        console.log(`Quick run, activeMenuKey=${activeMenuKey}`)
        setQuickRunLoading(true)
        deskService
            .runScript(trader, code)
            .then((resp) => setQuickrunResponse(resp))
            .catch((e) => setError(`${e.code}-${e.description}: ${e.detail}`))
            .finally(() => setQuickRunLoading(false))
    }

    const handleOptionsChange = (options: mts.desk.TraderOption[]) => {
        //setTrader(mts.desk.Trader.create({ ...trader, options: options }))

        mysave()
    }

    const handleDescriptionChange = (description: any) => {
        descriptionSignal.value = description
        mysave()
    }

    const handleNameChange = (name: any) => {
        nameSignal.value = name.target.value
        mysave()
    }

    const handleLabelsChange = (labels: string[]) => {
        console.log('labels updated, queuing save', labels)
        labelsSignal.value = labels
        mysave()
    }

    const handleAccessChange = (access: any) => {
        console.log('Access changed', access)
        //accessSignal.value = access
        mysave()
    }

    // List of access options using enum mts.desk.Access
    const accessOptions = Object.keys(mts.desk.Access)
        .filter((k) => k !== 'UNKNOWN_ACCESS')
        .map((k) => {
            return {
                // capitalize first letter only
                label: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
                value: k,
            }
        })
    const generalTab = (
        <div style={{ display: getDisplayValue('GENERAL'), flex: 1 }}>
            <Form
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 25 }}
                form={form}
                initialValues={initialValues}
                requiredMark={true}
                name="trader-general"
                style={{ width: '100%', maxWidth: 1000 }}
            >
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input onChange={handleNameChange} />
                </Form.Item>

                <Form.Item name="access" label="Access">
                    <Select options={accessOptions} style={{ width: 100 }} onChange={handleAccessChange} />
                </Form.Item>

                <Form.Item
                    label="Description"
                    extra="Using markdown, describe the Trader. This description show on the Trader and Instance pages"
                    className="description-editor"
                >
                    <Editor
                        language="markdown"
                        value={trader.description}
                        onChange={handleDescriptionChange}
                        height={'400px'}
                        // hide minimap
                        options={{ minimap: { enabled: false } }}
                        theme={appCtx.settings.themeName === 'dark' ? 'vs-dark' : 'vs-light'}
                    />
                </Form.Item>

                <Form.Item name="labels" label="Labels">
                    {/* @ts-ignore */}
                    <TraderLabels labels={labelsSignal.value} onChange={handleLabelsChange} />
                </Form.Item>

                <Form.Item name="tickTypes" label="Tick Types">
                    <Checkbox.Group style={{ width: '100%' }} onChange={handleTickTypesChange}>
                        {tickTypeOptions.map((tickType) => (
                            <div style={{ display: 'block', width: '100%' }} key={tickType.value}>
                                <Checkbox value={getTickTypeIndexValue(tickType.value)}>
                                    {tickType.value}
                                </Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </div>
    )

    return (
        <Wrapper>
            {contextHolder}
            <Toolbar>
                <ToolbarItem>
                    <Title>
                        <Link to="/traders">Traders: </Link> {trader.name}
                    </Title>
                </ToolbarItem>
                <ToolbarItem>
                    {saving && (
                        <>
                            <Typography.Paragraph>
                                <Spin /> Saving
                            </Typography.Paragraph>
                        </>
                    )}
                    <Typography.Paragraph>
                        {savedDate && (
                            <>
                                Last saved: <AutoUpdateDate updateInterval={5} lastUpdated={savedDate} />
                            </>
                        )}
                    </Typography.Paragraph>
                </ToolbarItem>
                <div style={{ flex: 1 }}>&nbsp;</div>
                <ToolbarItem>
                    <Button onClick={quickrun} loading={quickRunLoading}>
                        Quick Run
                    </Button>
                </ToolbarItem>
                <ToolbarItem>
                    <GuidePopover storageKey="run_backtest_guide" content="Click on the Run Backtest button">
                        <RunBacktestButton trader={trader} disabled={false} />
                    </GuidePopover>
                </ToolbarItem>
                <ToolbarItem>
                    <DeleteButton trader={trader} />
                </ToolbarItem>
                <ToolbarItem>
                    <Button onClick={handleExitEditorClick}>Exit Editor</Button>
                </ToolbarItem>
            </Toolbar>
            {error && <Alert message={error} type="error" />}
            <Layout style={{ flex: 1, display: 'flex' }}>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        openKeys={['event_handlers']}
                        onSelect={onMenuSelect}
                        selectedKeys={[activeMenuKey as string]}
                        selectable={true}
                        items={items}
                    />
                </Sider>

                <Content style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                    {generalTab}
                    <div style={{ display: getDisplayValue('OPTIONS'), flex: 1 }}>
                        <OptionsForm options={optionsSignal} onChange={handleOptionsChange} />
                    </div>
                    {trader.executeTickTypes.map((tickType) => {
                        return (
                            <div
                                key={tickType}
                                style={{ display: getDisplayValue(Tick.Type[tickType]), flex: 1 }}
                            >
                                <JavascriptEditor
                                    tickType={tickType}
                                    code={trader.tickHandlers[Tick.Type[tickType]]}
                                    onChange={handleCodeChange}
                                />
                            </div>
                        )
                    })}
                </Content>
            </Layout>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    .ant-layout {
        background: ${(props) => props.theme.antd.colorBgBase};
    }
    .ant-layout-content {
        padding-left: ${(props) => props.theme.antd.padding}px;
    }
    .ant-layout-sider {
        background: ${(props) => props.theme.antd.colorBgBase};
    }
    .description-editor {
        section {
            border: 1px solid ${(props) => props.theme.antd.colorBorder};
            border-radius: ${(props) => props.theme.antd.borderRadius}px;
        }
    }
`

export default TraderEditor
