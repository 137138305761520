import { Modal } from 'antd'
import { mts } from '../../desk_protos'
import { useEffect, useState } from 'react'
import { useSignal } from '@preact/signals-react'
import { OptionValues } from './TraderInstancePage'
import { OptionValueItemsForm } from '../../components/instances/OptionValueItemsForm'

/**
 * Modal for editing instance option values
 */
export const EditOptionValuesModal: React.FC<{
    instance: mts.desk.TraderInstance
    options: mts.desk.ITraderOption[]
    optionValues: OptionValues
    open: boolean
    onClose: () => void
    onSave: (optionValues: OptionValues) => void
}> = (props) => {
    const [options] = useState(props.instance.trader?.options)
    const optionValues = useSignal(props.optionValues)

    // When props.optionValues change update optionValues
    useEffect(() => {
        optionValues.value = props.optionValues
    }, [optionValues, props.optionValues])

    const handleOptionValuesChange = (values: OptionValues) => {
        optionValues.value = values
    }

    const handleOk = () => {
        console.log('Calling props.onSave()', optionValues)
        props.onSave(optionValues.value)
    }

    return (
        <Modal
            title="Edit Option Values"
            open={props.open}
            onCancel={props.onClose}
            okText="Save"
            onOk={handleOk}
            cancelText="Cancel"
        >
            <OptionValueItemsForm
                options={options as mts.desk.TraderOption[]}
                optionValues={optionValues.value}
                onOptionValuesChange={handleOptionValuesChange}
            />
        </Modal>
    )
}
