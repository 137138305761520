import { DeskContextValue } from '../App'
import { mts } from '../desk_protos'

/**
 * Low level fetch routine for a given Protobuf message from MTS backend that uses Protobuf. Logs at least one console.log per call
 *
 * @throws mts.common.ApiError
 */
export async function mtsFetchProtobuf<T>(
    appCtx: DeskContextValue,
    url: string,
    decodeFunc: any,
    init?: RequestInit,
): Promise<T> {
    // Add the 'async' keyword and remove 'export'
    const defaultRequestInit: RequestInit = {
        method: 'GET',
        headers: {
            accept: 'application/protobuf',
            'content-type': 'application/protobuf',
            'x-mts-session-id': appCtx === undefined ? '' : appCtx.session.id,
        },
    }
    // Merge init defaultInit
    const requestInit: RequestInit = { ...defaultRequestInit, ...init }

    console.info(`${requestInit.method} ${url} request`, requestInit)
    const resp = await fetch(url, requestInit)
    
    if (resp.ok) {
        const data = await resp.arrayBuffer()
        // If no decodeFunc then discard the response
        if (!decodeFunc) {
            return {} as T
        } 
        try {
            const msg = decodeFunc(new Uint8Array(data))
            console.log(`${requestInit.method} ${url} response`, msg)
            return msg
        } catch (err) {
            console.error(`${requestInit.method} ${url} response decode error`, err)
            throw new mts.common.ApiError({
                description: 'Failed to decode response',
                detail: err as string,
            })
        }
    }

    // If content type is protobuf then assume it's an ApiErrorResponse object
    if (resp.headers.get('content-type') === 'application/protobuf') {
        const data = await resp.arrayBuffer()
        const err = mts.common.ApiErrorResponse.decode(new Uint8Array(data)).error
        console.error(`${requestInit.method} ${url} non-200 response with ApiError`, err)
        throw err
    }
    // If content type is not protobuf then assume it's a text error, but wrap in ApiError
    const data = await resp.text()
    console.error(`${requestInit.method} ${url} non-200 response with text error`, data)
    throw new mts.common.ApiError({
        code: resp.status,
        description: 'Failed to fetch',
        detail: data || `[empty response from ${url}]`,
        httpStatus: resp.status,
    })
}
