import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const menuItems = [
    { key: '/traders', label: 'Traders', locationPath: '/traders' },
    { key: '/instances', label: 'Instances', locationPath: '/instances' },
    { key: '/portfolios', label: 'Portfolios', locationPath: '/portfolios' },
    { key: '/sandbox', label: 'Sandbox', locationPath: '/sandbox' },
]

const TopMenu: React.FC = () => {
    const location = useLocation()
    const [selectedKey, setSelectedKey] = useState('/instances')

    // update the selected key when the location changes
    useEffect(() => {
        const selectedItem = menuItems.find((item) => location.pathname.startsWith(item.locationPath))
        if (selectedItem) {
            setSelectedKey(selectedItem.key)
        } else {
            setSelectedKey('')
        }
    }, [location])

    // get classnames for a menu item taking into account the current location
    const classNames = (itemKey: string) => {
        return itemKey === selectedKey ? 'menu-item selected' : 'menu-item';
    }

    return (
        <Wrapper>
            {menuItems.map((item) => (
                <Link to={item.key} className={classNames(item.key)} key={item.key}>
                    {item.label}
                </Link>
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    a.menu-item {
        padding: 0px 6px;
        padding-top: 0px;
        text-decoration: none;
        color: ${(props) => props.theme.antd.colorTextBase};
    }
    a.menu-item.selected {
        color: ${(props) => props.theme.antd.colorLink};
    }

    a.menu-item:hover {
        background: ${(props) => props.theme.antd.colorBgHover};
    }
`

export default TopMenu
