import { Alert, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Config, DeskContext } from '../../App'
import { Formatters } from '../../components/utils/formatters'
import { mts } from '../../desk_protos'
import { useMediaQuery } from 'react-responsive'

const desktopColumns: ColumnsType<mts.portfolio.Portfolio> = [
    /*{
        title: "Id",
        dataIndex: "id",
        key: "id",
        render(value, record, index) {
            return <Link to={`/portfolios/${value}`}>{value}</Link>
        },
    },*/
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, portfolio) => <Link to={`/portfolios/${portfolio.id}`}>{name}</Link>,
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        render: (created: string, portfolio) => Formatters.humanDate(DateTime.fromISO(created)),
    },
    {
        title: 'Return',
        dataIndex: 'returnAll',
        key: 'returnAll',
        render: (returnAll, portfolio) => returnAll + '%',
    },
]

const mobileColumns: ColumnsType<mts.portfolio.Portfolio> = [
    {
        title: 'Portfolio',
        dataIndex: 'id',
        key: 'id',
        render: (name, portfolio) => <PortfolioSummary portfolio={portfolio} />,
    }
]

const PortfolioSummary: React.FC<{ portfolio: mts.portfolio.Portfolio }> = (props) => {
    const { portfolio } = props
    return (
        <Space direction="vertical">
            <Link to={`/portfolios/${portfolio.id}`}>{portfolio.name}</Link>
            <Space>
                <span>Created: {Formatters.humanDate(DateTime.fromISO(portfolio.created))}</span>
                <span>Return: {portfolio.returnAll}%</span>
            </Space>
        </Space>
    )
}

const PortfoliosTable: React.FC<{
    lastLoaded?: Date

}> = (props) => {
    const [portfolios, setPortfolios] = React.useState<any[]>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<string>('')

    const isPhone = useMediaQuery({ query: '(max-width: 600px)' })
    const isTablet = useMediaQuery({ query: '(max-width: 992px)' })
    
    const appCtx = React.useContext(DeskContext)
    
    const load = React.useCallback(async () => {
        const url = `${Config.PORTFOLIO_ADDR}/portfolios?sort=id+DESC&userId=${appCtx.session.userId}`
        try {
            const resp = await fetch(url, {
                headers: {
                    Accept: 'application/protobuf',
                    'x-mts-session-id': appCtx === undefined ? '' : appCtx.session.id
                },
            })
            const data = await resp.arrayBuffer()
            const portfoliosResponse = mts.portfolio.GetPortfoliosResponse.decode(new Uint8Array(data))
            console.log('Fetched portfolios', portfoliosResponse)
            setPortfolios(portfoliosResponse.portfolios)
            setLoading(false)
        } catch (err) {
            console.log('err', err)
            const msg = `Failed to fetch traders from ${url} - ${err}`
            //message.error(`Failed to fetch traders from ${url} - ${err}`)
            setError(String(msg))
        }
    }, [])

    // Load when props.lastLoaded changes, this includes onmount
    useEffect(() => {
        load()
    }, [load, props.lastLoaded])

    if (error) {
        return <Alert message={error} type="error" showIcon />
    }

    const columns = isPhone || isTablet ? mobileColumns : desktopColumns

    return (
        <>
            <Space style={{ marginBottom: '10px' }}>
                
            </Space>
            <div className="full-height-table">
                <Table
                    className="table-fillheight"
                    scroll={{ x: 'none', y: '100vh' }}
                    loading={loading}
                    key="id"
                    rowKey="id"
                    columns={columns}
                    dataSource={portfolios}
                    pagination={{ pageSize: 100 }}
                />
            </div>
        </>
    )
}

export default PortfoliosTable
