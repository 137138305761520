import { Alert, Checkbox, Spin, theme } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { ColorType, DeepPartial, IChartApi, TimeChartOptions, createChart } from 'lightweight-charts'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { Config } from '../../App'
import { mts } from '../../desk_protos'
import styled from 'styled-components'

export const PortfolioChart: React.FC<{
    portfolioId: string
    refresh?: number
}> = (props) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [historyResponse, setHistoryResponse] = useState<mts.portfolio.PerformanceResponse>()
    const [chart, setChart] = useState<IChartApi>()
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(['Cash', 'Total Value'])

    const chartRef = useRef<HTMLDivElement>(null)

    const token = theme.useToken().token

    useEffect(() => {
        console.log('props.refresh updated', props.refresh)
    }, [props.refresh])

    useEffect(() => {
        console.log(`props.portfolioId updated`, props.portfolioId)
    }, [props.portfolioId])

    // When portfolio is update check if the chart needs to be updated
    useEffect(() => {
        const loadData = async () => {
            const url = `${Config.PORTFOLIO_ADDR}/portfolios/${props.portfolioId}/performance`
            console.log(`Loading performance data from ${url}`)
            try {
                const resp = await fetch(url, {
                    headers: {
                        Accept: 'application/protobuf',
                    },
                })
                const performanceResponse = mts.portfolio.PerformanceResponse.decode(
                    new Uint8Array(await resp.arrayBuffer()),
                )
                console.debug('performanceResponse', performanceResponse)
                setHistoryResponse(performanceResponse)
                setLoading(false)
            } catch (err) {
                const msg = `Failed to fetch performance data from ${url} - ${err}`
                console.error(err)
                setError(String(msg))
            }
        }

        loadData()
    }, [props.portfolioId, props.refresh])

    useEffect(() => {
        if (!chart) {
            return
        }
        const { colorBgBase, colorBorderBg, colorText } = token
        const options = {
            layout: {
                textColor: colorText,

                background: {
                    type: ColorType.Solid,
                    color: colorBgBase,
                },
            },
            grid: {
                vertLines: { color: colorBorderBg },
                horzLines: { color: colorBorderBg },
            },
        }
        chart.applyOptions(options)
        console.log('Applying options to chart', options)
    }, [chart, token])

    // Updates the chart when the historyResponse changes
    useEffect(() => {
        if (!historyResponse) {
            return
        }

        const chartOptions: DeepPartial<TimeChartOptions> = {
            handleScroll: {
                mouseWheel: false,
                pressedMouseMove: false,
                horzTouchDrag: false,
                vertTouchDrag: false,
            },
            handleScale: {
                axisPressedMouseMove: false,
                mouseWheel: false,
                pinch: false,
            },
            height: chartRef.current?.clientHeight,
            width: chartRef.current?.clientWidth,
        }

        console.log('Creating chart', chartOptions)

        const chart: IChartApi = createChart(chartRef.current as HTMLElement, chartOptions)
        console.log('createChart() = ', chart)
        setChart(chart)
        const lineSeries = chart.addLineSeries({ lineWidth: 1, color: '#cccccc' })

        const data: any = historyResponse?.days.map((day) => {
            return {
                value: day.cash as number,
                time: DateTime.fromISO(day.date as string).toUnixInteger(),
            }
        })
        //lineSeries.setData(data)

        addTotalValuesSeries(chart, historyResponse?.days as mts.portfolio.PerformanceResponse.IDay[])

        chart.timeScale().fitContent()

        return () => {
            console.log('Unmounting chart')
            chart.remove()
        }
    }, [historyResponse])

    const addTotalValuesSeries = (chart: IChartApi, days: mts.portfolio.PerformanceResponse.IDay[]) => {
        const series = chart.addBaselineSeries({
            baseValue: { type: 'price', price: 25 },
            topLineColor: 'rgba( 38, 166, 154, 1)',
            topFillColor1: 'rgba( 38, 166, 154, 0.28)',
            topFillColor2: 'rgba( 38, 166, 154, 0.05)',
            bottomLineColor: 'rgba( 239, 83, 80, 1)',
            bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
            bottomFillColor2: 'rgba( 239, 83, 80, 0.28)',
            lineWidth: 1,
        })

        const data: any = days.map((day) => {
            return {
                value: day.totalValue as number,
                time: DateTime.fromISO(day.date as string).toUnixInteger(),
            }
        })
        console.log('Adding totalValuesSeries', data)
        series.setData(data)
    }

    const onChange = (list: CheckboxValueType[]) => {
        console.log('Selected performance values', list)
        // clear all
        //chart?.removeSeries
        if (chart) {
            addTotalValuesSeries(chart, historyResponse?.days as mts.portfolio.PerformanceResponse.IDay[])
        }
        setCheckedList(list)
    }

    return (
        <WrapperDiv className="mts-portfolio-chart-ct">
            {loading && <Spin />}
            {error && <Alert message="Error" description={error} type="error" showIcon />}

            {/*<div>
                <Checkbox.Group options={['Cash', 'Total Value']} value={checkedList} onChange={onChange} />
    </div>*/}
            <div className="mts-chartref-ct">
                <div className="mts-chartref-chart" ref={chartRef} />
            </div>
        </WrapperDiv>
    )
}

const WrapperDiv = styled.div`
    display: flex;
    bottom: 0px;
    top: 0px;
    height: 100%;
    flex-direction: column;
    .mts-chartref-ct {
        display: flex;
        flex: 1;
    }
    .mts-chartref-chart {
        height: 300px;
        width: 100%;
        display: flex;
    }
    .mts-portfolio-chart-ct {
        height: 300px;
        border: 1px solid blue;
    }
`

export default PortfolioChart
