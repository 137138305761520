import { PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { Alert, Button, Tag, message } from 'antd'
import modal from 'antd/es/modal'
import Table, { ColumnsType } from 'antd/es/table'
import { TableRowSelection } from 'antd/es/table/interface'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { deskService } from '../App'
import { GuidePopover } from '../components/GuidePopover'
import { Toolbar, ToolbarItem } from '../components/Toolbar'
import { FullHeightTablePageWrapper } from '../components/pages'
import { Formatters } from '../components/utils/formatters'
import { mts } from '../desk_protos'
import NewTraderModal from './traders/NewTraderModal'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

const desktopColumns: ColumnsType<mts.desk.Trader> = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render(value, trader, index) {
            return <Link to={`/traders/${trader.id}`}>{trader.name}</Link>
        },
    },
    {
        title: 'Access',
        dataIndex: 'access',
        key: 'access',
        render: (value) => mts.desk.Access[value],
    },
    {
        title: 'Labels',
        dataIndex: 'labels',
        key: 'labels',
        render: (labels) => {
            return Object.keys(labels).map((k) => <Tag key={k}>{k}</Tag>)
        },
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        render: (value, trader) => Formatters.humanDateISO(trader.created),
    },
    {
        title: 'Updated',
        dataIndex: 'updated',
        key: 'updated',
        render: (value, trader) => Formatters.humanDateISO(trader.updated),
    },
    // Owner columm, which is the username, only display if public
    {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
        render: (value, trader) => (
            <Link to={`/users/${trader.owner?.username}`}>{trader.owner?.username}</Link>
        ),
    },
]

const phoneColumns: ColumnsType<mts.desk.Trader> = [
    {
        title: 'Trader',
        dataIndex: 'name',
        key: 'name',
        render(value, trader, index) {
            return <TraderSummary trader={trader} />
        },
    },
]

/**
 * Render a multiline summary of a trader in a structured formate to be viewed in a table
 */
const TraderSummary: React.FC<{ trader: mts.desk.Trader }> = (props) => {
    const trader = props.trader
    return (
        <StyledTraderSummary className="mts-trader-summary">
            <div className="name">
                <Link to={`/traders/${trader.id}`}>{trader.name}</Link>
            </div>
            <div className="access">
                <span className="access">Access</span> {mts.desk.Access[trader.access]}
            </div>
            <div className="labels">
                <span className="label">Labels:</span>{' '}
                {Object.keys(trader.labels).map((k) => (
                    <Tag key={k}>{k}</Tag>
                ))}
            </div>
            <div className="created">
                <span className="mts-trader-label">Created</span> {Formatters.humanDateISO(trader.created)}
            </div>
            <div className="updated">
                <span className="label">Updated</span> {Formatters.humanDateISO(trader.updated)}
            </div>
        </StyledTraderSummary>
    )
}

const StyledTraderSummary = styled.div``

const TradersTable: React.FC<{
    onSelectTraders: (trader: mts.desk.Trader[]) => void
    reloadTrigger?: number
}> = (props) => {
    const [traders, setTraders] = React.useState<any[]>()
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState<string>('')

    const isPhone = useMediaQuery({ query: '(max-width: 600px)' })

    const load = useCallback(() => {
        console.log(`Reload trigger ${props.reloadTrigger} loading traders...`)
        setLoading(true)
        deskService
            .getTraders()
            .then((resp) => setTraders(resp.traders))
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false))
    }, [props.reloadTrigger])

    useEffect(() => {
        if (error) {
            console.error('Error loading traders', error)
        }
    }, [error])

    useEffect(() => {
        if (!loading) {
            setLoading(true)
            load()
        }
    }, [load, props.reloadTrigger])

    useEffect(() => {
        console.debug('Traders updated', traders)
    }, [traders])

    const columns: ColumnsType<mts.desk.Trader> = isPhone ? phoneColumns : desktopColumns

    if (error) {
        return <div>Error: {error}</div>
    }

    const rowSelection: TableRowSelection<mts.desk.Trader> = {
        type: 'checkbox',

        onChange: (keys, selectedRows: mts.desk.Trader[]) => {
            props.onSelectTraders(selectedRows)
        },
    }

    return (
        <>
            <Table
                className="table-fillheight"
                scroll={{ x: 'none', y: '100vh' }}
                loading={loading}
                key="id"
                rowKey="id"
                columns={columns}
                dataSource={traders}
                pagination={{
                    pageSize: 50,
                    size: 'small',
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
                rowSelection={rowSelection}
                size="small"
            />
        </>
    )
}

const TradersPage: React.FunctionComponent<{}> = (props) => {
    const [newTraderModalOpen, setNewTraderModalOpen] = useState(false)
    const [selectedTraders, setSelectedTraders] = useState<mts.desk.Trader[]>([])
    const [deleting, setDeleting] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()
    const [modalApi, contextHolder2] = modal.useModal()
    const [backtestDisabled, setBacktestDisabled] = useState(true)
    const [deleteDisabled, setDeleteDisabled] = useState(true)
    const [reloadTradersTrigger, setReloadTradersTrigger] = useState(0)

    const handleNewTraderClick = () => {
        setNewTraderModalOpen(true)
    }
    // fetch last element in selectedTraders
    const lastTrader = selectedTraders[selectedTraders.length - 1]
    const handleDeleteClick = () => {
        if (deleting || !selectedTraders) {
            return
        }

        modalApi.confirm({
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete the selected traders?',
            onOk: () => {
                setDeleting(true)
                deskService
                    .deleteTraders(selectedTraders)
                    .then((resp) => {
                        messageApi.info(`${resp.deletedCount} Traders deleted`)
                        setReloadTradersTrigger(new Date().getTime())
                    })
                    .catch((err) => console.error(err))
                    .finally(() => setDeleting(false))
            },
            onCancel: () => {
                // Do nothing
            },
        })
    }

    const [showHelp, setShowHelp] = useState(localStorage.getItem('help.traders') === null)

    // when show help changes, save
    useEffect(() => {
        window.localStorage.setItem('help.traders', showHelp ? 'true' : 'false')
    }, [showHelp])

    const handleSelectTraders = (traders: mts.desk.Trader[]) => {
        console.log('selectedTraders', traders)
        setSelectedTraders(traders)
        setDeleteDisabled(traders.length > 0 ? false : true)
        setBacktestDisabled(traders.length > 0 ? false : true)
    }

    const handleReloadClicked = () => {
        setReloadTradersTrigger(new Date().getTime())
    }

    const handleNewTraderClose = () => {
        setNewTraderModalOpen(false)
    }

    const help = showHelp ? (
        <Alert
            closable={true}
            showIcon={false}
            type="info"
            message="Traders are programs that implement a trading strategy, or trading tools. 
                With a Trader you can run Backtests and Create Instances."
            onClose={() => setShowHelp(false)}
        ></Alert>
    ) : null

    return (
        <FullHeightTablePageWrapper>
            {help}
            {contextHolder}
            {contextHolder2}
            <NewTraderModal open={newTraderModalOpen} onClose={handleNewTraderClose} />

            <Toolbar>
                <ToolbarItem>
                    <Button onClick={handleReloadClicked}>
                        <ReloadOutlined />
                    </Button>
                </ToolbarItem>
                <div style={{ flex: 1 }} />
                <ToolbarItem>
                    <Button disabled={deleteDisabled} loading={deleting} danger onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </ToolbarItem>
                <ToolbarItem>
                    <GuidePopover storageKey="new_trader" content="Click on the New Trader button">
                        <Button type="primary" className="mts-new-trader-btn" onClick={handleNewTraderClick}>
                            <PlusOutlined />
                            New Trader
                        </Button>
                    </GuidePopover>
                </ToolbarItem>
            </Toolbar>

            <div className="full-height-table">
                <TradersTable reloadTrigger={reloadTradersTrigger} onSelectTraders={handleSelectTraders} />
            </div>
        </FullHeightTablePageWrapper>
    )
}

export default TradersPage
