import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import GridLayout from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import styled from 'styled-components'
import PortfolioPositionsWidget from './workspaces/widgets/PortfolioPositionsWidget'

export const WorkspacesPage: React.FC<{}> = (props) => {
    // layout is an array of objects, see the demo for more complete usage

    return (
        <StyledPage>
            <Layout>
                <Content>
                    <GridLayout
                        className="layout"
                        cols={12}
                        rowHeight={50}
                        width={1200}
                        autoSize={false}
                        isResizable={true}
                        isDraggable={false}
                    >
                        <WidgetWrapper
                            className="widget portfolio-monitor"
                            key="b"
                            data-grid={{ i: 'b', x: 0, y: 0, w: 8, h: 6 }}
                        >
                            <PortfolioPositionsWidget></PortfolioPositionsWidget>
                        </WidgetWrapper>
                    </GridLayout>
                </Content>
            </Layout>
        </StyledPage>
    )
}

export const WidgetContent = styled.div`
    overflow: auto;
    flex: 1;
    display: flex;
    .ant-tabs-nav {
        margin-bottom: 0px;
    }
`

export const WidgetFullHeightTableContent = styled.div`
    overflow: auto;
    flex: 1;
    display: flex;
`

export const StyledPage = styled.div`
    flex: 1;
    display: flex;
    .layout {
    }
    .full-height-table {
        display: flex;
        position: relative;
        flex: 1;
    }

    .widget.portfolio-monitor {
        .ant-descriptions-header {
            margin-bottom: ${(props) => props.theme.antd.padding / 2}px;
        }
        .ant-descriptions-item {
            padding-bottom: ${(props) => props.theme.antd.padding / 2}px;
        }
    }
`

export const WidgetWrapper = styled.div`
    border: 2px solid #bbb;
    display: flex;
    padding: ${(props) => props.theme.antd.padding}px;
    flex-direction: column;

    .ant-tabs {
    }

    .ant-tabs-content-holder {
    }

    .ant-tabs-content {
        height: 100%;
    }

    .ant-tabs-tabpane {
        height: 100%;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        overflow: auto;
    }
`

export const WidgetHeader = styled.div`
    margin: 2px;
    display: flex;
`

export const WidgetHeaderStretchItem = styled.div`
    flex: 1;
`

export const WidgetHeaderItem = styled.div`
    flex: 0
    white-space: nowrap;
`

export const WidgetFooter = styled.div``
