import { Alert, Spin } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { DeskContext, deskService } from "../../App"
import { FullHeightTablePageWrapper } from "../../components/pages"
import { mts } from "../../desk_protos"
import TraderEditor from "./editor/TraderEditor"

const TraderEditorPage: React.FC = () => {
    const { traderId } = useParams()
    const [error, setError] = useState<string | null>(null)
    const [trader, setTrader] = useState<mts.desk.Trader>()
    const appCtx = React.useContext(DeskContext)

    // Load the trader
    const load = useCallback(async () => {
        const traderUrl = `${appCtx.config.deskAddr}/traders/${traderId}`
        console.log(`Loading Trader from ${traderUrl}`)
        try {
            let trader = await deskService.fetchProtobufOld<mts.desk.Trader>(traderUrl, mts.desk.Trader.decode)
            console.log("Fetched trader", trader)
            setTrader(trader)

            //setLoading(false)
        } catch (err) {
            const msg = `Failed to fetch traders from ${traderUrl} - ${JSON.stringify(err)}`
            console.error(msg)
            setError(msg)
        }
    }, [appCtx.config.deskAddr, traderId])

    useEffect(() => {
        load()
    }, [load])

    if (error) {
        console.log("error", error)
        return <Alert message="Error" description={error} type="error" showIcon />
    }

    if (!trader) {
        return <Spin />
    }

    return (
        <FullHeightTablePageWrapper>
            <TraderEditor trader={trader} />
        </FullHeightTablePageWrapper>
    )
}

export default TraderEditorPage
