import { Descriptions, Table, Tabs, TabsProps } from "antd"
import { ColumnsType } from "antd/es/table"
import { DateTime } from "luxon"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { DeskContext, deskService, portfolioService } from "../../../App"
import { mts } from "../../../desk_protos"
import {
    WidgetContent,
    WidgetFooter,
    WidgetHeader,
    WidgetHeaderItem,
    WidgetHeaderStretchItem,
} from "../../WorkspacesPage"
import SelectPortfolio, { UserValue } from "../SelectPortfolio"

const columns: ColumnsType<mts.portfolio.IPosition> = [
    {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol",
        render(value, record, index) {
            return value
        },
    },
    {
        title: "Qty",
        dataIndex: "quantity",
        key: "quantity",
        render: (value) => value,
    },
    {
        title: "Cost Basis",
        dataIndex: "costBasis",
        key: "costBasis",
        render: (value) => value,
    },
    {
        title: "Price",
        dataIndex: "lastPrice",
        key: "lastPrice",
        render: (value) => value,
    },
    {
        title: "Gain",
        dataIndex: "gain",
        key: "gain",
        render: (value) => value,
    },
    {
        title: "Gain %",
        dataIndex: "gainPercent",
        key: "gainPercent",
        render: (value) => `${value}%`,
    },
]

const closedColumns: ColumnsType<mts.portfolio.Tx> = [
    {
        title: "Date",
        dataIndex: "date",
        width: "175px",
        className: "date",
        key: "date",
        render(date, tx, index) {
            const dt = DateTime.fromISO(date, { zone: "local" })
            const diff = DateTime.now().diff(dt, ["seconds"])
            if (diff.seconds < 86400) {
                return dt.toFormat("h:mm:ss a").toLocaleLowerCase()
            }
            return dt.toFormat("yyyy-MM-dd h:mm:ss a")
        },
    },
    {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol",
        render(value, record, index) {
            return value
        },
    },
    {
        title: "Qty",
        dataIndex: "quantity",
        key: "quantity",
        render: (value) => value,
    },
    {
        title: "Cost Basis",
        dataIndex: "costBasis",
        key: "costBasis",
        width: 90,
        render: (value) => value,
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (value) => value,
    },
    {
        title: "P&L",
        dataIndex: "profitLoss",
        key: "profitLoss",
        render: (value) => value,
    },
]

export const PortfolioPositionsWidget: React.FC<{
    portfolioId?: number
}> = (props) => {
    const appCtx = useContext(DeskContext)
    const [portfolioId, setPortfolioId] = useState(props.portfolioId)
    const [portfolioIdSearch, setPortfolioIdSearch] = useState(props.portfolioId)
    const [portfolio, setPortfolio] = useState<mts.portfolio.Portfolio>()
    const [positions, setPositions] = useState<mts.portfolio.Positions>()
    const [exitTransactions, setExitTransactions] = useState<mts.portfolio.Tx[]>()
    const [positionsUpdated, setPositionsUpdated] = useState<number | undefined>(undefined)

    // Load a portfolio/positions, if portfolioId is undefined do nothing
    useEffect(() => {
        if (!portfolioId) {
            return
        }
        const sseUrl = `${appCtx?.config.portfolioAddr}/portfolios/${portfolioId}/stream`

        const handleMessage = (msg: MessageEvent<string>) => {
            console.log(`Received data from ${sseUrl}`, msg.data)
            const jsonObj = JSON.parse(msg.data)

            const t = mts.portfolio.http.GetPortfolioStreamResponse.fromObject(jsonObj)
            console.log("GetPortfolioStreamResponse is", t)
            if (t.messageType === "portfolioUpdated") {
                const portfolio = t.portfolioUpdated?.portfolio as mts.portfolio.Portfolio
                setPortfolio(portfolio as mts.portfolio.Portfolio)
                //setLastUpdated(DateTime.fromISO(portfolio.updated))
            } else if (t.messageType === "portfolio") {
                setPortfolio(t.portfolio as mts.portfolio.Portfolio)
            } else if (t.messageType === "positions") {
                setPositions(t.positions as mts.portfolio.Positions)
                setPositionsUpdated(DateTime.now().toMillis())
            }
        }

        console.log(`Creating EventSource: url=${sseUrl}`)
        const es = new EventSource(sseUrl)
        es.onmessage = handleMessage
        es.onerror = (ev) => {
            console.error("event source err", ev)
        }

        return () => {
            console.log("Closing EventSource")
            es.close()
        }
    }, [appCtx?.config.portfolioAddr, portfolioId])

    // Update closed positions when portfolio is changed, or when we get a transaction event
    useEffect(() => {
        if (!portfolioId) {
            return
        }

        const load = async () => {
            try {
                console.log("Positions updated, updating closed trades")
                const url = `${appCtx?.config.portfolioAddr}/portfolios/${portfolioId}/transactions?closed=true`
                const response = await deskService.fetchProtobufOld<mts.portfolio.GetTransactionsResponse>(
                    url,
                    mts.portfolio.GetTransactionsResponse.decode,
                )
                console.log("GetTransactionsResponse", response)
                setExitTransactions(response.transactions as mts.portfolio.Tx[])
            } catch (err) {
                if (err instanceof mts.common.ApiError) {
                    console.error(err)
                } else {
                    console.error(err)
                }
            }
        }
        load()
    }, [appCtx, portfolioId, positionsUpdated])

    const handlePortfolioIdSearchChange = (e: any) => {
        setPortfolioIdSearch(+e?.currentTarget.value)
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        console.log("Key pressed:", event.key)
        if (event.key === "Enter") {
            setPortfolioId(portfolioIdSearch)
        }
    }

    const renderDetails = (tx: mts.portfolio.Tx) => {
        const items = Object.entries(tx.brokerData).map(([key, value]) => (
            <Descriptions.Item label={key}>{value}</Descriptions.Item>
        ))

        return <Descriptions title="Broker Data">{items}</Descriptions>
    }

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "Open Positions",
            children: (
                <div className="full-height-table" style={{ height: "100%" }}>
                    <Table
                        rowKey="symbol"
                        className="table-fillheight"
                        scroll={{ x: "none", y: "100vh" }}
                        key="symbol"
                        columns={columns}
                        dataSource={positions?.positions}
                        pagination={{ hideOnSinglePage: true }}
                    />
                </div>
            ),
        },
        {
            key: "2",
            label: "Closed Trades",
            children: (
                <div className="full-height-table" style={{ height: "100%" }}>
                    <Table
                        rowKey="id"
                        className="table-fillheight"
                        scroll={{ x: "none", y: "100vh" }}
                        key="id"
                        columns={closedColumns}
                        dataSource={exitTransactions}
                        pagination={{ hideOnSinglePage: true }}
                        expandable={{
                            expandedRowRender: renderDetails,
                            rowExpandable: (record) => true,
                        }}
                    />
                </div>
            ),
        },
    ]

    function searchPortfolios(username: string): Promise<UserValue[]> {
        return new Promise((resolve, reject) => {
            console.log("fetching portfolios", username)

            let searchId = parseInt(username)
            if (isNaN(searchId)) {
                searchId = 0
            }
            return portfolioService
                .searchPortfolios(String(searchId))
                .then((response) => {
                    console.log("GetPortfoliosResponse", response)
                    const portfolios = response.portfolios as mts.portfolio.Portfolio[]
                    const selectItems = portfolios.map((portfolio) => {
                        return {
                            label: portfolio.id.toString(),
                            value: portfolio.id.toString(),
                        }
                    })
                    resolve(selectItems)
                })
                .catch((err) => {
                    console.error(err)
                    reject(err)
                })
        })
    }

    return (
        <>
            <WidgetHeader>
                <WidgetHeaderItem>
                    Portfolio:
                    <SelectPortfolio
                        value={portfolio ? { label: "" + portfolio.id, value: "" + portfolio.id } : undefined}
                        placeholder="Select portfolio"
                        fetchOptions={searchPortfolios}
                        onChange={(newValue) => {
                            const c = newValue as UserValue
                            setPortfolioId(parseInt(c.value))
                        }}
                        style={{ width: "125px" }}
                    />
                </WidgetHeaderItem>
                <WidgetHeaderStretchItem />
                <WidgetHeaderItem>
                    <Pair>
                        <Key>Portfolio</Key>
                        <Value>{portfolio?.id}</Value>
                    </Pair>
                    <Pair>
                        <Key>Cash</Key>
                        <Value>{portfolio?.cash}</Value>
                    </Pair>
                </WidgetHeaderItem>
            </WidgetHeader>
            <WidgetContent>
                <Tabs type="card" style={{ display: "flex", flex: 1 }} defaultActiveKey="1" items={items}></Tabs>
            </WidgetContent>
            <WidgetFooter></WidgetFooter>
        </>
    )
}

const Pair = styled.div`
    display: flex;
    color: ${(props) => props.theme.antd.colorTextBase};
`
const Key = styled.div`
    width: 75px;
    ::after {
        content: ":";
    }
`
const Value = styled.div`
    width: 75px;
`

export default PortfolioPositionsWidget
