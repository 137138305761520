import styled from 'styled-components'

export const Page = styled.div`
    padding: ${(props) => props.theme.antd.padding}px;
    width: 100%;
`

/**
 * Not sure what to call this, but it's a page that should have a max width that is suitble for text, an article, etc.
 */
export const NarrowPage = styled.div`
    padding: ${(props) => props.theme.antd.padding}px;
    width: 1000px;
    .ant-breadcrumb {
        padding-bottom: ${(props) => props.theme.antd.padding}px;
    }
`

export const FullHeightFlexPage = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    .ant-breadcrumb {
        padding-bottom: ${(props) => props.theme.antd.padding}px;
    }
`

export const FullHeightTablePageWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: ${(props) => props.theme.antd.padding}px;

    padding-bottom: 0px;
    .title {
        flex: 0;
    }

    .full-height-table {
        display: flex;
        position: relative;
        flex: 1;
    }
    .ant-breadcrumb {
        padding-bottom: ${(props) => props.theme.antd.padding}px;
    }
`
