import { Alert, Button, ConfigProvider, Layout, Modal, Typography, message, theme } from 'antd'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import './App.css'
import MyHeader from './components/MyHeader'
import { mts } from './desk_protos'
import Home from './pages/Home'
import PortfoliosPage from './pages/PortfoliosPage'
import ProfilePage from './pages/ProfilePage'
import Tools from './pages/SandboxPage'
import TraderInstancesPage from './pages/TraderInstancesPage'
import TradersPage from './pages/TradersPage'
import { WorkspacesPage } from './pages/WorkspacesPage'
import PortfolioView from './pages/portfolios/PortfolioPage'
import TraderInstanceSummary from './pages/instances/TraderInstancePage'
import TraderEditorPage from './pages/traders/TraderEditorPage'
import TraderPage from './pages/traders/TraderPage'
import ThemeProvider from './theme-provider'
import { MessageInstance } from 'antd/es/message/interface'
import { DeskService } from './data/DeskService'
import { PortfolioService } from './data/portfolio'
import styled from 'styled-components'
import { signal } from '@preact/signals-react'
import { SnippetsManager } from './data/SnippetsManager'
import useModal from 'antd/es/modal/useModal'
import InstanceSummaryMobilePage from './pages/instances/InstanceSummaryMobilePage'
import { WatchlistPage } from './pages/watchlists/WatchlistPage'

// Setup some global variables
// Default instance used throughout the app
export const deskService = new DeskService()
export const portfolioService = new PortfolioService()

// Unsure about the best way to use signals yet
export const settingsSignal = signal<Settings>({ themeName: 'light', compact: false })

export const snippetsManager = new SnippetsManager()

// Save user settings to local storage
export const saveUserSettings = (settings: Settings) => {
    console.log('Saving settings', settings)
    window.localStorage.setItem('mts.settings', JSON.stringify(settings))
    settingsSignal.value = settings
}

// Save user settings taking a Partial<Settings>
export const saveUserSettingsPartial = (settings: Partial<Settings>) => {
    const newSettings = { ...settingsSignal.value, ...settings }
    saveUserSettings(newSettings)
}

export const userSignal = signal<mts.desk.User>(mts.desk.User.create({}))

export interface AppConfig {
    portfolioAddr: string
    deskAddr: string
}

export type ThemeName = 'light' | 'dark'

export interface Settings {
    themeName: ThemeName
    compact: boolean
}

/**
 * Runtime context for a Desk instance. (Instance of App))
 */
export interface DeskContextValue {
    user: mts.desk.User
    session: mts.desk.Session
    config: AppConfig

    settings: Settings

    setTheme: (themeName: ThemeName) => void
    preferences: any
    messageApi?: MessageInstance
}

const preferences = new Map<string, string>()
preferences.set('runbacktest.redirect', 'true')
const emptyContext: DeskContextValue = {
    user: mts.desk.User.create({}),
    session: mts.desk.Session.create({}),
    config: { portfolioAddr: '', deskAddr: '' },
    settings: { themeName: 'light', compact: true },
    setTheme: function (themeName: ThemeName): void {
        throw new Error('Function not implemented.')
    },
    preferences: preferences,
}
export const DeskContext = React.createContext<DeskContextValue>(emptyContext)

export const DeskContextProvider: React.FC<{ children: ReactNode; value: DeskContextValue }> = (props) => {
    return <DeskContext.Provider value={props.value}>{props.children}</DeskContext.Provider>
}

export class Config {
    public static readonly PORTFOLIO_ADDR = process.env.REACT_APP_PORTFOLIO_ADDR
    public static readonly DESK_ADDR = process.env.REACT_APP_DESK_ADDR
}
console.log('process.env', process.env)

const App: React.FC<{}> = (props) => {
    const [themeName, setThemeName] = useState<ThemeName>('light')
    const [user, setUser] = useState<mts.desk.User>()
    const [session, setSession] = useState<mts.desk.Session>()
    const [appCtx, setDeskContextValue] = useState<DeskContextValue>()
    const [error] = useState(null)
    const [viewportWidth, setViewportWidth] = useState<number>()
    const [messageApi, contextHolder] = message.useMessage()
    const [modal, contextHolderModal] = useModal()

    // After login, setup the app Context
    useEffect(() => {
        if (!user || !session) {
            return
        }
        // Initial App Context
        const preferences = new Map<string, string>()
        preferences.set('runbacktest.redirect', 'true')
        const useCompact = window.innerWidth < 700

        let settings: Settings = {
            compact: useCompact,
            themeName: 'light',
        }
        // Loading user settings from local storage
        const settingsStr = window.localStorage.getItem('mts.settings')

        // parse to settings
        if (settingsStr !== null) {
            let savedSettings: Settings = JSON.parse(settingsStr)
            settings = { ...settings, ...savedSettings }
        }

        const appCtx: DeskContextValue = {
            user: user,
            session: session,
            config: {
                deskAddr: Config.DESK_ADDR as string,
                portfolioAddr: Config.PORTFOLIO_ADDR as string,
            },
            settings: settings,
            setTheme: setThemeName,
            preferences: preferences,
            messageApi: messageApi,
        }
        console.log('Setting AppContext', appCtx)
        setDeskContextValue(appCtx)

        // Set some global variables, yeah not the cleanest, but works for now
        deskService.setAppCtx(appCtx)
        portfolioService.setAppCtx(appCtx)
        settingsSignal.value = settings

        settingsSignal.subscribe((settings) => {
            const newAppCtx = { ...appCtx, settings }
            setDeskContextValue(newAppCtx)
        })
    }, [user, session])

    const getAlgos = useCallback(() => {
        if (!appCtx) {
            return
        }

        const themeName = appCtx.settings.themeName
        const algos = []
        algos.push(themeName === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm)
        if (appCtx?.settings.compact) {
            algos.push(theme.compactAlgorithm)
        }
        return algos
    }, [appCtx])

    useEffect(() => {
        if (!viewportWidth) {
            return
        }
    }, [viewportWidth])

    // On mount, listen on the viewport to update theme, etc.
    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth)
        }

        // Initial check
        handleResize()

        // Add event listener for window resize
        window.addEventListener('resize', handleResize)

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // When a user is set, log them in.
    useEffect(() => {
        if (!user) {
            return
        }
        let session: mts.desk.Session
        const login = async () => {
            const loginRequest = mts.desk.LoginRequest.create({
                userId: user.id,
                viewportWidth: window.innerWidth,
                viewportHeight: window.innerHeight,
            })
            const loginResponse = await deskService.fetchProtobufOld<mts.desk.LoginResponse>(
                `${Config.DESK_ADDR}/login`,
                mts.desk.LoginResponse.decode,
                {
                    body: mts.desk.LoginRequest.encode(loginRequest).finish(),
                    method: 'POST',
                }
            )

            session = loginResponse.session as mts.desk.Session

            setSession(session)
            setUser(user)
        }
        login()

        const logout = async () => {
            if (session) {
                const logoutRequest = mts.desk.LogoutRequest.create({ sessionId: session.id })
                const url = `${Config.DESK_ADDR}/logout`
                const logoutResponse = await deskService.fetchProtobufOld<mts.desk.LogoutResponse>(
                    url,
                    mts.desk.LogoutResponse.decode,
                    {
                        body: mts.desk.LogoutRequest.encode(logoutRequest).finish(),
                        method: 'POST',
                    }
                )

                console.log('logout response', logoutResponse)
            }
        }
        return () => window.addEventListener('beforeunload', logout)
    }, [user])

    // Fetch userId from localstorage, or create a new anonymous user automatically,
    // once done, setUser(...) is called which will initiate a login
    useEffect(() => {
        const load = async () => {
            // Check if we have a user id saved
            let userId = window.localStorage.getItem('mts.user.id')
            let user: mts.desk.User | undefined
            if (userId === null) {
                const request = mts.desk.AddUserRequest.create({
                    user: {
                        anonymous: true,
                    },
                })
                const url = `${Config.DESK_ADDR}/users`

                try {
                    const resp = await fetch(url, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/protobuf',
                            'Content-type': 'application/protobuf',
                        },
                        body: mts.desk.AddUserRequest.encode(request).finish(),
                    })
                    if (resp.ok) {
                        const data = await resp.arrayBuffer()
                        user = mts.desk.User.decode(new Uint8Array(data))
                        console.log('Got user from addUser request', user)
                        userId = user.id
                        window.localStorage.setItem('mts.user.id', user.id)
                    } else {
                        const data = await resp.arrayBuffer()
                        const apiError = mts.common.ApiErrorResponse.decode(new Uint8Array(data))
                        throw apiError
                    }
                } catch (err) {
                    if (err instanceof mts.common.ApiErrorResponse) {
                        throw err.error
                    }
                    throw new Error(`Failed: url=${url}, error=${JSON.stringify(err)}`)
                }
            } else {
                console.log('Using userId from localstorage', userId)
                const url = `${Config.DESK_ADDR}/users/${userId}`
                try {
                    user = await deskService.mtsFetchUser(url)
                    userSignal.value = user
                } catch (err) {
                    if (err instanceof mts.common.ApiError) {
                        if (err.httpStatus === 404) {
                            // Shouldn't happen.. TODO handle this properly somehow. But for now just clear local storage
                            console.log(
                                `No user found for userId ${userId}, clearing mts.user.id from local storage, reload`
                            )
                            window.localStorage.removeItem('mts.user.id')
                            return
                        }
                    }
                    throw err
                }

                console.log(`Fetched user from ${url}`, user)
            }
            setUser(user)
        }
        load()
    }, [])

    if (error) {
        return <Alert message={'failed'} type="error" />
    }

    if (!appCtx) {
        return <></>
    }

    return (
        <DeskContextProvider value={appCtx}>
            <ConfigProvider theme={{ algorithm: getAlgos() }}>
                <ThemeProvider>
                    <Router>
                        <Layout style={{ flex: 1, display: 'flex', height: '100vh' }}>
                            <MyHeader />

                            <StyledLayoutContent>
                                <Routes>
                                    <Route path="/traders-editor/:traderId" element={<TraderEditorPage />} />
                                    <Route path="/traders" element={<TradersPage />} />
                                    <Route path="/traders/:traderId" element={<TraderPage />} />
                                    <Route
                                        path="/instances/:instanceId"
                                        element={<ResponsiveInstanceSummary />}
                                    />
                                    <Route path="/instances" element={<TraderInstancesPage />} />
                                    <Route path="/portfolios/:id" element={<PortfolioView />} />
                                    <Route path="/" element={<Home />} />
                                    <Route path="/portfolios" element={<PortfoliosPage />} />
                                    <Route path="/workspaces" element={<WorkspacesPage />} />
                                    <Route path="/profile" element={<ProfilePage />} />
                                    <Route path="/sandbox" element={<Tools />} />
                                    <Route path="/watchlists/:watchlistId" element={<WatchlistPage />} />
                                    <Route path="*" element={<div>Not Found</div>} />
                                </Routes>
                            </StyledLayoutContent>
                        </Layout>
                    </Router>
                </ThemeProvider>
            </ConfigProvider>
            {contextHolder}
            {contextHolderModal}
            <WelcomeModal />
        </DeskContextProvider>
    )
}

// A wrapper around TraderInstanceSummary and MobileTraderInstanceSummary, if isMobile is true, it will render MobileTraderInstanceSummary
// otherwise it will render TraderInstanceSummary
const ResponsiveInstanceSummary: React.FC<{}> = props => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 700)
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 700)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return isMobile ? <InstanceSummaryMobilePage /> : <TraderInstanceSummary />
}

const WelcomeModal = () => {
    const [open, setOpen] = useState(window.localStorage.getItem('mts.firstVisit') === null)

    const close = () => {
        window.localStorage.setItem('mts.firstVisit', 'true')
        setOpen(false)

        deskService.addActivity('web.home.welcomemodal.ok.click')
    }

    return (
        <Modal
            title="Welcome to mtsbots.com"
            open={open}
            closeIcon={false}
            centered={true}
            zIndex={10001}
            destroyOnClose={true}
            footer={[
                <Button key="ok" type="primary" onClick={close}>
                    Ok
                </Button>,
            ]}
        >
            <Typography.Paragraph>
                This site is <b style={{ color: 'red' }}>ALPHA</b>; it's buggy, has unimplemented features, and will be unstable.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Best viewed on a desktop or laptop. Mobile support is limited.
            </Typography.Paragraph>
        </Modal>
    )
}

/**
 * Global stylesheet.
 */
const StyledLayoutContent = styled(Layout.Content)`

    flex: 1;
    display: flex;
    background: ${(props) => props.theme.antd.colorBgBase};
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code {
        color: ${(props) => props.theme.antd.colorTextBase};
    }
    p {
        color: ${(props) => props.theme.antd.colorTextBase};
    }

    overflow-y: auto;
    .ant-breadcrumb {
        padding-bottom: ${(props) => props.theme.antd.padding}px;
    }

    .ant-descriptions-item-label {
        padding-top: ${(props) => props.theme.antd.padding / 2}px !important;
        padding-bottom: ${(props) => props.theme.antd.padding / 2}px !important;
        padding-right: ${(props) => props.theme.antd.padding / 2}px !important;
        text-align: right !important;
    }

    .ant-descriptions-item-content {
        padding-top: ${(props) => props.theme.antd.padding / 2}px !important;
        padding-bottom: ${(props) => props.theme.antd.padding / 2}px !important;
        padding-left: ${(props) => props.theme.antd.padding / 2}px !important;
    }

    .mts-new-trader-btn {
        background-color: #228b22;
        &:hover {
            background-color: #2eae2e !important;
        }
    }

    .mts-run-backtest-btn {
        background-color: #228b22;
        color: #fff !important;
        &:hover {
            background-color: #2eae2e !important;
        }
    }

    .mts-run-btn {
        background-color: #228b22;
        &:hover {
            background-color: #2eae2e !important;
        }
    }
    
`

export default App
