import { Popover, Typography, theme } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import React, { useEffect } from 'react'

const { useToken } = theme

function isOpen(key: string): boolean {
    let json = window.localStorage.getItem('guides')
    if (!json) {
        return true
    }
    let data = JSON.parse(json)
    return data[key] !== true
}

function setClosed(key: string) {
    let json = window.localStorage.getItem('guides')
    if (!json) {
        json = '{}'
    }
    let data = JSON.parse(json)
    data[key] = true
    window.localStorage.setItem('guides', JSON.stringify(data))
}

// A component that extends Popover from antd and styles it with styled-components
/*
<Popover
                    rootClassName="guide"
                    color={token.colorPrimaryBg}
                    overlayStyle={{  }}
                    content=<div>
                        <Typography.Paragraph>Run your first Trader Bot now</Typography.Paragraph>
                        <div style={{ textAlign: 'right' }} className="footer">
                            <Typography.Link onClick={closeTradersGuide}>Got it</Typography.Link>
                        </div>
                    </div>
                    trigger="click"
                    open={isNewTraderGuideOpen}
                    placement="bottom"
                >
                */
/**
 * Stores state of in local stoage under the key "guides", storage key will be the index
 */
export const GuidePopover: React.FC<{
    content: React.ReactNode
    children: React.ReactNode
    storageKey: string
    placement?: TooltipPlacement
}> = (props) => {
    const [open, setOpen] = React.useState(isOpen(props.storageKey))

    const { token } = useToken()

    // When open changes to false, save it to local storage
    useEffect(() => {
        if (!open) {
            setClosed(props.storageKey)
        }
    }, [open, props.storageKey])

    // When this component is unmounted, save the open state to local storage
    useEffect(() => {
        return () => {
            setClosed(props.storageKey)
        }
    }, [open, props.storageKey])

    return (
        <Popover
            color={'#444'}
            placement={props.placement || 'bottom'}
            content={
                <>
                    <Typography.Paragraph style={{ color: '#fff'}}>{props.content}</Typography.Paragraph>
                    <div style={{ textAlign: 'right' }} className="footer">
                        <Typography.Link onClick={() => setOpen(false)}>Got it</Typography.Link>
                    </div>
                </>
            }
            open={open}
        >
            {props.children}
        </Popover>
    )
}
