import { Alert, Button, Form, Modal, Select } from 'antd'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Config, DeskContext, DeskContextValue } from '../../App'
import { GuidePopover } from '../../components/GuidePopover'
import { mts } from '../../desk_protos'

const { Tick } = mts.desk

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 25 },
}

interface Setting {
    [key: string]: string
}

export interface Template {
    description: string
    name?: string
    eventHandlers: Setting
    executeTickTypes: mts.desk.Tick.Type[]
}

const PRESETS: Template[] = [
    {
        description: 'Buy SPY at the open and sell on the close',
        name: 'Daily Trader',
        executeTickTypes: [Tick.Type.OPEN, Tick.Type.CLOSE, Tick.Type.INIT],
        eventHandlers: {
            INIT: 'mts.deposit(1000)',
            OPEN: `// Buy SPY at the open
let symbol = 'SPY'

console.log('Cash: ' + mts.portfolio.getCash())

// Buy max amount of SPY
let quote = mts.getQuote(symbol)
let quantity = Math.floor(mts.portfolio.getCash() / quote.getPrice())
if (quantity > 0) {
    console.log('Buy ' + symbol)
    mts.buy(quantity, symbol)
}`,
            CLOSE: `// Sell all positions on the close
let symbol = 'SPY'

// Close positions
let position = mts.getPosition(symbol)
if (position) {
    console.log('Selling position')
    mts.sell(position.getQuantity(), symbol)
} else {
    console.log('No position to sell')
}`,
        },
    },
    {
        name: 'Blank Trader',
        description: '',
        executeTickTypes: [],
        eventHandlers: {},
    },
]

const DEFAULT_TEMPLATE = PRESETS[0]

const addTrader = async (appCtx: DeskContextValue, trader: mts.desk.Trader) => {
    const request = mts.desk.AddTraderRequest.create({
        trader,
    })
    // POST to the desk runner endpoint
    const url = `${Config.DESK_ADDR}/traders`

    console.log(`POST ${url} addTraderRequest`, request)

    try {
        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/protobuf',
                'Content-type': 'application/protobuf',
                'x-mts-session-id': appCtx.session.id,
            },
            body: mts.desk.AddTraderRequest.encode(request).finish(),
        })
        if (resp.ok) {
            const data = await resp.arrayBuffer()

            const addTraderResponse = mts.desk.AddTraderResponse.decode(new Uint8Array(data))

            return addTraderResponse
        }
        const data = await resp.arrayBuffer()
        const apiError = mts.common.ApiErrorResponse.decode(new Uint8Array(data))
        console.log('Save trader response', resp)
        throw apiError
    } catch (err) {
        const msg = `Failed: url=${url}, error=${JSON.stringify(err)}`
        console.error(msg)
        if (err instanceof mts.common.ApiErrorResponse) {
            throw err.error
        }
        throw new Error(msg)
    }
}

const NewTraderModal: React.FC<{
    open: boolean
    onClose: () => void
}> = (props) => {
    const [preset, setPreset] = useState<Template>(DEFAULT_TEMPLATE)
    const navigate = useNavigate()
    const appCtx = React.useContext(DeskContext)
    const [error, setError] = useState('')
    const [open, setOpen] = useState(props.open)

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handlePresetChange = useCallback((presetName: any) => {
        console.log('preset change', presetName)
        const preset = PRESETS.find((preset) => preset.name === presetName)
        if (!preset) {
            throw new Error(`Preset not found: ${presetName}`)
        }
        setPreset(preset)
    }, [])

    const handleOk = () => {
        //setNewTraderModalOpen(false)
        if (!preset) {
            setError('Select one')
            return
        }
        let trader = mts.desk.Trader.create({
            name: preset.name + '-' + DateTime.now().toUnixInteger(),
            userId: appCtx.user.id,
            tickInterval: 1,
            tickIntervalUnit: mts.desk.TimeUnit.DAYS,
            executeTickTypes: preset.executeTickTypes,
            tickHandlers: preset.eventHandlers,
        })
        console.log('calling addTrader, preset=', preset)
        addTrader(appCtx, trader)
            .then((addTraderResponse: mts.desk.AddTraderResponse) => {
                const trader = addTraderResponse.trader as mts.desk.Trader
                navigate(`/traders-editor/${trader.id}`)
                setOpen(false)
            })
            .catch((err) => {
                console.log('got err', err)
                setError(JSON.stringify(err))
            })
    }

    const handleCancel = () => {
        setOpen(false)
        props.onClose()
    }

    return (
        <Modal
            title="New Trader"
            open={open}
            transitionName=""
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <GuidePopover key="submit" storageKey="new_trader_modal" content="Click Save to create a Bot">
                    <Button type="primary" onClick={handleOk}>
                        Save
                    </Button>
                </GuidePopover>,
            ]}
        >
            <Form {...layout}>
                <Form.Item
                    label="Template"
                    extra="Select a template. All aspects of the Trader can be modified later"
                >
                    <Select
                        showSearch
                        placeholder="Templates"
                        value={preset?.name}
                        optionFilterProp="children"
                        listHeight={200}
                        onSelect={handlePresetChange}
                    >
                        {PRESETS.map((preset) => (
                            <Select.Option value={preset.name} key={preset.name}>
                                {preset.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
            {error && <Alert message={error} type="error" />}
        </Modal>
    )
}

export default NewTraderModal
