import { MinusCircleOutlined } from '@ant-design/icons';
import { Form, Input, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { mts } from '../../../desk_protos';

/**
 * Renders a given setting field, choosing the correct form item to use
 */
export const TraderOption: React.FC<{
    option: mts.desk.TraderOption;
    index: number;
    onRemove: (index: number) => void;
    onChange: (index: number, option: mts.desk.TraderOption) => void;
}> = (props) => {
    const [option, setOption] = useState(props.option);

    const { index } = props;

    // call props.onChange when the option changes
    useEffect(() => {
        console.debug(`option changed for index ${index}`, option);
        props.onChange(index, option);
    }, [index, option, props]);

    const handleNameChange = (e: any) => {
        const newOption: mts.desk.TraderOption = mts.desk.TraderOption.create({
            ...option,
            name: e.target.value as string,
        });
        setOption(newOption);
    };

    const handleLabelChange = (e: any) => {
        const newOption: mts.desk.TraderOption = mts.desk.TraderOption.create({
            ...option,
            label: e.target.value as string,
        });
        setOption(newOption);
    };

    const handleTypeChange = (e: any) => {
        const newOption: mts.desk.TraderOption = mts.desk.TraderOption.create({
            ...option,
            type: e as mts.desk.TraderOption.OptionType,
        });
        setOption(newOption);
    };
    const handleDescriptionChange = (e: any) => {
        const newOption: mts.desk.TraderOption = mts.desk.TraderOption.create({
            ...option,
            description: e.target.value as string,
        });
        setOption(newOption);
    };

    return (
        <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Form.Item
                initialValue={option.name}
                name={`name-${index}`}
                rules={[{ required: true, message: 'required' }]}
            >
                <Input placeholder="name" width={50} onChange={handleNameChange} />
            </Form.Item>
            <Form.Item
                initialValue={option.label}
                name={`label-${index}`}
                rules={[{ required: true, message: 'required' }]}
            >
                <Input placeholder="label" onChange={handleLabelChange} />
            </Form.Item>

            <Form.Item initialValue={option.type} name={`type-${index}`}>
                <Select
                    options={[
                        { label: 'Number', value: mts.desk.TraderOption.OptionType.NUMBER },
                        { label: 'String', value: mts.desk.TraderOption.OptionType.STRING },
                        { label: 'Boolean', value: mts.desk.TraderOption.OptionType.BOOLEAN },
                    ]}
                    style={{ width: '100px' }}
                    onChange={handleTypeChange}
                ></Select>
            </Form.Item>
            <Form.Item
                name={`description-${index}`}
                initialValue={option.description}
                style={{ flex: 2, width: 400 }}
                rules={[{ max: 100, message: 'Max 100 characters' }]}
            >
                <Input placeholder="description" width={200} onChange={handleDescriptionChange} />
            </Form.Item>
            <MinusCircleOutlined onClick={() => props.onRemove(index)} />
        </Space>
    );
};
