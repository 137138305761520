import { DeskContextValue } from '../App'
import { mts } from '../desk_protos'
import { mtsFetchProtobuf } from './common'

export class PortfolioService {
    private appCtx?: DeskContextValue

    private addr = ''

    public setAppCtx(appCtx: DeskContextValue) {
        this.appCtx = appCtx
        this.addr = appCtx.config.portfolioAddr
    }

    async getPortfolio(portfolioId: string) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const url = `${this.addr}/portfolios/${portfolioId}`
        // blank RequestInit for now, but we'll need to pass some some sort of auth token later to portfolio
        let requestInit: RequestInit = {}
        return mtsFetchProtobuf<mts.portfolio.Portfolio>(
            this.appCtx,
            url,
            mts.portfolio.Portfolio.decode,
            requestInit
        )
    }

    public searchPortfolios(searchId: string) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const url = `${this.addr}/portfolios?limit=10&searchId=${searchId}`
        return mtsFetchProtobuf<mts.portfolio.GetPortfoliosResponse>(
            this.appCtx,
            url,
            mts.portfolio.GetPortfoliosResponse.decode
        )
    }

    resetPortfolio(portfolioId: string) {
        if (!this.appCtx) {
            throw new Error('appCtx is not set')
        }
        const url = `${this.addr}/portfolios/${portfolioId}/reset`
        const request = mts.portfolio.ResetRequest.create({
            portfolioId,
        })
        return mtsFetchProtobuf<mts.portfolio.Portfolio>(this.appCtx, url, mts.portfolio.Portfolio.decode, {
            method: 'PUT',
            body: mts.portfolio.ResetRequest.encode(request).finish(),
        })
    }
}
