import { Button, Modal, Spin, Typography } from 'antd'
import Text from 'antd/es/typography'
import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/es/typography/Title'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Config, portfolioService } from '../../App'
import { Toolbar, ToolbarItem } from '../../components/Toolbar'
import { Page } from '../../components/pages'
import { mts } from '../../desk_protos'
import { Formatters } from '../../components/utils/formatters'
import PerformanceChart from './PerformanceChart'
import PositionsTable from './PositionsTable'
import { NumberStatistic, PercentageReturnStatistic } from './Statistics'
import TransactionsTable from './TransactionsTable'

const { GetPortfolioResponse } = mts.portfolio

/**
 * A view for a single Portfolio.
 */
const PortfolioPage: React.FunctionComponent<{}> = (props) => {
    const [portfolio, setPortfolio] = React.useState<mts.portfolio.Portfolio>()
    const [positions, setPositions] = useState<mts.portfolio.Positions>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState('')
    const [lastUpdated, setLastUpdated] = useState<DateTime>()
    const [resetting, setResetting] = useState(false)

    const { id } = useParams()

    useEffect(() => {
        if (!id) {
            return
        }
        const since = DateTime.now().toISO()
        const sseUrl = `${Config.PORTFOLIO_ADDR}/portfolios/${id}/stream?since=${since}`

        const handleMessage = (msg: MessageEvent<string>) => {
            const jsonObj = JSON.parse(msg.data)

            const t = mts.portfolio.http.GetPortfolioStreamResponse.fromObject(jsonObj)
            if (t.messageType === 'portfolioUpdated') {
                console.log('received sse messageType of portfolioUpdated', t.portfolioUpdated)
                const portfolio = t.portfolioUpdated?.portfolio as mts.portfolio.Portfolio
                setPortfolio(t.portfolioUpdated?.portfolio as mts.portfolio.Portfolio)
                setLastUpdated(DateTime.fromISO(portfolio.updated))
            } else if (t.messageType === 'portfolio') {
                console.log('received sse messageType of portfolio', t.portfolio)
                setPortfolio(t.portfolio as mts.portfolio.Portfolio)
            }
        }

        console.log(`Creating EventSource: url=${sseUrl}`)
        const es = new EventSource(sseUrl)
        es.onmessage = handleMessage
        es.onerror = (ev) => {
            console.error('event source err', ev)
        }
        
        return () => {
            console.log('closing eventSource')
            es.close()
        }
    }, [id])

    useEffect(() => {
        const url = `${Config.PORTFOLIO_ADDR}/portfolios/${id}?includePositions=true`

        const load = async () => {
            try {
                const resp = await fetch(url, {
                    headers: {
                        Accept: 'application/protobuf',
                    },
                })
                
                const portfolioResponse = GetPortfolioResponse.decode(
                    new Uint8Array(await resp.arrayBuffer()),
                )
                const portfolio = portfolioResponse.portfolio as mts.portfolio.Portfolio
                console.log('Fetched portfolio', portfolioResponse)
                setPortfolio(portfolioResponse.portfolio as mts.portfolio.Portfolio)
                setPositions(portfolioResponse.positions as mts.portfolio.Positions)
                setLoading(false)
                setLastUpdated(DateTime.fromISO(portfolio.updated))
            } catch (err) {
                console.log('err', err)
                const msg = `Failed to fetch portfolio from ${url} - ${err}`
                //message.error(`Failed to fetch traders from ${url} - ${err}`)
                setError(String(msg))
            }
        }

        console.log(`Loading summary for Portfolio#${id}`)
        load()
    }, [id])

    if (loading || !portfolio || !positions) {
        return <Spin />
    }

    const handleResetClick = () => {
        Modal.confirm({
            title: 'Confirm Reset',
            content: 'Are you sure you want to reset the portfolio?',
            onOk: () => {
                // Call portfolioService to reset the portfolio
                setResetting(true)
                portfolioService
                    .resetPortfolio(String(portfolio.id))
                    .then((portfolio) => setPortfolio(portfolio))
                    .catch((err) => setError(err.message))
                    .finally(() => setResetting(false))
            },
            onCancel: () => {
                // Do nothing
            },
        })
    }

    return (
        <Page className="portfolio">
            <Toolbar>
                <ToolbarItem>
                    <Title>
                        <Link to="/portfolios">Portfolios</Link> : {portfolio.name}
                        <TitleSub>
                            {DateTime.fromISO(portfolio.startDate).toFormat('yyyy-MM-dd')} to{' '}
                            {DateTime.fromISO(portfolio.endDate).toFormat('yyyy-MM-dd')} (
                            {Formatters.diffBetweenDates(
                                DateTime.fromISO(portfolio.startDate),
                                DateTime.fromISO(portfolio.endDate),
                            )}
                            )
                        </TitleSub>
                    </Title>
                </ToolbarItem>
                <div style={{ flex: '1' }} />
                <ToolbarItem>
                    <Button danger loading={resetting} onClick={handleResetClick}>
                        Reset
                    </Button>
                </ToolbarItem>
            </Toolbar>

            {portfolio.endDate === '' && <LastUpdated lastUpdated={lastUpdated} />}

            {error && <Typography.Text type="danger">{error}</Typography.Text>}

            <Paragraph ellipsis={{ rows: 1, expandable: true }}>
                <ReactMarkdown>{portfolio.description}</ReactMarkdown>
            </Paragraph>

            <PortfolioStats className="portfolio-stats">
                <NumberStatistic title="Cash" value={portfolio.cash} />
                <NumberStatistic title="Equity" value={portfolio.equity} />
                <NumberStatistic title="Total Deposits" value={portfolio.totalDeposits} />
                <NumberStatistic title="Total Value" value={portfolio.totalValue} />
                <NumberStatistic title="Total Dividends" value={portfolio.totalDividends} />
                <NumberStatistic title="Buying Power" value={portfolio.buyingPower} />
                {/*<NumberStatistic title="Average Win" value={portfolio.averageWin} />
                <NumberStatistic title="Average Loss" value={portfolio.averageLoss} />*/}
                <PercentageReturnStatistic title="Total Return" value={portfolio.returnAll} />
                <NumberStatistic title="Percent Cash" value={portfolio.percentCash} suffix="%" />
            </PortfolioStats>

            <Title level={2}>Positions</Title>
            <PositionsTable positions={positions} />

            <Title level={2}>Historical Performance</Title>
            <PerformanceChart portfolio={portfolio} />

            <Title level={2}>Transactions</Title>
            <TransactionsTable portfolio={portfolio} />
        </Page>
    )
}

/**
 * Display last updated value
 */
export const PortfolioAutoUpdateDate: React.FC<{ lastUpdated?: DateTime }> = (props) => {
    const [formattedDate, setFormattedDate] = useState('')
    const [lastUpdated, setLastUpdated] = useState(props.lastUpdated)

    useEffect(() => setLastUpdated(props.lastUpdated), [props.lastUpdated])

    useEffect(() => {
        setFormattedDate(Formatters.humanDate(lastUpdated))
        const tid = setInterval(() => {
            setFormattedDate(Formatters.humanDate(lastUpdated))
        }, 30000)
        return () => clearTimeout(tid)
    }, [lastUpdated])

    return (
        <Text className="last-updated">
            {/*<Paragraph>Last Updated: {lastUpdated?.toLocaleString(DateTime.DATETIME_MED)}</Paragraph>*/}
            <Paragraph>{formattedDate}</Paragraph>
        </Text>
    )
}

/**
 * Display last updated value
 */
export const LastUpdated: React.FC<{ lastUpdated?: DateTime }> = (props) => {
    const [formattedDate, setFormattedDate] = useState('')
    const [lastUpdated, setLastUpdated] = useState(props.lastUpdated)

    useEffect(() => setLastUpdated(props.lastUpdated), [props.lastUpdated])

    useEffect(() => {
        setFormattedDate(Formatters.humanDate(lastUpdated))
        const tid = setInterval(() => {
            setFormattedDate(Formatters.humanDate(lastUpdated))
        }, 5000)
        return () => clearTimeout(tid)
    }, [lastUpdated])

    return (
        <Text className="last-updated">
            {/*<Paragraph>Last Updated: {lastUpdated?.toLocaleString(DateTime.DATETIME_MED)}</Paragraph>*/}
            <Paragraph>Last Updated: {formattedDate}</Paragraph>
        </Text>
    )
}

const PortfolioStats = styled.div`
    .ant-statistic-content-value {
        font-size: ${(props) => props.theme.antd.fontSizeLG}px;
    }
`

const TitleSub = styled(Typography.Paragraph)`
    font-size: smaller;
`

export default PortfolioPage
