import { useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { Alert, Button, Card, Dropdown, Modal, Typography, message } from 'antd'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Config, DeskContext, deskService } from '../../App'
import { Toolbar, ToolbarItem } from '../../components/Toolbar'
import { Page } from '../../components/pages'
import { PortfolioSummaryMobilePanel } from '../../components/portfolio/PortfolioSummaryMobileWidget'
import { mts } from '../../desk_protos'
import { EditOptionValuesModal } from './EditOptionValuesModal'
import { RunBacktestButton } from '../traders/RunBacktestButton'
import { formatInstanceDateRange } from './TraderInstancePage'
import { NumberStatistic, PercentageReturnStatistic, TextStatistic } from '../portfolios/Statistics'

const { State, Mode } = mts.desk.TraderInstance

function formatDuration(instance?: mts.desk.TraderInstance) {
    if (!instance) {
        return '-'
    }
    const d = durationMillis(instance)
    return d ? (d / 1000).toFixed(3) + ' seconds' : '-'
}

// Given an enum index return the name
function enumName(enumType: any, value: any) {
    return enumType[value]
}

function formatOptionValues(trader: mts.desk.Trader, optionValues: OptionValues) {
    if (!optionValues) {
        return <>-</>
    }

    const options = trader.options
    const optionsByName = Object.fromEntries(options?.map((o) => [o.name, o]) || [])

    const renderOption = (optionName: string) => {
        var option = optionsByName[optionName]
        return (
            <>
                {option?.label}: {JSON.stringify(optionValues[optionName])}
            </>
        )
    }

    return (
        <>
            {Object.keys(optionValues).map((optionName, i) => (
                <div key={i}>{renderOption(optionName)}</div>
            ))}
        </>
    )
}

export type OptionValues = { [key: string]: any }

// Build
const buildLogEntry = (entry: mts.desk.TraderInstanceOutput.LogEntry) => {
    let newLogEntry = document.createElement('div')
    newLogEntry.className = 'log-entry'
    newLogEntry.textContent = `${DateTime.fromMillis(entry.time as number)} ${entry.data}`
    return newLogEntry
}

export const InstanceSummaryMobilePage: React.FunctionComponent<{}> = (props) => {
    useSignals()

    const { instanceId } = useParams()
    const appCtx = React.useContext(DeskContext)
    const [error, setError] = React.useState<string | null>(null)
    const [instance, setInstance] = useState<mts.desk.TraderInstance>()
    const [loading, setLoading] = React.useState(true)
    const [messageApi, contextHolder] = message.useMessage()
    const [state, setState] = useState<mts.desk.TraderInstance.State>()
    const [deleting, setDeleting] = useState(false)
    const navigate = useNavigate()
    const [stopping, setStopping] = useState(false)
    const [outputError, setOutputError] = useState<string>()
    const [duration, setDuration] = useState<string>('-')
    const logEntriesRef = useRef<HTMLDivElement>(null)
    const [stopButtonEnabled, setStopButtonEnabled] = useState(false)
    const loggedInUser = useLoggedInUser()

    const [optionValuesModalOpen, setOptionValuesModalOpen] = useState(false)
    const optionValuesSignal = useSignal<OptionValues>({})

    const portfolioSignal = useSignal<mts.portfolio.Portfolio | undefined>(undefined)

    const { user } = appCtx

    // Instance updated side effects
    useEffect(() => {
        if (!instance) {
            return
        }

        const stateName = mts.desk.TraderInstance.State[instance?.state as mts.desk.TraderInstance.State]
        console.log(`instance updated, state=${stateName}, started=${instance?.started}`, instance)

        // Update duration
        setDuration(formatDuration(instance))
        setStopButtonEnabled(instance.state === State.RUNNING)
    }, [instance])

    // Update trader instance with new state
    useEffect(() => {
        if (!state) {
            return
        }
        messageApi.info('Instance state changed to ' + mts.desk.TraderInstance.State[state])

        // If new state is STOPPED then set stopping to false, and disable the stop button
        if (state === mts.desk.TraderInstance.State.STOPPED) {
            setStopping(false)
        }
    }, [messageApi, state])

    // Enable SSE when the instance id changes, OR disable permanently if state enters FINISHED
    useEffect(() => {
        const handleSseMessage = (ev: MessageEvent) => {
            const msg = mts.desk.http.GetTraderInstanceUpdatesResponse.fromObject(JSON.parse(ev.data))
            console.log(`Got instance update of ${msg.updateType} sequence ${msg.sequence}`, msg)

            switch (msg.updateType) {
                case 'traderInstance':
                    setInstance(msg.traderInstance as mts.desk.TraderInstance)
                    break
                case 'stateChange':
                    //let state = msg.stateChange?.state as mts.desk.TraderInstance.State
                    setState(msg.stateChange?.state as mts.desk.TraderInstance.State)
                    break
                case 'instanceOutput':
                    let newLogEntries = msg.instanceOutput?.logs as mts.desk.TraderInstanceOutput.LogEntry[]

                    let nodes = newLogEntries.map((entry) => buildLogEntry(entry))
                    logEntriesRef.current?.prepend(...nodes)

                    setDuration(formatDuration(instance))

                    const limit = 100

                    // Remove all entries too keep the count under the limit
                    if (logEntriesRef?.current) {
                        const div = logEntriesRef.current

                        let over = div.childElementCount - limit
                        if (limit > 0) {
                            for (let i = 0; i < over; i++) {
                                div.removeChild(div.lastChild as Node)
                            }
                        }
                    }

                    break
                case 'tickEvent':
                    // ignore for now, maybe update duration?
                    break
                default:
                    console.log('SSE unhandled message type', msg)
            }
        }

        const url = `${Config.DESK_ADDR}/traderinstances-events/${instanceId}`
        const handleError = (ev: Event) => {
            console.log(`Error`, ev)

            messageApi.warning(`Failed to connect to SSE(${url}; retrying automatically`)
        }

        const handleOpen = (ev: Event) => {}

        setError('')

        const es = new EventSource(url)
        es.onerror = handleError
        es.onmessage = handleSseMessage
        es.onopen = handleOpen

        return () => {
            console.log('Closing eventSource')
            es.close()
        }
    }, [instanceId, messageApi])

    // Loud output when traderInstance is updated
    useEffect(() => {
        if (!instance) {
            return
        }
        deskService
            .getInstanceOutput(instance)
            .then((resp) => {
                var logs = resp.instanceOutputs
                    .map((io) => io.logs as mts.desk.TraderInstanceOutput.LogEntry[])
                    .flat()
                console.log('Got output logs', logs)
                // Clear all children from logentriesref
                if (logEntriesRef?.current) {
                    logEntriesRef.current.innerHTML = ''
                }
                logEntriesRef.current?.append(...logs.map(buildLogEntry))
            })
            .catch((err) => setOutputError(err.message))
    }, [instance])

    useEffect(() => {
        const load = async () => {
            const traderInstanceUrl = `${Config.DESK_ADDR}/traderinstances/${instanceId}`
            console.log(`Loading Trader from ${traderInstanceUrl}`)

            deskService
                .fetchProtobufOld<mts.desk.http.GetInstanceResponse>(
                    traderInstanceUrl,
                    mts.desk.http.GetInstanceResponse.decode
                )
                .then((instanceResponse) => {
                    console.log('Got InstanceResponse', instanceResponse)
                    const instance = instanceResponse.instance as mts.desk.TraderInstance
                    setInstance(instance)
                    console.log('Setting optionValues', instance.optionValues)
                    optionValuesSignal.value = instance.optionValues
                    setLoading(false)
                })
                .catch((err) => {
                    console.log('got err', err)
                })
        }

        load()
    }, [])

    const handleErr = useCallback(
        () => (err: Error | mts.common.ApiError) => {
            if (err instanceof mts.common.ApiError) {
                messageApi.error(`${err.code}-${err.description}: ${err.detail}`)
            } else {
                messageApi.error(err.message)
            }
        },
        [messageApi]
    )

    const handleStopClick = useCallback(() => {
        if (!instance) {
            return
        }
        setStopping(true)
        deskService
            .updateInstanceState(instance, mts.desk.TraderInstance.State.STOPPING)
            .then(() => messageApi.info('Stopping Instance'))
            .catch(handleErr)
            .finally(() => setStopping(false))
    }, [handleErr, instance, messageApi])

    const handlerPortfolioChange = useCallback(
        (portfolio: mts.portfolio.Portfolio) => {
            console.log('Portfolio changed', portfolio)
            portfolioSignal.value = portfolio
        },
        [portfolioSignal]
    )

    if (!instance || !instance.trader || loading) {
        console.log('traderInstance not set or loading=true, return </>')
        return <></>
    }

    const handleStartClick = () => {
        deskService
            .updateInstanceState(instance, mts.desk.TraderInstance.State.STARTING)
            .then((instance) => {
                messageApi.info('Starting Instance')
                setInstance(instance)
            })
            .catch((err) => {
                messageApi.error(err.message)
                console.error('Caught err from startTraderInstance', err)
            })
    }

    // Conditions on which the start button is enabled
    const startEnabled =
        [State.NEW, State.STOPPED, State.ERROR].includes(instance.state) ||
        (instance.state === State.FINISHED && instance.mode === Mode.REALTIME)

    // Conditions on whcih the stop button is enabled
    const trader = instance.trader

    const deleteInstance = () => {
        setDeleting(true)
        deskService
            .deleteInstance(instance)
            .then(() => console.log('deleted'))
            .catch((err) => setError(err.message))
            .finally(() => navigate('/instances'))
    }

    const handleDeleteClick = () => {
        Modal.confirm({
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete this instance?',
            onOk: () => deleteInstance(),
            onCancel: () => {
                // Do nothing
            },
        })
    }

    const handleEditOptionsClick = () => {
        setOptionValuesModalOpen(true)
    }

    const handleOptionsChangeOk = (optionValues: OptionValues) => {
        setOptionValuesModalOpen(false)

        console.log('save OptionValues', optionValues)

        // Save the trader instance with the new option values
        // PUT a new option attributes onto an instance
        deskService
            .updateInstanceOptionValues(instance, optionValues)
            .then((resp) => {
                console.log('Saved option values', optionValues)
                optionValuesSignal.value = optionValues
                let updatedInstance = mts.desk.TraderInstance.create({
                    ...instance,
                    optionValues: optionValues,
                })
                setInstance(updatedInstance)
            })
            .catch((err) => {
                console.error('Failed to update instance option values', err)
                setError(err.message)
            })
    }

    const handleOptionsChangeClose = () => {
        setOptionValuesModalOpen(false)
    }

    return (
        <StyledPage>
            {contextHolder}
            {error && <Alert description={error} type="error" showIcon />}

            <EditOptionValuesModal
                open={optionValuesModalOpen}
                onClose={handleOptionsChangeClose}
                instance={instance}
                options={instance.trader?.options as mts.desk.ITraderOption[]}
                optionValues={optionValuesSignal.value}
                onSave={handleOptionsChangeOk}
            />

            <Toolbar>
                <ToolbarItem>
                    <h1>
                        <Link to="/instances">Instances</Link>: {instance.id}
                    </h1>
                </ToolbarItem>
                <div style={{ flex: '1' }} />
                <ToolbarItem>
                    <RunBacktestButton
                        instance={instance}
                        trader={instance.trader as mts.desk.Trader}
                        disabled={false}
                    />
                </ToolbarItem>
                <ToolbarItem>
                    <Button disabled={!stopButtonEnabled} loading={stopping} onClick={handleStopClick}>
                        Stop
                    </Button>
                </ToolbarItem>
                <ToolbarItem>
                    <Button
                        className="start"
                        disabled={!startEnabled}
                        onClick={handleStartClick}
                        type="primary"
                    >
                        Start
                    </Button>
                </ToolbarItem>
                {/* Add an elippisis that when clicked shows a menu with the options delete, clear output */}
                <ToolbarItem>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: 'delete',
                                    onClick: handleDeleteClick,
                                    label: 'Delete',
                                },
                                {
                                    key: 'clearOutput',
                                    label: 'Clear Output',
                                },
                            ],
                        }}
                        trigger={['click']}
                    >
                        <Button>More..</Button>
                    </Dropdown>
                </ToolbarItem>
            </Toolbar>

            {instance.error && <Alert description={instance.error} type="error" showIcon />}

            {/* A top bar that shows key metrics for an instance, perhaps four */}

            <StatsBar className="portfolio-stats">
                <PercentageReturnStatistic title="Return" value={portfolioSignal.value?.returnAll} />
                <NumberStatistic title="Equity" value={portfolioSignal.value?.equity} />
                <TextStatistic title="Mode" value={mts.desk.TraderInstance.Mode[instance.mode]} />
                <TextStatistic title="State" value={mts.desk.TraderInstance.State[instance.state]} />
                <TextStatistic
                    title="Tick Interval"
                    value={`${instance.trader?.tickInterval as number}  ${enumName(
                        mts.desk.TimeUnit,
                        instance.trader?.tickIntervalUnit
                    )}`}
                />
            </StatsBar>

            <StyledValues>
                <Item label="Trader">
                    <Link to={`/traders/${trader.id}`}>{trader.name}</Link>
                </Item>
                <Item label="Owner">{user.username}</Item>
                <Item label="Portfolio">
                    <Link to={`/portfolios/${instance.portfolioId}`}>{instance.portfolioId}</Link>
                </Item>
                <Item label="Duration">{duration}</Item>
                <Item label="Date Range">{formatInstanceDateRange(instance)}</Item>
                <Item label="Started">{instance.started}</Item>
                <Item label="Option Values">
                    {formatOptionValues(instance.trader as mts.desk.Trader, optionValuesSignal.value)}
                    {isEditOptionsVisible(loggedInUser, instance) && (
                        <div>
                            <Typography.Link onClick={handleEditOptionsClick}>Edit Options</Typography.Link>
                        </div>
                    )}
                </Item>
                <Item label="Finished">{instance.finished}</Item>
                <Item label="Trader Description" className="mts-instance-descriptions-description">
                    <Markdown>{trader.description}</Markdown>
                </Item>
            </StyledValues>
            <PortfolioSummaryMobilePanel
                portfolioId={instance.portfolioId}
                // A bit strange, but since this component has an SSE connection already, reuse it
                // to update the portfolio metrics when the portfolio is updated.
                // TODO refactor to make the sse connection a separate service that multiple components can use
                onPortfolioChange={handlerPortfolioChange}
            ></PortfolioSummaryMobilePanel>

            <OutputCard title={<div>Output</div>} className="logs" size="small">
                {outputError && <Alert type="error" message={outputError} />}
                <div ref={logEntriesRef}></div>
            </OutputCard>
        </StyledPage>
    )
}

const StatsBar = styled.div`
    .ant-card {
    }
    .ant-statistic-content-value {
        font-size: ${(props) => props.theme.antd.fontSize}px;
        line-height: 20px;
    }
`

// custom hook that returns the logged in user
export function useLoggedInUser() {
    const appCtx = React.useContext(DeskContext)
    return appCtx.user
}

// Check if the edit options button should be visible.
export const isEditOptionsVisible = (loggedInUser: mts.desk.User, instance: mts.desk.TraderInstance) => {
    if (instance.mode === Mode.BACKTEST) {
        return false
    }

    if (instance.userId !== loggedInUser.id) {
        return false
    }
    return true
}

export const canExecuteInstance = (loggedInUser: mts.desk.User, instance: mts.desk.TraderInstance) => {
    // If the instance is in backtest mode, then it can't be executed
    if (instance.mode === Mode.BACKTEST) {
        return false
    }

    // If the user owns this instance, then it can be executed AND Mode.REALTIME
    if (instance.userId !== loggedInUser.id) {
        return true
    }
    return true
}

const Item: React.FunctionComponent<{
    label: string
    span?: number
    className?: string
    children: React.ReactNode
}> = (props) => {
    return (
        <StyledItem className={props.className}>
            <div className="label">{props.label}</div>
            <div className="value">{props.children}</div>
        </StyledItem>
    )
}

const StyledItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: ${(props) => props.theme.antd.padding}px;
    margin-right: ${(props) => props.theme.antd.padding}px;
    margin-top: ${(props) => props.theme.antd.padding}px;
    margin-bottom: ${(props) => props.theme.antd.padding}px;
    .label {
        color: #888;
        flex: 1;
    }
    .value {
        color: ${(props) => props.theme.antd.colorTextBase};
    }
`

const StyledValues = styled.div``

const StyledPage = styled(Page)`
    .mts-instance-descriptions-description {
        display: block;
    }
    .descriptions {
        margin-right: ${(props) => props.theme.antd.padding}px;
        margin-left: ${(props) => props.theme.antd.padding}px;

        .ant-descriptions-item-label {
            vertical-align: top;
        }
        .ant-descriptions-item-content {
            vertical-align: top;
            padding-top: 0px;
        }

        .ant-descriptions-item-content.mts-instance-descriptions-description {
            padding-top: 0px !important;
        }

        .ant-descriptions-item-content.mts-instance-descriptions-description p {
            margin-top: 5px !important;
        }

        .ant-descriptions-item-content span {
            padding-top: 0px;
        }

        .ant-descriptions-item-content p {
            padding-top: 0px;
        }
    }

    .logs {
        height: 400px;
        overflow-x: hidden;
    }

    /* some markdown styling for the description */
    .mts-instance-descriptions-description {
        ol {
            margin-top: ${(props) => props.theme.antd.padding/2}px;
            margin-bottom: ${(props) => props.theme.antd.padding/2}px;
        }
    }
`

const OutputCard = styled(Card)`
    margin-top: ${(props) => props.theme.antd.padding}px;
    margin-left: ${(props) => props.theme.antd.padding}px;
    margin-right: ${(props) => props.theme.antd.padding}px;

    overflow: auto;
    .log-entry {
        font-family: monospace;
        white-space: pre;
        border-bottom: 1px solid #555;
        &:last-child {
            border-bottom: none;
        }
    }
`

/**
 * Returns duration this trader took to run in millis.
 * Returns undefined if Trader.started or Trader.finished are undefined
 */
function durationMillis(traderInstance: mts.desk.TraderInstance): undefined | number {
    if (traderInstance.state === State.RUNNING) {
        const started = DateTime.fromISO(traderInstance.started)
        const durationMillis = DateTime.now().toMillis() - started.toMillis()
        return durationMillis
    }

    if (!traderInstance.started || !traderInstance.finished) {
        return undefined
    }
    const started = DateTime.fromISO(traderInstance.started)
    const finished = DateTime.fromISO(traderInstance.finished)
    const durationMillis = finished.toMillis() - started.toMillis()
    return durationMillis
}

export default InstanceSummaryMobilePage
