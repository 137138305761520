import { Card, Statistic } from "antd"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import { useCallback } from "react"
import { DateTime } from "luxon"

export const PercentageReturnStatistic: React.FC<{ title: string; value?: number }> = ({ value, title }) => {
    // Prefix is an arrow up or down depending on the value
    const getPrefix = useCallback(() => {
        if (value === undefined || value === 0) {
            return <></>
        } else if (value > 0) {
            return <ArrowUpOutlined />
        } else if (value < 0) {
            return <ArrowDownOutlined />
        }
        return <></>
    }, [value])

    const getColor = useCallback(() => {
        if (value === undefined) {
            return "#000"
        } else if (value > 0) {
            return "#3f8600"
        } else if (value < 0) {
            return "#cf1322"
        }
        return "#000"
    }, [value])

    return (
        <Card size="small" bordered={false}>
            <Statistic
                title={title}
                value={value}
                precision={2}
                valueStyle={{ color: getColor() }}
                prefix={getPrefix()}
                suffix="%"
            />
        </Card>
    )
}

export const NumberStatistic: React.FC<{ title: string; value?: number; suffix?: string }> = ({
    value,
    title,
    suffix,
}) => {
    return (
        <Card size="small" bordered={false}>
            <Statistic title={title} value={value} precision={2} suffix={suffix} />
        </Card>
    )
}

export const DateStatistic: React.FC<{ title: string; value: string; format: string }> = (props) => {
    const date = props.value ? DateTime.fromISO(props.value) : undefined
    const formatted = date ? date.toFormat(props.format) : ""
    return (
        <Card size="small" bordered={false}>
            <Statistic title={props.title} value={formatted} precision={2} />
        </Card>
    )
}

// Not a statistic, just a text value
export const TextStatistic: React.FC<{ title: string; value: string }> = (props) => {
    return (
        <Card size="small" bordered={false}>
            <Statistic title={props.title} value={props.value} precision={2} />
        </Card>
    )
}

