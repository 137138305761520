import { ReloadOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox } from 'antd'
import Search from 'antd/es/input/Search'
import Table, { ColumnsType } from 'antd/es/table'
import { TableRowSelection } from 'antd/es/table/interface'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { DeskContext, deskService } from '../App'
import { Toolbar, ToolbarItem } from '../components/Toolbar'
import { FullHeightTablePageWrapper } from '../components/pages'
import { mts } from '../desk_protos'
import { PortfolioAutoUpdateDate } from './portfolios/PortfolioPage'
import { InstanceStateFilterSelect } from '../components/instances/InstanceStateFilterSelect'
import { useSignal } from '@preact/signals-react'

const desktopColumns: ColumnsType<mts.desk.TraderInstance> = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render(value, instance, index) {
            return <Link to={`/instances/${instance.id}`}>{instance?.id}</Link>
        },
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        render: (value, trader) => <PortfolioAutoUpdateDate lastUpdated={DateTime.fromISO(value)} />,
    },
    {
        title: 'Trader',
        dataIndex: 'trader.id',
        key: 'trader.id',
        render(value, instance, index) {
            return <Link to={`/traders/${instance.trader?.id}`}>{instance.trader?.name}</Link>
        },
    },
    {
        title: 'Portfolio',
        dataIndex: 'portfolioId',
        key: 'portfolioId',
        render: (portfolioId, trader, index) => <Link to={`/portfolios/${portfolioId}`}>{portfolioId}</Link>,
    },
    {
        title: 'Mode',
        dataIndex: 'mode',
        key: 'mode',
        render: (mode) => mts.desk.TraderInstance.Mode[mode],
    },
    {
        title: 'Access',
        dataIndex: 'access',
        key: 'access',
        render: (access) => mts.desk.Access[access],
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (value) => mts.desk.TraderInstance.State[value],
    },
]

const phoneColumns: ColumnsType<mts.desk.TraderInstance> = [
    {
        title: 'Instance Summary',
        dataIndex: 'id',
        key: 'id',
        render(value, instance, index) {
            return <InstanceSummary instance={instance} />
        },
    },
]

const InstanceSummary: React.FC<{ instance: mts.desk.TraderInstance }> = (props) => {
    const instance = props.instance
    const trader = instance.trader as mts.desk.Trader

    return (
        <StyledInstanceSummary className="mts-instance-summary">
            <div className="id">
                <span className="label">ID</span>
                <Link to={`/instances/${instance.id}`}>{instance.id}</Link>
            </div>
            <div className="trader">
                <span className="label">Trader</span>
                <Link to={`/traders/${trader.id}`}>{trader.name}</Link>
            </div>
            <div className="portfolio">
                <span className="label">Portfolio</span>
                <Link to={`/portfolios/${instance.portfolioId}`}>{instance.portfolioId}</Link>

                <div className="access">
                    <span className="value">{mts.desk.Access[instance.access]}</span>
                </div>
            </div>
            <div className="mode">
                <span className="label">Mode</span>
                <span className="value">{mts.desk.TraderInstance.Mode[instance.mode]}</span>
            </div>
            <div className="state">
                <span className="label">State</span>
                <span className="value">{mts.desk.TraderInstance.State[instance.state]}</span>
            </div>
        </StyledInstanceSummary>
    )
}

const StyledInstanceSummary = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;
    position: relative;

    /* absolute position the state bottom right */
    .state {
        position: absolute;
        right: ${(props) => props.theme.antd.padding}px;
        bottom: ${(props) => props.theme.antd.padding}px;
    }

    .portfolio {
        position: relative;
    }

    .access {
        float: right;
        margin-right: ${(props) => props.theme.antd.padding}px;
    }

    .label {
        font-weight: bold;
    }
    /* add colon and space at end of label */
    .label::after {
        content: ': ';
    }
    .name {
        font-weight: bold;
    }

    .access {
        font-size: 0.8em;
    }

    .labels {
        font-size: 0.8em;
    }

    .created {
        font-size: 0.8em;
    }

    .updated {
        font-size: 0.8em;
    }
`

const TraderProcessesTable: React.FC<{
    onSelectInstances: (selectedInstances: mts.desk.TraderInstance[]) => void
    reloadTrigger: number
}> = (props) => {
    const [traderInstances, setTraderInstances] = React.useState<any[]>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<string>('')
    const [reloadTrigger, setReloadTrigger] = useState(0)

    const filtersSignal = useSignal(
        mts.desk.http.GetInstancesRequest.Filters.create({
            modes: [mts.desk.TraderInstance.Mode.BACKTEST, mts.desk.TraderInstance.Mode.REALTIME],
        })
    )

    const navigate = useNavigate()

    const isPhone = useMediaQuery({ query: '(max-width: 600px)' })

    const getFilters = () => {
        return mts.desk.http.GetInstancesRequest.Filters.create({})
    }

    const load = async () => {
        setLoading(true)
        deskService
            .getInstances(getFilters())
            .then((resp) => setTraderInstances(resp.traderInstances))
            .catch((err) => setError(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        console.log('Reload trigger updated, reloading', reloadTrigger)
        props.onSelectInstances([])
        load()
    }, [reloadTrigger])

    useEffect(() => {
        setReloadTrigger(props.reloadTrigger)
    }, [props.reloadTrigger])

    const columns: ColumnsType<mts.desk.TraderInstance> = isPhone ? phoneColumns : desktopColumns

    if (error) {
        return <div>Error: {error}</div>
    }

    const rowSelection: TableRowSelection<mts.desk.TraderInstance> = {
        type: 'checkbox',
        onChange: (keys, selectedRows: mts.desk.TraderInstance[]) => {
            props.onSelectInstances(selectedRows)
        },
    }

    const handleRowClick = (instance: mts.desk.TraderInstance) => {
        // Navigate to the instance page
        //navigate(`/instances/${instance.id}`)
        console.log('row click', instance)
    }

    return (
        <>
            <Table
                className="table-fillheight"
                scroll={{ x: 'none', y: '100vh' }}
                loading={loading}
                key="id"
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={traderInstances}
                pagination={{ pageSize: 50, hideOnSinglePage: true, showSizeChanger: true }}
                rowSelection={rowSelection}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />
        </>
    )
}

const TraderInstancesPage: React.FunctionComponent<{}> = (props) => {
    const [deleting, setDeleting] = useState(false)
    const [deleteDisabled, setDeleteDisabled] = useState(true)
    const [selectedInstances, setSelectedInstances] = useState<mts.desk.TraderInstance[]>([])
    const appCtx = React.useContext(DeskContext)
    const [reloadInstancesTrigger, setReloadInstancesTrigger] = useState(0)

    const handleReloadClick = () => {
        console.log('set last refresh date')
        setReloadInstancesTrigger(new Date().getTime())
    }

    const onSearch = () => {}

    const handleSelectInstances = (selectedInstances: mts.desk.TraderInstance[]) => {
        console.log('selected:', selectedInstances)
        setSelectedInstances(selectedInstances)
        setDeleteDisabled(selectedInstances.length > 0 ? false : true)
    }

    const handleDelete = () => {
        if (deleting || !selectedInstances) {
            return
        }
        setDeleting(true)
        deskService
            .deleteInstances(selectedInstances)
            .then((resp) => {
                appCtx.messageApi?.info(`${resp.deletedCount} instances deleted`)
                setReloadInstancesTrigger(new Date().getTime())
            })
            .catch((err) => console.error(err))
            .finally(() => setDeleting(false))
    }

    const [showHelp, setShowHelp] = useState(localStorage.getItem('help.instances') === null)
    const handleDismissHelp = () => {
        setShowHelp(false)
        localStorage.setItem('help.instances', 'true')
    }
    const help = showHelp ? (
        <Alert
            closable={true}
            type="info"
            onClose={handleDismissHelp}
            message="Instances are running Traders. An Instance is created each time a Backtest is run
            or a Trader Instance is created."
        ></Alert>
    ) : null

    return (
        <FullHeightTablePageWrapper>
            {help}
            <Toolbar>
                <ToolbarItem>
                    <Button onClick={handleReloadClick} icon={<ReloadOutlined />} />
                </ToolbarItem>

                <ToolbarItem>
                    <Search placeholder="filter instances" onSearch={onSearch} width="400" />
                </ToolbarItem>
                <ToolbarItem>
                    <Checkbox>Backtests</Checkbox>
                    <Checkbox>Realtime</Checkbox>
                </ToolbarItem>

                <div style={{ flex: 1 }} />

                <ToolbarItem>
                    <Button disabled={deleteDisabled} loading={deleting} danger onClick={handleDelete}>
                        Delete {selectedInstances.length > 0 && `(${selectedInstances.length})`}
                    </Button>
                </ToolbarItem>
            </Toolbar>

            <div className="full-height-table">
                <TraderProcessesTable
                    reloadTrigger={reloadInstancesTrigger}
                    onSelectInstances={handleSelectInstances}
                />
            </div>
        </FullHeightTablePageWrapper>
    )
}

export default TraderInstancesPage
