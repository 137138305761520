// A react component that displays a summary portfolio

import { useSignal } from '@preact/signals-react'
import { Alert, Descriptions, DescriptionsProps, Table, Tabs, TabsProps } from 'antd'
import { ColumnsType } from 'antd/es/table'
import Paragraph from 'antd/es/typography/Paragraph'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DeskContext, DeskContextValue, deskService, portfolioService } from '../../App'
import { mts } from '../../desk_protos'
import { Currency } from '../utils/Currency'
import PortfolioChart from './PortfolioChart'

const columns: ColumnsType<mts.portfolio.IPosition> = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        render(value, record, index) {
            return value
        },
    },
    {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (value) => value,
    },
    {
        title: 'Cost Basis',
        dataIndex: 'costBasis',
        key: 'costBasis',
        render: (value) => value,
    },
    {
        title: 'Price',
        dataIndex: 'lastPrice',
        key: 'lastPrice',
        render: (value) => value,
    },
    {
        title: 'Gain',
        dataIndex: 'gain',
        key: 'gain',
        render: (value) => value,
    },
    {
        title: 'Gain %',
        dataIndex: 'gainPercent',
        key: 'gainPercent',
        render: (value) => `${value}%`,
    },
]

const closedColumns: ColumnsType<mts.portfolio.Tx> = [
    {
        title: 'Date',
        dataIndex: 'date',
        width: '175px',
        className: 'date',
        key: 'date',
        render(date, tx, index) {
            const dt = DateTime.fromISO(date, { zone: 'local' })
            const diff = DateTime.now().diff(dt, ['seconds'])
            if (diff.seconds < 86400) {
                return dt.toFormat('h:mm:ss a').toLocaleLowerCase()
            }
            return dt.toFormat('yyyy-MM-dd h:mm:ss a')
        },
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        render(value, record, index) {
            return value
        },
    },
    {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (value) => value,
    },
    {
        title: 'Cost Basis',
        dataIndex: 'costBasis',
        key: 'costBasis',
        width: 90,
        render: (value) => value,
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (value) => value,
    },
    {
        title: 'P&L',
        dataIndex: 'profitLoss',
        key: 'profitLoss',
        render: (value) => <Currency value={value} animate={true} type="currency" color={true} />,
    },
]

/**
 * A summary of a portfolio, meant to be used anywhere a summary of a portfolio is needed.
 * Real-time updates, with lot's of bells and whistles
 */
export const PortfolioSummaryPanel: React.FC<{ portfolioId: string }> = (props) => {
    const [portfolio, setPortfolio] = useState<mts.portfolio.Portfolio>()
    const [portfolioId, setPortfolioId] = useState<number>()
    const [positions, setPositions] = useState<mts.portfolio.Positions>()
    const [loadError, setLoadError] = useState<string>()
    const [loading, setLoading] = useState(false)
    // Use an SSE connection as long as portfolio has not end date
    const [eventSourcePortfolioId, setEventSourcePortfolioId] = useState(0)
    const [exitTransactions, setExitTransactions] = useState<mts.portfolio.Tx[]>()
    const [positionsUpdated, setPositionsUpdated] = useState<number | undefined>(undefined)
    const [chartRefresh, setChartRefresh] = useState<number>(DateTime.now().toMillis())

    const appCtx = React.useContext(DeskContext)

    const { config } = appCtx as DeskContextValue

    // Use <Currency and useSignal from preact to update cash value of portfolio
    const cash = useSignal(portfolio?.cash)
    const deposits = useSignal(portfolio?.totalDeposits)

    // Load portfolio when props.portfolioId changes
    useEffect(() => {
        console.log(`Loading Portfolio#${props.portfolioId}`)
        setLoading(true)
        portfolioService
            .getPortfolio(props.portfolioId)
            .then((portfolio) => setPortfolio(portfolio))
            .catch((e: mts.common.ApiError) => setLoadError(`${e.code}-${e.description}: ${e.detail}`))
            .finally(() => setLoading(false))
    }, [props.portfolioId])

    // Update updatedPortfolio when portfolio changes
    useEffect(() => {
        if (!portfolio) {
            return
        }

        if (eventSourcePortfolioId !== portfolio.id) {
            console.log(`Setting EventSourcePortfolioId to ${portfolio.id}`)
            setEventSourcePortfolioId(portfolio.id)
        }
        deposits.value = portfolio.totalDeposits
        cash.value = portfolio.cash
    }, [cash, deposits, eventSourcePortfolioId, portfolio])

    // Configure/Reconfigure EventSource/SSE connection when portfolio id changes
    useEffect(() => {
        // return if the portfolio has not changed
        if (!eventSourcePortfolioId) {
            return
        }
        let portfolioId = eventSourcePortfolioId
        const since = new Date().toISOString()
        const sseUrl = `${config.portfolioAddr}/portfolios/${portfolioId}/stream?since=${since}`

        const handleMessage = (msg: MessageEvent<string>) => {
            const jsonObj = JSON.parse(msg.data)

            const t = mts.portfolio.http.GetPortfolioStreamResponse.fromObject(jsonObj)
            console.debug(`Got portfolio event ${t.messageType} sequence ${t.sequence}`, t)

            if (t.messageType === 'portfolio') {
                let portfolio = t.portfolio as mts.portfolio.Portfolio
                setPortfolio(portfolio)
            } else if (t.messageType === 'portfolioUpdated') {
                let updatedPortfolio = t.portfolioUpdated?.portfolio as mts.portfolio.Portfolio
                if (updatedPortfolio.endDate) {
                    console.log('updatedPortfolio.endDate', updatedPortfolio.endDate)
                    setChartRefresh(DateTime.now().toMillis())
                }
                setPortfolio(updatedPortfolio)
            } else if (t.messageType === 'positions') {
                let positions = t.positions as mts.portfolio.Positions
                setPositions(positions)
            }
        }

        const es = new EventSource(sseUrl)
        console.log(`Created EventSource to ${sseUrl}`, es)
        es.onmessage = handleMessage
        es.onerror = (e) => {
            console.error('EventSource error', e)
            es.close()
        }

        return () => {
            console.log(`Closing EventSource for Portfolio#${portfolioId}`)
            es.close()
        }
    }, [config.portfolioAddr, eventSourcePortfolioId])

    // Update closed positions when portfolio is changed, or when we get a transaction event
    useEffect(() => {
        if (!portfolio?.id) {
            return
        }

        const load = async () => {
            try {
                console.log('Positions updated, updating closed trades')
                const url = `${appCtx?.config.portfolioAddr}/portfolios/${portfolio?.id}/transactions?closed=true`
                const response = await deskService.fetchProtobufOld<mts.portfolio.GetTransactionsResponse>(
                    url,
                    mts.portfolio.GetTransactionsResponse.decode
                )
                console.log('GetTransactionsResponse', response)
                setExitTransactions(response.transactions as mts.portfolio.Tx[])
            } catch (err) {
                if (err instanceof mts.common.ApiError) {
                    console.error(err)
                } else {
                    console.error(err)
                }
            }
        }
        load()
    }, [appCtx, portfolio?.id, portfolioId, positions])

    if (loadError) {
        return (
            <StyledPortfolioSummaryPanel>
                <Alert type="error" message={loadError} />
            </StyledPortfolioSummaryPanel>
        )
    }

    if (!portfolio) {
        return (
            <StyledPortfolioSummaryPanel>
                <Alert type="info" message="Loading..." />
            </StyledPortfolioSummaryPanel>
        )
    }

    const renderDetails = (tx: mts.portfolio.Tx) => {
        const items = Object.entries(tx.brokerData).map(([key, value]) => (
            <Descriptions.Item label={key}>{value}</Descriptions.Item>
        ))

        return <Descriptions title="Broker Data">{items}</Descriptions>
    }

    // Can the following by memoized?
    const items: DescriptionsProps['items'] = [
        {
            label: 'Name',
            span: { xl: 2 },
            children: <Link to={`/portfolios/${portfolio.id}`}>{portfolio.name}</Link>,
        },
        {
            label: 'Id',
            children: portfolio.id,
        },
        {
            label: 'Created',
            children: portfolio.created,
        },
        {
            label: 'Updated',
            children: portfolio.updated,
        },
        {
            label: 'Total Value',
            children: <Currency value={portfolio.totalValue} animate={true} type="currency" />,
        },
        {
            label: 'Total Dividends',
            children: <Currency value={portfolio.totalDividends} animate={true} type="currency" />,
        },
        {
            label: 'Deposits',
            children: <Currency value={deposits.value} animate={true} type="currency" />,
        },
        {
            label: 'Cash',
            children: <Currency value={cash.value} animate={true} type="currency" />,
        },
        {
            label: 'Return',
            children: <Currency value={portfolio.returnAll} animate={true} type="percentage" precision={2} />,
        },
        {
            label: 'Wins/Losses',
            children: `${portfolio.wins} wins, ${portfolio.losses} losses`,
        },
    ]

    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Performance',
            children: <PortfolioChart portfolioId={portfolio.id.toString()} refresh={chartRefresh} />,
        },
        {
            key: '2',
            label: 'Open Positions',
            children: (
                <div className="full-height-table-wrapper">
                    <Table
                        rowKey="symbol"
                        className="full-height-table"
                        scroll={{ x: 'none', y: '100vh' }}
                        key="symbol"
                        columns={columns}
                        dataSource={positions?.positions}
                        pagination={{ hideOnSinglePage: true }}
                    />
                </div>
            ),
        },
        {
            key: '3',
            label: 'Exit Trades',
            children: (
                <div className="full-height-table-wrapper">
                    <Table
                        rowKey="id"
                        className="full-height-table"
                        scroll={{ x: 'none', y: '100vh' }}
                        key="id"
                        columns={closedColumns}
                        dataSource={exitTransactions}
                        pagination={{ hideOnSinglePage: true }}
                        expandable={{
                            expandedRowRender: renderDetails,
                            rowExpandable: (record) => true,
                        }}
                    />
                </div>
            ),
        },
    ]
    return (
        <StyledPortfolioSummaryPanel className="mts-portfolio-summary">
            <Paragraph className="title">Portfolio Summary{loading && ' Loading...'}</Paragraph>
            <Descriptions bordered size="small" column={2} className="data-fields" items={items} />

            <Tabs type="card" defaultActiveKey="1" items={tabs}></Tabs>
        </StyledPortfolioSummaryPanel>
    )
}

const StyledPortfolioSummaryPanel = styled.div`
    flex: 1;
    display: flex;
    .title {
        font-weight: bold;
        margin-bottom: ${(props) => props.theme.antd.padding / 2}px;
    }
    flex-direction: column;

    .data-fields {
    }
    .ant-tabs {
        margin-top: ${(props) => props.theme.antd.padding}px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-table {
        border-radius: 0;
        overflow-x: auto;
        min-height: 200px;
        max-height: 200px;
    }

    .ant-tabs-content-holder {
        display: flex;
        flex: 1;
    }

    .ant-tabs-content {
        display: flex;
        flex: 1;
    }

    .ant-tabs-tabpane {
        flex: 1;
        position: relative;
    }
`
